import React, { useMemo, useEffect, useState } from "react";
import { IPricingModel, FrequencyType, PricingModelType } from "../../../models/Product";
import Panel from "../panel/Panel";
import Text from "../text/Text";
import { getCycleFreeTrialText, getSetupFeeText, getContractTermText, isAddOn, isComplexPricingModel, getFrequencyText } from "../../../utils/planUtils";
import "./PaymentSummary.scss";
import counterpart from "counterpart";
import { IAppliedCoupon } from "../../../models/DiscountCoupon";
import { GetDiscountOfferResponse } from "../../../utils/grpc/generated/Billsby.Protos/core/public/companyconfig/retentionstep/retention_step_pb";
import { getPriceDescription } from "../../../actions/paymentActions";
import { IAddOnReducer } from "../../../reducers/addOnReducer";
import { ISelectPlanReducer } from "../../../reducers/selectPlanReducer";
import { AppState } from "../../..";
import { IAllowance } from "../../../models/AddOnAllowance";
import { IPaymentReducer } from "../../../reducers/paymentReducer";
import LoadingAnimation from "../loading-animation/LoadingAnimation";
import { ISubscriptionReducer } from "../../../reducers/subscriptionReducer";
import { IPersonalDetailsReducerState } from "../../../reducers/personalDetailsReducer";
import SubscriptionAddOnsAllowances from "./subscription-addons/SubscriptionAddOnsAllowances";
import CyclePriceInfo from "../../select-plan/cycle-price-info/CyclePriceInfo";
import PlanUnitPicker from "./PlanUnitPicker";
import Modal from "../modal/Modal";
import { IGlobalReducer } from "../../../reducers/globalReducer";
import FormLabel from "../form-label/FormLabel";
import { useDispatch, useSelector } from "react-redux";

interface IPaymentSummary<T extends IPricingModel> {
  pricingModel: T;
  productName: string;
  planName: string;
  hasTitle?: boolean;
  className?: string;
  onClick?: () => void;
  currencyCode: string;
  appliedCoupons?: Array<IAppliedCoupon>;
  onDeleteCoupon?: (coupon: IAppliedCoupon) => void;
  discountOffer?: GetDiscountOfferResponse;
  showTerms?: boolean,
  units?: number,
  addonsAllowancesSummaryList?: boolean,
  planImageUrl?: string
}

const PaymentSummary = <T extends IPricingModel>({
  pricingModel,
  productName,
  planName,
  appliedCoupons,
  hasTitle = true,
  currencyCode,
  className = "",
  onClick = () => { },
  onDeleteCoupon,
  discountOffer,
  units,
  showTerms = true,
  addonsAllowancesSummaryList = false
}: IPaymentSummary<T>) => {
  const { frequency, frequencyType, freeTrial, freeTrialFrequencyType, setupFeePriceFormatted, contractTerm,
    contractTermFrequencyType } = pricingModel;

  let contractTermText = getContractTermText(contractTerm, contractTermFrequencyType);
  contractTermText = setupFeePriceFormatted ? contractTermText.toLowerCase() : contractTermText;
  const dateFormat = "DD MMM YYYY";

  const { addOns, allowances, allowancesOverage } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const { cycle, numberOfUnits, plan } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { paymentDescription } = useSelector<AppState, IPaymentReducer>(state => state.paymentReducer);
  const { currentSubscription, currentPlan } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const { address: { country, stateObject } } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer)
  const { branding, checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const dispatch = useDispatch<Function>()

  const [showPricingInfo, setShowPricingInfo] = useState(false);

  const isExisitingSubscription = Boolean(currentSubscription) || Boolean(currentPlan);

  const addOnsAllowances = useMemo(() => {
    const combinedAddOnAllowances = [...addOns, ...allowances];
    return combinedAddOnAllowances.filter(i => !!i.units && i.units > 0);
  }, [allowances, addOns]);

  useEffect(() => {
    const couponCodesList = appliedCoupons && appliedCoupons.length > 0 ? appliedCoupons.map(ac => ac.couponCode || "") : [];
    const discountIdsList = appliedCoupons && appliedCoupons.length > 0 ? appliedCoupons.map(ac => ac.discount.getDiscountId()) : []

    if (cycle) {
      const allowances = addOnsAllowances.filter(el => !isAddOn(el)) as Array<IAllowance>;
      dispatch(getPriceDescription(
        cycle.cycleId,
        addOns.filter(i => !!i.units && i.units > 0),
        allowances.filter(i => !!i.units && i.units > 0),
        numberOfUnits === 0 ? 1 : numberOfUnits,
        !isExisitingSubscription ? couponCodesList : undefined,
        isExisitingSubscription ? discountIdsList : undefined,
        allowancesOverage,
        currentSubscription ? currentSubscription.cycleId : undefined,
        country ? country.value.iso3Code : stateObject ? stateObject.value.CountryIso3 : undefined
      ));
    }

  }, [cycle, allowances, addOns, numberOfUnits, appliedCoupons, allowancesOverage]);

  if (!paymentDescription) {
    return <LoadingAnimation />
  }

  if (!plan) {
    return null;
  }

  const getCycleFrequencyText = (frequency: number, frequencyType: FrequencyType) => {
    if (frequency > 1) {
      return `${frequency} ${getFrequencyText(frequency, frequencyType)}`;
    }
    return `${getFrequencyText(frequency, frequencyType)}`;
  }

  const getPriceAmount = (type: "discounted" | "original") => {
    if (paymentDescription) {
      if (paymentDescription.getSetupFee()) {
        // the amount returned doesn't include setup fees
        return paymentDescription.getFirstInvoicePrice();
      }
      if (type === "original") {
        return paymentDescription.getFirstInvoiceTotalWithoutDiscountsPrice();
      }
      if (type === "discounted") {
        return paymentDescription.getFirstInvoiceTotalPrice();
      }
    }
    return pricingModel.priceFormatted;
  }

  //const originalPrice = paymentDescription ? paymentDescription.getFirstInvoiceTotalWithoutDiscountsPrice() : pricingModel.priceFormatted;
  //const discountedTotalPrice = paymentDescription ? paymentDescription.getFirstInvoiceTotalPrice() : pricingModel.priceFormatted;

  const originalPrice = getPriceAmount("original");
  const discountedTotalPrice = getPriceAmount("discounted");

  const freeTrialText = isExisitingSubscription ? "" : getCycleFreeTrialText(freeTrial, freeTrialFrequencyType);

  return (
    <div className={`payment-summary ${className}`} onClick={onClick}>
      <Panel id="payment-summary" image={plan.value.planImageUrl} imageLayout="box" noMargin>
        <div className="payment-summary__name__container">
          <FormLabel
            content={planName}
            shouldTranslate={false}
            className="payment-summary__name"
            weight="bold"
            noMargin
          />
        </div>
        <div>
          <div className="payment-summary__details">
            <div className="payment-summary__plan-price__container">
              {isComplexPricingModel(plan.value.pricingModelType) ? <>
                <div className="payment-summary__plan-price__picker">
                  <PlanUnitPicker />
                </div>
                <div className="payment-summary__plan-price__text">
                  <>
                    <Text
                      component="span"
                      content={`${!!numberOfUnits && numberOfUnits > 1 ? checkoutConfig?.terminologyUnitPlural : checkoutConfig?.terminologyUnitSingular} `}
                      shouldTranslate={false}
                      weight="bold"
                      noMargin
                    />
                    <Text
                      component="span"
                      content={`${counterpart("FREQUENCY_EVERY")} `}
                      shouldTranslate={false}
                      noMargin
                    />
                    <Text
                      component="span"
                      content={`${getCycleFrequencyText(frequency, frequencyType)} `}
                      shouldTranslate={false}
                      weight="bold"
                      noMargin
                    /><Text
                      component="span"
                      content="FREQUENCY_FOR"
                      noMargin
                    />
                    <Text
                      component="span"
                      content={` ${pricingModel.priceFormatted}  `}
                      weight="bold"
                      shouldTranslate={false}
                      noMargin
                    />
                    {
                      plan.value.pricingModelType !== PricingModelType.PerUnit &&
                      <Text
                        className="pricing-info"
                        component="span"
                        content={"PAYMENT_SUMMARY_PLAN_WHY"}
                        onClick={() => setShowPricingInfo(true)}
                        noMargin
                      />
                    }
                  </>
                </div></> :
                <>
                  <Text
                    className="payment-summary__inline-info"
                    content={`${pricingModel.priceFormatted} `}
                    weight="bold"
                    shouldTranslate={false}
                    noMargin
                  />
                  <Text
                    className="payment-summary__inline-info"
                    content={` ${counterpart("FREQUENCY_EVERY")} `}
                    shouldTranslate={false}
                    noMargin
                  />
                  <Text
                    className="payment-summary__inline-info"
                    content={` ${getCycleFrequencyText(frequency, frequencyType)}`}
                    shouldTranslate={false}
                    weight="bold"
                    noMargin
                  />
                </>}
            </div>
            {
              !!freeTrialText
                ? (
                  <Text
                    size='normal'
                    className="payment-summary__inline-info"
                    content={`${freeTrialText},`}
                    shouldTranslate={false}
                    noMargin
                  />
                )
                : null
            }
            <Text size='normal' className="payment-summary__inline-info" content={`${contractTermText}${setupFeePriceFormatted ? "," : ""} `} shouldTranslate={false} noMargin />
            {
              setupFeePriceFormatted
                ? (
                  <Text
                    size='normal'
                    className="payment-summary__inline-info"
                    content={getSetupFeeText(setupFeePriceFormatted, checkoutConfig)}
                    shouldTranslate={false}
                    noMargin
                  />
                )
                : null
            }
          </div>
        </div>
{/* 
        {!!appliedCoupons && appliedCoupons.length > 0 && <div className="payment-summary__coupons">
          <PaymentSummaryCoupons
            appliedCoupons={appliedCoupons}
            pricingModel={pricingModel}
            currencyCode={currencyCode}
            onDeleteCoupon={onDeleteCoupon}
          />
        </div>} */}
      </Panel>
      <SubscriptionAddOnsAllowances />
      <Modal
        className='cycle-price-info__modal'
        buttonColor={branding.highlightColor}
        isOpen={showPricingInfo}
        title=''
        shouldTranslateTitle={false}
        onRequestClose={() => setShowPricingInfo(false)}
        key={"modal"}>
        <CyclePriceInfo />
      </Modal>
    </div>
  );
};

export default PaymentSummary
