import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { FETCH_ACH_CLIENT_SECRET_FAILURE, FETCH_ACH_CLIENT_SECRET_SUCCESS, RESET_ACH_STATE, SET_ACH_FIELD, START_ACH_MICRODEPOSIT_FAILURE, START_ACH_MICRODEPOSIT_SUCCESS, UPDATE_ACH_PAYMENT_FAILURE, UPDATE_ACH_PAYMENT_SUCCESS } from "../actions/achActions";
import { ConfigConstants } from "../utils/config";
import { CreateAchMandateResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/customer_pb";
import { IStripeACHSetupIntent } from "../models/AchData";
import { CROSS_DOMAIN_EVENT, sendEvent } from "../utils/crossDomainEvents";


export interface IACHReducer {
  accountHolder: string,
  stripeClientSecret: string,
  stripeInstance: any,
  stripeCustomerId: string,
  stripeLast4Digits: string,
  stripeBankName: string,
  stripePaymentId: string,
  stripeSetupIntent?: IStripeACHSetupIntent,
  isOpenMandateForm: boolean,
  isDoingACHWorkflow: boolean,
  isUpdateAchSucceeded: boolean,
  isUpdateAchFailed: boolean,
  isStartMicroDepositSuccess: boolean,
  isStartMicroDepositFailed: boolean
}

export const initialState = (): IACHReducer => ({
  accountHolder: "",
  stripeClientSecret: "",
  stripeLast4Digits: "",
  stripeBankName: "",
  stripeInstance: {
    collectBankAccountForSetup: () => Promise.resolve({ setupIntent: {}, error: { message: "" }}),
    confirmUsBankAccountSetup: () => Promise.resolve({ setupIntent: {}, error: { message: "" }})
  },
  stripeCustomerId: "",
  stripePaymentId: "",
  isOpenMandateForm: false,
  isDoingACHWorkflow: false,
  isUpdateAchSucceeded: false,
  isUpdateAchFailed: false,
  isStartMicroDepositSuccess: false,
  isStartMicroDepositFailed: false
});

export default function achReducer(state = initialState(), action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case SET_ACH_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }
    case FETCH_ACH_CLIENT_SECRET_SUCCESS: {
      const createAchMandateResponse = action.response as CreateAchMandateResponse;
      return { 
        ...state, 
        stripeClientSecret: createAchMandateResponse.getClientSecret(),
        stripeInstance: (window as any).Stripe(ConfigConstants.stripePublicKey, {
          stripeAccount: createAchMandateResponse.getStripeAccount(),
        }),
        stripeCustomerId: createAchMandateResponse.getStripeCustomerId()
      }
    }
    case FETCH_ACH_CLIENT_SECRET_FAILURE: {
      return { ...state, isUpdateAchSucceeded: false, isUpdateAchFailed: true }
    }
    case UPDATE_ACH_PAYMENT_SUCCESS:
      sendEvent(CROSS_DOMAIN_EVENT.CHANGE_PAYMENT_DETAILS_SUCCESS)
      return { ...state, isUpdateAchSucceeded: true, isUpdateAchFailed: false }
    case UPDATE_ACH_PAYMENT_FAILURE:
      return { ...state, isUpdateAchSucceeded: false, isUpdateAchFailed: true }

    case START_ACH_MICRODEPOSIT_SUCCESS:
      return { ...state, isStartMicroDepositSuccess: true, isStartMicroDepositFailed: false }
    case START_ACH_MICRODEPOSIT_FAILURE:
      return { ...state, isStartMicroDepositSuccess: false, isStartMicroDepositFailed: true }

    case RESET_ACH_STATE:
      return { ...initialState() }
    default:
      return state;
  }
}
