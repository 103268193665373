import React from "react";
import SelectPlanSummary from "../../components/select-plan/SelectPlanSummary";

interface IManageCoupon {}

const ManageCoupon: React.FC<IManageCoupon> = () => {

  return (
    <SelectPlanSummary editableAddOns={false} resetOnUnmount={true}/>
  );
};

export default ManageCoupon
