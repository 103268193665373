import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { NavigationBarSteps } from "../../models/NavigationBar";
import SelectPlan from "../select-plan/SelectPlan";
import PersonalDetails from "../personal-details/PersonalDetails";
import PaymentMethod from "../payment-method/PaymentMethod";

const getCurrentStep = (navigationState: IGlobalReducer) => {
  const { navigationStep } = navigationState;
  switch (navigationStep) {
    case NavigationBarSteps.SELECT_PLAN:
      return <SelectPlan />
    case NavigationBarSteps.PERSONAL_DETAILS:
      return <PersonalDetails />
    case NavigationBarSteps.ADDRESS_DETAILS:
      return <PersonalDetails />
    case NavigationBarSteps.PAYMENT_METHOD:
      return <PaymentMethod />
    default:
      return <div />
  }
}

const NavigationContainer: React.FC = () => {
  const globalReducer = useSelector((state: AppState) => state.globalReducer);
  return getCurrentStep(globalReducer);
}

export default NavigationContainer;