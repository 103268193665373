import React, { useLayoutEffect, useState, useEffect } from "react";
import PageWrapper from "../../../containers/page-wrapper/PageWrapper";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { ILoginReducer } from "../../../reducers/loginReducer";
import CustomerSubscriptions from "../../customer-subscription/CustomerSubscriptions";
import { fetchCustomerSubscriptions, setCurrentSubscription, fetchCurrentPlan } from "../../../actions/subscriptionsActions";
import { ISubscriptionReducer } from "../../../reducers/subscriptionReducer";
import { IPersonalDetailsReducerState } from "../../../reducers/personalDetailsReducer";
import { IGlobalReducer } from "../../../reducers/globalReducer";
import { setCustomerUniqueId, setSubscriptionUniqueId } from "../../../actions/personalDetailsActions";
import history from "../../../utils/history";
import SystemInfo from "../../ui/system-info/SystemInfo";
import { setLoginField } from "../../../actions/loginActions";
import { fetchCheckoutConfig, getCustomerLanguage } from "../../../actions/globalActions";


const LoginSubscriptions: React.FC = () => {
  const { customerActiveSubscriptions, isFetchCustomerSubscriptionSuccess, isFetchCustomerSubscriptionFailure } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const { mainProps } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { validatePasscodeResponse } = useSelector<AppState, ILoginReducer>(state => state.loginReducer);
  const { companyDomain, checkoutConfig, billsbyAction } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const dispatch = useDispatch<Function>()

  const [selectedSubscription, setSelectedSubscription] = useState(-1);
  const customerUniqueId = validatePasscodeResponse && validatePasscodeResponse.getCustomerUniqueId() || mainProps.customerUniqueId;

  const goToAccoutManagement = () => {
    let pathname = `/management${billsbyAction ? `/${billsbyAction}` : ""}`;
    // if data-billsby-action is passed we need to redirect to the specific action screen otherwise the management menu
    // go to account management preserving the query string with the data-billsby attributes!!
    history.push({ pathname, search: window.location.search });
    dispatch(setLoginField("isLoadingScreen", false));
  }

  useLayoutEffect(() => {
    const fetchData = async () => {
      if (customerUniqueId) {
        dispatch(fetchCustomerSubscriptions(companyDomain, customerUniqueId));
        dispatch(setCustomerUniqueId(customerUniqueId));
        //we need to fetch the customer language after login because it can potentially change the checkout language
        await dispatch(getCustomerLanguage(companyDomain, customerUniqueId));
        dispatch(fetchCheckoutConfig(companyDomain));
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (isFetchCustomerSubscriptionFailure || (isFetchCustomerSubscriptionSuccess && !customerActiveSubscriptions.length)) {
      dispatch(setLoginField("isLoadingScreen", false));
    }
  }, [isFetchCustomerSubscriptionSuccess, isFetchCustomerSubscriptionFailure])

  useEffect(() => {
    if (customerActiveSubscriptions.length) {
      if (customerActiveSubscriptions.length === 1) {
        const currentSubscription = customerActiveSubscriptions[0];
        dispatch(setCurrentSubscription(currentSubscription));
        dispatch(setSubscriptionUniqueId(currentSubscription.subscriptionUniqueId));
        dispatch(fetchCurrentPlan(companyDomain, currentSubscription.productId, currentSubscription.planId, currentSubscription.subscriptionUniqueId));
        goToAccoutManagement();
        return;
      }
      if (mainProps.subscriptionUniqueId) {
        // if the subscription unique id is preloaded via the embed code
        const currentSubscription = customerActiveSubscriptions.find(subscription => subscription.subscriptionUniqueId === mainProps.subscriptionUniqueId);
        dispatch(setCurrentSubscription(currentSubscription));
        currentSubscription && dispatch(fetchCurrentPlan(companyDomain, currentSubscription.productId, currentSubscription.planId, currentSubscription.subscriptionUniqueId));
        goToAccoutManagement();
      }
    }
  }, [customerActiveSubscriptions]);

  const onSubmit = () => {
    const selectedSubscriptionObj = customerActiveSubscriptions[selectedSubscription];
    dispatch(setCurrentSubscription(selectedSubscriptionObj));
    dispatch(setSubscriptionUniqueId(selectedSubscriptionObj.subscriptionUniqueId));
    dispatch(fetchCurrentPlan(companyDomain, selectedSubscriptionObj.productId, selectedSubscriptionObj.planId, selectedSubscriptionObj.subscriptionUniqueId));
    goToAccoutManagement();
  }

  if (isFetchCustomerSubscriptionFailure) {
    return (
      <PageWrapper btnVisible={false}>
        <SystemInfo
          title="LOGIN_CUSTOMER_ERROR"
          isShowIcon={false}
          type="warning"
        />
      </PageWrapper>
    )
  }

  if (isFetchCustomerSubscriptionSuccess && !customerActiveSubscriptions.length) {
    return (
      <PageWrapper btnVisible={false}>
        <SystemInfo
          title="LOGIN_CUSTOMER_ZERO_SUBSCRIPTIONS_ERROR"
          translateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
          isShowIcon={false}
          type="warning"
        />
      </PageWrapper>
    )
  }

  if (!checkoutConfig || customerActiveSubscriptions.length <= 1) {
    return null;
  }

  return (
    <PageWrapper
      title="LOGIN_SUBSCRIPTIONS_TITLE"
      titleTranslateWith={{ subscriptionLabel: checkoutConfig.terminologySubscriptionPlural }}
      titlePosition="left-aligned"
      subTitle="LOGIN_SUBSCRIPTIONS_SUBTITLE"
      subTitleTranslateWith={{ subscriptionLabel: checkoutConfig.terminologySubscriptionSingular }}
      btnPosition="bottom"
      btnDisabled={selectedSubscription < 0}
      btnCallback={onSubmit}
      btnBackHidden>
      <CustomerSubscriptions
        subscriptions={customerActiveSubscriptions}
        selectedSubscriptionIdx={selectedSubscription}
        onClick={(selectedSubscriptionIdx) => setSelectedSubscription(selectedSubscriptionIdx)}
      />
    </PageWrapper>
  )
}

export default LoginSubscriptions