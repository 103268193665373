import React, { CSSProperties, ReactElement, useEffect, useMemo, useRef, useState } from "react";
import "./PageWrapper.scss";
import Button from "../../components/ui/button/Button";
import Text from "../../components/ui/text/Text";
import { sendEvent, CROSS_DOMAIN_EVENT } from "../../utils/crossDomainEvents";
import { isMobileOnly } from "react-device-detect";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import FloatingHeader from "../floating-header/FloatingHeader";
import { findDOMNode } from "react-dom";
import Icon from "../../components/ui/icon/Icon";
import { ContainerWebsite } from "../../models/BillsbyData";
import history from "../../utils/history";
import { useCheckOverflow } from "../../utils/custom-hooks";

interface IPageWrapper {
  className?: string,
  btnText?: string,
  btnType?: "button" | "submit",
  btnDisabled?: boolean,
  btnVisible?: boolean,
  btnIcon?: string,
  btnPosition?: "top-right" | "bottom",
  btnTextTranslateWith?: object,
  iconPosition?: "start" | "end",
  shouldTranslateText?: boolean,
  children: ReactElement | Array<ReactElement>,
  bottomStickyContent?: JSX.Element,
  upperStickyContent?: JSX.Element,
  isLoading?: boolean,
  //Stick header properties
  headerVisible?: boolean,
  title?: string,
  titlePosition?: "centered" | "left-aligned",
  titleShouldTranslate?: boolean,
  titleTranslateWith?: object,
  subTitle?: string,
  subTitleShouldTranslate?: boolean,
  subTitleTranslateWith?: object,
  btnCallback?: () => any,
  btnBackCallback?: () => void,
  btnBackHidden?: boolean,
  showCloseIcon?: boolean
}

const PageWrapper: React.FC<IPageWrapper> = ({ className = "", headerVisible = true, title, titlePosition = "centered", subTitle, titleTranslateWith = {},
  subTitleTranslateWith = {}, titleShouldTranslate = true, subTitleShouldTranslate = true, btnBackCallback, isLoading = false, btnBackHidden = false,
  btnCallback, btnText = "SELECT_PLAN_CONTINUE", btnVisible = true, btnType = "button", btnDisabled = false, btnIcon = "",
  btnPosition = "bottom", iconPosition = "end", btnTextTranslateWith, shouldTranslateText = true, children, bottomStickyContent, upperStickyContent,
  showCloseIcon = true }) => {
   
  const { checkoutConfig, preloadedCustomerData } = useSelector((state: AppState) => state.globalReducer);
  const activePlanId = useSelector((state: AppState) => state.selectPlanReducer.activePlanId);

  const upperContentRef = useRef(null);
  const bottomContentRef = useRef(null);
  const bodyContentRef = useRef(null);
  const isOverflown = useCheckOverflow(bodyContentRef, [children]);
  const [bodyContentStyle, setBodyContentStyle] = useState<CSSProperties>({});
  const [bottomScrolled, setBottomScrolled] = useState(false);
  const isContainerWebsiteBillsby = preloadedCustomerData.containerWebsite === ContainerWebsite.BILLSBY_APP;
  const isAccountManagement = history.location.pathname.includes("/management");

  const hasCart = useMemo(() => {
    return !!document.querySelector(".cart__container") && !!activePlanId && !isMobileOnly
  }, [children, activePlanId])

  useEffect(() => {
    /**
     * the sticky upper content and the sticky bottom content have a dynamic height, so we need to calculate the exact body content height 
     * which is the one that scrolls
     */

    const calculateBodyHeight = () => {
      const upperContentDom = findDOMNode(upperContentRef.current) as HTMLElement | null;
      const bottomContentDom = findDOMNode(bottomContentRef.current) as HTMLElement | null;
      const bodyContentDom = findDOMNode(bodyContentRef.current) as HTMLElement | null;
      
      //Detect current content if scrollable
      if (bodyContentDom && (bodyContentDom.scrollTop + 25 >= (bodyContentDom.scrollHeight - bodyContentDom.offsetHeight))) {
        setBottomScrolled(true)
      } else {
        setBottomScrolled(false)
      }

      if (upperContentDom && bottomContentDom && bodyContentDom) {
        const spacing = 20;
        const upperContentHeight = upperContentDom.offsetHeight ? upperContentDom.offsetHeight + spacing : 0;
        const bottomContentHeight = bottomContentDom.offsetHeight ? bottomContentDom.offsetHeight : 0;
        setBodyContentStyle({
          height: `calc(100% - ${upperContentHeight}px - ${bottomContentHeight}px - ${spacing}px)`,
          overflowY: "auto"
        });
      }
      else {
        setBodyContentStyle({
          height: "100%",
          overflowY: "auto"
        })
      }
    }
    calculateBodyHeight();
  }, [upperContentRef.current, bottomContentRef.current, bodyContentRef.current, upperStickyContent, bottomStickyContent, children, isOverflown]);

  const onScrollContainer = (evt: any) => {
    if(evt.target.className !== "page-wrapper__body-content") { return }
    if (evt.target.scrollTop + 25 >= (evt.target.scrollHeight - evt.target.offsetHeight)) {
      setBottomScrolled(true);
    } else {
      setBottomScrolled(false);
    }
  }

  const _showCloseIcon = () => {
    if(isAccountManagement) {
      return showCloseIcon
    }

    if(checkoutConfig?.isWixCompatibilityModeVisible) {
      return false;
    }

    return showCloseIcon;
  }

  const renderMobileFloatingHeader = () => {
    return (
      <>
        <FloatingHeader
          btnBackCallback={btnBackCallback}
          btnBackHidden={btnBackHidden}
          showCloseIcon={_showCloseIcon()}
        />
        <div className="page-wrapper__sticky-header page-wrapper__sticky-header--fade-out">
          <div className="page-wrapper__sticky-header__content">
            {title &&
              <Text
                content={title}
                shouldTranslate={titleShouldTranslate}
                translateWith={titleTranslateWith}
                size="xlarge"
                weight="bold"
                noMargin
              />}
            {subTitle &&
              <Text content={subTitle}
                shouldTranslate={subTitleShouldTranslate}
                translateWith={subTitleTranslateWith}
                size="small"
                noMargin
              />}
          </div>
        </div>
      </>
    )
  }

  const renderStickyHeader = () => {
    if (!headerVisible) {
      return null
    }

    return (
      <div className="page-wrapper__sticky-header page-wrapper__sticky-header--fade-out">
        <Icon
          onClick={btnBackCallback}
          className={`page-wrapper__sticky-header__back-btn ${(!!btnBackCallback && !btnBackHidden) ? "" : "page-wrapper__sticky-header__back-btn--hidden"}`}
          showBoundingBox={false} icon="far fa-chevron-left" size="xlarge">
        </Icon>

        <div className={`page-wrapper__sticky-header__content ${(titlePosition === "left-aligned") ? "page-wrapper__sticky-header__content--left-aligned" : ""}`}>
          {title &&
            <Text
              content={title}
              shouldTranslate={titleShouldTranslate}
              translateWith={titleTranslateWith}
              size="xlarge"
              weight="bold"
              noMargin
            />}
          {subTitle &&
            <Text content={subTitle}
              shouldTranslate={subTitleShouldTranslate}
              translateWith={subTitleTranslateWith}
              size="small"
              noMargin
            />}
        </div>
        {
          !!btnCallback && btnPosition === "top-right" && (
            <Button
              id="page-wrapper-continue"
              type={btnType}
              className="page-wrapper__continue"
              title={btnText}
              shouldTranslate={shouldTranslateText}
              icon={btnIcon}
              iconPosition={iconPosition}
              onClick={!btnDisabled ? btnCallback : () => null}
              isVisible={btnVisible}
              disabled={btnDisabled}
              isLoading={isLoading}
              translateWith={btnTextTranslateWith}
            />
          )

        }
        {_showCloseIcon() && (
          <Icon
            onClick={() => sendEvent(CROSS_DOMAIN_EVENT.CLOSE)}
            className="page-wrapper__sticky-header__close-btn"
            showBoundingBox={false} icon="far fa-times" size="xlarge">
          </Icon>
        )}
      </div>
    )
  }


  return (
    <div className={`page-wrapper ${className}`}>
      {isMobileOnly ? renderMobileFloatingHeader() : renderStickyHeader()}
      <div className={`page-wrapper__scrollable ${headerVisible ? "page-wrapper__scrollable--with-header" : ""}`}>

        <div ref={upperContentRef} className="page-wrapper__upper-content">{upperStickyContent}</div>

        <div
          className={"page-wrapper__body-content"}
          onScroll={onScrollContainer}
          ref={bodyContentRef}
          style={{...bodyContentStyle, paddingLeft: hasCart ? "15px" : "unset", paddingRight: isOverflown ? "20px" : "unset", marginRight: isOverflown ? "-20px" : "unset",} /*{ height: (btnPosition === 'bottom' && !!btnCallback && btnVisible) ? '76%' : '100%', overflowY: 'auto' }*/}>
          {children}
        </div>

        {
          ((!!btnCallback && btnPosition === "bottom") || bottomStickyContent) && (
            <div ref={bottomContentRef} className="page-wrapper__bottom-content">
              <Button
                id="page-wrapper-continue"
                type={btnType}
                className="page-wrapper__continue page-wrapper__continue--bottom"
                title={btnText}
                shouldTranslate={shouldTranslateText}
                icon={btnIcon}
                iconPosition={iconPosition}
                onClick={!btnDisabled ? btnCallback : () => null}
                isVisible={btnVisible && btnPosition === "bottom"}
                disabled={btnDisabled}
                isLoading={isLoading}
                translateWith={btnTextTranslateWith}
                isFullWidth
              />
              {bottomStickyContent}
            </div>
          )
        }

      </div>
      <div className="page-wrapper__gradient" style={{ bottom: Boolean(btnCallback) ? "70px" : undefined, opacity: bottomScrolled ? 0 : 1, width: isMobileOnly ? "100%" : "calc(100% - 35px)" }} />
    </div>
  )
}

export default PageWrapper;