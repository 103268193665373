/* eslint-disable */
// source: subscription.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.billing.public.subscription.Addon', null, global);
goog.exportSymbol('proto.billing.public.subscription.AddonPriceModel', null, global);
goog.exportSymbol('proto.billing.public.subscription.AddonPriceSimulation', null, global);
goog.exportSymbol('proto.billing.public.subscription.Allowance', null, global);
goog.exportSymbol('proto.billing.public.subscription.AllowancePriceModel', null, global);
goog.exportSymbol('proto.billing.public.subscription.AllowancePriceSimulation', null, global);
goog.exportSymbol('proto.billing.public.subscription.AllowancesPricingDisclosureResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.CancelSubscriptionRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.CancelSubscriptionResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.CheckoutAddonsSimulationRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.CheckoutAddonsSimulationResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.CheckoutAllowancesSimulationRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.CheckoutAllowancesSimulationResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.Currency', null, global);
goog.exportSymbol('proto.billing.public.subscription.FrequencyType', null, global);
goog.exportSymbol('proto.billing.public.subscription.GetAddonsRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.GetAddonsResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.GetAllowancesRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.GetAllowancesResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.GetCancellationDetailsRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.GetCancellationDetailsResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.GetPendingMinimumTermAmountRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.GetPendingMinimumTermAmountResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.PayPendingMinimumTermAmountRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.PayPendingMinimumTermAmountResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.PricingModelType', null, global);
goog.exportSymbol('proto.billing.public.subscription.SubscriptionAddon', null, global);
goog.exportSymbol('proto.billing.public.subscription.UnitTier', null, global);
goog.exportSymbol('proto.billing.public.subscription.UpdateSubscriptionAddonsRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.UpdateSubscriptionAddonsResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.AllowancesPricingDisclosureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.AllowancesPricingDisclosureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.AllowancesPricingDisclosureResponse.displayName = 'proto.billing.public.subscription.AllowancesPricingDisclosureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse.displayName = 'proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.displayName = 'proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.displayName = 'proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.GetPendingMinimumTermAmountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.displayName = 'proto.billing.public.subscription.GetPendingMinimumTermAmountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.GetPendingMinimumTermAmountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.displayName = 'proto.billing.public.subscription.GetPendingMinimumTermAmountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.PayPendingMinimumTermAmountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.displayName = 'proto.billing.public.subscription.PayPendingMinimumTermAmountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.PayPendingMinimumTermAmountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.PayPendingMinimumTermAmountResponse.displayName = 'proto.billing.public.subscription.PayPendingMinimumTermAmountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.GetAddonsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.GetAddonsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.GetAddonsRequest.displayName = 'proto.billing.public.subscription.GetAddonsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.GetAddonsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.GetAddonsResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.GetAddonsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.GetAddonsResponse.displayName = 'proto.billing.public.subscription.GetAddonsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.Addon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.Addon.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.Addon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.Addon.displayName = 'proto.billing.public.subscription.Addon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.AddonPriceModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.AddonPriceModel.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.AddonPriceModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.AddonPriceModel.displayName = 'proto.billing.public.subscription.AddonPriceModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.GetAllowancesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.GetAllowancesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.GetAllowancesRequest.displayName = 'proto.billing.public.subscription.GetAllowancesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.GetAllowancesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.GetAllowancesResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.GetAllowancesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.GetAllowancesResponse.displayName = 'proto.billing.public.subscription.GetAllowancesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.Allowance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.Allowance.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.Allowance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.Allowance.displayName = 'proto.billing.public.subscription.Allowance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.AllowancePriceModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.AllowancePriceModel.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.AllowancePriceModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.AllowancePriceModel.displayName = 'proto.billing.public.subscription.AllowancePriceModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.Currency = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.Currency, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.Currency.displayName = 'proto.billing.public.subscription.Currency';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.UnitTier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.UnitTier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.UnitTier.displayName = 'proto.billing.public.subscription.UnitTier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.UpdateSubscriptionAddonsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.displayName = 'proto.billing.public.subscription.UpdateSubscriptionAddonsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.UpdateSubscriptionAddonsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.UpdateSubscriptionAddonsResponse.displayName = 'proto.billing.public.subscription.UpdateSubscriptionAddonsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.displayName = 'proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse.displayName = 'proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.SubscriptionAddon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.SubscriptionAddon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.SubscriptionAddon.displayName = 'proto.billing.public.subscription.SubscriptionAddon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.CheckoutAddonsSimulationRequest.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.CheckoutAddonsSimulationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.CheckoutAddonsSimulationRequest.displayName = 'proto.billing.public.subscription.CheckoutAddonsSimulationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.CheckoutAddonsSimulationResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.CheckoutAddonsSimulationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.CheckoutAddonsSimulationResponse.displayName = 'proto.billing.public.subscription.CheckoutAddonsSimulationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.AddonPriceSimulation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.AddonPriceSimulation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.AddonPriceSimulation.displayName = 'proto.billing.public.subscription.AddonPriceSimulation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.CheckoutAllowancesSimulationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.displayName = 'proto.billing.public.subscription.CheckoutAllowancesSimulationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.CheckoutAllowancesSimulationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.displayName = 'proto.billing.public.subscription.CheckoutAllowancesSimulationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.AllowancePriceSimulation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.AllowancePriceSimulation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.AllowancePriceSimulation.displayName = 'proto.billing.public.subscription.AllowancePriceSimulation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.CancelSubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.CancelSubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.CancelSubscriptionRequest.displayName = 'proto.billing.public.subscription.CancelSubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.CancelSubscriptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.CancelSubscriptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.CancelSubscriptionResponse.displayName = 'proto.billing.public.subscription.CancelSubscriptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.GetCancellationDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.GetCancellationDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.GetCancellationDetailsRequest.displayName = 'proto.billing.public.subscription.GetCancellationDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.GetCancellationDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.GetCancellationDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.GetCancellationDetailsResponse.displayName = 'proto.billing.public.subscription.GetCancellationDetailsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.AllowancesPricingDisclosureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.AllowancesPricingDisclosureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.AllowancesPricingDisclosureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.AllowancesPricingDisclosureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricingDisclosure: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.AllowancesPricingDisclosureResponse}
 */
proto.billing.public.subscription.AllowancesPricingDisclosureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.AllowancesPricingDisclosureResponse;
  return proto.billing.public.subscription.AllowancesPricingDisclosureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.AllowancesPricingDisclosureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.AllowancesPricingDisclosureResponse}
 */
proto.billing.public.subscription.AllowancesPricingDisclosureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPricingDisclosure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.AllowancesPricingDisclosureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.AllowancesPricingDisclosureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.AllowancesPricingDisclosureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.AllowancesPricingDisclosureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricingDisclosure();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pricing_disclosure = 1;
 * @return {string}
 */
proto.billing.public.subscription.AllowancesPricingDisclosureResponse.prototype.getPricingDisclosure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.AllowancesPricingDisclosureResponse} returns this
 */
proto.billing.public.subscription.AllowancesPricingDisclosureResponse.prototype.setPricingDisclosure = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricingDisclosure: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse}
 */
proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse;
  return proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse}
 */
proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPricingDisclosure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricingDisclosure();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string pricing_disclosure = 1;
 * @return {string}
 */
proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse.prototype.getPricingDisclosure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse} returns this
 */
proto.billing.public.subscription.GetCheckoutAddonsSimulationPricingDisclosureResponse.prototype.setPricingDisclosure = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    companyDomain: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest;
  return proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string customer_unique_id = 1;
 * @return {string}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest} returns this
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest} returns this
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string company_domain = 3;
 * @return {string}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest} returns this
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    formattedAmount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse;
  return proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFormattedAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 subscription_id = 1;
 * @return {number}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse} returns this
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse} returns this
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 amount = 3;
 * @return {number}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse} returns this
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string formatted_amount = 4;
 * @return {string}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.prototype.getFormattedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse} returns this
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.prototype.setFormattedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse} returns this
 */
proto.billing.public.subscription.GetSubscriptionUnpaidAmountResponse.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.GetPendingMinimumTermAmountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    companyDomain: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountRequest}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.GetPendingMinimumTermAmountRequest;
  return proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.GetPendingMinimumTermAmountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountRequest}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.GetPendingMinimumTermAmountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string customer_unique_id = 1;
 * @return {string}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountRequest} returns this
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountRequest} returns this
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string company_domain = 3;
 * @return {string}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountRequest} returns this
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.GetPendingMinimumTermAmountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    formattedAmount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cardType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cardLastFourDigits: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountResponse}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.GetPendingMinimumTermAmountResponse;
  return proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.GetPendingMinimumTermAmountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountResponse}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormattedAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardLastFourDigits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.GetPendingMinimumTermAmountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFormattedAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCardType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCardLastFourDigits();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 subscription_id = 1;
 * @return {number}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountResponse} returns this
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountResponse} returns this
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 amount = 3;
 * @return {number}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountResponse} returns this
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string formatted_amount = 4;
 * @return {string}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.getFormattedAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountResponse} returns this
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.setFormattedAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountResponse} returns this
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string card_type = 6;
 * @return {string}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.getCardType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountResponse} returns this
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.setCardType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string card_last_four_digits = 7;
 * @return {string}
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.getCardLastFourDigits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetPendingMinimumTermAmountResponse} returns this
 */
proto.billing.public.subscription.GetPendingMinimumTermAmountResponse.prototype.setCardLastFourDigits = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.PayPendingMinimumTermAmountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    companyDomain: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.PayPendingMinimumTermAmountRequest}
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.PayPendingMinimumTermAmountRequest;
  return proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.PayPendingMinimumTermAmountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.PayPendingMinimumTermAmountRequest}
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.PayPendingMinimumTermAmountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string customer_unique_id = 1;
 * @return {string}
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.PayPendingMinimumTermAmountRequest} returns this
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.PayPendingMinimumTermAmountRequest} returns this
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string company_domain = 3;
 * @return {string}
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.PayPendingMinimumTermAmountRequest} returns this
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.PayPendingMinimumTermAmountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.PayPendingMinimumTermAmountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.PayPendingMinimumTermAmountResponse}
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.PayPendingMinimumTermAmountResponse;
  return proto.billing.public.subscription.PayPendingMinimumTermAmountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.PayPendingMinimumTermAmountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.PayPendingMinimumTermAmountResponse}
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.PayPendingMinimumTermAmountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.PayPendingMinimumTermAmountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.PayPendingMinimumTermAmountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.GetAddonsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.GetAddonsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.GetAddonsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetAddonsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.GetAddonsRequest}
 */
proto.billing.public.subscription.GetAddonsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.GetAddonsRequest;
  return proto.billing.public.subscription.GetAddonsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.GetAddonsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.GetAddonsRequest}
 */
proto.billing.public.subscription.GetAddonsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.GetAddonsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.GetAddonsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.GetAddonsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetAddonsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.subscription.GetAddonsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetAddonsRequest} returns this
 */
proto.billing.public.subscription.GetAddonsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.GetAddonsRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetAddonsRequest} returns this
 */
proto.billing.public.subscription.GetAddonsRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.GetAddonsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.GetAddonsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.GetAddonsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.GetAddonsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetAddonsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    addonsList: jspb.Message.toObjectList(msg.getAddonsList(),
    proto.billing.public.subscription.Addon.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.GetAddonsResponse}
 */
proto.billing.public.subscription.GetAddonsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.GetAddonsResponse;
  return proto.billing.public.subscription.GetAddonsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.GetAddonsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.GetAddonsResponse}
 */
proto.billing.public.subscription.GetAddonsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.public.subscription.Addon;
      reader.readMessage(value,proto.billing.public.subscription.Addon.deserializeBinaryFromReader);
      msg.addAddons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.GetAddonsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.GetAddonsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.GetAddonsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetAddonsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.public.subscription.Addon.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Addon addons = 1;
 * @return {!Array<!proto.billing.public.subscription.Addon>}
 */
proto.billing.public.subscription.GetAddonsResponse.prototype.getAddonsList = function() {
  return /** @type{!Array<!proto.billing.public.subscription.Addon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.subscription.Addon, 1));
};


/**
 * @param {!Array<!proto.billing.public.subscription.Addon>} value
 * @return {!proto.billing.public.subscription.GetAddonsResponse} returns this
*/
proto.billing.public.subscription.GetAddonsResponse.prototype.setAddonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.public.subscription.Addon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.Addon}
 */
proto.billing.public.subscription.GetAddonsResponse.prototype.addAddons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.public.subscription.Addon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.GetAddonsResponse} returns this
 */
proto.billing.public.subscription.GetAddonsResponse.prototype.clearAddonsList = function() {
  return this.setAddonsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.Addon.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.Addon.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.Addon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.Addon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.Addon.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 7, 0),
    quantityNext: (f = msg.getQuantityNext()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    addedOn: (f = msg.getAddedOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isForced: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    willBeDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    addOnPriceModelsList: jspb.Message.toObjectList(msg.getAddOnPriceModelsList(),
    proto.billing.public.subscription.AddonPriceModel.toObject, includeInstance),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.Addon}
 */
proto.billing.public.subscription.Addon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.Addon;
  return proto.billing.public.subscription.Addon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.Addon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.Addon}
 */
proto.billing.public.subscription.Addon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setQuantityNext(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAddedOn(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsForced(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWillBeDeleted(value);
      break;
    case 12:
      var value = /** @type {!proto.billing.public.subscription.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 13:
      var value = new proto.billing.public.subscription.AddonPriceModel;
      reader.readMessage(value,proto.billing.public.subscription.AddonPriceModel.deserializeBinaryFromReader);
      msg.addAddOnPriceModels(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.Addon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.Addon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.Addon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.Addon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getQuantityNext();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAddedOn();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsForced();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getWillBeDeleted();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getAddOnPriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.billing.public.subscription.AddonPriceModel.serializeBinaryToWriter
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.billing.public.subscription.Addon.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.billing.public.subscription.Addon.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.billing.public.subscription.Addon.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.billing.public.subscription.Addon.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string single_unit_name = 5;
 * @return {string}
 */
proto.billing.public.subscription.Addon.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string plural_unit_name = 6;
 * @return {string}
 */
proto.billing.public.subscription.Addon.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 quantity = 7;
 * @return {number}
 */
proto.billing.public.subscription.Addon.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.Int32Value quantity_next = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.public.subscription.Addon.prototype.getQuantityNext = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.public.subscription.Addon} returns this
*/
proto.billing.public.subscription.Addon.prototype.setQuantityNext = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.clearQuantityNext = function() {
  return this.setQuantityNext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.Addon.prototype.hasQuantityNext = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp added_on = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.public.subscription.Addon.prototype.getAddedOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.public.subscription.Addon} returns this
*/
proto.billing.public.subscription.Addon.prototype.setAddedOn = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.clearAddedOn = function() {
  return this.setAddedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.Addon.prototype.hasAddedOn = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool is_forced = 10;
 * @return {boolean}
 */
proto.billing.public.subscription.Addon.prototype.getIsForced = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.setIsForced = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool will_be_deleted = 11;
 * @return {boolean}
 */
proto.billing.public.subscription.Addon.prototype.getWillBeDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.setWillBeDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional PricingModelType pricing_model_type = 12;
 * @return {!proto.billing.public.subscription.PricingModelType}
 */
proto.billing.public.subscription.Addon.prototype.getPricingModelType = function() {
  return /** @type {!proto.billing.public.subscription.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.billing.public.subscription.PricingModelType} value
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * repeated AddonPriceModel add_on_price_models = 13;
 * @return {!Array<!proto.billing.public.subscription.AddonPriceModel>}
 */
proto.billing.public.subscription.Addon.prototype.getAddOnPriceModelsList = function() {
  return /** @type{!Array<!proto.billing.public.subscription.AddonPriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.subscription.AddonPriceModel, 13));
};


/**
 * @param {!Array<!proto.billing.public.subscription.AddonPriceModel>} value
 * @return {!proto.billing.public.subscription.Addon} returns this
*/
proto.billing.public.subscription.Addon.prototype.setAddOnPriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.billing.public.subscription.AddonPriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.AddonPriceModel}
 */
proto.billing.public.subscription.Addon.prototype.addAddOnPriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.billing.public.subscription.AddonPriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.clearAddOnPriceModelsList = function() {
  return this.setAddOnPriceModelsList([]);
};


/**
 * optional string image_url = 14;
 * @return {string}
 */
proto.billing.public.subscription.Addon.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Addon} returns this
 */
proto.billing.public.subscription.Addon.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.AddonPriceModel.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.AddonPriceModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.AddonPriceModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.AddonPriceModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    flatFeePrice: (f = msg.getFlatFeePrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    flatFeePriceFormatted: jspb.Message.getFieldWithDefault(msg, 6, ""),
    perUnitPrice: (f = msg.getPerUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perUnitPriceFormatted: jspb.Message.getFieldWithDefault(msg, 8, ""),
    currency: (f = msg.getCurrency()) && proto.billing.public.subscription.Currency.toObject(includeInstance, f),
    tiersList: jspb.Message.toObjectList(msg.getTiersList(),
    proto.billing.public.subscription.UnitTier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.AddonPriceModel}
 */
proto.billing.public.subscription.AddonPriceModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.AddonPriceModel;
  return proto.billing.public.subscription.AddonPriceModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.AddonPriceModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.AddonPriceModel}
 */
proto.billing.public.subscription.AddonPriceModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 4:
      var value = /** @type {!proto.billing.public.subscription.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setFlatFeePrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlatFeePriceFormatted(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPerUnitPrice(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPerUnitPriceFormatted(value);
      break;
    case 9:
      var value = new proto.billing.public.subscription.Currency;
      reader.readMessage(value,proto.billing.public.subscription.Currency.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 10:
      var value = new proto.billing.public.subscription.UnitTier;
      reader.readMessage(value,proto.billing.public.subscription.UnitTier.deserializeBinaryFromReader);
      msg.addTiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.AddonPriceModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.AddonPriceModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.AddonPriceModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFlatFeePrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getFlatFeePriceFormatted();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPerUnitPrice();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerUnitPriceFormatted();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.billing.public.subscription.Currency.serializeBinaryToWriter
    );
  }
  f = message.getTiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.billing.public.subscription.UnitTier.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
 */
proto.billing.public.subscription.AddonPriceModel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
 */
proto.billing.public.subscription.AddonPriceModel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 frequency = 3;
 * @return {number}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
 */
proto.billing.public.subscription.AddonPriceModel.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional FrequencyType frequency_type = 4;
 * @return {!proto.billing.public.subscription.FrequencyType}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.getFrequencyType = function() {
  return /** @type {!proto.billing.public.subscription.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.billing.public.subscription.FrequencyType} value
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
 */
proto.billing.public.subscription.AddonPriceModel.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Int32Value flat_fee_price = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.getFlatFeePrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
*/
proto.billing.public.subscription.AddonPriceModel.prototype.setFlatFeePrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
 */
proto.billing.public.subscription.AddonPriceModel.prototype.clearFlatFeePrice = function() {
  return this.setFlatFeePrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.hasFlatFeePrice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string flat_fee_price_formatted = 6;
 * @return {string}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.getFlatFeePriceFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
 */
proto.billing.public.subscription.AddonPriceModel.prototype.setFlatFeePriceFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Int32Value per_unit_price = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.getPerUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
*/
proto.billing.public.subscription.AddonPriceModel.prototype.setPerUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
 */
proto.billing.public.subscription.AddonPriceModel.prototype.clearPerUnitPrice = function() {
  return this.setPerUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.hasPerUnitPrice = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string per_unit_price_formatted = 8;
 * @return {string}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.getPerUnitPriceFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
 */
proto.billing.public.subscription.AddonPriceModel.prototype.setPerUnitPriceFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Currency currency = 9;
 * @return {?proto.billing.public.subscription.Currency}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.getCurrency = function() {
  return /** @type{?proto.billing.public.subscription.Currency} */ (
    jspb.Message.getWrapperField(this, proto.billing.public.subscription.Currency, 9));
};


/**
 * @param {?proto.billing.public.subscription.Currency|undefined} value
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
*/
proto.billing.public.subscription.AddonPriceModel.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
 */
proto.billing.public.subscription.AddonPriceModel.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated UnitTier tiers = 10;
 * @return {!Array<!proto.billing.public.subscription.UnitTier>}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.getTiersList = function() {
  return /** @type{!Array<!proto.billing.public.subscription.UnitTier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.subscription.UnitTier, 10));
};


/**
 * @param {!Array<!proto.billing.public.subscription.UnitTier>} value
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
*/
proto.billing.public.subscription.AddonPriceModel.prototype.setTiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.billing.public.subscription.UnitTier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.UnitTier}
 */
proto.billing.public.subscription.AddonPriceModel.prototype.addTiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.billing.public.subscription.UnitTier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.AddonPriceModel} returns this
 */
proto.billing.public.subscription.AddonPriceModel.prototype.clearTiersList = function() {
  return this.setTiersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.GetAllowancesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.GetAllowancesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.GetAllowancesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetAllowancesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.GetAllowancesRequest}
 */
proto.billing.public.subscription.GetAllowancesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.GetAllowancesRequest;
  return proto.billing.public.subscription.GetAllowancesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.GetAllowancesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.GetAllowancesRequest}
 */
proto.billing.public.subscription.GetAllowancesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.GetAllowancesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.GetAllowancesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.GetAllowancesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetAllowancesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.subscription.GetAllowancesRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetAllowancesRequest} returns this
 */
proto.billing.public.subscription.GetAllowancesRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.GetAllowancesRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetAllowancesRequest} returns this
 */
proto.billing.public.subscription.GetAllowancesRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.GetAllowancesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.GetAllowancesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.GetAllowancesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.GetAllowancesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetAllowancesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    allowancesList: jspb.Message.toObjectList(msg.getAllowancesList(),
    proto.billing.public.subscription.Allowance.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.GetAllowancesResponse}
 */
proto.billing.public.subscription.GetAllowancesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.GetAllowancesResponse;
  return proto.billing.public.subscription.GetAllowancesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.GetAllowancesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.GetAllowancesResponse}
 */
proto.billing.public.subscription.GetAllowancesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.public.subscription.Allowance;
      reader.readMessage(value,proto.billing.public.subscription.Allowance.deserializeBinaryFromReader);
      msg.addAllowances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.GetAllowancesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.GetAllowancesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.GetAllowancesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetAllowancesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllowancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.public.subscription.Allowance.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Allowance allowances = 1;
 * @return {!Array<!proto.billing.public.subscription.Allowance>}
 */
proto.billing.public.subscription.GetAllowancesResponse.prototype.getAllowancesList = function() {
  return /** @type{!Array<!proto.billing.public.subscription.Allowance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.subscription.Allowance, 1));
};


/**
 * @param {!Array<!proto.billing.public.subscription.Allowance>} value
 * @return {!proto.billing.public.subscription.GetAllowancesResponse} returns this
*/
proto.billing.public.subscription.GetAllowancesResponse.prototype.setAllowancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.public.subscription.Allowance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.Allowance}
 */
proto.billing.public.subscription.GetAllowancesResponse.prototype.addAllowances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.public.subscription.Allowance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.GetAllowancesResponse} returns this
 */
proto.billing.public.subscription.GetAllowancesResponse.prototype.clearAllowancesList = function() {
  return this.setAllowancesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.Allowance.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.Allowance.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.Allowance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.Allowance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.Allowance.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    singleUnitName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pluralUnitName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    addedOn: (f = msg.getAddedOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    willBeDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isForced: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    pricingModelType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    allowancePriceModelsList: jspb.Message.toObjectList(msg.getAllowancePriceModelsList(),
    proto.billing.public.subscription.AllowancePriceModel.toObject, includeInstance),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.Allowance}
 */
proto.billing.public.subscription.Allowance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.Allowance;
  return proto.billing.public.subscription.Allowance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.Allowance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.Allowance}
 */
proto.billing.public.subscription.Allowance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingleUnitName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPluralUnitName(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAddedOn(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWillBeDeleted(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsForced(value);
      break;
    case 11:
      var value = /** @type {!proto.billing.public.subscription.PricingModelType} */ (reader.readEnum());
      msg.setPricingModelType(value);
      break;
    case 12:
      var value = new proto.billing.public.subscription.AllowancePriceModel;
      reader.readMessage(value,proto.billing.public.subscription.AllowancePriceModel.deserializeBinaryFromReader);
      msg.addAllowancePriceModels(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.Allowance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.Allowance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.Allowance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.Allowance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSingleUnitName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPluralUnitName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddedOn();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getWillBeDeleted();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsForced();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPricingModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getAllowancePriceModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.billing.public.subscription.AllowancePriceModel.serializeBinaryToWriter
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.billing.public.subscription.Allowance.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.Allowance} returns this
 */
proto.billing.public.subscription.Allowance.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.billing.public.subscription.Allowance.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Allowance} returns this
 */
proto.billing.public.subscription.Allowance.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 quantity = 3;
 * @return {number}
 */
proto.billing.public.subscription.Allowance.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.Allowance} returns this
 */
proto.billing.public.subscription.Allowance.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.billing.public.subscription.Allowance.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Allowance} returns this
 */
proto.billing.public.subscription.Allowance.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.billing.public.subscription.Allowance.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Allowance} returns this
 */
proto.billing.public.subscription.Allowance.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string single_unit_name = 6;
 * @return {string}
 */
proto.billing.public.subscription.Allowance.prototype.getSingleUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Allowance} returns this
 */
proto.billing.public.subscription.Allowance.prototype.setSingleUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string plural_unit_name = 7;
 * @return {string}
 */
proto.billing.public.subscription.Allowance.prototype.getPluralUnitName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Allowance} returns this
 */
proto.billing.public.subscription.Allowance.prototype.setPluralUnitName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp added_on = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.public.subscription.Allowance.prototype.getAddedOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.public.subscription.Allowance} returns this
*/
proto.billing.public.subscription.Allowance.prototype.setAddedOn = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.Allowance} returns this
 */
proto.billing.public.subscription.Allowance.prototype.clearAddedOn = function() {
  return this.setAddedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.Allowance.prototype.hasAddedOn = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool will_be_deleted = 9;
 * @return {boolean}
 */
proto.billing.public.subscription.Allowance.prototype.getWillBeDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.Allowance} returns this
 */
proto.billing.public.subscription.Allowance.prototype.setWillBeDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_forced = 10;
 * @return {boolean}
 */
proto.billing.public.subscription.Allowance.prototype.getIsForced = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.Allowance} returns this
 */
proto.billing.public.subscription.Allowance.prototype.setIsForced = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional PricingModelType pricing_model_type = 11;
 * @return {!proto.billing.public.subscription.PricingModelType}
 */
proto.billing.public.subscription.Allowance.prototype.getPricingModelType = function() {
  return /** @type {!proto.billing.public.subscription.PricingModelType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.billing.public.subscription.PricingModelType} value
 * @return {!proto.billing.public.subscription.Allowance} returns this
 */
proto.billing.public.subscription.Allowance.prototype.setPricingModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * repeated AllowancePriceModel allowance_price_models = 12;
 * @return {!Array<!proto.billing.public.subscription.AllowancePriceModel>}
 */
proto.billing.public.subscription.Allowance.prototype.getAllowancePriceModelsList = function() {
  return /** @type{!Array<!proto.billing.public.subscription.AllowancePriceModel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.subscription.AllowancePriceModel, 12));
};


/**
 * @param {!Array<!proto.billing.public.subscription.AllowancePriceModel>} value
 * @return {!proto.billing.public.subscription.Allowance} returns this
*/
proto.billing.public.subscription.Allowance.prototype.setAllowancePriceModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.billing.public.subscription.AllowancePriceModel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.AllowancePriceModel}
 */
proto.billing.public.subscription.Allowance.prototype.addAllowancePriceModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.billing.public.subscription.AllowancePriceModel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.Allowance} returns this
 */
proto.billing.public.subscription.Allowance.prototype.clearAllowancePriceModelsList = function() {
  return this.setAllowancePriceModelsList([]);
};


/**
 * optional string image_url = 13;
 * @return {string}
 */
proto.billing.public.subscription.Allowance.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Allowance} returns this
 */
proto.billing.public.subscription.Allowance.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.AllowancePriceModel.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.AllowancePriceModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.AllowancePriceModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.AllowancePriceModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    includedUnits: (f = msg.getIncludedUnits()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perUnitPrice: jspb.Message.getFieldWithDefault(msg, 7, ""),
    perUnitPriceFormatted: jspb.Message.getFieldWithDefault(msg, 8, ""),
    currency: (f = msg.getCurrency()) && proto.billing.public.subscription.Currency.toObject(includeInstance, f),
    tiersList: jspb.Message.toObjectList(msg.getTiersList(),
    proto.billing.public.subscription.UnitTier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.AllowancePriceModel}
 */
proto.billing.public.subscription.AllowancePriceModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.AllowancePriceModel;
  return proto.billing.public.subscription.AllowancePriceModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.AllowancePriceModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.AllowancePriceModel}
 */
proto.billing.public.subscription.AllowancePriceModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 4:
      var value = /** @type {!proto.billing.public.subscription.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setIncludedUnits(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPerUnitPrice(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPerUnitPriceFormatted(value);
      break;
    case 9:
      var value = new proto.billing.public.subscription.Currency;
      reader.readMessage(value,proto.billing.public.subscription.Currency.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 10:
      var value = new proto.billing.public.subscription.UnitTier;
      reader.readMessage(value,proto.billing.public.subscription.UnitTier.deserializeBinaryFromReader);
      msg.addTiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.AllowancePriceModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.AllowancePriceModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.AllowancePriceModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getIncludedUnits();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerUnitPrice();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPerUnitPriceFormatted();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.billing.public.subscription.Currency.serializeBinaryToWriter
    );
  }
  f = message.getTiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.billing.public.subscription.UnitTier.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.AllowancePriceModel} returns this
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.AllowancePriceModel} returns this
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 frequency = 3;
 * @return {number}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.AllowancePriceModel} returns this
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional FrequencyType frequency_type = 4;
 * @return {!proto.billing.public.subscription.FrequencyType}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.getFrequencyType = function() {
  return /** @type {!proto.billing.public.subscription.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.billing.public.subscription.FrequencyType} value
 * @return {!proto.billing.public.subscription.AllowancePriceModel} returns this
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Int32Value included_units = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.getIncludedUnits = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.public.subscription.AllowancePriceModel} returns this
*/
proto.billing.public.subscription.AllowancePriceModel.prototype.setIncludedUnits = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.AllowancePriceModel} returns this
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.clearIncludedUnits = function() {
  return this.setIncludedUnits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.hasIncludedUnits = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string per_unit_price = 7;
 * @return {string}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.getPerUnitPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.AllowancePriceModel} returns this
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.setPerUnitPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string per_unit_price_formatted = 8;
 * @return {string}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.getPerUnitPriceFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.AllowancePriceModel} returns this
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.setPerUnitPriceFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Currency currency = 9;
 * @return {?proto.billing.public.subscription.Currency}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.getCurrency = function() {
  return /** @type{?proto.billing.public.subscription.Currency} */ (
    jspb.Message.getWrapperField(this, proto.billing.public.subscription.Currency, 9));
};


/**
 * @param {?proto.billing.public.subscription.Currency|undefined} value
 * @return {!proto.billing.public.subscription.AllowancePriceModel} returns this
*/
proto.billing.public.subscription.AllowancePriceModel.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.AllowancePriceModel} returns this
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated UnitTier tiers = 10;
 * @return {!Array<!proto.billing.public.subscription.UnitTier>}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.getTiersList = function() {
  return /** @type{!Array<!proto.billing.public.subscription.UnitTier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.subscription.UnitTier, 10));
};


/**
 * @param {!Array<!proto.billing.public.subscription.UnitTier>} value
 * @return {!proto.billing.public.subscription.AllowancePriceModel} returns this
*/
proto.billing.public.subscription.AllowancePriceModel.prototype.setTiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.billing.public.subscription.UnitTier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.UnitTier}
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.addTiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.billing.public.subscription.UnitTier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.AllowancePriceModel} returns this
 */
proto.billing.public.subscription.AllowancePriceModel.prototype.clearTiersList = function() {
  return this.setTiersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.Currency.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.Currency.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.Currency} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.Currency.toObject = function(includeInstance, msg) {
  var f, obj = {
    englishName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iso3Code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hasDecimal: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.Currency}
 */
proto.billing.public.subscription.Currency.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.Currency;
  return proto.billing.public.subscription.Currency.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.Currency} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.Currency}
 */
proto.billing.public.subscription.Currency.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnglishName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIso3Code(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDecimal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.Currency.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.Currency.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.Currency} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.Currency.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnglishName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIso3Code();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHasDecimal();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string english_name = 1;
 * @return {string}
 */
proto.billing.public.subscription.Currency.prototype.getEnglishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Currency} returns this
 */
proto.billing.public.subscription.Currency.prototype.setEnglishName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string iso_3_code = 2;
 * @return {string}
 */
proto.billing.public.subscription.Currency.prototype.getIso3Code = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Currency} returns this
 */
proto.billing.public.subscription.Currency.prototype.setIso3Code = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.billing.public.subscription.Currency.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.Currency} returns this
 */
proto.billing.public.subscription.Currency.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool has_decimal = 4;
 * @return {boolean}
 */
proto.billing.public.subscription.Currency.prototype.getHasDecimal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.Currency} returns this
 */
proto.billing.public.subscription.Currency.prototype.setHasDecimal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.UnitTier.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.UnitTier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.UnitTier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.UnitTier.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    finish: jspb.Message.getFieldWithDefault(msg, 2, 0),
    price: jspb.Message.getFieldWithDefault(msg, 3, 0),
    priceFormatted: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.UnitTier}
 */
proto.billing.public.subscription.UnitTier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.UnitTier;
  return proto.billing.public.subscription.UnitTier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.UnitTier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.UnitTier}
 */
proto.billing.public.subscription.UnitTier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinish(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceFormatted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.UnitTier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.UnitTier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.UnitTier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.UnitTier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFinish();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPriceFormatted();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 start = 1;
 * @return {number}
 */
proto.billing.public.subscription.UnitTier.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.UnitTier} returns this
 */
proto.billing.public.subscription.UnitTier.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 finish = 2;
 * @return {number}
 */
proto.billing.public.subscription.UnitTier.prototype.getFinish = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.UnitTier} returns this
 */
proto.billing.public.subscription.UnitTier.prototype.setFinish = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 price = 3;
 * @return {number}
 */
proto.billing.public.subscription.UnitTier.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.UnitTier} returns this
 */
proto.billing.public.subscription.UnitTier.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string price_formatted = 4;
 * @return {string}
 */
proto.billing.public.subscription.UnitTier.prototype.getPriceFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.UnitTier} returns this
 */
proto.billing.public.subscription.UnitTier.prototype.setPriceFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.UpdateSubscriptionAddonsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionAddonsList: jspb.Message.toObjectList(msg.getSubscriptionAddonsList(),
    proto.billing.public.subscription.SubscriptionAddon.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAddonsRequest}
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.UpdateSubscriptionAddonsRequest;
  return proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.UpdateSubscriptionAddonsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAddonsRequest}
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var value = new proto.billing.public.subscription.SubscriptionAddon;
      reader.readMessage(value,proto.billing.public.subscription.SubscriptionAddon.deserializeBinaryFromReader);
      msg.addSubscriptionAddons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.UpdateSubscriptionAddonsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionAddonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.billing.public.subscription.SubscriptionAddon.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAddonsRequest} returns this
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAddonsRequest} returns this
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SubscriptionAddon subscription_addons = 3;
 * @return {!Array<!proto.billing.public.subscription.SubscriptionAddon>}
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.prototype.getSubscriptionAddonsList = function() {
  return /** @type{!Array<!proto.billing.public.subscription.SubscriptionAddon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.subscription.SubscriptionAddon, 3));
};


/**
 * @param {!Array<!proto.billing.public.subscription.SubscriptionAddon>} value
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAddonsRequest} returns this
*/
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.prototype.setSubscriptionAddonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.billing.public.subscription.SubscriptionAddon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.SubscriptionAddon}
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.prototype.addSubscriptionAddons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.billing.public.subscription.SubscriptionAddon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAddonsRequest} returns this
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsRequest.prototype.clearSubscriptionAddonsList = function() {
  return this.setSubscriptionAddonsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.UpdateSubscriptionAddonsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.UpdateSubscriptionAddonsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAddonsResponse}
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.UpdateSubscriptionAddonsResponse;
  return proto.billing.public.subscription.UpdateSubscriptionAddonsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.UpdateSubscriptionAddonsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAddonsResponse}
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.UpdateSubscriptionAddonsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.UpdateSubscriptionAddonsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAddonsResponse} returns this
 */
proto.billing.public.subscription.UpdateSubscriptionAddonsResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionAllowancesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest}
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest;
  return proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest}
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSubscriptionAllowances(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionAllowancesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest} returns this
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest} returns this
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 subscription_allowances = 3;
 * @return {!Array<number>}
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.prototype.getSubscriptionAllowancesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest} returns this
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.prototype.setSubscriptionAllowancesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest} returns this
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.prototype.addSubscriptionAllowances = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest} returns this
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceRequest.prototype.clearSubscriptionAllowancesList = function() {
  return this.setSubscriptionAllowancesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse}
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse;
  return proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse}
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse} returns this
 */
proto.billing.public.subscription.UpdateSubscriptionAllowanceResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.SubscriptionAddon.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.SubscriptionAddon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.SubscriptionAddon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.SubscriptionAddon.toObject = function(includeInstance, msg) {
  var f, obj = {
    addonId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.SubscriptionAddon}
 */
proto.billing.public.subscription.SubscriptionAddon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.SubscriptionAddon;
  return proto.billing.public.subscription.SubscriptionAddon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.SubscriptionAddon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.SubscriptionAddon}
 */
proto.billing.public.subscription.SubscriptionAddon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAddonId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.SubscriptionAddon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.SubscriptionAddon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.SubscriptionAddon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.SubscriptionAddon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddonId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 addon_id = 1;
 * @return {number}
 */
proto.billing.public.subscription.SubscriptionAddon.prototype.getAddonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.SubscriptionAddon} returns this
 */
proto.billing.public.subscription.SubscriptionAddon.prototype.setAddonId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 quantity = 2;
 * @return {number}
 */
proto.billing.public.subscription.SubscriptionAddon.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.SubscriptionAddon} returns this
 */
proto.billing.public.subscription.SubscriptionAddon.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.CheckoutAddonsSimulationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.CheckoutAddonsSimulationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionAddonsList: jspb.Message.toObjectList(msg.getSubscriptionAddonsList(),
    proto.billing.public.subscription.SubscriptionAddon.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.CheckoutAddonsSimulationRequest}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.CheckoutAddonsSimulationRequest;
  return proto.billing.public.subscription.CheckoutAddonsSimulationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.CheckoutAddonsSimulationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.CheckoutAddonsSimulationRequest}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var value = new proto.billing.public.subscription.SubscriptionAddon;
      reader.readMessage(value,proto.billing.public.subscription.SubscriptionAddon.deserializeBinaryFromReader);
      msg.addSubscriptionAddons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.CheckoutAddonsSimulationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.CheckoutAddonsSimulationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionAddonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.billing.public.subscription.SubscriptionAddon.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.CheckoutAddonsSimulationRequest} returns this
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.CheckoutAddonsSimulationRequest} returns this
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SubscriptionAddon subscription_addons = 3;
 * @return {!Array<!proto.billing.public.subscription.SubscriptionAddon>}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.prototype.getSubscriptionAddonsList = function() {
  return /** @type{!Array<!proto.billing.public.subscription.SubscriptionAddon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.subscription.SubscriptionAddon, 3));
};


/**
 * @param {!Array<!proto.billing.public.subscription.SubscriptionAddon>} value
 * @return {!proto.billing.public.subscription.CheckoutAddonsSimulationRequest} returns this
*/
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.prototype.setSubscriptionAddonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.billing.public.subscription.SubscriptionAddon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.SubscriptionAddon}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.prototype.addSubscriptionAddons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.billing.public.subscription.SubscriptionAddon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.CheckoutAddonsSimulationRequest} returns this
 */
proto.billing.public.subscription.CheckoutAddonsSimulationRequest.prototype.clearSubscriptionAddonsList = function() {
  return this.setSubscriptionAddonsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.CheckoutAddonsSimulationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.CheckoutAddonsSimulationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    priceSimulationsList: jspb.Message.toObjectList(msg.getPriceSimulationsList(),
    proto.billing.public.subscription.AddonPriceSimulation.toObject, includeInstance),
    totalAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalAmountFormatted: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.CheckoutAddonsSimulationResponse}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.CheckoutAddonsSimulationResponse;
  return proto.billing.public.subscription.CheckoutAddonsSimulationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.CheckoutAddonsSimulationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.CheckoutAddonsSimulationResponse}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.public.subscription.AddonPriceSimulation;
      reader.readMessage(value,proto.billing.public.subscription.AddonPriceSimulation.deserializeBinaryFromReader);
      msg.addPriceSimulations(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalAmountFormatted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.CheckoutAddonsSimulationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.CheckoutAddonsSimulationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPriceSimulationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.public.subscription.AddonPriceSimulation.serializeBinaryToWriter
    );
  }
  f = message.getTotalAmount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalAmountFormatted();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated AddonPriceSimulation price_simulations = 1;
 * @return {!Array<!proto.billing.public.subscription.AddonPriceSimulation>}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.prototype.getPriceSimulationsList = function() {
  return /** @type{!Array<!proto.billing.public.subscription.AddonPriceSimulation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.subscription.AddonPriceSimulation, 1));
};


/**
 * @param {!Array<!proto.billing.public.subscription.AddonPriceSimulation>} value
 * @return {!proto.billing.public.subscription.CheckoutAddonsSimulationResponse} returns this
*/
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.prototype.setPriceSimulationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.public.subscription.AddonPriceSimulation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.AddonPriceSimulation}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.prototype.addPriceSimulations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.public.subscription.AddonPriceSimulation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.CheckoutAddonsSimulationResponse} returns this
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.prototype.clearPriceSimulationsList = function() {
  return this.setPriceSimulationsList([]);
};


/**
 * optional int32 total_amount = 2;
 * @return {number}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.CheckoutAddonsSimulationResponse} returns this
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string total_amount_formatted = 3;
 * @return {string}
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.prototype.getTotalAmountFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.CheckoutAddonsSimulationResponse} returns this
 */
proto.billing.public.subscription.CheckoutAddonsSimulationResponse.prototype.setTotalAmountFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.AddonPriceSimulation.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.AddonPriceSimulation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.AddonPriceSimulation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.AddonPriceSimulation.toObject = function(includeInstance, msg) {
  var f, obj = {
    addonId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amountFormatted: jspb.Message.getFieldWithDefault(msg, 3, ""),
    regularAmount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    regularAmountFormatted: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.AddonPriceSimulation}
 */
proto.billing.public.subscription.AddonPriceSimulation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.AddonPriceSimulation;
  return proto.billing.public.subscription.AddonPriceSimulation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.AddonPriceSimulation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.AddonPriceSimulation}
 */
proto.billing.public.subscription.AddonPriceSimulation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAddonId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountFormatted(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRegularAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegularAmountFormatted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.AddonPriceSimulation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.AddonPriceSimulation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.AddonPriceSimulation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.AddonPriceSimulation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddonId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAmountFormatted();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegularAmount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRegularAmountFormatted();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 addon_id = 1;
 * @return {number}
 */
proto.billing.public.subscription.AddonPriceSimulation.prototype.getAddonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.AddonPriceSimulation} returns this
 */
proto.billing.public.subscription.AddonPriceSimulation.prototype.setAddonId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 amount = 2;
 * @return {number}
 */
proto.billing.public.subscription.AddonPriceSimulation.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.AddonPriceSimulation} returns this
 */
proto.billing.public.subscription.AddonPriceSimulation.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string amount_formatted = 3;
 * @return {string}
 */
proto.billing.public.subscription.AddonPriceSimulation.prototype.getAmountFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.AddonPriceSimulation} returns this
 */
proto.billing.public.subscription.AddonPriceSimulation.prototype.setAmountFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 regular_amount = 4;
 * @return {number}
 */
proto.billing.public.subscription.AddonPriceSimulation.prototype.getRegularAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.AddonPriceSimulation} returns this
 */
proto.billing.public.subscription.AddonPriceSimulation.prototype.setRegularAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string regular_amount_formatted = 5;
 * @return {string}
 */
proto.billing.public.subscription.AddonPriceSimulation.prototype.getRegularAmountFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.AddonPriceSimulation} returns this
 */
proto.billing.public.subscription.AddonPriceSimulation.prototype.setRegularAmountFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.CheckoutAllowancesSimulationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionAllowancesIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.CheckoutAllowancesSimulationRequest}
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.CheckoutAllowancesSimulationRequest;
  return proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.CheckoutAllowancesSimulationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.CheckoutAllowancesSimulationRequest}
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSubscriptionAllowancesIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.CheckoutAllowancesSimulationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionAllowancesIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.CheckoutAllowancesSimulationRequest} returns this
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.CheckoutAllowancesSimulationRequest} returns this
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 subscription_allowances_ids = 3;
 * @return {!Array<number>}
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.prototype.getSubscriptionAllowancesIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.billing.public.subscription.CheckoutAllowancesSimulationRequest} returns this
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.prototype.setSubscriptionAllowancesIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.CheckoutAllowancesSimulationRequest} returns this
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.prototype.addSubscriptionAllowancesIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.CheckoutAllowancesSimulationRequest} returns this
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationRequest.prototype.clearSubscriptionAllowancesIdsList = function() {
  return this.setSubscriptionAllowancesIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.CheckoutAllowancesSimulationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    priceSimulationsList: jspb.Message.toObjectList(msg.getPriceSimulationsList(),
    proto.billing.public.subscription.AllowancePriceSimulation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.CheckoutAllowancesSimulationResponse}
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.CheckoutAllowancesSimulationResponse;
  return proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.CheckoutAllowancesSimulationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.CheckoutAllowancesSimulationResponse}
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.public.subscription.AllowancePriceSimulation;
      reader.readMessage(value,proto.billing.public.subscription.AllowancePriceSimulation.deserializeBinaryFromReader);
      msg.addPriceSimulations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.CheckoutAllowancesSimulationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPriceSimulationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.public.subscription.AllowancePriceSimulation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AllowancePriceSimulation price_simulations = 1;
 * @return {!Array<!proto.billing.public.subscription.AllowancePriceSimulation>}
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.prototype.getPriceSimulationsList = function() {
  return /** @type{!Array<!proto.billing.public.subscription.AllowancePriceSimulation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.subscription.AllowancePriceSimulation, 1));
};


/**
 * @param {!Array<!proto.billing.public.subscription.AllowancePriceSimulation>} value
 * @return {!proto.billing.public.subscription.CheckoutAllowancesSimulationResponse} returns this
*/
proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.prototype.setPriceSimulationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.public.subscription.AllowancePriceSimulation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.AllowancePriceSimulation}
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.prototype.addPriceSimulations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.public.subscription.AllowancePriceSimulation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.CheckoutAllowancesSimulationResponse} returns this
 */
proto.billing.public.subscription.CheckoutAllowancesSimulationResponse.prototype.clearPriceSimulationsList = function() {
  return this.setPriceSimulationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.AllowancePriceSimulation.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.AllowancePriceSimulation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.AllowancePriceSimulation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.AllowancePriceSimulation.toObject = function(includeInstance, msg) {
  var f, obj = {
    allowanceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    regularIncludedUnits: (f = msg.getRegularIncludedUnits()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    proratedIncludedUnits: (f = msg.getProratedIncludedUnits()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.AllowancePriceSimulation}
 */
proto.billing.public.subscription.AllowancePriceSimulation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.AllowancePriceSimulation;
  return proto.billing.public.subscription.AllowancePriceSimulation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.AllowancePriceSimulation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.AllowancePriceSimulation}
 */
proto.billing.public.subscription.AllowancePriceSimulation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAllowanceId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setRegularIncludedUnits(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setProratedIncludedUnits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.AllowancePriceSimulation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.AllowancePriceSimulation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.AllowancePriceSimulation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.AllowancePriceSimulation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllowanceId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRegularIncludedUnits();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getProratedIncludedUnits();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 allowance_id = 1;
 * @return {number}
 */
proto.billing.public.subscription.AllowancePriceSimulation.prototype.getAllowanceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.AllowancePriceSimulation} returns this
 */
proto.billing.public.subscription.AllowancePriceSimulation.prototype.setAllowanceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value regular_included_units = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.public.subscription.AllowancePriceSimulation.prototype.getRegularIncludedUnits = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.public.subscription.AllowancePriceSimulation} returns this
*/
proto.billing.public.subscription.AllowancePriceSimulation.prototype.setRegularIncludedUnits = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.AllowancePriceSimulation} returns this
 */
proto.billing.public.subscription.AllowancePriceSimulation.prototype.clearRegularIncludedUnits = function() {
  return this.setRegularIncludedUnits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.AllowancePriceSimulation.prototype.hasRegularIncludedUnits = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value prorated_included_units = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.public.subscription.AllowancePriceSimulation.prototype.getProratedIncludedUnits = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.public.subscription.AllowancePriceSimulation} returns this
*/
proto.billing.public.subscription.AllowancePriceSimulation.prototype.setProratedIncludedUnits = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.AllowancePriceSimulation} returns this
 */
proto.billing.public.subscription.AllowancePriceSimulation.prototype.clearProratedIncludedUnits = function() {
  return this.setProratedIncludedUnits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.AllowancePriceSimulation.prototype.hasProratedIncludedUnits = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.CancelSubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.CancelSubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.CancelSubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.CancelSubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.CancelSubscriptionRequest}
 */
proto.billing.public.subscription.CancelSubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.CancelSubscriptionRequest;
  return proto.billing.public.subscription.CancelSubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.CancelSubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.CancelSubscriptionRequest}
 */
proto.billing.public.subscription.CancelSubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.CancelSubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.CancelSubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.CancelSubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.CancelSubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.subscription.CancelSubscriptionRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.CancelSubscriptionRequest} returns this
 */
proto.billing.public.subscription.CancelSubscriptionRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.CancelSubscriptionRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.CancelSubscriptionRequest} returns this
 */
proto.billing.public.subscription.CancelSubscriptionRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_unique_id = 3;
 * @return {string}
 */
proto.billing.public.subscription.CancelSubscriptionRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.CancelSubscriptionRequest} returns this
 */
proto.billing.public.subscription.CancelSubscriptionRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.CancelSubscriptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.CancelSubscriptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.CancelSubscriptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.CancelSubscriptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.CancelSubscriptionResponse}
 */
proto.billing.public.subscription.CancelSubscriptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.CancelSubscriptionResponse;
  return proto.billing.public.subscription.CancelSubscriptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.CancelSubscriptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.CancelSubscriptionResponse}
 */
proto.billing.public.subscription.CancelSubscriptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.CancelSubscriptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.CancelSubscriptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.CancelSubscriptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.CancelSubscriptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.GetCancellationDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.GetCancellationDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.GetCancellationDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetCancellationDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.GetCancellationDetailsRequest}
 */
proto.billing.public.subscription.GetCancellationDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.GetCancellationDetailsRequest;
  return proto.billing.public.subscription.GetCancellationDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.GetCancellationDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.GetCancellationDetailsRequest}
 */
proto.billing.public.subscription.GetCancellationDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.GetCancellationDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.GetCancellationDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.GetCancellationDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetCancellationDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.subscription.GetCancellationDetailsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetCancellationDetailsRequest} returns this
 */
proto.billing.public.subscription.GetCancellationDetailsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.GetCancellationDetailsRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetCancellationDetailsRequest} returns this
 */
proto.billing.public.subscription.GetCancellationDetailsRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_unique_id = 3;
 * @return {string}
 */
proto.billing.public.subscription.GetCancellationDetailsRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetCancellationDetailsRequest} returns this
 */
proto.billing.public.subscription.GetCancellationDetailsRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.GetCancellationDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.GetCancellationDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    refundAmount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    refundAmountFormatted: jspb.Message.getFieldWithDefault(msg, 2, ""),
    billingCurrency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    unusedTimeStartDate: (f = msg.getUnusedTimeStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    unusedTimeEndDate: (f = msg.getUnusedTimeEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    cardType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cardLastFourDigits: jspb.Message.getFieldWithDefault(msg, 7, ""),
    subscriptionIsInFreeTrial: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.GetCancellationDetailsResponse}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.GetCancellationDetailsResponse;
  return proto.billing.public.subscription.GetCancellationDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.GetCancellationDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.GetCancellationDetailsResponse}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRefundAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefundAmountFormatted(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingCurrency(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUnusedTimeStartDate(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUnusedTimeEndDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardLastFourDigits(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscriptionIsInFreeTrial(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.GetCancellationDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.GetCancellationDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRefundAmount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRefundAmountFormatted();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBillingCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnusedTimeStartDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUnusedTimeEndDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCardType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCardLastFourDigits();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSubscriptionIsInFreeTrial();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional int32 refund_amount = 1;
 * @return {number}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.getRefundAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.GetCancellationDetailsResponse} returns this
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.setRefundAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string refund_amount_formatted = 2;
 * @return {string}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.getRefundAmountFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetCancellationDetailsResponse} returns this
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.setRefundAmountFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string billing_currency = 3;
 * @return {string}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.getBillingCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetCancellationDetailsResponse} returns this
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.setBillingCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp unused_time_start_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.getUnusedTimeStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.public.subscription.GetCancellationDetailsResponse} returns this
*/
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.setUnusedTimeStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.GetCancellationDetailsResponse} returns this
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.clearUnusedTimeStartDate = function() {
  return this.setUnusedTimeStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.hasUnusedTimeStartDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp unused_time_end_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.getUnusedTimeEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.public.subscription.GetCancellationDetailsResponse} returns this
*/
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.setUnusedTimeEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.GetCancellationDetailsResponse} returns this
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.clearUnusedTimeEndDate = function() {
  return this.setUnusedTimeEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.hasUnusedTimeEndDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string card_type = 6;
 * @return {string}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.getCardType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetCancellationDetailsResponse} returns this
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.setCardType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string card_last_four_digits = 7;
 * @return {string}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.getCardLastFourDigits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.GetCancellationDetailsResponse} returns this
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.setCardLastFourDigits = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool subscription_is_in_free_trial = 8;
 * @return {boolean}
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.getSubscriptionIsInFreeTrial = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.GetCancellationDetailsResponse} returns this
 */
proto.billing.public.subscription.GetCancellationDetailsResponse.prototype.setSubscriptionIsInFreeTrial = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * @enum {number}
 */
proto.billing.public.subscription.FrequencyType = {
  UNSPECIFIED_FT: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  YEARLY: 4
};

/**
 * @enum {number}
 */
proto.billing.public.subscription.PricingModelType = {
  UNSPECIFIED_PMT: 0,
  FLATFEE: 1,
  PERUNIT: 2,
  RESERVED: 3,
  TIERED: 4,
  VOLUME: 5,
  RANGED: 6,
  CAPPED: 7
};

goog.object.extend(exports, proto.billing.public.subscription);
