import React, { ReactNode } from "react";
import "./Panel.scss";
import Title from "../title/Title";
import { isMobileOnly } from "react-device-detect";

export interface IPanel {
  id: string,
  title?: string,
  shouldTranslateTitle?: boolean,
  className?: string,
  style?: any,
  type?: "basic" | "grey"
  children: ReactNode | Array<ReactNode>,
  noMargin?: boolean,
  onClick?: () => void
  isClickable?: boolean,
  isSelected?: boolean,
  image?: string,
  imageLayout?: "dynamic" | "box" 
}

const Panel: React.FC<IPanel> = React.memo(({ id, title = "", imageLayout = "dynamic", shouldTranslateTitle = true, className = "", type = "basic", children, style = {}, noMargin = false,
  onClick, isClickable = false, isSelected = false, image }) => {

  const renderContent = () => {
    if (image) {
      return (
        <div className={`panel__content ${isSelected && isMobileOnly ? "panel__content--selected" : ""}  ${imageLayout === "box" && isMobileOnly ? "panel__content--align-start" : ""}`}>
          <div className={`panel__content__image panel__content__image--${imageLayout}`} style={{
            background: `#FFF url(${image}) no-repeat center center`
          }}></div>
          { title && <Title className="panel__title" content={title} shouldTranslate={shouldTranslateTitle} />}
          <div className={`panel__content__children ${imageLayout === "box" ? "panel__content__children--align-center" : ""}`}>{children}</div>
        </div>
      )
    }
    return (
      <>
        { title && <Title className="panel__title" content={title} shouldTranslate={shouldTranslateTitle} />}
        { children}
      </>
    )
  }

  return (
    <section
      id={id}
      className={`panel panel--${type} ${noMargin ? "panel--nomargin" : ""} ${isClickable ? "panel--clikable" : ""} ${(isSelected) ? "panel--selected" : ""} ${image? "panel--has-image" : ""} ${className}`}
      style={style}
      onClick={onClick}>
      {renderContent()}
    </section>
  )
})

export default Panel;
