import moment from "moment";

export const SPREEDLY_TEST_CREDIT_CARD = "4111111111111111";
export const SPREEDLY_TEST_CVV = "123";
export const SPREEDLY_TEST_EXP_MONTH = 12;
export const SPREEDLY_TEST_EXP_YEAR = moment.utc().add(3, "years").year();
export const SPREEDLY_TEST_CARD_NAME = "Sandbox Test Card"

export const MAX_SINGLE_LINE_INPUT = 99;
export const MAX_MULTI_LINE_INPUT = 250;

export const GOOGLE_RECAPTCHA_TOKEN = "google-recaptcha-token"

/**
 * internal mapping from spreedly errors to user errors
 * https://docs.spreedly.com/reference/iframe/v1/#errors
 */
export const SPREEDLY_ERRORS: { [key: string]: string } = {
  "errors.account_inactive": "SPREEDLY_ERROR_GENERIC",
  "errors.environment_key_parameter_required": "SPREEDLY_ERROR_GENERIC",
  "errors.invalid_environment_key_parameter": "SPREEDLY_ERROR_GENERIC",
  "errors.blank": "SPREEDLY_ERROR_CARD_INVALID",
  "errors.invalid": "SPREEDLY_ERROR_CARD_INVALID",
  "errors.blank_card_type": "SPREEDLY_ERROR_CARD_TYPE_INVALID",
  "errors.expired": "SPREEDLY_ERROR_CARD_EXPIRED",
  "errors.unknown_referrer": "SPREEDLY_ERROR_GENERIC",
  "errors.invalid_referrer": "SPREEDLY_ERROR_GENERIC",
  "errors.configuration": "SPREEDLY_ERROR_GENERIC"
}

export enum RecaptchaActions {
  CHECKOUT_OPEN = "checkout_open",
  CHECKOUT_PRELIVE = "checkout_prelive",
  CHECKOUT = "checkout",
}

export const europeanCountriesCode = ["AUT", "BEL", "BGR", "HRV", "CYP", "CZE", "DNK", "EST", "FIN", "FRA",
  "DEU", "GRC", "HUN", "IRL", "ITA", "LVA", "LTU", "LUX", "MLT", "NLD",
  "POL", "PRT", "ROU", "SVK", "SVN", "ESP", "SWE", "GBR"]
