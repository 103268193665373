import React from "react";
import ManageAllowanceList from "../../components/allowances/manage-allowance-list/ManageAllowanceList";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { fetchSubscriptionAllowances } from "../../actions/addOnActions";
import PageWrapper from "../page-wrapper/PageWrapper";
import SystemInfo from "../../components/ui/system-info/SystemInfo";
import { useFetch } from "../../utils/custom-hooks";
import LoadingAnimation from "../../components/ui/loading-animation/LoadingAnimation";

const ManageAllowance: React.FC = () => {
  const { companyDomain, checkoutConfig } = useSelector((state: AppState) => state.globalReducer);
  const subscriptionUniqueId = useSelector((state: AppState) => state.personalDetailsReducer.mainProps.subscriptionUniqueId);
  const isFetchSubscriptionAllowancesFailure = useSelector((state: AppState) => state.addOnReducer.isFetchSubscriptionAllowancesFailure);
  const { currentPlan, currentSubscription } = useSelector((state: AppState) => state.subscriptionReducer);

  const dispatch = useDispatch<Function>();

  const { isLoading } = useFetch(() => dispatch(fetchSubscriptionAllowances(companyDomain, subscriptionUniqueId as string)))

  if (isFetchSubscriptionAllowancesFailure) {
    return (
      <PageWrapper btnVisible={false}>
        <SystemInfo
          title="MANAGE_ALLOWANCE_FETCH_ALLOWANCES_FAILURE"
          translateWith={{ allowanceLabel: checkoutConfig?.terminologyAllowancePlural }}
          isShowIcon={false}
          type="warning"
        />
      </PageWrapper>
    )
  }

  if (currentPlan && !currentPlan.allowances.length) {
    return (
      <PageWrapper btnVisible={false}>
        <SystemInfo
          title="MANAGE_ALLOWANCE_NO_ALLOWANCES_AVAILABLE"
          translateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular, allowanceLabel: checkoutConfig?.terminologyAllowancePlural }}
          isShowIcon={false}
          type="warning"
        />
      </PageWrapper>
    )
  }

  if (isLoading || !currentPlan || !currentSubscription) {
    return <LoadingAnimation />
  }

  return <ManageAllowanceList />
}

export default ManageAllowance;