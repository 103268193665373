import { apiGet, apiPost, apiPut, apiDelete } from "./apiUtils";
import { ISubscription, ISubscriptionACH, ISubscriptionExistingCustomer, ISubscriptionExistingCustomerSCA, ISubscriptionSCA } from "../models/Subscription";
import { IChangePlanBody } from "../models/ChangePlan";
import { PersonalDetails } from "../models/PersonalDetails";
import { ICardDetailInputDetail, ICardDetailInputDetailSCA } from "../models/PaymentDetail";
import { IAddress } from "../models/Address";

class API {
  static getProducts(companyDomain: string) {
    return apiGet(`api/v1/checkout/${companyDomain}/products`);
  }

  static getProductById(companyDomain: string, productId: number) {
    return apiGet(`api/v1/checkout/${companyDomain}/products/${productId}`);
  }

  static getPlans(companyDomain: string, productId: number) {
    return apiGet(`api/v1/checkout/${companyDomain}/products/${productId}/plans`);
  }

  static getCurrentPlan(companyDomain: string, productId: number, planId: number, subscriptionUniqueId: string) {
    return apiGet(`api/v1/checkout/${companyDomain}/products/${productId}/plans/${planId}?subscriptionUniqueId=${subscriptionUniqueId}`);
  }

  static getCustomFields(companyDomain: string, productId: number) {
    return apiGet(`api/v1/checkout/${companyDomain}/products/${productId}/customfields`);
  }

  static createSubscription(companyDomain: string, subscription: ISubscription) {
    return apiPost(`api/v1/${companyDomain}/subscriptions`, subscription);
  }

  static createSubscriptionExistingCustomer(companyDomain: string, customerUniqueId: string, subscription: ISubscriptionExistingCustomer) {
    return apiPost(`api/v1/${companyDomain}/customers/${customerUniqueId}/subscriptions`, subscription);
  }

  static createSubscriptionSCA(companyDomain: string, subscription: ISubscriptionSCA | ISubscriptionACH) {
    return apiPost(`api/v1/${companyDomain}/subscriptions/sca`, subscription);
    // fallback 3ds1 token: 2jV9pZSdhQAzqpRrxG2voeMMErp
    
    /*return Promise.resolve({
      customerUniqueId: "c123",
      subscriptionUniqueId: "s1234",
      paymentStatus: PaymentStatus.Pending,
      transactionToken: "RMFQc7gYCuvghu06cYzkusSjhmI"
    })*/
  }

  static createSubscriptionExistingCustomerSCA(companyDomain: string, customerUniqueId: string, subscription: ISubscriptionExistingCustomerSCA) {
    return apiPost(`api/v1/${companyDomain}/customers/${customerUniqueId}/subscriptions/sca`, subscription);
  }

  static getCountries() {
    return apiGet("api/v1/countries");
  }

  static getProductCountries(companyDomain: string, productId: number) {
    return apiGet(`api/v1/checkout/${companyDomain}/products/${productId}/countries`);
  }

  static fetchCustomerBranding(companyDomain: string) {
    return apiGet(`api/v1/checkout/${companyDomain}/company/branding`);
  }

  static getCustomerSubscriptions(companyDomain: string, customerUniqueId: string) {
    return apiGet(`api/v1/checkout/${companyDomain}/subscriptions?customerUniqueId=${customerUniqueId}`);
  }

  static getCustomerSubscriptionDetails(companyDomain: string, subscriptionUniqueId: string) {
    return apiGet(`api/v1/checkout/${companyDomain}/subscriptions/${subscriptionUniqueId}/customer`);
  }

  static removeCustomerSubscription(companyDomain: string, customerUniqueId: string, subscriptionUniqueId: string) {
    return apiDelete(`api/v1/checkout/${companyDomain}/subscriptions/${subscriptionUniqueId}?customerUniqueId=${customerUniqueId}`);
  }

  static updatePaymentDetails(companyDomain: string, customerUniqueId: string, inputModel: ICardDetailInputDetail) {
    return apiPut(`api/v1/checkout/${companyDomain}/customers/${customerUniqueId}`, inputModel);
  }

  static updatePaymentDetailsSCA(companyDomain: string, customerUniqueId: string, inputModel: ICardDetailInputDetailSCA) {
    return apiPut(`api/v1/checkout/${companyDomain}/customers/${customerUniqueId}/sca`, inputModel);
  }

  static changePlan(companyDomain: string, subscriptionUniqueId: string, changePlanBody: IChangePlanBody) {
    return apiPut(`api/v1/checkout/${companyDomain}/subscriptions/${subscriptionUniqueId}`, changePlanBody);
  }

  static fetchPersonalDetails(companyDomain: string, customerUniqueId: string) {
    return apiGet(`api/v1/checkout/${companyDomain}/customers/${customerUniqueId}`);
  }

  static updatePersonalDetails(companyDomain: string, customerUniqueId: string, personalDetailsData: Partial<PersonalDetails>) {
    return apiPut(`api/v1/checkout/${companyDomain}/customers/details/${customerUniqueId}`, personalDetailsData);
  }

  static fetchShippingAddress(companyDomain: string, subscriptionUniqueId: string) {
    return apiGet(`api/v1/checkout/${companyDomain}/subscriptions/${subscriptionUniqueId}/shippingAddress`);
  }

  static updateShippingAddress(companyDomain: string, subscriptionUniqueId: string, payload: IAddress) {
    return apiPut(`api/v1/checkout/${companyDomain}/subscriptions/${subscriptionUniqueId}/shippingAddress`, payload);
  }

  static fetchCheckoutConfig(companyDomain: string) {
    return apiGet(`api/v1/checkout/${companyDomain}/company/checkout/config`);
  }
}

export default API;