import React from "react";
import { ITier } from "../../../models/Product";
import Text from "../../ui/text/Text";
import { ICheckoutConfig } from "../../../models/BillsbyData";

interface Props {
  tier: ITier,
  checkoutConfig: ICheckoutConfig,
  frequency: string,
  unit: string,
  units: string
}

const VolumeCylePricingInfo = (props: Props) => {
  const { tier, checkoutConfig, frequency, unit, units } = props;

  const getLabel = () => {
    const min = 1;
    const max = 1000000;

    if (tier.start === min && tier.finish < max) {
      return (
        <Text
          content="SELECT_PLAN_TIERED_CYCLE_UP_TO"
          translateWith={{ finish: tier.finish, units: units }}
          className="cycle-price-info__tiered-price__title"
          noMargin
        />
      )
    }
    if (tier.start > min && tier.finish < max) {
      return (
        <Text
          content="SELECT_PLAN_TIERED_CYCLE_BETWEEN"
          translateWith={{ start: tier.start, finish: tier.finish, units: units }}
          className="cycle-price-info__tiered-price__title"
          noMargin
        />
      )
    }
    if (tier.finish === max) {
      return (
        <Text
          content="SELECT_PLAN_TIERED_CYCLE_AND_UP"
          translateWith={{ start: tier.start, units: tier.start === 1 ? unit : units }}
          className="cycle-price-info__tiered-price__title"
          noMargin
        />
      )
    }
    return null;
  }

  const getDescription = () => {
    const priceFormatted = tier.priceFormatted ? tier.priceFormatted : tier.price / 100;
    return (
      <Text
        className="cycle-price-info__plan-info"
        content="SELECT_PLAN_TIERED_CYCLE_VOLUME_MORE_INFO_DESCRIPTION"
        translateWith={{
          priceFormatted,
          frequency,
          units,
          unit
        }}
        noMargin
      />
    )
  }

  return (
    <>
      {getLabel()}
      {getDescription()}
    </>
  )
}

export default VolumeCylePricingInfo
