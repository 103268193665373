import { makeActionCreator } from "../utils/reduxUtils";
import { AppState } from "..";
import queryString from "query-string";
import { setPlanId, setProductId, setCycleId, setNumberOfUnits } from "./selectPlanActions";
import { IBillsbyData, BillsbyDataParams, BillsbyDataAction, BillsbyDataType } from "../models/BillsbyData";
import { setFirstName, setLastName, setEmail, setAddressLine1, setAddressLine2, setCity, setState, setZipCode, setCountry, setField, setCustomerUniqueId, setSubscriptionUniqueId, setIsUserDataScreenPreloaded, setIsAddressScreenPreloaded, setCompanyName } from "./personalDetailsActions";
import { ICountry } from "../models/Country";
import { EMAIL_REGEX } from "../utils/commonUtils";
import API from "../utils/API";
import { ICountryState } from "../models/CountryState";
import { AccountManagementPermissions as AccountManagementPermissions } from "../models/AccountManagement";
import { setPreloadedAddOns, setPreloadedAllowances } from "./addOnActions";
import { getHasCustomerCardOnFile } from "./paymentActions";
import { supportedLanguages } from "../utils/languages/registerTranslations";
import { ConfigConstants } from "../utils/config";
import { CustomerServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/CustomerServiceClientPb";
import { GetCustomerLanguageRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/customer_pb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { setAchField } from "./achActions";

export const SET_COMPANY_DOMAIN = "SET_COMPANY_DOMAIN";
export const setCompanyDomain = makeActionCreator(SET_COMPANY_DOMAIN, "payload");
export const SET_NAVIGATION_STEP = "SET_NAVIGATION_STEP";
export const setNavigationStep = makeActionCreator(SET_NAVIGATION_STEP, "payload");
export const SET_IS_CLOSE_ICON_VISIBLE = "SET_IS_CLOSE_ICON_VISIBLE";
export const setIsCloseIconVisible = makeActionCreator(SET_IS_CLOSE_ICON_VISIBLE, "payload");
export const SET_ACCOUNT_MANAGEMENT_PERMISSIONS = "SET_ACCOUNT_MANAGEMENT_PERMISSIONS";
export const setAccountManagementPermissions = makeActionCreator(SET_ACCOUNT_MANAGEMENT_PERMISSIONS, "payload");
export const SET_PRELOADED_CUSTOMER_DATA = "SET_PRELOADED_CUSTOMER_DATA";
export const setPreloadedCustomerData = makeActionCreator(SET_PRELOADED_CUSTOMER_DATA, "payload");

export const SET_BILLSBY_ACTION = "SET_BILLSBY_ACTION";
export const setBillsbyAction = makeActionCreator(SET_BILLSBY_ACTION, "payload");

export const SET_EMBED_CODE_LANGUAGE = "SET_EMBED_CODE_LANGUAGE";
export const setEmbedCodeLanguage = makeActionCreator(SET_EMBED_CODE_LANGUAGE, "payload");

export const SET_BILLSBY_DATA_TYPE = "SET_BILLSBY_DATA_TYPE";
export const setBillsbyDataType = makeActionCreator(SET_BILLSBY_DATA_TYPE, "payload");

export const FETCH_BRANDING_REQUEST = "FETCH_BRANDING_REQUEST";
export const FETCH_BRANDING_SUCCESS = "FETCH_BRANDING_SUCCESS";
export const FETCH_BRANDING_FAILURE = "FETCH_BRANDING_FAILURE";

export const FETCH_CHECKOUT_CONFIG_REQUEST = "FETCH_CHECKOUT_CONFIG_REQUEST";
export const FETCH_CHECKOUT_CONFIG_SUCCESS = "FETCH_CHECKOUT_CONFIG_SUCCESS";
export const FETCH_CHECKOUT_CONFIG_FAILURE = "FETCH_CHECKOUT_CONFIG_FAILURE";

export const DETECT_CHECKOUT_VIEW_MODE = "DETECT_CHECKOUT_VIEW_MODE";
export const detectCheckoutViewMode = makeActionCreator(DETECT_CHECKOUT_VIEW_MODE);

export const SET_CHECKOUT_VIEW_MODE = "SET_CHECKOUT_VIEW_MODE";
export const setCheckoutViewMode = makeActionCreator(SET_CHECKOUT_VIEW_MODE, "payload");

export const GET_CUSTOMER_LANGUAGE_REQUEST = "GET_CUSTOMER_LANGUAGE_REQUEST";
export const GET_CUSTOMER_LANGUAGE_SUCCESS = "GET_CUSTOMER_LANGUAGE_SUCCESS";
export const GET_CUSTOMER_LANGUAGE_FAILURE = "GET_CUSTOMER_LANGUAGE_FAILURE";

// Async actions
export const fetchBranding = (companyDomain: string) => {
  return {
    types: [FETCH_BRANDING_REQUEST, FETCH_BRANDING_SUCCESS, FETCH_BRANDING_FAILURE],
    callAPI: () => API.fetchCustomerBranding(companyDomain)
  }
}

export const fetchCheckoutConfig = (companyDomain: string) => {
  return {
    types: [FETCH_CHECKOUT_CONFIG_REQUEST, FETCH_CHECKOUT_CONFIG_SUCCESS, FETCH_CHECKOUT_CONFIG_FAILURE],
    callAPI: () => API.fetchCheckoutConfig(companyDomain)
  }
}

const getCompanyDomainSessionStorage = () => {
  let companyDomain;
  try {
    // check if 3ds1 redirect from spreedly and try to get companyDomain from sessionStorage
    companyDomain = JSON.parse(sessionStorage?.billsbySubscriptionSCA || sessionStorage.billsbyUpdatePaymentSCA)?.companyDomain;
  }
  catch(err) {}

  return companyDomain;
}

export const getCustomerLanguage = (companyDomain: string, customerUniqueId: string) => {
  const getCustomerLanguageRequest = new GetCustomerLanguageRequest();
  getCustomerLanguageRequest.setCompanyDomain(companyDomain);
  getCustomerLanguageRequest.setCustomerUniqueId(customerUniqueId);
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [GET_CUSTOMER_LANGUAGE_REQUEST, GET_CUSTOMER_LANGUAGE_SUCCESS, GET_CUSTOMER_LANGUAGE_FAILURE],
    callAPI: () => grpcUnaryCall(getCustomerLanguageRequest, customerServiceClient, customerServiceClient.getCustomerLanguage)
  }
}

const getCustomerUniqueIdSessionStorage = () => {
  let customerUniqueId;
  try {
    // check if 3ds1 redirect from spreedly and try to get companyDomain from sessionStorage
    customerUniqueId = JSON.parse(sessionStorage?.billsbySubscriptionSCA || sessionStorage.billsbyUpdatePaymentSCA)?.customerUniqueId;
  }
  catch(err) {}

  return customerUniqueId;
}

export const preLoadBillsbyCustomerData = () => {
  return async (dispatch: Function, getState: () => AppState) => {
    const queryParams = queryString.parse(window.location.search);
    const productId = queryParams[BillsbyDataParams.product];
    const planId = queryParams[BillsbyDataParams.plan];
    const cycleId = queryParams[BillsbyDataParams.cycle];
    const units = queryParams[BillsbyDataParams.units];
    const customerUniqueId = queryParams[BillsbyDataParams.customer] || getCustomerUniqueIdSessionStorage();
    const subscriptionUniqueId = queryParams[BillsbyDataParams.subscription];
    const language = (queryParams[BillsbyDataParams.language] as string)?.toLocaleLowerCase();
    if(supportedLanguages.has(language)) {
      dispatch(setEmbedCodeLanguage(language));
    }
    const billsbyDataType = queryParams[BillsbyDataParams.type];
    const companyDomain = queryParams[BillsbyDataParams.company] || getCompanyDomainSessionStorage();
    const { isValidNumber } = (global as any).intlTelInputUtils;

    if (productId && planId && cycleId) {
      try {
        const preloadedAddons = JSON.parse(String(queryParams[BillsbyDataParams.addons] || "[]"));
        dispatch(setPreloadedAddOns(preloadedAddons));
        const preloadedAllowances = JSON.parse(String(queryParams[BillsbyDataParams.allowances] || "[]"));
        dispatch(setPreloadedAllowances(preloadedAllowances));
      }
      catch (err) { }
    }

    if(billsbyDataType) {
      dispatch(setBillsbyDataType(billsbyDataType));
    }
    
    if (companyDomain) {
      dispatch(setCompanyDomain(companyDomain));
      if(customerUniqueId) {
        // synchronous blocking call here to get customer language before the checkoutconfig and then set the preferred language in the reducer
        await dispatch(getCustomerLanguage(companyDomain, customerUniqueId));
      }
      dispatch(fetchCheckoutConfig(companyDomain as string));
    }

    /* pre load product, plan and cycle */
    if (productId) {
      dispatch(setProductId(+productId));
    }
    if (planId) {
      dispatch(setPlanId(+planId));
    }
    if (cycleId) {
      dispatch(setCycleId(+cycleId));
    }
    if (productId && planId && units) {
      dispatch(setNumberOfUnits(+units));
    }
    if (customerUniqueId) {
      dispatch(setCustomerUniqueId(customerUniqueId));
      dispatch(getHasCustomerCardOnFile(companyDomain as string, customerUniqueId as string));
    }
    if (customerUniqueId && subscriptionUniqueId) {
      // to preload the subscriptionId the user must pass the customerId as well
      dispatch(setSubscriptionUniqueId(subscriptionUniqueId));
    }

    try {
      let customerData: IBillsbyData = JSON.parse((queryParams.billsbyData as string) || "{}");
      dispatch(setPreloadedCustomerData(customerData));
      /* pre load customer data */
      if(customerData.cid) {
        dispatch(setCustomerUniqueId(customerData.cid));
        dispatch(getHasCustomerCardOnFile(companyDomain as string, customerData.cid));
      }
      if (customerData.firstName) {
        dispatch(setFirstName(customerData.firstName));
      }
      if (customerData.lastName) {
        dispatch(setLastName(customerData.lastName));
      }
      if (customerData.email) {
        dispatch(setEmail(customerData.email));
      }
      if (customerData.billingAddressLine1) {
        dispatch(setAddressLine1(true, customerData.billingAddressLine1));
      }
      if (customerData.billingAddressLine2) {
        dispatch(setAddressLine2(true, customerData.billingAddressLine2));
      }
      if (customerData.billingAddressCity) {
        dispatch(setCity(true, customerData.billingAddressCity));
      }
      if (customerData.billingAddressState) {
        dispatch(setState(true, { label: "", value: { StateIso2: customerData.billingAddressState, StateName: customerData.billingAddressState } as ICountryState }));
      }
      if (customerData.billingAddressZip) {
        dispatch(setZipCode(true, customerData.billingAddressZip));
      }
      if (customerData.billingAddressCountry) {
        dispatch(setCountry(true, { label: customerData.billingAddressCountry, value: { iso3Code: customerData.billingAddressCountry } as ICountry }));
      }
      if (customerData.shippingAddressLine1) {
        dispatch(setAddressLine1(false, customerData.shippingAddressLine1));
      }
      if (customerData.shippingAddressLine2) {
        dispatch(setAddressLine2(false, customerData.shippingAddressLine2));
      }
      if (customerData.shippingAddressCity) {
        dispatch(setCity(false, customerData.shippingAddressCity));
      }
      if (customerData.shippingAddressState) {
        dispatch(setState(false, { label: "", value: { StateIso2: customerData.shippingAddressState } as ICountryState }));
      }
      if (customerData.shippingAddressZip) {
        dispatch(setZipCode(false, customerData.shippingAddressZip));
      }
      if (customerData.shippingAddressCountry) {
        dispatch(setCountry(false, { label: "", value: { iso3Code: customerData.shippingAddressCountry } as ICountry }));
      }
      if(customerData.companyName) {
        dispatch(setCompanyName(customerData.companyName))
      }
      if (customerData.additionalEmails && customerData.additionalEmails.match(EMAIL_REGEX)) {
        dispatch(setField("additionalEmails", customerData.additionalEmails));
        dispatch(setField("isAdditionalEmailsPreLoaded", true));
      }

      if(customerData.achSession) {
        dispatch(setAchField("stripeCustomerId", customerData.achSession.customerId))
        dispatch(setAchField("accountHolder", customerData.achSession.fullName))
        dispatch(setAchField("stripeLast4Digits", customerData.achSession.last4Digits))
        dispatch(setAchField("stripeBankName", customerData.achSession.bankName))
        dispatch(setAchField("stripePaymentId", customerData.achSession.stripePaymentId))
      }

      // store info of preloaded screens
      const isPreloadedUserDataScreen = !!customerData.firstName && !!customerData.lastName && !!(customerData.email || "").match(EMAIL_REGEX);
      const isPreloadAddressScreen = !!customerData.billingAddressLine1 && !!customerData.billingAddressZip && !!customerData.billingAddressCity
        && !!customerData.billingAddressState && !!customerData.billingAddressCountry;
      
      dispatch(setIsUserDataScreenPreloaded(isPreloadedUserDataScreen));
      dispatch(setIsAddressScreenPreloaded(isPreloadAddressScreen));

      // check if phone number is valid before saving it to redux (removing spacing and extra '+' that could be added by mistake)
      const REGEX_SPACE_PLUS = /[\s\+]/g;
      if (isValidNumber(`+${(customerData.phoneNumberDialCode || "").replace(REGEX_SPACE_PLUS, "")}${(customerData.phoneNumber || "").replace(REGEX_SPACE_PLUS, "")}`)) {
        dispatch(setField("phoneNumberPrefix", (customerData.phoneNumberDialCode || "").replace(REGEX_SPACE_PLUS, "")));
        dispatch(setField("phoneNumber", (customerData.phoneNumber || "").replace(REGEX_SPACE_PLUS, "")));
        dispatch(setField("phoneNumberCountry", customerData.phoneNumberDialCountry || ""));
        dispatch(setField("isPhoneNumberPreLoaded", true));
        dispatch(setField("isPhoneNumberPrefixPreLoaded", true));
      }
      if (customerData.marketingConsent === true || customerData.marketingConsent === false) {
        dispatch(setField("marketingConsent", customerData.marketingConsent));
        dispatch(setField("isMarketingConsentPreLoaded", true));
      }
      if (customerData.customFields && customerData.customFields.length && customerData.customFields.every(customField => !!customField.customFieldId)) {
        dispatch(setField("preLoadedCustomFields", customerData.customFields));
      }
    }
    catch (err) {
      console.log(err);
    }
  }
}

export const loadAccountMangementPermissions = () => {

  return (dispatch: Function) => {
    const queryParams = queryString.parse(window.location.search);
    const type = queryParams[BillsbyDataParams.type] as BillsbyDataType.ACCOUNT | undefined;
    const action = queryParams[BillsbyDataParams.action] as BillsbyDataAction | undefined;
    if (type === BillsbyDataType.ACCOUNT && !action) {
      dispatch(setAccountManagementPermissions(AccountManagementPermissions.FULL));
    }
    if (action) {
      dispatch(setBillsbyAction(action));
    }
  }

}