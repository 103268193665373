import { BillsbyDataAction } from "./BillsbyData";
import { NavigationBarSteps } from "./NavigationBar";

export const TrackedPageTitles = {
    //Account management pages
    ACCT_MGMT_CHANGE_PLAN: "Account Management - View and change subscription",
    ACCT_MGMT_CHANGE_ADDRESS: "Account Management - Address details",
    ACCT_MGMT_CHANGE_PERSONAL_DETAILS: "Account Management - Contact details",
    ACCT_MGMT_MANAGE_COUPONS: "Account Management - Manage coupons",
    ACCT_MGMT_UPDATE_PAYMENT_DETAILS: "Account Management - Update payment details",
    ACCT_MGMT_MANAGE_ADDONS: "Account Management - Manage addons",
    ACCT_MGMT_MANAGE_ALLOWANCES: "Account Management - Manage allowances",
    ACCT_MGMT_VIEW_BILLING_HISTORY: "Account Management - View billing history",
    ACCT_MGMT_CANCEL_PLAN: "Account Management - Cancel subscription",
    ACCT_MGMT: "Account Management",
    //Checkout pages
    CHECKOUT_SELECT_PLAN: "Checkout - Select plan",
    CHECKOUT_ADDRESS_DETAILS: "Checkout - Address details",
    CHECKOUT_PERSONAL_DETAILS: "Checkout - Personal details",
    CHECKOUT_PAYMENT_METHOD: "Checkout - Payment method",
    CHECKOUT: "Checkout"
}

export const getCurrentScreenTitle = (screen?: NavigationBarSteps | BillsbyDataAction | "management") => {
    switch (screen) {
        case BillsbyDataAction.CHANGE_PLAN:
            return TrackedPageTitles.ACCT_MGMT_CHANGE_PLAN
        case BillsbyDataAction.CHANGE_ADDRESS:
            return TrackedPageTitles.ACCT_MGMT_CHANGE_ADDRESS
        case BillsbyDataAction.CHANGE_PERSONAL_DETAILS:
            return TrackedPageTitles.ACCT_MGMT_CHANGE_PERSONAL_DETAILS
        case BillsbyDataAction.MANAGE_COUPONS:
            return TrackedPageTitles.ACCT_MGMT_MANAGE_COUPONS
        case BillsbyDataAction.UPDATE_PAYMENT_DETAILS:
            return TrackedPageTitles.ACCT_MGMT_UPDATE_PAYMENT_DETAILS
        case BillsbyDataAction.MANAGE_ADDONS:
            return TrackedPageTitles.ACCT_MGMT_MANAGE_ADDONS
        case BillsbyDataAction.MANAGE_ALLOWANCES:
            return TrackedPageTitles.ACCT_MGMT_MANAGE_ALLOWANCES
        case BillsbyDataAction.VIEW_BILLING_HISTORY:
            return TrackedPageTitles.ACCT_MGMT_VIEW_BILLING_HISTORY
        case BillsbyDataAction.CANCEL_PLAN:
            return TrackedPageTitles.ACCT_MGMT_CANCEL_PLAN
        case NavigationBarSteps.SELECT_PLAN:
            return TrackedPageTitles.CHECKOUT_SELECT_PLAN
        case NavigationBarSteps.ADDRESS_DETAILS:
            return TrackedPageTitles.CHECKOUT_ADDRESS_DETAILS
        case NavigationBarSteps.PERSONAL_DETAILS:
            return TrackedPageTitles.CHECKOUT_PERSONAL_DETAILS
        case NavigationBarSteps.PAYMENT_METHOD:
            return TrackedPageTitles.CHECKOUT_PAYMENT_METHOD
        case "management":
            return TrackedPageTitles.ACCT_MGMT
        default:
            return TrackedPageTitles.CHECKOUT
    }
}

export enum CustomAnalyticsEvents {
    CREATED_SUBSCRIPTION = "created_subscription",
    UPDATED_SUBSCRIPTION = "updated_subscription",
    CANCELLED_SUBSCRIPTION = "cancelled_subscription",
    CHANGED_PLAN = "changed_plan",
    UPDATED_PAYMENT_DETAILS = "updated_payment_details",
    PURCHASE = "purchase",
    TEST = "test_event"
}

export interface IAnalyticsEventConfig {
    category?: string,
    label?: string,
    value?: number,
    nonInteraction?: boolean,
    transport?: "xhr" | "beacon" | "image"
}

export interface IPurchaseEventItem {
    item_id?: string;
    item_name: string;
    affiliation?: string;
    coupon?: string;
    currency?: string;
    discount?: number;
    index?: number;
    item_brand?: string;
    item_category?: string;
    item_list_id?: string;
    item_list_name?: string;
    item_variant?: string;
    location_id?: string;
    price?: number;
    quantity?: number;
  }
  
  export interface IPurchaseEventDetails {
    currency?: string;
    transaction_id: string;
    value: number;
    affiliation?: string;
    coupon?: string;
    shipping?: number;
    tax?: number;
    items: Array<IPurchaseEventItem>
  }

  