import React, { useState } from "react";
import Text from "../ui/text/Text";

interface ITaxLabel {
  companyDomain: string,
  countryIso2Code: string
}

const TaxLabel: React.FC<ITaxLabel> = ({ companyDomain, countryIso2Code }) => {
  const [taxPercentage, setTaxPercentage] = useState("");
  const [taxName, setTaxName] = useState("");
  const [taxCountry, setTaxCountry] = useState("");

  const fetchTaxDetails = () => {};

  const renderTaxLabel = () => {
    return <Text content="TAX_LABEL" translateWith={{ taxName, taxCountry, taxPercentage }} size="small"></Text>
  }

  //return renderTaxLabel();
  // TODO - This functionality is temporary removed
  return null;
}

export default TaxLabel;