import React, { useLayoutEffect, useEffect, useState, useMemo } from "react";
import "./ChangePlanStep.scss";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import FormGroup from "../ui/form-group/FormGroup";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IChangePlanReducer } from "../../reducers/changePlanReducer";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import { getPriceDescription, getPricingInfoDisclosure } from "../../actions/paymentActions";
import { changePlan, setChangePlanField } from "../../actions/changePlanActions";
import { IGlobalReducer } from "../../reducers/globalReducer";
import history from "../../utils/history";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { fetchCurrentPlan, fetchCustomerSubscriptions, setCurrentSubscription } from "../../actions/subscriptionsActions";
import { PlanChangeDate, IChangePlanBody, ChangePlanScreen } from "../../models/ChangePlan";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import { IPlanWithCycles, FrequencyType } from "../../models/Product";
import { IAddOnReducer } from "../../reducers/addOnReducer";
import Modal from "../ui/modal/Modal";
import AddOnSummaryList from "../add-on/addon-summary-list/AddOnSummaryList";
import { IAddOn, IAllowance } from "../../models/AddOnAllowance";
import { setSelectedAddOns } from "../../actions/addOnActions";
import { getFrequencyText, getCouponError, showTaxLabel } from "../../utils/planUtils";
import { IDiscountCouponReducer } from "../../reducers/discountCouponReducer";
import PlanSummaryDetails from "../select-plan/plan-summary-details/PlanSummaryDetails";
import { IAppliedCoupon, CouponError } from "../../models/DiscountCoupon";
import { setAppliedCoupons, setCouponCode, fetchCouponDetails } from "../../actions/discountCouponActions";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";
import Input from "../ui/input/Input";
import counterpart from "counterpart";
import Button from "../ui/button/Button";
import { IChangePersonalDetailsReducer } from "../../reducers/changePersonalDetailsReducer";
import SystemInfo from "../ui/system-info/SystemInfo";
import moment from "moment";
import PaymentSummaryCoupons from "../ui/payment-summary/PaymentSummaryCoupons";
import { gaEventTracker, getTaxName } from "../../utils/commonUtils";
import { ICustomerSubscription } from "../../models/Customer";
import TaxInput from "../tax-input/TaxInput";
import { isMobileOnly } from "react-device-detect";
import UpdatePaymentDetails from "../../containers/update-payment-details/UpdatePaymentDetails";
import { resetUpdatePaymentState } from "../../actions/updatePaymentActions";
import { fetchTaxValidation, setTaxRegNumber } from "../../actions/personalDetailsActions";
import { CustomAnalyticsEvents } from "../../models/GoogleAnalytics";
import PricingInfoDisclosure from "../pricing-info-disclosure/PricingInfoDisclosure";
import { IPaymentReducer } from "../../reducers/paymentReducer";

interface IChangePlanStep4 {
  btnBackCallback?: () => void
}

const ChangePlanStep4: React.FC<IChangePlanStep4> = ({ btnBackCallback }) => {


  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);
  const [isApplyingCouponCode, setIsApplyingCouponCode] = useState(false);
  const [isOpenSummaryModal, setIsOpenSummaryModal] = useState(false);
  const [isVerifyingCouponCodes, setIsVerifyingCouponCodes] = useState(false);
  const [isOpenTaxNumberModal, setIsOpenTaxNumberModal] = useState(false);
  const [tryAnotherCard, setTryAnotherCard] = useState(false);

  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState<CouponError | null>(null);
  const [removedCoupons, setRemovedCoupons] = useState<Array<{ name: string; id: number }>>([]);

  const { selectedSubscription, paymentDescription, paymentDescriptionIsLoading, selectedDateToChange, isChangePlanRequest, isChangePlanSuccess,
    isChangePlanFailure } = useSelector<AppState, IChangePlanReducer>(state => state.changePlanReducer);
  const { plan, cycle, numberOfUnits, plans, product } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { addOns, allowances, isRequestingCurrentAllowances } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const { companyDomain, checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const personalDetailsReducer = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer)
  const customerUniqueId = personalDetailsReducer.mainProps.customerUniqueId as string;
  const { country, stateObject } = personalDetailsReducer.address;
  const { taxRegNumberRequirementType, taxRegNumber } = personalDetailsReducer
  const [isOpenIncludedAddonsAllowancesModal, setIsOpenIncludedAddonsAllowancesModal] = useState(false);
  const { appliedCoupons, couponCode, couponName } = useSelector<AppState, IDiscountCouponReducer>(state => state.discountCouponReducer);
  const { currentSubscription } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const { email } = useSelector<AppState, IChangePersonalDetailsReducer>(state => state.changePersonalDetailsReducer);
  const { pricingInfoDisclosure } = useSelector<AppState, IPaymentReducer>(state => state.paymentReducer)
  const dispatch = useDispatch<Function>()
 
  const hasDiscounts = appliedCoupons && appliedCoupons.length > 0;
  const isNotFreeTrial = paymentDescription && !paymentDescription.getEndTrialDate() && paymentDescription.getHasInvoices()
  const taxName = useMemo(() => getTaxName(stateObject && stateObject.value.CountryIso3), [stateObject]);
  // const isTaxesIncluded = paymentDescription && paymentDescription.getTaxesIncluded();

  const plusTaxLabel = showTaxLabel(paymentDescription, taxRegNumber) ? ` ${counterpart("PRICE_SUMMARY_PLUS_TAX")}` : "";
  const couponError = useMemo(() => getCouponError(error, appliedCoupons, removedCoupons), [error, appliedCoupons, removedCoupons])

  const getLostDays = useMemo(() => {
    if (selectedDateToChange === PlanChangeDate.IMMEDIATE) {
      const todayDate = moment(new Date());
      const nextBillingDate = moment(currentSubscription && currentSubscription.nextBilling);
      const lostDays = - todayDate.diff(nextBillingDate, "days");
      return lostDays;
    }
  }, [currentSubscription, selectedDateToChange]);

  const optionalAddOnAllowances = useMemo(() => {
    const combinedAddOnAllowances: Array<IAddOn | IAllowance> = [...addOns, ...allowances];
    return combinedAddOnAllowances.filter(i => !i.isForced && !!i.units && i.units > 0);
  }, [allowances, addOns]);

  useLayoutEffect(() => {
    if (cycle && selectedSubscription) {
      dispatch(getPriceDescription(
        cycle.cycleId,
        addOns.filter(i => !!i.units && i.units > 0),
        allowances.filter(i => !!i.units && i.units > 0),
        numberOfUnits === 0 ? 1 : numberOfUnits,
        appliedCoupons.map(ac => ac.couponCode || ""),
        undefined,
        undefined,
        selectedSubscription.cycleId,
        stateObject ? stateObject.value.CountryIso3 : undefined
      ));
      dispatch(getPricingInfoDisclosure(
        cycle.cycleId,
        addOns.filter(i => !!i.units && i.units > 0),
        allowances.filter(i => !!i.units && i.units > 0),
        numberOfUnits === 0 ? 1 : numberOfUnits,
        appliedCoupons.map(ac => ac.couponCode || ""),
        undefined,
        undefined,
        selectedSubscription.cycleId,
        stateObject ? stateObject.value.CountryIso3 : undefined
      ));
      stateObject && dispatch(fetchTaxValidation(companyDomain, stateObject.value.CountryIso3))
    }
  }, [cycle, selectedSubscription, appliedCoupons, numberOfUnits]);

  useEffect(() => {
    if (isChangePlanSuccess) {
      // redirect + forward history query string
      gaEventTracker(CustomAnalyticsEvents.CHANGED_PLAN);

      history.push({ pathname: "/management", search: history.location.search });
    }
  }, [isChangePlanSuccess])

  useEffect(() => {
    //reset flag
    tryAnotherCard && dispatch(setChangePlanField("isChangePlanFailure", false));
  }, [tryAnotherCard])

  if (!cycle || !selectedSubscription) {
    return null;
  }

  const getWarningMessage = () => {
    const oldPlan = plans.find(p => p.planId === selectedSubscription.planId) as IPlanWithCycles;
    if (!oldPlan.addons.length) {
      return;
    }
    // TODO add warning messages for allowances
    return (
      <Panel id="change-plan-warning">
        <Text content="CHANGE_PLAN_WARNING_ADDONS" translateWith={{ addonLabel: checkoutConfig?.terminologyAddonPlural, subscriptionLabel: checkoutConfig?.terminologySubscriptionSingular }} noMargin />
      </Panel>
    )
  }

  const renderAddTaxNumerModal = () => {
    return (
      <Modal width="340px"
        height="172px"
        isOpen={isOpenTaxNumberModal}
        title={taxName.short}
        shouldTranslateTitle={false}
        onRequestClose={() => setIsOpenTaxNumberModal(false)}>
        <TaxInput isInlineDisplay={false} onSubmit={() => setIsOpenTaxNumberModal(false)} />
      </Modal>
    )
  }

  const onError = (err: CouponError) => {
    setIsApplyingCouponCode(false);
    setIsSuccessful(false);
    setError(err);
    onCloseCouponModal();
  };

  const onCloseCouponModal = () => {
    setIsOpenCouponModal(false);
    dispatch(setCouponCode(""));
  };

  if (isVerifyingCouponCodes) {
    return null;
  }

  const onSubmit = async () => {
    if (!plan) {
      return;
    }

    //const addonsAllowances = selectedAddOns.concat(addOns);
    const newAddOns = addOns.filter(i => !!i.units && i.units > 0).map(addOn => ({ addOnId: addOn.id, quantity: addOn.units || 1 }));
    const newAllowances: Array<number> = allowances.filter(i => !!i.units && i.units > 0).map(i => i.id);
    try {
      const changePlanRequestBody: IChangePlanBody = {
        planId: plan.value.planId,
        cycleId: cycle.cycleId,
        customerUniqueId,
        planChangeType: selectedDateToChange,
        addOns: newAddOns,
        allowances: newAllowances,
        couponCodes: appliedCoupons.map((ac) => ({ planId: plan ? plan.value.planId : 0, couponCode: ac.couponCode || "" })),
        units: numberOfUnits ? numberOfUnits : undefined
      }
      const response = await dispatch(changePlan(companyDomain, selectedSubscription.subscriptionUniqueId, changePlanRequestBody));
      //TODO: Outstanding balance is not supported for the meantime
      // if (response.error && response.error.message === "Subscription_Change_Subscription_Plan_Billing_Error") {
      //   dispatch(fetchSubscriptionOutstandingBalance(companyDomain, customerUniqueId, selectedSubscription.subscriptionUniqueId));
      // }
      //plan and cylce changed, we have to refetch the subscription that holds the new planId and cylceId and the currentPlan
      const { response: subscriptions } = await dispatch(fetchCustomerSubscriptions(companyDomain, customerUniqueId as string)) as { response: Array<ICustomerSubscription> };
      const newCurrentSubscription = subscriptions.find(s => s.subscriptionUniqueId === currentSubscription?.subscriptionUniqueId);
      dispatch(setCurrentSubscription(newCurrentSubscription));
      dispatch(fetchCurrentPlan(companyDomain, Number(newCurrentSubscription?.productId), Number(newCurrentSubscription?.planId), String(newCurrentSubscription?.subscriptionUniqueId)));
    }
    catch (err) {
      setTryAnotherCard(false)
    }
  }

  const onAddCoupon = async () => {
    if (!couponCode) {
      return;
    }

    setIsApplyingCouponCode(true);
    setIsSuccessful(false);
    setError(null);
    setRemovedCoupons([]);

    try {
      await dispatch(fetchCouponDetails(companyDomain, couponCode, email));

      setIsSuccessful(true);
      setIsApplyingCouponCode(false);
      setError(null);
      onCloseCouponModal();
    } catch (err) {
      onError(err);
    }
  };

  // const canSubmit = checkoutConfig && (checkoutConfig.termsOfUseUrl || checkoutConfig.privacyPolicyUrl) ? termsAndPolicies : true;

  const onDeleteCoupon = (coupon: IAppliedCoupon) => {
    dispatch(setAppliedCoupons(appliedCoupons.filter((ac) => ac.coupon.getCouponId() !== coupon.coupon.getCouponId())));
  };

  if (isChangePlanFailure && !tryAnotherCard) {
    return (
      <PageWrapper className="payment-method__change-plan-error" btnBackHidden btnPosition="bottom" btnText="PAYMENT_TRY_ANOTHER_CARD" btnCallback={() => {
        dispatch(resetUpdatePaymentState())
        setTryAnotherCard(true)
      }}>
        <div className="payment-method">
          <SystemInfo
            title="PAYMENT_ERROR_TITLE"
            text="PAYMENT_ERROR_SUBTITLE"
            type="warning"
            isShowIcon={false}
          />
        </div>
      </PageWrapper>
    )
  }

  if (tryAnotherCard) {
    return (
      <UpdatePaymentDetails
        title="CHANGE_SUBSCRIPTION_NEW_SUBSCRIPTION"
        titlePosition="left-aligned"
        titleTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
        //btnText={selectedDateToChange === PlanChangeDate.IMMEDIATE ? "CHANGE_SUBSCRIPTION_PLAN_BTN" : "CHANGE_SUBSCRIPTION_CHANGE_PLAN"}
        btnTextTranslateWith={{
          planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular,
          amount: paymentDescription && (hasDiscounts
            ? (
              <>
                <Text component="span" className="payment-method__price payment-method__price--discounted" shouldTranslate={false} content={`${paymentDescription.getFirstInvoiceTotalWithoutDiscountsPrice()}${plusTaxLabel}`} />
                <Text component="span" shouldTranslate={false} className="payment-method__price" content={`${paymentDescription.getFirstInvoiceTotalPrice()}${plusTaxLabel}`} />
              </>
            )
            : <Text component="span" className="payment-method__price" shouldTranslate={false} content={`${paymentDescription.getFirstInvoiceTotalPrice()}${plusTaxLabel}`} />)
        }}
        isLoading={isChangePlanRequest}
        btnBackCallback={() => {
          dispatch(setChangePlanField("isChangePlanFailure", false));
          setTryAnotherCard(false);
        }}
        customSuccessSubtitle="ACCOUNT_MANAGEMENT_UPDATE_PAYMENT_SUCCESS_CHANGE_PLAN"
      >
      </UpdatePaymentDetails>
    )
  }

  return (
    <PageWrapper
      className="change-plan-step4"
      btnBackCallback={btnBackCallback}
      titlePosition="left-aligned"
      title={currentSubscription ? "CHANGE_SUBSCRIPTION_NEW_SUBSCRIPTION" : ""}
      btnText={selectedDateToChange === PlanChangeDate.IMMEDIATE ? "CHANGE_SUBSCRIPTION_PLAN_BTN" : "CHANGE_SUBSCRIPTION_CHANGE_PLAN"}
      titleTranslateWith={{ planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular, subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
      btnCallback={onSubmit}
      btnPosition="bottom"
      isLoading={paymentDescriptionIsLoading}
      btnTextTranslateWith={{
        planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular,
        amount: paymentDescription && (hasDiscounts
          ? (
            <>
              <Text component="span" className="payment-method__price payment-method__price--discounted" shouldTranslate={false} content={`${paymentDescription.getFirstInvoiceTotalWithoutDiscountsPrice()}${plusTaxLabel}`} />
              <Text component="span" shouldTranslate={false} className="payment-method__price" content={`${paymentDescription.getFirstInvoiceTotalPrice()}${plusTaxLabel}`} />
            </>
          )
          : <Text component="span" className="payment-method__price" shouldTranslate={false} content={`${paymentDescription.getFirstInvoiceTotalPrice()}${plusTaxLabel}`} />)
      }}
      btnDisabled={isChangePlanRequest || isRequestingCurrentAllowances}
      bottomStickyContent={
        <>
          {isMobileOnly && checkoutConfig?.isHowWasThisCalculatedVisible && <Text
            onClick={() => setIsOpenSummaryModal(true)}
            className="payment-method__link"
            content="UPDATE_PAYMENT_DETAILS_HOW_THIS_IS_CALCULATED"
            noMargin
          />}
          <div className="payment-method__links-container" style={{ display: !isMobileOnly && !checkoutConfig?.isHowWasThisCalculatedVisible && !checkoutConfig?.isApplyCouponsVisible ? "none" : "flex" }}>
            {!isMobileOnly && checkoutConfig?.isHowWasThisCalculatedVisible && <Text
              onClick={() => setIsOpenSummaryModal(true)}
              className="payment-method__link payment-method__link__calculation"
              content="UPDATE_PAYMENT_DETAILS_HOW_THIS_IS_CALCULATED"
              noMargin
            />}
            {checkoutConfig?.isApplyCouponsVisible && <Text
              onClick={() => setIsOpenCouponModal(true)}
              className="payment-method__link payment-method__link__apply-coupon"
              content="UPDATE_PAYMENT_DETAILS_APPLY_COUPON"
              translateWith={{ couponLabel: checkoutConfig.terminologyCouponSingular }}
              noMargin
            />}
            {/* HIDE FOR NOW: For next sprint */}
            {/* {taxRegNumberRequirementType === TaxCollection.OPTIONAL &&
              !taxRegNumber && (
                <Text
                  onClick={() => setIsOpenTaxNumberModal(true)}
                  className="payment-method__link"
                  content="UPDATE_PAYMENT_DETAILS_ADD_TAX_NUMBER"
                  translateWith={{ taxName: taxName.short }}
                  noMargin
                />)
            } */}
          </div>
        </>
      }
    >
      <FormGroup>
        {selectedDateToChange === PlanChangeDate.IMMEDIATE && !!getLostDays && getLostDays > 0 && <SystemInfo
          isShowIcon={false}
          title={"CHANGE_PLAN_LOST_DAYS_ERROR"}
          type={"warning"}
          translateWith={{ planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular, lostDays: getLostDays, frequency: getFrequencyText(getLostDays, FrequencyType.Daily) }}
        />}
        {/* <PaymentSummary
          pricingModel={cycle.pricingModel}
          productName={selectedSubscription.productName}
          planName={plan.value.displayName}
          hasTitle={false}
          onClickIncludedAddonsAllowances={selectedAddOns.length ? () => setIsOpenIncludedAddonsAllowancesModal(true) : undefined}
        /> */}
        {(isSuccessful || error) && (
          <SystemInfo
            title={counterpart(!error ? "APPLY_COUPON_SUCCESS_TITLE1" : couponError.title, {
              couponName,
              couponLabel: checkoutConfig?.terminologyCouponSingular
            })}
            text={error ? couponError.content : ""}
            type={error ? "warning" : "success"}
            isShowIcon={false}
            shouldTranslateTitle={false}
          ></SystemInfo>
        )}
        <PlanSummaryDetails onDeleteSubscriptionCoupon={onDeleteCoupon} includeAllowanceOverages />
      </FormGroup>

      <div className="payment-method__extra-details">
        {!!taxRegNumber &&
          <div className="payment-method__tax-details">
            <Text
              className="payment-method__tax-details text"
              content="UPDATE_PAYMENT_DETAILS_TAX_NUMBER_ADDED"
              translateWith={{ taxName: taxName.short, taxNumber: taxRegNumber }}
              noMargin
            />
            <Text
              onClick={() => dispatch(setTaxRegNumber(""))}
              className="payment-method__tax-details remove-text"
              content="UPDATE_PAYMENT_DETAILS_REMOVE_TAX_NUMBER"
              noMargin
            />
          </div>}

        {
          hasDiscounts && cycle &&
          <div className="payment-method__discounts-list">
            <PaymentSummaryCoupons
              paymentDescription={paymentDescription}
              appliedCoupons={appliedCoupons}
              onDeleteCoupon={onDeleteCoupon}
              checkoutConfig={checkoutConfig}
              currencyCode={product && product.value ? product.value.billingCurrency : currentSubscription ? currentSubscription?.currency : "USD"}
            />
          </div>
        }

      </div>



      {/* {
        !isRequestingCurrentAllowances
          ? (
            <PaymentDescription
              paymentDescription={paymentDescription}
              isLoading={paymentDescriptionIsLoading}
              planType={plan.value.pricingModelType}
            />
          )
          : <div />
      } */}


      <Modal
        isOpen={isOpenIncludedAddonsAllowancesModal}
        title=''
        shouldTranslateTitle={false}
        className="payment-method__included-addons-allowances"
        onRequestClose={() => setIsOpenIncludedAddonsAllowancesModal(false)}>
        {plan && <AddOnSummaryList
          productName={selectedSubscription.productName}
          appliedCoupons={appliedCoupons}
          currencyCode={product && product.value ? product.value.billingCurrency : currentSubscription ? currentSubscription?.currency : "USD"}
          plan={plan.value}
          cycle={cycle}
          selectedAddOnAllowance={optionalAddOnAllowances}
          onEdit={(addon: IAddOn | IAllowance) => {
            dispatch(setChangePlanField("currentScreen", ChangePlanScreen.SET_ADD_ON));
            setIsOpenIncludedAddonsAllowancesModal(false);
          }}
          onDelete={(addon: IAddOn | IAllowance) => {
            dispatch(setSelectedAddOns(optionalAddOnAllowances.filter(a => a.id !== addon.id)));
          }}
          isEditable={(addon: IAddOn | IAllowance) => true}
          isDeletable={(addon: IAddOn | IAllowance) => true}
          isLocked={(addon: IAddOn | IAllowance) => false}
        />}
      </Modal>

      <Modal hasOverlay width="340px" height="172px" shouldTranslateTitle={false} isOpen={isOpenCouponModal} title={counterpart("APPLY_COUPON_LABEL", { couponLabel: checkoutConfig?.terminologyCouponSingular })} onRequestClose={onCloseCouponModal}>
        <>
          <FormGroup>
            <Input
              id="coupon-code"
              value={couponCode}
              placeholder={counterpart("APPLY_COUPON_PLACEHOLDER", { couponLabel: checkoutConfig?.terminologyCouponSingular })}
              onChange={(evt: any) => {
                dispatch(setCouponCode(evt.target.value.replace(/\s/g, "")));
              }}
            />
          </FormGroup>

          <div className="select-plan__coupon-modal__footer">
            <Button
              disabled={isApplyingCouponCode || !couponCode}
              isLoading={isApplyingCouponCode}
              className="modal__body__closebtn"
              title="APPLY_COUPON_BTN"
              translateWith={{ couponLabel: checkoutConfig?.terminologyCouponSingular }}
              id="apply-coupon"
              onClick={onAddCoupon}
            />
          </div>
        </>
      </Modal>

      {
        !!plan && (
          <Modal isOpen={isOpenSummaryModal} onRequestClose={() => setIsOpenSummaryModal(false)}>
            {/*<PaymentDescription
              paymentDescription={paymentDescription}
              isLoading={paymentDescriptionIsLoading}
              planType={plan.value.pricingModelType}
              taxRegNumber={taxRegNumber}
              checkoutConfig={checkoutConfig}
            />*/}
            <PricingInfoDisclosure
              rawHTMLEncoded={pricingInfoDisclosure}
            />
          </Modal>
        )
      }

      {
        <Modal width="340px"
          height="172px"
          isOpen={isOpenTaxNumberModal}
          title={taxName.short}
          shouldTranslateTitle={false}
          onRequestClose={() => setIsOpenTaxNumberModal(false)}>
          <TaxInput isInlineDisplay={false} onSubmit={() => setIsOpenTaxNumberModal(false)} />
        </Modal>
      }



    </PageWrapper>
  )
}

export default ChangePlanStep4