import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { SET_CHANGE_ADDRESS_FIELD, RESET_CHANGE_ADDRESS_STATE } from "../actions/changeAddressActions";
import { CROSS_DOMAIN_EVENT, sendEvent } from "../utils/crossDomainEvents";

export interface IChangeAddressReducer {
  isChangeAddressSuccess: boolean,
  isChangeAddressFailure: boolean
}

export const initialState: IChangeAddressReducer = {
  isChangeAddressSuccess: false,
  isChangeAddressFailure: false
}

export default function changeAddressReducer(state = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case SET_CHANGE_ADDRESS_FIELD:
      if(action.fieldName === "isChangeAddressSuccess" && action.fieldValue === true) {
        sendEvent(CROSS_DOMAIN_EVENT.CHANGE_ADDRESS_DETAILS_SUCCESS)
      }
      return { ...state, [action.fieldName]: action.fieldValue }
    case RESET_CHANGE_ADDRESS_STATE:
      return {...initialState}
    default:
      return state;
  }
}