import React, { useMemo } from "react";
import { ISelectPlanReducer } from "../../../reducers/selectPlanReducer";
import { setNumberOfUnits, setPlan } from "../../../actions/selectPlanActions";
import { getMaximumUnit, recalculatePlanCycle, getMaximumUnitCycle } from "../../../utils/planUtils";
import { AppState } from "../../..";
import { IGlobalReducer } from "../../../reducers/globalReducer";
import NumberPicker from "../number-picker/NumberPicker";
import { useDispatch, useSelector } from "react-redux";


const PlanUnitPicker: React.FC = () => {
  const { checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const { plan, cycle: selectedCycle, numberOfUnits, validUnitRange, planId, plansDropdown } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);

  const dispatch = useDispatch<Function>()

  const isLocked = useMemo(() => {
    const preselectedPlan = plansDropdown.find(plan => plan.value.planId === planId);
    return Boolean(preselectedPlan && numberOfUnits)
  }, [plansDropdown, plan, planId])

  if (!plan) {
    return <div />;
  }

  const onNumberOfUnitsChanged = (units: number) => {
    dispatch(setNumberOfUnits(units));
    const currentPlan = { ...plan };
    currentPlan.value = recalculatePlanCycle(currentPlan.value, units, []);
    dispatch(setPlan(currentPlan));

    // HIDE FOR NOw
    // const isValidRange = isValidNumberOfUnits(plan.value, units);
    // if (!isValidRange)
    //   dispatch(setCycle(undefined));

    // dispatch(setValidUnitRange(isValidRange));
  }

  if (!checkoutConfig) {
    return null;
  }

  const updateUnits = (numberOfUnits: number | undefined, action: "plus" | "minus") => {
    if (numberOfUnits === undefined) {
      if (action === "plus") {
        dispatch(setNumberOfUnits(1))
      }
    } else {
      onNumberOfUnitsChanged(action === "plus" ? Number(numberOfUnits) + 1 : Number(numberOfUnits) - 1)
    }
  }

  return (
    <div>
      <NumberPicker
        value={numberOfUnits}
        min={1}
        max={getMaximumUnit(plan.value)}
        minLock={numberOfUnits as any === "" || numberOfUnits === 1 || numberOfUnits === undefined || isLocked}
        // maxLock={plan && plan.value.pricingModelType !== PricingModelType.PerUnit && Boolean(numberOfUnits && numberOfUnits >= getMaximumUnit(plan.value))}
        maxLock={(selectedCycle ? ((numberOfUnits || 1000000) >= getMaximumUnitCycle(selectedCycle)) : false) || isLocked}
        onMinus={() => updateUnits(numberOfUnits, "minus")}
        onPlus={() => updateUnits(numberOfUnits, "plus")}
        isInputDisabled={isLocked}
        onChange={(value) => {
          onNumberOfUnitsChanged(value);
        }}>
      </NumberPicker>
    </div>
  )
}

export default PlanUnitPicker