import React, { useEffect } from "react";
import SeeMoreText from "../../ui/see-more-text/SeeMoreText";
import { calculateAddOnPrice } from "../../../utils/planUtils";
import { IAllowancePricingModel, IAllowance } from "../../../models/AddOnAllowance";
import { ICheckoutConfig } from "../../../models/BillsbyData";
import { ICycle, IPlanWithCycles } from "../../../models/Product";
import { IBranding } from "../../../models/Branding";
import "./AllowanceSelector.scss";
import Selector from "../../ui/selector/Selector";
import AllowancePriceText from "../allowance-price-text/AllowancePriceText";
import SystemInfo from "../../ui/system-info/SystemInfo";
import { isMobileOnly } from "react-device-detect";
import { Col, Row } from "react-grid-system";
import FormLabel from "../../ui/form-label/FormLabel";
import CollapsiblePanel from "../../ui/collapsible-panel/CollapsiblePanel";

interface IAllowanceSelector {
  allowance: IAllowance;
  plan: IPlanWithCycles;
  cycle: ICycle;
  branding: IBranding;
  checkoutConfig: ICheckoutConfig;
  updateAddons: (allowanceId: number, pricingModels: IAllowancePricingModel[], units?: number) => void;
  isLocked?: boolean;
  // this flag turns to true after removing an allowance from the account management
  willBeDeleted?: boolean;
  nextBillingDate?: string,
  image?: string
}

const AllowanceSelector: React.FC<IAllowanceSelector> = ({
  allowance,
  plan,
  cycle,
  branding,
  checkoutConfig,
  updateAddons,
  isLocked = false,
  willBeDeleted = false,
  nextBillingDate,
  image
}) => {


  useEffect(() => {
    if (Number(allowance.units)) {
      // in case of editing an addon we calculate the price immediately
      onUnitsChange(allowance, Number(allowance.units));
    } else {
      const initialUnit = allowance.isForced ? 1 : 0;
      onUnitsChange(allowance, initialUnit);
    }
  }, []);

  const onUnitsChange = (allowance: IAllowance, units: number) => {
    if (allowance.isForced) {
      units = units < 1 ? 1 : units;
    } else {
      units = units < 0 ? 0 : units;
    }

    if (!cycle) return;

    const pricingModels: Array<IAllowancePricingModel> = allowance.pricingModels.map(pricingModel => {
      if (pricingModel.frequency === cycle.pricingModel.frequency && pricingModel.frequencyType === cycle.pricingModel.frequencyType) {
        return calculateAddOnPrice(cycle, pricingModel, units, allowance.pricingModelType) as IAllowancePricingModel;
      }
      return pricingModel;
    });

    updateAddons(allowance.id, pricingModels, units);
  };

  const renderAllowanceDetails = () => {
    return (
      <Row>
        <Col xs={9}>
          <AllowancePriceText
            allowance={allowance}
            plan={plan}
            cycle={cycle}
            checkoutConfig={checkoutConfig}
            branding={branding}
            showPreviewPriceForComplexType={true}
            viewPriceModalBtnText={"ALLOWANCE_PRICE_TEXT_VIEW_PRICES"}
          />
        </Col>
        <Col xs={3}>
          <div className="allowance-selector__selector-container">
            <Selector
              className="allowance-selector__btn"
              isLock={allowance.isForced}
              selected={!!allowance.units}
              onClick={() => !allowance.isForced ? onUnitsChange(allowance, !!allowance.units ? 0 : 1) : {}}
            >
            </Selector>
          </div>
        </Col>
      </Row>
    )
  }


  return (
    <>
      <SystemInfo
        className="allowance-selector__will-be-deleted"
        title="MANAGE_ALLOWANCE_WILL_BE_DELETED"
        translateWith={{ nextBilling: nextBillingDate, allowanceLabel: checkoutConfig?.terminologyAllowanceSingular }}
        type="warning"
        isVisible={willBeDeleted}
      />
      <CollapsiblePanel
        id="allowance-unit-input"
        isClickable
        style={(isLocked || willBeDeleted) ? { pointerEvents: "none", opacity: "0.5" } : undefined}
        className={`allowance-selector ${(isLocked || willBeDeleted) ? "allowance-selector--locked" : ""}`}
        key={`add-on-${allowance.id}`}
        onClick={() => { }}
        image={image}
        imageLayout="dynamic"
        isExpanded
        isFloating={false}
        isModal
        expandableContent={isMobileOnly ? renderAllowanceDetails() : undefined}
      >
        {
          isMobileOnly ? (
            <div className="allowance-selector__mobile">
              <FormLabel content={allowance.displayName || allowance.name} shouldTranslate={false} weight="bold" noMargin />
              <SeeMoreText content={allowance.description} shouldTranslateContent={false} />
            </div>
          ) : (
            <>
              <div>
                <FormLabel content={allowance.displayName || allowance.name} shouldTranslate={false} weight="bold" noMargin />
                <SeeMoreText content={allowance.description} shouldTranslateContent={false} />
              </div>
              {renderAllowanceDetails()}
            </>
          )
        }
      </CollapsiblePanel>
    </>
  );
};

export default AllowanceSelector;
