import React, { useMemo } from "react";
import { IPlanWithCycles, ICycle } from "../../../models/Product";
import { getContractTermText, isAddOn } from "../../../utils/planUtils";
import AddOnSummary from "../addon-summary/AddOnSummary";
import AddOnPriceText from "../addon-price-text/AddonPriceText";
import { IAddOn, IAllowance, IAddOnPricingModel, IAllowancePricingModel } from "../../../models/AddOnAllowance";
import { useSelector } from "react-redux";
import { AppState } from "../../..";
import { IGlobalReducer } from "../../../reducers/globalReducer";
import { ICheckoutConfig } from "../../../models/BillsbyData";
import { IAppliedCoupon } from "../../../models/DiscountCoupon";
import { DeductionType, DiscountType } from "../../../utils/grpc/generated/Billsby.Protos/billing/public/coupons/coupons_pb";
import PaymentSummary from "../../ui/payment-summary/PaymentSummary";
import "./AddOnSummaryList.scss";

interface IAddOnSummaryList {
  productName: string;
  plan: IPlanWithCycles;
  cycle: ICycle;
  selectedAddOnAllowance: Array<IAddOn | IAllowance>;
  appliedCoupons?: Array<IAppliedCoupon>;
  currencyCode?: string;
  onEdit: (addon: IAddOn | IAllowance) => void;
  onDelete: (addon: IAddOn | IAllowance) => void;
  isEditable: (addon: IAddOn | IAllowance) => boolean;
  isDeletable: (addon: IAddOn | IAllowance) => boolean;
  isLocked: (addon: IAddOn | IAllowance) => boolean;
}

const AddOnSummaryList: React.FC<IAddOnSummaryList> = ({
  productName,
  plan,
  cycle,
  selectedAddOnAllowance,
  onEdit,
  onDelete,
  isEditable,
  isLocked,
  isDeletable,
  appliedCoupons,
  currencyCode = "USD",
}) => {
  const { checkoutConfig, branding } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { setupFeePriceFormatted, contractTerm, contractTermFrequencyType } = cycle.pricingModel;

  let contractTermText = getContractTermText(contractTerm, contractTermFrequencyType);
  contractTermText = setupFeePriceFormatted ? contractTermText.toLowerCase() : contractTermText;

  const discountsForSubscription = useMemo(() => {
    if (appliedCoupons) {
      return appliedCoupons.filter((ac) => ac.discount.getDeductionType() === DeductionType.ENTIRE_SUBSCRIPTION && ac.discount.getDiscountType() === DiscountType.PERCENTAGE);
    }
  }, [appliedCoupons]);


  return (
    <div className="addon-allowance-summary">
      <PaymentSummary
        pricingModel={cycle.pricingModel}
        productName={productName}
        planName={plan.displayName}
        planImageUrl={plan.planImageUrl}
        hasTitle={false}
        currencyCode={cycle.currency}
        appliedCoupons={appliedCoupons}
        addonsAllowancesSummaryList={true}
      />

      {
        selectedAddOnAllowance.map((addon, idx) => {
          const pricingModel = (addon.pricingModels as Array<IAddOnPricingModel | IAllowancePricingModel>).find(
            (i) => i.frequency === cycle.pricingModel.frequency && i.frequencyType === cycle.pricingModel.frequencyType
          );
          if (!pricingModel) {
            return <div />;
          }
          return (
            <React.Fragment key={idx}>
              <AddOnSummary
                title={addon.name}
                subTitle={
                  <AddOnPriceText
                    addon={addon}
                    plan={plan}
                    cycle={cycle}
                    checkoutConfig={checkoutConfig as ICheckoutConfig}
                    branding={branding}
                    viewPriceModalBtnText={isAddOn(addon) ? "VIEW_PRICING_INFO" : "SELECT_ALLOWANCES_VIEW_OVERAGE"}
                    showPreviewPriceForComplexType={true}
                  />
                }
                description={addon.description}
                isLocked={isLocked(addon)}
                isEditable={isEditable(addon)}
                onEdit={() => onEdit(addon)}
                isDeletable={isDeletable(addon)}
                onDelete={() => onDelete(addon)}
              />
            </React.Fragment>
          );
        })
      }
    </div>
  );
};

export default AddOnSummaryList
