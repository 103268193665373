import counterpart from "counterpart";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { payContractTerm, setChangePlanField } from "../../actions/changePlanActions";
import { fetchPendingMinimumTermAmount, setCurrentSubscription } from "../../actions/subscriptionsActions";
import { resetUpdatePaymentState } from "../../actions/updatePaymentActions";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import UpdatePaymentDetails from "../../containers/update-payment-details/UpdatePaymentDetails";
import { ICustomerSubscription } from "../../models/Customer";
import { IChangePlanReducer } from "../../reducers/changePlanReducer";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";
import { IUpdatePaymentReducer } from "../../reducers/updatePaymentReducer";
import { getContractMinimumTermInfo } from "../../utils/commonUtils";
import history from "../../utils/history";
import LoadingAnimation from "../ui/loading-animation/LoadingAnimation";
import Panel from "../ui/panel/Panel";
import SystemInfo from "../ui/system-info/SystemInfo";
import Text from "../ui/text/Text";

interface IEndContractTerm {
  btnBackCallback: () => void;
  btnBackHidden?: boolean
}

const EndContractTerm: React.FC<IEndContractTerm> = ({ btnBackCallback, btnBackHidden = false }) => {

  const { companyDomain, checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { currentSubscription, isFetchPendingMinTermRequest, pendingMinimumTermAmount } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const { mainProps: { subscriptionUniqueId, customerUniqueId } } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { isPayContractTermRequest, isPayContractTermSuccess, isPayContractTermFailure } = useSelector<AppState, IChangePlanReducer>(state => state.changePlanReducer);
  const { isUpdatingPaymentDetailsFailure } = useSelector<AppState, IUpdatePaymentReducer>(state => state.updatePaymentReducer);
  const dispatch = useDispatch<Function>()

  const { isPendingContractMinimumTermEnd, contractMinimumTermEnd } = getContractMinimumTermInfo(currentSubscription as ICustomerSubscription);
  const [tryAnotherCard, setTryAnotherCard] = useState(false);
  const [showWarning, setShowWarning] = useState(true);

  useEffect(() => {
    const fetchMinTermAmt = async () => {
      try {
        await dispatch(fetchPendingMinimumTermAmount(companyDomain, customerUniqueId as string, subscriptionUniqueId as string))
      } catch (err) {
        console.log(err)
      }
    }

    fetchMinTermAmt()
  }, [])

  const payAndEndContractTerm = async () => {
    try {
      await dispatch(payContractTerm(companyDomain, customerUniqueId as string, subscriptionUniqueId as string));
    } catch (err) {
      console.log(err)
      setTryAnotherCard(false);
    }
  }

  useEffect(() => {
    if (isPayContractTermSuccess && currentSubscription) {
      dispatch(setCurrentSubscription({ ...currentSubscription, contractMinimumTermEnd: undefined }))
      history.push({ pathname: "/management", search: history.location.search });
    }
  }, [isPayContractTermSuccess, currentSubscription]);



  const renderContent = () => {
    if (showWarning && currentSubscription && isPendingContractMinimumTermEnd) {
      return (
        <SystemInfo
          title={counterpart("CHANGE_PLAN_CONTRACT_TERM_WARNING_TITLE", { contractMinimumTermEnd: moment(contractMinimumTermEnd).format("DD MMM YYYY") })}
          shouldTranslateTitle={false}
          text="CHANGE_PLAN_CONTRACT_TERM_WARNING_SUBTITLE"
          translateWith={{
            planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular,
            link: (
              <Text
                onClick={() => setShowWarning(false)}
                className="select-plan__pay-link"
                component="span"
                noMargin
                content="CHANGE_PLAN_CONTRACT_TERM_WARNING_SUBTITLE_LINK"
              />
            )
          }}
          type={"warning"}
        />
      )
    }

    if (isFetchPendingMinTermRequest) {
      return <LoadingAnimation />
    }

    if (isPayContractTermFailure || isUpdatingPaymentDetailsFailure) {
      return (
        <SystemInfo type="warning" title="PAYMENT_ERROR_TITLE" text="PAYMENT_ERROR_SUBTITLE" ></SystemInfo>
      )
    }

    if (!pendingMinimumTermAmount) {
      return <div />
    }

    return (
      <Panel id="end-contract-term">
        <Text 
          content="END_CONTRACT_TERM_CONTENT" 
          translateWith={{ amount: pendingMinimumTermAmount.getFormattedAmount(), cardType: pendingMinimumTermAmount.getCardType(), lastFourDigits: pendingMinimumTermAmount.getCardLastFourDigits() }} 
          noMargin
        />
      </Panel>
    )
  }

  if (tryAnotherCard && !isUpdatingPaymentDetailsFailure) {
    return (
      <UpdatePaymentDetails
        title="END_CONTRACT_TERM_TITLE"
        /*btnText="END_CONTRACT_TERM_BTN"
        btnTextTranslateWith={{ amount: pendingMinimumTermAmount && pendingMinimumTermAmount.getFormattedAmount() }}*/
        isLoading={isPayContractTermRequest || isFetchPendingMinTermRequest}
        btnBackCallback={btnBackCallback}
        customSuccessSubtitle="ACCOUNT_MANAGEMENT_UPDATE_PAYMENT_SUCCESS_PAY_CONTRACT_TERM"
      >
      </UpdatePaymentDetails>
    )
  }

  return (
    <PageWrapper
      title="END_CONTRACT_TERM_TITLE"
      className='select-plan select-plan-step2'
      btnBackCallback={btnBackCallback}
      btnBackHidden={btnBackHidden}
      btnCallback={showWarning ? undefined
        : !tryAnotherCard && isPayContractTermFailure || isUpdatingPaymentDetailsFailure ? () => {
          setTryAnotherCard(true);
          dispatch(resetUpdatePaymentState());
          dispatch(setChangePlanField("isPayContractTermFailure", false));
        }
          : payAndEndContractTerm}
      btnText={isPayContractTermFailure || isUpdatingPaymentDetailsFailure ? "PAYMENT_TRY_ANOTHER_CARD" : "END_CONTRACT_TERM_BTN"}
      isLoading={isPayContractTermRequest}
      btnTextTranslateWith={{ amount: pendingMinimumTermAmount && pendingMinimumTermAmount.getFormattedAmount() }} //TO DO: Get amount to end contract term
    >
      {renderContent()}
    </PageWrapper>
  )
}

export default EndContractTerm