import React, { useEffect, useMemo, useState } from "react";
import PageWrapper from "../page-wrapper/PageWrapper";
import Panel from "../../components/ui/panel/Panel";
import FormGroup from "../../components/ui/form-group/FormGroup";
import FormLabel from "../../components/ui/form-label/FormLabel";
import { Row, Col } from "react-grid-system";
import Input from "../../components/ui/input/Input";
import PhoneInput from "../../components/ui/phone-input/PhoneInput";
import { AppState } from "../..";
import { IChangePersonalDetailsReducer } from "../../reducers/changePersonalDetailsReducer";
import { setChangePersonalDetailsField, fetchPersonalDetails, changePersonalDetails, fetchProductById } from "../../actions/changePersonalDetailsActions";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { PersonalDetails } from "../../models/PersonalDetails";
import history from "../../utils/history";
import { useFetch } from "../../utils/custom-hooks";
import { AccountManagementPermissions } from "../../models/AccountManagement";
import counterpart from "counterpart";
import { isMobileOnly } from "react-device-detect";
import { CollectCompanyNameType } from "../../models/BillsbyData";
import { trimWhiteSpaces } from "../../utils/commonUtils";
import { useDispatch, useSelector } from "react-redux";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";


const ChangePersonalDetails: React.FC = () => {
  const { firstName, lastName, email, companyName, product, phoneNumberDialCode, phoneNumberDialCountry, phoneNumber, billingAddress, isChangePersonalDetailsRequest,
    isChangePersonalDetailsSuccess, isChangePersonalDetailsFailure } = useSelector<AppState, IChangePersonalDetailsReducer>(state => state.changePersonalDetailsReducer);
  const { companyDomain, checkoutConfig, accountManagementPermissions } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const personalDetailsReducer = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer)
  const customerUniqueId = personalDetailsReducer.mainProps.customerUniqueId as string;
  const { currentSubscription } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer)

  const dispatch = useDispatch<Function>()
  const { isLoading } = useFetch(() => dispatch(fetchPersonalDetails(companyDomain, customerUniqueId)));
  const { isLoading: isLoadingProduct } = useFetch(() => dispatch(fetchProductById(companyDomain, currentSubscription?.productId as number)));
  
  const collectCompanyNameType = checkoutConfig?.collectCompanyNameType as CollectCompanyNameType;
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

  useEffect(() => {
    if (isChangePersonalDetailsSuccess || isChangePersonalDetailsFailure) {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }, [isChangePersonalDetailsSuccess || isChangePersonalDetailsFailure]);

  const isCompanyNameVisible = useMemo(() => {
    return collectCompanyNameType === CollectCompanyNameType.Compulsory || collectCompanyNameType === CollectCompanyNameType.Optional;
  }, [collectCompanyNameType])

  const isCompanyNameValid = useMemo(() => {
    if(collectCompanyNameType === CollectCompanyNameType.Compulsory && !companyName) {
      return false;
    }
    return true;
  }, [collectCompanyNameType, companyName])

  const isPhoneNumberSubmittable = useMemo(() => {
    if(product?.isPhoneNumberRequired && phoneNumber && isPhoneNumberValid) {
      return true
    }
    if(!product?.isPhoneNumberRequired && !phoneNumber) { 
      return true
    }
    if(!product?.isPhoneNumberRequired && phoneNumber && isPhoneNumberValid) {
      return true
    } 
    return false
  }, [phoneNumber, product, isPhoneNumberValid])

  const onSubmit = () => {
    const newPersonalDetails: Partial<PersonalDetails> = {
      firstName, lastName, email, companyName, phoneNumber, phoneNumberDialCode, phoneNumberDialCountry, billingAddress
    };
    dispatch(changePersonalDetails(companyDomain, customerUniqueId, newPersonalDetails));
  }

  if (isLoading || isLoadingProduct || !checkoutConfig) {
    return null;
  }

  const goBackCallback = () => {
    if (accountManagementPermissions === AccountManagementPermissions.FULL && history.location.pathname !== "/management") {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }

  return (
    <PageWrapper
      btnText="CHANGE_PERSONAL_DETAILS_SAVE_CHANGES"
      btnDisabled={!firstName || !lastName || !email || !isPhoneNumberSubmittable || !isCompanyNameValid || isChangePersonalDetailsRequest}
      isLoading={isChangePersonalDetailsRequest}
      btnCallback={onSubmit} 
      btnBackCallback={goBackCallback} 
      btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION}
      title="CHANGE_PERSONAL_DETAILS_HEADING_TITLE"
      titlePosition="left-aligned"
      subTitle="CHANGE_PERSONAL_DETAILS_HEADING"
      subTitleTranslateWith={{ subscriptionLabel: checkoutConfig.terminologySubscriptionPlural }}
    >
      <Panel id="change-personal-details" noMargin>
        <FormGroup>
          <FormLabel content="CHANGE_PERSONAL_DETAILS_NAME" />
          <Row align="center">
            <Col sm={6} style={isMobileOnly ? { marginBottom: 20 } : {}}>
              <Input
                id="first-name"
                placeholder={counterpart("PERSONAL_DETAILS_FIRST_NAME")}
                value={firstName} 
                onChange={(evt: any) => dispatch(setChangePersonalDetailsField("firstName", evt.target.value))} 
              />
            </Col>
            <Col sm={6}>
              <Input
                id="last-name"
                placeholder={counterpart("PERSONAL_DETAILS_LAST_NAME")}
                value={lastName} 
                onChange={(evt: any) => dispatch(setChangePersonalDetailsField("lastName", evt.target.value))} 
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <FormLabel content="CHANGE_PERSONAL_DETAILS_EMAIL" />
          <Input 
            id="email"
            value={email} 
            placeholder={counterpart("CHANGE_PERSONAL_DETAILS_EMAIL_PLACEHOLDER")} 
            onChange={(evt: any) => dispatch(setChangePersonalDetailsField("email", evt.target.value))} 
          />
        </FormGroup>
        {isCompanyNameVisible && <FormGroup>
          <FormLabel content="CHANGE_PERSONAL_DETAILS_COMPANY_NAME" />
          <Input 
            id="company-name"
            value={companyName} 
            placeholder={counterpart("CHANGE_PERSONAL_DETAILS_COMPANY_NAME_PLACEHOLDER")} 
            onChange={(evt: any) => dispatch(setChangePersonalDetailsField("companyName", trimWhiteSpaces(evt.target.value, "left")))} 
          />
        </FormGroup>}
        <FormGroup>
          <FormLabel content="CHANGE_PERSONAL_DETAILS_PHONE" />
          <PhoneInput
            preferredCountries={["US", "GB", "AU", "CA"]}
            value={phoneNumber}
            defaultCountry={phoneNumberDialCountry}
            dialCode={phoneNumberDialCode}
            isRequired={product?.isPhoneNumberRequired}
            onPhoneNumberChange={(isValid: boolean, phone: string, countryData: any) => {
              setIsPhoneNumberValid(isValid);
              if (countryData.dialCode) {
                dispatch(setChangePersonalDetailsField("phoneNumberDialCountry", countryData.iso2));
                dispatch(setChangePersonalDetailsField("phoneNumberDialCode", countryData.dialCode));
                dispatch(setChangePersonalDetailsField("phoneNumber", phone));
              }
            }}
            onSelectFlag={(phone: string, countryData: any, _, isValid) => {
              setIsPhoneNumberValid(isValid);
              dispatch(setChangePersonalDetailsField("phoneNumberDialCountry", countryData.iso2));
              dispatch(setChangePersonalDetailsField("phoneNumberDialCode", countryData.dialCode));
              dispatch(setChangePersonalDetailsField("phoneNumber", phone));
            }}
            errorLabel="CUSTOM_FIELDS_PHONE_NUMBER_INVALID"
            separateDialCode
          />
        </FormGroup>
      </Panel>
    </PageWrapper>
  )
}

export default ChangePersonalDetails