interface IConfig {
  reactAppEnvironment: "Localhost" | "Development" | "Test" | "Staging" | "Production",
  baseUrl: string,
  clientId: string,
  billsbyDomain: string,
  spreedlyKey: string,
  grpcBaseUrl: string,
  instrumentationKey: string,
  googleFontApiKey: string,
  stripePublicKey: string,
  reCaptchaEnterpriseKey: string
}

export let ConfigConstants: IConfig = (window as any).ConfigConstants || {};