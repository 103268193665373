import { SelectPlanScreen } from "../models/SelectPlan";
import { SET_SELECTPLAN_CURRENT_SCREEN, SET_ACTIVE_PLAN_ID, SET_PRODUCT, SET_SELECTED_PRICING_MODEL, SET_VALID_UNIT_RANGE, SET_NUMBER_OF_UNITS, SET_SHOW_PRICING_INFO, SET_PLAN, FETCH_PRODUCTS_SUCCESS, FETCH_PLANS_SUCCESS, SET_CYCLE, FETCH_PRODUCTS_FAILURE, SET_PLAN_ID, SET_PRODUCT_ID, SET_CYCLE_ID, FETCH_PLANS_REQUEST, FETCH_PLANS_FAILURE, RESET_SELECTPLAN_STATE, SET_HAS_SELECTED_CYCLE } from "../actions/selectPlanActions";
import { IProduct, IPlanWithCycles, ICycle, IPricingModel, VisibilityType } from "../models/Product";
import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { IAddOn, IAllowance } from "../models/AddOnAllowance";
import { ContainerWebsite, IBillsbyData } from "../models/BillsbyData";

export interface ISelectPlanReducer {
  currentScreen: SelectPlanScreen,
  product: { label: string, value: IProduct } | null,
  activePlanId: number | null,
  plan: { label: string, value: IPlanWithCycles } | null,
  cycle: ICycle | null,
  //contains the preloaded product
  productId: number | undefined,
  //contains the preloaded plan
  planId: number | undefined,
  //contains the preloaded cycle
  cycleId: number | undefined,
  isFetchingProducts: boolean,
  isFetchingProductsFailure: boolean,
  products: Array<IProduct>,
  productsDropdown: Array<{ label: string, value: IProduct }>,
  productsDropdownAvailable: Array<{ label: string, value: IProduct }>,
  plans: Array<IPlanWithCycles>,
  isFetchingPlans: boolean,
  plansDropdown: Array<{ label: string, value: IPlanWithCycles }>,
  plansDropdownAvailable: Array<{ label: string, value: IPlanWithCycles }>,
  showMorePricingInfo: boolean,
  selectedPricingModel?: IPricingModel,
  numberOfUnits?: number,
  validUnitRange: boolean,
  // addons and allowances filtered by the selected pricing model!
  addOnsFilteredByCycle: Array<IAddOn | IAllowance>,
  allowancesFilteredByCycle: Array<IAddOn | IAllowance>
}

export const initialState = {
  currentScreen: SelectPlanScreen.PRODUCT,
  activePlanId: null,
  product: null,
  plan: null,
  cycle: null,
  productId: undefined,
  planId: undefined,
  cycleId: undefined,
  isFetchingProducts: true,
  isFetchingProductsFailure: false,
  products: [],
  productsDropdown: [],
  productsDropdownAvailable: [],
  plans: [],
  isFetchingPlans: false,
  plansDropdown: [],
  plansDropdownAvailable: [],
  showMorePricingInfo: false,
  selectedPricingModel: undefined,
  numberOfUnits: undefined,
  validUnitRange: true,
  addOnsFilteredByCycle: [],
  allowancesFilteredByCycle: []
}

export default function selectPlanReducer(state: ISelectPlanReducer = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case SET_SELECTPLAN_CURRENT_SCREEN:
      return { ...state, currentScreen: action.payload }
    case SET_PRODUCT:
      return { ...state, product: action.payload }
    case SET_PLAN:
      return { ...state, plan: action.payload }
    case SET_NUMBER_OF_UNITS:
      return { ...state, numberOfUnits: action.payload }
    case SET_VALID_UNIT_RANGE:
      return { ...state, validUnitRange: action.payload }

    case SET_CYCLE: {
      const { product, plan } = state;
      const cycle = action.payload;

      const getAddOnsFiltered = () => {
        const { currentSubscription } = store.subscriptionReducer;

        if (!product || !plan || !plan.value || !cycle) {
          return [];
        }
        const addonsFiltered = plan.value.addons.filter(addon => addon.pricingModels.some(p => {
          return p.frequency === cycle.pricingModel.frequency
            && p.frequencyType === cycle.pricingModel.frequencyType
            && p.currency.isoCode === (product.value ? (product.value as IProduct).billingCurrency : currentSubscription ? currentSubscription.currency : "USD");
        }))
        return [...addonsFiltered];
      }

      const getAllowancesFiltered = () => {
        const { currentSubscription } = store.subscriptionReducer;

        if (!product || !plan || !plan.value || !cycle) {
          return [];
        }
        const allowancesFiltered = plan.value.allowances.filter(allowance => allowance.pricingModels.some(p => {
          return p.frequency === cycle.pricingModel.frequency
            && p.frequencyType === cycle.pricingModel.frequencyType
            && p.currency.isoCode === (product.value ? (product.value as IProduct).billingCurrency : currentSubscription ? currentSubscription.currency : "USD")
        }))
        return [...allowancesFiltered];
      }

      return {
        ...state,
        cycle,
        addOnsFilteredByCycle: getAddOnsFiltered(),
        allowancesFilteredByCycle: getAllowancesFiltered()
      }

    }
    case SET_PRODUCT_ID:
      return { ...state, productId: action.payload }
    case SET_PLAN_ID:
      return { ...state, planId: action.payload }
    case SET_SELECTED_PRICING_MODEL:
      return { ...state, selectedPricingModel: action.payload }
    case SET_SHOW_PRICING_INFO:
      return { ...state, showMorePricingInfo: action.payload }
    case SET_CYCLE_ID:
      return { ...state, cycleId: action.payload }
    case FETCH_PRODUCTS_SUCCESS: {
      const preloadedCustomerData = store.globalReducer.preloadedCustomerData as IBillsbyData;
      const isContainerWebsiteBillsby = preloadedCustomerData.containerWebsite === ContainerWebsite.BILLSBY_APP;
      let products: Array<IProduct> = action.response;

      // if the container website running the checkout is billsby app we will set all the products visibility to PUBLIC
      // because everything has to be visibile for the company
      if (isContainerWebsiteBillsby) {
        products = products.map(p => ({ ...p, visibility: VisibilityType.Public }))
      }

      const productsDropdown: Array<{ label: string, value: IProduct }> = products
        .map((product: IProduct) => ({ label: product.displayName || product.name, value: product }))
      productsDropdown.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1);

      let productsDropdownAvailable = productsDropdown.filter(p => p.value.visibility === VisibilityType.Public && !p.value.isDeleted);

      return {
        ...state,
        products,
        productsDropdown: productsDropdown.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1),
        productsDropdownAvailable,
        isFetchingProducts: false,
        isFetchingProductsFailure: false
      }
    }
    case FETCH_PRODUCTS_FAILURE:
      return { ...state, isFetchingProducts: false, isFetchingProductsFailure: true }
    case FETCH_PLANS_REQUEST:
      return { ...state, isFetchingPlans: true }
    case SET_HAS_SELECTED_CYCLE:
      return { ...state, hasSelectedACycle: action.payload }
    case SET_ACTIVE_PLAN_ID:
      return { ...state, activePlanId: action.payload }
    case FETCH_PLANS_SUCCESS: {
      const preloadedCustomerData = store.globalReducer.preloadedCustomerData as IBillsbyData;
      const isContainerWebsiteBillsby = preloadedCustomerData.containerWebsite === ContainerWebsite.BILLSBY_APP;
      let plans: Array<IPlanWithCycles> = action.response;

      // if the container website running the checkout is billsby app we will set all the plans and cycles visibility to PUBLIC 
      // because everything has to be visibile for the company
      if (isContainerWebsiteBillsby) {
        plans = plans.map(p => ({
          ...p, 
          visibility: VisibilityType.Public,
          cycles: p.cycles.map(c => ({ ...c, visibility: c.visibility !== VisibilityType.OffSale ? VisibilityType.Public : c.visibility }))
        }))
      }

      const plansDropdown: Array<{ label: string, value: IPlanWithCycles }> = plans
        .map((plan: IPlanWithCycles) => ({ label: plan.displayName || plan.name, value: plan }));

      let plansDropdownAvailable: Array<{ label: string, value: IPlanWithCycles }> = [];
      if (state.planId) {
        // if a plan is preloaded we have to hide all the plans but the preloaded one
        plansDropdownAvailable = plansDropdown.filter(plan => plan.value.planId === state.planId);
      }
      else {
        const { currentSubscription } = store.subscriptionReducer;
        const filteredPlans = currentSubscription ? plansDropdown.filter(p => p.value.planId !== currentSubscription.planId) : plansDropdown;
        plansDropdownAvailable = filteredPlans.filter(p => p.value.visibility === VisibilityType.Public && !p.value.isDeleted);
      }

      return {
        ...state,
        isFetchingPlans: false,
        plans,
        plansDropdown,
        plansDropdownAvailable,
        activePlanId: plansDropdownAvailable.length === 1 ? plansDropdownAvailable[0].value.planId : state.activePlanId,
        plan: plansDropdownAvailable.length === 1 ? plansDropdownAvailable[0] : state.plan
      }
    }
    case FETCH_PLANS_FAILURE:
      return { ...state, isFetchingPlans: false }
    case RESET_SELECTPLAN_STATE:
      return { ...initialState }
    default:
      return state;
  }
}