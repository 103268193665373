import React, { useLayoutEffect, useState, useEffect } from "react";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import FormGroup from "../ui/form-group/FormGroup";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";
import { fetchCustomerSubscriptions, fetchRefundPolicy, fetchSubscriptionCancellationDetails, fetchSubscriptionOutstandingBalance } from "../../actions/subscriptionsActions";
import { IGlobalReducer } from "../../reducers/globalReducer";
import history from "../../utils/history";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import moment from "moment";
import { ICustomerSubscription, SubscriptionStatusType } from "../../models/Customer";
import { removeCustomerSubscription, setRetentionField, saveExitReason } from "../../actions/retentionActions";
import { IRetentionReducer } from "../../reducers/retentionReducer";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import { RetentionScreen } from "../../models/Retention";
import { setPlan, setNumberOfUnits, fetchPlans, setCycle } from "../../actions/selectPlanActions";
import { PricingModelType, IPlanWithCycles } from "../../models/Product";
import { recalculatePlanCycle, showTaxLabel } from "../../utils/planUtils";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import CollapsiblePanel from "../ui/collapsible-panel/CollapsiblePanel";
import PlanCycles from "../select-plan/plan-cycles/PlanCycles";
import PaymentSummaryCoupons from "../ui/payment-summary/PaymentSummaryCoupons";
import { IDiscountCouponReducer } from "../../reducers/discountCouponReducer";
import { fetchSubscriptionDiscounts } from "../../actions/discountCouponActions";
import { fetchSubscriptionAddons, fetchSubscriptionAllowances } from "../../actions/addOnActions";
import { fetchPersonalDetails } from "../../actions/changePersonalDetailsActions";
import { getPriceDescription } from "../../actions/paymentActions";
import { IAddOnReducer } from "../../reducers/addOnReducer";
import counterpart from "counterpart";
import { IPaymentReducer } from "../../reducers/paymentReducer";
import LoadingAnimation from "../ui/loading-animation/LoadingAnimation";
import { RefundPolicy } from "../../utils/grpc/generated/Billsby.Protos/billing/public/refundpolicy/refundpolicy_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { BillsbyDataAction } from "../../models/BillsbyData";
import { sendEvent, CROSS_DOMAIN_EVENT } from "../../utils/crossDomainEvents";
import { CustomAnalyticsEvents } from "../../models/GoogleAnalytics";
import { gaEventTracker } from "../../utils/commonUtils";


const RetentionCancel: React.FC = () => {
  const { isRemoveCustomerSubscriptionRequest, isRemoveCustomerSubscriptionSuccess, isRemoveCustomerSubscriptionFailure,
    selectedExitReason, exitReasonComment } = useSelector<AppState, IRetentionReducer>(state => state.retentionReducer);
  const [state, setState] = useState<{ selectedSubscription: number, filteredSubscriptions: Array<ICustomerSubscription> }>({
    selectedSubscription: 0,
    filteredSubscriptions: []
  });
  const [isLoadingData, setIsLoadingData] = useState(false);
  const dateFormat = "DD MMM YYYY";

  const { selectedSubscription, filteredSubscriptions } = state;
  const { companyDomain, checkoutConfig, billsbyAction } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { mainProps: { customerUniqueId, subscriptionUniqueId }, address: { country, stateObject } } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { currentSubscription, currentPlan, refundPolicy, subscriptionOutstandingBalance, subscriptionCancellationDetails  } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const { plan, cycle, product, numberOfUnits } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { appliedCoupons } = useSelector<AppState, IDiscountCouponReducer>(state => state.discountCouponReducer);
  const { paymentDescriptionIsLoading, paymentDescription } = useSelector<AppState, IPaymentReducer>(state => state.paymentReducer);
  const { subscriptionAddons, subscriptionAllowances, allowancesOverage } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const dispatch = useDispatch<Function>()

  const hasOutStandingBalance = subscriptionOutstandingBalance && subscriptionOutstandingBalance.getAmount();
  const outStandingBalance = subscriptionOutstandingBalance && subscriptionOutstandingBalance.getFormattedAmount();

  const hasRefund = subscriptionCancellationDetails && subscriptionCancellationDetails.getRefundAmount();
  const refundAmount = subscriptionCancellationDetails && subscriptionCancellationDetails.getRefundAmountFormatted();
  const startDate = subscriptionCancellationDetails && moment.unix((subscriptionCancellationDetails.getUnusedTimeStartDate() as Timestamp).getSeconds()).format(dateFormat);
  const endDate = subscriptionCancellationDetails && moment.unix((subscriptionCancellationDetails.getUnusedTimeEndDate() as Timestamp).getSeconds()).format(dateFormat);

  const allowRefund = refundPolicy && refundPolicy.getRefundPolicy() === RefundPolicy.PRORATE_UNUSED;
  const plusTaxLabel = showTaxLabel(paymentDescription) ? `${counterpart("PRICE_SUMMARY_PLUS_TAX")}` : "";

  const fetchSubscriptions = async () => {
    try {
      const { response: subscriptions } = await dispatch(fetchCustomerSubscriptions(companyDomain, customerUniqueId as string)) as { response: Array<ICustomerSubscription> };
      setState({ ...state, filteredSubscriptions: subscriptions.filter(subscription => subscription.subscriptionUniqueId === subscriptionUniqueId as string) });
    }
    catch (err) { }
  }

  const preloadData = async () => {
    if (!currentSubscription || !currentPlan || !customerUniqueId || !subscriptionUniqueId) {
      return;
    }

    setIsLoadingData(true);

    try {
      const { response: plans }: { response: Array<IPlanWithCycles> } = (await dispatch(fetchPlans(currentSubscription.productId))) as { response: Array<IPlanWithCycles> };

      await dispatch(fetchRefundPolicy(companyDomain));
      await dispatch(fetchSubscriptionCancellationDetails(companyDomain, customerUniqueId as string, subscriptionUniqueId as string))
      await dispatch(fetchPersonalDetails(companyDomain, customerUniqueId as string));
      await dispatch(fetchSubscriptionDiscounts(companyDomain, subscriptionUniqueId as string));
      await dispatch(fetchSubscriptionAllowances(companyDomain, subscriptionUniqueId as string));
      await dispatch(fetchSubscriptionAddons(companyDomain, subscriptionUniqueId as string));

      await dispatch(fetchSubscriptionOutstandingBalance(companyDomain, customerUniqueId, subscriptionUniqueId));
      if (plans) {
        let _currentPlan = plans.find((plan) => plan.planId === currentSubscription.planId);

        if (_currentPlan) {
          _currentPlan = _currentPlan.pricingModelType !== PricingModelType.FlatFee ? recalculatePlanCycle(currentPlan, currentSubscription.units as number, []) : currentPlan;
          dispatch(setPlan({ label: currentPlan.name, value: _currentPlan }));
          dispatch(setNumberOfUnits(currentSubscription.units));

          const currentCycle = _currentPlan.cycles.find((cycle) => cycle.cycleId === currentSubscription.cycleId);
          if (currentCycle) {
            dispatch(setCycle(currentCycle));
          }
        }
      }

      setIsLoadingData(false);

    } catch (err) {
      setIsLoadingData(false);

    }
  };

  //Get simulated data
  useLayoutEffect(() => {
    if (!currentSubscription || paymentDescription) {
      return;
    }

    const couponCodesList = appliedCoupons && appliedCoupons.length > 0 ? appliedCoupons.map(ac => ac.couponCode || "") : [];
    const discountIdsList = appliedCoupons && appliedCoupons.length > 0 ? appliedCoupons.map(ac => ac.discount.getDiscountId()) : [];

    if (cycle) {
      dispatch(getPriceDescription(
        cycle.cycleId,
        subscriptionAddons ? subscriptionAddons.filter(i => !!i.units && i.units > 0 || i.isForced) : [],
        subscriptionAllowances ? subscriptionAllowances.filter(i => !!i.units && i.units > 0 || i.isForced) : [],
        numberOfUnits === 0 ? 1 : numberOfUnits,
        !currentSubscription ? couponCodesList : undefined,
        currentSubscription ? discountIdsList : undefined,
        allowancesOverage,
        currentSubscription ? currentSubscription.cycleId : undefined,
        stateObject ? stateObject.value.CountryIso3 : undefined
      ));
    }
  }, [currentSubscription, appliedCoupons, subscriptionAddons, subscriptionAllowances, allowancesOverage, cycle])

  useLayoutEffect(() => {
    preloadData()
    fetchSubscriptions();
  }, [])

  useEffect(() => {
    if (isRemoveCustomerSubscriptionSuccess || isRemoveCustomerSubscriptionFailure) {
      if(isRemoveCustomerSubscriptionSuccess) {
        gaEventTracker(CustomAnalyticsEvents.CANCELLED_SUBSCRIPTION)
      }
      // redirect + forward history query string
      history.push({ pathname: "/management", search: history.location.search });
    }
  }, [isRemoveCustomerSubscriptionSuccess || isRemoveCustomerSubscriptionFailure])


  const removeSubscription = async () => {
    try {
      const response = await dispatch(removeCustomerSubscription(companyDomain, customerUniqueId as string, filteredSubscriptions[selectedSubscription].subscriptionUniqueId));
      if (response.error && (response.error.message === "Subscription_Cancel_Failed" || response.error.message === "ErrorCode: Subscription_Cancel_Subscription_Has_Unpaid_Invoices; ErrorMessage: Subscription_Cancel_Subscription_Has_Unpaid_Invoices")) {
        dispatch(fetchSubscriptionOutstandingBalance(companyDomain, customerUniqueId as string, subscriptionUniqueId as string));
      } else {
        dispatch(saveExitReason(companyDomain, Number(selectedExitReason.value), String(subscriptionUniqueId), exitReasonComment));
      }
    }
    catch (err) { }
  };

  const getText = (): { content: string, translateWith?: any, shouldTranslate?: boolean } => {
    if (!checkoutConfig) {
      return { content: "", shouldTranslate: false }
    }

    if (hasRefund && allowRefund) {
      return { content: "CANCEL_SUBSCRIPTION_SELECT_PLAN_REFUND", translateWith: { amount: refundAmount, startDate, endDate, plusTax: plusTaxLabel, planLabel: checkoutConfig.terminologyPlanSingular } }
    }

    if (selectedSubscription < 0 || !filteredSubscriptions.length) {
      return { content: "CANCEL_SUBSCRIPTION_SELECT_PLAN_BASE", translateWith: { planLabel: checkoutConfig.terminologyPlanSingular } }
    }
    
    const todayDate = moment(new Date());
    const nextBillingDate = moment(filteredSubscriptions[selectedSubscription].nextBilling);
    const lostDays = currentSubscription?.status === SubscriptionStatusType.SUSPENDED ? 0 : todayDate.diff(nextBillingDate, "days");

    return {
      content: "CANCEL_SUBSCRIPTION_SELECT_PLAN", 
      translateWith: {
        days: (
          <Text 
            component="span" 
            weight="bold" 
            content={counterpart("CANCEL_SUBSCRIPTION_SELECT_PLAN_NR_DAYS_LOST", { lostDays: `${(-lostDays).toString()}`})} 
            shouldTranslate={false} 
            noMargin 
          />
        ),
        planLabel: checkoutConfig.terminologyPlanSingular
      }
    }
  }

  const getLinkText = () => {
    if (hasRefund && allowRefund) {
      return counterpart("CANCEL_SUBSCRIPTION_CONFIRM_REFUND")
    }

    if (hasOutStandingBalance) {
      return counterpart("CANCEL_SUBSCRIPTION_CONTINUE_PAYMENT")
    }

    return counterpart("CANCEL_SUBSCRIPTION_CONFIRM_CANCEL")
  }


  return (
    <PageWrapper className="cancel-subscription"
      title="CANCEL_SUBSCRIPTION_TITLE"
      titleTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
      btnDisabled={selectedSubscription < 0 || isRemoveCustomerSubscriptionRequest || paymentDescriptionIsLoading || !paymentDescription}
      btnText="RETENTION_KEEP_MY_PLAN"
      btnTextTranslateWith={{subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular}}
      btnCallback={() => billsbyAction === BillsbyDataAction.CANCEL_PLAN ? sendEvent(CROSS_DOMAIN_EVENT.CLOSE) : history.goBack()}
      isLoading={isLoadingData}
      btnBackCallback={() => dispatch(setRetentionField("currentScreen", RetentionScreen.exitReasons))}
      bottomStickyContent={
        !isLoadingData && (!paymentDescriptionIsLoading && paymentDescription) ? <a
          href="javascript:void(0)"
          className="retention__link"
          onClick={() => {
            if (hasOutStandingBalance) {
              dispatch(setRetentionField("currentScreen", RetentionScreen.payBalance));
            } else {
              removeSubscription()
            }
          }} >
          {getLinkText()}
        </a> : undefined
      }
    >
      { isLoadingData || (paymentDescriptionIsLoading && !paymentDescription) ? <LoadingAnimation /> :
        <>
          <Panel id="cancel-subscription-texts">
            <Text {...getText()} noMargin />
          </Panel>
          <FormGroup>
            {cycle && plan && currentSubscription && <CollapsiblePanel
              id="cancel-subscription-collapsible"
              isLocked={false}
              isExpanded={true}
            >
              <Panel id="cancel-subscription-plan">
                <div>
                  <Text content={`${currentSubscription.productDisplayName || currentSubscription.productName} - ${currentSubscription.planDisplayName || currentSubscription.planName}`} shouldTranslate={false} size="large" weight="bold" noMargin></Text>
                </div>
                <PlanCycles readOnly hasSelectedCycle={false} isChangePlan={true} />
                {appliedCoupons.length > 0 && <div className="update-subscription__discounts">
                  <PaymentSummaryCoupons 
                    paymentDescription={paymentDescription}
                    appliedCoupons={appliedCoupons}
                    checkoutConfig={checkoutConfig}
                    currencyCode={currentSubscription.currency}>
                  </PaymentSummaryCoupons>
                </div>}
              </Panel>
              {hasOutStandingBalance ? (
                <>
                  <Panel id="cancel-subscription-outstanding-balance">
                    <Text content="CANCEL_SUBSCRIPTION_OUTSTANDING_BALANCE" translateWith={{ amount: outStandingBalance }}></Text>
                  </Panel>
                </>
              ) : null}
            </CollapsiblePanel>}
          </FormGroup>
        </>}
    </PageWrapper>
  )
}

export default RetentionCancel