import * as React from "react";
import "./TestGatewayPlaceHolder.scss"
import Text from "../ui/text/Text";
import FormGroup from "../ui/form-group/FormGroup";
import Button from "../ui/button/Button";

interface ITestGatewayPlaceHolderProps {
  isSubscribing: boolean,
  content?: string,
  onContinue: () => any
}

const TestGatewayPlaceHolder: React.FC<ITestGatewayPlaceHolderProps> = ({ content = "BILLSBY_TEST_GATEWAY_TEXT", isSubscribing, onContinue }) => {

  return (
    <>
      <div className="tg-placeholder">
        <Text className="tg-placeholder__title" content="BILLSBY_TEST_GATEWAY_TITLE" noMargin />
        <FormGroup>
          <Text
            content={content}
            shouldTranslate
            className="tg-placeholder__text"
            noMargin
          />
        </FormGroup>

        <Button
          id="test-placeholder-continue"
          className="modal__body__closebtn"
          title="BILLSBY_TEST_GATEWAY_CONTINUE"
          isLoading={isSubscribing}
          disabled={isSubscribing}
          onClick={onContinue}
        />
      </div>
    </>
  )
};

export default TestGatewayPlaceHolder;