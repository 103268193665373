import { makeGenericActionCreator, makeActionCreator } from "../utils/reduxUtils";
import { GetExitReasonsConfigRequest, SaveExitReasonRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/company/exitreason/exit_reason_config_pb";
import { ExitReasonConfigServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/company/exitreason/Exit_reason_configServiceClientPb";
import { GetHelpInterruptRequest, GetReasonsToStayRequest, GetTimeRemainingRequest, GetPlanChangeRequest, GetRetentionStepsRequest, GetDiscountOfferRequest } from "../utils/grpc/generated/Billsby.Protos/core/public/companyconfig/retentionstep/retention_step_pb";
import { CompanyRetentionStepServiceClient } from "../utils/grpc/generated/Billsby.Protos/core/public/companyconfig/retentionstep/Retention_stepServiceClientPb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { ConfigConstants } from "../utils/config";
import { CancelSubscriptionRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/subscription_pb";
import { SubscriptionServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/SubscriptionServiceClientPb";

export const GET_EXIT_REASONS_REQUEST = "GET_EXIT_REASONS_REQUEST";
export const GET_EXIT_REASONS_SUCCESS = "GET_EXIT_REASONS_SUCCESS";
export const GET_EXIT_REASONS_FAILURE = "GET_EXIT_REASONS_FAILURE";

export const SAVE_EXIT_REASONS_REQUEST = "SAVE_EXIT_REASONS_REQUEST";
export const SAVE_EXIT_REASONS_SUCCESS = "SAVE_EXIT_REASONS_SUCCESS";
export const SAVE_EXIT_REASONS_FAILURE = "SAVE_EXIT_REASONS_FAILURE";

export const GET_HELP_INTERRUPT_REQUEST = "GET_HELP_INTERRUPT_REQUEST";
export const GET_HELP_INTERRUPT_SUCCESS = "GET_HELP_INTERRUPT_SUCCESS";
export const GET_HELP_INTERRUPT_FAILURE = "GET_HELP_INTERRUPT_FAILURE";

export const GET_REASONS_TO_STAY_REQUEST = "GET_REASONS_TO_STAY_REQUEST";
export const GET_REASONS_TO_STAY_SUCCESS = "GET_REASONS_TO_STAY_SUCCESS";
export const GET_REASONS_TO_STAY_FAILURE = "GET_REASONS_TO_STAY_FAILURE";

export const GET_TIME_REMAINING_REQUEST = "GET_TIME_REMAINING_REQUEST";
export const GET_TIME_REMAINING_SUCCESS = "GET_TIME_REMAINING_SUCCESS";
export const GET_TIME_REMAINING_FAILURE = "GET_TIME_REMAINING_FAILURE";

export const GET_PLAN_CHANGE_REQUEST = "GET_PLAN_CHANGE_REQUEST";
export const GET_PLAN_CHANGE_SUCCESS = "GET_PLAN_CHANGE_SUCCESS";
export const GET_PLAN_CHANGE_FAILURE = "GET_PLAN_CHANGE_FAILURE";

export const GET_RETENTION_STEPS_REQUEST = "GET_RETENTION_STEPS_REQUEST";
export const GET_RETENTION_STEPS_SUCCESS = "GET_RETENTION_STEPS_SUCCESS";
export const GET_RETENTION_STEPS_FAILURE = "GET_RETENTION_STEPS_FAILURE";

export const GET_DISCOUNT_OFFER_REQUEST = "GET_DISCOUNT_OFFER_REQUEST";
export const GET_DISCOUNT_OFFER_SUCCESS = "GET_DISCOUNT_OFFER_SUCCESS";
export const GET_DISCOUNT_OFFER_FAILURE = "GET_DISCOUNT_OFFER_FAILURE";

export const REMOVE_CUSTOMER_SUBSCRIPTION_REQUEST = "REMOVE_CUSTOMER_SUBSCRIPTION_REQUEST";
export const REMOVE_CUSTOMER_SUBSCRIPTION_SUCCESS = "REMOVE_CUSTOMER_SUBSCRIPTION_SUCCESS";
export const REMOVE_CUSTOMER_SUBSCRIPTION_FAILURE = "REMOVE_CUSTOMER_SUBSCRIPTION_FAILURE";

type allowedRetentionType = "currentScreen" | "selectedExitReason" | "exitReasonComment";
export const SET_RETENTION_FIELD = "SET_RETENTION_FIELD";
export const setRetentionField = makeGenericActionCreator<allowedRetentionType>(SET_RETENTION_FIELD);

export const RESET_RETENTION_STATE = "CANCEL_SUBSCRIPTION_STATE";
export const resetRetentionState = makeActionCreator(RESET_RETENTION_STATE);

export function removeCustomerSubscription(companyDomain: string, customerUniqueId: string, subscriptionUniqueId: string) {
  const cancelSubscriptionRequest = new CancelSubscriptionRequest();
  cancelSubscriptionRequest.setCompanyDomain(companyDomain);
  cancelSubscriptionRequest.setCustomerUniqueId(customerUniqueId);
  cancelSubscriptionRequest.setSubscriptionUniqueId(subscriptionUniqueId);

  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [REMOVE_CUSTOMER_SUBSCRIPTION_REQUEST, REMOVE_CUSTOMER_SUBSCRIPTION_SUCCESS, REMOVE_CUSTOMER_SUBSCRIPTION_FAILURE],
    callAPI:  () => grpcUnaryCall(cancelSubscriptionRequest, subscriptionServiceClient, subscriptionServiceClient.cancelSubscription)
  }
}

export function getExitReasons(companyDomain: string) {
  const getExitReasonsRequest = new GetExitReasonsConfigRequest();
  getExitReasonsRequest.setCompanyDomain(companyDomain);
  const exitReasonConfigServiceClient = new ExitReasonConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [GET_EXIT_REASONS_REQUEST, GET_EXIT_REASONS_SUCCESS, GET_EXIT_REASONS_FAILURE],
    callAPI: () => grpcUnaryCall(getExitReasonsRequest, exitReasonConfigServiceClient, exitReasonConfigServiceClient.getExitReasonsConfig)
  }
}

export function saveExitReason(companyDomain: string, exitReasonId: number, subscriptionUniqueId: string, comment: string) {
  const saveExitReasonRequest = new SaveExitReasonRequest();
  saveExitReasonRequest.setCompanyDomain(companyDomain);
  saveExitReasonRequest.setExitReasonId(exitReasonId);
  saveExitReasonRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  saveExitReasonRequest.setComment(comment);
  const exitReasonConfigServiceClient = new ExitReasonConfigServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [SAVE_EXIT_REASONS_REQUEST, SAVE_EXIT_REASONS_SUCCESS, SAVE_EXIT_REASONS_FAILURE],
    callAPI: () => grpcUnaryCall(saveExitReasonRequest, exitReasonConfigServiceClient, exitReasonConfigServiceClient.saveExitReason)
  }
}

export function getHelpInterrupt(companyDomain: string) {
  const getHelpInterruptRequest = new GetHelpInterruptRequest();
  getHelpInterruptRequest.setCompanyDomain(companyDomain);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [GET_HELP_INTERRUPT_REQUEST, GET_HELP_INTERRUPT_SUCCESS, GET_HELP_INTERRUPT_FAILURE],
    callAPI: () => grpcUnaryCall(getHelpInterruptRequest, companyRetentionStepServiceClient, companyRetentionStepServiceClient.getHelpInterrupt)
  }
}

export function getReasonsToStay(companyDomain: string) {
  const getReasonsToStayRequest = new GetReasonsToStayRequest();
  getReasonsToStayRequest.setCompanyDomain(companyDomain);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [GET_REASONS_TO_STAY_REQUEST, GET_REASONS_TO_STAY_SUCCESS, GET_REASONS_TO_STAY_FAILURE],
    callAPI: () => grpcUnaryCall(getReasonsToStayRequest, companyRetentionStepServiceClient, companyRetentionStepServiceClient.getReasonsToStay)
  }
}

export function getTimeRemaining(companyDomain: string, subscriptionUniqueId: string) {
  const getTimeRemainingRequest = new GetTimeRemainingRequest();
  getTimeRemainingRequest.setCompanyDomain(companyDomain);
  getTimeRemainingRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [GET_TIME_REMAINING_REQUEST, GET_TIME_REMAINING_SUCCESS, GET_TIME_REMAINING_FAILURE],
    callAPI: () => grpcUnaryCall(getTimeRemainingRequest, companyRetentionStepServiceClient, companyRetentionStepServiceClient.getTimeRemaining)
  }
}

export function getPlanChange(companyDomain: string) {
  const getPlanChangeRequest = new GetPlanChangeRequest();
  getPlanChangeRequest.setCompanyDomain(companyDomain);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [GET_PLAN_CHANGE_REQUEST, GET_PLAN_CHANGE_SUCCESS, GET_PLAN_CHANGE_FAILURE],
    callAPI: () => grpcUnaryCall(getPlanChangeRequest, companyRetentionStepServiceClient, companyRetentionStepServiceClient.getPlanChange)
  }
}

export function getRetentionSteps(companyDomain: string) {
  const getRetentionStepsRequest = new GetRetentionStepsRequest();
  getRetentionStepsRequest.setCompanyDomain(companyDomain);
  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [GET_RETENTION_STEPS_REQUEST, GET_RETENTION_STEPS_SUCCESS, GET_RETENTION_STEPS_FAILURE],
    callAPI: () => grpcUnaryCall(getRetentionStepsRequest, companyRetentionStepServiceClient, companyRetentionStepServiceClient.getRetentionSteps)
  }
}

export function getDiscountOffer(companyDomain: string, subscriptionUniqueId: string) {
  const getDiscountOfferRequest = new GetDiscountOfferRequest();
  getDiscountOfferRequest.setCompanyDomain(companyDomain);
  getDiscountOfferRequest.setSubscriptionUniqueId(subscriptionUniqueId);

  const companyRetentionStepServiceClient = new CompanyRetentionStepServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [GET_DISCOUNT_OFFER_REQUEST, GET_DISCOUNT_OFFER_SUCCESS, GET_DISCOUNT_OFFER_FAILURE],
    callAPI: () => grpcUnaryCall(getDiscountOfferRequest, companyRetentionStepServiceClient, companyRetentionStepServiceClient.getDiscountOffer)
  }
}

