import React, { useState } from "react";
import ManageAddonList from "../../components/add-on/manage-addon-list/ManageAddonList";
import { ManageAddonScreen } from "../../models/AddOnAllowance";
import { addNewAddons, fetchSubscriptionAddons } from "../../actions/addOnActions";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { IAddOnReducer } from "../../reducers/addOnReducer";
import { AppState } from "../..";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";
import { ICycle } from "../../models/Product";
import PageWrapper from "../page-wrapper/PageWrapper";
import ManageAddonRetryPayment from "../../components/add-on/manage-addon-retry-payment/ManageAddonRetryPayment";
import SystemInfo from "../../components/ui/system-info/SystemInfo";
import history from "../../utils/history";
import { useFetch } from "../../utils/custom-hooks";
import LoadingAnimation from "../../components/ui/loading-animation/LoadingAnimation";
import { useDispatch, useSelector } from "react-redux";


const ManageAddon: React.FC = () => {

  const [currentScreen, setCurrentScreen] = useState(ManageAddonScreen.CURRENT_ADDON_LIST);
  const { mainProps: { subscriptionUniqueId } } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer)
  const { companyDomain, checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const { addOns, isFetchSubscriptionAddOnsFailure } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const { currentPlan, currentSubscription } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const dispatch = useDispatch<Function>()

  const { isLoading } = useFetch(() => dispatch(fetchSubscriptionAddons(companyDomain, subscriptionUniqueId as string)));


  if (isFetchSubscriptionAddOnsFailure) {
    return (
      <PageWrapper btnVisible={false}>
        <SystemInfo
          title="MANAGE_ADDON_FETCH_ADDONS_FAILURE"
          translateWith={{ addonLabel: checkoutConfig?.terminologyAddonPlural }}
          isShowIcon={false}
          type="warning"
        />
      </PageWrapper>
    )
  }

  if (currentPlan && !currentPlan.addons.length) {
    return (
      <PageWrapper btnVisible={false}>
        <SystemInfo
          title="MANAGE_ADDON_NO_ADDONS_AVAILABLE"
          translateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular, addonLabel: checkoutConfig?.terminologyAddonPlural }}
          isShowIcon={false}
          type="warning"
        />
      </PageWrapper>
    )
  }

  if (isLoading || !currentPlan || !currentSubscription) {
    return <LoadingAnimation />
  }


  const currentCycle = currentPlan.cycles.find(cycle => cycle.cycleId === currentSubscription.cycleId) as ICycle;

  const renderCurrentScreen = () => {
    switch (currentScreen) {
      case ManageAddonScreen.CURRENT_ADDON_LIST:
        return <ManageAddonList onAddingAddOnsFailure={() => setCurrentScreen(ManageAddonScreen.RETRY_PAYMENT)} />

      case ManageAddonScreen.RETRY_PAYMENT:
        return (
          <ManageAddonRetryPayment
            currentCycle={currentCycle}
            currentSubscription={currentSubscription}
            onUpdateCardSuccess={async () => {
              try {
                await dispatch(addNewAddons(companyDomain, subscriptionUniqueId as string, addOns));
                history.push({ pathname: "/management", search: history.location.search })
              }
              catch (err) { }
            }}
            btnBackCallback={() => setCurrentScreen(ManageAddonScreen.CURRENT_ADDON_LIST)}
          />
        )
      default:
        return <div />
    }

  }

  return renderCurrentScreen();
}

export default ManageAddon