import React from "react";
import "./Selector.scss";
import Icon from "../icon/Icon";

interface ISelector {
  onClick?: () => any,
  className?: string,
  text?: string,
  isLock?: boolean,
  selected?: boolean
}

const Selector: React.FC<ISelector> = React.memo(({ text, isLock, selected, onClick = () => { }, className = "" }) => {

  return (
    <div className={`selector selector__container ${!!text ? "with-text" : ""} ${isLock ? "lock" : ""} ${selected ? "selected" : ""} ${className}`}
      onClick={onClick}>
      <Icon
        className={`selector__btn ${selected ? "selected" : ""} ${!!text ? "with-text" : ""} ${isLock ? "lock" : ""}`}
        icon={`selector__icon ${isLock ? "fas fa-lock-alt" : selected ? "fas fa-check-circle" : "fal fa-plus-circle"}`}
        size="xlarge"
        component="button"
        text={text ? text : ""}
      />
    </div>
  )
})

export default Selector;