
import { PricingModelType, ITier, FrequencyType } from "./Product";

export enum AllowanceAddOnsType {
  Allowance = "Allowance",
  AddOn = "AddOn",
}

export enum AddOnPricingModelType {
  AddonFlatFee = "AddonFlatFee",
  AddonVolume = "AddonVolume",
  AddonPerUnit = "AddonPerUnit",
  AddonRanged = "AddonRanged",
  AddonTiered = "AddonTiered"
}

export enum AllowancePricingModelType {
  AllowanceCapped = "AllowanceCapped",
  AllowanceVolume = "AllowanceVolume",
  AllowancePerUnit = "AllowancePerUnit",
  AllowanceRanged = "AllowanceRanged",
  AllowanceTiered = "AllowanceTiered"
}

export interface IAddOnPricingModel {
  frequency: number,
  frequencyType: FrequencyType,
  flatFeePrice?: number,
  perUnitPrice?: number,
  pricingModelType: AddOnPricingModelType,
  priceFormatted?: string,
  tiers: ITier[],
  currency: ICurrency
}

export interface IAllowancePricingModel {
  frequency: number,
  frequencyType: FrequencyType,
  perUnitPrice?: number,
  priceFormatted?: string,
  pricingModelType: AllowancePricingModelType,
  tiers: ITier[],
  includedUnits: number,
  currency: ICurrency
}

export interface ICurrency {
  englishName: string,
  symbol: string,
  isoCode: string,
  hasDecimal: boolean
}

export interface IAddOn {
  id: number,
  name: string,
  displayName: string,
  description: string,
  singleUnitName: string,
  pluralUnitName: string,
  isForced: boolean,
  pricingModelType: AddOnPricingModelType,
  pricingModels: Array<IAddOnPricingModel>,
  units?: number,
  unitsNext?: number,
  willBeDeleted?: boolean,
  imageUrl?: string
}

export interface IAllowance {
  id: number,
  name: string,
  displayName: string,
  description: string,
  singleUnitName: string,
  pluralUnitName: string,
  isForced: boolean,
  pricingModelType: AllowancePricingModelType,
  pricingModels: Array<IAllowancePricingModel>,
  units?: number,
  willBeDeleted?: boolean,
  imageUrl?: string
}


export interface IAddOnAllowance {
  addOnId: number,
  description: string,
  name: string,
  pricingModelType: PricingModelType,
  price?: number,
  priceFormatted?: string,
  tiers?: Array<ITier>,
  type: AllowanceAddOnsType
}

export enum ManageAddonScreen {
  CURRENT_ADDON_LIST = "CURRENT_ADDON_LIST",
  RETRY_PAYMENT = "RETRY_PAYMENT"
}

export enum ManageAllowanceScreen {
  CURRENT_ALLOWANCE_LIST = "CURRENT_ALLOWANCE_LIST"
}