import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { RESET_SUBSCRIPTION_STATE, FETCH_CUSTOMER_SUBSCRIPTIONS_SUCCESS, FETCH_CUSTOMER_SUBSCRIPTION_DETAILS_SUCCESS, FETCH_SUBSCRIPTION_OUTSTANDING_BALANCE_SUCCESS, FETCH_CURRENT_PLAN_SUCCESS, SET_CURRENT_SUBSCRIPTION, FETCH_CUSTOMER_SUBSCRIPTIONS_FAILURE, FETCH_PENDING_MIN_TERM_SUCCESS, FETCH_PENDING_MIN_TERM_REQUEST, FETCH_PENDING_MIN_TERM_FAILURE, FETCH_REFUND_POLICY_REQUEST, FETCH_REFUND_POLICY_FAILURE, FETCH_REFUND_POLICY_SUCCESS, FETCH_CANCELLATION_DETAILS_SUCCESS } from "../actions/subscriptionsActions";
import { ICustomerSubscription } from "../models/Customer";
import { ISubscriptionDetails } from "../models/Subscription";
import { GetPendingMinimumTermAmountResponse, GetSubscriptionUnpaidAmountResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/subscription_pb";
import { GetCancellationDetailsResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/subscription_pb";
import { IPlanWithCycles } from "../models/Product";
import { GetRefundPolicyResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/refundpolicy/refundpolicy_pb";

export interface ISubscriptionReducer {
  isFetchCustomerSubscriptionSuccess: boolean,
  isFetchCustomerSubscriptionFailure: boolean,
  customerActiveSubscriptions: Array<ICustomerSubscription>,
  currentSubscription?: ICustomerSubscription,
  currentSubscriptionDetails?: ISubscriptionDetails,
  currentPlan?: IPlanWithCycles,
  subscriptionOutstandingBalance?: GetSubscriptionUnpaidAmountResponse,
  subscriptionCancellationDetails?: GetCancellationDetailsResponse,
  isFetchPendingMinTermRequest: boolean,
  isFetchPendingMinTermFailure: boolean,
  isFetchPendingMinTermSuccess: boolean,
  pendingMinimumTermAmount?: GetPendingMinimumTermAmountResponse,
  refundPolicy?: GetRefundPolicyResponse
}

export const initialState: ISubscriptionReducer = {
  customerActiveSubscriptions: [],
  isFetchCustomerSubscriptionSuccess: false,
  isFetchCustomerSubscriptionFailure: false,
  isFetchPendingMinTermRequest: false,
  isFetchPendingMinTermFailure: false,
  isFetchPendingMinTermSuccess: false,
}

export default function subscriptionReducer(state = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case RESET_SUBSCRIPTION_STATE:
      return { ...initialState }
    case FETCH_CUSTOMER_SUBSCRIPTIONS_SUCCESS:
      return { ...state, customerActiveSubscriptions: action.response, isFetchCustomerSubscriptionSuccess: true, isFetchCustomerSubscriptionFailure: false }
    case FETCH_CUSTOMER_SUBSCRIPTIONS_FAILURE:
      return { ...state, isFetchCustomerSubscriptionSuccess: false, isFetchCustomerSubscriptionFailure: true }
    case FETCH_CUSTOMER_SUBSCRIPTION_DETAILS_SUCCESS:
      return { ...state, currentSubscriptionDetails: action.response }
    case FETCH_SUBSCRIPTION_OUTSTANDING_BALANCE_SUCCESS:
      return { ...state, subscriptionOutstandingBalance: action.response }
    case FETCH_PENDING_MIN_TERM_REQUEST:
      return { ...state, isFetchPendingMinTermRequest: true, isFetchPendingMinTermFailure: false, isFetchPendingMinTermSuccess: false }
    case FETCH_PENDING_MIN_TERM_FAILURE:
      return { ...state, isFetchPendingMinTermRequest: false, isFetchPendingMinTermFailure: true, isFetchPendingMinTermSuccess: false }
    case FETCH_PENDING_MIN_TERM_SUCCESS:
      return { ...state, isFetchPendingMinTermRequest: false, isFetchPendingMinTermFailure: false, isFetchPendingMinTermSuccess: true, pendingMinimumTermAmount: action.response }
    case FETCH_REFUND_POLICY_REQUEST:
      return { ...state, isFetchRefundPolicyRequest: true, isFetchRefundPolicyFailure: false, isFetchRefundPolicySuccess: false }
    case FETCH_REFUND_POLICY_FAILURE:
      return { ...state, isFetchRefundPolicyRequest: false, isFetchRefundPolicyFailure: true, isFetchRefundPolicySuccess: false }
    case FETCH_REFUND_POLICY_SUCCESS:
      return { ...state, isFetchRefundPolicyRequest: false, isFetchRefundPolicyFailure: false, isFetchRefundPolicySuccess: true, refundPolicy: action.response }
    case FETCH_CANCELLATION_DETAILS_SUCCESS:
      return { ...state,  subscriptionCancellationDetails: action.response }
      case FETCH_CURRENT_PLAN_SUCCESS:
      return { ...state, currentPlan: action.response }
    case SET_CURRENT_SUBSCRIPTION:
      return { ...state, currentSubscription: action.payload }
    default:
      return state;
  }
}