import React, { useLayoutEffect, ReactElement, useState, useMemo } from "react";
import "./SubscriptionRoute.scss";
import { Switch, Route, RouteProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import counterpart from "counterpart";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import { fetchProducts } from "../../actions/selectPlanActions";
import { fetchBranding } from "../../actions/globalActions";
import Container from "../container/Container";
import CloseBtn from "../../components/ui/close-btn/CloseBtn";
import ContainerError from "../container-error/ContainerError";
import { getBrandingInlineStyle } from "../../utils/brandingUtils";
import Cart from "../../components/ui/cart/Cart";

interface ISubscriptionRoute extends RouteProps {
  children: Array<ReactElement> | ReactElement
}

const getErrorSubTitle = (companyDomain: string, selectPlanReducer: ISelectPlanReducer) => {
  const { products, isFetchingProducts } = selectPlanReducer;

  if (!companyDomain) {
    return (
      <div>
        <b>{counterpart("ERROR_INVOKING_BILLSBY_COMPANY_NOT_PRESENT1")}</b> {counterpart("ERROR_INVOKING_BILLSBY_COMPANY_NOT_PRESENT2")}
      </div>
    )
  }

  if (!products.length && !isFetchingProducts) {
    return (
      <div>{counterpart("ERROR_INVOKING_BILLSBY_NO_PRODUCTS")}</div>
    )
  }

  return (
    <div>{counterpart("ERROR_INVOKING_BILLSBY_GENERIC_ERROR")}</div>
  )
}

const SubscriptionRoute: React.FC<ISubscriptionRoute> = ({ children, path, exact }) => {
  const globalReducer = useSelector((state: AppState) => state.globalReducer);
  const selectPlanReducer = useSelector((state: AppState) => state.selectPlanReducer);

  const dispatch = useDispatch<Function>();

  const { isFetchingProducts, isFetchingProductsFailure, products } = selectPlanReducer;
  const { companyDomain, branding, isFetchingBrandingFailure, checkoutConfig } = globalReducer;
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      await dispatch(fetchBranding(companyDomain));
      await dispatch(fetchProducts());
    }
    catch(err) {}
    finally {
      setIsLoading(false);
    }
  }
  
  useLayoutEffect(() => {
    fetchData();
  }, []);

  const customBranding = useMemo(() => {
    return getBrandingInlineStyle(branding);
  }, [branding]);

  if (isFetchingProducts || isFetchingProductsFailure || isFetchingBrandingFailure || !products.length) {
    return (
      <Container>
        <CloseBtn isVisible={isFetchingBrandingFailure} />
        <ContainerError
          title="ERROR_INVOKING_BILLSBY"
          isVisibleMessage={isFetchingProductsFailure || !companyDomain || (!products.length && !isFetchingProducts)}
          subTitle={getErrorSubTitle(companyDomain, selectPlanReducer)}
        />
      </Container>
    )
  }

  
  if(!checkoutConfig || isLoading) {
    return null;
  }

  return (
    <Route path={path} exact={exact}>
      <Container showProgress>
        <style>{customBranding}</style>
        <Cart />
        <Switch>
          {children}
        </Switch>
      </Container>
    </Route>
  )
}

export default SubscriptionRoute;