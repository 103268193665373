import { ICardDetailInputDetail } from "./PaymentDetail";
import { ICouponSubscription } from "./DiscountCoupon";

export interface ISubscription {
  firstName: string,
  lastName: string,
  email: string,
  companyName: string | null,
  cycleId: number,
  //paymentCardToken: string,
  units?: number,
  address: {
    addressLine1: string,
    addressLine2: string,
    state: string,
    city: string,
    country: string,
    postCode: string
  },
  shippingAddress?: {
    addressLine1: string,
    addressLine2: string,
    state: string,
    city: string,
    country: string,
    postCode: string
  },
  additionalEmail?: string | null,
  phoneNumberDialCountry?: string,
  phoneNumberDialCode?: string,
  phoneNumber?: string,
  marketingConsent?: boolean,
  customFieldResponse?: Array<ICustomFieldResponse>,
  cardDetails?: ICardDetailInputDetail,
  addOns: IAddOnSubscription[]
  couponCodes: Array<ICouponSubscription>
  allowances: Array<number>
  ipAddress: string,
  taxRegNumber: string,
  customerLanguage: string
}


export interface ISubscriptionSCA extends ISubscription {
  scaTransaction: {
    browserInfo: string,
    transactionToken?: string
  }
}

export interface ISubscriptionACH extends ISubscription {
  achDetails: {
    customerId: string,
    paymentId: string,
    fullName: string,
    last4Digits: string,
    bankName: string
  }
}


export interface ISubscriptionExistingCustomer {
  cycleId: number,
  units?: number,
  shippingAddress?: {
    addressLine1: string,
    addressLine2: string,
    state: string,
    city: string,
    country: string,
    postCode: string
  },
  additionalEmail?: string,
  phoneNumberDialCountry?: string,
  phoneNumberDialCode?: string,
  phoneNumber?: string,
  marketingConsent?: boolean,
  customFieldResponse?: Array<ICustomFieldResponse>,
  taxRegNumber: string
  ipAddress: string,
  addOns: IAddOnSubscription[]
  allowances: Array<number>
  couponCodes: Array<ICouponSubscription>
}

export interface ISubscriptionExistingCustomerSCA extends ISubscriptionExistingCustomer{
  browserInfo: string,
  transactionToken?: string
}

export interface IAddOnSubscription {
  addOnId: number,
  quantity: number
}

export interface ICustomFieldResponse {
  customFieldId: number,
  value: string
}

export enum PaymentStatus {
  Success = "Success",
  Declined = "Declined", // When a charge failed because of an issue in customer's side (card expired, fraud, etc...).
  Failed = "Failed", // When a charge failed because of an issue in our side or Spreedly side. Service down, wrong API Key, etc..
  Pending = "Pending", // When a charge requires 3DSecure validation (SCA)
  PendingAch = "PendingAch"
}

export interface ISubscriptionResponse {
  customerUniqueId: string,
  subscriptionUniqueId: string
}

export interface ISubscriptionResponseSCA extends ISubscriptionResponse {
  paymentStatus: PaymentStatus
  transactionToken: string
}


export interface ISubscriptionDetails {
  subscriptionId: number,
  subscriptionUniqueId: string,
  customerId: number,
  customerUniqueId: string,
  customerEmail: string,
  customerFullname: string,
  planId: number,
  planName: string,
  planPricingModel: {
    pricingModelId: number,
    frequency: number,
    frequencyType: number,
    freeTrial: number,
    freeTrialFrequencyType: number,
    setupFeePrice: number,
    setupFeePriceFormatted: string,
    billingDateType: number,
    fixedBillingDateDay: number,
    proRateOption: number
  },
  productId: number,
  productName: string,
  totalRevenue: string,
  nextBillingAmount: string,
  nextBillingDate: string,
  createdOn: string,
  status: string,
  currency: string,
  isBillingAddressValidationRequired: boolean,
  isShippingAddressValidationRequired: boolean,
  isShippingAddressRequired: boolean
}
