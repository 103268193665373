import React from "react";
import { sendEvent, CROSS_DOMAIN_EVENT } from "../../../utils/crossDomainEvents";
import CircleIcon from "../circle-icon/CirlcleIcon";
import "./CloseBtn.scss";

interface ICloseBtn {
  isVisible?: boolean,
  className?: string
}

const CloseBtn: React.FC<ICloseBtn> = React.memo(({ isVisible = true, className = "" }) => (
  <CircleIcon className={`close-btn ${className}`} isVisible={isVisible} onClick={() => sendEvent(CROSS_DOMAIN_EVENT.CLOSE)} />
))

export default CloseBtn;