import counterpart from "counterpart";
import { IBillsbyData, ICheckoutConfig } from "../models/BillsbyData";
import { RetentionScreen } from "../models/Retention";
import { GetRetentionStepsResponse, RetentionStepType } from "./grpc/generated/Billsby.Protos/core/public/companyconfig/retentionstep/retention_step_pb";
import { ICustomerSubscription } from "../models/Customer";
import moment from "moment";
import { IAppliedCoupon } from "../models/DiscountCoupon";
import { DiscountType, Discount } from "./grpc/generated/Billsby.Protos/billing/public/coupons/coupons_pb";
import { extractCurrencyFromPriceFormatted } from "./planUtils";
import { FrequencyType as FrequencyTypeGrpc } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/discount/discount_pb";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import queryString from "query-string";
import { europeanCountriesCode, GOOGLE_RECAPTCHA_TOKEN, RecaptchaActions } from "./constants";
import { ComplexTaxCountry } from "../models/Taxes";
import { supportedLanguages } from "./languages/registerTranslations";
import { CustomAnalyticsEvents, IAnalyticsEventConfig } from "../models/GoogleAnalytics";
import ReactGA from "react-ga4";
import { PaymentMethodType, PaymentScreen } from "../models/Payment";
import { ConfigConstants } from "./config";
const publicIp = require("public-ip");


export const validateEmail = (email: string) => {
  var expression = /(?!.*\.{2})^([a-zA-Z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-zA-Z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-zA-Z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-zA-Z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-zA-Z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-zA-Z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-zA-Z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/;

  return expression.test(email);
}

export const EMAIL_REGEX = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;


export const changeFavIcon = (icon: string = "", removeExisting = true) => {
  if (removeExisting) {
    // remove existing fav-icons
    const favIconsDom = document.querySelectorAll("link[rel*='icon']");
    favIconsDom.forEach(el => el.remove());
  }
  const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = icon;
  document.getElementsByTagName("head")[0].appendChild(link);
}

const headerLogoPreviousStyle = `.header .header__logo {
  background: url([LOGO_URL]);
  background-size: contain;
  background-repeat: no-repeat;
  width:0px; 
  height:0px; 
  padding: 20px;
}`


export const getCheckoutConfig = (checkoutConfig: ICheckoutConfig): ICheckoutConfig => {
  let { terminologyProductSingular, terminologyProductPlural, terminologyPlanSingular, terminologyPlanPlural, terminologyUnitSingular, terminologyUnitPlural,
    terminologySubscriptionSingular, terminologySubscriptionPlural, terminologyAddonSingular, terminologyAddonPlural, terminologySetupFeeSingular,
    terminologySetupFeePlural, terminologyAllowanceSingular, terminologyAllowancePlural, checkoutDefaultLanguage, selfServiceAccountManagementDefaultLanguage, terminologyCouponPlural, terminologyCouponSingular, terminologyDiscountPlural, terminologyDiscountSingular } = checkoutConfig;

  const defaultLanguageKey = supportedLanguages.keys().next().value;

  if (checkoutDefaultLanguage) {
    checkoutDefaultLanguage = checkoutDefaultLanguage || "";
  }

  if (selfServiceAccountManagementDefaultLanguage) {
    selfServiceAccountManagementDefaultLanguage = selfServiceAccountManagementDefaultLanguage || "";
  }

  return {
    ...checkoutConfig,
    checkoutDefaultLanguage: checkoutDefaultLanguage || defaultLanguageKey,
    selfServiceAccountManagementDefaultLanguage: selfServiceAccountManagementDefaultLanguage || defaultLanguageKey,
    terminologyAllowanceSingular: terminologyAllowanceSingular || counterpart("TERMINOLOGY_ALLOWANCE_SINGULAR_DEFAULT"),
    terminologyAllowancePlural: terminologyAllowancePlural || counterpart("TERMINOLOGY_ALLOWANCE_PLURAL_DEFAULT"),
    terminologySetupFeeSingular: terminologySetupFeeSingular || counterpart("TERMINOLOGY_SETUP_FEE_SINGULAR_DEFAULT"),
    terminologySetupFeePlural: terminologySetupFeePlural || counterpart("TERMINOLOGY_SETUP_FEE_PLURAL_DEFAULT"),
    terminologyAddonSingular: terminologyAddonSingular || counterpart("TERMINOLOGY_ADDON_SINGULAR_DEFAULT"),
    terminologyAddonPlural: terminologyAddonPlural || counterpart("TERMINOLOGY_ADDON_PLURAL_DEFAULT"),
    terminologyProductSingular: terminologyProductSingular || counterpart("TERMINOLOGY_PRODUCT_SINGULAR_DEFAULT"),
    terminologyProductPlural: terminologyProductPlural || counterpart("TERMINOLOGY_PRODUCT_PLURAL_DEFAULT"),
    terminologyPlanSingular: terminologyPlanSingular || counterpart("TERMINOLOGY_PLAN_SINGULAR_DEFAULT"),
    terminologyPlanPlural: terminologyPlanPlural || counterpart("TERMINOLOGY_PLAN_PLURAL_DEFAULT"),
    terminologyUnitSingular: terminologyUnitSingular || counterpart("TERMINOLOGY_UNIT_SINGULAR_DEFAULT"),
    terminologyUnitPlural: terminologyUnitPlural || counterpart("TERMINOLOGY_UNIT_PLURAL_DEFAULT"),
    terminologySubscriptionSingular: terminologySubscriptionSingular || counterpart("TERMINOLOGY_SUBSCRIPTION_SINGULAR_DEFAULT"),
    terminologySubscriptionPlural: terminologySubscriptionPlural || counterpart("TERMINOLOGY_SUBSCRIPTION_PLURAL_DEFAULT"),
    terminologyCouponPlural: terminologyCouponPlural || counterpart("TERMINOLOGY_COUPON_PLURAL_DEFAULT"),
    terminologyCouponSingular: terminologyCouponSingular || counterpart("TERMINOLOGY_COUPON_SINGULAR_DEFAULT"),
    terminologyDiscountPlural: terminologyDiscountPlural || counterpart("TERMINOLOGY_DISCOUNT_PLURAL_DEFAULT"),
    terminologyDiscountSingular: terminologyDiscountSingular || counterpart("TERMINOLOGY_DISCOUNT_SINGULAR_DEFAULT"),
  }
}

/**
 * this method runs a generic untrustable piece of JS within a web worker (parallel thread) so any possible
 * malicious code is wrapped in a sandbox, this is to avoid any interaction with the outer scope and if 
 * for any reason the worker hangs the main thread will keep working fine
 * @param script 
 */
export const loadScriptFromString = (script: string | null | undefined) => {
  if (script) {
    const blob = new Blob([script], { type: "text/javascript" })
    const worker = new Worker(window.URL.createObjectURL(blob));
    worker.postMessage("start worker");
  }
}

/**
 * return the next AVAILABLE step (screen) in the retention workflow , can be configured to return
 * the next AVAILABLE following or previous step
 * @param retentionStepsAvailability 
 * @param type 
 * @param currentStep 
 */
export const getNextRetentionStepAvailable = (retentionStepsAvailability: GetRetentionStepsResponse, type: "forward" | "backward",
  currentStep?: RetentionScreen): RetentionScreen | null => {

  const getIsStepAvailable = (type: RetentionStepType) => {
    const step = retentionStepsAvailability.getRetentionStepsList().find(el => el.getRetentionStepType() === type);
    if (step) {
      return step.getIsEnabled();
    }
    return false;
  }

  const getRetentionScreen = (position: 0 | 1 | 2 | 3 | 4): { value: RetentionScreen, available: boolean } => {
    const typeToScreen: any = {
      [RetentionStepType.UNSPECIFIED]: RetentionScreen.helpInterrupt,
      [RetentionStepType.HELP_INTERRUPT]: RetentionScreen.helpInterrupt,
      [RetentionStepType.REASONS_TO_STAY]: RetentionScreen.reasonsToStay,
      [RetentionStepType.TIME_REMAINING]: RetentionScreen.timeRemaining,
      [RetentionStepType.PLAN_CHANGE]: RetentionScreen.changePlan,
      [RetentionStepType.DISCOUNT_OFFER]: RetentionScreen.discountOffer
    }

    const elem = retentionStepsAvailability.getRetentionStepsList()[position];
    return { value: typeToScreen[elem.getRetentionStepType()], available: elem.getIsEnabled() }
  }

  const steps = [
    getRetentionScreen(0),
    getRetentionScreen(1),
    getRetentionScreen(2),
    getRetentionScreen(3),
    getRetentionScreen(4),
    { value: RetentionScreen.exitReasons, available: true },
    { value: RetentionScreen.confirmCancel, available: true }
  ]

  /* need to consider even the first screen when the current step is undefined */
  const currentStepIdx = currentStep ? steps.findIndex(step => step.value === currentStep) : -1;

  if (type === "forward") {
    for (let i = currentStepIdx; i < steps.length; i++) {
      const nextStep = steps[i + 1];
      if (nextStep && nextStep.available) {
        return nextStep.value;
      }
    }
    // if none of the following step is enabled i need to jump to the last one
    return RetentionScreen.confirmCancel;
  }
  else {
    for (let i = currentStepIdx; i > 0; i--) {
      const previousStep = steps[i - 1];
      if (previousStep && previousStep.available) {
        return previousStep.value;
      }
    }
    // if none of the previous step is enabled i need to leave the retention workflow setting the value of the previous screen to null
    return null;
  }
}


export const getContractMinimumTermInfo = (subscription: ICustomerSubscription): { isPendingContractMinimumTermEnd: boolean, contractMinimumTermEnd: string } => {
  let isPendingContractMinimumTermEnd = false;
  let contractMinimumTermEnd = "";
  if (subscription && subscription.contractMinimumTermEnd && subscription.nextBilling) {
    const nextBillingDate = moment(subscription.nextBilling);
    const contractMinimumTermEndDate = moment(subscription.contractMinimumTermEnd);

    if (nextBillingDate <= contractMinimumTermEndDate) {
      isPendingContractMinimumTermEnd = true;
      contractMinimumTermEnd = subscription.contractMinimumTermEnd;
    }
  }
  return { isPendingContractMinimumTermEnd, contractMinimumTermEnd };
}


export const getPublicIpV4 = async () => {
  const ipv4 = await publicIp.v4() as any;
  return ipv4
}


export const getFormattedDiscountedPrice = (appliedCoupons: Array<IAppliedCoupon> | undefined, price: number, formatted: string,
  currencyCode: string, currencySymbol?: string) => {

  let currentPrice = price;
  let formattedPrice = formatted;

  if (!appliedCoupons || appliedCoupons.length === 0) {
    return formattedPrice;
  }

  let totalPercentage = 0;
  let totalMonetary = 0;

  appliedCoupons.forEach((ac) => {
    if (ac.discount.getDiscountType() === DiscountType.MONETARY) {
      const priceList = (ac.discount.getMonetary() as Discount.MonetaryModel).getPricesList();
      const price = priceList.find((pl) => pl.getCurrencyCode() === currencyCode);

      totalMonetary += price ? price.getValue() : 0;
    }

    if (ac.discount.getDiscountType() === DiscountType.PERCENTAGE) {
      const percentage = (ac.discount.getPercentage() as Discount.PercentageModel).getPercentage() / 100;
      totalPercentage += percentage;
    }
  });

  currentPrice = currentPrice - (totalMonetary);
  currentPrice = currentPrice - currentPrice * (totalPercentage / 100);

  if (currentPrice < 0) {
    currentPrice = 0
  }

  formattedPrice = `${currencySymbol || extractCurrencyFromPriceFormatted(formatted).currency}${parseFloat((currentPrice / 100).toFixed(2))}`;

  return formattedPrice
};

export const removeAllDiscountsFormattedPrice = (appliedCoupons: Array<IAppliedCoupon> | undefined, discountedPriceFormatted: string,
  currencyCode: string, currencySymbol?: string) => {

  let currentPrice = Math.round(convertFormattedPriceToNumber(discountedPriceFormatted) * 100);
  let formattedPrice = discountedPriceFormatted;

  if (!appliedCoupons || appliedCoupons.length === 0) {
    return
  }

  let totalPercentage = 0;
  let totalMonetary = 0;

  appliedCoupons.forEach((ac) => {
    if (ac.discount.getDiscountType() === DiscountType.MONETARY) {
      const priceList = (ac.discount.getMonetary() as Discount.MonetaryModel).getPricesList();
      const price = priceList.find((pl) => pl.getCurrencyCode() === currencyCode);

      totalMonetary += price ? price.getValue() : 0;
    }

    if (ac.discount.getDiscountType() === DiscountType.PERCENTAGE) {
      const percentage = (ac.discount.getPercentage() as Discount.PercentageModel).getPercentage() / 100;
      totalPercentage += percentage;
    }

    currentPrice = currentPrice + (totalMonetary);
    currentPrice = currentPrice + (currentPrice * (totalPercentage / 100));

    formattedPrice = `${currencySymbol || extractCurrencyFromPriceFormatted(discountedPriceFormatted).currency}${parseFloat((currentPrice / 100).toFixed(2))}`;

  });

  return formattedPrice
}



export const convertFormattedPriceToNumber = (formattedPrice: string) => {
  return +formattedPrice.replace(/[^0-9.]/g, "")
}


export const getMomentFrequencyTypeParam = (frequencyType: FrequencyTypeGrpc) => {
  switch (frequencyType) {
    case FrequencyTypeGrpc.DAILY: return "days"
    case FrequencyTypeGrpc.MONTHLY: return "months"
    case FrequencyTypeGrpc.WEEKLY: return "weeks"
    case FrequencyTypeGrpc.YEARLY: return "years"
    default: return "month"
  }
}

/**
 * trim whitespaces from string, at the beginning, at the end, or all of them
 */
export const trimWhiteSpaces = (text: string, type: "left" | "right" | "all") => {
  if (type === "left") {
    return text.replace(/^\s+/g, "");
  }
  if (type === "right") {
    return text.replace(/\s+$/g, "");
  }
  return text.replace(/\s/g, "");
}

export function divideNumberBy100<T>(value?: T): T | undefined {
  if (value === undefined || value === null) { return }
  if (!(value as any as Int32Value).getValue) {
    //treat it like a number primitive
    return Number(value) / 100 as any as T;
  }
  const newVal = (value as any as Int32Value).getValue();
  const newValDivideBy100 = new Int32Value();
  newValDivideBy100.setValue(newVal / 100);
  return newValDivideBy100 as any as T;
}

export const addScriptDynamically = (url: string, isAsync = false, queryParams: { [key: string]: string } = {}, onloadCb?: () => any) => {
  let existingQueryString = "";

  if (url.indexOf("?") > 0) {
    existingQueryString = url.substring(url.indexOf("?"), url.length);
    url = url.substring(0, url.indexOf("?"));
  }

  const queryStringParsed: any = queryString.parse(existingQueryString);
  Object.keys(queryParams).forEach(queryParamKey => {
    queryStringParsed[queryParamKey] = queryParams[queryParamKey];
  });
  const script: HTMLScriptElement = document.createElement("script");
  script.setAttribute("src", `${url}?${queryString.stringify(queryStringParsed)}`);

  script.async = isAsync;
  document.head.appendChild(script);
  script.onload = () => {
    onloadCb && onloadCb();
  }
}

export const getTaxName = (countryIso3Code?: string | null): { short: string; full: string } => {
  let taxName = { short: "Tax Registration Number", full: "Tax Registration Number" };

  if (!countryIso3Code) {
    return taxName;
  }

  if (europeanCountriesCode.indexOf(countryIso3Code) > -1) {
    return (taxName = { short: "EU VAT number", full: "EU VAT number" });
  }

  switch (countryIso3Code) {
    case ComplexTaxCountry.UnitedStates:
      return (taxName = { short: "TIN number", full: "TIN number" })

    case ComplexTaxCountry.Australia:
      return (taxName = { short: "ABN number", full: "Australian Business Number" });

    case ComplexTaxCountry.Canada:
      return (taxName = { short: "BN number", full: "Canadian Business Number" });

    case ComplexTaxCountry.India:
      return (taxName = { short: "GSTIN number", full: "GSTIN number" });

    case ComplexTaxCountry.NewZealand:
      return (taxName = { short: "NZBN number", full: "New Zealand Business Number" });

    default:
      return taxName;
  }
};

export const gaEventTracker = (action: CustomAnalyticsEvents, config: IAnalyticsEventConfig = { value: 1 }) => {
  return ReactGA.event(action, config)
}

export const getPaymentScreenBySourceType = (planPaymentSourceTypes: Array<PaymentMethodType>, preLoadCustomerData: Partial<IBillsbyData>,
  hasCustomerPaymentOnFile: boolean): PaymentScreen => {

  const paymentSourceToScreen = {
    [PaymentMethodType.Ach]: PaymentScreen.ACH,
    [PaymentMethodType.CreditCard]: PaymentScreen.CREDIT_CARD,
    [PaymentMethodType.None]: PaymentScreen.CREDIT_CARD,
  }

  if (hasCustomerPaymentOnFile) {
    return PaymentScreen.CARD_ON_FILE;
  }

  if (preLoadCustomerData.paymentSourceType && planPaymentSourceTypes.includes(preLoadCustomerData.paymentSourceType)) {
    return paymentSourceToScreen[preLoadCustomerData.paymentSourceType]
  }

  if (planPaymentSourceTypes.length === 1 && planPaymentSourceTypes.includes(PaymentMethodType.CreditCard)) {
    return PaymentScreen.CREDIT_CARD;
  }
  if (planPaymentSourceTypes.length === 1 && planPaymentSourceTypes.includes(PaymentMethodType.Ach)) {
    return PaymentScreen.ACH;
  }

  return PaymentScreen.SELECT_TYPE;
}

export const generateRecaptchaActionToken = async (action: RecaptchaActions) => {
  const grecaptcha = (window as any).grecaptcha;

  return new Promise((resolve, reject) => {
    grecaptcha?.enterprise?.ready?.(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(ConfigConstants.reCaptchaEnterpriseKey, { action });
        sessionStorage.setItem(GOOGLE_RECAPTCHA_TOKEN, token);
        resolve(token)
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  })
}