import React from "react";
import "./FormLabel.scss";
import Text from "../text/Text";

interface IFormLabel {
  content: string,
  shouldTranslate?: boolean,
  translateWith?: object,
  className?: string,
  onClick?: () => void,
  noMargin?: boolean,
  size?: "xlarge" | "large" | "normal" | "small" | "xsmall"
  weight?: "bold" | "normal"
}

const FormLabel: React.FC<IFormLabel> = React.memo(({ content, shouldTranslate = true, translateWith, className = "", onClick = () => { },
  noMargin = false, size, weight }) => {
  return (
    <Text
      content={content}
      className={`form-label ${className}`}
      shouldTranslate={shouldTranslate}
      translateWith={translateWith}
      onClick={onClick}
      noMargin={noMargin}
      size={size}
      weight={weight}
    />
  )
})

export default FormLabel;