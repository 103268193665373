import React from "react";
import Text from "../text/Text";
import Panel from "../panel/Panel";
import "./InteractivePanel.scss";

export interface IInteractivePanel {
  id: string,
  title: string,
  shouldTranslateTitle?: boolean,
  translateWith?: any,
  isVisible?: boolean,
  className?: string,
  onClick?: () => void
}

const InteractivePanel: React.FC<IInteractivePanel> = React.memo(({ id, title, shouldTranslateTitle = true, translateWith,
  isVisible = true, className = "", onClick = () => { } }) => {

  if (!isVisible) {
    return null;
  }

  return (
    <Panel
      id={id}
      isClickable
      className={`interactive-panel ${className}`}
      onClick={onClick}>
      <Text
        content={title}
        shouldTranslate={shouldTranslateTitle}
        translateWith={translateWith}
        className="interactive-panel__text"
        noMargin
      />
    </Panel>
  )
})

export default InteractivePanel;