/**
 * @fileoverview gRPC-Web generated client stub for billing.public.subscription
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  AllowancesPricingDisclosureResponse,
  CancelSubscriptionRequest,
  CancelSubscriptionResponse,
  CheckoutAddonsSimulationRequest,
  CheckoutAddonsSimulationResponse,
  CheckoutAllowancesSimulationRequest,
  CheckoutAllowancesSimulationResponse,
  GetAddonsRequest,
  GetAddonsResponse,
  GetAllowancesRequest,
  GetAllowancesResponse,
  GetCancellationDetailsRequest,
  GetCancellationDetailsResponse,
  GetCheckoutAddonsSimulationPricingDisclosureResponse,
  GetPendingMinimumTermAmountRequest,
  GetPendingMinimumTermAmountResponse,
  GetSubscriptionUnpaidAmountRequest,
  GetSubscriptionUnpaidAmountResponse,
  PayPendingMinimumTermAmountRequest,
  PayPendingMinimumTermAmountResponse,
  UpdateSubscriptionAddonsRequest,
  UpdateSubscriptionAddonsResponse,
  UpdateSubscriptionAllowanceRequest,
  UpdateSubscriptionAllowanceResponse} from "./subscription_pb";

export class SubscriptionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetSubscriptionUnpaidAmount = new grpcWeb.AbstractClientBase.MethodInfo(
    GetSubscriptionUnpaidAmountResponse,
    (request: GetSubscriptionUnpaidAmountRequest) => {
      return request.serializeBinary();
    },
    GetSubscriptionUnpaidAmountResponse.deserializeBinary
  );

  getSubscriptionUnpaidAmount(
    request: GetSubscriptionUnpaidAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetSubscriptionUnpaidAmountResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.subscription.SubscriptionService/GetSubscriptionUnpaidAmount",
      request,
      metadata || {},
      this.methodInfoGetSubscriptionUnpaidAmount,
      callback);
  }

  methodInfoGetPendingMinimumTermAmount = new grpcWeb.AbstractClientBase.MethodInfo(
    GetPendingMinimumTermAmountResponse,
    (request: GetPendingMinimumTermAmountRequest) => {
      return request.serializeBinary();
    },
    GetPendingMinimumTermAmountResponse.deserializeBinary
  );

  getPendingMinimumTermAmount(
    request: GetPendingMinimumTermAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetPendingMinimumTermAmountResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.subscription.SubscriptionService/GetPendingMinimumTermAmount",
      request,
      metadata || {},
      this.methodInfoGetPendingMinimumTermAmount,
      callback);
  }

  methodInfoPayPendingMinimumTermAmount = new grpcWeb.AbstractClientBase.MethodInfo(
    PayPendingMinimumTermAmountResponse,
    (request: PayPendingMinimumTermAmountRequest) => {
      return request.serializeBinary();
    },
    PayPendingMinimumTermAmountResponse.deserializeBinary
  );

  payPendingMinimumTermAmount(
    request: PayPendingMinimumTermAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: PayPendingMinimumTermAmountResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.subscription.SubscriptionService/PayPendingMinimumTermAmount",
      request,
      metadata || {},
      this.methodInfoPayPendingMinimumTermAmount,
      callback);
  }

  methodInfoGetAddons = new grpcWeb.AbstractClientBase.MethodInfo(
    GetAddonsResponse,
    (request: GetAddonsRequest) => {
      return request.serializeBinary();
    },
    GetAddonsResponse.deserializeBinary
  );

  getAddons(
    request: GetAddonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetAddonsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.subscription.SubscriptionService/GetAddons",
      request,
      metadata || {},
      this.methodInfoGetAddons,
      callback);
  }

  methodInfoUpdateSubscriptionAddons = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateSubscriptionAddonsResponse,
    (request: UpdateSubscriptionAddonsRequest) => {
      return request.serializeBinary();
    },
    UpdateSubscriptionAddonsResponse.deserializeBinary
  );

  updateSubscriptionAddons(
    request: UpdateSubscriptionAddonsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateSubscriptionAddonsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.subscription.SubscriptionService/UpdateSubscriptionAddons",
      request,
      metadata || {},
      this.methodInfoUpdateSubscriptionAddons,
      callback);
  }

  methodInfoCheckoutAddonsSimulation = new grpcWeb.AbstractClientBase.MethodInfo(
    CheckoutAddonsSimulationResponse,
    (request: CheckoutAddonsSimulationRequest) => {
      return request.serializeBinary();
    },
    CheckoutAddonsSimulationResponse.deserializeBinary
  );

  checkoutAddonsSimulation(
    request: CheckoutAddonsSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CheckoutAddonsSimulationResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.subscription.SubscriptionService/CheckoutAddonsSimulation",
      request,
      metadata || {},
      this.methodInfoCheckoutAddonsSimulation,
      callback);
  }

  methodInfoGetAllowances = new grpcWeb.AbstractClientBase.MethodInfo(
    GetAllowancesResponse,
    (request: GetAllowancesRequest) => {
      return request.serializeBinary();
    },
    GetAllowancesResponse.deserializeBinary
  );

  getAllowances(
    request: GetAllowancesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetAllowancesResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.subscription.SubscriptionService/GetAllowances",
      request,
      metadata || {},
      this.methodInfoGetAllowances,
      callback);
  }

  methodInfoUpdateSubscriptionAllowance = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateSubscriptionAllowanceResponse,
    (request: UpdateSubscriptionAllowanceRequest) => {
      return request.serializeBinary();
    },
    UpdateSubscriptionAllowanceResponse.deserializeBinary
  );

  updateSubscriptionAllowance(
    request: UpdateSubscriptionAllowanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateSubscriptionAllowanceResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.subscription.SubscriptionService/UpdateSubscriptionAllowance",
      request,
      metadata || {},
      this.methodInfoUpdateSubscriptionAllowance,
      callback);
  }

  methodInfoCheckoutAllowancesSimulation = new grpcWeb.AbstractClientBase.MethodInfo(
    CheckoutAllowancesSimulationResponse,
    (request: CheckoutAllowancesSimulationRequest) => {
      return request.serializeBinary();
    },
    CheckoutAllowancesSimulationResponse.deserializeBinary
  );

  checkoutAllowancesSimulation(
    request: CheckoutAllowancesSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CheckoutAllowancesSimulationResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.subscription.SubscriptionService/CheckoutAllowancesSimulation",
      request,
      metadata || {},
      this.methodInfoCheckoutAllowancesSimulation,
      callback);
  }

  methodInfoCancelSubscription = new grpcWeb.AbstractClientBase.MethodInfo(
    CancelSubscriptionResponse,
    (request: CancelSubscriptionRequest) => {
      return request.serializeBinary();
    },
    CancelSubscriptionResponse.deserializeBinary
  );

  cancelSubscription(
    request: CancelSubscriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CancelSubscriptionResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.subscription.SubscriptionService/CancelSubscription",
      request,
      metadata || {},
      this.methodInfoCancelSubscription,
      callback);
  }

  methodInfoGetCancellationDetails = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCancellationDetailsResponse,
    (request: GetCancellationDetailsRequest) => {
      return request.serializeBinary();
    },
    GetCancellationDetailsResponse.deserializeBinary
  );

  getCancellationDetails(
    request: GetCancellationDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCancellationDetailsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.subscription.SubscriptionService/GetCancellationDetails",
      request,
      metadata || {},
      this.methodInfoGetCancellationDetails,
      callback);
  }

  methodInfoGetAllowancesSimulationPricingDisclosure = new grpcWeb.AbstractClientBase.MethodInfo(
    AllowancesPricingDisclosureResponse,
    (request: CheckoutAllowancesSimulationRequest) => {
      return request.serializeBinary();
    },
    AllowancesPricingDisclosureResponse.deserializeBinary
  );

  getAllowancesSimulationPricingDisclosure(
    request: CheckoutAllowancesSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: AllowancesPricingDisclosureResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.subscription.SubscriptionService/GetAllowancesSimulationPricingDisclosure",
      request,
      metadata || {},
      this.methodInfoGetAllowancesSimulationPricingDisclosure,
      callback);
  }

  methodInfoGetAddonsSimulationPricingDisclosure = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCheckoutAddonsSimulationPricingDisclosureResponse,
    (request: CheckoutAddonsSimulationRequest) => {
      return request.serializeBinary();
    },
    GetCheckoutAddonsSimulationPricingDisclosureResponse.deserializeBinary
  );

  getAddonsSimulationPricingDisclosure(
    request: CheckoutAddonsSimulationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCheckoutAddonsSimulationPricingDisclosureResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.subscription.SubscriptionService/GetAddonsSimulationPricingDisclosure",
      request,
      metadata || {},
      this.methodInfoGetAddonsSimulationPricingDisclosure,
      callback);
  }

}

