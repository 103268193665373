import React, { useEffect, useState } from "react";
import counterpart from "counterpart";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { setRetentionField, getPlanChange } from "../../actions/retentionActions";
import { RetentionScreen } from "../../models/Retention";
import "./Retention.scss";
import ChangePlan from "../../containers/change-plan/ChangePlan";
import { IRetentionReducer } from "../../reducers/retentionReducer";
import { getNextRetentionStepAvailable } from "../../utils/commonUtils";
import history from "../../utils/history";


const RetentionChangePlan: React.FC = () => {
  const { companyDomain } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const { planChange, retentionStepsAvailability } = useSelector<AppState, IRetentionReducer>(state => state.retentionReducer);
  const dispatch = useDispatch<Function>()

  const [previousScreen, setPreviousScreen] = useState<RetentionScreen | null>(null);

  useEffect(() => {
    dispatch(getPlanChange(companyDomain));
  }, []);

  useEffect(() => {
    if(retentionStepsAvailability) {
      const previousScreen = getNextRetentionStepAvailable(retentionStepsAvailability, "backward", RetentionScreen.changePlan);
      setPreviousScreen(previousScreen);
    }
  }, [retentionStepsAvailability]);

  const goBackCallback = () => {
    if (previousScreen) {
      dispatch(setRetentionField("currentScreen", previousScreen))
    }
    else {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }

  if (!planChange || !retentionStepsAvailability) {
    return null;
  }

  return (
    <ChangePlan
      customizedHeading={planChange.getText()}
      bottomContent={
        <a
          href="javascript:void(0)"
          className="retention__link"
          onClick={() => dispatch(setRetentionField("currentScreen", getNextRetentionStepAvailable(retentionStepsAvailability, "forward", RetentionScreen.changePlan)))} >
          {counterpart("RETENTION_CONTINUE_TOCANCEL")}
        </a>
      }
    />
  )
}

export default RetentionChangePlan