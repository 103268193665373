import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { IGlobalReducer } from "../../../reducers/globalReducer";
import { IAddOnReducer } from "../../../reducers/addOnReducer";
import { ISubscriptionReducer } from "../../../reducers/subscriptionReducer";
import { IAllowance, IAllowancePricingModel } from "../../../models/AddOnAllowance";
import PageWrapper from "../../../containers/page-wrapper/PageWrapper";
import Text from "../../ui/text/Text";
import moment from "moment";
import { ICycle } from "../../../models/Product";
import counterpart from "counterpart";
import { removeSubscriptionAllowances, setAllowances, setAllowanceUnits, getAllowancePriceSimulation, addNewAllowances, getAllowancePricingDisclosure } from "../../../actions/addOnActions";
import SystemInfo from "../../ui/system-info/SystemInfo";
import "./ManageAllowanceList.scss";
import history from "../../../utils/history";
import { AccountManagementPermissions } from "../../../models/AccountManagement";
import AllowanceSelector from "../allowance-selector/AllowanceSelector";
import Modal from "../../ui/modal/Modal";
import PricingInfoDisclosure from "../../pricing-info-disclosure/PricingInfoDisclosure";


const ManageAllowanceList: React.FC = () => {
  const { companyDomain, checkoutConfig, branding, accountManagementPermissions } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { subscriptionAllowances, allowances, allowancePricingDisclosure, isAddingNewAllowancesRequest, isAddingNewAllowancesSuccess,
    isAddingNewAllowancesFailure, isRemovingAllowancesRequest, isRemovingAllowancesSuccess, isRemovingAllowancesFailure } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const { currentPlan, currentSubscription } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const dispatch = useDispatch<Function>()

  const [hasAddedNewAllowances, setHasAddedNewAllowances] = useState(false);
  const [hasChangedSubscriptionAllowances, setHasChangedSubscriptionAllowances] = useState(false);
  const [newAllowancesAdded, setNewAllowancesAdded] = useState<number>(0);
  const [hasError, setHasError] = useState(false);
  const [showOverageInfoModal, setShowOverageInfoModal] = useState(false);
  const [isLoadingPriceSimulation, setIsLoadingPriceSimulation] = useState(false);
  const [hasIncludedUnits, setHasIncludedUnits] = useState(false);

  useEffect(() => {
    if (isAddingNewAllowancesSuccess || isAddingNewAllowancesFailure || isRemovingAllowancesSuccess || isRemovingAllowancesFailure) {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }, [isAddingNewAllowancesSuccess, isAddingNewAllowancesFailure, isRemovingAllowancesSuccess, isRemovingAllowancesFailure])


  useEffect(() => {
    const fetchPriceSimulation = async () => {
      if (currentSubscription && hasAddedNewAllowances && subscriptionAllowances) {
        try {
          setIsLoadingPriceSimulation(true);
          const newAllowances = allowances.filter(al => !!al.units && !subscriptionAllowances.some(sa => sa.id === al.id))
          await dispatch(getAllowancePriceSimulation(companyDomain, currentSubscription.subscriptionUniqueId, newAllowances));
          await dispatch(getAllowancePricingDisclosure(companyDomain, currentSubscription.subscriptionUniqueId, newAllowances));
        }
        catch (err) { }
        finally { setIsLoadingPriceSimulation(false) }
      }
    }
    fetchPriceSimulation();
  }, [hasAddedNewAllowances, allowances]);

  const backupAllowances: Array<IAllowance> = useMemo(() => {
    if (currentPlan && currentSubscription && subscriptionAllowances) {
      const currentCycle = currentPlan.cycles.find(cycle => cycle.cycleId === currentSubscription.cycleId) as ICycle;
      if (currentCycle) {
        // new allowances excluding the ones already added by the customer
        const newAllowances = currentPlan.allowances
          .filter(allowance => allowance.pricingModels.some(p => (
            p.frequency === currentCycle.pricingModel.frequency
            && p.frequencyType === currentCycle.pricingModel.frequencyType
            && p.currency.isoCode === currentSubscription.currency
          ))
            && !subscriptionAllowances.some(a => a.id === allowance.id));
        // include new allowances and subscription allowances
        const allAllowances = JSON.parse(JSON.stringify([...subscriptionAllowances, ...newAllowances]));
        dispatch(setAllowances(allAllowances));
        // backupAllowances contains a deep clone of the addons that will be useful if we need to reset the allowances
        // this is a very expensive operation and has to be done only once
        return JSON.parse(JSON.stringify(allAllowances));
      }
    }
    return [];
  }, [currentPlan, currentSubscription, subscriptionAllowances]);

  const checkIfCustomerAddedNewAllowances = () => {
    let hasAddedNewAllowances = false;
    let hasChangedSubscriptionAllowances = false;

    for (let i = 0; i < allowances.length && !hasAddedNewAllowances; i++) {
      if (backupAllowances.some(a => a.id === allowances[i].id
        && a.units !== allowances[i].units
        && !allowances[i].isForced
        && !(allowances[i].units === 0 && a.units === undefined)
        && subscriptionAllowances?.every(ad => ad.id !== a.id))) {
        hasAddedNewAllowances = true;
      }
    }
    for (let i = 0; i < allowances.length && !hasChangedSubscriptionAllowances; i++) {
      hasChangedSubscriptionAllowances = !!subscriptionAllowances?.some(a => a.id === allowances[i].id && a.units !== allowances[i].units);
    }
    setHasAddedNewAllowances(hasAddedNewAllowances);
    setHasChangedSubscriptionAllowances(hasChangedSubscriptionAllowances);
  }

  const checkIfAllowancesHasIncludedUnits = () => {
    if (!!subscriptionAllowances) {
      const allowanceList = [...subscriptionAllowances, ...allowances];
      const pricingModels = allowanceList.filter(a => !!a.units).map(a => a.pricingModels.find(
        (i) => i.frequency === currentCycle.pricingModel.frequency && i.frequencyType === currentCycle.pricingModel.frequencyType && i.currency.isoCode === currentCycle.currency
      ));
      setHasIncludedUnits(pricingModels.filter(p => !!p?.includedUnits).length > 0);
    } else {
      setHasIncludedUnits(false);
    }
  }

  useEffect(() => {
    checkIfCustomerAddedNewAllowances();
    checkIfAllowancesHasIncludedUnits();
    const addedAllowancesNr = allowances.filter(al => !!al.units && !subscriptionAllowances?.some(subAl => subAl.id === al.id)).length;
    setNewAllowancesAdded(addedAllowancesNr);
  }, [allowances]);

  if (!currentPlan || !currentSubscription || !subscriptionAllowances || !checkoutConfig) {
    return null;
  }

  const updateAllowance = (allowanceId: number, pricingModels: IAllowancePricingModel[], units?: number) => {
    const isNewAllowance = subscriptionAllowances.every(a => a.id !== allowanceId);
    setHasError(false);
    if (hasChangedSubscriptionAllowances && isNewAllowance) {
      // before showing the warning we scroll to the top of the page to make sure the warning is visible even if there are many add-ons
      const pageWrapperScrollable = document.querySelector(".page-wrapper__body-content");
      pageWrapperScrollable && pageWrapperScrollable.scrollTo({ top: 0, behavior: "smooth" });
      setHasError(true);
    }
    else {
      dispatch(setAllowanceUnits({ id: allowanceId, units, pricingModels }))
    }
  };

  const nextBilling = moment(currentSubscription.nextBilling).format("DD MMM YYYY");
  const currentCycle = currentPlan.cycles.find(cycle => cycle.cycleId === currentSubscription.cycleId) as ICycle;

  const renderPriceSummaryModal = () => {
    return (
      <Modal isOpen={showOverageInfoModal} title="MANAGE_ALLOWANCE_OVERAGE_INFO" onRequestClose={() => setShowOverageInfoModal(false)}>
        {allowancePricingDisclosure && <PricingInfoDisclosure rawHTMLEncoded={allowancePricingDisclosure} />}
      </Modal>
    )
  }

  return (
    <PageWrapper
      title="MANAGE_ALLOWANCE_TITLE"
      titleTranslateWith={{ allowanceLabel: checkoutConfig?.terminologyAllowancePlural }}
      titlePosition="left-aligned"
      subTitle="MANAGE_ALLOWANCE_SUBTITLE"
      subTitleTranslateWith={{ nextBilling, allowanceLabel: checkoutConfig?.terminologyAllowancePlural }}
      btnText={hasAddedNewAllowances
        ? counterpart("MANAGE_ALLOWANCE_ADD_NEW_ONES", {
          allowanceLabel: newAllowancesAdded > 1
            ? counterpart("MANAGE_ALLOWANCE_ADD_MULTIPLE_ALLOWANCES")
            : counterpart("MANAGE_ALLOWANCE_ADD_ONE_ALLOWANCE")
        })
        : counterpart("MANAGE_ALLOWANCE_SAVE_CHANGES")}
      shouldTranslateText={false}
      btnDisabled={(!hasAddedNewAllowances && !hasChangedSubscriptionAllowances) || isAddingNewAllowancesRequest || isRemovingAllowancesRequest}
      btnPosition="bottom"
      btnCallback={() => {
        if (!hasAddedNewAllowances && hasChangedSubscriptionAllowances) {
          dispatch(removeSubscriptionAllowances(companyDomain, currentSubscription.subscriptionUniqueId, allowances));
        }
        if (hasAddedNewAllowances && !hasChangedSubscriptionAllowances) {
          dispatch(addNewAllowances(companyDomain, currentSubscription.subscriptionUniqueId, allowances));
        }
      }}
      isLoading={isAddingNewAllowancesRequest || isRemovingAllowancesRequest || isLoadingPriceSimulation}
      btnBackCallback={() => history.push({ pathname: "/management", search: history.location.search })}
      btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION}
      bottomStickyContent={
        hasAddedNewAllowances && hasIncludedUnits
          ? <Text
            className="manage-allowance-list__calculation"
            content="MANAGE_ALLOWANCE_OVERAGE_INFO"
            onClick={() => setShowOverageInfoModal(true)}
            noMargin
          />
          : undefined
      }>
      <>
        <SystemInfo
          title="MANAGE_ALLOWANCE_SAVE_CHANGES_ERROR"
          translateWith={{ allowanceLabel: checkoutConfig?.terminologyAllowancePlural }}
          isShowIcon={false}
          type="warning"
          isVisible={hasError}
        />
        {
          allowances.map((allowance: IAllowance) => (
            <AllowanceSelector
              key={allowance.id}
              allowance={allowance}
              plan={currentPlan}
              cycle={currentCycle}
              branding={branding}
              checkoutConfig={checkoutConfig}
              isLocked={hasAddedNewAllowances && subscriptionAllowances.some(a => a.id === allowance.id)}
              willBeDeleted={!!allowance.willBeDeleted}
              updateAddons={(allowanceId: number, pricingModels: IAllowancePricingModel[], units?: number) =>
                updateAllowance(allowanceId, pricingModels, units)}
              nextBillingDate={nextBilling}
              image={allowance.imageUrl}
            />
          ))
        }
        {renderPriceSummaryModal()}
      </>

    </PageWrapper>
  )
}

export default ManageAllowanceList