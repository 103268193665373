import { IBranding } from "../models/Branding";
import WebFont from "webfontloader";
import { isMobileOnly } from "react-device-detect";
import { ICheckoutConfig } from "../models/BillsbyData";


export const getBrandingInlineStyle = (branding: IBranding, checkoutConfig?: ICheckoutConfig): string => {
  if (!branding || !Object.keys(branding).length) {
    return "";
  }

  WebFont.load({
    google: {
      families: [branding.typographyBody, branding.typographyHeader]
    }
  });

  const getBranding = () => {
    return ` 
      .text:not(.footer__text1):not(.floating-header__text-block__text),
      .checkbox-general__label,
      .custom-date-picker .DateInput input,
      .number-input__field,
      input[type=tel] {
        font-family: ${branding.typographyBody};
        color: ${branding.bodyTextColor};
      }
      .system-info-container-warning .system-info-container__bg-color {
        background-color: ${branding.errorColor};
      }
      .system-info-container-success .system-info-container__bg-color {
        background-color: ${branding.confirmationColor};
      }
      .system-info-container-warning .text,
      .billsby-phone-input__error,
      .invoice-summary__transaction-details--error {
        color: ${branding.errorColor} !important;
      }
      .system-info-container-success .text,
      .update-subscription__discounts .payment-summary__coupon .text,
      .retention__reason-to-stay__el > i,
      .payment-summary__coupon span  {
        color: ${branding.confirmationColor} !important;
      }
      .page-wrapper__scrollable,
      .container {
        background-color: ${branding.backgroundColor} !important;
      }
      .page-wrapper__sticky-header {
        background: transparent;
      }
      .page-wrapper__gradient {
         background:  linear-gradient(0deg, rgba(${hexToRgb(branding.backgroundColor)},1) 9%, rgba(${hexToRgb(branding.backgroundColor)},0) 55%)
      }
      .page-wrapper__sticky-header__back-btn i, 
      .page-wrapper__sticky-header__content > *:nth-child(1), 
      .page-wrapper__sticky-header__close-btn > i,
      .form-label,
      .retention__discount-offer__content .text,
      .retention__time-remaining .text,
      .retention__help-interrupt-heading > *:first-child,
      .account-management__subscription-cancelled > *:first-child,
      .cycle-price-info__plan-name,
      .icon-container .icon   {
        color: ${branding.headerAndButtonColor} !important;
      }
      .icon-container:hover, .icon-container:focus {
        background-color: rgba(${hexToRgb(branding.headerAndButtonColor)},0.1)
      }
      .icon-container:active {
        background-color: rgba(${hexToRgb(branding.headerAndButtonColor)}, 0.2)
      }

      .retention__time-remaining > *:first-child {
        border-color: ${branding.headerAndButtonColor} !important;
      }
      
      .page-wrapper__sticky-header__content > *:nth-child(1),
      .form-label,
      .retention__discount-offer__content .text,
      .retention__time-remaining .text,
      .CalendarMonth_caption {
        font-family: ${branding.typographyHeader} !important;
        text-transform: ${branding.typographyHeaderIsAllCaps ? "uppercase" : "none"} !important;
      }
      .navigation-bar__filler, .navigation-bar__text,
      .loading-animation--lds-ellipsis div {
        background-color: ${branding.headerAndButtonColor} !important;
      }
      .navigation-bar__text {
        border-top-right-radius: ${branding.rounding}px;
        border-top-left-radius: ${branding.rounding}px;
      }
      .panel__content__image {
        border-top-left-radius: ${branding.rounding}px;
        border-bottom-left-radius: ${branding.rounding}px;
      }
      .panel__content__image.panel__content__image--box, .payment-summary {
        border-radius: ${branding.rounding}px !important;
      }
      .panel__content--selected .panel__content__image  {
        border-bottom-right-radius: ${isMobileOnly ? branding.rounding : 0}px;
      }
      .navigation-bar__container {
        background-color: ${lightenDarkenColor(branding.headerAndButtonColor, 100)};
      }
      .collapsible-panel__expanded-content, .navigation-bar__container {
        border-bottom-left-radius: ${branding.rounding}px;
        border-bottom-right-radius: ${branding.rounding}px;
      }
      .btn__basic span {
        font-family: ${branding.typographyBody} !important;
      }
      .btn__general, .btn__general[disabled], .cart__button  {
        background-color: ${branding.headerAndButtonColor} !important;
        border-${isMobileOnly ? "top-left" : "top-right"}-radius: ${branding.rounding}px;
        border-${isMobileOnly ? "top-right" : "bottom-right"}-radius: ${branding.rounding}px;
      }
      .number-input__btn-plus, .number-input__btn-minus,
      .custom-select__option--is-focused {
        background-color: ${branding.headerAndButtonColor} !important;
      }
      .selector__container:not(.selected), .selector__btn:not(.selected) {
        background-color: ${branding.headerAndButtonColor} !important;
      }
      .selector__container.selected, .selector__btn.selected {
        background-color: ${branding.confirmationColor} !important;
      }
      .selector__text,
      .number-input__btn-plus.disabled,
      .number-input__btn-minus.disabled {
        color: white !important;
      }
      .number-input__btn-minus.lock, .selector__container.lock, .selector__btn.lock, .number-input__btn-plus.disabled, .number-input__btn-minus.disabled {
        background-color: ${branding.errorColor} !important;
      }
      .MuiOutlinedInput-root input {
        font-family: ${branding.typographyBody} !important;
        color: ${branding.bodyTextColor} !important;
      }
      .MuiFormHelperText-root.Mui-error {
        font-family: ${branding.typographyBody} !important;
        color: ${branding.errorColor} !important;
      }
      .MuiAutocomplete-input, .MuiAutocomplete-listbox li {
        font-family: ${branding.typographyBody} !important;
        color: ${branding.bodyTextColor} !important;
      }
      .MuiAutocomplete-listbox li:hover {
        background-color: ${branding.headerAndButtonColor} !important;
        color: #fff !important;
      }
      .Mui-error .MuiOutlinedInput-notchedOutline,
      .MuiOutlinedInput-root.Mui-error {
        border-color: ${branding.errorColor} !important;
      }
      .modal__body__title, .modal__body__close-btn > i,
      .CalendarMonth_caption,
      .checkbox-general__icon {
        color: ${branding.headerAndButtonColor} !important;
      }
      .billsby-phone-input .selected-flag{
        background-color: ${branding.headerAndButtonColor} !important;
      }
      .billsby-phone-input .country-list {
        background: ${branding.headerAndButtonColor} !important;
      }
      .billsby-phone-input .country-list .country > span, .billsby-phone-input .selected-dial-code,
      .custom-select__option,
      .custom-select__single-value,
      .billsby-phone-input__error {
        font-family: ${branding.typographyBody} !important;
      }
      .billsby-phone-input .expanded .flag-container::after {
        border-bottom: 10px solid ${branding.headerAndButtonColor} !important;
      }
      /*old dropdown*/
      .custom-select__option--is-focused, .c2a_results li:hover {
        background-color: ${branding.headerAndButtonColor} !important;
      }
      .personal-details__custom-fields__checkbox > .text:first-of-type {
        color: ${branding.headerAndButtonColor} !important;
      }
      .modal__body, .modal__wrapper, .panel, .cart__content, .btn__general, .MuiOutlinedInput-root, .selector__container, .payment-details-form__spreedly-input-container,
      .custom-select__control,
      .custom-select__menu-list,
      .icon-container {
        border-radius: ${branding.rounding}px;
      }
      .number-input__btn-plus {
        border-radius: 0px;
        border-top-right-radius: ${branding.rounding}px !important;
        border-bottom-right-radius: ${branding.rounding}px !important;
      }
      .selected-flag,  .number-input__btn-minus {
        border-radius: 0px;
        border-top-left-radius: ${branding.rounding}px !important;
        border-bottom-left-radius: ${branding.rounding}px !important;
      }
      .billsby-phone-input input, .country-list {
        border-radius: 0 !important;
      }

      /* Scrollbar */
      *::-webkit-scrollbar {
        width: 10px;
      }
     
      /* Track */
      *::-webkit-scrollbar-track {
        box-shadow: inset 0 0 36px rgba(${hexToRgb("#555555")},0.2); 
        border-radius: 15px;
      }
      
      /* Handle */
      *::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 36px rgba(${hexToRgb("#AFAFAF")},1); 
        border-radius: 15px;
      }
      *:not(.cart__body):not(.modal__body)::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 36px rgba(${hexToRgb(branding.headerAndButtonColor)},0.5);
      }
      *:not(.cart__body):not(.modal__body)::-webkit-scrollbar-track {
        box-shadow: inset 0 0 36px rgba(${hexToRgb(branding.headerAndButtonColor)},0.15);
      }

      *{
        scrollbar-color: rgba(${hexToRgb(branding.headerAndButtonColor)},0.5) rgba(${hexToRgb(branding.headerAndButtonColor)},0.15);
        scrollbar-width: thin;
      }

      .cart__body, .modal__body {
        scrollbar-color:  rgba(${hexToRgb("#AFAFAF")},1) rgba(${hexToRgb("#555555")},0.2);
      }
    `;
  }

  return getBranding();

}

export const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return r + "," + g + "," + b;
}

export const lightenDarkenColor = (col: string, amt: number) => {
  let usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

}
