import React from "react";
import "./CircleIcon.scss";

interface ICircleIcon {
  number?: number
  icon?: string,
  onClick?: () => void,
  className?: string,
  isVisible?: boolean
}

const CircleIcon: React.FC<ICircleIcon> = React.memo(({ number, icon, className = "", onClick = () => { }, isVisible = true }) => {

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`circle-icon ${className}`} onClick={onClick}>
      {
        icon
          ? <i className={`${icon}`} />
          : number
      }
    </div>
  )
})

export default CircleIcon;