import React, { useEffect, useState } from "react";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import Text from "../ui/text/Text";
import Panel from "../ui/panel/Panel";
import counterpart from "counterpart";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { setRetentionField, getReasonsToStay } from "../../actions/retentionActions";
import { RetentionScreen } from "../../models/Retention";
import history from "../../utils/history";
import "./Retention.scss";
import { IRetentionReducer } from "../../reducers/retentionReducer";
import { getNextRetentionStepAvailable } from "../../utils/commonUtils";
import { AccountManagementPermissions } from "../../models/AccountManagement";
import { BillsbyDataAction } from "../../models/BillsbyData";
import { sendEvent, CROSS_DOMAIN_EVENT } from "../../utils/crossDomainEvents";


const RetentionReasonsToStay: React.FC = () => {

  const { companyDomain, checkoutConfig, accountManagementPermissions, billsbyAction } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const { currentSubscriptionDetails } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const { reasonsToStay, retentionStepsAvailability } = useSelector<AppState, IRetentionReducer>(state => state.retentionReducer);
  const dispatch = useDispatch<Function>()

  const [previousScreen, setPreviousScreen] = useState<RetentionScreen | null>(null);
  const goToNextScreen = () => retentionStepsAvailability && dispatch(setRetentionField("currentScreen", getNextRetentionStepAvailable(retentionStepsAvailability, "forward", RetentionScreen.reasonsToStay)));

  useEffect(() => {
    dispatch(getReasonsToStay(companyDomain));
  }, []);

  useEffect(() => {
    if (retentionStepsAvailability) {
      const previousScreen = getNextRetentionStepAvailable(retentionStepsAvailability, "backward", RetentionScreen.reasonsToStay);
      setPreviousScreen(previousScreen);
    }
  }, [retentionStepsAvailability]);

  if (!currentSubscriptionDetails || !reasonsToStay || !retentionStepsAvailability) {
    return null;
  }

  const getReasonToStayEl = (text: string) => {
    if (!text) { return null }
    return (
      <div className="retention__reason-to-stay__el">
        <i className="fas fa-check-circle" />
        <Text content={text} weight="bold" shouldTranslate={false} noMargin />
      </div>
    )
  }

  const goBackCallback = () => {
    if (previousScreen) {
      dispatch(setRetentionField("currentScreen", previousScreen))
    }
    else {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }

  return (
    <PageWrapper
      btnText="RETENTION_KEEP_MY_PLAN"
      btnPosition="bottom"
      btnTextTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
      title="CANCEL_SUBSCRIPTION_TITLE"
      titleTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
      btnCallback={() => billsbyAction === BillsbyDataAction.CANCEL_PLAN ? sendEvent(CROSS_DOMAIN_EVENT.CLOSE) : history.goBack()}
      btnBackCallback={goBackCallback}
      btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION}
      bottomStickyContent={
        <a
          href="javascript:void(0)"
          className="retention__link"
          onClick={goToNextScreen} >
          {counterpart("RETENTION_CONTINUE_TOCANCEL")}
        </a>
      }>
      <Panel id="retention-loose-benefits">
        <Text content="RETENTION_STEP2_LOOSE_BENEFITS" translateWith={{ planName: currentSubscriptionDetails.planName, subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }} noMargin />
      </Panel>

      <Panel id="retention__reason-to-stay" className="retention__reason-to-stay__panel">
        {getReasonToStayEl(reasonsToStay.getText1())}
        {getReasonToStayEl(reasonsToStay.getText2())}
        {getReasonToStayEl(reasonsToStay.getText3())}
        {getReasonToStayEl(reasonsToStay.getText4())}
        {getReasonToStayEl(reasonsToStay.getText5())}
      </Panel>
    </PageWrapper>
  )
}

export default RetentionReasonsToStay