import React from "react";
import "./Checkbox.scss";

interface ICheckbox {
  /**
  * The ID of the checkbox
  */
  id?: string,
  /**
   * The checked state of the checkbox
   */
  checked: any,
  /**
   * optional classes
   */
  className?: string
  /**
   * The callback when a checkbox is selected
   */
  onClick: () => void,
  /**
   * Whether or not the user has to tick these checkboxes
   */
  required?: boolean,
  /**
   * The text of the checkbox option could be either a string or a node (plain html)
   */
  content: string | JSX.Element,
  /**
   * The design style of the checkbox
   */
  checkType?: "general",
  /**
   * The value of the checkbox
   */
  value: string | number
  /*
   * Remove margin if it's true
   */
  noMargin?: boolean,
  disabled?: boolean
}

const Checkbox: React.FC<ICheckbox> = React.memo((props) => {
  const {
    id,
    checked,
    className = "",
    onClick,
    required = false,
    content = "",
    checkType = "general",
    value,
    noMargin = false,
    disabled
  } = props;

  return (
    <div className={`checkbox ${className} ${disabled ? "checkbox--disabled" : ""}`}>
      <input
        id={id}
        className={`checkbox-${checkType}__input`}
        type="checkbox"
        checked={checked}
        onClick={evt => { evt.preventDefault(); evt.stopPropagation(); }}
        onChange={evt => { evt.preventDefault(); evt.stopPropagation(); }}
        required={required}
        value={value}
      />
      {
        checked
          ? <i className={`fas fa-check-circle fa-2x checkbox-${checkType}__icon`} onClick={onClick} />
          : <i className={`fal fa-circle fa-2x checkbox-${checkType}__icon`} onClick={onClick} />
      }
      <label className={`checkbox-${checkType}__label`} htmlFor={id}>{content}</label>
    </div>

  );
})

export default Checkbox;
