/**
 * @fileoverview gRPC-Web generated client stub for core.public.companyretentionstepconfig
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  GetDiscountOfferRequest,
  GetDiscountOfferResponse,
  GetHelpInterruptRequest,
  GetHelpInterruptResponse,
  GetPlanChangeRequest,
  GetPlanChangeResponse,
  GetReasonsToStayRequest,
  GetReasonsToStayResponse,
  GetRetentionStepsRequest,
  GetRetentionStepsResponse,
  GetTimeRemainingRequest,
  GetTimeRemainingResponse} from "./retention_step_pb";

export class CompanyRetentionStepServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetHelpInterrupt = new grpcWeb.AbstractClientBase.MethodInfo(
    GetHelpInterruptResponse,
    (request: GetHelpInterruptRequest) => {
      return request.serializeBinary();
    },
    GetHelpInterruptResponse.deserializeBinary
  );

  getHelpInterrupt(
    request: GetHelpInterruptRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetHelpInterruptResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.public.companyretentionstepconfig.CompanyRetentionStepService/GetHelpInterrupt",
      request,
      metadata || {},
      this.methodInfoGetHelpInterrupt,
      callback);
  }

  methodInfoGetPlanChange = new grpcWeb.AbstractClientBase.MethodInfo(
    GetPlanChangeResponse,
    (request: GetPlanChangeRequest) => {
      return request.serializeBinary();
    },
    GetPlanChangeResponse.deserializeBinary
  );

  getPlanChange(
    request: GetPlanChangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetPlanChangeResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.public.companyretentionstepconfig.CompanyRetentionStepService/GetPlanChange",
      request,
      metadata || {},
      this.methodInfoGetPlanChange,
      callback);
  }

  methodInfoGetReasonsToStay = new grpcWeb.AbstractClientBase.MethodInfo(
    GetReasonsToStayResponse,
    (request: GetReasonsToStayRequest) => {
      return request.serializeBinary();
    },
    GetReasonsToStayResponse.deserializeBinary
  );

  getReasonsToStay(
    request: GetReasonsToStayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetReasonsToStayResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.public.companyretentionstepconfig.CompanyRetentionStepService/GetReasonsToStay",
      request,
      metadata || {},
      this.methodInfoGetReasonsToStay,
      callback);
  }

  methodInfoGetTimeRemaining = new grpcWeb.AbstractClientBase.MethodInfo(
    GetTimeRemainingResponse,
    (request: GetTimeRemainingRequest) => {
      return request.serializeBinary();
    },
    GetTimeRemainingResponse.deserializeBinary
  );

  getTimeRemaining(
    request: GetTimeRemainingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetTimeRemainingResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.public.companyretentionstepconfig.CompanyRetentionStepService/GetTimeRemaining",
      request,
      metadata || {},
      this.methodInfoGetTimeRemaining,
      callback);
  }

  methodInfoGetRetentionSteps = new grpcWeb.AbstractClientBase.MethodInfo(
    GetRetentionStepsResponse,
    (request: GetRetentionStepsRequest) => {
      return request.serializeBinary();
    },
    GetRetentionStepsResponse.deserializeBinary
  );

  getRetentionSteps(
    request: GetRetentionStepsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetRetentionStepsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.public.companyretentionstepconfig.CompanyRetentionStepService/GetRetentionSteps",
      request,
      metadata || {},
      this.methodInfoGetRetentionSteps,
      callback);
  }

  methodInfoGetDiscountOffer = new grpcWeb.AbstractClientBase.MethodInfo(
    GetDiscountOfferResponse,
    (request: GetDiscountOfferRequest) => {
      return request.serializeBinary();
    },
    GetDiscountOfferResponse.deserializeBinary
  );

  getDiscountOffer(
    request: GetDiscountOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetDiscountOfferResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/core.public.companyretentionstepconfig.CompanyRetentionStepService/GetDiscountOffer",
      request,
      metadata || {},
      this.methodInfoGetDiscountOffer,
      callback);
  }

}

