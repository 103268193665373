import React, { useLayoutEffect, useState, useEffect, useMemo } from "react";
import { IPaymentReducer } from "../../reducers/paymentReducer";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import "./PaymentMethod.scss";
import {
  setCardHolderName,
  setCardExpiryMonth,
  setCardExpiryYear,
  setCardsErrors,
  setPaymentToken,
  setPaymentCurrentScreen,
  setCardType,
  setCardLastFourDigit,
  setTermsPolicy,
  getPriceDescription,
  CREATE_SUBSCRIPTION_FAILURE,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_REQUEST,
  getPricingInfoDisclosure,
} from "../../actions/paymentActions";
import { PaymentScreen, ProRateOptions } from "../../models/Payment";
import TestGatewayPlaceHolder from "./TestGatewayPlaceHolder";
import SystemInfo from "../ui/system-info/SystemInfo";
import counterpart from "counterpart";
import { PaymentGatewayTypeId } from "../../models/Product";
import { setUpdatePaymentState } from "../../actions/updatePaymentActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import PaymentDetailsForm, { validatePaymentDetailsForm, ISpreedlyCardData } from "../payment-details-form/PaymentDetailsForm";
import { RecaptchaActions, SPREEDLY_TEST_CARD_NAME, SPREEDLY_TEST_CREDIT_CARD, SPREEDLY_TEST_CVV, SPREEDLY_TEST_EXP_MONTH, SPREEDLY_TEST_EXP_YEAR } from "../../utils/constants";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { PersonalDetailsScreen } from "../../models/PersonalDetails";
import { setNavigationStep } from "../../actions/globalActions";
import { NavigationBarSteps } from "../../models/NavigationBar";
import { setSelectPlanCurrentScreen, setCycle, setProduct } from "../../actions/selectPlanActions";
import { SelectPlanScreen } from "../../models/SelectPlan";
import { setPersonalDetailsCurrentScreen, setEmail, setTaxRegNumber, fetchTaxValidation, setTaxRegError } from "../../actions/personalDetailsActions";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import { IGlobalReducer } from "../../reducers/globalReducer";
import Checkbox from "../ui/checkbox/Checkbox";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import { gaEventTracker, generateRecaptchaActionToken, getPublicIpV4, getTaxName } from "../../utils/commonUtils";
import { CouponError, IAppliedCoupon } from "../../models/DiscountCoupon";
import { IDiscountCouponReducer } from "../../reducers/discountCouponReducer";
import { validateCouponDetails, setAppliedCoupons, setCouponCode, fetchCouponDetails } from "../../actions/discountCouponActions";
import { Coupon, EligibleCustomers, GetCouponDetailsFromCouponCodeResponse } from "../../utils/grpc/generated/Billsby.Protos/billing/public/coupons/coupons_pb";
import { IAddOnReducer } from "../../reducers/addOnReducer";
import { getCouponError, getPurchaseDetails, isComplexPricingModel, showTaxLabel } from "../../utils/planUtils";
import { IAddOn, AddOnPricingModelType } from "../../models/AddOnAllowance";
import { ICustomFieldWithValue, CustomFieldType } from "../../models/CustomFields";
import { IAddOnSubscription, ISubscription, ISubscriptionSCA, ISubscriptionExistingCustomerSCA, ISubscriptionResponseSCA, PaymentStatus } from "../../models/Subscription";
import { AddCouponDiscountRequest } from "../../utils/grpc/generated/Billsby.Protos/billing/public/subscription/discount/discount_pb";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import FormGroup from "../ui/form-group/FormGroup";
import Modal from "../ui/modal/Modal";
import Input from "../ui/input/Input";
import PaymentSummaryCoupon from "../ui/payment-summary/PaymentSummaryCoupons";
import TaxInput from "../tax-input/TaxInput";
import { TaxCollection, TaxRegNumberError } from "../../models/Taxes";
import { isMobileOnly } from "react-device-detect";
import Panel from "../ui/panel/Panel";
import { getBrowserInfo, setup3dSecure } from "../sca-setup/ScaSetup";
import API from "../../utils/API";
import { supportedLanguages } from "../../utils/languages/registerTranslations";
import AchForm from "../ach-form/AchForm";
import { IACHReducer } from "../../reducers/achReducer";
import { CustomAnalyticsEvents } from "../../models/GoogleAnalytics";
import PricingInfoDisclosure from "../pricing-info-disclosure/PricingInfoDisclosure";


const PaymentMethodStep1: React.FC = () => {

  const { checkoutConfig, companyDomain, customerLanguage, embedCodeLanguage } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const selectPlanState = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const globalReducer = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const paymentState = useSelector<AppState, IPaymentReducer>(state => state.paymentReducer);
  const discountCouponState = useSelector<AppState, IDiscountCouponReducer>(state => state.discountCouponReducer);
  const personalDetailsReducer = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const addOnReducer = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);

  const { product, plan, cycle, productId, planId, plansDropdownAvailable, productsDropdownAvailable, addOnsFilteredByCycle,
    allowancesFilteredByCycle, numberOfUnits } = selectPlanState;
  const { cardHolderName, cardExpiryMonth, cardExpiryYear, errors, paymentGatewayType, termsAndPolicies, paymentDescription,
    pricingInfoDisclosure, paymentDescriptionIsLoading, isSubscribing, hasCustomerPaymentOnFile, currentScreen } = paymentState;
  const { appliedCoupons, couponCode, couponName } = discountCouponState;
  const { mainProps: { firstName, lastName, customerUniqueId, email, companyName }, fields, address, shippingAddress, taxRegNumber,
    taxRegNumberRequirementType, taxRegError, isUserDataScreenPreloaded, isAddressScreenPreloaded } = personalDetailsReducer;
  const { addOns, allowances, preloadedAddOns, preloadedAllowances, hasPreloadedAddOnsWithMissingUnits } = addOnReducer;
  const { accountHolder, isUpdateAchSucceeded, isUpdateAchFailed, isStartMicroDepositSuccess, isStartMicroDepositFailed } = useSelector<AppState, IACHReducer>(state => state.achReducer);
  const dispatch = useDispatch<Function>()

  const { country } = address;

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>("");
  const [isOpenSummaryModal, setIsOpenSummaryModal] = useState(false);
  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);
  const [isOpenTaxNumberModal, setIsOpenTaxNumberModal] = useState(false);
  const [isApplyingCouponCode, setIsApplyingCouponCode] = useState(false);
  const [isVerifyingCouponCodes, setIsVerifyingCouponCodes] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isAchSubmitted, setIsAchSubmitted] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState<ISubscription | undefined>();
  const [error, setError] = useState<CouponError | null>(null);
  const [removedCoupons, setRemovedCoupons] = useState<Array<{ name: string; id: number }>>([]);
  const hasDiscounts = appliedCoupons && appliedCoupons.length > 0;

  const { selectedAddOns, selectedAllowances } = useMemo(() => {
    return {
      selectedAddOns: addOns.filter(addon => !!addon.units && addon.units > 0),
      selectedAllowances: allowances.filter(allowance => !!allowance.units && allowance.units > 0)
    }
  }, []);

  const isNotFreeTrial = paymentDescription && !paymentDescription.getEndTrialDate() && paymentDescription.getHasInvoices();
  const plusTaxesLabel = showTaxLabel(paymentDescription, taxRegNumber) ? `${counterpart("PRICE_SUMMARY_PLUS_TAX")}` : "";
  let buttonCopy = counterpart("PAYMENT_METHOD_BTN_NO_AMOUNT");

  if (paymentDescription && !paymentDescription.getEndTrialDate() && paymentDescription.getHasInvoices()) {
    const originalPrice = paymentDescription.getFirstInvoiceTotalWithoutDiscountsPrice();
    const discountedPrice = paymentDescription.getFirstInvoiceTotalPrice();

    buttonCopy = counterpart("PAYMENT_METHOD_BTN", { amount: originalPrice });
  }


  const noticeCouponName = removedCoupons
    ? removedCoupons.map((rc) => rc.name).join(", ")
    : error === CouponError.NOT_COMPATIBLE
      ? appliedCoupons.map((d) => d.coupon.getName()).join(", ")
      : couponName;

  const onDeleteCoupon = (coupon: IAppliedCoupon) => {
    dispatch(setAppliedCoupons(appliedCoupons.filter((ac) => ac.coupon.getCouponId() !== coupon.coupon.getCouponId())));
    setIsSuccessful(false);
    setError(null);
  };

  const fetchValidation = async () => {
    if (!country) {
      return;
    }

    setIsLoadingData(true);

    try {
      await dispatch(fetchTaxValidation(companyDomain, country.value.iso3Code));
    } catch (err) {
      console.log(err);
    }
    finally {
      setIsLoadingData(false);
    }
  };

  useLayoutEffect(() => {
    fetchValidation();
    if (paymentGatewayType === PaymentGatewayTypeId.SpreedlyTest) {
      dispatch(setCardHolderName(SPREEDLY_TEST_CARD_NAME));
      dispatch(setCardExpiryMonth(SPREEDLY_TEST_EXP_MONTH));
      dispatch(setCardExpiryYear(SPREEDLY_TEST_EXP_YEAR.toString()));
    }
    if (customerUniqueId) {
      // if the customer unique id is preloaded it means we're creating a subscription for an existing customer
      //dispatch(setPaymentCurrentScreen(PaymentScreen.PAYMENT_STEP3));
    }

    return () => {
      dispatch(setTaxRegNumber(""));
      dispatch(setTaxRegError(null));
    }
  }, []);

  const taxName = useMemo(() => getTaxName(country && country.value.iso3Code), [country])

  const fetchIpAddress = async () => {
    setIsLoadingData(true);
    try {
      const ipv4 = await getPublicIpV4();
      setIpAddress(ipv4);
      setIsLoadingData(false);
    } catch (err) {
      console.log(err);
      setIsLoadingData(false);
    }
  };

  const verifyAllCouponsIfEligible = async () => {
    if (appliedCoupons.length < 1) {
      return
    }

    let removedCoupons: Array<{ name: string; id: number }> = [];
    setIsVerifyingCouponCodes(true);
    try {
      const results = (await Promise.all(
        appliedCoupons.map((ac) => {
          return dispatch(validateCouponDetails(companyDomain, ac.couponCode as string, email as string));
        })
      )) as Array<{ response: GetCouponDetailsFromCouponCodeResponse }>;

      results.forEach(({ response }) => {
        const coupon = response.getCoupon() as Coupon;

        if (response.getIsExistingCustomer() && coupon.getEligibleCustomers() === EligibleCustomers.NEW_CUSTOMERS) {
          removedCoupons.push({ name: coupon.getName(), id: coupon.getCouponId() });
          setError(CouponError.NOT_FOR_EXISTING_CUSTOMERS);
        }

        if (!response.getIsExistingCustomer() && coupon.getEligibleCustomers() === EligibleCustomers.EXISTING_CUSTOMERS) {
          removedCoupons.push({ name: coupon.getName(), id: coupon.getCouponId() });
          setError(CouponError.NOT_FOR_NEW_CUSTOMERS);
        }
      });

      setRemovedCoupons(removedCoupons);
      removedCoupons.length > 0 && dispatch(setAppliedCoupons(appliedCoupons.filter((ac) => removedCoupons.every((rc) => rc.id !== ac.coupon.getCouponId()))));
      setIsVerifyingCouponCodes(false);
    } catch (err) {
      console.log(err);
      setIsVerifyingCouponCodes(false);
    }
  };

  useEffect(() => {
    fetchIpAddress();
    verifyAllCouponsIfEligible();
    return () => dispatch(setCardsErrors(null));
  }, []);

  useEffect(() => {
    if (cycle) {
      dispatch(getPriceDescription(
        cycle.cycleId,
        selectedAddOns,
        selectedAllowances,
        numberOfUnits === 0 ? 1 : numberOfUnits,
        appliedCoupons.map(ac => ac.couponCode || ""),
        undefined,
        undefined,
        undefined,
        address.country ? address.country.value.iso3Code : undefined
      ));
      dispatch(getPricingInfoDisclosure(
        cycle.cycleId,
        selectedAddOns,
        selectedAllowances,
        numberOfUnits === 0 ? 1 : numberOfUnits,
        appliedCoupons.map(ac => ac.couponCode || ""),
        undefined,
        undefined,
        undefined,
        address.country ? address.country.value.iso3Code : undefined
      ));
    }
  }, [cycle, appliedCoupons])

  const onSubmit = async (cardData: ISpreedlyCardData) => {
    const { token, card_type, last_four_digits, month, year } = cardData;

    const getAddress = (address: any) => {
      return {
        addressLine1: address.address1 || "",
        addressLine2: address.address2 || "",
        state: address.state,
        city: address.city,
        country: address && address.country && address.country.value ? address.country.value.iso3Code : "",
        postCode: address.zipCode,
      };
    };

    const getCustomFields = (customFields: Array<ICustomFieldWithValue>) => {
      if (!customFields || !customFields.length) {
        return undefined;
      }
      let result = customFields.map((customField: ICustomFieldWithValue) => {
        let value = customField.value;
        if (customField.type === CustomFieldType.CheckboxField && !customField.value) {
          value = "false";
        }
        return { customFieldId: customField.customFieldId, value };
      });
      return result;
    };

    if (!firstName || !lastName || !email || !cycle || !product) {
      return;
    }

    const phoneNumberData = product.value.isPhoneNumberRequired
      ? {
        phoneNumberDialCountry: fields.phoneNumberCountry,
        phoneNumberDialCode: fields.phoneNumberPrefix,
        phoneNumber: fields.phoneNumber.replace(/-/g, ""),
      }
      : {};

    const addUnitInFlatFee = (items: Array<IAddOn>) => {
      const newAddOns = items.map((addOn) => {
        if (addOn.pricingModelType === AddOnPricingModelType.AddonFlatFee) {
          addOn.units = 1;
        }
        return addOn;
      });

      return newAddOns;
    };

    const cardDetails = hasCustomerPaymentOnFile ? undefined : {
      fullName: cardHolderName,
      paymentCardToken: token,
      cardType: card_type,
      last4Digits: last_four_digits,
      expiryMonth: month,
      expiryYear: year
    }



    const subscription: ISubscription = {
      firstName,
      lastName,
      email,
      companyName, 
      cycleId: cycle.cycleId,
      units: numberOfUnits && numberOfUnits > 0 ? numberOfUnits : 1,
      //paymentCardToken: token,
      address: getAddress(address),
      shippingAddress: product.value.isShippingAddressRequired ? getAddress(shippingAddress) : undefined,
      additionalEmail: product.value.isAdditionalEmailRequired && fields.additionalEmails !== "" ? fields.additionalEmails : null,
      ...phoneNumberData,
      marketingConsent: product.value.isMarketingConsentRequired ? fields.marketingConsent : undefined,
      customFieldResponse: getCustomFields(fields.customFields),
      cardDetails,
      addOns: addUnitInFlatFee(addOns.filter(i => !!i.units && i.units > 0).map(i => i as IAddOn)).map((addOn) => ({ addOnId: addOn.id, quantity: addOn.units } as IAddOnSubscription)),
      couponCodes: appliedCoupons.map((ac) => ({ planId: plan ? plan.value.planId : 0, couponCode: ac.couponCode || "" })),
      allowances: allowances.filter(i => !!i.units && i.units > 0).map((el) => el.id),
      ipAddress,
      taxRegNumber,
      customerLanguage: customerLanguage || embedCodeLanguage || checkoutConfig?.checkoutDefaultLanguage || supportedLanguages.keys().next().value
    };

    let codeDetails: Array<AddCouponDiscountRequest.CodeDetails> = [];

    appliedCoupons.forEach((ac) => {
      let newCodeDetails = new AddCouponDiscountRequest.CodeDetails();
      let newInt32 = new Int32Value();
      newInt32.setValue(plan ? plan.value.planId : 0);
      newCodeDetails.setPlanId(newInt32);
      newCodeDetails.setCouponCode(ac.couponCode || "");
      codeDetails.push(newCodeDetails);
    });

    setSubscriptionData(subscription);

    if(currentScreen === PaymentScreen.ACH) {
      return setIsAchSubmitted(true);
    }

    // NEW codepath
    const subscriptionSCA: ISubscriptionSCA = { ...subscription, scaTransaction: { browserInfo: getBrowserInfo() } }

    const callAPI = async (transactionToken?: string): Promise<ISubscriptionResponseSCA | undefined> => {
      let response = {} as ISubscriptionResponseSCA;
      dispatch({ type: CREATE_SUBSCRIPTION_REQUEST });
      
      try {
        if (customerUniqueId) {
          response = await API.createSubscriptionExistingCustomerSCA(companyDomain, customerUniqueId, { ...subscriptionSCA, browserInfo: subscriptionSCA.scaTransaction.browserInfo, transactionToken } as ISubscriptionExistingCustomerSCA) as ISubscriptionResponseSCA;
        }
        else {
          response = await API.createSubscriptionSCA(companyDomain, { ...subscriptionSCA, scaTransaction: { ...subscriptionSCA.scaTransaction, transactionToken } }) as  ISubscriptionResponseSCA;
        }
      }
      catch (err) {
        dispatch({ type: CREATE_SUBSCRIPTION_FAILURE });
        return;
      }
      if (response.paymentStatus === PaymentStatus.Declined || response.paymentStatus === PaymentStatus.Failed) {
        dispatch({ type: CREATE_SUBSCRIPTION_FAILURE });
        return;
      }
      if (response.paymentStatus === PaymentStatus.Success || response.paymentStatus === PaymentStatus.PendingAch) {
        dispatch({ type: CREATE_SUBSCRIPTION_SUCCESS, response });
        gaEventTracker(CustomAnalyticsEvents.CREATED_SUBSCRIPTION);
        gaEventTracker(CustomAnalyticsEvents.PURCHASE, getPurchaseDetails(response.subscriptionUniqueId, paymentDescription?.toObject(), plan?.value, cycle));
        return;
      }
      if (response.paymentStatus === PaymentStatus.Pending) {
        return response;
      }
    }

    const response = await callAPI();

    if(!response) {
      // if undefined it means the call has already failed
      return;
    }

    //PENDING status -> required 3ds
    const statusUpdates = async (event: { action: string }) => {
      console.log(event.action);

      // save in session storage relevant data required in case 3ds1 is supported
      sessionStorage.setItem("billsbySubscriptionSCA", JSON.stringify({
        //subscriptionSCAResponse: response,
        //companyDomain,
        subscriptionSCARequestPayload: { ...subscriptionSCA, scaTransaction: { ...subscriptionSCA.scaTransaction, transactionToken: response?.transactionToken } },
        customerUniqueId,
        selectPlanState,
        personalDetailsState: personalDetailsReducer,
        globalReducerState: globalReducer,
        addOnState: addOnReducer,
        discountCouponState, 
        paymentDescription: paymentState.paymentDescription?.toObject()
        //isExistingCustomer: !!customerUniqueId
      }));
      

      if (event.action === "succeeded") {
        callAPI(response?.transactionToken);
      }
      else if (event.action === "error") {
        return dispatch({ type: CREATE_SUBSCRIPTION_FAILURE });
      }
      else if (event.action === "trigger-completion") {
        callAPI(response?.transactionToken);
      }
    }

    setup3dSecure(response?.transactionToken, statusUpdates);
  };

  const handleValidatePaymentDetails = () => {
    // remove any info panel about coupons when submitting
    setError(null);
    setIsSuccessful(false);
    dispatch(setCardsErrors(null));
    if (hasCustomerPaymentOnFile || currentScreen === PaymentScreen.ACH) {
      onSubmit({} as any);
    }
    else {
      validatePaymentDetailsForm({ cardholderName: cardHolderName, expiryMonth: cardExpiryMonth, expiryYear: cardExpiryYear },
        {
          addressLine1: address.address1,
          addressLine2: address.address2,
          state: address.state,
          city: address.city,
          country: address.country ? address.country.value.iso3Code : "",
          postCode: address.zipCode
        },
        (cardData, errors) => {
          if (errors) {
            dispatch(setCardsErrors(errors));
            return;
          }

          if (cardData) {
            dispatch(setUpdatePaymentState("cardType", cardData.card_type));
            dispatch(setUpdatePaymentState("lastFourDigit", cardData.last_four_digits));
            dispatch(setCardHolderName(cardData.full_name));
            dispatch(setCardExpiryMonth(cardData.month));
            dispatch(setCardExpiryYear(cardData.year));
            dispatch(setCardType(cardData.card_type));
            dispatch(setCardLastFourDigit(cardData.last_four_digits));
            dispatch(setPaymentToken(cardData.token));
            onSubmit(cardData);
          }
        });
    }
  };

  const handleValidatePaymentDetailsWithReCaptcha = async (action: RecaptchaActions) => {
    await generateRecaptchaActionToken(action);
    handleValidatePaymentDetails();
  }

  const renderHowThisWasCalculatedModal = () => {
    return !!plan && (
      <Modal isOpen={isOpenSummaryModal} onRequestClose={() => setIsOpenSummaryModal(false)}>
        {/*<PaymentDescription
          paymentDescription={paymentDescription}
          isLoading={paymentDescriptionIsLoading}
          planType={plan.value.pricingModelType}
          taxRegNumber={taxRegNumber}
          checkoutConfig={checkoutConfig}
        />*/}
        <PricingInfoDisclosure
          rawHTMLEncoded={pricingInfoDisclosure}
        />
      </Modal>
    )
  }

  const renderAddCouponModal = () => {
    const onError = (err: CouponError) => {
      setIsApplyingCouponCode(false);
      setIsSuccessful(false);
      setError(err);
      onCloseCouponModal();
    };

    const onAddCoupon = async () => {
      if (!couponCode) {
        return;
      }
      dispatch(setCardsErrors(null));
      setIsApplyingCouponCode(true);
      setIsSuccessful(false);
      setError(null);
      setRemovedCoupons([]);
      
      try {
        await dispatch(fetchCouponDetails(companyDomain, couponCode, email as string));

        setIsSuccessful(true);
        setIsApplyingCouponCode(false);
        setError(null);
        onCloseCouponModal();
      } catch (err) {
        onError(err);
      }
    };

    const onCloseCouponModal = () => {
      setIsOpenCouponModal(false);
      dispatch(setCouponCode(""));
    }

    return (
      <Modal hasOverlay width="340px" shouldTranslateTitle={false} height="172px" isOpen={isOpenCouponModal} title={counterpart("APPLY_COUPON_LABEL", { couponLabel: checkoutConfig?.terminologyCouponSingular })} onRequestClose={() => setIsOpenCouponModal(false)}>
        <>
          <FormGroup>
            <Input
              id="coupon-code"
              value={couponCode}
              placeholder={counterpart("APPLY_COUPON_PLACEHOLDER", { couponLabel: checkoutConfig?.terminologyCouponSingular })}
              onChange={(evt: any) => {
                dispatch(setCouponCode(evt.target.value.replace(/\s/g, "")));
              }}
            />
          </FormGroup>

          <div className="select-plan__coupon-modal__footer">
            <Button
              disabled={isApplyingCouponCode || !couponCode}
              isLoading={isApplyingCouponCode}
              className="modal__body__closebtn"
              title="APPLY_COUPON_BTN"
              translateWith={{ couponLabel: checkoutConfig?.terminologyCouponSingular }}
              id="apply-coupon"
              onClick={onAddCoupon}
            />
          </div>
        </>
      </Modal>
    )
  }

  const renderAddTaxNumberModal = () => {
    return (
      <Modal width="340px"
        hasOverlay
        height="172px"
        isOpen={isOpenTaxNumberModal}
        title={taxName.short}
        shouldTranslateTitle={false}
        onRequestClose={() => setIsOpenTaxNumberModal(false)}>
        <TaxInput isInlineDisplay={false} onSubmit={() => setIsOpenTaxNumberModal(false)} />
      </Modal>
    )
  }

  const renderBody = () => {
    if(hasCustomerPaymentOnFile) {
      return (
        <>
          <Panel id="payment-method-card-on-file">
            <Text content="PAYMENT_SUMMARY_EXISTING_CARD_ON_FILE" translateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }} noMargin />
          </Panel>
          {taxRegNumberRequirementType === TaxCollection.COMPULSORY && (
            <Panel id="payment-method-tax-compulsory">
              <TaxInput margin="none" />
            </Panel>
          )}
        </>
      )
    }

    switch(currentScreen) {
      case PaymentScreen.ACH:
        return (
          <AchForm 
            isSubmitted={isAchSubmitted} 
            type="create-subscription" 
            subscriptionData={subscriptionData}
            isTaxRequired={taxRegNumberRequirementType === TaxCollection.COMPULSORY}
          />
        )
      case PaymentScreen.CREDIT_CARD:
        return (
          <PaymentDetailsForm
            className="payment-method__form"
            cardNumberId="payment-method-card-number"
            cvvId="payment-method-card-cvv"
            onChangeCardholderName={name => dispatch(setCardHolderName(name))}
            onChangeExpiryMonth={month => dispatch(setCardExpiryMonth(month))}
            onChangeExpiryYear={year => dispatch(setCardExpiryYear(year))}
            initialCardNumber={paymentGatewayType === PaymentGatewayTypeId.SpreedlyTest ? SPREEDLY_TEST_CREDIT_CARD : undefined}
            initialCvv={paymentGatewayType === PaymentGatewayTypeId.SpreedlyTest ? SPREEDLY_TEST_CVV : undefined}
            initialExpiryMonth={paymentGatewayType === PaymentGatewayTypeId.SpreedlyTest ? SPREEDLY_TEST_EXP_MONTH : undefined}
            initialExpiryYear={paymentGatewayType === PaymentGatewayTypeId.SpreedlyTest ? SPREEDLY_TEST_EXP_YEAR : undefined}
            initialCardholderName={paymentGatewayType === PaymentGatewayTypeId.SpreedlyTest ? SPREEDLY_TEST_CARD_NAME : undefined}
            formData={{
              cardholderName: cardHolderName,
              expiryMonth: cardExpiryMonth,
              expiryYear: cardExpiryYear
            }}
            formErrors={{
              expiryMonth: errors?.obj.expiryMonth,
              expiryYear: errors?.obj.expiryYear,
              cardNumber: errors?.obj.cardNumber
            }}
            isTaxRequired={taxRegNumberRequirementType === TaxCollection.COMPULSORY}
          />
        )
    }
  }


  if (!product || !plan || !checkoutConfig) {
    return <div />;
  }

  const canSubmit = checkoutConfig.termsOfUseUrl || checkoutConfig.privacyPolicyUrl ? termsAndPolicies : true;
  const { isAdditionalEmailRequired, isPhoneNumberRequired, isMarketingConsentRequired } = product.value;
  const hasCustomFieldsOrStandardFields = !!fields.customFields.length || isAdditionalEmailRequired || isPhoneNumberRequired || isMarketingConsentRequired;
  const isTaxRegValid = taxRegNumberRequirementType === TaxCollection.COMPULSORY ? !!taxRegNumber : true;
  const isCardDataAdded = hasCustomerPaymentOnFile ? true : !!cardHolderName && !!cardExpiryMonth && !!cardExpiryYear;
  const isAchDataAdded = !!accountHolder;
  const isFreeTrial = !isNotFreeTrial;
  const isProrationNextCycleAndNoSetupFee = paymentDescription?.getProRateOption() === ProRateOptions.DoNotChargeUntilNextCycle && !paymentDescription?.getSetupFee();

  return (
    <PageWrapper
      className="payment-method__step1"
      title={hasCustomerPaymentOnFile ? "PAYMENT_DETAILS_TITLE_WITH_CARD_ON_FILE" : "PAYMENT_DETAILS_TITLE"}
      isLoading={paymentDescriptionIsLoading || isSubscribing}
      btnText={(isFreeTrial || isProrationNextCycleAndNoSetupFee) ? "PAYMENT_METHOD_BTN_NO_AMOUNT" : "PAYMENT_METHOD_BTN"}
      btnDisabled={isSubscribing || !canSubmit || isVerifyingCouponCodes || !isTaxRegValid || (!isCardDataAdded && !isAchDataAdded) || paymentGatewayType === PaymentGatewayTypeId.SpreedlyTest}
      btnTextTranslateWith={{
        amount: !!paymentDescription && (hasDiscounts
          ? (
            <>
              <Text
                component="span"
                className="payment-method__price payment-method__price--discounted"
                shouldTranslate={false}
                content={`${paymentDescription.getFirstInvoiceTotalWithoutDiscountsPrice()} ${plusTaxesLabel}`}
              />
              <Text
                component="span"
                shouldTranslate={false}
                className="payment-method__price"
                content={`${paymentDescription.getFirstInvoiceTotalPrice()} ${plusTaxesLabel}`}
              />
            </>
          )
          : <Text
            component="span"
            className="payment-method__price"
            shouldTranslate={false}
            content={`${paymentDescription.getFirstInvoiceTotalPrice()} ${plusTaxesLabel}`}
          />
        )
      }}
      btnCallback={async () => await handleValidatePaymentDetailsWithReCaptcha(RecaptchaActions.CHECKOUT)}
      btnBackCallback={() => {
        if(plan.value.paymentSourceTypes.length > 1 && !hasCustomerPaymentOnFile) {
          dispatch(setPaymentCurrentScreen(PaymentScreen.SELECT_TYPE));
        }
        else if (hasCustomFieldsOrStandardFields) {
          dispatch(setNavigationStep(NavigationBarSteps.PERSONAL_DETAILS));
          dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.CUSTOM_FIELDS));
        }
        else if (isAddressScreenPreloaded && !isUserDataScreenPreloaded) {
          // go back to user data screen
          dispatch(setNavigationStep(NavigationBarSteps.PERSONAL_DETAILS));
          dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.MAIN_DATA));
          dispatch(setEmail(""));
        }
        else if (isAddressScreenPreloaded && isUserDataScreenPreloaded) {
          dispatch(setNavigationStep(NavigationBarSteps.SELECT_PLAN));

          if (allowancesFilteredByCycle.length && !allowancesFilteredByCycle.every(a => a.isForced) && !preloadedAllowances.length) {
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ALLOWANCE));
          }
          else if ((addOnsFilteredByCycle.length && !addOnsFilteredByCycle.every(a => a.isForced && !isComplexPricingModel(a.pricingModelType)) && !preloadedAddOns.length) || (hasPreloadedAddOnsWithMissingUnits)) {
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ADD_ON));
          }
          else if (!planId && plansDropdownAvailable.length > 1) {
            dispatch(setCycle(null));
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PLAN));
          }
          else /*if (!productId && products.length > 1)*/ {
            dispatch(setProduct(null));
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PRODUCT));
          }
        }
        else {
          // go back to address screen
          dispatch(setNavigationStep(NavigationBarSteps.PERSONAL_DETAILS));
          dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.PERSONAL_ADDRESS));
        }
      }}
      btnBackHidden={isUserDataScreenPreloaded && isAddressScreenPreloaded && !hasCustomFieldsOrStandardFields &&
        ((!!preloadedAddOns.length && !!preloadedAllowances.length) || (!addOnsFilteredByCycle.length && !allowancesFilteredByCycle.length)) &&
        ((!!productId && !!planId) || (productsDropdownAvailable.length === 1 && plansDropdownAvailable.length === 1) || (!!productId && plansDropdownAvailable.length === 1))}
      bottomStickyContent={
        <>
          {isMobileOnly && checkoutConfig.isHowWasThisCalculatedVisible && taxRegNumberRequirementType === TaxCollection.OPTIONAL && !taxRegNumber && (
            <Text
              onClick={() => setIsOpenSummaryModal(true)}
              className="payment-method__link payment-method__link__calculation--mobile"
              content="UPDATE_PAYMENT_DETAILS_HOW_THIS_IS_CALCULATED"
              noMargin
            />)}
          <div className="payment-method__links-container" style={{ display: !isMobileOnly && !checkoutConfig?.isHowWasThisCalculatedVisible && !checkoutConfig.isApplyCouponsVisible ? "none" : "flex" }}>
            {!(isMobileOnly && taxRegNumberRequirementType === TaxCollection.OPTIONAL && !taxRegNumber) && checkoutConfig.isHowWasThisCalculatedVisible && (
              <Text
                onClick={() => setIsOpenSummaryModal(true)}
                className="payment-method__link payment-method__link__calculation"
                content="UPDATE_PAYMENT_DETAILS_HOW_THIS_IS_CALCULATED"
                noMargin
              />)}
            {checkoutConfig.isApplyCouponsVisible && <Text
              onClick={() => setIsOpenCouponModal(true)}
              className="payment-method__link payment-method__link__apply-coupon"
              content="UPDATE_PAYMENT_DETAILS_APPLY_COUPON"
              translateWith={{ couponLabel: checkoutConfig.terminologyCouponSingular }}
              noMargin
            />}
            {taxRegNumberRequirementType === TaxCollection.OPTIONAL &&
              !taxRegNumber && (
                <Text
                  onClick={() => setIsOpenTaxNumberModal(true)}
                  className="payment-method__link payment-method__link-add-tax"
                  content="UPDATE_PAYMENT_DETAILS_ADD_TAX_NUMBER"
                  translateWith={{ taxName: taxName.short }}
                  noMargin
                />)}
          </div>
        </>
      }>

      <>
        <SystemInfo
          title={counterpart("PAYMENT_COMPULSORY_FIELDS")}
          text={errors?.arr.join(", ")}
          isShowIcon={false}
          shouldTranslateTitle={false}
          shouldTranslateText={false}
          type="warning"
          isVisible={!!errors && !isSuccessful}
        />

        <SystemInfo
          title={counterpart(!error ? "APPLY_COUPON_SUCCESS_TITLE1" : getCouponError(error, appliedCoupons, removedCoupons).title, {
            couponName: noticeCouponName,
            couponLabel: checkoutConfig?.terminologyCouponSingular,
            couponsLabel: checkoutConfig?.terminologyCouponPlural
          })}
          text={error ? getCouponError(error, appliedCoupons, removedCoupons).content : ""}
          type={error ? "warning" : "success"}
          isShowIcon={false}
          shouldTranslateTitle={false}
          isVisible={isSuccessful || !!error}
        />

        <SystemInfo
          type="warning"
          isShowIcon={false}
          title={counterpart("TAX_REG_NUMBER_VALIDATION_ERROR_TITLE3", { taxName: getTaxName().short })}
          text={counterpart("TAX_REG_NUMBER_VALIDATION_ERROR_CONTENT3")}
          shouldTranslateTitle={false}
          shouldTranslateText={false}
          isVisible={taxRegError === TaxRegNumberError.UNABLE_TO_VALIDATE}
        />
        <SystemInfo
          type="warning"
          isShowIcon={false}
          title={counterpart("TAX_REG_NUMBER_VALIDATION_ERROR_TITLE2", { taxName: getTaxName().short })}
          text={counterpart("TAX_REG_NUMBER_VALIDATION_ERROR_CONTENT2", { taxName: getTaxName().short })}
          shouldTranslateTitle={false}
          shouldTranslateText={false}
          isVisible={taxRegError === TaxRegNumberError.INVALID}
        />
      </>

      {
        paymentGatewayType === PaymentGatewayTypeId.SpreedlyTest
          ? <TestGatewayPlaceHolder isSubscribing={isSubscribing} onContinue={async () => await handleValidatePaymentDetailsWithReCaptcha(RecaptchaActions.CHECKOUT_PRELIVE)} />
          : <div />
      }

      <div className={paymentGatewayType === PaymentGatewayTypeId.SpreedlyTest ? "payment-method--opaque" : "payment-method"}>

        { renderBody() }

        {
          checkoutConfig.termsOfUseUrl || checkoutConfig.privacyPolicyUrl ? (
            <FormGroup className="payment-method__form-group__terms">
              <Checkbox
                className="payment-method__form-group__terms__checkbox"
                checked={termsAndPolicies}
                value=""
                onClick={() => dispatch(setTermsPolicy(!termsAndPolicies))}
                content={
                  <div style={{ cursor: "auto" }}>
                    {checkoutConfig.termsOfUseUrl && (
                      <>
                        <Text
                          content="PAYMENT_AGREE_TERMS1"
                          component="span"
                          noMargin
                        />
                        <Text
                          content="PAYMENT_AGREE_TERMS2"
                          className="payment-method__link"
                          onClick={() => window.open(`${checkoutConfig.termsOfUseUrl}?utm_source=billsby_checkout&utm_medium=referral&utm_content=${companyDomain}`, "_blank")}
                          component="span"
                          noMargin
                        />
                      </>
                    )}
                    {checkoutConfig.privacyPolicyUrl && (
                      <>
                        <Text
                          content="PAYMENT_AGREE_TERMS3"
                          component="span"
                          noMargin
                        />
                        <Text
                          content="PAYMENT_AGREE_TERMS4"
                          className="payment-method__link"
                          onClick={() => window.open(`${checkoutConfig.privacyPolicyUrl}?utm_source=billsby_checkout&utm_medium=referral&utm_content=${companyDomain}`, "_blank")}
                          component="span"
                          noMargin
                        />
                      </>
                    )}
                  </div>
                }
              />
            </FormGroup>
          ) : (
            <div />
          )
        }

        <div className="payment-method__extra-details">
          {!!taxRegNumber &&
            <div className="payment-method__tax-details">
              <Text
                className="payment-method__tax-details text"
                content="UPDATE_PAYMENT_DETAILS_TAX_NUMBER_ADDED"
                translateWith={{ taxName: taxName.short, taxNumber: taxRegNumber }}
                noMargin
              />
              <Text
                onClick={() => dispatch(setTaxRegNumber(""))}
                className="payment-method__tax-details remove-text"
                content="UPDATE_PAYMENT_DETAILS_REMOVE_TAX_NUMBER"
                noMargin
              />
            </div>}
          {hasDiscounts && cycle &&
            <div className="payment-method__discounts-list">
              <PaymentSummaryCoupon
                className="payment-method__coupon-summary"
                paymentDescription={paymentDescription}
                appliedCoupons={appliedCoupons}
                onDeleteCoupon={onDeleteCoupon}
                checkoutConfig={checkoutConfig}
                currencyCode={product.value.billingCurrency}
              />
            </div>
          }
        </div>



        {renderHowThisWasCalculatedModal()}
        {renderAddCouponModal()}
        {renderAddTaxNumberModal()}


      </div>
    </PageWrapper>
  );
};

export default PaymentMethodStep1
