import React, { useLayoutEffect, useRef, useState } from "react";
import Select from "react-select";
import "./Dropdown.scss";
//import { ActionMeta, ValueType } from 'react-select/lib/types';
import { isMobileOnly } from "react-device-detect";
import { findDOMNode } from "react-dom";
import { FormControl, InputLabel, makeStyles, MenuItem, Select as SelectMUI } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Input from "../input/Input";
import Text from "../text/Text";

export interface IDropdown {
  [key: string]: any,
  options: Array<{ label: string, value: any }>
  menuIsOpen?: boolean,
  menuPlacement?: "top" | "bottom",
  value?: { label: string, value: any } | null
  disabled?: boolean
  className?: string,
  placeholder?: string,
  readOnly?: boolean
}


const Dropdown: React.FC<IDropdown> = React.memo(({ dividerAfter, placeholder = "", readOnly = false, className = "", onChange, menuIsOpen, menuPlacement = "bottom", disabled = false, options = [], ...rest }) => {
  const ref = useRef(null);
  const [selectedOption, setSelectedOption] = useState({ label: "", value: undefined })
  const portal: HTMLElement | null = document.querySelector(".app-wrapper__react-select-portal");

  useLayoutEffect(() => {
    const reactDropdown = findDOMNode(ref.current);
    if (isMobileOnly && reactDropdown) {
      const input = (reactDropdown as Element).querySelector("input");
      // in mobile we don't allow the keyboard to pop-up while clicking on a dropdown
      input && (input.readOnly = true);
    }
  }, []);

  useLayoutEffect(() => {
    setSelectedOption(rest.value ? rest.value : { label: "", value: undefined })
  }, [rest.value])

  const useStyles = makeStyles(theme => ({
    "@global": {
      '.MuiAutocomplete-option[data-focus="true"]': {
        background: "white"
      }
    },
    formControl: {
      minWidth: 120,
      height: 40
    },
    select: {
      "&:focus": {
        borderColor: "transparent",
        outline: "none"
      },
      "&:before": {
        borderColor: "transparent"
      },
      "&:after": {
        borderColor: "transparent",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent !important",
        borderWidth: "1px !important",
        outline: "none"
      }
    },
    inputRoot: {
      fontFamily: "Open Sans",
      color: "#555555"
    }
  }));

  const classes = useStyles();

  const renderLayout = () => {
    return (
      <Select
        ref={ref}
        {...rest}
        menuIsOpen={menuIsOpen}
        menuPlacement={menuPlacement}
        menuPortalTarget={isMobileOnly ? null : portal}
        onChange={onChange}
        options={options}
        isSearchable={false}
        className={`dropdown ${className} ${disabled ? "dropdown--disabled" : ""}`}
        classNamePrefix="custom-select"
      />
    )
  }

  const renderMUILayout = () => {
    return (
      <FormControl variant="outlined" className={classes.formControl} size="small" fullWidth>
        <InputLabel id="mui-outlined-label">{rest.val}</InputLabel>
        <SelectMUI
          labelId="mui-outlined-label"
          id="mui-outlined"
          value={rest.value ? rest.value.value : ""}
          disabled={disabled}
          onChange={(evt) => {
            onChange({ label: "", value: evt.target.value });
          }}
          className={classes.select}
        >
          {options.map(({ label, value }, index) => (
            <MenuItem key={index} value={value}>{label}</MenuItem>
          ))}
        </SelectMUI>
      </FormControl>
    )
  }

  const renderAutoComplete = () => {
    return (
      <Autocomplete
        ref={ref}
        className={className}
        disabled={disabled}
        renderInput={(params) => <Input {...params} placeholder={placeholder} />}
        getOptionLabel={(option) => option.label}
        onInputChange={() => {}}
        getOptionSelected={(option) => option === rest.value}
        value={selectedOption}
        options={options}
        classes={{ inputRoot: classes.inputRoot }}
        onChange={(evt, newValue) => {
          onChange(newValue);
        }}
        disableClearable={true}
        //open
      />
    )
  }

  if(readOnly) {
    return <Text style={rest.style} className="dropdown--readonly" content={rest.value ? rest.value.label : ""} shouldTranslate={false} noMargin></Text>
  }

  return renderLayout();
})



export default Dropdown;
