import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { REMOVE_CUSTOMER_SUBSCRIPTION_REQUEST, REMOVE_CUSTOMER_SUBSCRIPTION_SUCCESS, REMOVE_CUSTOMER_SUBSCRIPTION_FAILURE, SET_RETENTION_FIELD, RESET_RETENTION_STATE, GET_EXIT_REASONS_SUCCESS, GET_HELP_INTERRUPT_SUCCESS, GET_REASONS_TO_STAY_SUCCESS, GET_TIME_REMAINING_SUCCESS, GET_PLAN_CHANGE_SUCCESS, GET_RETENTION_STEPS_SUCCESS, GET_DISCOUNT_OFFER_SUCCESS, GET_DISCOUNT_OFFER_FAILURE } from "../actions/retentionActions";
import { RetentionScreen } from "../models/Retention";
import { GetExitReasonsConfigResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/company/exitreason/exit_reason_config_pb";
import { GetHelpInterruptResponse, GetDiscountOfferResponse, GetReasonsToStayResponse, GetTimeRemainingResponse, GetPlanChangeResponse, GetRetentionStepsResponse, RetentionStepType } from "../utils/grpc/generated/Billsby.Protos/core/public/companyconfig/retentionstep/retention_step_pb";
import { CROSS_DOMAIN_EVENT, sendEvent } from "../utils/crossDomainEvents";

export interface IRetentionReducer {
  currentScreen?: RetentionScreen,
  isRemoveCustomerSubscriptionRequest: boolean,
  isRemoveCustomerSubscriptionSuccess: boolean,
  isRemoveCustomerSubscriptionFailure: boolean,
  selectedExitReason: { label: string, value: string },
  exitReasonComment: string,
  exitReasons?: GetExitReasonsConfigResponse,
  helpInterrupt?: GetHelpInterruptResponse,
  reasonsToStay?: GetReasonsToStayResponse
  timeRemaining?: GetTimeRemainingResponse
  planChange?: GetPlanChangeResponse,
  discountOffer?: GetDiscountOfferResponse,
  retentionStepsAvailability?: GetRetentionStepsResponse
}

export const initialState: IRetentionReducer = {
  isRemoveCustomerSubscriptionRequest: false,
  isRemoveCustomerSubscriptionSuccess: false,
  isRemoveCustomerSubscriptionFailure: false,
  selectedExitReason: { label: "", value: "" },
  exitReasonComment: "",
}

export default function retentionReducer(state = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case SET_RETENTION_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }
    case GET_EXIT_REASONS_SUCCESS:
      return { ...state, exitReasons: action.response }
    case GET_HELP_INTERRUPT_SUCCESS:
      return { ...state, helpInterrupt: action.response }
    case GET_REASONS_TO_STAY_SUCCESS:
      return { ...state, reasonsToStay: action.response }
    case GET_TIME_REMAINING_SUCCESS:
      return { ...state, timeRemaining: action.response }
    case GET_PLAN_CHANGE_SUCCESS:
      return { ...state, planChange: action.response }
    case GET_DISCOUNT_OFFER_SUCCESS:
      const discountOffer = action.response as GetDiscountOfferResponse;
      const retentionSteps = state.retentionStepsAvailability as GetRetentionStepsResponse;
      if (discountOffer && !discountOffer.getAvailable() && retentionSteps) {
        // if the discount offer is not enabled for a specific customer we disable the discount step entirely
        const discountStep = retentionSteps.getRetentionStepsList().find(step => step.getRetentionStepType() === RetentionStepType.DISCOUNT_OFFER);
        discountStep?.setIsEnabled(false);
      }
      return { ...state, discountOffer: action.response }

    case GET_DISCOUNT_OFFER_FAILURE: {
      const retentionSteps = state.retentionStepsAvailability as GetRetentionStepsResponse;
      const discountStep = retentionSteps.getRetentionStepsList().find(step => step.getRetentionStepType() === RetentionStepType.DISCOUNT_OFFER);
      discountStep?.setIsEnabled(false);
      return { ...state }
    }
    
    case GET_RETENTION_STEPS_SUCCESS:
      return { ...state, retentionStepsAvailability: action.response }

    case REMOVE_CUSTOMER_SUBSCRIPTION_REQUEST:
      return { ...state, isRemoveCustomerSubscriptionRequest: true, isRemoveCustomerSubscriptionSuccess: false, isRemoveCustomerSubscriptionFailure: false }
    case REMOVE_CUSTOMER_SUBSCRIPTION_SUCCESS:
      sendEvent(CROSS_DOMAIN_EVENT.CANCEL_SUBSCRIPTION_SUCCESS)
      return { ...state, isRemoveCustomerSubscriptionRequest: false, isRemoveCustomerSubscriptionSuccess: true, isRemoveCustomerSubscriptionFailure: false }
    case REMOVE_CUSTOMER_SUBSCRIPTION_FAILURE:
      return { ...state, isRemoveCustomerSubscriptionRequest: false, isRemoveCustomerSubscriptionSuccess: false, isRemoveCustomerSubscriptionFailure: true }
    case RESET_RETENTION_STATE:
      return { ...initialState }
    default:
      return state;
  }
}