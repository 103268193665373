import React, { useEffect, useMemo, useState } from "react";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import Text from "../ui/text/Text";
import Panel from "../ui/panel/Panel";
import FormLabel from "../ui/form-label/FormLabel";
import Dropdown from "../ui/dropdown/Dropdown";
import FormGroup from "../ui/form-group/FormGroup";
import counterpart from "counterpart";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import Textarea from "../ui/textarea/Textarea";
import { setRetentionField, getExitReasons } from "../../actions/retentionActions";
import { RetentionScreen } from "../../models/Retention";
import history from "../../utils/history";
import { getNextRetentionStepAvailable } from "../../utils/commonUtils";
import { AccountManagementPermissions } from "../../models/AccountManagement";
import { BillsbyDataAction } from "../../models/BillsbyData";
import { sendEvent, CROSS_DOMAIN_EVENT } from "../../utils/crossDomainEvents";
import { IRetentionReducer } from "../../reducers/retentionReducer";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";
import { IGlobalReducer } from "../../reducers/globalReducer";


const RetentionExitReasons: React.FC = () => {

  const { companyDomain, checkoutConfig, billsbyAction, accountManagementPermissions } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const { exitReasons, selectedExitReason, exitReasonComment, retentionStepsAvailability } = useSelector<AppState, IRetentionReducer>(state => state.retentionReducer);
  const { currentSubscriptionDetails, currentSubscription } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const dispatch = useDispatch<Function>()
  const [previousScreen, setPreviousScreen] = useState<RetentionScreen | null>(null);

  useEffect(() => {
    dispatch(getExitReasons(companyDomain));
  }, []);

  useEffect(() => {
    if (retentionStepsAvailability) {
      const previousScreen = getNextRetentionStepAvailable(retentionStepsAvailability, "backward", RetentionScreen.exitReasons);
      setPreviousScreen(previousScreen);
    }
  }, [retentionStepsAvailability]);

  const exitReasonsDropdown = useMemo(() => {
    if (exitReasons) {
      const arr = exitReasons.getResultsList().map(exitReason => ({ label: exitReason.getText(), value: exitReason.getExitReasonId() }));
      dispatch(setRetentionField("selectedExitReason", arr[0]));
      return arr;
    }
    return [];
  }, [exitReasons]);

  const goBackCallback = () => {
    if (previousScreen) {
      dispatch(setRetentionField("currentScreen", previousScreen))
    }
    else {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }

  if (!currentSubscriptionDetails || !exitReasons || !retentionStepsAvailability) {
    return null;
  }

  return (
    <PageWrapper
      btnText="RETENTION_KEEP_MY_PLAN"
      title="CANCEL_SUBSCRIPTION_TITLE"
      titleTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
      btnTextTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
      btnCallback={() => billsbyAction === BillsbyDataAction.CANCEL_PLAN ? sendEvent(CROSS_DOMAIN_EVENT.CLOSE) : history.goBack()}
      btnBackCallback={goBackCallback}
      btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION}
      bottomStickyContent={
        <a
          href="javascript:void(0)"
          className="retention__link"
          onClick={() => dispatch(setRetentionField("currentScreen", getNextRetentionStepAvailable(retentionStepsAvailability, "forward", RetentionScreen.exitReasons)))} >
          {counterpart("RETENTION_CONTINUE_TOCANCEL")}
        </a>
      }>
      <Panel id="retention__exit-reasons">
        <Text content="RETENTION_EXIT_REASONS_HEADING" weight="bold" translateWith={{ planName: currentSubscription?.planDisplayName || currentSubscription?.planName }} />
        <FormGroup>
          <FormLabel content="RETENTION_EXIT_REASONS_HEADING2" />
          <Dropdown
            value={selectedExitReason}
            placeholder={counterpart("RETENTION_EXIT_REASONS_HEADING2_PLACEHOLDER")}
            onChange={(selectedItem: any) => dispatch(setRetentionField("selectedExitReason", selectedItem))}
            options={exitReasonsDropdown}
          />
        </FormGroup>
        <FormLabel content="RETENTION_EXIT_REASONS_COMMENTS" />
        <Textarea
          id="comments"
          placeholder={counterpart("RETENTION_EXIT_REASONS_COMMENTS_PLACEHOLDER")}
          value={exitReasonComment}
          onChange={(evt: any) => dispatch(setRetentionField("exitReasonComment", evt.target.value))}
        />
      </Panel>
    </PageWrapper>
  )
}

export default RetentionExitReasons