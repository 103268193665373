/* eslint-disable */
// source: customer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.billing.public.customer.CreateAchMandateExistingCustomerRequest', null, global);
goog.exportSymbol('proto.billing.public.customer.CreateAchMandateNewCustomerRequest', null, global);
goog.exportSymbol('proto.billing.public.customer.CreateAchMandateResponse', null, global);
goog.exportSymbol('proto.billing.public.customer.GetCustomerLanguageRequest', null, global);
goog.exportSymbol('proto.billing.public.customer.GetCustomerLanguageResponse', null, global);
goog.exportSymbol('proto.billing.public.customer.GetCustomerPaymentDetailsRequest', null, global);
goog.exportSymbol('proto.billing.public.customer.GetCustomerPaymentDetailsResponse', null, global);
goog.exportSymbol('proto.billing.public.customer.SendPasscodeEmailRequest', null, global);
goog.exportSymbol('proto.billing.public.customer.SendPasscodeEmailResponse', null, global);
goog.exportSymbol('proto.billing.public.customer.StartCheckoutAchMicroDepositRequest', null, global);
goog.exportSymbol('proto.billing.public.customer.StartCheckoutAchMicroDepositResponse', null, global);
goog.exportSymbol('proto.billing.public.customer.UpdateAchPaymentMethodRequest', null, global);
goog.exportSymbol('proto.billing.public.customer.UpdateAchPaymentMethodResponse', null, global);
goog.exportSymbol('proto.billing.public.customer.ValidatePasscodeRequest', null, global);
goog.exportSymbol('proto.billing.public.customer.ValidatePasscodeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.SendPasscodeEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.SendPasscodeEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.SendPasscodeEmailRequest.displayName = 'proto.billing.public.customer.SendPasscodeEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.SendPasscodeEmailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.SendPasscodeEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.SendPasscodeEmailResponse.displayName = 'proto.billing.public.customer.SendPasscodeEmailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.ValidatePasscodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.ValidatePasscodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.ValidatePasscodeRequest.displayName = 'proto.billing.public.customer.ValidatePasscodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.ValidatePasscodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.ValidatePasscodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.ValidatePasscodeResponse.displayName = 'proto.billing.public.customer.ValidatePasscodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.GetCustomerPaymentDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.GetCustomerPaymentDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.GetCustomerPaymentDetailsRequest.displayName = 'proto.billing.public.customer.GetCustomerPaymentDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.GetCustomerPaymentDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.GetCustomerPaymentDetailsResponse.displayName = 'proto.billing.public.customer.GetCustomerPaymentDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.GetCustomerLanguageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.GetCustomerLanguageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.GetCustomerLanguageRequest.displayName = 'proto.billing.public.customer.GetCustomerLanguageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.GetCustomerLanguageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.GetCustomerLanguageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.GetCustomerLanguageResponse.displayName = 'proto.billing.public.customer.GetCustomerLanguageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.CreateAchMandateNewCustomerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.CreateAchMandateNewCustomerRequest.displayName = 'proto.billing.public.customer.CreateAchMandateNewCustomerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.CreateAchMandateExistingCustomerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.CreateAchMandateExistingCustomerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.displayName = 'proto.billing.public.customer.CreateAchMandateExistingCustomerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.CreateAchMandateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.CreateAchMandateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.CreateAchMandateResponse.displayName = 'proto.billing.public.customer.CreateAchMandateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.UpdateAchPaymentMethodRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.UpdateAchPaymentMethodRequest.displayName = 'proto.billing.public.customer.UpdateAchPaymentMethodRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.UpdateAchPaymentMethodResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.UpdateAchPaymentMethodResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.UpdateAchPaymentMethodResponse.displayName = 'proto.billing.public.customer.UpdateAchPaymentMethodResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.StartCheckoutAchMicroDepositRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.displayName = 'proto.billing.public.customer.StartCheckoutAchMicroDepositRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.customer.StartCheckoutAchMicroDepositResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.customer.StartCheckoutAchMicroDepositResponse.displayName = 'proto.billing.public.customer.StartCheckoutAchMicroDepositResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.SendPasscodeEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.SendPasscodeEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.SendPasscodeEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.SendPasscodeEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyDomain: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.SendPasscodeEmailRequest}
 */
proto.billing.public.customer.SendPasscodeEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.SendPasscodeEmailRequest;
  return proto.billing.public.customer.SendPasscodeEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.SendPasscodeEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.SendPasscodeEmailRequest}
 */
proto.billing.public.customer.SendPasscodeEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.SendPasscodeEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.SendPasscodeEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.SendPasscodeEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.SendPasscodeEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.billing.public.customer.SendPasscodeEmailRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.SendPasscodeEmailRequest} returns this
 */
proto.billing.public.customer.SendPasscodeEmailRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string company_domain = 2;
 * @return {string}
 */
proto.billing.public.customer.SendPasscodeEmailRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.SendPasscodeEmailRequest} returns this
 */
proto.billing.public.customer.SendPasscodeEmailRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.SendPasscodeEmailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.SendPasscodeEmailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.SendPasscodeEmailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.SendPasscodeEmailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.SendPasscodeEmailResponse}
 */
proto.billing.public.customer.SendPasscodeEmailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.SendPasscodeEmailResponse;
  return proto.billing.public.customer.SendPasscodeEmailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.SendPasscodeEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.SendPasscodeEmailResponse}
 */
proto.billing.public.customer.SendPasscodeEmailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.SendPasscodeEmailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.SendPasscodeEmailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.SendPasscodeEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.SendPasscodeEmailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.billing.public.customer.SendPasscodeEmailResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.customer.SendPasscodeEmailResponse} returns this
 */
proto.billing.public.customer.SendPasscodeEmailResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.ValidatePasscodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.ValidatePasscodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.ValidatePasscodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.ValidatePasscodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    passcode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.ValidatePasscodeRequest}
 */
proto.billing.public.customer.ValidatePasscodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.ValidatePasscodeRequest;
  return proto.billing.public.customer.ValidatePasscodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.ValidatePasscodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.ValidatePasscodeRequest}
 */
proto.billing.public.customer.ValidatePasscodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPasscode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.ValidatePasscodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.ValidatePasscodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.ValidatePasscodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.ValidatePasscodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPasscode();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.customer.ValidatePasscodeRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.ValidatePasscodeRequest} returns this
 */
proto.billing.public.customer.ValidatePasscodeRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 passcode = 2;
 * @return {number}
 */
proto.billing.public.customer.ValidatePasscodeRequest.prototype.getPasscode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.customer.ValidatePasscodeRequest} returns this
 */
proto.billing.public.customer.ValidatePasscodeRequest.prototype.setPasscode = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string email_address = 3;
 * @return {string}
 */
proto.billing.public.customer.ValidatePasscodeRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.ValidatePasscodeRequest} returns this
 */
proto.billing.public.customer.ValidatePasscodeRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.ValidatePasscodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.ValidatePasscodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.ValidatePasscodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.ValidatePasscodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.ValidatePasscodeResponse}
 */
proto.billing.public.customer.ValidatePasscodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.ValidatePasscodeResponse;
  return proto.billing.public.customer.ValidatePasscodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.ValidatePasscodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.ValidatePasscodeResponse}
 */
proto.billing.public.customer.ValidatePasscodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.ValidatePasscodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.ValidatePasscodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.ValidatePasscodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.ValidatePasscodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_unique_id = 1;
 * @return {string}
 */
proto.billing.public.customer.ValidatePasscodeResponse.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.ValidatePasscodeResponse} returns this
 */
proto.billing.public.customer.ValidatePasscodeResponse.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.GetCustomerPaymentDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.GetCustomerPaymentDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.GetCustomerPaymentDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsRequest}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.GetCustomerPaymentDetailsRequest;
  return proto.billing.public.customer.GetCustomerPaymentDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.GetCustomerPaymentDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsRequest}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.GetCustomerPaymentDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.GetCustomerPaymentDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.GetCustomerPaymentDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsRequest} returns this
 */
proto.billing.public.customer.GetCustomerPaymentDetailsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_unique_id = 2;
 * @return {string}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsRequest} returns this
 */
proto.billing.public.customer.GetCustomerPaymentDetailsRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.GetCustomerPaymentDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentMethodType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastFourDigits: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cardType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    expiryMonth: jspb.Message.getFieldWithDefault(msg, 6, 0),
    expiryYear: jspb.Message.getFieldWithDefault(msg, 7, 0),
    bankName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdOn: (f = msg.getCreatedOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.GetCustomerPaymentDetailsResponse;
  return proto.billing.public.customer.GetCustomerPaymentDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastFourDigits(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryMonth(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryYear(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.GetCustomerPaymentDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentMethodType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastFourDigits();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCardType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExpiryMonth();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getExpiryYear();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedOn();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_unique_id = 1;
 * @return {string}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payment_method_type = 2;
 * @return {string}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.getPaymentMethodType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.setPaymentMethodType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_four_digits = 4;
 * @return {string}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.getLastFourDigits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.setLastFourDigits = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string card_type = 5;
 * @return {string}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.getCardType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.setCardType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 expiry_month = 6;
 * @return {number}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.getExpiryMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.setExpiryMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 expiry_year = 7;
 * @return {number}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.getExpiryYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.setExpiryYear = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string bank_name = 8;
 * @return {string}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp created_on = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.getCreatedOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse} returns this
*/
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.setCreatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.customer.GetCustomerPaymentDetailsResponse} returns this
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.clearCreatedOn = function() {
  return this.setCreatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.customer.GetCustomerPaymentDetailsResponse.prototype.hasCreatedOn = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.GetCustomerLanguageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.GetCustomerLanguageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.GetCustomerLanguageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.GetCustomerLanguageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.GetCustomerLanguageRequest}
 */
proto.billing.public.customer.GetCustomerLanguageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.GetCustomerLanguageRequest;
  return proto.billing.public.customer.GetCustomerLanguageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.GetCustomerLanguageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.GetCustomerLanguageRequest}
 */
proto.billing.public.customer.GetCustomerLanguageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.GetCustomerLanguageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.GetCustomerLanguageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.GetCustomerLanguageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.GetCustomerLanguageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.customer.GetCustomerLanguageRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.GetCustomerLanguageRequest} returns this
 */
proto.billing.public.customer.GetCustomerLanguageRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_unique_id = 2;
 * @return {string}
 */
proto.billing.public.customer.GetCustomerLanguageRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.GetCustomerLanguageRequest} returns this
 */
proto.billing.public.customer.GetCustomerLanguageRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.GetCustomerLanguageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.GetCustomerLanguageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.GetCustomerLanguageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.GetCustomerLanguageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isoCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    englishName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.GetCustomerLanguageResponse}
 */
proto.billing.public.customer.GetCustomerLanguageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.GetCustomerLanguageResponse;
  return proto.billing.public.customer.GetCustomerLanguageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.GetCustomerLanguageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.GetCustomerLanguageResponse}
 */
proto.billing.public.customer.GetCustomerLanguageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsoCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnglishName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.GetCustomerLanguageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.GetCustomerLanguageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.GetCustomerLanguageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.GetCustomerLanguageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsoCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnglishName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string iso_code = 1;
 * @return {string}
 */
proto.billing.public.customer.GetCustomerLanguageResponse.prototype.getIsoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.GetCustomerLanguageResponse} returns this
 */
proto.billing.public.customer.GetCustomerLanguageResponse.prototype.setIsoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string english_name = 2;
 * @return {string}
 */
proto.billing.public.customer.GetCustomerLanguageResponse.prototype.getEnglishName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.GetCustomerLanguageResponse} returns this
 */
proto.billing.public.customer.GetCustomerLanguageResponse.prototype.setEnglishName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.CreateAchMandateNewCustomerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.CreateAchMandateNewCustomerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    customerCountry: jspb.Message.getFieldWithDefault(msg, 4, ""),
    addressLine1: jspb.Message.getFieldWithDefault(msg, 5, ""),
    addressCity: jspb.Message.getFieldWithDefault(msg, 6, ""),
    addressState: jspb.Message.getFieldWithDefault(msg, 7, ""),
    addressPostCode: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.CreateAchMandateNewCustomerRequest}
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.CreateAchMandateNewCustomerRequest;
  return proto.billing.public.customer.CreateAchMandateNewCustomerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.CreateAchMandateNewCustomerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.CreateAchMandateNewCustomerRequest}
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerCountry(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine1(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressCity(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressState(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressPostCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.CreateAchMandateNewCustomerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.CreateAchMandateNewCustomerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCustomerEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustomerCountry();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddressLine1();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddressCity();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddressState();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddressPostCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.customer.CreateAchMandateNewCustomerRequest} returns this
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string customer_email = 2;
 * @return {string}
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.getCustomerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.CreateAchMandateNewCustomerRequest} returns this
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.setCustomerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_name = 3;
 * @return {string}
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.CreateAchMandateNewCustomerRequest} returns this
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string customer_country = 4;
 * @return {string}
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.getCustomerCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.CreateAchMandateNewCustomerRequest} returns this
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.setCustomerCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address_line1 = 5;
 * @return {string}
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.getAddressLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.CreateAchMandateNewCustomerRequest} returns this
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.setAddressLine1 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string address_city = 6;
 * @return {string}
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.getAddressCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.CreateAchMandateNewCustomerRequest} returns this
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.setAddressCity = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address_state = 7;
 * @return {string}
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.getAddressState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.CreateAchMandateNewCustomerRequest} returns this
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.setAddressState = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string address_post_code = 8;
 * @return {string}
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.getAddressPostCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.CreateAchMandateNewCustomerRequest} returns this
 */
proto.billing.public.customer.CreateAchMandateNewCustomerRequest.prototype.setAddressPostCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.CreateAchMandateExistingCustomerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.CreateAchMandateExistingCustomerRequest}
 */
proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.CreateAchMandateExistingCustomerRequest;
  return proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.CreateAchMandateExistingCustomerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.CreateAchMandateExistingCustomerRequest}
 */
proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.CreateAchMandateExistingCustomerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.customer.CreateAchMandateExistingCustomerRequest} returns this
 */
proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string customer_unique_id = 2;
 * @return {string}
 */
proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.CreateAchMandateExistingCustomerRequest} returns this
 */
proto.billing.public.customer.CreateAchMandateExistingCustomerRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.CreateAchMandateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.CreateAchMandateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.CreateAchMandateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.CreateAchMandateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stripeAccount: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stripeCustomerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientSecret: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.CreateAchMandateResponse}
 */
proto.billing.public.customer.CreateAchMandateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.CreateAchMandateResponse;
  return proto.billing.public.customer.CreateAchMandateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.CreateAchMandateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.CreateAchMandateResponse}
 */
proto.billing.public.customer.CreateAchMandateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeAccount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.CreateAchMandateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.CreateAchMandateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.CreateAchMandateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.CreateAchMandateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStripeAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStripeCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string stripe_account = 1;
 * @return {string}
 */
proto.billing.public.customer.CreateAchMandateResponse.prototype.getStripeAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.CreateAchMandateResponse} returns this
 */
proto.billing.public.customer.CreateAchMandateResponse.prototype.setStripeAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string stripe_customer_id = 2;
 * @return {string}
 */
proto.billing.public.customer.CreateAchMandateResponse.prototype.getStripeCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.CreateAchMandateResponse} returns this
 */
proto.billing.public.customer.CreateAchMandateResponse.prototype.setStripeCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string client_secret = 3;
 * @return {string}
 */
proto.billing.public.customer.CreateAchMandateResponse.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.CreateAchMandateResponse} returns this
 */
proto.billing.public.customer.CreateAchMandateResponse.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.UpdateAchPaymentMethodRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.UpdateAchPaymentMethodRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    achCustomerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    achPaymentId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    achFullName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    achLast4Digits: jspb.Message.getFieldWithDefault(msg, 6, ""),
    achBankName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    hostedVerificationUrl: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.UpdateAchPaymentMethodRequest}
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.UpdateAchPaymentMethodRequest;
  return proto.billing.public.customer.UpdateAchPaymentMethodRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.UpdateAchPaymentMethodRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.UpdateAchPaymentMethodRequest}
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerUniqueId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchCustomerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchPaymentId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchFullName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchLast4Digits(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchBankName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostedVerificationUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.UpdateAchPaymentMethodRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.UpdateAchPaymentMethodRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCustomerUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAchCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAchPaymentId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAchFullName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAchLast4Digits();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAchBankName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHostedVerificationUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.customer.UpdateAchPaymentMethodRequest} returns this
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string customer_unique_id = 2;
 * @return {string}
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.getCustomerUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.UpdateAchPaymentMethodRequest} returns this
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.setCustomerUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ach_customer_id = 3;
 * @return {string}
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.getAchCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.UpdateAchPaymentMethodRequest} returns this
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.setAchCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ach_payment_id = 4;
 * @return {string}
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.getAchPaymentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.UpdateAchPaymentMethodRequest} returns this
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.setAchPaymentId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ach_full_name = 5;
 * @return {string}
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.getAchFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.UpdateAchPaymentMethodRequest} returns this
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.setAchFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ach_last_4_digits = 6;
 * @return {string}
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.getAchLast4Digits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.UpdateAchPaymentMethodRequest} returns this
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.setAchLast4Digits = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ach_bank_name = 7;
 * @return {string}
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.getAchBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.UpdateAchPaymentMethodRequest} returns this
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.setAchBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string hosted_verification_url = 8;
 * @return {string}
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.getHostedVerificationUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.UpdateAchPaymentMethodRequest} returns this
 */
proto.billing.public.customer.UpdateAchPaymentMethodRequest.prototype.setHostedVerificationUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.UpdateAchPaymentMethodResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.UpdateAchPaymentMethodResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.UpdateAchPaymentMethodResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.UpdateAchPaymentMethodResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.UpdateAchPaymentMethodResponse}
 */
proto.billing.public.customer.UpdateAchPaymentMethodResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.UpdateAchPaymentMethodResponse;
  return proto.billing.public.customer.UpdateAchPaymentMethodResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.UpdateAchPaymentMethodResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.UpdateAchPaymentMethodResponse}
 */
proto.billing.public.customer.UpdateAchPaymentMethodResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.UpdateAchPaymentMethodResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.UpdateAchPaymentMethodResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.UpdateAchPaymentMethodResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.UpdateAchPaymentMethodResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.billing.public.customer.UpdateAchPaymentMethodResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.customer.UpdateAchPaymentMethodResponse} returns this
 */
proto.billing.public.customer.UpdateAchPaymentMethodResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.StartCheckoutAchMicroDepositRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerFirstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    achLast4Digits: jspb.Message.getFieldWithDefault(msg, 4, ""),
    achBankName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    hostedVerificationUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    checkoutUrl: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.StartCheckoutAchMicroDepositRequest}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.StartCheckoutAchMicroDepositRequest;
  return proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.StartCheckoutAchMicroDepositRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.StartCheckoutAchMicroDepositRequest}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchLast4Digits(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchBankName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostedVerificationUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckoutUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.StartCheckoutAchMicroDepositRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCustomerEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAchLast4Digits();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAchBankName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHostedVerificationUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCheckoutUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 company_id = 1;
 * @return {number}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.getCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.customer.StartCheckoutAchMicroDepositRequest} returns this
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string customer_email = 2;
 * @return {string}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.getCustomerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.StartCheckoutAchMicroDepositRequest} returns this
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.setCustomerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_first_name = 3;
 * @return {string}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.getCustomerFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.StartCheckoutAchMicroDepositRequest} returns this
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.setCustomerFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ach_last_4_digits = 4;
 * @return {string}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.getAchLast4Digits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.StartCheckoutAchMicroDepositRequest} returns this
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.setAchLast4Digits = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ach_bank_name = 5;
 * @return {string}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.getAchBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.StartCheckoutAchMicroDepositRequest} returns this
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.setAchBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string hosted_verification_url = 6;
 * @return {string}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.getHostedVerificationUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.StartCheckoutAchMicroDepositRequest} returns this
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.setHostedVerificationUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string checkout_url = 7;
 * @return {string}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.getCheckoutUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.customer.StartCheckoutAchMicroDepositRequest} returns this
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositRequest.prototype.setCheckoutUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.customer.StartCheckoutAchMicroDepositResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.customer.StartCheckoutAchMicroDepositResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.customer.StartCheckoutAchMicroDepositResponse}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.customer.StartCheckoutAchMicroDepositResponse;
  return proto.billing.public.customer.StartCheckoutAchMicroDepositResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.customer.StartCheckoutAchMicroDepositResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.customer.StartCheckoutAchMicroDepositResponse}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.customer.StartCheckoutAchMicroDepositResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.customer.StartCheckoutAchMicroDepositResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.customer.StartCheckoutAchMicroDepositResponse} returns this
 */
proto.billing.public.customer.StartCheckoutAchMicroDepositResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.billing.public.customer);
