import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { SET_CHANGE_PERSONAL_DETAILS_FIELD, FETCH_PERSONAL_DETAILS_SUCCESS, CHANGE_PERSONAL_DETAILS_SUCCESS, CHANGE_PERSONAL_DETAILS_REQUEST, CHANGE_PERSONAL_DETAILS_FAILURE, RESET_CHANGE_PERSONAL_DETAILS_STATE, FETCH_PRODUCT_BY_ID_SUCCESS } from "../actions/changePersonalDetailsActions";
import { PersonalDetails } from "../models/PersonalDetails";
import { IProduct } from "../models/Product";
import { CROSS_DOMAIN_EVENT, sendEvent } from "../utils/crossDomainEvents";


export interface IChangePersonalDetailsReducer {
  firstName: string,
  lastName: string,
  email: string,
  companyName: string,
  phoneNumberDialCode: string,
  phoneNumberDialCountry: string,
  phoneNumber: string,
  product?: IProduct,
  billingAddress: any,
  isChangePersonalDetailsRequest: boolean,
  isChangePersonalDetailsSuccess: boolean,
  isChangePersonalDetailsFailure: boolean
}

export const initialState: IChangePersonalDetailsReducer = {
  firstName: "",
  lastName: "",
  email: "",
  companyName: "",
  phoneNumberDialCode: "",
  phoneNumberDialCountry: "",
  phoneNumber: "",
  billingAddress: {},
  isChangePersonalDetailsRequest: false,
  isChangePersonalDetailsSuccess: false,
  isChangePersonalDetailsFailure: false
}

export default function changePersonalDetailsReducer(state = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case SET_CHANGE_PERSONAL_DETAILS_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }
    case FETCH_PERSONAL_DETAILS_SUCCESS: {
      const personalDetails = action.response as PersonalDetails;
      let { firstName, lastName, email, companyName = "", phoneNumber, phoneNumberDialCode, phoneNumberDialCountry, billingAddress } = personalDetails;
      phoneNumber = phoneNumber || "";
      phoneNumberDialCode = phoneNumberDialCode || "";
      phoneNumberDialCountry = phoneNumberDialCountry || "";
      return { ...state, firstName, lastName, email, companyName, phoneNumber, phoneNumberDialCode, phoneNumberDialCountry, billingAddress };
    }
    case CHANGE_PERSONAL_DETAILS_REQUEST:
      return { ...state, isChangePersonalDetailsRequest: true, isChangePersonalDetailsSuccess: false, isChangePersonalDetailsFailure: false }
    case CHANGE_PERSONAL_DETAILS_SUCCESS:
      sendEvent(CROSS_DOMAIN_EVENT.CHANGE_CONTACT_DETAILS_SUCCESS)
      return { ...state, isChangePersonalDetailsRequest: false, isChangePersonalDetailsSuccess: true, isChangePersonalDetailsFailure: false }
    case CHANGE_PERSONAL_DETAILS_FAILURE:
      return { ...state, isChangePersonalDetailsRequest: false, isChangePersonalDetailsSuccess: false, isChangePersonalDetailsFailure: true }
    
    case FETCH_PRODUCT_BY_ID_SUCCESS:
      return { ...state, product: action.response }
    case RESET_CHANGE_PERSONAL_DETAILS_STATE:
      return { ...initialState };

    default:
      return state;
  }
}