import { makeActionCreator } from "../utils/reduxUtils";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { ConfigConstants } from "../utils/config";
import { GetCouponDetailsFromCouponCodeRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/coupons/coupons_pb";
import { CouponsServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/coupons/CouponsServiceClientPb";
import { AddCouponDiscountRequest, GetDiscountsRequest, RemoveDiscountRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/discount/discount_pb";
import { SubscriptionDiscountServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/discount/DiscountServiceClientPb";

export const FETCH_COUPON_DETAILS_REQUEST = "FETCH_COUPON_DETAILS_REQUEST";
export const FETCH_COUPON_DETAILS_SUCCESS = "FETCH_COUPON_DETAILS_SUCCESS";
export const FETCH_COUPON_DETAILS_FAILURE = "FETCH_COUPON_DETAILS_FAILURE";

export const FETCH_DISCOUNTS_REQUEST = "FETCH_DISCOUNTS_REQUEST";
export const FETCH_DISCOUNTS_SUCCESS = "FETCH_DISCOUNTS_SUCCESS";
export const FETCH_DISCOUNTS_FAILURE = "FETCH_DISCOUNTS_FAILURE";

export const ADD_COUPON_DISCOUNTS_REQUEST = "ADD_COUPON_DISCOUNTS_REQUEST";
export const ADD_COUPON_DISCOUNTS_SUCCESS = "ADD_COUPON_DISCOUNTS_SUCCESS";
export const ADD_COUPON_DISCOUNTS_FAILURE = "ADD_COUPON_DISCOUNTS_FAILURE";

export const DELETE_DISCOUNTS_REQUEST = "DELETE_DISCOUNTS_REQUEST";
export const DELETE_DISCOUNTS_SUCCESS = "DELETE_DISCOUNTS_SUCCESS";
export const DELETE_DISCOUNTS_FAILURE = "DELETE_DISCOUNTS_FAILURE";

export const VALIDATE_COUPON_DETAILS_REQUEST = "VALIDATE_COUPON_DETAILS_REQUEST";
export const VALIDATE_COUPON_DETAILS_SUCCESS = "VALIDATE_COUPON_DETAILS_SUCCESS";
export const VALIDATE_COUPON_DETAILS_FAILURE = "VALIDATE_COUPON_DETAILS_FAILURE";

export const SET_APPLIED_COUPONS = "SET_APPLIED_COUPONS";
export const setAppliedCoupons = makeActionCreator(SET_APPLIED_COUPONS, "payload");

export const SET_COUPON_CODE = "SET_COUPON_CODE";
export const setCouponCode = makeActionCreator(SET_COUPON_CODE, "payload");

export const RESET_DISCOUNT_COUPON = "RESET_DISCOUNT_COUPON";
export const resetDiscountCoupon = makeActionCreator(RESET_DISCOUNT_COUPON, "payload");

export const fetchCouponDetails = (companyDomain: string, couponCode: string, email?: string) => {
  const fetchCouponDetailsRequest = new GetCouponDetailsFromCouponCodeRequest();
  fetchCouponDetailsRequest.setCompanyDomain(companyDomain);
  fetchCouponDetailsRequest.setCouponCode(couponCode);
  email && fetchCouponDetailsRequest.setEmailAddress(email)

  const couponServiceClient = new CouponsServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_COUPON_DETAILS_REQUEST, FETCH_COUPON_DETAILS_SUCCESS, FETCH_COUPON_DETAILS_FAILURE],
    callAPI: () => grpcUnaryCall(fetchCouponDetailsRequest, couponServiceClient, couponServiceClient.getCouponDetailsFromCouponCode)
  }
}

export const updateCouponDiscount = (companyDomain: string, subscriptionUniqueId: string, codesList: Array<AddCouponDiscountRequest.CodeDetails>) => {
  const addCouponRequest = new AddCouponDiscountRequest();
  addCouponRequest.setCompanyDomain(companyDomain);
  addCouponRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  addCouponRequest.setCodesList(codesList)

  const subscriptionDiscountService = new SubscriptionDiscountServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [ADD_COUPON_DISCOUNTS_REQUEST, ADD_COUPON_DISCOUNTS_SUCCESS, ADD_COUPON_DISCOUNTS_FAILURE],
    callAPI: () => grpcUnaryCall(addCouponRequest, subscriptionDiscountService, subscriptionDiscountService.addCouponDiscount)
  }
}

export const fetchSubscriptionDiscounts = (companyDomain: string, subscriptionUniqueId: string) => {
  const getDiscountsRequest =  new GetDiscountsRequest();
  getDiscountsRequest.setCompanyDomain(companyDomain);
  getDiscountsRequest.setSubscriptionUniqueId(subscriptionUniqueId);

  const subscriptionDiscountService = new SubscriptionDiscountServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_DISCOUNTS_REQUEST, FETCH_DISCOUNTS_SUCCESS, FETCH_DISCOUNTS_FAILURE],
    callAPI: () => grpcUnaryCall(getDiscountsRequest, subscriptionDiscountService, subscriptionDiscountService.getDiscounts)
  }
}

export const deleteSubscriptionDiscounts = (companyDomain: string, subscriptionUniqueId: string, subscriptionDiscountId?: number) => {
  const removeDiscountRequest = new RemoveDiscountRequest();
  removeDiscountRequest.setCompanyDomain(companyDomain);
  removeDiscountRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  subscriptionDiscountId && removeDiscountRequest.setSubscriptionDiscountId(subscriptionDiscountId)

  const subscriptionDiscountService = new SubscriptionDiscountServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [DELETE_DISCOUNTS_REQUEST, DELETE_DISCOUNTS_SUCCESS, DELETE_DISCOUNTS_FAILURE],
    callAPI: () => grpcUnaryCall(removeDiscountRequest, subscriptionDiscountService, subscriptionDiscountService.removeDiscount)
  }
}

export const validateCouponDetails = (companyDomain: string, couponCode: string, email?: string) => {
  const validateCouponDetailsRequest = new GetCouponDetailsFromCouponCodeRequest();
  validateCouponDetailsRequest.setCompanyDomain(companyDomain);
  validateCouponDetailsRequest.setCouponCode(couponCode);
  email && validateCouponDetailsRequest.setEmailAddress(email)

  const couponServiceClient = new CouponsServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [VALIDATE_COUPON_DETAILS_REQUEST, VALIDATE_COUPON_DETAILS_SUCCESS, VALIDATE_COUPON_DETAILS_FAILURE],
    callAPI: () => grpcUnaryCall(validateCouponDetailsRequest, couponServiceClient, couponServiceClient.getCouponDetailsFromCouponCode)
  }
}
