import React, { useState } from "react";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import SeeMoreText from "../../ui/see-more-text/SeeMoreText";
import "./AddOnSummary.scss";
import Tooltip from "../../ui/tooltip/Tooltip";

interface IAddOnSummary {
  title: string,
  subTitle: JSX.Element | string,
  description: string,
  isLocked?: boolean,
  className?: string,
  textTooltipWhenLocked?: string,
  shouldTranslateTextTooltipWhenLocked?: boolean,
  isEditable: boolean,
  isDeletable: boolean,
  onEdit?: () => void,
  onDelete?: () => void
}

const AddOnSummary: React.FC<IAddOnSummary> = ({ title, subTitle, description, isLocked = false, className = "", 
  textTooltipWhenLocked = "MANAGE_ADDON_ON_HOVER_LOCKED_FORCED", shouldTranslateTextTooltipWhenLocked = true, isEditable, 
  onEdit = () => { }, onDelete = () => { }, isDeletable }) => {

  const [isHover, setIsHover] = useState(false);

  const getIcons = () => {
    if (isLocked) {
      return (
        <div>
          {isEditable && <i className="element-modifiers__edit fa fa-edit" onClick={onEdit} />}
          <i className="element-modifiers__lock fas fa-lock-alt" onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)} />
          <div style={{ position: "absolute" }} className={isHover ? "addon-summary__container-tooltip" : ""}>
            <Tooltip type="blue" isOpen={isHover} arrowClassName="addon-summary__arrow_bottom">
              <>
                {textTooltipWhenLocked && <Text content={textTooltipWhenLocked} shouldTranslate={shouldTranslateTextTooltipWhenLocked} noMargin />}
              </>
            </Tooltip>
          </div>
        </div>
      )
    }
    return (
      <span>
        {isEditable && <i className="element-modifiers__edit fa fa-edit" onClick={onEdit} />}
        {isDeletable && <i className="element-modifiers__delete far fa-trash-alt" onClick={onDelete} />}
      </span>
    )
  }

  return (
    <Panel id="addon-summary" className={`addon-summary ${className}`}>
      <div>
        <Text className="addon-summary__title" content={title} shouldTranslate={false} noMargin />
        {getIcons()}
      </div>

      <Text className="addon-summary__subTitle" content={subTitle} shouldTranslate={false} component="div" noMargin />
      <SeeMoreText className="addon-summary__description addon-summary__light" content={description} shouldTranslateContent={false} noMargin />
    </Panel>
  )
}

export default AddOnSummary;