import selectPlanReducer from "./selectPlanReducer";
import globalReducer from "./globalReducer";
import personalDetailsReducer from "./personalDetailsReducer";
import paymentReducer from "./paymentReducer";
import updatePaymentReducer from "./updatePaymentReducer";
import subscriptionReducer from "./subscriptionReducer";
import changePlanReducer from "./changePlanReducer";
import changePersonalDetailsReducer from "./changePersonalDetailsReducer";
import loginReducer from "./loginReducer";
import changeAddressReducer from "./changeAddressReducer";
import retentionReducer from "./retentionReducer";
import addOnReducer from "./addOnReducer";
import viewBillingHistoryReducer from "./viewBillingHistoryReducer";
import discountCouponReducer from "./discountCouponReducer"
import addDiscountOfferReducer from "./addDiscountOfferReducer";
import history from "../utils/history";
import achReducer from "./achReducer";


function loadInitialState() {
  try {
    // if we serialized some state locally we reload it to restart the app from the correct state
    const billsbyPendingData = JSON.parse(sessionStorage.billsbySubscriptionSCA || sessionStorage.billsbyUpdatePaymentSCA);
    if(billsbyPendingData && history.location.pathname.includes("/3ds1")) {
      return {
        globalReducer: billsbyPendingData?.globalReducerState,
        selectPlanReducer: billsbyPendingData?.selectPlanState,
        personalDetailsReducer: billsbyPendingData?.personalDetailsState,
        discountCouponReducer: billsbyPendingData?.discountCouponState,
        addOnReducer: billsbyPendingData?.addOnState,
        updatePaymentReducer: billsbyPendingData?.updatePaymentState
      }
    } 
    else {
      return {}
    }
  }
  catch(err) {
    return {}
  }
}

const reducers = function (store: any = loadInitialState(), action: any) {
  return {
    selectPlanReducer: selectPlanReducer(store.selectPlanReducer, action, store),
    globalReducer: globalReducer(store.globalReducer, action, store),
    personalDetailsReducer: personalDetailsReducer(store.personalDetailsReducer, action, store),
    paymentReducer: paymentReducer(store.paymentReducer, action, store),
    updatePaymentReducer: updatePaymentReducer(store.updatePaymentReducer, action, store),
    subscriptionReducer: subscriptionReducer(store.subscriptionReducer, action, store),
    changePlanReducer: changePlanReducer(store.changePlanReducer, action, store),
    changePersonalDetailsReducer: changePersonalDetailsReducer(store.changePersonalDetailsReducer, action, store),
    loginReducer: loginReducer(store.loginReducer, action, store),
    changeAddressReducer: changeAddressReducer(store.changeAddressReducer, action, store),
    retentionReducer: retentionReducer(store.retentionReducer, action, store),
    addOnReducer: addOnReducer(store.addOnReducer, action, store),
    viewBillingHistoryReducer: viewBillingHistoryReducer(store.viewBillingHistoryReducer, action, store),
    discountCouponReducer: discountCouponReducer(store.discountCouponReducer, action, store),
    addDiscountOfferReducer: addDiscountOfferReducer(store.addDiscountOfferReducer, action, store),
    achReducer: achReducer(store.achReducer, action, store)
  }
}

export default reducers