import { Discount, Coupon } from "../utils/grpc/generated/Billsby.Protos/billing/public/coupons/coupons_pb"
import { SubscriptionDiscount } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/discount/discount_pb";

export interface IAppliedCoupon { coupon: Coupon, discount: Discount, subscriptionDiscount?: SubscriptionDiscount, couponCode?: string }

export interface ICouponSubscription { planId: number, couponCode: string }

export enum CouponError {
  INVALID_CODE = "INVALID_CODE",
  NOT_COMPATIBLE = "NOT_COMPATIBLE",
  NOT_FOR_EXISTING_CUSTOMERS = "NOT_FOR_EXISTING_CUSTOMERS",
  NOT_FOR_NEW_CUSTOMERS = "NOT_FOR_NEW_CUSTOMERS"
}
