import { makeActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { reduxStore } from "..";

/**********************  SYNCHRONOUS ACTIONS ********************/
export const SET_SELECTPLAN_CURRENT_SCREEN = "SET_SELECTPLAN_CURRENT_SCREEN";
export const setSelectPlanCurrentScreen = makeActionCreator(SET_SELECTPLAN_CURRENT_SCREEN, "payload");
export const SET_PRODUCT = "SET_PRODUCT";
export const setProduct = makeActionCreator(SET_PRODUCT, "payload");
export const SET_PLAN = "SET_PLAN";
export const setPlan = makeActionCreator(SET_PLAN, "payload");
export const SET_CYCLE = "SET_CYCLE";
export const setCycle = makeActionCreator(SET_CYCLE, "payload");
export const SET_PRODUCT_ID = "SET_PRODUCT_ID";
export const setProductId = makeActionCreator(SET_PRODUCT_ID, "payload");
export const SET_PLAN_ID = "SET_PLAN_ID";
export const setPlanId = makeActionCreator(SET_PLAN_ID, "payload");
export const SET_CYCLE_ID = "SET_CYCLE_ID";
export const setCycleId = makeActionCreator(SET_CYCLE_ID, "payload");
export const SET_SELECTED_PRICING_MODEL = "SET_SELECTED_PRICING_MODEL";
export const setSelectedPricingModel = makeActionCreator(SET_SELECTED_PRICING_MODEL, "payload");
export const SET_NUMBER_OF_UNITS = "SET_NUMBER_OF_UNITS";
export const setNumberOfUnits = makeActionCreator(SET_NUMBER_OF_UNITS, "payload");
export const SET_VALID_UNIT_RANGE = "SET_VALID_UNIT_RANGE";
export const setValidUnitRange = makeActionCreator(SET_VALID_UNIT_RANGE, "payload");
export const SET_SHOW_PRICING_INFO = "SET_SHOW_PRICING_INFO";
export const setShowPricingInfo = makeActionCreator(SET_SHOW_PRICING_INFO, "payload");
export const SET_HAS_SELECTED_CYCLE = "SET_HAS_SELECTED_CYCLE";
export const setHasSelectedACycle = makeActionCreator(SET_HAS_SELECTED_CYCLE, "payload");
export const SET_ACTIVE_PLAN_ID = "SET_ACTIVE_PLAN_ID";
export const setActivePlanId = makeActionCreator(SET_ACTIVE_PLAN_ID, "payload");

export const RESET_SELECTPLAN_STATE = "RESET_SELECTPLAN_STATE";
export const resetSelectPlanState = makeActionCreator(RESET_SELECTPLAN_STATE);

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const FETCH_PLANS_REQUEST = "FETCH_PLANS_REQUEST";
export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const FETCH_PLANS_FAILURE = "FETCH_PLANS_FAILURE";

/**********************  ASYNCHRONOUS ACTIONS (api requests) ********************/

export const fetchProducts = function () {
  return {
    types: [FETCH_PRODUCTS_REQUEST, FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCTS_FAILURE],
    callAPI: () => API.getProducts(reduxStore.getState().globalReducer.companyDomain)
  }
}

export const fetchPlans = function(productId: number) {
  return {
    types: [FETCH_PLANS_REQUEST, FETCH_PLANS_SUCCESS, FETCH_PLANS_FAILURE],
    callAPI: () => API.getPlans(reduxStore.getState().globalReducer.companyDomain, productId)
  }
}