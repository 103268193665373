import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { RetentionScreen } from "../../models/Retention";
import RetentionHelpInterrupt from "../../components/retention/RetentionHelpInterrupt";
import RetentionReasonsToStay from "../../components/retention/RetentionReasonsToStay";
import RetentionExitReasons from "../../components/retention/RetentionExitReasons";
import RetentionTimeRemaining from "../../components/retention/RetentionTimeRemaining";
import RetentionPayBalance from "../../components/retention/RetentionPayBalance";
import RetentionChangePlan from "../../components/retention/RetentionChangePlan";
import RetentionCancel from "../../components/retention/RetentionCancel";
import LoadingAnimation from "../../components/ui/loading-animation/LoadingAnimation";
import { getDiscountOffer, getRetentionSteps, setRetentionField } from "../../actions/retentionActions";
import { getContractMinimumTermInfo, getNextRetentionStepAvailable } from "../../utils/commonUtils";
import { fetchCustomerSubscriptionDetails, fetchPendingMinimumTermAmount, setCurrentSubscription } from "../../actions/subscriptionsActions";
import RetentionDiscountOffer from "../../components/retention/RetentionDiscountOffer";
import EndContractTerm from "../../components/change-plan/EndContractTerm";
import { ICustomerSubscription } from "../../models/Customer";
import { AccountManagementPermissions } from "../../models/AccountManagement";
import history from "../../utils/history";
import { payContractTerm } from "../../actions/changePlanActions";
import { GetPendingMinimumTermAmountResponse } from "../../utils/grpc/generated/Billsby.Protos/billing/public/subscription/subscription_pb";

const Retention: React.FC = () => {
  const { companyDomain, accountManagementPermissions } = useSelector((state: AppState) => state.globalReducer);
  const retentionReducer = useSelector((state: AppState) => state.retentionReducer);
  const subscriptionReducer = useSelector((state: AppState) => state.subscriptionReducer);
  const personalDetailsReducer = useSelector((state: AppState) => state.personalDetailsReducer);

  const dispatch = useDispatch<Function>();

  const [isLoading, setIsLoading] = useState(true);
  const [currentPendingMinimumAmount, setCurrentPendingMinimumAmount] = useState(0);
  const { currentScreen, retentionStepsAvailability, discountOffer } = retentionReducer;
  const { currentSubscription } = subscriptionReducer;
  const { isPendingContractMinimumTermEnd } = getContractMinimumTermInfo(currentSubscription as ICustomerSubscription);

  const {
    mainProps: { subscriptionUniqueId, customerUniqueId },
  } = personalDetailsReducer;

  const fetchMinimumContract = async () => {
    try {
      if (!!currentSubscription?.contractMinimumTermEnd) {
        const { response: pendingMinimumAmount } = await dispatch(fetchPendingMinimumTermAmount(companyDomain, customerUniqueId as string, subscriptionUniqueId as string)) as { response: GetPendingMinimumTermAmountResponse };
        setCurrentPendingMinimumAmount(pendingMinimumAmount.getAmount())
        if (pendingMinimumAmount.getAmount() === 0) {
          await dispatch(payContractTerm(companyDomain, customerUniqueId as string, subscriptionUniqueId as string));
          dispatch(setCurrentSubscription({ ...currentSubscription, contractMinimumTermEnd: undefined, pendingMinimumTermAmount: undefined }))
        }
      }
    }
    catch { }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchCustomerSubscriptionDetails(companyDomain, subscriptionUniqueId as string));
        fetchMinimumContract();
        // if the discount offer for this customer is disabled (does not meet minimum term rule) we remove the discount step
        // from the cancellation workflow
        await dispatch(getRetentionSteps(companyDomain));
        await dispatch(getDiscountOffer(companyDomain, subscriptionUniqueId as string));
      }
      catch (err) { }
      finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);


  useEffect(() => {
    if (retentionStepsAvailability) {
      dispatch(setRetentionField("currentScreen", getNextRetentionStepAvailable(retentionStepsAvailability, "forward")));
    }
  }, [retentionStepsAvailability, discountOffer]);

  const getCurrentScreen = () => {
    if (isLoading) {
      return <LoadingAnimation />
    }

    if (isPendingContractMinimumTermEnd && currentPendingMinimumAmount !== 0) {
      return (
        <EndContractTerm
          btnBackCallback={() => history.push({ pathname: "/management", search: history.location.search })}
          btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION}
        />
      )
    }

    switch (currentScreen) {
      case RetentionScreen.helpInterrupt:
        return <RetentionHelpInterrupt />;
      case RetentionScreen.reasonsToStay:
        return <RetentionReasonsToStay />;
      case RetentionScreen.timeRemaining:
        return <RetentionTimeRemaining />;
      case RetentionScreen.changePlan:
        return <RetentionChangePlan />;
      case RetentionScreen.exitReasons:
        return <RetentionExitReasons />;
      case RetentionScreen.discountOffer:
        return <RetentionDiscountOffer />;
      case RetentionScreen.confirmCancel:
        return <RetentionCancel />;
      case RetentionScreen.payBalance:
        return <RetentionPayBalance />
      default:
        return <div />;
    }
  };

  return getCurrentScreen();
};

export default Retention;
