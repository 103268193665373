import { makeGenericActionCreator, makeActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { PersonalDetails } from "../models/PersonalDetails";
import { IChangePersonalDetailsReducer } from "../reducers/changePersonalDetailsReducer";

export const SET_CHANGE_PERSONAL_DETAILS_FIELD = "SET_CHANGE_PERSONAL_DETAILS_FIELD";
//type AllowedChangePersonalDetailsFields = "firstName" | "lastName" | "email" | "phoneNumberDialCode" | "phoneNumberDialCountry" | "phoneNumber";
export const setChangePersonalDetailsField = makeGenericActionCreator<keyof IChangePersonalDetailsReducer>(SET_CHANGE_PERSONAL_DETAILS_FIELD);

export const FETCH_PERSONAL_DETAILS_REQUEST = "FETCH_PERSONAL_DETAILS_REQUEST";
export const FETCH_PERSONAL_DETAILS_SUCCESS = "FETCH_PERSONAL_DETAILS_SUCCESS";
export const FETCH_PERSONAL_DETAILS_FAILURE = "FETCH_PERSONAL_DETAILS_FAILURE";

export const FETCH_PRODUCT_BY_ID_REQUEST = "FETCH_PRODUCT_BY_ID_REQUEST";
export const FETCH_PRODUCT_BY_ID_SUCCESS = "FETCH_PRODUCT_BY_ID_SUCCESS";
export const FETCH_PRODUCT_BY_ID_FAILURE = "FETCH_PRODUCT_BY_ID_FAILURE";

export const CHANGE_PERSONAL_DETAILS_REQUEST = "CHANGE_PERSONAL_DETAILS_REQUEST";
export const CHANGE_PERSONAL_DETAILS_SUCCESS = "CHANGE_PERSONAL_DETAILS_SUCCESS";
export const CHANGE_PERSONAL_DETAILS_FAILURE = "CHANGE_PERSONAL_DETAILS_FAILURE";


export const RESET_CHANGE_PERSONAL_DETAILS_STATE = "RESET_CHANGE_PERSONAL_DETAILS_STATE";
export const resetChangePersonalDetailsState = makeActionCreator(RESET_CHANGE_PERSONAL_DETAILS_STATE);

export const fetchPersonalDetails = (companyDomain: string, customerUniqueId: string) => {
  return {
    types: [FETCH_PERSONAL_DETAILS_REQUEST, FETCH_PERSONAL_DETAILS_SUCCESS, FETCH_PERSONAL_DETAILS_FAILURE],
    callAPI: () => API.fetchPersonalDetails(companyDomain, customerUniqueId)
  }
}

export const fetchProductById = (companyDomain: string, productId: number) => {
  return {
    types: [FETCH_PRODUCT_BY_ID_REQUEST, FETCH_PRODUCT_BY_ID_SUCCESS, FETCH_PRODUCT_BY_ID_FAILURE],
    callAPI: () => API.getProductById(companyDomain, productId)
  }
}

export const changePersonalDetails = (companyDomain: string, customerUniqueId: string, personalDetailsData: Partial<PersonalDetails>) => {
  return {
    types: [CHANGE_PERSONAL_DETAILS_REQUEST, CHANGE_PERSONAL_DETAILS_SUCCESS, CHANGE_PERSONAL_DETAILS_FAILURE],
    callAPI: () => API.updatePersonalDetails(companyDomain, customerUniqueId, personalDetailsData)
  }
}