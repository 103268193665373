import React, { useLayoutEffect, useMemo, useState } from "react";
import { AppState } from "../../..";
import CollapsiblePanel from "../../ui/collapsible-panel/CollapsiblePanel";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../ui/text/Text";
import PlanCycles from "../plan-cycles/PlanCycles";
import PaymentSummaryCoupons from "../../ui/payment-summary/PaymentSummaryCoupons";
import { ISelectPlanReducer } from "../../../reducers/selectPlanReducer";
import { ISubscriptionReducer } from "../../../reducers/subscriptionReducer";
import { IDiscountCouponReducer } from "../../../reducers/discountCouponReducer";
import { CouponError, IAppliedCoupon } from "../../../models/DiscountCoupon";
import { deleteSubscriptionDiscounts, fetchCouponDetails, fetchSubscriptionDiscounts, setAppliedCoupons, setCouponCode, updateCouponDiscount } from "../../../actions/discountCouponActions";
import { IGlobalReducer } from "../../../reducers/globalReducer";
import { IPersonalDetailsReducerState } from "../../../reducers/personalDetailsReducer";
import { fetchSubscriptionAddons, fetchSubscriptionAllowances } from "../../../actions/addOnActions";
import { fetchPersonalDetails } from "../../../actions/changePersonalDetailsActions";
import { IAddOnReducer } from "../../../reducers/addOnReducer";
import { getPriceDescription } from "../../../actions/paymentActions";
import { IAddOn, IAllowance } from "../../../models/AddOnAllowance";
import { getCouponError } from "../../../utils/planUtils";
import Modal from "../../ui/modal/Modal";
import FormGroup from "../../ui/form-group/FormGroup";
import Input from "../../ui/input/Input";
import counterpart from "counterpart";
import Button from "../../ui/button/Button";
import { AddCouponDiscountRequest } from "../../../utils/grpc/generated/Billsby.Protos/billing/public/subscription/discount/discount_pb";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import { IChangePersonalDetailsReducer } from "../../../reducers/changePersonalDetailsReducer";
import SystemInfo from "../../ui/system-info/SystemInfo";
import "./UpdateSubscription.scss"
import { getTaxName } from "../../../utils/commonUtils";
import { IPaymentReducer } from "../../../reducers/paymentReducer";
import TaxInput from "../../tax-input/TaxInput";
import moment from "moment";
import LoadingAnimation from "../../ui/loading-animation/LoadingAnimation";
import { VisibilityType } from "../../../models/Product";

interface IUpdateSubscription {
  btnDisabled?: boolean,
  onSubmit?: () => void
}

const UpdateSubscription: React.FC<IUpdateSubscription> = ({ onSubmit, btnDisabled = true }) => {
  const { plan, cycle, product, numberOfUnits, products, plansDropdown } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { currentSubscription, currentPlan } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const { appliedCoupons, couponCode, couponName } = useSelector<AppState, IDiscountCouponReducer>(state => state.discountCouponReducer);
  const { companyDomain, checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { mainProps: { subscriptionUniqueId, customerUniqueId }, address: { country, stateObject } } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { paymentDescription } = useSelector<AppState, IPaymentReducer>(state => state.paymentReducer);
  const { allowancesOverage, subscriptionAddons, subscriptionAllowances } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const { email } = useSelector<AppState, IChangePersonalDetailsReducer>(state => state.changePersonalDetailsReducer);
  const dispatch = useDispatch<Function>()

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingDescription, setIsLoadingDescription] = useState(true);
  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);
  const [isOpenTaxNumberModal, setIsOpenTaxNumberModal] = useState(false);
  const [isApplyingCouponCode, setIsApplyingCouponCode] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [hasUpdatedSubscription, setHasUpdatedSubscription] = useState(false);
  const [error, setError] = useState<CouponError | null>(null);
  const [filteredAddons, setFilteredAddons] = useState<IAddOn[]>([]);
  const [filteredAllowances, setFilteredAllowances] = useState<IAllowance[]>([]);
  const isTaxesIncluded = paymentDescription && paymentDescription.getTaxesIncluded();
  const taxName = useMemo(() => getTaxName(stateObject && stateObject.value.CountryIso3), [stateObject]);
  const couponError = useMemo(() => getCouponError(error, appliedCoupons), [error, appliedCoupons])
  const isOffSale = useMemo(() => {
    if (!currentSubscription) {
      return false;
    }
    const currentPlan = plansDropdown.find(p => p.value.planId === currentSubscription.planId)
    return currentPlan && (currentPlan.value.visibility !== VisibilityType.Public || currentPlan.value.isDeleted);
  }, [currentSubscription, products, plansDropdown])

  //Handle preloading of current subscription data
  useLayoutEffect(() => {
    const preloadData = async () => {
      if (!currentSubscription) {
        return;
      }

      setIsLoadingData(true);
      try {

        await dispatch(fetchSubscriptionAddons(companyDomain, subscriptionUniqueId as string));
        await dispatch(fetchSubscriptionAllowances(companyDomain, subscriptionUniqueId as string));
        await dispatch(fetchSubscriptionDiscounts(companyDomain, subscriptionUniqueId as string));
        await dispatch(fetchPersonalDetails(companyDomain, customerUniqueId as string));
        setIsLoadingData(false);

      } catch (err) {
        setIsLoadingData(false);
        console.log(err);
      }
    }

    preloadData();
  }, [])

  const filterCurrentAddOnsAllowancesSubscription = (addon: IAddOn | IAllowance) => {
    if (!btnDisabled) {
      return !!addon.units && addon.units > 0 && addon.isForced
    } else {
      return !!addon.units && addon.units > 0 || addon.isForced
    }
  }

  useLayoutEffect(() => {
    const filteredAddons = subscriptionAddons ? subscriptionAddons.filter(i => filterCurrentAddOnsAllowancesSubscription(i)).map((a) => {
      //Set unit value to 1 of forced addons to get the correct data from invoice simulation
      if (a.units && a.isForced && !btnDisabled) {
        return { ...a, units: 1 }
      } else {
        return a
      }
    }) : [];

    const filteredAllowances = subscriptionAllowances ? subscriptionAllowances.filter(i => filterCurrentAddOnsAllowancesSubscription(i)) : []

    setFilteredAddons(filteredAddons);
    setFilteredAllowances(filteredAllowances);

  }, [subscriptionAddons, subscriptionAllowances, numberOfUnits, cycle, btnDisabled])

  //Get simulated data
  useLayoutEffect(() => {
    if (!currentSubscription || isLoadingData) {
      return;
    }

    if ((currentSubscription.cycleIdNext && currentSubscription.cycleIdNext !== currentSubscription.cycleId) || 
    (currentSubscription.unitsNext && currentSubscription.unitsNext !== currentSubscription.units)) {
      setHasUpdatedSubscription(true)
    } else {
      setHasUpdatedSubscription(false)
    }

    const couponCodesList = appliedCoupons && appliedCoupons.length > 0 ? appliedCoupons.map(ac => ac.couponCode || "") : [];
    const discountIdsList = appliedCoupons && appliedCoupons.length > 0 ? appliedCoupons.map(ac => ac.discount.getDiscountId()) : [];

    const fetchDescription = async () => {
      if (!cycle) {
        return;
      }

      try {
        await dispatch(getPriceDescription(
          cycle.cycleId,
          filteredAddons,
          filteredAllowances,
          numberOfUnits === 0 ? 1 : numberOfUnits,
          !currentSubscription ? couponCodesList : undefined,
          currentSubscription ? discountIdsList : undefined,
          allowancesOverage,
          currentSubscription ? currentSubscription.cycleId : undefined,
          stateObject ? stateObject.value.CountryIso3 : undefined
        ));
        setIsLoadingDescription(false);

      } catch (err) {
        console.log(err);
        setIsLoadingDescription(false);

      }
    }

    const timeout = setTimeout(() => {
      fetchDescription()
    }, 500);

    return () => clearTimeout(timeout);
  }, [currentSubscription, appliedCoupons, filteredAllowances, filteredAllowances, allowancesOverage, cycle, isLoadingData, btnDisabled, numberOfUnits])

  if (!plan || !product || !currentSubscription) {
    return null;
  }

  const renderCouponModal = () => {
    return (
      <Modal id="coupon-modal" hasOverlay shouldTranslateTitle={false} width="340px" height="172px" isOpen={isOpenCouponModal} title={counterpart("APPLY_COUPON_LABEL", { couponLabel: checkoutConfig?.terminologyCouponSingular })} onRequestClose={onCloseCouponModal}>
        <>
          <FormGroup>
            <Input
              id="coupon-code"
              value={couponCode}
              placeholder={counterpart("APPLY_COUPON_PLACEHOLDER", { couponLabel: checkoutConfig?.terminologyCouponSingular })}
              onChange={(evt: any) => {
                dispatch(setCouponCode(evt.target.value.replace(/\s/g, "")));
              }}
            />
          </FormGroup>

          <div className="select-plan__coupon-modal__footer">
            <Button
              disabled={isApplyingCouponCode || !couponCode}
              isLoading={isApplyingCouponCode}
              className="modal__body__closebtn"
              title="APPLY_COUPON_BTN"
              translateWith={{ couponLabel: checkoutConfig?.terminologyCouponSingular }}
              id="apply-coupon"
              onClick={onAddCoupon}
            />
          </div>
        </>
      </Modal>
    )
  }

  const renderAddTaxNumerModal = () => {
    return (
      <Modal width="340px"
        height="172px"
        isOpen={isOpenTaxNumberModal}
        title={taxName.short}
        shouldTranslateTitle={false}
        onRequestClose={() => setIsOpenTaxNumberModal(false)}>
        <TaxInput isInlineDisplay={false} onSubmit={() => setIsOpenTaxNumberModal(false)} />
      </Modal>
    )
  }

  const onError = (err: CouponError) => {
    setIsApplyingCouponCode(false);
    setIsSuccessful(false);
    setError(err);
    onCloseCouponModal();
  };

  const onCloseCouponModal = () => {
    setIsOpenCouponModal(false);
    dispatch(setCouponCode(""));
  };

  const onDeleteCoupon = async (coupon: IAppliedCoupon) => {
    setIsSuccessful(false);
    setError(null)

    if (currentSubscription) {
      try {
        await dispatch(deleteSubscriptionDiscounts(companyDomain, currentSubscription.subscriptionUniqueId, coupon.subscriptionDiscount ? coupon.subscriptionDiscount.getSubscriptionDiscountId() : undefined));
        await dispatch(fetchSubscriptionDiscounts(companyDomain, currentSubscription.subscriptionUniqueId));
      } catch (err) {
        onError(err);
      }

      return;
    }
    dispatch(setAppliedCoupons(appliedCoupons.filter((ac: any) => {
      if (ac.coupon) {
        return ac.coupon.getCouponId() !== coupon.coupon.getCouponId()
      }
    })));
    setError(null);
    setIsSuccessful(false);
  };

  const onAddCoupon = async () => {
    if (!couponCode) {
      return;
    }

    setIsSuccessful(false);
    setError(null)

    if (currentSubscription && currentPlan) {
      let codeList: Array<AddCouponDiscountRequest.CodeDetails> = [];

      const newCode = new AddCouponDiscountRequest.CodeDetails();
      const newInt32 = new Int32Value();
      newInt32.setValue(currentPlan.planId);
      newCode.setPlanId(newInt32);
      newCode.setCouponCode(couponCode);

      codeList.push(newCode);

      try {
        await dispatch(fetchCouponDetails(companyDomain, couponCode, email));
        await dispatch(updateCouponDiscount(companyDomain, currentSubscription.subscriptionUniqueId, codeList));
        await dispatch(fetchSubscriptionDiscounts(companyDomain, currentSubscription.subscriptionUniqueId));

        setIsSuccessful(true);
        setIsApplyingCouponCode(false);
        setError(null);
        onCloseCouponModal();
      } catch (err) {
        console.log(err)
        onError(err);
      }

      return;
    }

    setIsApplyingCouponCode(true);
    setIsSuccessful(false);
    setError(null);

    try {
      await dispatch(fetchCouponDetails(companyDomain, couponCode, email));

      setIsSuccessful(true);
      setIsApplyingCouponCode(false);
      setError(null);
      onCloseCouponModal();
    } catch (err) {
      onError(err);
    }
  };

  if (isLoadingData || isLoadingDescription) {
    return <LoadingAnimation />
  }


  return (
    <div className={`update-subscription ${hasUpdatedSubscription ? "update-subscription--updated" : ""}`}>
      {
        (isSuccessful || error) && ( <SystemInfo title={counterpart(!error ? "APPLY_COUPON_SUCCESS_TITLE1" : couponError.title, { couponName, couponLabel: checkoutConfig?.terminologyCouponSingular, couponsLabel: checkoutConfig?.terminologyCouponPlural })} text={error ? couponError.content : ""} type={error ? "warning" : "success"} isShowIcon={false} shouldTranslateTitle={false} /> )
      }
      {isOffSale && (
        <SystemInfo
          title=""
          text="CHANGE_PLAN_PLAN_NOT_ON_SALE_ERROR"
          type="warning"
          translateWith={{ planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular }} 
          isShowIcon={false}
          shouldTranslateTitle={false}
        />
      )}
      {hasUpdatedSubscription && (
        <SystemInfo
          className="update-subscription__update-notice"
          title=""
          text={counterpart("CHANGE_SUBSCRIPTION_UPDATED_NOTICE", { nextBillingDate: moment(currentSubscription.nextBilling).format("DD MMM YYYY"), subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular })}
          type="warning"
          isShowIcon={false}
          shouldTranslateTitle={false}
          shouldTranslateText={false}
        />
      )}
      <CollapsiblePanel
        id="select-plan__product-item-collapsible"
        isLocked={false}
        isFloating={false}
        isExpanded={true}
        isModal
        className="select-plan__product-item"
        image={currentPlan?.planImageUrl || product.value.productImageUrl}
        expandableContent={
          <>
            <PlanCycles
              hasSelectedCycle={!btnDisabled}
              isChangePlan={true}
              onSelectCycle={onSubmit}
              filteredAddons={filteredAddons}
              filteredAllowances={filteredAllowances}
              disabled={isOffSale}
            />
            {currentSubscription && appliedCoupons.length > 0 && (
              <div className="update-subscription__discounts">
                <PaymentSummaryCoupons
                  paymentDescription={paymentDescription}
                  appliedCoupons={appliedCoupons}
                  onDeleteCoupon={onDeleteCoupon}
                  checkoutConfig={checkoutConfig}
                  currencyCode={product.value ? product.value.billingCurrency : currentSubscription.currency}>
                </PaymentSummaryCoupons>
              </div>
            )}
          </>
        }
      >
        <div>
          <Text content={`${currentSubscription.productDisplayName || currentSubscription.productName} - ${currentSubscription.planDisplayName || currentSubscription.planName}`} shouldTranslate={false} size="large" weight="bold" noMargin></Text>
          {!isOffSale && <Text content="CHANGE_SUBSCRIPTION_SUBTITLE" translateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular.capitalize() }} size="small" noMargin />}
        </div>

      </CollapsiblePanel>
      <div className="payment-method__links-container">
        <Text
          onClick={() => setIsOpenCouponModal(true)}
          className="payment-method__link payment-method__link__apply-coupon"
          content="UPDATE_PAYMENT_DETAILS_APPLY_COUPON"
          translateWith={{ couponLabel: checkoutConfig?.terminologyCouponSingular }}
          noMargin
        />
        {/* {isTaxesIncluded && <Text
          onClick={() => setIsOpenTaxNumberModal(true)}
          className="payment-method__link"
          content="UPDATE_PAYMENT_DETAILS_ADD_TAX_NUMBER"
          translateWith={{ taxName: taxName.short }}
          noMargin
        />} */}
      </div>
      {renderCouponModal()}
      {renderAddTaxNumerModal()}
    </div>
  )
}

export default UpdateSubscription