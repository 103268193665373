import React, { useEffect, useMemo } from "react";
import "./SelectPlanStep.scss";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import AddOnInput from "../add-on/addon-input/AddOnInput";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { SelectPlanScreen } from "../../models/SelectPlan";
import { setSelectPlanCurrentScreen } from "../../actions/selectPlanActions";
import { IAddOnPricingModel, IAddOn, AddOnPricingModelType } from "../../models/AddOnAllowance";
import { ICycle, IPlanWithCycles } from "../../models/Product";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IAddOnReducer } from "../../reducers/addOnReducer";
import { setAddOnsUnits } from "../../actions/addOnActions";
import { getCycleFrequencyText, isComplexPricingModel } from "../../utils/planUtils";

interface ISetAddOnsStep {
  onSubmit?: () => void;
  noAddonsCb?: () => void;
  btnText?: string;
  shouldTranslateBtnText?: boolean;
  btnDisabled?: boolean;
  btnBackCallback?: () => void;
  btnBackHidden?: boolean;
  warningMessage?: string;
  plan: IPlanWithCycles;
  cycle: ICycle;
  billingCurrency: string
}

const SetAddOnsStep: React.FC<ISetAddOnsStep> = ({
  onSubmit,
  noAddonsCb,
  btnText = "SELECT_PLAN_CONTINUE",
  shouldTranslateBtnText = true,
  btnDisabled,
  btnBackCallback = () => { },
  btnBackHidden = false,
  plan,
  cycle,
  billingCurrency
}) => {

  const { addOns, preloadedAddOns, hasPreloadedAddOnsWithMissingUnits } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const { checkoutConfig, branding } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const dispatch = useDispatch<Function>()

  btnDisabled = btnDisabled || addOns.some(i => i.pricingModelType !== AddOnPricingModelType.AddonFlatFee
    && i.isForced && (!i.units || i.units < 1));
    
  if(!onSubmit) {
    onSubmit = () => dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ALLOWANCE))
  }
  if(!noAddonsCb) {
    noAddonsCb = () => dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ALLOWANCE))
  }

  const addOnsFiltered = useMemo(() => {
    if (plan && cycle) {
      const addonsFiltered = addOns.sort((a, b) => ((a.displayName || a.name) > (b.displayName || b.name)) ? 1 : -1).filter(addon => addon.pricingModels.some(p => {
        return p.frequency === cycle.pricingModel.frequency
          && p.frequencyType === cycle.pricingModel.frequencyType
          && p.currency.isoCode === billingCurrency;
      }));
      if (preloadedAddOns.length && hasPreloadedAddOnsWithMissingUnits) {
        //return only the preloaded addons that don't come with the nr of units, the others that come with units cannot be modified
        return addOns.filter(a => isComplexPricingModel(a.pricingModelType) && preloadedAddOns.some(p => p[0] === a.id && !p[1]));
      }
      return [...addonsFiltered];
    }
    return [];
  }, [plan, cycle, addOns]);

  const canSkipScreen = (addOnsFiltered.length < 1 && !preloadedAddOns.length)
    || (preloadedAddOns.length && !hasPreloadedAddOnsWithMissingUnits)
    || (addOnsFiltered.every(a => a.isForced && !isComplexPricingModel(a.pricingModelType)) && !preloadedAddOns.length);

  useEffect(() => {
    if (canSkipScreen) {
      noAddonsCb?.();
    }
  }, []);

  const updateAddOns = (addOnId: number, pricingModels: IAddOnPricingModel[], units?: number) => {
    dispatch(setAddOnsUnits({ id: addOnId, units, pricingModels }))
  };

  if (!checkoutConfig || !plan) {
    return null;
  }

  return (
    <PageWrapper
      className={`select-plan select-plan-step2 ${canSkipScreen ? "hidden" : ""}`}
      btnText={btnText}
      shouldTranslateText={shouldTranslateBtnText}
      btnDisabled={btnDisabled}
      btnCallback={onSubmit}
      title="CONFIGURE_ADD_ON_TITLE"
      titleTranslateWith={{ planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular, addonLabel: checkoutConfig?.terminologyAddonPlural }}
      titlePosition="left-aligned"
      subTitle="CONFIGURE_ADD_ON_SUB_TITLE"
      subTitleTranslateWith={{ cycle: getCycleFrequencyText(cycle.pricingModel.frequency, cycle.pricingModel.frequencyType), addonLabel: checkoutConfig?.terminologyAddonPlural }}
      btnBackCallback={btnBackCallback}
      btnBackHidden={btnBackHidden}>
      <>
        {
          addOnsFiltered.map((addOn: IAddOn) => (
            <AddOnInput
              key={addOn.id}
              addOn={addOn}
              plan={plan}
              cycle={cycle as ICycle}
              branding={branding}
              checkoutConfig={checkoutConfig}
              image={addOn.imageUrl}
              updateAddons={(addOnId: number, pricingModels: IAddOnPricingModel[], units?: number) => updateAddOns(addOnId, pricingModels, units)}
            />
          ))
        }
      </>

    </PageWrapper>
  );
};

export default SetAddOnsStep
