/* eslint-disable */
// source: discount.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.billing.public.subscription.discount.AddCouponDiscountRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.AddCouponDiscountResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.Coupon', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.Coupon.DiscountLengthCase', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.Coupon.ExpirationCase', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.CouponType', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.DeductionType', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.Discount', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.Discount.DiscountModelCase', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.Discount.MonetaryModel', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.Discount.PercentageModel', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.DiscountType', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.FrequencyType', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.GetDiscountsRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.GetDiscountsResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.RemoveDiscountRequest', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.RemoveDiscountResponse', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.SubscriptionDiscount', null, global);
goog.exportSymbol('proto.billing.public.subscription.discount.SubscriptionDiscount.CouponCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.displayName = 'proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse.displayName = 'proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.discount.AddCouponDiscountRequest.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.discount.AddCouponDiscountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.AddCouponDiscountRequest.displayName = 'proto.billing.public.subscription.discount.AddCouponDiscountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.displayName = 'proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.AddCouponDiscountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.discount.AddCouponDiscountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.AddCouponDiscountResponse.displayName = 'proto.billing.public.subscription.discount.AddCouponDiscountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.RemoveDiscountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.discount.RemoveDiscountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.RemoveDiscountRequest.displayName = 'proto.billing.public.subscription.discount.RemoveDiscountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.RemoveDiscountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.discount.RemoveDiscountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.RemoveDiscountResponse.displayName = 'proto.billing.public.subscription.discount.RemoveDiscountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.GetDiscountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.discount.GetDiscountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.GetDiscountsRequest.displayName = 'proto.billing.public.subscription.discount.GetDiscountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.GetDiscountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.discount.GetDiscountsResponse.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.discount.GetDiscountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.GetDiscountsResponse.displayName = 'proto.billing.public.subscription.discount.GetDiscountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.SubscriptionDiscount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.billing.public.subscription.discount.SubscriptionDiscount.oneofGroups_);
};
goog.inherits(proto.billing.public.subscription.discount.SubscriptionDiscount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.SubscriptionDiscount.displayName = 'proto.billing.public.subscription.discount.SubscriptionDiscount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.Coupon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.billing.public.subscription.discount.Coupon.oneofGroups_);
};
goog.inherits(proto.billing.public.subscription.discount.Coupon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.Coupon.displayName = 'proto.billing.public.subscription.discount.Coupon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.displayName = 'proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.Discount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.discount.Discount.repeatedFields_, proto.billing.public.subscription.discount.Discount.oneofGroups_);
};
goog.inherits(proto.billing.public.subscription.discount.Discount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.Discount.displayName = 'proto.billing.public.subscription.discount.Discount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.Discount.PercentageModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.discount.Discount.PercentageModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.Discount.PercentageModel.displayName = 'proto.billing.public.subscription.discount.Discount.PercentageModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.subscription.discount.Discount.MonetaryModel.repeatedFields_, null);
};
goog.inherits(proto.billing.public.subscription.discount.Discount.MonetaryModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.Discount.MonetaryModel.displayName = 'proto.billing.public.subscription.discount.Discount.MonetaryModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.displayName = 'proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest}
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest;
  return proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest}
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest} returns this
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest} returns this
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse}
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse;
  return proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse}
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse} returns this
 */
proto.billing.public.subscription.discount.ApplyRetentionDiscountResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.AddCouponDiscountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.AddCouponDiscountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    codesList: jspb.Message.toObjectList(msg.getCodesList(),
    proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountRequest}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.AddCouponDiscountRequest;
  return proto.billing.public.subscription.discount.AddCouponDiscountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.AddCouponDiscountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountRequest}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var value = new proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails;
      reader.readMessage(value,proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.deserializeBinaryFromReader);
      msg.addCodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.AddCouponDiscountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.AddCouponDiscountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    planId: (f = msg.getPlanId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails;
  return proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponCode(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPlanId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlanId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string coupon_code = 1;
 * @return {string}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.prototype.getCouponCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails} returns this
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.prototype.setCouponCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value plan_id = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.prototype.getPlanId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails} returns this
*/
proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.prototype.setPlanId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails} returns this
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.prototype.clearPlanId = function() {
  return this.setPlanId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails.prototype.hasPlanId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountRequest} returns this
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountRequest} returns this
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CodeDetails codes = 3;
 * @return {!Array<!proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails>}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.prototype.getCodesList = function() {
  return /** @type{!Array<!proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails, 3));
};


/**
 * @param {!Array<!proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails>} value
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountRequest} returns this
*/
proto.billing.public.subscription.discount.AddCouponDiscountRequest.prototype.setCodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails}
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.prototype.addCodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.billing.public.subscription.discount.AddCouponDiscountRequest.CodeDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountRequest} returns this
 */
proto.billing.public.subscription.discount.AddCouponDiscountRequest.prototype.clearCodesList = function() {
  return this.setCodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.AddCouponDiscountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.AddCouponDiscountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.AddCouponDiscountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.AddCouponDiscountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountResponse}
 */
proto.billing.public.subscription.discount.AddCouponDiscountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.AddCouponDiscountResponse;
  return proto.billing.public.subscription.discount.AddCouponDiscountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.AddCouponDiscountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountResponse}
 */
proto.billing.public.subscription.discount.AddCouponDiscountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.AddCouponDiscountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.AddCouponDiscountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.AddCouponDiscountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.AddCouponDiscountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.billing.public.subscription.discount.AddCouponDiscountResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.discount.AddCouponDiscountResponse} returns this
 */
proto.billing.public.subscription.discount.AddCouponDiscountResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.RemoveDiscountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.RemoveDiscountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.RemoveDiscountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.RemoveDiscountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionDiscountId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.RemoveDiscountRequest}
 */
proto.billing.public.subscription.discount.RemoveDiscountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.RemoveDiscountRequest;
  return proto.billing.public.subscription.discount.RemoveDiscountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.RemoveDiscountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.RemoveDiscountRequest}
 */
proto.billing.public.subscription.discount.RemoveDiscountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubscriptionDiscountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.RemoveDiscountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.RemoveDiscountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.RemoveDiscountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.RemoveDiscountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionDiscountId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.subscription.discount.RemoveDiscountRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.discount.RemoveDiscountRequest} returns this
 */
proto.billing.public.subscription.discount.RemoveDiscountRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.discount.RemoveDiscountRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.discount.RemoveDiscountRequest} returns this
 */
proto.billing.public.subscription.discount.RemoveDiscountRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 subscription_discount_id = 3;
 * @return {number}
 */
proto.billing.public.subscription.discount.RemoveDiscountRequest.prototype.getSubscriptionDiscountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.discount.RemoveDiscountRequest} returns this
 */
proto.billing.public.subscription.discount.RemoveDiscountRequest.prototype.setSubscriptionDiscountId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.RemoveDiscountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.RemoveDiscountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.RemoveDiscountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.RemoveDiscountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.RemoveDiscountResponse}
 */
proto.billing.public.subscription.discount.RemoveDiscountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.RemoveDiscountResponse;
  return proto.billing.public.subscription.discount.RemoveDiscountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.RemoveDiscountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.RemoveDiscountResponse}
 */
proto.billing.public.subscription.discount.RemoveDiscountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.RemoveDiscountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.RemoveDiscountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.RemoveDiscountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.RemoveDiscountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.billing.public.subscription.discount.RemoveDiscountResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.discount.RemoveDiscountResponse} returns this
 */
proto.billing.public.subscription.discount.RemoveDiscountResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.GetDiscountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.GetDiscountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.GetDiscountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.GetDiscountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.GetDiscountsRequest}
 */
proto.billing.public.subscription.discount.GetDiscountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.GetDiscountsRequest;
  return proto.billing.public.subscription.discount.GetDiscountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.GetDiscountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.GetDiscountsRequest}
 */
proto.billing.public.subscription.discount.GetDiscountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.GetDiscountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.GetDiscountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.GetDiscountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.GetDiscountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.subscription.discount.GetDiscountsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.discount.GetDiscountsRequest} returns this
 */
proto.billing.public.subscription.discount.GetDiscountsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.billing.public.subscription.discount.GetDiscountsRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.discount.GetDiscountsRequest} returns this
 */
proto.billing.public.subscription.discount.GetDiscountsRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.discount.GetDiscountsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.GetDiscountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.GetDiscountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.GetDiscountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.GetDiscountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    discountsList: jspb.Message.toObjectList(msg.getDiscountsList(),
    proto.billing.public.subscription.discount.SubscriptionDiscount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.GetDiscountsResponse}
 */
proto.billing.public.subscription.discount.GetDiscountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.GetDiscountsResponse;
  return proto.billing.public.subscription.discount.GetDiscountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.GetDiscountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.GetDiscountsResponse}
 */
proto.billing.public.subscription.discount.GetDiscountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.public.subscription.discount.SubscriptionDiscount;
      reader.readMessage(value,proto.billing.public.subscription.discount.SubscriptionDiscount.deserializeBinaryFromReader);
      msg.addDiscounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.GetDiscountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.GetDiscountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.GetDiscountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.GetDiscountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.public.subscription.discount.SubscriptionDiscount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SubscriptionDiscount discounts = 1;
 * @return {!Array<!proto.billing.public.subscription.discount.SubscriptionDiscount>}
 */
proto.billing.public.subscription.discount.GetDiscountsResponse.prototype.getDiscountsList = function() {
  return /** @type{!Array<!proto.billing.public.subscription.discount.SubscriptionDiscount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.subscription.discount.SubscriptionDiscount, 1));
};


/**
 * @param {!Array<!proto.billing.public.subscription.discount.SubscriptionDiscount>} value
 * @return {!proto.billing.public.subscription.discount.GetDiscountsResponse} returns this
*/
proto.billing.public.subscription.discount.GetDiscountsResponse.prototype.setDiscountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.public.subscription.discount.SubscriptionDiscount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount}
 */
proto.billing.public.subscription.discount.GetDiscountsResponse.prototype.addDiscounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.public.subscription.discount.SubscriptionDiscount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.discount.GetDiscountsResponse} returns this
 */
proto.billing.public.subscription.discount.GetDiscountsResponse.prototype.clearDiscountsList = function() {
  return this.setDiscountsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.oneofGroups_ = [[5,6]];

/**
 * @enum {number}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.CouponCase = {
  COUPON_NOT_SET: 0,
  NO_COUPON: 5,
  COUPON_VALUE: 6
};

/**
 * @return {proto.billing.public.subscription.discount.SubscriptionDiscount.CouponCase}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.getCouponCase = function() {
  return /** @type {proto.billing.public.subscription.discount.SubscriptionDiscount.CouponCase} */(jspb.Message.computeOneofCase(this, proto.billing.public.subscription.discount.SubscriptionDiscount.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.SubscriptionDiscount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.SubscriptionDiscount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionDiscountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdOn: (f = msg.getCreatedOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    discount: (f = msg.getDiscount()) && proto.billing.public.subscription.discount.Discount.toObject(includeInstance, f),
    planId: (f = msg.getPlanId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    noCoupon: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    couponValue: (f = msg.getCouponValue()) && proto.billing.public.subscription.discount.Coupon.toObject(includeInstance, f),
    isRetentionDiscount: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    expiresOn: (f = msg.getExpiresOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.SubscriptionDiscount;
  return proto.billing.public.subscription.discount.SubscriptionDiscount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.SubscriptionDiscount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubscriptionDiscountId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedOn(value);
      break;
    case 3:
      var value = new proto.billing.public.subscription.discount.Discount;
      reader.readMessage(value,proto.billing.public.subscription.discount.Discount.deserializeBinaryFromReader);
      msg.setDiscount(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPlanId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoCoupon(value);
      break;
    case 6:
      var value = new proto.billing.public.subscription.discount.Coupon;
      reader.readMessage(value,proto.billing.public.subscription.discount.Coupon.deserializeBinaryFromReader);
      msg.setCouponValue(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRetentionDiscount(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiresOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.SubscriptionDiscount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.SubscriptionDiscount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionDiscountId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCreatedOn();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDiscount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.billing.public.subscription.discount.Discount.serializeBinaryToWriter
    );
  }
  f = message.getPlanId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCouponValue();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.billing.public.subscription.discount.Coupon.serializeBinaryToWriter
    );
  }
  f = message.getIsRetentionDiscount();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getExpiresOn();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 subscription_discount_id = 1;
 * @return {number}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.getSubscriptionDiscountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.setSubscriptionDiscountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_on = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.getCreatedOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
*/
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.setCreatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.clearCreatedOn = function() {
  return this.setCreatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.hasCreatedOn = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Discount discount = 3;
 * @return {?proto.billing.public.subscription.discount.Discount}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.getDiscount = function() {
  return /** @type{?proto.billing.public.subscription.discount.Discount} */ (
    jspb.Message.getWrapperField(this, proto.billing.public.subscription.discount.Discount, 3));
};


/**
 * @param {?proto.billing.public.subscription.discount.Discount|undefined} value
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
*/
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.setDiscount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.clearDiscount = function() {
  return this.setDiscount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.hasDiscount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value plan_id = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.getPlanId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
*/
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.setPlanId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.clearPlanId = function() {
  return this.setPlanId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.hasPlanId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool no_coupon = 5;
 * @return {boolean}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.getNoCoupon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.setNoCoupon = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.billing.public.subscription.discount.SubscriptionDiscount.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.clearNoCoupon = function() {
  return jspb.Message.setOneofField(this, 5, proto.billing.public.subscription.discount.SubscriptionDiscount.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.hasNoCoupon = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Coupon coupon_value = 6;
 * @return {?proto.billing.public.subscription.discount.Coupon}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.getCouponValue = function() {
  return /** @type{?proto.billing.public.subscription.discount.Coupon} */ (
    jspb.Message.getWrapperField(this, proto.billing.public.subscription.discount.Coupon, 6));
};


/**
 * @param {?proto.billing.public.subscription.discount.Coupon|undefined} value
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
*/
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.setCouponValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.billing.public.subscription.discount.SubscriptionDiscount.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.clearCouponValue = function() {
  return this.setCouponValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.hasCouponValue = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool is_retention_discount = 7;
 * @return {boolean}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.getIsRetentionDiscount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.setIsRetentionDiscount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp expires_on = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.getExpiresOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
*/
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.setExpiresOn = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.discount.SubscriptionDiscount} returns this
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.clearExpiresOn = function() {
  return this.setExpiresOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.discount.SubscriptionDiscount.prototype.hasExpiresOn = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.billing.public.subscription.discount.Coupon.oneofGroups_ = [[4,5],[6,7]];

/**
 * @enum {number}
 */
proto.billing.public.subscription.discount.Coupon.ExpirationCase = {
  EXPIRATION_NOT_SET: 0,
  IS_INFINITE: 4,
  EXPIRES_ON: 5
};

/**
 * @return {proto.billing.public.subscription.discount.Coupon.ExpirationCase}
 */
proto.billing.public.subscription.discount.Coupon.prototype.getExpirationCase = function() {
  return /** @type {proto.billing.public.subscription.discount.Coupon.ExpirationCase} */(jspb.Message.computeOneofCase(this, proto.billing.public.subscription.discount.Coupon.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.billing.public.subscription.discount.Coupon.DiscountLengthCase = {
  DISCOUNT_LENGTH_NOT_SET: 0,
  IS_LIFE_TIME: 6,
  FIXED_TERM: 7
};

/**
 * @return {proto.billing.public.subscription.discount.Coupon.DiscountLengthCase}
 */
proto.billing.public.subscription.discount.Coupon.prototype.getDiscountLengthCase = function() {
  return /** @type {proto.billing.public.subscription.discount.Coupon.DiscountLengthCase} */(jspb.Message.computeOneofCase(this, proto.billing.public.subscription.discount.Coupon.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.Coupon.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.Coupon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.Coupon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.Coupon.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    couponType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isInfinite: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    expiresOn: (f = msg.getExpiresOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isLifeTime: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    fixedTerm: (f = msg.getFixedTerm()) && proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.Coupon}
 */
proto.billing.public.subscription.discount.Coupon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.Coupon;
  return proto.billing.public.subscription.discount.Coupon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.Coupon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.Coupon}
 */
proto.billing.public.subscription.discount.Coupon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.billing.public.subscription.discount.CouponType} */ (reader.readEnum());
      msg.setCouponType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInfinite(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiresOn(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLifeTime(value);
      break;
    case 7:
      var value = new proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength;
      reader.readMessage(value,proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.deserializeBinaryFromReader);
      msg.setFixedTerm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.Coupon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.Coupon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.Coupon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.Coupon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCouponType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getExpiresOn();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getFixedTerm();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength}
 */
proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength;
  return proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength}
 */
proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {!proto.billing.public.subscription.discount.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 frequency = 1;
 * @return {number}
 */
proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength} returns this
 */
proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional FrequencyType frequency_type = 2;
 * @return {!proto.billing.public.subscription.discount.FrequencyType}
 */
proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.prototype.getFrequencyType = function() {
  return /** @type {!proto.billing.public.subscription.discount.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.billing.public.subscription.discount.FrequencyType} value
 * @return {!proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength} returns this
 */
proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 coupon_id = 1;
 * @return {number}
 */
proto.billing.public.subscription.discount.Coupon.prototype.getCouponId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.discount.Coupon} returns this
 */
proto.billing.public.subscription.discount.Coupon.prototype.setCouponId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.billing.public.subscription.discount.Coupon.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.discount.Coupon} returns this
 */
proto.billing.public.subscription.discount.Coupon.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CouponType coupon_type = 3;
 * @return {!proto.billing.public.subscription.discount.CouponType}
 */
proto.billing.public.subscription.discount.Coupon.prototype.getCouponType = function() {
  return /** @type {!proto.billing.public.subscription.discount.CouponType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.billing.public.subscription.discount.CouponType} value
 * @return {!proto.billing.public.subscription.discount.Coupon} returns this
 */
proto.billing.public.subscription.discount.Coupon.prototype.setCouponType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool is_infinite = 4;
 * @return {boolean}
 */
proto.billing.public.subscription.discount.Coupon.prototype.getIsInfinite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.discount.Coupon} returns this
 */
proto.billing.public.subscription.discount.Coupon.prototype.setIsInfinite = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.billing.public.subscription.discount.Coupon.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.billing.public.subscription.discount.Coupon} returns this
 */
proto.billing.public.subscription.discount.Coupon.prototype.clearIsInfinite = function() {
  return jspb.Message.setOneofField(this, 4, proto.billing.public.subscription.discount.Coupon.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.discount.Coupon.prototype.hasIsInfinite = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp expires_on = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.public.subscription.discount.Coupon.prototype.getExpiresOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.public.subscription.discount.Coupon} returns this
*/
proto.billing.public.subscription.discount.Coupon.prototype.setExpiresOn = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.billing.public.subscription.discount.Coupon.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.discount.Coupon} returns this
 */
proto.billing.public.subscription.discount.Coupon.prototype.clearExpiresOn = function() {
  return this.setExpiresOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.discount.Coupon.prototype.hasExpiresOn = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool is_life_time = 6;
 * @return {boolean}
 */
proto.billing.public.subscription.discount.Coupon.prototype.getIsLifeTime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.discount.Coupon} returns this
 */
proto.billing.public.subscription.discount.Coupon.prototype.setIsLifeTime = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.billing.public.subscription.discount.Coupon.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.billing.public.subscription.discount.Coupon} returns this
 */
proto.billing.public.subscription.discount.Coupon.prototype.clearIsLifeTime = function() {
  return jspb.Message.setOneofField(this, 6, proto.billing.public.subscription.discount.Coupon.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.discount.Coupon.prototype.hasIsLifeTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional FixedTermDiscountLength fixed_term = 7;
 * @return {?proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength}
 */
proto.billing.public.subscription.discount.Coupon.prototype.getFixedTerm = function() {
  return /** @type{?proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength} */ (
    jspb.Message.getWrapperField(this, proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength, 7));
};


/**
 * @param {?proto.billing.public.subscription.discount.Coupon.FixedTermDiscountLength|undefined} value
 * @return {!proto.billing.public.subscription.discount.Coupon} returns this
*/
proto.billing.public.subscription.discount.Coupon.prototype.setFixedTerm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.billing.public.subscription.discount.Coupon.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.discount.Coupon} returns this
 */
proto.billing.public.subscription.discount.Coupon.prototype.clearFixedTerm = function() {
  return this.setFixedTerm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.discount.Coupon.prototype.hasFixedTerm = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.discount.Discount.repeatedFields_ = [8];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.billing.public.subscription.discount.Discount.oneofGroups_ = [[9,10]];

/**
 * @enum {number}
 */
proto.billing.public.subscription.discount.Discount.DiscountModelCase = {
  DISCOUNT_MODEL_NOT_SET: 0,
  PERCENTAGE: 9,
  MONETARY: 10
};

/**
 * @return {proto.billing.public.subscription.discount.Discount.DiscountModelCase}
 */
proto.billing.public.subscription.discount.Discount.prototype.getDiscountModelCase = function() {
  return /** @type {proto.billing.public.subscription.discount.Discount.DiscountModelCase} */(jspb.Message.computeOneofCase(this, proto.billing.public.subscription.discount.Discount.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.Discount.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.Discount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.Discount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.Discount.toObject = function(includeInstance, msg) {
  var f, obj = {
    discountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    discountUniqueId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    couponCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    discountType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    deductionType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    allowAllPlans: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    planIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    percentage: (f = msg.getPercentage()) && proto.billing.public.subscription.discount.Discount.PercentageModel.toObject(includeInstance, f),
    monetary: (f = msg.getMonetary()) && proto.billing.public.subscription.discount.Discount.MonetaryModel.toObject(includeInstance, f),
    isAllowedWithOtherDiscounts: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.Discount}
 */
proto.billing.public.subscription.discount.Discount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.Discount;
  return proto.billing.public.subscription.discount.Discount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.Discount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.Discount}
 */
proto.billing.public.subscription.discount.Discount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscountUniqueId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponCount(value);
      break;
    case 5:
      var value = /** @type {!proto.billing.public.subscription.discount.DiscountType} */ (reader.readEnum());
      msg.setDiscountType(value);
      break;
    case 6:
      var value = /** @type {!proto.billing.public.subscription.discount.DeductionType} */ (reader.readEnum());
      msg.setDeductionType(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowAllPlans(value);
      break;
    case 8:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPlanIdsList(value);
      break;
    case 9:
      var value = new proto.billing.public.subscription.discount.Discount.PercentageModel;
      reader.readMessage(value,proto.billing.public.subscription.discount.Discount.PercentageModel.deserializeBinaryFromReader);
      msg.setPercentage(value);
      break;
    case 10:
      var value = new proto.billing.public.subscription.discount.Discount.MonetaryModel;
      reader.readMessage(value,proto.billing.public.subscription.discount.Discount.MonetaryModel.deserializeBinaryFromReader);
      msg.setMonetary(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAllowedWithOtherDiscounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.Discount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.Discount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.Discount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.Discount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscountId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDiscountUniqueId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCouponCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDiscountType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDeductionType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAllowAllPlans();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPlanIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
  f = message.getPercentage();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.billing.public.subscription.discount.Discount.PercentageModel.serializeBinaryToWriter
    );
  }
  f = message.getMonetary();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.billing.public.subscription.discount.Discount.MonetaryModel.serializeBinaryToWriter
    );
  }
  f = message.getIsAllowedWithOtherDiscounts();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.Discount.PercentageModel.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.Discount.PercentageModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.Discount.PercentageModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.Discount.PercentageModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentage: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.Discount.PercentageModel}
 */
proto.billing.public.subscription.discount.Discount.PercentageModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.Discount.PercentageModel;
  return proto.billing.public.subscription.discount.Discount.PercentageModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.Discount.PercentageModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.Discount.PercentageModel}
 */
proto.billing.public.subscription.discount.Discount.PercentageModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.Discount.PercentageModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.Discount.PercentageModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.Discount.PercentageModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.Discount.PercentageModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 percentage = 1;
 * @return {number}
 */
proto.billing.public.subscription.discount.Discount.PercentageModel.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.discount.Discount.PercentageModel} returns this
 */
proto.billing.public.subscription.discount.Discount.PercentageModel.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.Discount.MonetaryModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.Discount.MonetaryModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricesList: jspb.Message.toObjectList(msg.getPricesList(),
    proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.Discount.MonetaryModel}
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.Discount.MonetaryModel;
  return proto.billing.public.subscription.discount.Discount.MonetaryModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.Discount.MonetaryModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.Discount.MonetaryModel}
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices;
      reader.readMessage(value,proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.deserializeBinaryFromReader);
      msg.addPrices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.Discount.MonetaryModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.Discount.MonetaryModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices}
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices;
  return proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices}
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string currency_code = 1;
 * @return {string}
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices} returns this
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices} returns this
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated MonetaryCurrencyPrices prices = 1;
 * @return {!Array<!proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices>}
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.prototype.getPricesList = function() {
  return /** @type{!Array<!proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices, 1));
};


/**
 * @param {!Array<!proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices>} value
 * @return {!proto.billing.public.subscription.discount.Discount.MonetaryModel} returns this
*/
proto.billing.public.subscription.discount.Discount.MonetaryModel.prototype.setPricesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices}
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.prototype.addPrices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.public.subscription.discount.Discount.MonetaryModel.MonetaryCurrencyPrices, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.discount.Discount.MonetaryModel} returns this
 */
proto.billing.public.subscription.discount.Discount.MonetaryModel.prototype.clearPricesList = function() {
  return this.setPricesList([]);
};


/**
 * optional int32 discount_id = 1;
 * @return {number}
 */
proto.billing.public.subscription.discount.Discount.prototype.getDiscountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
 */
proto.billing.public.subscription.discount.Discount.prototype.setDiscountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.billing.public.subscription.discount.Discount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
 */
proto.billing.public.subscription.discount.Discount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string discount_unique_id = 3;
 * @return {string}
 */
proto.billing.public.subscription.discount.Discount.prototype.getDiscountUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
 */
proto.billing.public.subscription.discount.Discount.prototype.setDiscountUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 coupon_count = 4;
 * @return {number}
 */
proto.billing.public.subscription.discount.Discount.prototype.getCouponCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
 */
proto.billing.public.subscription.discount.Discount.prototype.setCouponCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional DiscountType discount_type = 5;
 * @return {!proto.billing.public.subscription.discount.DiscountType}
 */
proto.billing.public.subscription.discount.Discount.prototype.getDiscountType = function() {
  return /** @type {!proto.billing.public.subscription.discount.DiscountType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.billing.public.subscription.discount.DiscountType} value
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
 */
proto.billing.public.subscription.discount.Discount.prototype.setDiscountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional DeductionType deduction_type = 6;
 * @return {!proto.billing.public.subscription.discount.DeductionType}
 */
proto.billing.public.subscription.discount.Discount.prototype.getDeductionType = function() {
  return /** @type {!proto.billing.public.subscription.discount.DeductionType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.billing.public.subscription.discount.DeductionType} value
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
 */
proto.billing.public.subscription.discount.Discount.prototype.setDeductionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool allow_all_plans = 7;
 * @return {boolean}
 */
proto.billing.public.subscription.discount.Discount.prototype.getAllowAllPlans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
 */
proto.billing.public.subscription.discount.Discount.prototype.setAllowAllPlans = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated int32 plan_ids = 8;
 * @return {!Array<number>}
 */
proto.billing.public.subscription.discount.Discount.prototype.getPlanIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
 */
proto.billing.public.subscription.discount.Discount.prototype.setPlanIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
 */
proto.billing.public.subscription.discount.Discount.prototype.addPlanIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
 */
proto.billing.public.subscription.discount.Discount.prototype.clearPlanIdsList = function() {
  return this.setPlanIdsList([]);
};


/**
 * optional PercentageModel percentage = 9;
 * @return {?proto.billing.public.subscription.discount.Discount.PercentageModel}
 */
proto.billing.public.subscription.discount.Discount.prototype.getPercentage = function() {
  return /** @type{?proto.billing.public.subscription.discount.Discount.PercentageModel} */ (
    jspb.Message.getWrapperField(this, proto.billing.public.subscription.discount.Discount.PercentageModel, 9));
};


/**
 * @param {?proto.billing.public.subscription.discount.Discount.PercentageModel|undefined} value
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
*/
proto.billing.public.subscription.discount.Discount.prototype.setPercentage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.billing.public.subscription.discount.Discount.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
 */
proto.billing.public.subscription.discount.Discount.prototype.clearPercentage = function() {
  return this.setPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.discount.Discount.prototype.hasPercentage = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional MonetaryModel monetary = 10;
 * @return {?proto.billing.public.subscription.discount.Discount.MonetaryModel}
 */
proto.billing.public.subscription.discount.Discount.prototype.getMonetary = function() {
  return /** @type{?proto.billing.public.subscription.discount.Discount.MonetaryModel} */ (
    jspb.Message.getWrapperField(this, proto.billing.public.subscription.discount.Discount.MonetaryModel, 10));
};


/**
 * @param {?proto.billing.public.subscription.discount.Discount.MonetaryModel|undefined} value
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
*/
proto.billing.public.subscription.discount.Discount.prototype.setMonetary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.billing.public.subscription.discount.Discount.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
 */
proto.billing.public.subscription.discount.Discount.prototype.clearMonetary = function() {
  return this.setMonetary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.subscription.discount.Discount.prototype.hasMonetary = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool is_allowed_with_other_discounts = 11;
 * @return {boolean}
 */
proto.billing.public.subscription.discount.Discount.prototype.getIsAllowedWithOtherDiscounts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.subscription.discount.Discount} returns this
 */
proto.billing.public.subscription.discount.Discount.prototype.setIsAllowedWithOtherDiscounts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * @enum {number}
 */
proto.billing.public.subscription.discount.CouponType = {
  UNSPECIFIED_CT: 0,
  SINGLE_USE: 1,
  MULTI_USE: 2
};

/**
 * @enum {number}
 */
proto.billing.public.subscription.discount.DiscountType = {
  UNSPECIFIED_DIT: 0,
  PERCENTAGE: 1,
  MONETARY: 2
};

/**
 * @enum {number}
 */
proto.billing.public.subscription.discount.FrequencyType = {
  UNSPECIFIED_FT: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  YEARLY: 4
};

/**
 * @enum {number}
 */
proto.billing.public.subscription.discount.DeductionType = {
  UNSPECIFIED_DET: 0,
  PLAN_ONLY: 1,
  ENTIRE_SUBSCRIPTION: 2
};

goog.object.extend(exports, proto.billing.public.subscription.discount);
