import React, { useState } from "react";
import "./DatePicker.scss";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Moment } from "moment";


interface IDatePickerProps {
  date: Moment | null,
  openDirection?: "up" | "down",
  onChange: (date: Moment | null) => void
  className?: string,
  isOutsideRange?: (day: any) => boolean
}

const DatePicker: React.FC<IDatePickerProps> = React.memo(({ date, openDirection = "down", onChange, className = "", 
  isOutsideRange = () => false }) => {
  const [focus, setFocus] = useState(false);

  return (
    <div className={`custom-date-picker ${className}`} onClick={(evt: any) =>{
      const datePicker: HTMLElement = evt.target.querySelector("button");
      datePicker && datePicker.click();
    }}>
      <SingleDatePicker
        id="Some-id"
        openDirection={openDirection}
        date={date}
        onDateChange={date => onChange(date)}
        focused={focus}
        onFocusChange={({ focused }) => setFocus(!!focused)}
        customInputIcon={(
          <div>
            <i className="custom-date-picker__icon far fa-calendar-day" />
            <i className="custom-date-picker__icon-chevron far fa-chevron-down" />
          </div>
        )}
        displayFormat={"MMMM DD YYYY"}
        block
        numberOfMonths={1}
        inputIconPosition="before"
        isOutsideRange={(d:any) => isOutsideRange(d)}
      >
      </SingleDatePicker>
    </div>
  );
})

export default DatePicker;