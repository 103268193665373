export enum RetentionScreen {
  helpInterrupt = "helpInterrupt",
  reasonsToStay = "reasonsToStay",
  timeRemaining = "daysRemaining",
  changePlan = "changePlan",
  exitReasons = "exitReason",
  confirmCancel = "confirmCancel",
  discountOffer = "discountOffer",
  payBalance = "payBalance",
  endContract = "endContract" 

}