import React from "react";
import Text from "../text/Text";
import Panel from "../panel/Panel";
import "./ErrorMessage.scss";

interface IErrorMessage {
  title?: string,
  shouldTranslateTitle?: boolean
  subTitle: JSX.Element,
  className?: string,
  isVisible?: boolean,
  type?: "whitebg" | "redbg"
}

const ErrorMessage: React.FC<IErrorMessage> = React.memo(({ title, shouldTranslateTitle = true, subTitle, className = "", isVisible = true, type = "whitebg" }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Panel id="error-message" className={`error-message error-message-${type} ${className}`}>
      {title && <Text className={`error-message__title-${type}`} content={title} shouldTranslate={shouldTranslateTitle} noMargin />}
      <div className={`error-message__subTitle-${type}`}>{subTitle}</div>
    </Panel>
  )
})

export default ErrorMessage;