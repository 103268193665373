import React, { useLayoutEffect, useMemo, useState } from "react";
import FormGroup from "../ui/form-group/FormGroup";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import counterpart from "counterpart";
import Text from "../ui/text/Text";
import PhoneInput from "../ui/phone-input/PhoneInput";
import Checkbox from "../ui/checkbox/Checkbox";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import { setField, validateCustomFields, setCustomFieldValue, validateTaxRegNumber, resetPersonalDetailsErrors, setPersonalDetailsCurrentScreen } from "../../actions/personalDetailsActions";
import { CustomFieldType, ICustomFieldWithValue } from "../../models/CustomFields";
import Textarea from "../ui/textarea/Textarea";
import DatePicker from "../ui/date-picker/DatePicker";
import { MAX_MULTI_LINE_INPUT } from "../../utils/constants";
import moment, { Moment } from "moment";
import Dropdown from "../ui/dropdown/Dropdown";
import { TaxCollection, TaxRegNumberError } from "../../models/Taxes";
import SystemInfo from "../ui/system-info/SystemInfo";
import { PersonalDetailsScreen } from "../../models/PersonalDetails";
import { setNavigationStep } from "../../actions/globalActions";
import { NavigationBarSteps } from "../../models/NavigationBar";
import { setSelectPlanCurrentScreen, setCycle, setProduct } from "../../actions/selectPlanActions";
import { SelectPlanScreen } from "../../models/SelectPlan";
import Panel from "../ui/panel/Panel";
import "./PersonalDetailsStep.scss";
import SeeMoreText from "../ui/see-more-text/SeeMoreText";
import { isComplexPricingModel } from "../../utils/planUtils";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import { IAddOnReducer } from "../../reducers/addOnReducer";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { IGlobalReducer } from "../../reducers/globalReducer";



const PersonalDetailsStep3: React.FC = () => {
  const [isValidating, setIsValidating] = useState(false);
  const [isTaxValidated, setIsTaxValidated] = useState(false);

  const { productId, planId, cycleId, plansDropdownAvailable, productsDropdownAvailable, addOnsFilteredByCycle, allowancesFilteredByCycle,
    product } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { preloadedAddOns, preloadedAllowances, hasPreloadedAddOnsWithMissingUnits } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const {
    fields,
    address: { country },
    taxRegNumberRequirementType,
    taxRegNumber,
    taxRegError,
    personalDetailsErrors,
    isUserDataScreenPreloaded,
    isAddressScreenPreloaded
  } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { companyDomain } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const dispatch = useDispatch<Function>()


  const {
    additionalEmails,
    phoneNumber,
    phoneNumberPrefix,
    phoneNumberCountry,
    marketingConsent,
    errors,
    isAdditionalEmailsPreLoaded,
    isPhoneNumberPreLoaded,
    isPhoneNumberPrefixPreLoaded,
    isMarketingConsentPreLoaded,
    customFields,
    preLoadedCustomFields,
  } = fields;

  const isContinueEnabled = useMemo(() => {
    for (let customField of customFields) {
      if (customField.compulsory && !customField.value) {
        return false;
      }
    }
    return true;
  }, [customFields]);


  const getCustomFieldPreLoaded = (preLoadedCustomFields: Array<Partial<ICustomFieldWithValue>>, originalField: ICustomFieldWithValue) => {
    const preLoadedField: Partial<ICustomFieldWithValue> | undefined = preLoadedCustomFields.find((_field) => _field.customFieldId === originalField.customFieldId);
    const isValid = preLoadedField && preLoadedField.value && (originalField.options ? originalField.options.split(",").some((opt) => opt === preLoadedField.value) : true);
    return { isValid, field: preLoadedField };
  };

  useLayoutEffect(() => {
    let countValidFields = 0;
    customFields.forEach((field, index) => {
      const preloadedField = getCustomFieldPreLoaded(preLoadedCustomFields, field);
      if (preloadedField.isValid && preloadedField.field) {
        dispatch(setCustomFieldValue(index, preloadedField.field.value));
        countValidFields++;
      }
    });
    if (
      countValidFields === customFields.length &&
      isStandardFieldReady(isAdditionalEmailsPreLoaded, isAdditionalEmailRequired) &&
      isStandardFieldReady(isPhoneNumberPreLoaded, isPhoneNumberRequired) &&
      isStandardFieldReady(isMarketingConsentPreLoaded, isMarketingConsentRequired)
    ) {
      // go to the next screen
      dispatch(validateCustomFields());
    }
  }, []);

  useLayoutEffect(() => {
    if (isTaxValidated && !taxRegError) {
      dispatch(validateCustomFields());
    }
    return () => dispatch(resetPersonalDetailsErrors())
  }, [isTaxValidated, taxRegNumber]);

  const isStandardFieldReady = (isStandardFieldPreLoaded: boolean | undefined, isStandardFieldRequired: boolean) => {
    if (isStandardFieldRequired) {
      return isStandardFieldPreLoaded;
    }
    return true;
  };

  if (!product) {
    return null;
  }

  const { isAdditionalEmailRequired, isPhoneNumberRequired, isMarketingConsentRequired } = product.value;

  const validateTax = async () => {
    if (!country) {
      return;
    }

    setIsTaxValidated(false);
    setIsValidating(true);
    try {
      await dispatch(validateTaxRegNumber(companyDomain, country.value.iso3Code, taxRegNumber));
      setIsTaxValidated(true);
      setIsValidating(false);
    } catch (err) {
      console.log(err);
      setIsTaxValidated(false);
      setIsValidating(false);
    }
  };

  const onContinue = async () => {
    if (taxRegError === TaxRegNumberError.UNABLE_TO_VALIDATE || taxRegNumberRequirementType !== TaxCollection.COMPULSORY) {
      dispatch(validateCustomFields());
    } else await validateTax();
  };


  return (
    <PageWrapper
      btnDisabled={isValidating || !isContinueEnabled}
      title="ADDITIONAL_INFORMATION_TITLE"
      titlePosition="left-aligned"
      btnCallback={() => dispatch(validateCustomFields())}
      btnBackCallback={() => {
        if (isAddressScreenPreloaded && !isUserDataScreenPreloaded) {
          // go back to user data screen
          dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.MAIN_DATA));
        }
        else if (isAddressScreenPreloaded && isUserDataScreenPreloaded) {
          dispatch(setNavigationStep(NavigationBarSteps.SELECT_PLAN));

          if (allowancesFilteredByCycle.length && !allowancesFilteredByCycle.every(a => a.isForced) && !preloadedAllowances.length) {
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ALLOWANCE));
          }
          else if ((addOnsFilteredByCycle.length && !addOnsFilteredByCycle.every(a => a.isForced && !isComplexPricingModel(a.pricingModelType)) && !preloadedAddOns.length) || (hasPreloadedAddOnsWithMissingUnits)) {
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ADD_ON));
          }
          else if (!planId && plansDropdownAvailable.length > 1) {
            dispatch(setCycle(null));
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PLAN));
          }
          else /*if (!productId && products.length > 1)*/ {
            dispatch(setProduct(null));
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PRODUCT));
          }
        }
        else {
          // go back to address screen
          dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.PERSONAL_ADDRESS));
        }
      }}
      btnBackHidden={isUserDataScreenPreloaded && isAddressScreenPreloaded &&
        ((!!preloadedAddOns.length && !!preloadedAllowances.length) || (!addOnsFilteredByCycle.length && !allowancesFilteredByCycle.length)) &&
        ((!!productId && !!planId && !!cycleId)
          || (productsDropdownAvailable.length === 1 && plansDropdownAvailable.length === 1 && plansDropdownAvailable[0].value.cycles.length === 1)
          || (!!productId && plansDropdownAvailable.length === 1 && plansDropdownAvailable[0].value.cycles.length === 1))
      }>
      <>
        {personalDetailsErrors.length > 0 &&
          <SystemInfo
            title={counterpart("PAYMENT_COMPULSORY_FIELDS")}
            text={personalDetailsErrors.join(", ")}
            isShowIcon={false}
            shouldTranslateTitle={false}
            shouldTranslateText={false}
            type="warning"
          />
        }
      </>

      <Panel id="personal-details-customfields" noMargin>
        <>
          <div>
            {isAdditionalEmailRequired && !isAdditionalEmailsPreLoaded ? (
              <FormGroup>
                <FormLabel content="CUSTOM_FIELDS_ADDITIONAL_EMAILS" />
                <FormGroup margin="tiny">
                  <Input
                    id="additional-emails"
                    placeholder={counterpart("CUSTOM_FIELDS_ADDITIONAL_EMAILS_PLACEHOLDER")}
                    required
                    value={additionalEmails}
                    onChange={(evt: any) => dispatch(setField("additionalEmails", evt.target.value))}
                    isError={!!errors.additionalEmails}
                    errorMsg={errors.additionalEmails ? counterpart(errors.additionalEmails) : ""}
                  />
                </FormGroup>
                <SeeMoreText maxChar={65} content="CUSTOM_FIELDS_ADDITIONAL_EMAILS_TEXT"></SeeMoreText>
              </FormGroup>
            ) : null}
            {isPhoneNumberRequired && !(isPhoneNumberPreLoaded && isPhoneNumberPrefixPreLoaded) ? (
              <FormGroup>
                <FormLabel content="CUSTOM_FIELDS_PHONE_NUMBER" />
                <PhoneInput
                  preferredCountries={["US", "GB", "AU", "CA"]}
                  defaultCountry={phoneNumberCountry}
                  value={phoneNumber}
                  dialCode={phoneNumberPrefix}
                  isRequired={false}
                  onPhoneNumberChange={(isValid: boolean, phone: string, countryData: any) => {
                    if (countryData.dialCode) {
                      dispatch(setField("phoneNumberPrefix", countryData.dialCode));
                      dispatch(setField("phoneNumberCountry", countryData.iso2));
                      dispatch(setField("phoneNumber", phone));
                    }
                  }}
                  onSelectFlag={(phone: string, countryData: any) => {
                    dispatch(setField("phoneNumberPrefix", countryData.dialCode));
                    dispatch(setField("phoneNumberCountry", countryData.iso2));
                  }}
                  errorLabel="CUSTOM_FIELDS_PHONE_NUMBER_INVALID"
                  separateDialCode
                />
              </FormGroup>
            ) : null}
            {isMarketingConsentRequired && !isMarketingConsentPreLoaded ? (
              <FormGroup>
                <Checkbox checked={marketingConsent} content={counterpart("CUSTOM_FIELDS_MARKETING_CONTENT")} onClick={() => dispatch(setField("marketingConsent", !marketingConsent))} value="" />
              </FormGroup>
            ) : null}

            <div className="personal-details__custom-fields__container">
              {customFields.map((customField: ICustomFieldWithValue, index: number) => {
                switch (customField.type) {
                  case CustomFieldType.SingleLineTextField:
                    return getCustomFieldPreLoaded(preLoadedCustomFields, customField).isValid ? null : (
                      <FormGroup key={index}>
                        <FormLabel content={customField.label} shouldTranslate={false} />
                        <FormGroup margin="tiny">
                          <Input
                            id={`custom-field-${index}`}
                            value={customField.value ? customField.value : ""}
                            onChange={(evt: any) => dispatch(setCustomFieldValue(index, evt.target.value))}
                            isError={!!errors["CustomField" + customField.customFieldId]}
                            errorMsg={errors["CustomField" + customField.customFieldId] ? counterpart(errors["CustomField" + customField.customFieldId]) : ""}
                          />
                        </FormGroup>
                        <Text size="normal" content={customField.description} shouldTranslate={false} noMargin />
                      </FormGroup>
                    );
                  case CustomFieldType.MultiLineTextField:
                    return getCustomFieldPreLoaded(preLoadedCustomFields, customField).isValid ? null : (
                      <FormGroup key={index}>
                        <FormLabel content={customField.label} shouldTranslate={false} />
                        <FormGroup margin="tiny">
                          <Textarea
                            id={`custom-field-${customField.customFieldId}`}
                            value={customField.value ? customField.value : ""}
                            onChange={(evt: any) => dispatch(setCustomFieldValue(index, evt.target.value))}
                            maxlength={MAX_MULTI_LINE_INPUT}
                            className={`${!!errors["CustomField" + customField.customFieldId] ? "personal-details__custom-fields__multiline--error" : ""}`}
                          />
                        </FormGroup>
                        <Text size="normal" content={customField.description} shouldTranslate={false} noMargin />
                      </FormGroup>
                    );
                  case CustomFieldType.NumbersField:
                    return getCustomFieldPreLoaded(preLoadedCustomFields, customField).isValid ? null : (
                      <FormGroup key={index}>
                        <FormLabel content={customField.label} shouldTranslate={false} />
                        <FormGroup margin="tiny">
                          <Input
                            id={`custom-field-${customField.customFieldId}`}
                            value={customField.value ? customField.value : ""}
                            onChange={(evt: any) => dispatch(setCustomFieldValue(index, evt.target.value))}
                            isError={!!errors["CustomField" + customField.customFieldId]}
                            errorMsg={errors["CustomField" + customField.customFieldId] ? counterpart(errors["CustomField" + customField.customFieldId]) : ""}
                            type="number"
                          />
                        </FormGroup>
                        <Text size="normal" content={customField.description} shouldTranslate={false} noMargin />
                      </FormGroup>
                    );
                  case CustomFieldType.CheckboxField:
                    return getCustomFieldPreLoaded(preLoadedCustomFields, customField).isValid ? null : (
                      <FormGroup key={index}>
                        <Checkbox
                          id={`custom-field-${customField.customFieldId}`}
                          className={!!errors["CustomField" + customField.customFieldId] ? "personal-details__custom-fields__checkbox--error" : ""}
                          checked={customField.value}
                          onClick={() => dispatch(setCustomFieldValue(index, !customField.value))}
                          value=""
                          content={
                            <div className="personal-details__custom-fields__checkbox">
                              <Text content={customField.label} size="normal" shouldTranslate={false} noMargin />
                              <Text content={customField.description} noMargin shouldTranslate={false} size="normal" />
                            </div>
                          }
                          noMargin
                        />
                      </FormGroup>
                    );

                  case CustomFieldType.DatePickerField:
                    return getCustomFieldPreLoaded(preLoadedCustomFields, customField).isValid ? null : (
                      <FormGroup key={index}>
                        <FormLabel content={customField.label} shouldTranslate={false} />
                        <FormGroup margin="tiny">
                          <DatePicker
                            className={!!errors["CustomField" + customField.customFieldId] ? "personal-details__custom-fields__date--error" : ""}
                            date={customField.value ? moment(new Date(customField.value)) : null}
                            onChange={(date: Moment | null) => date && dispatch(setCustomFieldValue(index, date.format()))}
                          />
                        </FormGroup>
                        <Text size="normal" content={customField.description} shouldTranslate={false} noMargin />
                      </FormGroup>
                    );
                  case CustomFieldType.DropdownField:
                    const options = customField.options.split(",").map((val: string) => ({ label: val, value: val }));
                    return getCustomFieldPreLoaded(preLoadedCustomFields, customField).isValid ? null : (
                      <FormGroup key={index}>
                        <FormLabel content={customField.label} shouldTranslate={false} />
                        <FormGroup margin="tiny">
                          <Dropdown
                            placeholder={counterpart("CUSTOM_FIELDS_DROPDOWN_OPTIONS_SELECT")}
                            className={!!errors["CustomField" + customField.customFieldId] ? "personal-details__custom-fields__dropdown--error" : ""}
                            options={options}
                            value={customFields[index] && customFields[index].value ? { label: customFields[index].value, value: customFields[index].value } : undefined}
                            onChange={(opt: any) => opt && dispatch(setCustomFieldValue(index, opt.value))}
                          />
                        </FormGroup>
                        <Text size="normal" content={customField.description} shouldTranslate={false} noMargin />
                      </FormGroup>
                    );
                  default:
                    return <Text content="Not implemented component" shouldTranslate={false} />;
                }
              })}
            </div>
          </div>
        </>
      </Panel>


    </PageWrapper>
  );
};

export default PersonalDetailsStep3