import React, { useLayoutEffect } from "react";
import "./SelectPlanStep.scss";
import FormLabel from "../ui/form-label/FormLabel";
import Text from "../ui/text/Text";
import Panel from "../ui/panel/Panel";
import Modal from "../ui/modal/Modal";
import CyclePriceInfo from "./cycle-price-info/CyclePriceInfo";
import PlanCycles from "./plan-cycles/PlanCycles";
import SeeMoreText from "../ui/see-more-text/SeeMoreText";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import { setPlan, setCycle, setShowPricingInfo, setNumberOfUnits, setValidUnitRange, setSelectPlanCurrentScreen, setProduct, setActivePlanId } from "../../actions/selectPlanActions";
import { PaymentGatewayTypeId, ITier, IPlanWithCycles, PricingModelType, ICycle, VisibilityType } from "../../models/Product";
import { getPriceOfPerUnitCycle, getTierPrice, getCurrencyFromCycle } from "../../utils/planUtils";
import { setCardHolderName, setCardExpiryYear, setCardExpiryMonth, setPaymentGatewayType, setPaymentCurrentScreen } from "../../actions/paymentActions";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { SelectPlanScreen } from "../../models/SelectPlan";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { setAddOns, setAllowances } from "../../actions/addOnActions";
import { IAddOn, IAllowance, IAddOnPricingModel, IAllowancePricingModel } from "../../models/AddOnAllowance";
import { IAddOnReducer } from "../../reducers/addOnReducer";
import LoadingAnimation from "../ui/loading-animation/LoadingAnimation";
import CollapsiblePanel from "../ui/collapsible-panel/CollapsiblePanel";
import { setNavigationStep } from "../../actions/globalActions";
import { setPersonalDetailsCurrentScreen } from "../../actions/personalDetailsActions";
import { NavigationBarSteps } from "../../models/NavigationBar";
import { PersonalDetailsScreen } from "../../models/PersonalDetails";
import SystemInfo from "../ui/system-info/SystemInfo";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";
import { setAppliedCoupons } from "../../actions/discountCouponActions";
import UpdateSubscription from "./update-subscription/UpdateSubscription";
import { IChangePlanReducer } from "../../reducers/changePlanReducer";
import { setChangePlanField, setIsUpdateSubscription } from "../../actions/changePlanActions";
import { IRetentionReducer } from "../../reducers/retentionReducer";
import { RetentionScreen } from "../../models/Retention";
import history from "../../utils/history";
import { getContractMinimumTermInfo, getPaymentScreenBySourceType } from "../../utils/commonUtils";
import { ICustomerSubscription } from "../../models/Customer";
import { ChangePlanScreen } from "../../models/ChangePlan";
import { BillsbyDataAction } from "../../models/BillsbyData";
import { sendEvent, CROSS_DOMAIN_EVENT } from "../../utils/crossDomainEvents";
import { IPaymentReducer } from "../../reducers/paymentReducer";

interface ISelectPlanStep2 {
  nextBilling?: string;
  onSubmit?: () => void;
  btnText?: string;
  shouldTranslateBtnText?: boolean;
  btnDisabled?: boolean;
  btnBackCallback?: () => void,
  btnBackHidden?: boolean,
  isNewPlan?: boolean;
  warningMessage?: string;
  /* optional heading text used for example in retention */
  customizedHeading?: string;
  customizedSubText?: string;
  /* optional button under the continue wrapper button */
  bottomContent?: JSX.Element;
}

const SelectPlanStep2: React.FC<ISelectPlanStep2> = ({
  isNewPlan = false,
  nextBilling,
  onSubmit,
  btnText = "SELECT_PLAN_CONTINUE",
  shouldTranslateBtnText = true,
  btnDisabled,
  btnBackCallback,
  btnBackHidden,
  warningMessage,
  customizedHeading,
  customizedSubText,
  bottomContent
}) => {
  const {
    plan,
    product,
    plansDropdown,
    plansDropdownAvailable,
    planId,
    cycleId,
    cycle,
    isFetchingPlans,
    productId,
    showMorePricingInfo,
    numberOfUnits,
    activePlanId
  } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);

  const selectPlanState = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer)
  const globalReducer = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const retentionState = useSelector<AppState, IRetentionReducer>(state => state.retentionReducer)
  const { preloadedAddOns, preloadedAllowances, addOns, allowances, allowancesOverage } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer)
  const { currentSubscription, subscriptionOutstandingBalance } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const { isUpdateSubscription } = useSelector<AppState, IChangePlanReducer>(state => state.changePlanReducer)
  const { hasCustomerPaymentOnFile } = useSelector<AppState, IPaymentReducer>(state => state.paymentReducer)
  const { isPendingContractMinimumTermEnd, contractMinimumTermEnd } = getContractMinimumTermInfo(currentSubscription as ICustomerSubscription);

  const isRetentionChangePlan = retentionState.currentScreen && retentionState.currentScreen === RetentionScreen.changePlan;
  const retentionText = retentionState.planChange && retentionState.planChange.getText();
  const retentionSubText = retentionState.planChange && retentionState.planChange.getSubText();
  const scrollableParentContainer = document.querySelector(".page-wrapper__body-content");
  const isCyclePreloaded = !!productId && !!planId && !!cycleId;
  btnDisabled = btnDisabled || !selectPlanState.cycle;
  const { checkoutConfig, branding, companyDomain, billsbyAction, preloadedCustomerData } = globalReducer;
  //const hasPreloadedAddOnsOrAllowances = !!preloadedAddOns.length || !!preloadedAllowances.length;
  const hasPreloadedAddOns = !!preloadedAddOns.length;
  const hasPreloadedAllowances = !!preloadedAllowances.length;

  const dispatch = useDispatch<Function>()

  if(!btnBackCallback) {
    btnBackCallback = (() => {
      dispatch(setProduct(null));
      dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PRODUCT));
    })
  }

  const filterAllowancesAddOns = (addonsAllowances: Array<IAddOn | IAllowance>, currentCycle: ICycle) => {
    return addonsAllowances.filter(addon => (addon.pricingModels as Array<IAddOnPricingModel | IAllowancePricingModel>).some(p => {
      return (p.frequency === currentCycle.pricingModel.frequency
        && p.frequencyType === currentCycle.pricingModel.frequencyType
        && p.currency.isoCode === currentCycle.currency);
    }))
  }

  useLayoutEffect(() => {
    //even a hidden plan can be pre-selected hence why we filtering through all the plans including the hidden ones
    const preselectedPlan = plansDropdown.find(plan => plan.value.planId === planId);

    // check if plan/cycle are preselected
    if (preselectedPlan) {
      //dispatch(setPlan(preselectedPlan));
      onPlanChange(preselectedPlan);
      dispatch(setPaymentGatewayType(preselectedPlan.value.paymentGatewayType));
      dispatch(setPaymentCurrentScreen(getPaymentScreenBySourceType(preselectedPlan.value.paymentSourceTypes, preloadedCustomerData, hasCustomerPaymentOnFile)));
      // even a hidden cycle can be pre-selected
      const preselectedCycle = preselectedPlan.value.cycles.length === 1
        ? preselectedPlan.value.cycles[0]
        : preselectedPlan.value.cycles.find((cycle) => cycle.cycleId === cycleId);

      if (preselectedCycle) {
        dispatch(setCycle(preselectedCycle));
        if (preselectedPlan && (preselectedPlan.value.addons.length || preselectedPlan.value.allowances.length)) {
          const addOns = filterAllowancesAddOns([...preselectedPlan.value.addons], preselectedCycle);
          const allowances = filterAllowancesAddOns([...preselectedPlan.value.allowances], preselectedCycle);
          if (!hasPreloadedAddOns) {
            dispatch(setAddOns(addOns));
          }
          if (!hasPreloadedAllowances) {
            dispatch(setAllowances(allowances));
          }
        }
        const isComplexPricingModelPlan = preselectedPlan.value.pricingModelType !== PricingModelType.FlatFee;
        const preselectedUnits = numberOfUnits;

        if ((!preselectedPlan.value.addons.length && !preselectedPlan.value.allowances.length)
          && (!isComplexPricingModelPlan || (isComplexPricingModelPlan && preselectedUnits))) {
          dispatch(setNavigationStep(NavigationBarSteps.PERSONAL_DETAILS));
          dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.MAIN_DATA));
        }
        if ((preselectedPlan.value.addons.length || preselectedPlan.value.allowances.length)
          && (!isComplexPricingModelPlan || (isComplexPricingModelPlan && preselectedUnits))) {
          dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ADD_ON));
        }
      }
      return;
    }

    // check if only one plan/cycle is available in the public ones we're not going to consider here the hidden ones
    if (plansDropdownAvailable.length === 1 && plansDropdownAvailable[0].value.pricingModelType === PricingModelType.FlatFee) {
      //dispatch(setPlan(plansDropdownPublic[0]));
      onPlanChange(plansDropdownAvailable[0]);
      dispatch(setPaymentGatewayType(plansDropdownAvailable[0].value.paymentGatewayType));
      dispatch(setPaymentCurrentScreen(getPaymentScreenBySourceType(plansDropdownAvailable[0].value.paymentSourceTypes, preloadedCustomerData, hasCustomerPaymentOnFile)));
      const publicCycles = plansDropdownAvailable[0].value.cycles.filter(c => c.visibility === VisibilityType.Public);
      if (publicCycles.length === 1) {
        dispatch(setCycle(publicCycles[0]));
        if (plansDropdownAvailable[0] && (plansDropdownAvailable[0].value.addons.length || plansDropdownAvailable[0].value.allowances.length)) {
          const addOns = filterAllowancesAddOns([...plansDropdownAvailable[0].value.addons], publicCycles[0]);
          const allowances = filterAllowancesAddOns([...plansDropdownAvailable[0].value.allowances], publicCycles[0]);
          if (!hasPreloadedAddOns) {
            dispatch(setAddOns(addOns));
          }
          if (!hasPreloadedAllowances) {
            dispatch(setAllowances(allowances));
          }
        }
        if (!plansDropdownAvailable[0].value.addons.length && !plansDropdownAvailable[0].value.allowances.length) {
          dispatch(setNavigationStep(NavigationBarSteps.PERSONAL_DETAILS));
          dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.MAIN_DATA));
        }
        else {
          dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ADD_ON));
        }
        return;
      }
    }

    //if selected product only has single plan, set it to default selected plan
    if (plansDropdownAvailable.length === 1) {
      dispatch(setActivePlanId(plansDropdownAvailable[0].value.planId))
      onPlanChange(plansDropdownAvailable[0]);
    }

  }, [plansDropdown, plansDropdownAvailable]);

  useLayoutEffect(() => {
    isPendingContractMinimumTermEnd && dispatch(setChangePlanField("currentScreen", ChangePlanScreen.END_CONTRACT));
  }, [isPendingContractMinimumTermEnd])

  useLayoutEffect(() => {
    if (plan && plan.value && cycle) {
      const addOns = filterAllowancesAddOns([...plan.value.addons], cycle);
      const allowances = filterAllowancesAddOns([...plan.value.allowances], cycle);
      if (!hasPreloadedAddOns) {
        dispatch(setAddOns(addOns));
      }
      if (!hasPreloadedAllowances) {
        dispatch(setAllowances(allowances));
      }
    }

  }, [plan, cycle]);

  const resetSubscriptionData = () => {
    dispatch(setAppliedCoupons([]));
    dispatch(setCycle(null));
    dispatch(setPlan(null));
    dispatch(setNumberOfUnits(1))
  }

  const setPlanPaymentGateway = (currentPlan: { label: string; value: IPlanWithCycles }) => {
    if (currentPlan.value.paymentGatewayType !== PaymentGatewayTypeId.SpreedlyTest) {
      dispatch(setPaymentGatewayType(currentPlan.value.paymentGatewayType));
      dispatch(setPaymentCurrentScreen(getPaymentScreenBySourceType(currentPlan.value.paymentSourceTypes, preloadedCustomerData, hasCustomerPaymentOnFile)));
      dispatch(setCardHolderName(""));
      dispatch(setCardExpiryMonth(""));
      dispatch(setCardExpiryYear(""));
    }
  }

  const _onSubmit = onSubmit || (() => {
    dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ADD_ON));
  });


  const onPlanChange = (currentPlan: { label: string; value: IPlanWithCycles } | null) => {
    if (currentPlan === null || !numberOfUnits) {
      dispatch(setPlan(currentPlan));
      currentPlan && setPlanPaymentGateway(currentPlan);
      dispatch(setNumberOfUnits(""));
      return;
    }


    setPlanPaymentGateway(currentPlan);
    currentPlan.value.cycles.forEach(cycle => {
      if (currentPlan.value.pricingModelType === PricingModelType.FlatFee) {
        return;
      }
      const freeQuantity = !cycle.pricingModel.freeQuantity ? 0 : cycle.pricingModel.freeQuantity;
      const { isAtBeginning, currency } = getCurrencyFromCycle(cycle);
      if (currentPlan.value.pricingModelType === PricingModelType.PerUnit) {
        cycle.pricingModel.priceFormatted = `${isAtBeginning ? currency : ""}${getPriceOfPerUnitCycle(cycle.pricingModel.price,
          numberOfUnits,
          freeQuantity
        )}${!isAtBeginning ? currency : ""}`;
      } else {
        cycle.pricingModel.priceFormatted = `${isAtBeginning ? currency : ""}${getTierPrice(currentPlan.value.pricingModelType,
          cycle.pricingModel.tiers as Array<ITier>,
          numberOfUnits,
          freeQuantity
        )}${!isAtBeginning ? currency : ""}`;
      }
    });

    dispatch(setPlan(currentPlan));

    //if (!(currentPlan.value.pricingModelType === PricingModelType.PerUnit || currentPlan.value.pricingModelType === PricingModelType.FlatFee))
    validateNumberOfUnits(currentPlan, numberOfUnits);
  };

  const getTiers = (cycle: any) => {
    if (!cycle.pricingModel.tiers) return null;

    return cycle.pricingModel.tiers;
  };

  const getTitle = () => {
    if (isCyclePreloaded && isFetchingPlans) {
      return undefined
    }

    if (isRetentionChangePlan) {
      return "CANCEL_SUBSCRIPTION_TITLE"
    }

    if (currentSubscription && isUpdateSubscription) {
      return "CHANGE_SUBSCRIPTION_TITLE"
    }

    if (subscriptionOutstandingBalance?.getAmount()) {
      return "CHANGE_PLAN_BTN"
    }

    return "CHOOSE_PLAN_TITLE"
  }

  const getSubtitle = () => {
    if (isCyclePreloaded && isFetchingPlans) {
      return undefined
    }

    if (!isRetentionChangePlan && currentSubscription && !isUpdateSubscription && !subscriptionOutstandingBalance?.getAmount()) {
      return "CHOOSE_PLAN_SUBTITLE"
    }

    return undefined
  }

  const validateNumberOfUnits = (plan: { label: string; value: IPlanWithCycles }, units: number) => {
    if (plan.value.pricingModelType === PricingModelType.FlatFee || plan.value.pricingModelType === PricingModelType.PerUnit) {
      return dispatch(setValidUnitRange(true));
    }

    const tiersCoverage = plan.value.cycles.map((cycle) => {
      const tiers = getTiers(cycle);
      return tiers.filter((tier: ITier) => tier.finish >= units).length > 0;
    });

    const isValidRange = tiersCoverage.every((i) => i);
    if (!isValidRange) dispatch(setCycle(undefined));

    dispatch(setValidUnitRange(isValidRange));
  };

  const isPlanNotOnSale = () => {
    if (currentSubscription) {
      const currentPlan = plansDropdown.find(p => p.value.planId === currentSubscription.planId)
      return currentPlan && (currentPlan.value.visibility !== VisibilityType.Public || currentPlan.value.isDeleted);
    }

    return false;
  };

  const renderPlanSelection = () => {
    //Added loading animation here to prevent multiple rendering of UpdateSubscription component
    if (isFetchingPlans) {
      return <LoadingAnimation />
    }
    if (subscriptionOutstandingBalance?.getAmount()) {
      return <SystemInfo className="select-plan__outstanding-balance"
        title="ACCOUNT_MANAGEMENT_CHANGEPLAN_ERROR_INVOICE_TITLE"
        text="ACCOUNT_MANAGEMENT_CHANGEPLAN_ERROR_INVOICE_SUBTITLE"
        type="warning"
        translateWith={{ planLabel: checkoutConfig?.terminologyPlanSingular, outstandingBalance: subscriptionOutstandingBalance.getFormattedAmount() }}
      />
    }

    return (
      <div className={`select-plan__list${isRetentionChangePlan ? " select-plan__list--retention" : ""}`}>
        {isRetentionChangePlan && (
          <Panel id="select-plan__list">
            <Text content={retentionText || "CANCEL_SUBSCRIPTION_PLANS_TITLE"} noMargin translateWith={{ planLabel: checkoutConfig && checkoutConfig.terminologyPlanPlural }} shouldTranslate={!retentionText} weight="bold"></Text>
            {
              !isRetentionChangePlan ? <Text content="CANCEL_SUBSCRIPTION_PLANS_SUBTITLE" noMargin translateWith={{ planLabel: checkoutConfig && checkoutConfig.terminologyPlanSingular }} ></Text>
                : retentionSubText ? <Text content={retentionSubText} noMargin shouldTranslate={false} ></Text>
                  : <div />
            }
          </Panel>
        )}
        {isPlanNotOnSale() && (
          <SystemInfo title="" text="CHANGE_PLAN_PLAN_NOT_ON_SALE_ERROR" translateWith={{ planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular }} type="warning"></SystemInfo>
        )}
        {
          plansDropdownAvailable.map((plan, idx) => {
            return (
              <CollapsiblePanel
                id="select-plan__product-item-collapsible"
                isModal
                scrollIntoViewIfExpanded
                parentContainer={scrollableParentContainer}
                modalPosition={plansDropdownAvailable.length > 2 && plansDropdownAvailable.length - 1 === idx ? "bottom" : "top"}
                isLocked={(!!activePlanId && activePlanId !== plan.value.planId)}
                isExpanded={(!!activePlanId && activePlanId === plan.value.planId) || (plansDropdownAvailable.length === 1)}
                isClickable
                className="select-plan__product-item"
                key={plan.label}
                image={plan.value.planImageUrl}
                onClick={() => {
                  // when preloading a plan it is automatiacally expanded and cannot be collapsed
                  if (plansDropdownAvailable.length === 1) {
                    return;
                  }

                  dispatch(setActivePlanId(activePlanId === null ? plan.value.planId : null));
                  resetSubscriptionData()
                  onPlanChange(plan);

                }}
                expandableContent={<>
                  {((!!activePlanId && activePlanId === plan.value.planId) || (plansDropdownAvailable.length === 1 && !currentSubscription))
                    && <PlanCycles hasSelectedCycle={!btnDisabled} onSelectCycle={_onSubmit} />}
                </>}
              >
                <div className="select-plan__product-item__description">
                  <FormLabel content={plan.label} shouldTranslate={false} size="large" weight="bold" noMargin />
                  {plan?.value?.description?.length > 130
                    ? <SeeMoreText content={plan.value.description} maxChar={130} shouldTranslateContent={false} noMargin></SeeMoreText>
                    : <Text content={plan.value.description} shouldTranslate={false} noMargin />}
                </div>

                {/* <CollapsiblePanel.ExpandableContent>
                  {
                    ((!!activePlanId && activePlanId === plan.value.planId) || (plansDropdownAvailable.length === 1 && !currentSubscription))
                    && <PlanCycles hasSelectedCycle={!btnDisabled} onSelectCycle={_onSubmit} />
                  }
                </CollapsiblePanel.ExpandableContent> */}
              </CollapsiblePanel>
            )
          })
        }
        <SystemInfo
          title="SELECTED_PRODUCT_HAVE_NO_PLANS"
          type="warning"
          isVisible={!plansDropdownAvailable.length}
        />
      </div>
    )

  }

  return (
    <PageWrapper
      className='select-plan select-plan-step2'
      btnText={isRetentionChangePlan ? "RETENTION_KEEP_MY_PLAN" : btnText}
      btnTextTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
      shouldTranslateText={isRetentionChangePlan ? true : shouldTranslateBtnText}
      btnCallback={
        isRetentionChangePlan
          ? () => billsbyAction === BillsbyDataAction.CANCEL_PLAN ? sendEvent(CROSS_DOMAIN_EVENT.CLOSE) : history.goBack()
          : isPendingContractMinimumTermEnd ? () => dispatch(setChangePlanField("currentScreen", ChangePlanScreen.END_CONTRACT))
            : currentSubscription && isUpdateSubscription
              ? () => {
                dispatch(setIsUpdateSubscription(false));
                resetSubscriptionData();
                dispatch(setActivePlanId(null));

                if (plansDropdownAvailable.length === 1) {
                  dispatch(setActivePlanId(plansDropdownAvailable[0].value.planId))
                  onPlanChange(plansDropdownAvailable[0]);
                }
              }
              : undefined
      }
      // btnDisabled={btnDisabled}
      bottomStickyContent={bottomContent}
      btnBackCallback={() => {
        if (!isRetentionChangePlan && currentSubscription && !isUpdateSubscription) {
          resetSubscriptionData();
          // dispatch(setActivePlanId(null));
          dispatch(setIsUpdateSubscription(true))
        } else {
          btnBackCallback?.()
        }
      }}
      titlePosition={"centered"}
      subTitle={getSubtitle()}
      subTitleTranslateWith={{ planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular, addonLabel: checkoutConfig?.terminologyAddonPlural, allowanceLabel: checkoutConfig?.terminologyAllowancePlural }}
      btnBackHidden={btnBackHidden || (!currentSubscription && (!!productId || selectPlanState.products.length === 1))}
      title={getTitle()}
      titleTranslateWith={{ planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanPlural, subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
    >
      <>
        {currentSubscription && isUpdateSubscription
          ? <UpdateSubscription btnDisabled={btnDisabled} onSubmit={_onSubmit} />
          : renderPlanSelection()}

      </>
      <Modal
        className='cycle-price-info__modal'
        buttonColor={branding.highlightColor}
        isOpen={showMorePricingInfo}
        title=''
        shouldTranslateTitle={false}
        onRequestClose={() => dispatch(setShowPricingInfo(false))}
        key={"modal"}>
        <CyclePriceInfo />
      </Modal>
      {/* <TaxLabel taxCountry={"Sample country"} taxName={"Sample name"} taxPercentage={"100%"}></TaxLabel> */}
    </PageWrapper>
  );
};

export default SelectPlanStep2
