/**
 * @fileoverview gRPC-Web generated client stub for billing.public.customer.invoice
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  GetInvoiceResponse,
  GetInvoicesRequest} from "./invoice_pb";

export class InvoiceServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetInvoices = new grpcWeb.AbstractClientBase.MethodInfo(
    GetInvoiceResponse,
    (request: GetInvoicesRequest) => {
      return request.serializeBinary();
    },
    GetInvoiceResponse.deserializeBinary
  );

  getInvoices(
    request: GetInvoicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetInvoiceResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.customer.invoice.InvoiceService/GetInvoices",
      request,
      metadata || {},
      this.methodInfoGetInvoices,
      callback);
  }

}

