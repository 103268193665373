import React from "react";
import Text from "../text/Text";
import "./Title.scss";

interface ITitle {
  size?: "normal" | "small",
  content: string,
  shouldTranslate?: boolean,
  className?: string,
  noMargin?: boolean
}

const Title: React.FC<ITitle> = React.memo(({ size = "normal", content, shouldTranslate = true, className = "", noMargin = false }) => {
  
  return (
    <Text content={content} className={`title ${size === "small" ? "title--small" : ""} ${className}`} shouldTranslate={shouldTranslate} noMargin={noMargin} /> 
  )
})

export default Title;