import { IAddOn, IAllowance } from "./AddOnAllowance";
import { PaymentMethodType } from "./Payment";

export interface IProduct extends IBaseProduct {
  productId: number,
  isAdditionalEmailRequired: boolean,
  isMarketingConsentRequired: boolean,
  isPhoneNumberRequired: boolean,
  isShippingAddressRequired: boolean,
  isWorldWideAddressAllowed: boolean,
  isBillingAddressValidationRequired: boolean,
  isShippingAddressValidationRequired: boolean,
  productImageUrl?: string
}

export interface IBaseProduct {
  name: string,
  visibility: VisibilityType,
  billingCurrency: string,
  displayName: string,
  description: string,
  typeOfProduct: ProductType,
  isDeleted: boolean
}

/*export enum VisibilityType {
  Public = 0,
  Hidden = 1,
  Internal = 2,
  OffSale = 3
}*/

export enum VisibilityType {
  Public = "Public",
  Hidden = "Hidden",
  Internal = "Internal",
  OffSale = "OffSale"
}

export enum ProductType {
  TieredPlan = 1
}

export enum PricingModelType {
  FlatFee = "FlatFee",
  Reserved = "Reserved",
  PerUnit = "PerUnit",
  Tiered = "Tiered",
  Volume = "Volume",
  Ranged = "Ranged"
}

export enum FrequencyType {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Yearly = "Yearly"
}

export enum PaymentGatewayTypeId {
  SpreedlyTest = "SpreedlyTest",
  Stripe = "Stripe"
}

export interface IProductWithPlan extends IProduct {
  plans: IPlan[]
}

export interface IBasePlan {
  name: string,
  displayName: string,
  description: string,
  pricingModelType: PricingModelType
}


export interface IPlan extends IBasePlan {
  planId: number,
  productId: number,
  paymentGatewayType: PaymentGatewayTypeId,
  paymentSourceTypes: Array<PaymentMethodType>,
  redirectUrl: string,
  visibility: VisibilityType,
  isDeleted: boolean,
  currency: string,
  planImageUrl?: string
}

export interface IPlanWithCycles extends IPlan {
  cycles: ICycle[],
  addons: Array<IAddOn>,
  allowances: Array<IAllowance>
}

export interface IPricingModel {
  pricingModelId?: number,
  price: number,
  priceFormatted: string,
  frequencyType: FrequencyType,
  frequency: number,
  freeTrial?: number,
  freeTrialFrequencyType?: FrequencyType,
  contractTerm?: number,
  contractTermFrequencyType?: FrequencyType,
  setupFeePrice?: number,
  setupFeePriceFormatted?: string,
  tiers? : Array<ITier>,
  freeQuantity?: number
  units?: number
}

export interface ITier {
  start: number,
  finish: number,
  price: number,
  priceFormatted: string
}

export interface IBaseCycle {
  pricingModel: IPricingModel
}

export interface ICycle extends IBaseCycle {
  billingDateType: number,
  cycleId: number,
  planId: number,
  currency: string,
  visibility: VisibilityType
}