import { IPricingModel } from "./Product"

export interface IPricingModelSubscription extends IPricingModel {
  billingDateType: number,
  fixedBillingDateDay: number,
  proRateOption: number
}

export interface ICustomerSubscription {
  subscriptionUniqueId: string,
  productId: number,
  productName: string,
  planId: number,
  planName: string,
  status: SubscriptionStatusType,
  currency: string,
  nextBilling: string,
  createdOn: string,
  priceModel: IPricingModelSubscription,
  cycleId: number,
  cycleIdNext: number | null,
  planIdNext: number | null,
  units?: number,
  unitsNext?: number
  contractMinimumTermEnd?: string,
  planDisplayName?: string,
  productDisplayName?: string
}

export enum SubscriptionStatusType {
  ACTIVE = "Active",
  CANCELLED = "Cancelled",
  SUSPENDED = "Suspended",
  PAUSED = "Paused",
  INTRIAL = "InTrial",
}