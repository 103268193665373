import React, { useMemo } from "react";
import "./CyclePriceInfo.scss";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import { ISelectPlanReducer } from "../../../reducers/selectPlanReducer";
import { getCycleFrequencyText, getFlatFeeAddOnPriceText, getContractTermText, getSetupFeeText } from "../../../utils/planUtils";
import { ITier, PricingModelType, ICycle } from "../../../models/Product";
import TieredCyclePriceInfo from "./TieredCyclePriceInfo";
import RangedCyclePricingInfo from "./RangedCyclePricingInfo";
import VolumeCylePricingInfo from "./VolumeCylePricingInfo";
import { IAddOn, AddOnPricingModelType, IAllowance } from "../../../models/AddOnAllowance";
import { useSelector } from "react-redux";
import { AppState } from "../../..";
import { IAddOnReducer } from "../../../reducers/addOnReducer";
import { IGlobalReducer } from "../../../reducers/globalReducer";


const CyclePriceInfo: React.FC = () => {
  const { plan, cycle, selectedPricingModel } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { checkoutConfig, branding } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { addOns, allowances } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);

  const forceAddOnAllowances = useMemo(() => {
    const combinedAddOnAllowances = [...addOns, ...allowances];
    return combinedAddOnAllowances.filter(i => i.isForced);
  }, [allowances, addOns]);

  const getPlanName = () => {
    if (!plan)
      return "";
    return plan.label;
  }

  const getSetupFee = () => {
    if (!cycle || !cycle.pricingModel.setupFeePriceFormatted)
      return "";
    return getSetupFeeText(cycle.pricingModel.setupFeePriceFormatted, checkoutConfig);
  }

  const getContractTerm = () => {
    if (!cycle)
      return "";

    let contractTerm = getContractTermText(cycle.pricingModel.contractTerm, cycle.pricingModel.contractTermFrequencyType);
    if (!cycle.pricingModel.setupFeePriceFormatted)
      return contractTerm.toLowerCase();

    return contractTerm;
  }

  const getDescription = (tier: ITier) => {
    if (!plan)
      return null;

    let frequency = "";
    if (selectedPricingModel) {
      frequency = getCycleFrequencyText(selectedPricingModel.frequency, selectedPricingModel.frequencyType);
    }
    if (!checkoutConfig) {
      return;
    }

    switch (plan.value.pricingModelType) {
      case PricingModelType.Tiered:
        return <TieredCyclePriceInfo tier={tier} checkoutConfig={checkoutConfig} frequency={frequency}
          unit={checkoutConfig.terminologyUnitSingular} units={checkoutConfig.terminologyUnitPlural} />
      case PricingModelType.Ranged:
        return <RangedCyclePricingInfo tier={tier} checkoutConfig={checkoutConfig} frequency={frequency}
          unit={checkoutConfig.terminologyUnitSingular} units={checkoutConfig.terminologyUnitPlural} />
      case PricingModelType.Volume:
        return <VolumeCylePricingInfo tier={tier} checkoutConfig={checkoutConfig} frequency={frequency}
          unit={checkoutConfig.terminologyUnitSingular} units={checkoutConfig.terminologyUnitPlural} />
    }
  }

  const getPricingInfoDescription = () => {
    if (!plan)
      return "SELECT_PLAN_TIERED_CYCLE_DESCRIPTION";

    switch (plan.value.pricingModelType) {
      case PricingModelType.Tiered:
        return "SELECT_PLAN_TIERED_CYCLE_DESCRIPTION";
      case PricingModelType.Ranged:
        return "SELECT_PLAN_RANGED_CYCLE_DESCRIPTION";
      case PricingModelType.Volume:
        return "SELECT_PLAN_VOLUME_CYCLE_DESCRIPTION";
      default:
        return "SELECT_PLAN_TIERED_CYCLE_DESCRIPTION";
    }
  }

  const getFlatFeeAddOns = (addOns: Array<IAddOn | IAllowance>) => {
    return addOns.filter(i => i.pricingModelType === AddOnPricingModelType.AddonFlatFee) as Array<IAddOn>;
  }

  const hasUnitBasedForcedAddOns = (addOns: Array<IAddOn | IAllowance>) => {
    return addOns.some(i => i.isForced && i.pricingModelType !== AddOnPricingModelType.AddonFlatFee);
  }

  return (
    <div>
      <Text className="cycle-price-info__plan-name" content={getPlanName()} shouldTranslate={false} style={{ color: branding.highlightColor }} />
      {
        getSetupFee()
          ? <Text className="cycle-price-info__plan-info" content={`${getSetupFee()}`} shouldTranslate={false} noMargin />
          : null
      }

      {
        getFlatFeeAddOns(forceAddOnAllowances).length > 0
          ? getFlatFeeAddOns(forceAddOnAllowances)
            .map(addOn => (
              <Text
                key={addOn.id}
                className="cycle-price-info__plan-info"
                content="ADD_ONS_FLAT_FEE"
                translateWith={{
                  name: addOn.displayName ? addOn.displayName : addOn.name,
                  priceFormatted: getFlatFeeAddOnPriceText(addOn, cycle as ICycle)
                }}
                noMargin
              />
            ))
          : null
      }

      {hasUnitBasedForcedAddOns(addOns) && <Text className="cycle-price-info__plan-info" content="ADD_ONS_ADDITIONAL_FORCED_ADD_ON" translateWith={{ addonLabel: checkoutConfig?.terminologyAddonPlural }} noMargin />}

      <Text size='small' className="cycle-price-info__plan-info" content={getContractTerm()} shouldTranslate={false} noMargin />

      {/* <Text className="cycle-price-info__plan-info" content="SELECT_PLAN_TIERED_CYCLE_MINIMUM_TERM" noMargin /> */}

      <Text className="cycle-price-info__message" translateWith={{ planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular }} content={getPricingInfoDescription()} noMargin />

      {selectedPricingModel && selectedPricingModel.tiers && selectedPricingModel.tiers.map((tier, index) =>
        <Panel id="cycle-price-info__tiered-price__panel" noMargin className="cycle-price-info__tiered-price__panel" key={`tier-${index}`}>
          {getDescription(tier)}
        </Panel>
      )}
    </div>
  )
}

export default CyclePriceInfo