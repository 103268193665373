import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import AchForm from "../../components/ach-form/AchForm";
import { AccountManagementPermissions } from "../../models/AccountManagement";
import { IACHReducer } from "../../reducers/achReducer";
import { IGlobalReducer } from "../../reducers/globalReducer";
import history from "../../utils/history";
import PageWrapper from "../page-wrapper/PageWrapper";
import { IUpdatePaymentDetails } from "../update-payment-details/UpdatePaymentDetails";
import "./UpdateAchDetails.scss";

const UpdateAchDetails: React.FC<IUpdatePaymentDetails> = ({
  title = "UPDATE_ACH_DETAILS_TITLE",
  titleTranslateWith,
  titlePosition = "centered",
  btnText = "UPDATE_ACH_DETAILS_BTN",
  btnTextTranslateWith = {},
  shouldTranslateText = true,
  btnPosition = "bottom",
  bottomContent = <div />,
  btnCallback = () => { },
  btnBackCallback,
  onSuccessRedirectToAccountManagement = true,
  redirect = true,
  showCloseIcon = true
}) => {

  const { isOpenMandateForm, accountHolder, isUpdateAchSucceeded, isUpdateAchFailed } = useSelector<AppState, IACHReducer>(state => state.achReducer);
  const { accountManagementPermissions } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const [isSubmitted, setIsSubmitted] = useState(false);

  const goBackCallback = () => {
    if (accountManagementPermissions === AccountManagementPermissions.FULL && history.location.pathname !== "/management") {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }

  useEffect(() => {
    if ((isUpdateAchSucceeded || isUpdateAchFailed) && onSuccessRedirectToAccountManagement && redirect) {
      // redirect + forward history query string
      history.push({ pathname: "/management", search: history.location.search });
    }

  }, [isUpdateAchSucceeded, isUpdateAchFailed, redirect]);

  useEffect(() => {
    setIsSubmitted(false);
  }, [isUpdateAchSucceeded, isUpdateAchFailed, isOpenMandateForm])

  return (
    <PageWrapper
      isLoading={isOpenMandateForm}
      className="update-ach-details"
      btnDisabled={isOpenMandateForm || !accountHolder}
      btnText={btnText}
      btnTextTranslateWith={btnTextTranslateWith}
      shouldTranslateText={shouldTranslateText}
      btnPosition={btnPosition}
      bottomStickyContent={bottomContent}
      btnCallback={() => {
        setIsSubmitted(true)
        btnCallback()
      }}
      btnBackCallback={btnBackCallback || goBackCallback}
      btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION}
      title={title}
      titleTranslateWith={titleTranslateWith}
      titlePosition={titlePosition}
      showCloseIcon={showCloseIcon}
    >
      <AchForm
        type="update-payment"
        isSubmitted={isSubmitted}
        isTaxRequired={false}
      />
    </PageWrapper>
  )
}

export default UpdateAchDetails;