import React from "react";
import logo from "../../images/billsby-logo.svg";
import Text from "../../components/ui/text/Text";
import { sendEvent, CROSS_DOMAIN_EVENT } from "../../utils/crossDomainEvents";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import "./FloatingHeader.scss";
import Icon from "../../components/ui/icon/Icon";

export interface IFloatingHeader {
  btnBackHidden?: boolean,
  btnBackCallback?: () => void,
  showCloseIcon?: boolean,
  isVisible?: boolean,
  className?: string
}

const FloatingHeader: React.FC<IFloatingHeader> = React.memo(({ btnBackCallback, btnBackHidden = false, showCloseIcon = true, 
  isVisible = true, className = "" }) => {

  const  { checkoutConfig, companyDomain } = useSelector((state: AppState) => state.globalReducer);

  if (!checkoutConfig || !isVisible) {
    return null;
  }

  return (
    <div className={`floating-header ${className}`} style={{ top: checkoutConfig.isProgressIndicatorVisible ? 0 : 3 }}>
      {!!btnBackCallback && !btnBackHidden
        ? <Icon icon="far fa-chevron-left" onClick={btnBackCallback} />
        : <div className="empty" />}
      <div className="floating-header__text-block">
        <i className="fas fa-lock" />
        <Text className="floating-header__text-block__text" content="FOOTER_SECURED_TEXT" noMargin />
        <img
          src={logo}
          alt="Billsby"
          className="logo"
          onClick={() => window.open(`https://www.billsby.com?utm_source=billsby_checkout&utm_medium=referral&utm_content=${companyDomain}`, "_blank")}
        />
      </div>
      {showCloseIcon
        ? <Icon icon="far fa-times" onClick={() => sendEvent(CROSS_DOMAIN_EVENT.CLOSE)} />
        : <div className="empty" />
      }
    </div>
  )
})

export default FloatingHeader;