import React from "react";
import "./Input.scss";
import { makeStyles, TextField, withStyles } from "@material-ui/core";

interface IInput {
  id: string,
  /**
   * optional icon to be placed before the actual text
   */
  icon?: string,
  /**
   * show the error state
   */
  isError?: boolean,
  /**
   * show error message
   */
  errorMsg?: string,
  /**
   * type of error icon
   */
  errorIcon?: string,
  /**
   * callback for error icon
   */
  errorCb?: () => {},
  /**
   * classes
   */
  className?: string,
  /**
   * placeholder
   */
  placeholder?: string,
  /**
   * on change hook
   */
  onChange?: (evt: any) => any,
  /*
  *
  */
  onPaste?: (evt: any) => any,
  /**
   * value
   */
  value?: string | number,
  type?: string,
  pattern?: string,
  /**
   * display error with a warning layout
   */
  warninglayout?: boolean,
  required?: boolean,
  autoComplete?: string,
  maxLength?: string,
  style?: any,
  disabled?: boolean,
  showDisabledIcon?: boolean,
  name?: string,
  margin?: "tiny" | "default" | "none"
}
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "&": {
        height: 40
      },
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px !important",
        outline: "none"
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#d22630"
      },
      "& input": {
        color: "#555555",
        fontFamily: "Open Sans",
        fontSize: "14px"
      },
      "&.Mui-disabled": {
        opacity: 0.5,
        pointerEvents: "none"
      }
    },
  },
})(TextField);

const Input: React.FC<IInput> = React.memo(({ disabled = false, showDisabledIcon = false, icon = "", margin = "none", isError = false, errorMsg = "", errorIcon = "exclamation-circle",
  errorCb = () => { }, warninglayout = false, className = "", maxLength, autoComplete, name, id, ...rest }) => {

  const useStyles = makeStyles(theme => ({
    root: {
      "& .MuiOutlinedInput-root": {
        transition: "box-shadow ease 0.3s",
        boxShadow: isError ? "none" : "0px 0px 6px #00000029",
      },
      "&:hover .MuiOutlinedInput-root": {
        boxShadow: "0px 0px 8px #00000045"
      }
    }
  }))
  const dynamicClasses = useStyles();

  const renderLayout = () => {
    return (
      <div className={isError ? (`cc-input cc-input${warninglayout ? "--warning" : "--error"} ${className}`) : `cc-input ${className}`}>
        {icon && <i className={`cc-input__icon ${icon}`} />}
        <input
          type="text"
          className={icon ? "cc-input__field cc-input__field--icon" : "cc-input__field"}
          maxLength={maxLength as any}
          autoComplete={autoComplete}
          name={name}
          disabled={disabled}
          {...rest}
        />
        {isError &&
          <span className={`cc-input__error-container ${warninglayout ? "cc-input__error-container--warning" : ""}`}>
            <i role="button" onClick={errorCb} className={`cc-input__error-icon ${warninglayout ? "far fa-info-circle" : errorIcon}`} tabIndex={-1} />
            <span className="cc-input__error-msg">{errorMsg}</span>
          </span>
        }
      </div>
    );
  }

  const renderMUILayout = () => {
    return (
      <CssTextField
        className={`${dynamicClasses.root} ${className} ${showDisabledIcon ? "input--locked" : ""}`}
        variant="outlined"
        autoComplete={autoComplete}
        disabled={disabled}
        name={name}
        required={rest.required}
        error={isError}
        fullWidth
        helperText={isError && errorMsg}
        //inputProps={{ style: { fontFamily: 'Open Sans', color: '#00000029' } }}
        type={rest.type}
        onChange={rest.onChange}
        onPaste={rest.onPaste}
        value={rest.value}
        placeholder={rest.placeholder}
        label=""
        {...rest}
        id={id}
        size="small"
        margin="none"
      />
    )
  }

  return renderMUILayout();
})

export default Input;
