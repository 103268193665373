/**
 * @fileoverview gRPC-Web generated client stub for billing.public.customer
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";


import {
  CreateAchMandateExistingCustomerRequest,
  CreateAchMandateNewCustomerRequest,
  CreateAchMandateResponse,
  GetCustomerLanguageRequest,
  GetCustomerLanguageResponse,
  GetCustomerPaymentDetailsRequest,
  GetCustomerPaymentDetailsResponse,
  SendPasscodeEmailRequest,
  SendPasscodeEmailResponse,
  StartCheckoutAchMicroDepositRequest,
  StartCheckoutAchMicroDepositResponse,
  UpdateAchPaymentMethodRequest,
  UpdateAchPaymentMethodResponse,
  ValidatePasscodeRequest,
  ValidatePasscodeResponse} from "./customer_pb";

export class CustomerServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoSendPasscodeEmail = new grpcWeb.AbstractClientBase.MethodInfo(
    SendPasscodeEmailResponse,
    (request: SendPasscodeEmailRequest) => {
      return request.serializeBinary();
    },
    SendPasscodeEmailResponse.deserializeBinary
  );

  sendPasscodeEmail(
    request: SendPasscodeEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: SendPasscodeEmailResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.customer.CustomerService/SendPasscodeEmail",
      request,
      metadata || {},
      this.methodInfoSendPasscodeEmail,
      callback);
  }

  methodInfoValidatePasscode = new grpcWeb.AbstractClientBase.MethodInfo(
    ValidatePasscodeResponse,
    (request: ValidatePasscodeRequest) => {
      return request.serializeBinary();
    },
    ValidatePasscodeResponse.deserializeBinary
  );

  validatePasscode(
    request: ValidatePasscodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: ValidatePasscodeResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.customer.CustomerService/ValidatePasscode",
      request,
      metadata || {},
      this.methodInfoValidatePasscode,
      callback);
  }

  methodInfoGetCustomerPaymentDetails = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCustomerPaymentDetailsResponse,
    (request: GetCustomerPaymentDetailsRequest) => {
      return request.serializeBinary();
    },
    GetCustomerPaymentDetailsResponse.deserializeBinary
  );

  getCustomerPaymentDetails(
    request: GetCustomerPaymentDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCustomerPaymentDetailsResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.customer.CustomerService/GetCustomerPaymentDetails",
      request,
      metadata || {},
      this.methodInfoGetCustomerPaymentDetails,
      callback);
  }

  methodInfoGetCustomerLanguage = new grpcWeb.AbstractClientBase.MethodInfo(
    GetCustomerLanguageResponse,
    (request: GetCustomerLanguageRequest) => {
      return request.serializeBinary();
    },
    GetCustomerLanguageResponse.deserializeBinary
  );

  getCustomerLanguage(
    request: GetCustomerLanguageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetCustomerLanguageResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.customer.CustomerService/GetCustomerLanguage",
      request,
      metadata || {},
      this.methodInfoGetCustomerLanguage,
      callback);
  }

  methodInfoCreateAchMandateNewCustomer = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateAchMandateResponse,
    (request: CreateAchMandateNewCustomerRequest) => {
      return request.serializeBinary();
    },
    CreateAchMandateResponse.deserializeBinary
  );

  createAchMandateNewCustomer(
    request: CreateAchMandateNewCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateAchMandateResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.customer.CustomerService/CreateAchMandateNewCustomer",
      request,
      metadata || {},
      this.methodInfoCreateAchMandateNewCustomer,
      callback);
  }

  methodInfoCreateAchMandateExistingCustomer = new grpcWeb.AbstractClientBase.MethodInfo(
    CreateAchMandateResponse,
    (request: CreateAchMandateExistingCustomerRequest) => {
      return request.serializeBinary();
    },
    CreateAchMandateResponse.deserializeBinary
  );

  createAchMandateExistingCustomer(
    request: CreateAchMandateExistingCustomerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: CreateAchMandateResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.customer.CustomerService/CreateAchMandateExistingCustomer",
      request,
      metadata || {},
      this.methodInfoCreateAchMandateExistingCustomer,
      callback);
  }

  methodInfoUpdateAchPaymentMethod = new grpcWeb.AbstractClientBase.MethodInfo(
    UpdateAchPaymentMethodResponse,
    (request: UpdateAchPaymentMethodRequest) => {
      return request.serializeBinary();
    },
    UpdateAchPaymentMethodResponse.deserializeBinary
  );

  updateAchPaymentMethod(
    request: UpdateAchPaymentMethodRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: UpdateAchPaymentMethodResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.customer.CustomerService/UpdateAchPaymentMethod",
      request,
      metadata || {},
      this.methodInfoUpdateAchPaymentMethod,
      callback);
  }

  methodInfoStartCheckoutAchMicroDeposit = new grpcWeb.AbstractClientBase.MethodInfo(
    StartCheckoutAchMicroDepositResponse,
    (request: StartCheckoutAchMicroDepositRequest) => {
      return request.serializeBinary();
    },
    StartCheckoutAchMicroDepositResponse.deserializeBinary
  );

  startCheckoutAchMicroDeposit(
    request: StartCheckoutAchMicroDepositRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: StartCheckoutAchMicroDepositResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.customer.CustomerService/StartCheckoutAchMicroDeposit",
      request,
      metadata || {},
      this.methodInfoStartCheckoutAchMicroDeposit,
      callback);
  }

}

