import counterpart from "counterpart";
import React, { useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { fetchACHClientSecretExistingCustomer, fetchACHClientSecretNewCustomer, setAchField } from "../../actions/achActions";
import { fetchPersonalDetails } from "../../actions/changePersonalDetailsActions";
import AchMandateModal from "../../components/ach-mandate-modal/AchMandateModal";
import FormGroup from "../../components/ui/form-group/FormGroup";
import FormLabel from "../../components/ui/form-label/FormLabel";
import Input from "../../components/ui/input/Input";
import Panel from "../../components/ui/panel/Panel";
import { ISubscription } from "../../models/Subscription";
import { IACHReducer } from "../../reducers/achReducer";
import { IChangePersonalDetailsReducer } from "../../reducers/changePersonalDetailsReducer";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import TaxInput from "../tax-input/TaxInput";

interface IAchForm {
  isSubmitted: boolean,
  type: "update-payment" | "create-subscription",
  subscriptionData?: ISubscription,
  isTaxRequired: boolean
}

const AchForm: React.FC<IAchForm> = ({ isSubmitted, type, subscriptionData, isTaxRequired }) => {
  const { stripeClientSecret, stripeInstance: stripe, accountHolder,
     stripePaymentId } = useSelector<AppState, IACHReducer>(state => state.achReducer);
  const { companyDomain, checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { email } = useSelector<AppState, IChangePersonalDetailsReducer>(state => state.changePersonalDetailsReducer);
  const { mainProps: { customerUniqueId } } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const dispatch = useDispatch();

  const isPreloadedAchSession = !!stripePaymentId
  
  useEffect(() => {
    if(type === "update-payment") {
      dispatch(fetchACHClientSecretExistingCustomer(checkoutConfig?.companyId as number, customerUniqueId as string));
      dispatch(fetchPersonalDetails(companyDomain, customerUniqueId as string));
    }

    if(type === "create-subscription" && subscriptionData && !isPreloadedAchSession) {
      dispatch(fetchACHClientSecretNewCustomer(checkoutConfig?.companyId as number, subscriptionData));
    }

    if(type === "create-subscription" && subscriptionData && isPreloadedAchSession) {
      dispatch(setAchField("isOpenMandateForm", true))
    }

  }, [type, subscriptionData])

  useEffect(() => {
    if(type === "update-payment" && isSubmitted) {
      onSubmit()
    }
  }, [isSubmitted])

  useEffect(() => {
    if(type === "create-subscription" && isSubmitted && stripeClientSecret) {
      onSubmit()
    }
  }, [isSubmitted, stripeClientSecret])


  const onSubmit = async () => {
    dispatch(setAchField("isUpdateAchSucceeded", false));
    dispatch(setAchField("isUpdateAchFailed", false));

    const { setupIntent, error }: any = await stripe.collectBankAccountForSetup({
      clientSecret: stripeClientSecret,
      params: {
        payment_method_type: "us_bank_account",
        payment_method_data: {
          billing_details: {
            name: accountHolder,
            email: subscriptionData?.email || email,
          },
        },
      },
      expand: ["payment_method"],
    })

    if (error) {
      dispatch(setAchField("isUpdateAchFailed", true));
      // PaymentMethod collection failed for some reason.
    } else if (setupIntent.status === "requires_payment_method") {
      // Customer canceled the hosted verification modal. Present them with other
      // payment method type options.
      dispatch(setAchField("isUpdateAchFailed", true));
    } else if (setupIntent.status === "requires_confirmation") {
      // We collected an account - possibly instantly verified, but possibly
      // manually-entered. Display payment method details and mandate text
      // to the customer and confirm the intent once they accept
      // the mandate.
      dispatch(setAchField("stripeLast4Digits", setupIntent?.payment_method?.us_bank_account?.last4));
      dispatch(setAchField("stripeBankName", setupIntent?.payment_method?.us_bank_account?.bank_name));
      dispatch(setAchField("isOpenMandateForm", true));
    }

  }

  return (
    <>
      <AchMandateModal type={type} subscriptionData={subscriptionData}  />
      <Panel id="update-ach-details-panel">
        { isTaxRequired && <TaxInput /> }
        <FormGroup>
          <FormLabel content="UPDATE_ACH_DETAILS_ACCOUNT_HOLDER" />
          <Row>
            <Col xs={12} sm={12}>
              <Input
                id="account-holder"
                value={accountHolder}
                placeholder={counterpart("UPDATE_ACH_DETAILS_ACCOUNT_HOLDER_PLACEHOLDER")}
                onChange={evt => { dispatch(setAchField("accountHolder", evt.target.value)) }}
                isError={false}
                errorMsg={""}
              />
            </Col>
          </Row>
        </FormGroup>

      </Panel>
    </>
  )
}

export default AchForm