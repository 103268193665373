import React, { useEffect, useState } from "react";
import counterpart from "counterpart";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { setRetentionField } from "../../actions/retentionActions";
import { applyDiscountRetention } from "../../actions/addDiscountOfferActions";
import { RetentionScreen } from "../../models/Retention";
import "./Retention.scss";
import { IRetentionReducer } from "../../reducers/retentionReducer";
import { getNextRetentionStepAvailable } from "../../utils/commonUtils";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import PaymentSummary from "../ui/payment-summary/PaymentSummary";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";
import { fetchPlans, setPlan, setNumberOfUnits, setCycle } from "../../actions/selectPlanActions";
import { IPlanWithCycles, PricingModelType } from "../../models/Product";
import { AddOnPricingModelType, IAddOn } from "../../models/AddOnAllowance";
import { getFrequencyText, recalculatePlanCycle } from "../../utils/planUtils";
import { IAddOnReducer } from "../../reducers/addOnReducer";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import history from "../../utils/history";
import { IAddDiscountOfferReducer } from "../../reducers/addDiscountOfferReducer";
import SystemInfo from "../ui/system-info/SystemInfo";
import { AccountManagementPermissions } from "../../models/AccountManagement";
import CouponImg from "../../images/coupon.svg";


const RetentionDiscountOffer: React.FC = () => {
  const [selectedDiscountOffer, setSelectedDiscountOffer] = useState(false);
  const [isApplyingDiscount, setIsApplyingDiscount] = useState(false);
  const [previousScreen, setPreviousScreen] = useState<RetentionScreen | null>(null);
  const { companyDomain, accountManagementPermissions, checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const { currentSubscription, currentPlan } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const { addOns } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const { discountOffer, retentionStepsAvailability } = useSelector<AppState, IRetentionReducer>(state => state.retentionReducer);
  const { isAddDiscountOfferSuccess, isAddDiscountOfferFailure } = useSelector<AppState, IAddDiscountOfferReducer>(state => state.addDiscountOfferReducer)
  const { mainProps: { subscriptionUniqueId } } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { plan, cycle } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const dispatch = useDispatch<Function>()

  const applyDiscount = async () => {
    setIsApplyingDiscount(true);
    try {
      await dispatch(applyDiscountRetention(companyDomain, subscriptionUniqueId as string));
      setIsApplyingDiscount(false);

    } catch (err) {
      console.log(err)
      setIsApplyingDiscount(false);
    }
  };

  const preloadData = async () => {
    if (!currentSubscription || !currentPlan) {
      return;
    }
    try {
      const { response: plans }: { response: Array<IPlanWithCycles> } = (await dispatch(fetchPlans(currentSubscription.productId))) as { response: Array<IPlanWithCycles> };

      if (plans) {
        let _currentPlan = plans.find((plan) => plan.planId === currentSubscription.planId);

        if (_currentPlan) {
          const flatFeeAddOns = addOns.filter((i) => i.pricingModelType === AddOnPricingModelType.AddonFlatFee) as Array<IAddOn>;
          _currentPlan = _currentPlan.pricingModelType !== PricingModelType.FlatFee ? recalculatePlanCycle(currentPlan, currentSubscription.units as number, flatFeeAddOns) : currentPlan;
          dispatch(setPlan({ label: currentPlan.name, value: _currentPlan }));

          dispatch(setNumberOfUnits(currentSubscription.units));

          const currentCycle = _currentPlan.cycles.find((cycle) => cycle.cycleId === currentSubscription.cycleId);
          if (currentCycle) {
            dispatch(setCycle(currentCycle));
          }
        }
      }
    } catch (err) { }
  };


  useEffect(() => {
    if (isAddDiscountOfferSuccess || isAddDiscountOfferFailure) {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }, [isAddDiscountOfferFailure, isAddDiscountOfferSuccess])

  useEffect(() => {
    if (retentionStepsAvailability) {
      const previousScreen = getNextRetentionStepAvailable(retentionStepsAvailability, "backward", RetentionScreen.discountOffer);
      setPreviousScreen(previousScreen);
    }
  }, [retentionStepsAvailability]);

  const goBackCallback = () => {
    if (previousScreen) {
      dispatch(setRetentionField("currentScreen", previousScreen))
    }
    else {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }

  if (!retentionStepsAvailability || !discountOffer) {
    return null;
  }

  const renderContent = () => {
    if (!discountOffer.getAvailable()) {
      return <SystemInfo title="RETENTION_DISCOUNT_OFFER_NOT_AVAILABLE" translateWith={{ discountLabel: checkoutConfig?.terminologyDiscountSingular }} isShowIcon={false} type="warning"></SystemInfo>
    }

    if (cycle && plan && currentPlan && currentSubscription) {
      return (
        <div className={`retention__discount-offer ${selectedDiscountOffer ? "retention__discount-offer--selected" : ""}`} onClick={() => setSelectedDiscountOffer(!selectedDiscountOffer)}>
          <PaymentSummary
            pricingModel={cycle.pricingModel}
            discountOffer={discountOffer}
            productName={currentSubscription.productName}
            planName={plan.value.displayName}
            planImageUrl={plan.value.planImageUrl}
            hasTitle={false}
            showTerms={false}
            currencyCode={currentSubscription.currency}
          />
        </div>
      )
    }
  }

  return (
    <PageWrapper
      className="cancel-subscription"
      btnDisabled={!discountOffer.getAvailable() || isApplyingDiscount}
      isLoading={isApplyingDiscount}
      title="CANCEL_SUBSCRIPTION_TITLE"
      titleTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
      btnText="RETENTION_DISCOUNT_OFFER_BTN"
      btnTextTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular, discountLabel: checkoutConfig?.terminologyDiscountSingular  }}
      btnCallback={applyDiscount}
      btnBackCallback={goBackCallback}
      btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION}
      bottomStickyContent={
        <a
          href="javascript:void(0)"
          className="retention__link"
          onClick={() => dispatch(setRetentionField("currentScreen", getNextRetentionStepAvailable(retentionStepsAvailability, "forward", RetentionScreen.discountOffer)))}
        >
          {counterpart("RETENTION_CONTINUE_TOCANCEL")}
        </a>
      }
    >
      <>
        {!discountOffer.getAvailable() && <SystemInfo title="RETENTION_DISCOUNT_OFFER_NOT_AVAILABLE" isShowIcon={false} type="warning"></SystemInfo>}

        {discountOffer.getAvailable() && <Panel id="retention__discount-offer" className="retention__discount-offer__content">
          <Text content="ACCOUNT_MANAGEMENT_GET_DISCOUN_TITLE"
            translateWith={{
              discount: `${Number(discountOffer.getPercentage()?.getValue()) / 100}%`,
              frequency: discountOffer.getExpiryFrequency(),
              frequencyType: getFrequencyText(discountOffer.getExpiryFrequency(), discountOffer.getExpiryFrequencyType())
            }} size="xlarge" weight="bold" />
          <img src={discountOffer.getExitIntentImageUrl() || CouponImg} alt="coupon"></img>
        </Panel>}
      </>
    </PageWrapper>
  );
};

export default RetentionDiscountOffer
