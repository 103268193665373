import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { setRetentionField, getPlanChange } from "../../actions/retentionActions";
import { RetentionScreen } from "../../models/Retention";
import "./Retention.scss";
import UpdatePaymentDetails from "../../containers/update-payment-details/UpdatePaymentDetails";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";


const RetentionPayBalance: React.FC = () => {

  const { companyDomain, checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const { subscriptionOutstandingBalance } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const dispatch = useDispatch<Function>()

  useEffect(() => {
    dispatch(getPlanChange(companyDomain));
  }, []);

  //TODO
  const payAndCancelSubscription = () => {
    //Pay balance
    //Cancel subscription
  }

  return (
    <UpdatePaymentDetails
      title="CANCEL_SUBSCRIPTION_TITLE"
      titleTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
      subTitle=""
      customSuccessSubtitle="ACCOUNT_MANAGEMENT_UPDATE_PAYMENT_SUCCESS_CANCEL_SUBSCRIPTION_OUTSTANDING_INVOICES"
      //btnText="CANCEL_SUBSCRIPTION_PAY"
      //btnTextTranslateWith={{ amount: subscriptionOutstandingBalance && subscriptionOutstandingBalance.getFormattedAmount()  }}
      //btnCallback={payAndCancelSubscription}
      btnBackCallback={() => dispatch(setRetentionField("currentScreen", RetentionScreen.confirmCancel))}
    >
    </UpdatePaymentDetails>
  )
}

export default RetentionPayBalance