import React, { useEffect, useState } from "react";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import Text from "../ui/text/Text";
import Panel from "../ui/panel/Panel";
import counterpart from "counterpart";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { setRetentionField, getHelpInterrupt } from "../../actions/retentionActions";
import { RetentionScreen } from "../../models/Retention";
import "./Retention.scss";
import { IRetentionReducer } from "../../reducers/retentionReducer";
import { getNextRetentionStepAvailable } from "../../utils/commonUtils";
import { sendEvent, CROSS_DOMAIN_EVENT } from "../../utils/crossDomainEvents";
import history from "../../utils/history";
import { AccountManagementPermissions } from "../../models/AccountManagement";


const RetentionHelpInterrupt: React.FC = () => {
  const { companyDomain, accountManagementPermissions, checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const { helpInterrupt, retentionStepsAvailability } = useSelector<AppState, IRetentionReducer>(state => state.retentionReducer);
  const dispatch = useDispatch<Function>()
  const [previousScreen, setPreviousScreen] = useState<RetentionScreen | null>(null);

  useEffect(() => {
    dispatch(getHelpInterrupt(companyDomain));
    if (retentionStepsAvailability) {
      const previousScreen = getNextRetentionStepAvailable(retentionStepsAvailability, "backward", RetentionScreen.helpInterrupt);
      setPreviousScreen(previousScreen);
    }
  }, []);

  if (!helpInterrupt || !retentionStepsAvailability) {
    return null;
  }

  const getPhoneEmailPanel = () => {
    if (!helpInterrupt.getEmail() && !helpInterrupt.getPhoneNumber() && !helpInterrupt.getPhoneNumberDialCode()) {
      return <div />
    }

    return (
      <Panel id="retention__help-interrupt" className="retention__help-interrupt__details">
        {
          helpInterrupt.getEmail()
          && <Text content="RETENTION_HELP_INTERRUPT_EMAIL" translateWith={{ companySupportEmail: <Text component="span" content={helpInterrupt.getEmail()} shouldTranslate={false} noMargin weight="bold"></Text> }} noMargin />
        }
        {
          helpInterrupt.getPhoneNumber() && helpInterrupt.getPhoneNumberDialCode()
          && <Text content="RETENTION_HELP_INTERRUPT_PHONE" translateWith={{ companySupportPhone: <Text component="span" content={`(+${helpInterrupt.getPhoneNumberDialCode()}) ${helpInterrupt.getPhoneNumber()}`} shouldTranslate={false} noMargin weight="bold"></Text> }} noMargin />
        }
      </Panel>
    )
  }

  const goBackCallback = () => {
    if (previousScreen) {
      dispatch(setRetentionField("currentScreen", previousScreen))
    }
    else {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }

  return (
    <PageWrapper 
      btnText={helpInterrupt.getSupportPageUrl() ? "RETENTION_GO_TO_SUPPORT_PAGE" : "RETENTION_KEEP_MY_PLAN"}
      btnTextTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
      btnPosition="bottom"
      title="CANCEL_SUBSCRIPTION_TITLE"
      titleTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
      btnCallback={() => helpInterrupt.getSupportPageUrl() ? sendEvent(CROSS_DOMAIN_EVENT.REDIRECT_NEW_TAB, helpInterrupt.getSupportPageUrl()) : history.goBack()}
      btnBackCallback={goBackCallback}
      btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION}
      bottomStickyContent={
        <a
          href="javascript:void(0)"
          className="retention__link"
          onClick={() => dispatch(setRetentionField("currentScreen", getNextRetentionStepAvailable(retentionStepsAvailability, "forward", RetentionScreen.helpInterrupt)))} >
          {counterpart("RETENTION_CONTINUE_TOCANCEL")}
        </a>
      }>
      <Panel id="retention__help-interrupt-heading" className="retention__help-interrupt-heading">
        <Text content={helpInterrupt.getTitle()} shouldTranslate={false} noMargin />
        <Text content={helpInterrupt.getSubtitle()} shouldTranslate={false} noMargin />
      </Panel>

      {getPhoneEmailPanel()}

    </PageWrapper>
  )
}

export default RetentionHelpInterrupt