import { IGlobalReducer } from "../reducers/globalReducer";
import { IPersonalDetailsReducerState } from "../reducers/personalDetailsReducer";
import { ISelectPlanReducer } from "../reducers/selectPlanReducer";
import { GetInvoiceSimulationResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/invoice/invoice_pb";
import { ICardDetailInputDetail } from "./PaymentDetail";
import { ISubscriptionExistingCustomerSCA, ISubscriptionSCA } from "./Subscription";


export enum PaymentScreen {
  CREDIT_CARD = "CREDIT_CARD",
  ACH = "ACH",
  SELECT_TYPE = "SELECT_TYPE",
  CONFIRMATION = "CONFIRMATION",
  CARD_ON_FILE = "CARD_ON_FILE"
}

export enum ProRateOptions {
  DoNotProRate = "DoNotProRate",
  DoNotChargeUntilNextCycle = "DoNotChargeUntilNextCycle",
  ProRateBasedOnDaysRemaining = "ProRateBasedOnDaysRemaining"
}

export interface IPaymentSCA  {
  browserInfo: string,
  transactionToken?: string,
  cardDetails?: ICardDetailInputDetail
}

export enum PaymentStatusSCA {
  Success = "Success",
  Declined = "Declined", // When a charge failed because of an issue in customer's side (card expired, fraud, etc...).
  Failed = "Failed", // When a charge failed because of an issue in our side or Spreedly side. Service down, wrong API Key, etc..
  Pending = "Pending" // When a charge requires 3DSecure validation (SCA)
}

export interface PaymentResponseSCA { 
  status: PaymentStatusSCA, 
  transactionToken: string, 
 }

/**
 * pending data saved in the session storage before 3ds1 redirect
 */
 export type IBillsbySubscriptionSCAPendingData = {
  //subscriptionSCAResponse: ISubscriptionResponseSCA,
  companyDomain: string,
  subscriptionSCARequestPayload: ISubscriptionSCA | ISubscriptionExistingCustomerSCA,
  customerUniqueId: string | null,
  selectPlanState: ISelectPlanReducer,
  personalDetailsState: IPersonalDetailsReducerState,
  globalReducerState: IGlobalReducer,
  paymentDescription?: GetInvoiceSimulationResponse.AsObject
  //isExistingCustomer: boolean 
}

export enum PaymentMethodType {
  None = "None",
  CreditCard = "CreditCard",
  Ach = "Ach"
}