import React, { FormEvent, useState } from "react";
import { ICheckoutConfig } from "../../../models/BillsbyData";
import { IBranding } from "../../../models/Branding";
import { IAddOn, IAddOnPricingModel, IAllowance, IAllowancePricingModel, AllowancePricingModelType } from "../../../models/AddOnAllowance";
import { IPlanWithCycles, ICycle } from "../../../models/Product";
import Text from "../../ui/text/Text";
import { extractCurrencyFromPriceFormatted } from "../../../utils/planUtils";
import counterpart from "counterpart";
import Modal from "../../ui/modal/Modal";
import AddOnPriceInfo from "../../select-plan/cycle-price-info/AddOnPriceInfo";
import { convertFormattedPriceToNumber } from "../../../utils/commonUtils";
import IncludedUnitsText from "./IncludedUnitsText";

interface IAllowancePriceText {
  allowance: IAllowance;
  plan: IPlanWithCycles;
  cycle: ICycle;
  checkoutConfig: ICheckoutConfig;
  branding: IBranding;
  viewPriceModalBtnText?: string;
  showPreviewPriceForComplexType?: boolean;
}

const AllowancePriceText: React.FC<IAllowancePriceText> = ({
  allowance,
  plan,
  cycle,
  checkoutConfig,
  branding,
  viewPriceModalBtnText = "MORE_PRICING_INFO",
  showPreviewPriceForComplexType = false,
}) => {
  const { pricingModels } = allowance;
  const priceModel = (pricingModels as Array<IAddOnPricingModel | IAllowancePricingModel>).find(
    (i) => i.frequency === cycle.pricingModel.frequency && i.frequencyType === cycle.pricingModel.frequencyType && i.currency.isoCode === cycle.currency
  );

  const [showPricingInfo, setShowPricingInfo] = useState(false);
  const [showPricingInfoAddOn, setShowPricingInfoAddOn] = useState<IAddOn | IAllowance | undefined>(undefined);
  const [showPricingInfoPricingModel, setShowPricingInfoPricingModel] = useState<IAddOnPricingModel | IAllowancePricingModel | undefined>(undefined);

  const showMoreInfoOnClick = (allowance: IAddOn | IAllowance, priceModel: IAddOnPricingModel | IAllowancePricingModel) => {
    setShowPricingInfoAddOn(allowance);
    setShowPricingInfoPricingModel(priceModel);
    setShowPricingInfo(true);
  };

  if (!priceModel) {
    return null;
  }

  if (allowance.pricingModelType === AllowancePricingModelType.AllowancePerUnit) {
    const currency = priceModel.currency.symbol.replace("_", "");
    const { isAtBeginning } = extractCurrencyFromPriceFormatted(cycle.pricingModel.priceFormatted);
    const priceFormatted = `${isAtBeginning ? currency : ""}${(((priceModel.perUnitPrice as number) / 100) * (allowance.units || 1)).toFixed(2)}${!isAtBeginning ? currency : ""}`;
    const convertedPriceToNumber = convertFormattedPriceToNumber(priceFormatted);
    const unit = allowance.singleUnitName || checkoutConfig.terminologyUnitSingular;
    const units = allowance.pluralUnitName || checkoutConfig.terminologyUnitPlural;

    if ((priceModel as IAddOnPricingModel).flatFeePrice as number === 0) {
      return <Text content="SELECT_PLAN_TIERED_CYCLE_UNIT_OUT_OF_RANGE" noMargin></Text>
    }

    const getPerUnitLabel = () => {
      if (allowance.units) {
        return allowance.units === 1 ? `${counterpart("FREQUENCY_PER")} ${unit}` : `${counterpart("FREQUENCY_FOR")} ${allowance.units} ${units}`;
      }
      return "";
    }

    // if (allowance.units && allowance.units > 0 && convertedPriceToNumber === 0) {
    //   return <Text content="SELECT_PLAN_TIERED_CYCLE_OUT_OF_RANGE" translateWith={{ unitLabel: getPerUnitLabel() }}></Text>
    // }

    return (
      <>
        {!!(priceModel as IAllowancePricingModel).includedUnits && <>
          <IncludedUnitsText
            priceModel={priceModel as IAllowancePricingModel}
            allowance={allowance as IAllowance}
            checkoutConfig={checkoutConfig}
          />
          <Text className={"select-plan__inline-info"} content="ALLOWANCE_PRICE_TEXT_THEN" />
        </>}
        <Text
          className="select-plan__inline-info text-bold"
          content={`${priceFormatted}`}
          shouldTranslate={false}
          noMargin
        />
        <Text
          className="select-plan__inline-info"
          content={` ${counterpart("FREQUENCY_PER")} ${unit}`}
          shouldTranslate={false}
          noMargin
        />
      </>
    );
  }

  if (allowance.pricingModelType === AllowancePricingModelType.AllowanceCapped) {
    return (
      <IncludedUnitsText
        priceModel={priceModel as IAllowancePricingModel}
        allowance={allowance as IAllowance}
        checkoutConfig={checkoutConfig}
      />
    );
  }

  const getPricePreviewComplexPricingModels = () => {
    if (!showPreviewPriceForComplexType) {
      return;
    }

    const hasIncludedUnits = !!(priceModel as IAllowancePricingModel).includedUnits;
    if (hasIncludedUnits) {
      return (
        <>
          <IncludedUnitsText
            priceModel={priceModel as IAllowancePricingModel}
            allowance={allowance as IAllowance}
            checkoutConfig={checkoutConfig}
          />
          <Text className={"select-plan__inline-info"} content="ALLOWANCE_PRICE_TEXT_THEN" />
        </>
      )
    }
  };

  const getComplexPriceText = () => {
    const allowancePricingModels: Array<IAllowancePricingModel> = allowance.pricingModels
      .filter(pricingModel => pricingModel.frequency === cycle.pricingModel.frequency
        && pricingModel.frequencyType === cycle.pricingModel.frequencyType);
    const firstTierFinish = allowancePricingModels[0].tiers[0].finish;
    const firstPrice = allowancePricingModels[0].tiers[0].priceFormatted;
    const hasIncludedUnits = !!allowancePricingModels[0].includedUnits;

    if (allowance.pricingModelType === AllowancePricingModelType.AllowanceRanged) {
      return (
        <>
          <Text
            className={`select-plan__inline-info ${hasIncludedUnits ? "text-lower" : ""}`}
            content={"ALLOWANCE_PRICE_TEXT_FROM"}
            noMargin
          />
          <Text
            className="select-plan__inline-info text-bold"
            content={`${firstPrice}`}
            shouldTranslate={false}
            noMargin
          />
          <Text
            className="select-plan__inline-info"
            content={"ALLOWANCE_PRICE_TEXT_FOR_UP_TO_UNIT"}
            noMargin
          />
          <Text
            className="select-plan__inline-info text-bold"
            content={`${firstTierFinish}`}
            shouldTranslate={false}
            noMargin
          />
          <Text
            className="select-plan__inline-info text-bold"
            content={` ${allowance.pluralUnitName} `}
            shouldTranslate={false}
            noMargin
          />
        </>)
    }

    if (allowance.pricingModelType === AllowancePricingModelType.AllowanceVolume || AllowancePricingModelType.AllowanceTiered) {
      return (
        <>
          <Text
            className={`select-plan__inline-info ${hasIncludedUnits ? "text-lower" : ""}`}
            content={"ALLOWANCE_PRICE_TEXT_FROM"}
            noMargin
          />
          <Text
            className="select-plan__inline-info text-bold"
            content={`${firstPrice}`}
            shouldTranslate={false}
            noMargin
          />
          <Text
            className="select-plan__inline-info"
            content={"ALLOWANCE_PRICE_TEXT_FOR_UNIT"}
            translateWith={{ unit: allowance.singleUnitName }}
            noMargin
          />
        </>)
    }
  }

  // for complex pricing models
  return (
    <>
      {getPricePreviewComplexPricingModels()}
      {getComplexPriceText()}
      <Text
        className="select-plan__more-pricing-info select-plan__inline-info"
        content={viewPriceModalBtnText}
        onClick={(evt: FormEvent) => showMoreInfoOnClick(allowance, priceModel)}
        noMargin
      />
      <Modal
        className="cycle-price-info__modal"
        buttonColor={branding.highlightColor}
        isOpen={showPricingInfo}
        title=""
        shouldTranslateTitle={false}
        onRequestClose={() => {
          setShowPricingInfo(false);
          setShowPricingInfoAddOn(undefined);
          setShowPricingInfoPricingModel(undefined);
        }}
        key={"modal"}
      >
        <AddOnPriceInfo
          plan={plan}
          cycle={cycle}
          checkoutConfig={checkoutConfig}
          branding={branding}
          pricingModel={showPricingInfoPricingModel}
          addOn={showPricingInfoAddOn}
        />
      </Modal>
    </>
  );
};

export default AllowancePriceText;
