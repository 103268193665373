export enum TaxCollection {
  DO_NOT_COLLECT = "DoNotCollect",
  COMPULSORY = "Compulsory",
  OPTIONAL = "Optional"
}

export enum ComplexTaxCountry {
  Australia = "AUS",
  Canada = "CAN",
  EuropeanUnion = "EUE",
  India = "IND",
  NewZealand = "NZL",
  UnitedStates = "USA"
}

export enum TaxRegNumberError {
  INVALID = "INVALID",
  UNABLE_TO_VALIDATE = "UNABLE_TO_VALIDATE"
}