import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import { BillsbyDataAction } from "../../models/BillsbyData";
import { IACHReducer } from "../../reducers/achReducer";
import { IGlobalReducer } from "../../reducers/globalReducer";
import SystemInfo from "../ui/system-info/SystemInfo";
import Text from "../ui/text/Text";

const AchInfoMessage = () => {
	const { isUpdateAchSucceeded, isStartMicroDepositSuccess, 
		stripeSetupIntent } = useSelector<AppState, IACHReducer>(state => state.achReducer)

	const { billsbyAction } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)

	const getACHMessage = (): { title: string | JSX.Element, text?: string | JSX.Element, type: "success" | "warning", shouldTranslateText?: boolean } => {
		if (isUpdateAchSucceeded && stripeSetupIntent?.status === "succeeded") { 
			return { 
				title: "ACCOUNT_MANAGEMENT_UPDATE_PAYMENT_SUCCESS_TITLE",
				type: "success"
			} 
		}
		if (isUpdateAchSucceeded && stripeSetupIntent?.next_action?.type === "verify_with_microdeposits" && billsbyAction === BillsbyDataAction.UPDATE_PAYMENT_DETAILS) { 
			// payment details request email link
			return { 
				title: "ACH_MICRODEPOSIT_VERIFICATION_WARNING_TITLE",
				text: (
					<>
						<Text content="ACH_MICRODEPOSIT_VERIFICATION_WARNING_CONTENT_UPDATE_PAYMENT_1" />
						<Text content="ACH_MICRODEPOSIT_VERIFICATION_WARNING_CONTENT_UPDATE_PAYMENT_2" noMargin />
					</>
				),
				shouldTranslateText: false,
				type: "warning"
			} 
		}
		if (isStartMicroDepositSuccess) { 
			// complete checkout subscription email link
			return { 
				title: "ACH_MICRODEPOSIT_VERIFICATION_WARNING_TITLE",
				text: (
					<>
						<Text content="ACH_MICRODEPOSIT_VERIFICATION_WARNING_CONTENT_CREATE_SUBCRIPTION_1" />
						<Text content="ACH_MICRODEPOSIT_VERIFICATION_WARNING_CONTENT_CREATE_SUBCRIPTION_2" noMargin />
					</>
				),
				shouldTranslateText: false,
				type: "warning"
			} 
		}

		return { title: "ACCOUNT_MANAGEMENT_UPDATE_PAYMENT_ERROR_TITLE", text: "ACCOUNT_MANAGEMENT_UPDATE_PAYMENT_ERROR_SUBTITLE", type: "warning" }
	}

	return (
		<SystemInfo
			className=""
			{...getACHMessage()}
		/>
	)
}

export default AchInfoMessage