import { FrequencyType, ICycle, ITier, PricingModelType, IPlanWithCycles, IBaseCycle } from "../models/Product";
import counterpart from "counterpart";
import { IAddOnPricingModel, AddOnPricingModelType, IAddOn, IAllowance, AllowancePricingModelType, IAllowancePricingModel } from "../models/AddOnAllowance";
import { ICheckoutConfig } from "../models/BillsbyData";
import { trimWhiteSpaces, convertFormattedPriceToNumber } from "../utils/commonUtils";
import { CouponError, IAppliedCoupon } from "../models/DiscountCoupon";
import { FrequencyType as DiscountFrequencyType } from "../utils/grpc/generated/Billsby.Protos/core/public/companyconfig/retentionstep/retention_step_pb"
import { GetInvoiceSimulationResponse } from "./grpc/generated/Billsby.Protos/billing/public/invoice/invoice_pb";
import { IPurchaseEventDetails, IPurchaseEventItem } from "../models/GoogleAnalytics";

export const getPriceOfTieredCycle = (tiers: Array<ITier>, numberOfUnits: number, totalFlatFeeAddOns: number = 0) => {
  let simulatedPrice = 0;
  numberOfUnits = +numberOfUnits;

  tiers.forEach(tier => {
    if (tier.start + numberOfUnits <= tier.finish) {
      simulatedPrice += numberOfUnits * tier.price;
      numberOfUnits = 0;
    } else {
      const tierUnits = tier.finish - tier.start + 1;
      simulatedPrice += tierUnits * tier.price;
      numberOfUnits -= tierUnits;
    }
  })

  return ((simulatedPrice + totalFlatFeeAddOns) / 100);
}

export const getPriceOfRangeCycle = (tiers: Array<ITier>, numberOfUnits: number, freeUnit: number, totalFlatFeeAddOns: number = 0) => {
  let simulatedPrice = 0;
  const units = numberOfUnits - freeUnit
  const unitTier = tiers.filter(tier => units >= tier.start && units <= tier.finish);
  if (unitTier.length > 0)
    simulatedPrice = unitTier[0].price;

  return ((simulatedPrice + totalFlatFeeAddOns) / 100)
}

export const getPriceOfVolumeCycle = (tiers: Array<ITier>, numberOfUnits: number, freeUnit: number, totalFlatFeeAddOns: number = 0) => {
  let simulatedPrice = 0;

  const unitTier = tiers.filter(tier => numberOfUnits >= tier.start && numberOfUnits <= tier.finish);
  const units = numberOfUnits - freeUnit
  if (unitTier.length > 0)
    simulatedPrice = unitTier[0].price * (units < 0 ? 0 : units);

  if (simulatedPrice < 0)
    simulatedPrice = 0;

  return ((simulatedPrice + totalFlatFeeAddOns) / 100)
}

export const getPriceOfPerUnitCycle = (price: number, numberOfUnits: number, freeUnit: number, totalFlatFeeAddOns: number = 0) => {
  const units = numberOfUnits - freeUnit
  let simulatedPrice = (price * (units < 0 ? 0 : units)) + totalFlatFeeAddOns;
  //let simulatedPriceTruncated = Math.round(simulatedPrice * 100) / 100;
  if (simulatedPrice < 0)
    simulatedPrice = 0;
  return (simulatedPrice / 100).toFixed(2);
}

const getPriceOfFlatFeeCycle = (price: number, totalFlatFeeAddOns: number = 0) => {
  return ((price + totalFlatFeeAddOns) / 100).toFixed(2);
}

export const getTierPrice = (pricingModelType: PricingModelType, tiers: Array<ITier>, units: number, totalFlatFeeAddOns: number, freeUnits: number = 0) => {
  switch (pricingModelType) {
    case PricingModelType.Tiered:
      return `${getPriceOfTieredCycle(tiers as Array<ITier>, units, totalFlatFeeAddOns).toFixed(2)}`
    case PricingModelType.Ranged:
      return `${getPriceOfRangeCycle(tiers as Array<ITier>, units, freeUnits, totalFlatFeeAddOns).toFixed(2)}`
    case PricingModelType.Volume:
      return `${getPriceOfVolumeCycle(tiers as Array<ITier>, units, freeUnits, totalFlatFeeAddOns).toFixed(2)}`
    default:
      return "";
  }
}

export const getTiers = (cycle: any) => {
  if (!cycle.pricingModel.tiers)
    return null;

  return cycle.pricingModel.tiers;
}

export const isValidNumberOfUnits = (plan: IPlanWithCycles, units: number) => {
  if (plan.pricingModelType === PricingModelType.FlatFee || plan.pricingModelType === PricingModelType.PerUnit)
    return true;

  const tiersCoverage = plan.cycles.map(cycle => {
    const tiers = getTiers(cycle);
    return tiers.filter((tier: ITier) => tier.finish >= (units)).length > 0;
  });

  const isValidRange = tiersCoverage.every(i => i);
  return isValidRange;
}


export const isValidNumberOfUnitsAddOns = (addOn: IAddOn | IAllowance) => {
  if (addOn.pricingModelType === AddOnPricingModelType.AddonFlatFee || addOn.pricingModelType === AddOnPricingModelType.AddonPerUnit) {
    return true;
  }

  const pricingModel = (addOn.pricingModels as Array<IAddOnPricingModel | IAllowancePricingModel>)
    .find((pm: IAddOnPricingModel | IAllowancePricingModel) => !!pm.priceFormatted);

  if (!pricingModel) {
    return false;
  }

  if (addOn.units && addOn.units > 0 && convertFormattedPriceToNumber(pricingModel.priceFormatted as string) === 0) {
    return false;
  }

  return true
}

export const getCurrencyFromCycle = (cycle: IBaseCycle): { isAtBeginning: boolean, currency: string } => {
  const tiers: Array<ITier> = getTiers(cycle);
  if (!tiers || !tiers[0].priceFormatted) {
    return extractCurrencyFromPriceFormatted(cycle.pricingModel.priceFormatted);
  }

  return extractCurrencyFromPriceFormatted(tiers[0].priceFormatted);
}

export const calculateTotalFlatFeeAddOnsPerCycle = (addOns: Array<IAddOn>, cycle: ICycle) => {
  const totalFlatFeeAddOnPricingMethod = addOns.length > 0 ? addOns.map(addOn =>
    addOn.pricingModels.reduce((previousValue, currentValue) => {
      if (currentValue.frequency === cycle.pricingModel.frequency &&
        currentValue.frequencyType === cycle.pricingModel.frequencyType &&
        currentValue.currency.isoCode === cycle.currency) {
        return currentValue.flatFeePrice ? previousValue + currentValue.flatFeePrice : previousValue;
      }
      return previousValue;
    }, 0)) : [0];

  return totalFlatFeeAddOnPricingMethod.reduce((prev, curr) => prev + curr, 0);
}

export const recalculatePlanCycle = (plan: IPlanWithCycles, units: number, addOns: IAddOn[]) => {
  plan.cycles.forEach((cycle) => {

    const totalFlatFeeAddOn = +calculateTotalFlatFeeAddOnsPerCycle(addOns, cycle);
    const freeQuantity = !cycle.pricingModel.freeQuantity ? 0 : cycle.pricingModel.freeQuantity;
    const { isAtBeginning, currency } = getCurrencyFromCycle(cycle);
    if (plan.pricingModelType === PricingModelType.PerUnit) {
      cycle.pricingModel.priceFormatted = `${isAtBeginning ? currency : ""}${getPriceOfPerUnitCycle(cycle.pricingModel.price, units, freeQuantity, totalFlatFeeAddOn)}${!isAtBeginning ? currency : ""}`;
    }
    else if (plan.pricingModelType === PricingModelType.FlatFee) {
      cycle.pricingModel.priceFormatted = `${isAtBeginning ? currency : ""}${getPriceOfFlatFeeCycle(cycle.pricingModel.price, totalFlatFeeAddOn)}${!isAtBeginning ? currency : ""}`;
    }
    else {
      cycle.pricingModel.priceFormatted = `${isAtBeginning ? currency : ""}${getTierPrice(plan.pricingModelType, cycle.pricingModel.tiers as Array<ITier>, units, totalFlatFeeAddOn, freeQuantity)}${!isAtBeginning ? currency : ""}`;
    }
  });

  return plan;
}

export const getAddOnTierPrice = (pricingModelType: AddOnPricingModelType | AllowancePricingModelType | PricingModelType, tiers: Array<ITier>,
  units: number, freeUnits: number = 0) => {

  switch (pricingModelType) {
    case AddOnPricingModelType.AddonTiered:
    case AllowancePricingModelType.AllowanceTiered:
    case PricingModelType.Tiered:
      return `${getPriceOfTieredCycle(tiers as Array<ITier>, units).toFixed(2)}`
    case AddOnPricingModelType.AddonRanged:
    case AllowancePricingModelType.AllowanceRanged:
    case PricingModelType.Ranged:
      return `${getPriceOfRangeCycle(tiers as Array<ITier>, units, freeUnits).toFixed(2)}`
    case AddOnPricingModelType.AddonVolume:
    case AllowancePricingModelType.AllowanceVolume:
    case PricingModelType.Volume:
      return `${getPriceOfVolumeCycle(tiers as Array<ITier>, units, freeUnits).toFixed(2)}`
    default:
      return "";
  }
}

export const calculateAddOnPrice = (cycle: ICycle, pricingModel: IAddOnPricingModel | IAllowancePricingModel, units: number,
  pricingModelType: AddOnPricingModelType | AllowancePricingModelType) => {

  const currency = pricingModel.currency.symbol.replace("_", "");
  const freeQuantity = 0;
  const { isAtBeginning } = getCurrencyFromCycle(cycle);
  if (pricingModelType === AddOnPricingModelType.AddonPerUnit) {
    pricingModel.priceFormatted = `${isAtBeginning ? currency : ""}${getPriceOfPerUnitCycle(pricingModel.perUnitPrice as number, units, freeQuantity)}${!isAtBeginning ? currency : ""}`;
  } else {
    pricingModel.priceFormatted = `${isAtBeginning ? currency : ""}${getAddOnTierPrice(pricingModel.pricingModelType, pricingModel.tiers as Array<ITier>, units, freeQuantity)}${!isAtBeginning ? currency : ""}`;
  }

  return pricingModel;
}

export const extractCurrencyFromPriceFormatted = (priceFormatted: string = ""): { isAtBeginning: boolean, currency: string } => {
  let currency = "";
  let isAtBeginning = false;
  const priceFormattedNoSpaces = priceFormatted.replace(/ /g, "");
  for (let i = 0; i < priceFormattedNoSpaces.length; i++) {
    let ch = priceFormattedNoSpaces.charAt(i);
    if (!ch.match(/\d/) && !ch.match(/\./) && !ch.match(/,/)) {
      if (i === 0) { isAtBeginning = true }
      const firstDigitIndex = priceFormattedNoSpaces.search(/\d/);
      //currency can be at the beginning or at the end of the price
      currency = trimWhiteSpaces(priceFormattedNoSpaces.substring(i, firstDigitIndex || priceFormattedNoSpaces.length), "all");
      break;
    }
  }

  return { isAtBeginning, currency };
}

export const getFrequencyText = (frequency: number, frequencyType: FrequencyType | DiscountFrequencyType) => {
  switch (frequencyType) {
    case FrequencyType.Daily:
    case DiscountFrequencyType.DAILY:
      return frequency > 1 ? counterpart("FREQUENCY_DAYS") : counterpart("FREQUENCY_DAY");
    case FrequencyType.Weekly:
    case DiscountFrequencyType.WEEKLY:
      return frequency > 1 ? counterpart("FREQUENCY_WEEKS") : counterpart("FREQUENCY_WEEK");
    case FrequencyType.Monthly:
    case DiscountFrequencyType.MONTHLY:
      return frequency > 1 ? counterpart("FREQUENCY_MONTHS") : counterpart("FREQUENCY_MONTH");
    case FrequencyType.Yearly:
    case DiscountFrequencyType.YEARLY:
      return frequency > 1 ? counterpart("FREQUENCY_YEARS") : counterpart("FREQUENCY_YEAR");
  }
}

export const getCycleFrequencyText = (frequency: number, frequencyType: FrequencyType, frequencyTextOnly = false) => {
  if (frequencyTextOnly)
    return `${frequency} ${getFrequencyText(frequency, frequencyType)}`;
  return `${counterpart("FREQUENCY_EVERY")} ${frequency} ${getFrequencyText(frequency, frequencyType)}`;
}

export const getCycleFreeTrialText = (freeTrial: number | undefined, freeTrialFrequency: FrequencyType | undefined) => {
  if (freeTrial && freeTrialFrequency) {
    return `${counterpart("FREE_TRIAL_AFTER")} ${freeTrial} ${getFrequencyText(freeTrial, freeTrialFrequency)} ${counterpart("FREE_TRIAL_TEXT")}`;
  }
  return "";
}

export const getSetupFeeText = (setupFeeFormatted: string | null, checkoutConfig?: ICheckoutConfig) => {
  if (setupFeeFormatted) {
    return `${setupFeeFormatted.replace(/ /g, "")} ${checkoutConfig?.terminologySetupFeeSingular}`;
  }
  return "";
}

export const getContractTermText = (frequency: number | undefined, frequencyType: FrequencyType | undefined) => {
  if (frequency && frequencyType) {
    //no plurarization of frequency text for minimum term - ex. 12 week minimum term
    return `${frequency} ${getFrequencyText(1, frequencyType)} ${counterpart("CONTRACT_TERM_MINIMUM_TERM")}`;
  }
  return counterpart("CONTRACT_TERM_NO_MINIMUM_TERM");
}

export const getFlatFeeAddOnPriceText = (addOn: IAddOn, cycle: ICycle) => {
  const priceModel = addOn.pricingModels.find(pricingModel => pricingModel.frequency === cycle.pricingModel.frequency &&
    pricingModel.frequencyType === cycle.pricingModel.frequencyType);

  if (priceModel) {
    //const currency = priceModel.currency ? priceModel.currency.symbol.replace('_', '') : '';
    const { currency, isAtBeginning } = extractCurrencyFromPriceFormatted(cycle.pricingModel.priceFormatted);
    const flatFee = priceModel.flatFeePrice ? `${(priceModel.flatFeePrice / 100).toFixed(2)}` : "";
    return `${isAtBeginning ? currency : ""}${flatFee}${!isAtBeginning ? currency : ""} ${getCycleFrequencyText(priceModel.frequency, priceModel.frequencyType)}`;
  }

  return "";
}

export const isComplexPricingModel = (pricingModel: PricingModelType | AddOnPricingModelType | AllowancePricingModelType) => {
  return [PricingModelType.Ranged, PricingModelType.Tiered,
  PricingModelType.Volume, PricingModelType.PerUnit,
  AddOnPricingModelType.AddonPerUnit, AddOnPricingModelType.AddonRanged,
  AddOnPricingModelType.AddonTiered, AddOnPricingModelType.AddonVolume,
  AllowancePricingModelType.AllowancePerUnit, AllowancePricingModelType.AllowanceRanged,
  AllowancePricingModelType.AllowanceTiered, AllowancePricingModelType.AllowanceTiered].some(el => el === pricingModel);
}

export const isAddOn = (addon: IAddOn | IAllowance) => {
  return [AddOnPricingModelType.AddonFlatFee, AddOnPricingModelType.AddonPerUnit, AddOnPricingModelType.AddonRanged,
  AddOnPricingModelType.AddonTiered, AddOnPricingModelType.AddonVolume].some(el => el === addon.pricingModelType);
}

export const getIncludedAddOnsAllowancesTypes = (addonsAllowances: Array<IAddOn | IAllowance>): "addons" | "allowances" | "both" | "none" => {
  const hasAddons = addonsAllowances.some(el => isAddOn(el));
  const hasAllowances = addonsAllowances.some(el => !isAddOn(el));
  if (hasAddons && hasAllowances) {
    return "both";
  }
  if (hasAddons) {
    return "addons";
  }
  if (hasAllowances) {
    return "allowances";
  }
  return "none";
}

export const getIncludedUnitsText = (priceModelAllowance: IAllowancePricingModel, allowance: IAllowance, checkoutConfig: ICheckoutConfig) => {
  if (!priceModelAllowance.includedUnits) { return "" }
  const unitLabel = priceModelAllowance.includedUnits
    ? (allowance.pluralUnitName || checkoutConfig.terminologyUnitPlural)
    : (allowance.singleUnitName || checkoutConfig.terminologyUnitSingular);
  return `${priceModelAllowance.includedUnits} ${counterpart("SELECT_ALLOWANCES_INCLUDED")} ${unitLabel} ${getCycleFrequencyText(priceModelAllowance.frequency, priceModelAllowance.frequencyType)}`
}

export const getMaximumUnit = (plan: IPlanWithCycles) => {
  let tierFinish: Array<number> = [];

  if (plan.pricingModelType === PricingModelType.PerUnit) {
    return 1000000;
  }

  plan.cycles.forEach(cycle => {
    if (cycle.pricingModel.tiers) {
      cycle.pricingModel.tiers.forEach(tier => {
        if (tierFinish.indexOf(tier.finish) === -1) {
          tierFinish.push(tier.finish)
        }
      })
    }

    return 0;
  })

  return Math.max(...tierFinish);
}

export const getMaximumUnitCycle = (cycle: ICycle) => {
  let tierFinish: Array<number> = [];

  if (!cycle.pricingModel.tiers) {
    return 1000000
  }

  cycle.pricingModel.tiers.forEach(tier => {
    if (tierFinish.indexOf(tier.finish) === -1) {
      tierFinish.push(tier.finish)
    }
  })

  return Math.max(...tierFinish);
}

export const getCouponError = (error: CouponError | null, appliedCoupons: Array<IAppliedCoupon>, removedCoupons?: Array<{ name: string, id: number }>) => {
  switch (error) {
    case CouponError.INVALID_CODE:
      return { title: "APPLY_COUPON_ERROR_TITLE1", content: "APPLY_COUPON_ERROR_CONTENT1" };
    case CouponError.NOT_COMPATIBLE:
      return { title: appliedCoupons.length > 1 ? "APPLY_COUPON_ERROR_TITLE2" : "APPLY_COUPON_ERROR_TITLE6", content: "" };
    case CouponError.NOT_FOR_EXISTING_CUSTOMERS:
      return { title: removedCoupons && removedCoupons.length < 1 ? "APPLY_COUPON_ERROR_CONTENT3" : "APPLY_COUPON_ERROR_TITLE3", content: removedCoupons && removedCoupons.length < 1 ? "" : "APPLY_COUPON_ERROR_CONTENT3" };
    case CouponError.NOT_FOR_NEW_CUSTOMERS:
      return { title: removedCoupons && removedCoupons.length < 1 ? "APPLY_COUPON_ERROR_CONTENT4" : "APPLY_COUPON_ERROR_TITLE3", content: removedCoupons && removedCoupons.length < 1 ? "" : "APPLY_COUPON_ERROR_CONTENT4" };

    default:
      return { title: "", content: "" };
  }
}

export const showTaxLabel = (paymentDescription?: GetInvoiceSimulationResponse | null, taxRegNumber?: string) => {
  if (!paymentDescription) {
    return false
  }

  if (paymentDescription && paymentDescription.getCustomerTaxExemptionEnabled()) {
    return !Boolean(taxRegNumber)
  }

  if (paymentDescription && !paymentDescription.getTaxesIncluded()) {
    return true
  }

  return false
}


export const getPurchaseDetails = (transactionId: string, paymentDescription?: GetInvoiceSimulationResponse.AsObject, plan?: IPlanWithCycles | null, cycle?: ICycle | null): IPurchaseEventDetails | undefined => {
  if (!paymentDescription || !plan || !cycle) {
    return undefined
  };

  const firstInvoicePrice = convertFormattedPriceToNumber(paymentDescription.firstInvoicePrice);
  const setupFeeValue = convertFormattedPriceToNumber(paymentDescription.setupFee);
  const selectedAddons = plan.addons.filter(addon => addon.units);
  const defaultItemProps = { item_category: "addon", item_list_name: "Billsby_checkout" };

  const getPrice = (addon: IAddOn) => {
    const pricingModel = addon.pricingModels.find(pm => pm.frequency === paymentDescription.frequency && pm.frequencyType === paymentDescription.frequencyType);
    const selectedCycle = plan.cycles.find(c => c.cycleId === cycle?.cycleId);
    if(addon.pricingModelType === AddOnPricingModelType.AddonFlatFee) {
      return pricingModel?.flatFeePrice ? pricingModel.flatFeePrice / 100 : 0
    } 
    if(selectedCycle && pricingModel && addon.units) {
      return convertFormattedPriceToNumber(calculateAddOnPrice(selectedCycle, pricingModel, addon.units, addon.pricingModelType).priceFormatted || "$0.00");
    }
    return 0
  }

  const items: Array<IPurchaseEventItem> = [{
    item_id: plan.planId.toString(),
    item_name: plan.name,
    item_category: "plan",
    item_list_name: "Billsby_checkout",
    index: 0,
    price: firstInvoicePrice
  }];

  if (setupFeeValue) {
    items.push({
      item_name: "setupFee",
      price: setupFeeValue,
      index: 1,
      ...defaultItemProps
    })
  }

  if (selectedAddons) {
    selectedAddons.forEach((addon, idx) => {
      items.push({
        item_name: addon.name,
        item_id: addon.id.toString(),
        quantity: addon.units,
        price: getPrice(addon),
        index: idx + (setupFeeValue ? 2 : 1),
        ...defaultItemProps
      })
    })
  }

  return ({
    transaction_id: transactionId,
    currency: plan.currency,
    value: firstInvoicePrice,
    items
  }) as IPurchaseEventDetails;
}