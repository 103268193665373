import React, { useEffect, useRef, useMemo } from "react";
import Input from "../ui/input/Input";
import counterpart from "counterpart";
import { AutocompleteFields } from "../../models/Address";
import { countryStates } from "../../utils/states/states";
import { ICountryState } from "../../models/CountryState";
import { ICountryRestricted } from "../../models/Country";

interface IAddressAutocomplete {
  id: string,
  hasError?: boolean,
  countries: Array<{ label: string, value: { iso2Code: string, iso3Code: string } }>,
  restrictedCountries?: Array<ICountryRestricted>,
  initialValue?: IGoogleSelectedAddress,
  onSelectedAddress?: (address: IGoogleSelectedAddress | null, isValid: boolean) => void,
  className?: string
}

export interface IGoogleSelectedAddress {
  city: string,
  line1: string,
  line2: string,
  state: { label: string, value: ICountryState },
  zipCode: string,
  countryName: string,
  countryIso2: string,
  countryIso3: string,
}

const AddressAutocomplete: React.FC<IAddressAutocomplete> = ({ id, hasError, initialValue, onSelectedAddress, className = "", countries,
  restrictedCountries }) => {

  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);

  const restrictedCountriesIso2 = useMemo(() => {
    if (!restrictedCountries) {
      return []
    }
    return restrictedCountries.map(rc => rc.iso2Code.toLocaleLowerCase());
  }, restrictedCountries)

  useEffect(() => {
    const google = (window as any).google;
    const options: google.maps.places.AutocompleteOptions = {
      types: ["address"],
      // google api support up to 5 restricted countries
      // to overcome this limit if the user set more than 5 restricted countries we allow to input any country via the google dropdown
      // but we show a warning with an error if the selected country is not supported
      componentRestrictions: restrictedCountriesIso2.length <= 5 ? { country: restrictedCountriesIso2 } : undefined
    }
    const inputElement = document.getElementById(id) as HTMLInputElement || null;
    autocomplete.current = new google.maps.places.Autocomplete(inputElement, options);

    if (autocomplete && autocomplete.current) {
      autocomplete.current.setFields(["address_component"]);
      autocomplete.current.addListener("place_changed", onChangeAutoComplete);
    }

    if (inputElement && initialValue && initialValue.line1 && initialValue.state && initialValue.countryName) {
      //let's initialize the input value, then it will be the library responsible to fill in the value every time the address changes
      inputElement.value = `${initialValue.line1}, ${initialValue.state.label}, ${initialValue.countryName}`;
    }

  }, []);

  const onChangeAutoComplete = () => {
    if (!autocomplete.current) {
      return;
    }
    //instead of assign the object to 'any' we use the google types library and force the strong typing to try remove errors at runtime
    const autoCompleteObj = autocomplete.current as any as google.maps.places.Autocomplete;
    const addressObject = autoCompleteObj.getPlace();
    const address = addressObject.address_components;

    if (!address) {
      return;
    }

    const _name = addressObject.name;
    const _country = address.find((d: any) => d.types[0] === AutocompleteFields.COUNTRY);
    const _city = address.find((d: any) => d.types[0] === AutocompleteFields.LOCALITY ||
      d.types[0] === AutocompleteFields.ADMIN_AREA_LVL3 ||
      d.types[0] === AutocompleteFields.SUBLOCALITY_LV1 || // To get cities for Brooklyn and others parts of New York
      d.types[0] === AutocompleteFields.POSTAL_TOWN); // //To get cities for UK and Sweden 
    const _street = address[0] && address[1] ? `${address[0].long_name} ${address[1].long_name}` : "";
    const _state = address.find((d: any) => d.types[0] === AutocompleteFields.ADMIN_AREA_LVL1);
    const _postalCode = address.find((d: any) => d.types[0] === AutocompleteFields.POSTAL_CODE);

    const _selectedCountry = _country ? countries.find(c => c.value.iso2Code === _country.short_name) : undefined;

    let _selectedState = undefined;
    // country.short_name returns the ISO2 code
    if (_state && ["US", "IN", "CA"].some(el => el === _country?.short_name)) {
      // we use the countryStates array only if the country is usa, india or canada
      _selectedState = countryStates.find(cs => cs.StateIso2 === _state.short_name);
    }

    if (!_selectedCountry || !restrictedCountriesIso2.includes(_selectedCountry.value.iso2Code.toLowerCase())) {
      onSelectedAddress && onSelectedAddress(null, false)
      return;
    }

    onSelectedAddress && onSelectedAddress({
      city: _city ? _city.long_name : "",
      line1: _street,
      line2: "",
      state: _selectedState
        ? {
          label: _selectedState.StateName,
          value: {
            CountryIso2: _selectedCountry.value.iso2Code,
            CountryIso3: _selectedCountry.value.iso3Code,
            StateIso2: _selectedState.StateIso2 || _selectedState.StateName,
            StateName: _selectedState.StateName
          } as ICountryState
        }
        : {
          label: _state ? _state.long_name : "",
          value: {
            CountryIso2: _selectedCountry.value.iso2Code,
            CountryIso3: _selectedCountry.value.iso3Code,
            StateIso2: _state ? _state.long_name : "",
            StateName: _state ? _state.long_name : ""
          } as ICountryState
        },
      zipCode: _postalCode ? _postalCode.long_name : "",
      countryName: _selectedCountry ? _selectedCountry.label : "",
      countryIso2: _selectedCountry ? _selectedCountry.value.iso2Code : "",
      countryIso3: _selectedCountry ? _selectedCountry.value.iso3Code : ""
    }, true)
  }

  /**
   * it seems difficult to remove the chrome input autofill but we need to do here to avoid overlapping the suggestion with the 
   * google maps dropdown, apparently the solution below works
   * https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
   */
  return (
    <Input
      id={id}
      className={className}
      isError={hasError}
      autoComplete="chrome-off"
      placeholder={counterpart("ADDRESS_PLACEHOLDER")}
    />
  )
}

export default AddressAutocomplete;