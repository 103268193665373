import { ICustomFieldWithValue } from "./CustomFields";
import { PaymentMethodType } from "./Payment";

export interface IBillsbyData {
  cid?: string,
  containerWebsite?: ContainerWebsite,
  firstName?: string,
  lastName?: string,
  email?: string,
  billingAddressLine1?: string,
  billingAddressLine2?: string,
  billingAddressCity?: string,
  billingAddressState: string,
  billingAddressZip?: string,
  billingAddressCountry?: string,
  shippingAddressLine1?: string,
  shippingAddressLine2?: string,
  shippingAddressCity?: string,
  shippingAddressState?: string,
  shippingAddressZip?: string,
  shippingAddressCountry?: string,
  companyName?: string,
  additionalEmails?: string,
  phoneNumberDialCode?: string,
  phoneNumberDialCountry?: string,
  phoneNumber?: string,
  marketingConsent?: boolean,
  customFields?: Array<Partial<ICustomFieldWithValue>>,
  paymentSourceType?: PaymentMethodType,
  achSession?: {
    customerId: string,
    fullName: string,
    last4Digits: string,
    bankName: string,
    stripePaymentId: string
  },
}

export enum BillsbyDataParams {
  type = "data-billsby-type",
  company = "data-billsby-company",
  product = "data-billsby-product",
  plan = "data-billsby-plan",
  cycle = "data-billsby-cycle",
  units = "data-billsby-units",
  redirect = "data-billsby-redirect",
  wmessage = "data-billsby-wmessage",
  action = "data-billsby-action",
  customer = "data-billsby-customer",
  subscription = "data-billsby-subscription",
  addons = "data-billsby-addons",
  allowances = "data-billsby-allowances",
  language = "data-billsby-language"
}

/* type of action passed in the query string */
export enum BillsbyDataAction {
  CANCEL_PLAN = "cancelplan",
  UPDATE_PAYMENT_DETAILS = "paymentdetails",
  CHANGE_PLAN = "changeplan",
  CHANGE_PERSONAL_DETAILS = "personaldetails",
  CHANGE_ADDRESS = "address",
  MANAGE_ADDONS = "manageaddons",
  VIEW_BILLING_HISTORY = "billinghistory",
  VIEW_BILLING_HISTORY_UNPAID = "billinghistoryunpaid",
  MANAGE_COUPONS = "managecoupons",
  MANAGE_ALLOWANCES = "manageallowances",
}

export enum BillsbyDataType {
  ACCOUNT = "account",
  CHECKOUT = "checkout"
}

export enum ContainerWebsite {
  BILLSBY_APP = "billsby_app",
  OTHER = "other"
}

export enum CheckoutViewMode {
  STANDARD = "STANDARD",
  STANDALONE = "STANDALONE"
}

export enum DashboardSettingsType {
  GettingStarted1 = "GettingStarted1",
  GettingStarted2 = "GettingStarted2",
  PreLive = "PreLive",
  OnTrial = "OnTrial",
  Live = "Live"
}

export enum CollectCompanyNameType {
  DoNotCollect = "DoNotCollect",
  Optional = "Optional",
  Compulsory = "Compulsory"
}

export interface ICheckoutConfig {
  companyName: string,
  companyId: number,
  terminologyProductSingular: string,
  terminologyProductPlural: string,
  terminologyPlanSingular: string,
  terminologyPlanPlural: string,
  terminologyUnitSingular: string,
  terminologyUnitPlural: string,
  terminologyAddonSingular: string,
  terminologyAddonPlural: string,
  terminologyAllowanceSingular: string,
  terminologyAllowancePlural: string,
  terminologySetupFeeSingular: string,
  terminologySetupFeePlural: string,
  terminologyCouponPlural: string,
  terminologyCouponSingular: string,
  terminologyDiscountPlural: string,
  terminologyDiscountSingular: string,
  isShoppingCartHidden: boolean,
  termsOfUseUrl: string,
  privacyPolicyUrl: string,
  redirectUrl: string,
  trackingCodeStart: string,
  trackingCodeEnd: string,
  terminologySubscriptionSingular: string,
  terminologySubscriptionPlural: string,
  isViewChangePlanVisible: boolean,
  isChangePersonalDetailsVisible: boolean,
  isChangeAddressVisible: boolean,
  isUpdatePaymentVisible: boolean,
  isManageAllowancesVisible: boolean,
  isManageAddonsVisible: boolean,
  isViewBillingHistoryVisible: boolean,
  isCancelPlanVisible: boolean,
  companyStatus: DashboardSettingsType,
  isProgressIndicatorVisible: boolean
  isVisaCardLogoVisible: boolean,
  isMasterCardLogoVisible: boolean,
  isAmexCardLogoVisible: boolean,
  isJCBCardLogoVisible: boolean,
  isDinersCardLogoVisible: boolean,
  isHowWasThisCalculatedVisible: boolean,
  isApplyCouponsVisible: boolean,
  isWixCompatibilityModeVisible: boolean,
  confirmationHeading: string,
  confirmationSubheading: string,
  confirmationCopy: string,
  confirmationButtonText: string,
  checkoutDefaultLanguage: string,
  collectCompanyNameType: CollectCompanyNameType,
  selfServiceAccountManagementDefaultLanguage: string,
  googleAnalyticsCode?: string
}