import React, { useState, useLayoutEffect, useEffect } from "react";
import "./ChangePlanStep.scss";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";
import { fetchCustomerSubscriptions, fetchPendingMinimumTermAmount, fetchSubscriptionOutstandingBalance, setCurrentSubscription } from "../../actions/subscriptionsActions";
import FormGroup from "../ui/form-group/FormGroup";
import CustomerSubscriptions from "../customer-subscription/CustomerSubscriptions";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import { AppState } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { payContractTerm, setChangePlanField } from "../../actions/changePlanActions";
import { ChangePlanScreen } from "../../models/ChangePlan";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { ICustomerSubscription } from "../../models/Customer";
import counterpart from "counterpart";
import { GetPendingMinimumTermAmountResponse } from "../../utils/grpc/generated/Billsby.Protos/billing/public/subscription/subscription_pb";

interface IChangePlanStep1 {
  /* optional button under the continue wrapper button */
  bottomContent?: JSX.Element
}

const ChangePlanStep1: React.FC<IChangePlanStep1> = ({ bottomContent  }) => {
  const [state, setState] = useState<{ selectedSubscription: number, filteredSubscriptions: Array<ICustomerSubscription>, isLoading: boolean}>({
    selectedSubscription: 0,
    filteredSubscriptions: [],
    isLoading: true
  });
  const { selectedSubscription, filteredSubscriptions, isLoading } = state;
  const { companyDomain, checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { mainProps: { customerUniqueId, subscriptionUniqueId } } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { currentSubscription } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const dispatch = useDispatch<Function>()

  const fetchSubscriptions = async () => {
    try {
      const { response: subscriptions } = await dispatch(fetchCustomerSubscriptions(companyDomain, customerUniqueId as string)) as { response: Array<ICustomerSubscription> };
      await dispatch(fetchSubscriptionOutstandingBalance(companyDomain, customerUniqueId as string, subscriptionUniqueId as string))
      if(!!currentSubscription?.contractMinimumTermEnd) {
        const { response: pendingMinimumAmount } = await dispatch(fetchPendingMinimumTermAmount(companyDomain, customerUniqueId as string, subscriptionUniqueId as string))  as { response: GetPendingMinimumTermAmountResponse };
        if(pendingMinimumAmount.getAmount() === 0) {
          await dispatch(payContractTerm(companyDomain, customerUniqueId as string, subscriptionUniqueId as string));
          dispatch(setCurrentSubscription({ ...currentSubscription, contractMinimumTermEnd: undefined, pendingMinimumTermAmount: undefined }))
        }
      }
      
      const filteredSubscriptions = subscriptions.filter(subscription => subscription.subscriptionUniqueId === subscriptionUniqueId as string );
      setState({ ...state, filteredSubscriptions });
    }
    catch (err) { }
    finally {
      setState({ ...state, isLoading: false });
    }
  }

  
  useLayoutEffect(() => {
    fetchSubscriptions();
  }, []);

  useEffect(() => {
    if(filteredSubscriptions[selectedSubscription]) {
      onSubmit();
    }
  }, [filteredSubscriptions]);
  
  const onSubmit = () => {
    dispatch(setChangePlanField("selectedSubscription", filteredSubscriptions[selectedSubscription]))
    dispatch(setChangePlanField("currentScreen", ChangePlanScreen.STEP2));
  }

  if(isLoading || !checkoutConfig) {
    return null;
  }

  return (
    <PageWrapper 
      className="change-plan-step1" 
      btnText={counterpart("CHANGE_PLAN_BTN", { planLabel: checkoutConfig.terminologyPlanSingular })} 
      shouldTranslateText={false} 
      btnDisabled={selectedSubscription < 0} 
      btnCallback={onSubmit} 
      bottomStickyContent={bottomContent}>
      <FormGroup>
        <CustomerSubscriptions
          subscriptions={filteredSubscriptions}
          selectedSubscriptionIdx={selectedSubscription}
          onClick={(selectedSubscription) => setState({  ...state, selectedSubscription })}
        />
      </FormGroup>
    </PageWrapper>
  )
}

export default ChangePlanStep1