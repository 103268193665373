import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { ChangePlanScreen, PlanChangeDate } from "../models/ChangePlan";
import { SET_IS_UPDATE_SUBSCRIPTION, CHANGE_PLAN_CYCLE_FAILURE, CHANGE_PLAN_CYCLE_REQUEST, CHANGE_PLAN_CYCLE_SUCCESS, SET_CHANGE_PLAN_FIELD, RESET_CHANGE_PLAN_STATE, CHANGE_PLAN_REQUEST, CHANGE_PLAN_SUCCESS, CHANGE_PLAN_FAILURE, PAY_CONTRACT_TERM_REQUEST, PAY_CONTRACT_TERM_FAILURE, PAY_CONTRACT_TERM_SUCCESS } from "../actions/changePlanActions";
import { ICustomerSubscription } from "../models/Customer";
import { GET_PRICE_DESCRIPTION_REQUEST, GET_PRICE_DESCRIPTION_SUCCESS, GET_PRICE_DESCRIPTION_FAILURE } from "../actions/paymentActions";
import { GetInvoiceSimulationResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/invoice/invoice_pb";
import { CROSS_DOMAIN_EVENT, sendEvent } from "../utils/crossDomainEvents";

export interface IChangePlanReducer {
  currentScreen: ChangePlanScreen;
  selectedSubscription?: ICustomerSubscription;
  selectedDateToChange: PlanChangeDate;
  billingDate?: string;
  paymentDescription?: GetInvoiceSimulationResponse;
  paymentDescriptionIsLoading: boolean,
  isChangePlanRequest: boolean;
  isChangePlanSuccess: boolean;
  isChangePlanFailure: boolean;
  isChangePlanCycleRequest: boolean;
  isChangePlanCycleSuccess: boolean;
  isChangePlanCycleFailure: boolean;
  isPayContractTermRequest: boolean;
  isPayContractTermSuccess: boolean;
  isPayContractTermFailure: boolean;
  isUpdateSubscription: boolean;
}

export const initialState: IChangePlanReducer = {
  currentScreen: ChangePlanScreen.STEP1,
  selectedSubscription: undefined,
  selectedDateToChange: PlanChangeDate.IMMEDIATE,
  billingDate: "",
  paymentDescription: undefined,
  paymentDescriptionIsLoading: true,
  isChangePlanRequest: false,
  isChangePlanSuccess: false,
  isChangePlanFailure: false,
  isChangePlanCycleRequest: false,
  isChangePlanCycleSuccess: false,
  isChangePlanCycleFailure: false,
  isPayContractTermRequest: false,
  isPayContractTermSuccess: false,
  isPayContractTermFailure: false,
  isUpdateSubscription: false
};

export default function changePlanReducer(state = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case SET_CHANGE_PLAN_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue };
    case GET_PRICE_DESCRIPTION_REQUEST:
      return { ...state, paymentDescriptionIsLoading: true };
    case GET_PRICE_DESCRIPTION_SUCCESS:
      return { ...state, paymentDescription: action.response, paymentDescriptionIsLoading: false };
    case GET_PRICE_DESCRIPTION_FAILURE:
      return { ...state, paymentDescriptionIsLoading: false };
    case RESET_CHANGE_PLAN_STATE:
      return { ...initialState };
    case CHANGE_PLAN_REQUEST:
      return { ...state, isChangePlanRequest: true, isChangePlanSuccess: false, isChangePlanFailure: false };
    case CHANGE_PLAN_SUCCESS:
      sendEvent(CROSS_DOMAIN_EVENT.CHANGE_PLAN_SUCCESS)
      return { ...state, isChangePlanRequest: false, isChangePlanSuccess: true, isChangePlanFailure: false };
    case CHANGE_PLAN_FAILURE:
      return { ...state, isChangePlanRequest: false, isChangePlanSuccess: false, isChangePlanFailure: true };
    case CHANGE_PLAN_CYCLE_REQUEST:
      return { ...state, isChangePlanCycleRequest: true, isChangePlanCycleSuccess: false, isChangePlanCycleFailure: false };
    case CHANGE_PLAN_CYCLE_SUCCESS:
      sendEvent(CROSS_DOMAIN_EVENT.CHANGE_PLAN_SUCCESS)
      return { ...state, isChangePlanCycleRequest: false, isChangePlanCycleSuccess: true, isChangePlanCycleFailure: false };
    case CHANGE_PLAN_CYCLE_FAILURE:
      return { ...state, isChangePlanCycleRequest: false, isChangePlanCycleSuccess: false, isChangePlanCycleFailure: true };
    case SET_IS_UPDATE_SUBSCRIPTION:
      return { ...state, isUpdateSubscription: action.payload }
    case PAY_CONTRACT_TERM_REQUEST:
      return { ...state, isPayContractTermRequest: true, isPayContractTermSuccess: false, isPayContractTermFailure: false };
    case PAY_CONTRACT_TERM_SUCCESS:
      return { ...state, isPayContractTermRequest: false, isPayContractTermSuccess: true, isPayContractTermFailure: false };
    case PAY_CONTRACT_TERM_FAILURE:
      return { ...state, isPayContractTermRequest: false, isPayContractTermSuccess: false, isPayContractTermFailure: true };
    default:
      return state;
  }
}
