import React from "react";
import { ITier } from "../../../models/Product";
import Text from "../../ui/text/Text";
import { ICheckoutConfig } from "../../../models/BillsbyData";

interface Props {
  tier: ITier,
  checkoutConfig: ICheckoutConfig,
  frequency: string,
  unit: string,
  units: string
}

const TieredCyclePriceInfo = (props: Props) => {
  const { tier, checkoutConfig, frequency, unit, units } = props;

  const getLabel = () => {
    const min = 1;
    const max = 1000000;

    if (tier.start === min && tier.finish < max)
      return (
        <Text
          content="SELECT_PLAN_TIERED_CYCLE_UP_TO"
          translateWith={{ finish: tier.finish, units: units }}
          className="cycle-price-info__tiered-price__title"
          noMargin
        />
      )

    if (tier.start > min && tier.finish < max)
      return (
        <Text
          content="SELECT_PLAN_TIERED_CYCLE_BETWEEN"
          translateWith={{ start: tier.start, finish: tier.finish, units: units }}
          className="cycle-price-info__tiered-price__title"
          noMargin
        />
      )

    if (tier.finish === max) return (
      <Text
        content="SELECT_PLAN_TIERED_CYCLE_AND_UP"
        translateWith={{ start: tier.start, units: tier.start === 1 ? unit : units }}
        className="cycle-price-info__tiered-price__title"
        noMargin
      />
    )

    return null;
  }

  const getDescription = () => {
    const min = 1;
    const max = 1000000;

    const priceFormatted = tier.priceFormatted ? tier.priceFormatted : tier.price / 100;
    if (tier.start === min && tier.finish < max) {
      return (
        <Text
          className="cycle-price-info__plan-info"
          content="SELECT_PLAN_TIERED_CYCLE_FOR_YOUR_FIRST"
          translateWith={{
            priceFormatted,
            unit,
            units,
            frequency,
            finish: tier.finish
          }}
          noMargin
        />
      )
    }
    const numberOfUnits = (tier.finish - tier.start) + 1;
    if (tier.start > min && tier.finish < max) {
      return (
        <Text
          className="cycle-price-info__plan-info"
          content="SELECT_PLAN_TIERED_CYCLE_FOR_THE_NEXT"
          translateWith={{
            priceFormatted,
            unit,
            units,
            frequency,
            numberOfUnits
          }}
          noMargin
        />
      )
    }
    if (tier.finish === max) {
      return (
        <Text
          className="cycle-price-info__plan-info"
          content="SELECT_PLAN_TIERED_CYCLE_FOR_REST"
          translateWith={{
            priceFormatted,
            unit,
            units,
            frequency
          }}
          noMargin
        />
      )
    }
    return null;
  }

  return (
    <>
      {getLabel()}
      {getDescription()}
    </>
  )
}

export default TieredCyclePriceInfo
