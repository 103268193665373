export interface ICardDetailInputDetail {        
  paymentCardToken: string,
  expiryMonth: string,
  expiryYear: string,
  cardType: string,
  last4Digits: string,
  fullName: string,
  ipAddress?: string
}

export interface ICardDetailInputDetailSCA extends ICardDetailInputDetail {        
  browserInfo: string,
  transactionToken?: string,
  redirectUrl: string,
  callbackUrl: string
}

export enum UpdatePaymentStatusSCA {
  Unassigned = "Unassigned",
  Pending = "Pending",
  UpdateCompleted = "UpdateCompleted"
}

export interface IUpdatePaymentResponseSCA {
  status: UpdatePaymentStatusSCA,
  transactionToken: string
}