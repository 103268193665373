import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import Panel, { IPanel } from "../panel/Panel";
import "./CollapsiblePanel.scss"

export interface ICollapsiblePanel extends IPanel {
  id: string,
  isExpanded?: boolean,
  isLocked?: boolean,
  isModal?: boolean,
  isFloating?: boolean,
  modalPosition?: "top" | "bottom",
  scrollIntoViewIfExpanded?: boolean,
  className?: string,
  parentContainer?: Element | null,
  expandableContent?: React.ReactNode,
  style?: CSSProperties
}

const CollapsiblePanel: React.FC<ICollapsiblePanel> = (({ id, children, isLocked, isExpanded, expandableContent, isModal = false, scrollIntoViewIfExpanded = false, parentContainer, modalPosition = "top", isFloating = true, style, ...rest }) => {
  const defaultHeight = 104;
  const [height, setHeight] = useState(defaultHeight);
  const [scrollToView, setScrollToView] = useState(false);
  const [scrollSmooth, setScrollSmooth] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const collapsibleRef = useRef<HTMLDivElement | null>(null);

  const _onClick = (evt: any) => {
    //Set height of the collapsible panel depending on the content to prevent jumpy effect
    setHeight(evt.currentTarget.offsetHeight);
    //Add smooth animation on click only
    setScrollSmooth(true);
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)

    if (isExpanded && isModal && scrollIntoViewIfExpanded) {
      setScrollToView(true)
    } else {
      setScrollToView(false)
    }
  }, [isExpanded])

  useEffect(() => {
    setTimeout(() => {
      if (collapsibleRef && collapsibleRef.current && scrollToView && parentContainer) {
        //Scroll to view when expended and to see the selected plan when going back to screen
        const containerHeight = collapsibleRef.current.getBoundingClientRect().height;
        parentContainer.scrollTo({ top: collapsibleRef.current.offsetTop - (containerHeight + (containerHeight/2)), behavior: scrollSmooth ? "smooth" : "auto" })
      }
    }, 100)
  }, [scrollToView, collapsibleRef, scrollSmooth])

  const isSmallWindow = windowWidth <= 500;

  const renderContent = () => {
    if (isModal) {
      return (
        <div className={`collapsible-panel__modal collapsible-panel__modal--${modalPosition} ${isMobileOnly && isSmallWindow && isExpanded ? "collapsible-panel__modal--no-bottom-radius" : ""}`} style={style}>
          <Panel id={id} isSelected={isExpanded} {...rest} className={`${rest.className} panel--inner`} >
            {children}
            {!isMobileOnly && expandableContent && isExpanded && <ExpandableContent>{expandableContent}</ExpandableContent>}
          </Panel>
          {isMobileOnly && isSmallWindow && expandableContent && isExpanded && <ExpandableContent>{expandableContent}</ExpandableContent>}
        </div>
      )
    }

    return <div className="collapsible-panel__default"><Panel id={id} isSelected={isExpanded} {...rest} className={`${rest.className} panel--inner`} >{children}{expandableContent && <ExpandableContent>{expandableContent}</ExpandableContent>}</Panel></div>
  }

  return (
    <div ref={collapsibleRef} style={{ height: isModal && isExpanded && isFloating ? `${height}px` : "auto" }} className={`collapsible-panel${isLocked ? " collapsible-panel--locked" : ""}${isExpanded ? " collapsible-panel--expanded" : ""} ${isModal ? " collapsible-panel--modal" : ""} ${isFloating ? " collapsible-panel--floating" : ""} ${rest.image ? "collapsible-panel--has-image" : ""}`} onClick={_onClick}>
      {renderContent()}
    </div>
  )
});

const ExpandableContent: React.FC = ({ children }) => {
  //Prevent from collapsing the panel when interacting with the content
  const onClick = (evt: any) => {
    evt.stopPropagation()
  }

  return (
    <div className="collapsible-panel__expanded-content" onClick={onClick}>{children}</div>
  )
};

//Example component usage
/*
  <CollapsiblePanel isExpanded>
    <Panel isClickable>
       Sample Panel Content
      <CollapsiblePanel.ExpandableContent>
        Sample collapsible expandable content
      </CollapsiblePanel.ExpandableContent>
    </Panel>
  </CollapsiblePanel> 
*/

export default CollapsiblePanel