import React, { ReactElement, useMemo } from "react";
import { Switch, Route, RouteProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import counterpart from "counterpart";
import { fetchBranding } from "../../actions/globalActions";
import Container from "../container/Container";
import CloseBtn from "../../components/ui/close-btn/CloseBtn";
import { getBrandingInlineStyle } from "../../utils/brandingUtils";
import "./LoginRoute.scss";
import ContainerError from "../container-error/ContainerError";
import { useFetch } from "../../utils/custom-hooks";
interface ILoginRoute extends RouteProps {
  children: Array<ReactElement> | ReactElement
}

const LoginRoute: React.FC<ILoginRoute> = ({ children, path, exact }) => {
  const isLoadingScreen = useSelector((state: AppState) => state.loginReducer.isLoadingScreen)
  const { companyDomain, isCloseIconVisible, branding } = useSelector((state: AppState) => state.globalReducer);

  const dispatch = useDispatch<Function>();

  const { isLoading: isLoadingBranding } = useFetch(() => dispatch(fetchBranding(companyDomain)));

  const customBranding = useMemo(() => {
    return getBrandingInlineStyle(branding);
  }, [branding]);

  if (isLoadingBranding) {
    return null;
  }

  if (!companyDomain) {
    return (
      <Container>
        <CloseBtn isVisible={isCloseIconVisible} />
        <ContainerError
          title="ERROR_INVOKING_BILLSBY"
          subTitle={<div>
            <b>{counterpart("ERROR_INVOKING_BILLSBY_COMPANY_NOT_PRESENT1")}</b> {counterpart("ERROR_INVOKING_BILLSBY_COMPANY_NOT_PRESENT2")}
          </div>}
        />
      </Container>
    )
  }


  if (isLoadingScreen) {
    // the login screen is dynamic and to avoid seeing some flashy content in the browser (due to the re-routing to the correct screen) 
    // we keep the screen empty until the right screen is determined
    return (
      <div style={{ display: "none" }}>
        <Route path={path} exact={exact}>
          <Switch>
            {children}
          </Switch>
        </Route>
      </div>
    )
  }

  return (
    <Route path={path} exact={exact}>
      <Container className="login-route">
        <style>{customBranding}</style>
        <Switch>
          {children}
        </Switch>
      </Container>
    </Route>
  )
}

export default LoginRoute;