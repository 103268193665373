import { PersonalDetailsScreen, PersonalDetails } from "../models/PersonalDetails";
import { validateEmail, EMAIL_REGEX } from "../utils/commonUtils";
import { RESET_PERSONAL_DETAILS_ERRORS, SET_FIRST_NAME, SET_PERSONALDETAILS_CURRENT_SCREEN, SET_LAST_NAME, SET_TAX_REG_NUMBER, SET_EMAIL, SET_ADDRESS_LINE_1, SET_ADDRESS_LINE_2, SET_CITY, SET_STATE, SET_ZIP_CODE, SET_COUNTRY, FETCH_COUNTRIES_SUCCESS, VALIDATE_BILLING_ADDRESS, SET_SHIPPING_AS_BILLING_FLAG, SET_SHIPPING_AS_BILLING_ADDRESS, VALIDATE_SHIPPING_ADDRESS, SET_FIELD as SET_FIELD, VALIDATE_CUSTOM_FIELDS, FETCH_CUSTOM_FIELDS_SUCCESS, SET_CUSTOM_FIELD_VALUE, SET_CUSTOMER_UNIQUE_ID, RESET_COUNTRY_STATE, SET_SUBSCRIPTION_UNIQUE_ID, FETCH_SHIPPING_ADDRESS_SUCCESS, UPDATE_SHIPPING_ADDRESS_SUCCESS, UPDATE_SHIPPING_ADDRESS_FAILURE, FETCH_TAX_VALIDATION_SUCCESS, VALIDATE_TAX_SUCCESS, VALIDATE_TAX_FAILURE, RESET_PERSONAL_ADDRESS_ERRORS, SET_IS_SHIPPING_ADDRESS_MANUAL, SET_IS_BILLING_ADDRESS_MANUAL, VALIDATE_TAX_REQUEST, SET_IS_USER_DATA_SCREEN_PRELOADED, SET_IS_ADDRESS_SCREEN_PRELOADED, SET_TAX_REG_ERROR, SET_COMPANY_NAME } from "../actions/personalDetailsActions";
import { ICountry, ICountryRestricted } from "../models/Country";
import { ICountryState } from "../models/CountryState";
import { CheckoutAction } from "../models/CheckoutAction";
import { TaxCollection, TaxRegNumberError } from "../models/Taxes";
import { ICustomFieldWithValue, CustomFieldType } from "../models/CustomFields";
import { MAX_SINGLE_LINE_INPUT, MAX_MULTI_LINE_INPUT } from "../utils/constants";
import { AppState } from "..";
import { countryStates } from "../utils/states/states";
import { IAddress, IBillingAddress, IShippingAddress } from "../models/Address";
import { FETCH_PERSONAL_DETAILS_SUCCESS } from "../actions/changePersonalDetailsActions";
import { GetTaxValidationResponse, ValidateTaxResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/company/taxation/taxation_pb";
import { SET_PRODUCT } from "../actions/selectPlanActions";
import { IProduct } from "../models/Product";
const { isValidNumber } = (global as any).intlTelInputUtils;

interface IMainDataErrors {
  firstName?: string,
  lastName?: string,
  email?: string
}

interface IAddressDataErrors {
  address1?: string
  city?: string
  state?: string
  zipCode?: string
  country?: string
}

interface ICustomFieldsErrors {
  [key: string]: any,
  additionalEmails?: string,
  phoneNumber?: string
}

export interface IPersonalDetailsReducerState {
  currentScreen: PersonalDetailsScreen,
  isUserDataScreenPreloaded: boolean,
  isAddressScreenPreloaded: boolean,
  isShippingAddressManual: boolean,
  isBillingAddressManual: boolean,
  shippingAsBilling: boolean,
  mainDataErrors: IMainDataErrors,
  addressDataErrors: IAddressDataErrors,
  shippingAddressDataErrors: IAddressDataErrors,
  countries: Array<ICountryRestricted>,
  countriesDropdown: Array<{ label: string, value: ICountryRestricted }>,
  mainProps: {
    customerUniqueId: string | null,
    subscriptionUniqueId: string | null,
    firstName: string | null,
    lastName: string | null,
    email: string | null,
    companyName: string | null
  },
  address: IBillingAddress,
  shippingAddress: IShippingAddress,
  fields: {
    additionalEmails: string,
    phoneNumber: string,
    phoneNumberPrefix: string,
    phoneNumberCountry: string,
    marketingConsent: boolean,
    isAdditionalEmailsPreLoaded?: boolean,
    isPhoneNumberPreLoaded?: boolean,
    isPhoneNumberPrefixPreLoaded?: boolean,
    isMarketingConsentPreLoaded?: boolean,
    errors: ICustomFieldsErrors,
    customFields: Array<ICustomFieldWithValue>,
    preLoadedCustomFields: Array<Partial<ICustomFieldWithValue>>
  },
  taxRegNumberRequirementType: TaxCollection
  taxRegNumber: string
  taxRegError: TaxRegNumberError | null,
  personalDetailsErrors: Array<string>
}

export const initialState = {
  currentScreen: PersonalDetailsScreen.MAIN_DATA,
  isUserDataScreenPreloaded: false,
  isAddressScreenPreloaded: false,
  isShippingAddressManual: false,
  isBillingAddressManual: false,
  shippingAsBilling: true,
  mainDataErrors: {},
  addressDataErrors: {},
  shippingAddressDataErrors: {},
  countries: [],
  countriesDropdown: [],
  // this data can be perloaded
  mainProps: {
    customerUniqueId: null,
    subscriptionUniqueId: null,
    firstName: null,
    lastName: null,
    email: null,
    companyName: null
  },
  address: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    country: null,
    statesDropdown: [],
    stateObject: null
  },
  shippingAddress: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    country: null,
    statesDropdown: [],
    stateObject: null,
    isUpdateShippingAddressSuccess: false,
    isUpdateShippingAddressFailure: false
  },
  fields: {
    additionalEmails: "",
    phoneNumber: "",
    phoneNumberPrefix: "",
    phoneNumberCountry: "",
    marketingConsent: true,
    isAdditionalEmailsPreLoaded: false,
    isPhoneNumberPreLoaded: false,
    isPhoneNumberPrefixPreLoaded: false,
    isMarketingConsentPreLoaded: false,
    errors: {},
    customFields: [],
    preLoadedCustomFields: []
  },
  taxRegNumberRequirementType: TaxCollection.DO_NOT_COLLECT,
  taxRegNumber: "",
  taxRegError: null,
  personalDetailsErrors: []
}

const validateMainData = (state: IPersonalDetailsReducerState, action: CheckoutAction) => {
  var errorModel = {} as IMainDataErrors;
  let personalDetailsErrors: Array<string> = [];

  if (!state.mainProps.firstName) {
    errorModel.firstName = "INVALID";
    personalDetailsErrors.push("PERSONAL_DETAILS_FIRST_NAME")
  }
  if (!state.mainProps.lastName) {
    errorModel.lastName = "INVALID";
    personalDetailsErrors.push("PERSONAL_DETAILS_LAST_NAME")
  }
  if (!state.mainProps.email) {
    errorModel.email = "INVALID";
    personalDetailsErrors.push("PERSONAL_DETAILS_EMAIL")
  }
  const email = state.mainProps.email;
  if (email && !validateEmail(email)) {
    errorModel.email = "INVALID";
  }

  if (Object.keys(errorModel).length && action.payload !== PersonalDetailsScreen.MAIN_DATA) {
    return { ...state, mainDataErrors: errorModel, personalDetailsErrors }
  }

  return { ...state, currentScreen: action.payload, mainDataErrors: {} };
}

const validateBillingAddress = (state: IPersonalDetailsReducerState, action: CheckoutAction) => {
  const addressDataErrors = {} as IAddressDataErrors;
  let personalDetailsErrors: Array<string> = [];

  if (!state.address.address1) {
    addressDataErrors.address1 = "INVALID";
    personalDetailsErrors.push("PERSONAL_DETAILS_ADDRESS_LINE_1");
  }
  if (!state.address.city) {
    addressDataErrors.city = "INVALID";
    personalDetailsErrors.push("PERSONAL_DETAILS_CITY");
  }
  if (!state.address.state) {
    addressDataErrors.state = "INVALID";
    personalDetailsErrors.push("PERSONAL_DETAILS_STATE");
  }
  if (!state.address.zipCode) {
    addressDataErrors.zipCode = "INVALID";
    personalDetailsErrors.push("PERSONAL_DETAILS_ZIPCODE");
  }
  if (!state.address.country) {
    addressDataErrors.country = "INVALID";
    personalDetailsErrors.push("PERSONAL_DETAILS_COUNTRY");
  }

  return { ...state, addressDataErrors, shippingAddressDataErrors: {}, personalDetailsErrors }
}

const validateShippingAddress = (state: IPersonalDetailsReducerState, action: CheckoutAction) => {
  const shippingAddressDataErrors = {} as IAddressDataErrors;
  let personalDetailsErrors: Array<string> = [];

  if (!state.shippingAddress.address1) {
    shippingAddressDataErrors.address1 = "INVALID";
    personalDetailsErrors.push("PERSONAL_DETAILS_ADDRESS_LINE_1");
  }
  if (!state.shippingAddress.city) {
    shippingAddressDataErrors.city = "INVALID";
    personalDetailsErrors.push("PERSONAL_DETAILS_CITY");
  }
  if (!state.shippingAddress.state) {
    shippingAddressDataErrors.state = "INVALID";
    personalDetailsErrors.push("PERSONAL_DETAILS_STATE");
  }
  if (!state.shippingAddress.zipCode) {
    shippingAddressDataErrors.zipCode = "INVALID";
    personalDetailsErrors.push("PERSONAL_DETAILS_ZIPCODE");
  }
  if (!state.shippingAddress.country) {
    shippingAddressDataErrors.country = "INVALID";
    personalDetailsErrors.push("PERSONAL_DETAILS_COUNTRY");
  }

  return { ...state, shippingAddressDataErrors, personalDetailsErrors };
}

const validateCustomFields = (state: IPersonalDetailsReducerState, action: CheckoutAction, store: AppState) => {
  const { product } = store.selectPlanReducer;
  const { fields: customFields } = state;
  const { additionalEmails, phoneNumber, phoneNumberPrefix } = customFields;
  let errors: ICustomFieldsErrors = {}
  let personalDetailsErrors: Array<string> = [];

  if (additionalEmails && !additionalEmails.match(EMAIL_REGEX) && product && product.value.isAdditionalEmailRequired) {
    errors.additionalEmails = "INVALID";
  }

  if (phoneNumber && !isValidNumber(`+${phoneNumberPrefix}${phoneNumber}`) && product && product.value.isPhoneNumberRequired) {
    errors.phoneNumber = "INVALID";
  }

  state.fields.customFields.forEach((customField: ICustomFieldWithValue, index: number) => {
    const labelError = "CustomField" + customField.customFieldId;

    if (customField.compulsory && !customField.value) {
      errors[labelError] = "INVALID";
      personalDetailsErrors.push(customField.label)
    } else {
      switch (customField.type) {
        case CustomFieldType.SingleLineTextField:
          if (customField.value && customField.value.length >= MAX_SINGLE_LINE_INPUT) {
            errors[labelError] = "INVALID";
          }
          break;
        case CustomFieldType.MultiLineTextField:
          if (customField.value && customField.value.length >= MAX_MULTI_LINE_INPUT) {
            errors[labelError] = "INVALID";
          }
          break;
        case CustomFieldType.CheckboxField:
          if (customField.compulsory && !customField.value) {
            errors[labelError] = "INVALID";
          }
          break;
      }
    }
  })

  return { ...state, fields: { ...state.fields, errors }, personalDetailsErrors };
}

export default function personalDetailsReducer(state: IPersonalDetailsReducerState = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case SET_PERSONALDETAILS_CURRENT_SCREEN:
      return validateMainData(state, action);
    case VALIDATE_BILLING_ADDRESS:
      return validateBillingAddress(state, action);
    case VALIDATE_SHIPPING_ADDRESS:
      return validateShippingAddress(state, action);
    case VALIDATE_CUSTOM_FIELDS:
      return validateCustomFields(state, action, store);
    case SET_CUSTOMER_UNIQUE_ID:
      return { ...state, mainProps: { ...state.mainProps, customerUniqueId: action.payload } }
    case SET_SUBSCRIPTION_UNIQUE_ID:
      return { ...state, mainProps: { ...state.mainProps, subscriptionUniqueId: action.payload } }
    case SET_FIRST_NAME:
      return { ...state, mainProps: { ...state.mainProps, firstName: action.payload } }
    case SET_LAST_NAME:
      return { ...state, mainProps: { ...state.mainProps, lastName: action.payload } }
    case SET_EMAIL:
      return { ...state, mainProps: { ...state.mainProps, email: action.payload } }
    case SET_COMPANY_NAME:
      return { ...state, mainProps: { ...state.mainProps, companyName: action.payload } }
    case SET_ADDRESS_LINE_1:
      return { ...state, [action.isBilling ? "address" : "shippingAddress"]: { ...state[action.isBilling ? "address" : "shippingAddress"], address1: action.payload } }
    case SET_ADDRESS_LINE_2:
      return { ...state, [action.isBilling ? "address" : "shippingAddress"]: { ...state[action.isBilling ? "address" : "shippingAddress"], address2: action.payload } }
    case SET_CITY:
      return { ...state, [action.isBilling ? "address" : "shippingAddress"]: { ...state[action.isBilling ? "address" : "shippingAddress"], city: action.payload } }
    case SET_STATE: {
      const selectedState = action.payload as { label: string, value: ICountryState };
      const stateIso2 = (selectedState.value && selectedState.value.StateIso2) ? selectedState.value.StateIso2 : "";
      return { ...state, [action.isBilling ? "address" : "shippingAddress"]: { ...state[action.isBilling ? "address" : "shippingAddress"], state: stateIso2, stateObject: selectedState } }
    }
    case RESET_COUNTRY_STATE:
      return { ...state, [action.isBilling ? "address" : "shippingAddress"]: { ...state[action.isBilling ? "address" : "shippingAddress"], state: "", stateObject: null } }
    case SET_ZIP_CODE:
      return { ...state, [action.isBilling ? "address" : "shippingAddress"]: { ...state[action.isBilling ? "address" : "shippingAddress"], zipCode: action.payload } }
    case SET_COUNTRY: {
      const selectedCountry = action.payload as { label: string, value: ICountryRestricted };
      let statesDropdown: Array<{ label: string, value: ICountryState }> = [];
      if (selectedCountry.value) {
        const statesOptions = countryStates.filter(state => state.CountryIso2 === selectedCountry.value.iso2Code);
        statesDropdown = statesOptions.map((countryState: ICountryState) => ({ label: countryState.StateName, value: countryState }));
      }
      return { ...state, [action.isBilling ? "address" : "shippingAddress"]: { ...state[action.isBilling ? "address" : "shippingAddress"], country: action.payload, statesDropdown } }
    }
    case FETCH_COUNTRIES_SUCCESS:
      return { ...state, countries: action.response, countriesDropdown: action.response.sort((a: ICountryRestricted, b: ICountryRestricted) => (a.name > b.name) ? 1 : (a.name === b.name) ? 0 : -1).map((country: ICountryRestricted) => ({ label: country.name, value: country })) }
    case SET_SHIPPING_AS_BILLING_FLAG:
      return { ...state, shippingAsBilling: action.payload }
    case SET_SHIPPING_AS_BILLING_ADDRESS:
      return { ...state, shippingAddress: { ...state.address } }
    case SET_FIELD:
      return { ...state, fields: { ...state.fields, [action.fieldName]: action.payload } }
    case FETCH_CUSTOM_FIELDS_SUCCESS:
      return { ...state, fields: { ...state.fields, customFields: action.response } }
    case SET_CUSTOM_FIELD_VALUE: {
      let newArr = [...state.fields.customFields];
      newArr[action.index].value = action.payload;
      return { ...state, fields: { ...state.fields, customFields: newArr } }
    }
    case RESET_PERSONAL_DETAILS_ERRORS: {
      return { ...state, personalDetailsErrors: [] }
    }
    case RESET_PERSONAL_ADDRESS_ERRORS:
      return { ...state, addressDataErrors: [], shippingAddressDataErrors: [] }

    case FETCH_PERSONAL_DETAILS_SUCCESS: {
      const personalDetails = action.response as PersonalDetails;
      let { billingAddress } = personalDetails;
      const statesOptions = countryStates.filter(state => state.CountryIso2 === billingAddress.country || state.CountryIso3 === billingAddress.country);
      const statesDropdown = statesOptions.map((countryState: ICountryState) => ({ label: countryState.StateName, value: countryState }));
      const selectedState: { label: string, value: ICountryState } = statesDropdown.find(state => state.value.StateIso2 === billingAddress.state)
        || { label: billingAddress.state, value: { StateIso2: billingAddress.state, StateName: billingAddress.state, CountryIso3: billingAddress.country, CountryIso2: billingAddress.country } };

      return {
        ...state,
        address: {
          address1: billingAddress.addressLine1,
          address2: billingAddress.addressLine2,
          city: billingAddress.city,
          state: billingAddress.state,
          zipCode: billingAddress.postCode,
          country: (state.countriesDropdown as Array<{ label: string, value: ICountry }>).find(el => el.value.iso2Code === billingAddress.country || el.value.iso3Code === billingAddress.country) || "",
          statesDropdown,
          stateObject: selectedState
        }
      };
    }

    case FETCH_SHIPPING_ADDRESS_SUCCESS: {
      const { addressLine1, addressLine2, state: _state, city, country, postCode } = action.response as IAddress;
      const statesOptions = countryStates.filter(state => state.CountryIso2 === country || state.CountryIso3 === country);
      const statesDropdown = statesOptions.map((countryState: ICountryState) => ({ label: countryState.StateName, value: countryState }));
      const selectedState = statesDropdown.find(state => state.value.StateIso2 === _state)
        || { label: _state, value: { StateIso2: _state, StateName: _state, CountryIso3: country, CountryIso2: country } };

      const shippingAddress = {
        address1: addressLine1,
        address2: addressLine2,
        city,
        zipCode: postCode,
        country: (state.countriesDropdown as Array<{ label: string, value: ICountry }>).find(el => el.value.iso2Code === country || el.value.iso3Code === country) || "",
        state: _state,
        statesDropdown,
        stateObject: selectedState
      }

      return { ...state, shippingAddress };
    }
    case UPDATE_SHIPPING_ADDRESS_SUCCESS:
      return { ...state, isUpdateShippingAddressSuccess: true, isUpdateShippingAddressFailure: false }
    case UPDATE_SHIPPING_ADDRESS_FAILURE:
      return { ...state, isUpdateShippingAddressSuccess: false, isUpdateShippingAddressFailure: true }

    case SET_TAX_REG_NUMBER:
      return { ...state, taxRegNumber: action.payload }

    case FETCH_TAX_VALIDATION_SUCCESS:
      const getTaxValidation = action.response as GetTaxValidationResponse
      return { ...state, taxRegNumberRequirementType: getTaxValidation.getTaxRegNumberRequirementType() || state.taxRegNumberRequirementType }

    case VALIDATE_TAX_REQUEST:
      return { ...state, taxRegError: null }

    case VALIDATE_TAX_FAILURE:
      return { ...state, taxRegError: TaxRegNumberError.UNABLE_TO_VALIDATE, taxRegNumber: "" }

    case VALIDATE_TAX_SUCCESS:
      const validateTax = action.response as ValidateTaxResponse
      return { ...state, taxRegError: validateTax.getIsSuccess() ? null : TaxRegNumberError.INVALID }

    case SET_IS_USER_DATA_SCREEN_PRELOADED:
      return { ...state, isUserDataScreenPreloaded: action.payload }
    case SET_IS_ADDRESS_SCREEN_PRELOADED:
      return { ...state, isAddressScreenPreloaded: action.payload }
    case SET_TAX_REG_ERROR:
      return { ...state, taxRegError: action.payload }

    case SET_PRODUCT: {
      const selectedProduct = action.payload as {
        label: string;
        value: IProduct;
      } | null;

      // when the customer select a product whilst subscribing we have to update the isAddressScreenPreloaded flag depending
      // if the shipping address is required or not
      let isAddressScreenPreloaded = state.isAddressScreenPreloaded;

      if (selectedProduct && selectedProduct.value.isShippingAddressRequired) {
        const customerData = store.globalReducer.preloadedCustomerData;
        isAddressScreenPreloaded = isAddressScreenPreloaded && !!customerData.shippingAddressLine1 &&
          !!customerData.shippingAddressZip && !!customerData.shippingAddressCity && !!customerData.shippingAddressState &&
          !!customerData.shippingAddressCountry;
      }

      return { ...state, isAddressScreenPreloaded }
    }
    case SET_IS_SHIPPING_ADDRESS_MANUAL:
      return { ...state, isShippingAddressManual: action.payload }
    case SET_IS_BILLING_ADDRESS_MANUAL:
      return { ...state, isBillingAddressManual: action.payload }

    default:
      return state;
  }
}