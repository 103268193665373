import { NavigationBarSteps } from "../models/NavigationBar";
import { SET_NAVIGATION_STEP, SET_COMPANY_DOMAIN, SET_IS_CLOSE_ICON_VISIBLE, FETCH_BRANDING_SUCCESS, DETECT_CHECKOUT_VIEW_MODE, SET_ACCOUNT_MANAGEMENT_PERMISSIONS, SET_BILLSBY_ACTION, FETCH_CHECKOUT_CONFIG_SUCCESS, FETCH_BRANDING_FAILURE, SET_PRELOADED_CUSTOMER_DATA, SET_CHECKOUT_VIEW_MODE, SET_EMBED_CODE_LANGUAGE, SET_BILLSBY_DATA_TYPE, GET_CUSTOMER_LANGUAGE_SUCCESS } from "../actions/globalActions";
import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { IBranding } from "../models/Branding";
import { changeFavIcon, getCheckoutConfig, loadScriptFromString } from "../utils/commonUtils";
import billsbyLogo from "../images/billsby-logo.svg";
import { CheckoutViewMode, BillsbyDataAction, ICheckoutConfig, IBillsbyData, BillsbyDataType } from "../models/BillsbyData";
import { AccountManagementPermissions } from "../models/AccountManagement";
import counterpart from "counterpart";
import { GetCustomerLanguageResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/customer_pb";

export interface IGlobalReducer {
  companyDomain: string,
  navigationStep: NavigationBarSteps,
  isCloseIconVisible: boolean,
  branding: IBranding,
  preloadedCustomerData: Partial<IBillsbyData>,
  isFetchingBrandingFailure: boolean,
  checkoutViewMode: CheckoutViewMode,
  accountManagementPermissions: AccountManagementPermissions,
  checkoutConfig?: ICheckoutConfig,
  embedCodeLanguage?: string,
  customerLanguage?: string,
  billsbyDataType: BillsbyDataType,
  billsbyAction?: BillsbyDataAction
}

export const initialState: IGlobalReducer = {
  companyDomain: "",
  navigationStep: NavigationBarSteps.SELECT_PLAN,
  isCloseIconVisible: true,
  branding: {
    logoUrl: billsbyLogo,
    logoBackgroundColor: "#243F5C",
    highlightColor: "#555555",
    faviconUrl: "/static/icons/favicon-32x32.png",
    backgroundColor: "#F5F5F5",
    bodyTextColor: "#555555",
    confirmationColor: "#259A70",
    errorColor: "#D22630",
    faviconFileName: "",
    headerAndButtonColor: "#555555",
    logoFileName: "",
    rounding: 0,
    typographyBody: "Open sans",
    typographyHeader: "Open sans",
    typographyHeaderIsAllCaps: false
  },
  preloadedCustomerData: {},
  isFetchingBrandingFailure: false,
  checkoutViewMode: CheckoutViewMode.STANDARD,
  accountManagementPermissions: AccountManagementPermissions.ACTION,
  billsbyDataType: BillsbyDataType.CHECKOUT
}

export default function globalReducer(state = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case SET_COMPANY_DOMAIN:
      return { ...state, companyDomain: action.payload }
    case SET_NAVIGATION_STEP:
      return { ...state, navigationStep: action.payload }
    case DETECT_CHECKOUT_VIEW_MODE: {
      if (window.location.pathname.includes("/standalone")) {
        return { ...state, checkoutViewMode: CheckoutViewMode.STANDALONE }
      }
      return state;
    }
    case SET_CHECKOUT_VIEW_MODE:
      return { ...state, checkoutViewMode: action.payload }
    case SET_IS_CLOSE_ICON_VISIBLE:
      return { ...state, isCloseIconVisible: action.payload }
    case FETCH_BRANDING_SUCCESS: {
      let branding: IBranding = action.response;
      // remove empty branding fields
      Object.keys(branding).forEach((k) => !branding[k as keyof IBranding] ? delete branding[k as keyof IBranding] : "");
      branding = { ...state.branding, ...branding };
      changeFavIcon(branding.faviconUrl);
      return { ...state, branding }
    }
    case FETCH_BRANDING_FAILURE:
      return { ...state, isFetchingBrandingFailure: true }
    case SET_ACCOUNT_MANAGEMENT_PERMISSIONS:
      return { ...state, accountManagementPermissions: action.payload }
    case SET_BILLSBY_ACTION:
      return { ...state, billsbyAction: action.payload }
    case FETCH_CHECKOUT_CONFIG_SUCCESS: {
      const checkoutConfig = getCheckoutConfig(action.response as ICheckoutConfig);
      loadScriptFromString(checkoutConfig.trackingCodeStart);
      const { customerLanguage, embedCodeLanguage, billsbyDataType } = state;
      // embed code language has priority over checkout/self service default language
      if(customerLanguage) {
        counterpart.setLocale(customerLanguage.toLocaleLowerCase());
      }
      else if(embedCodeLanguage) {
        counterpart.setLocale(embedCodeLanguage.toLocaleLowerCase());
      }
      else if(billsbyDataType === BillsbyDataType.CHECKOUT && checkoutConfig.checkoutDefaultLanguage) {
        counterpart.setLocale(checkoutConfig.checkoutDefaultLanguage);
      }
      else if(billsbyDataType === BillsbyDataType.ACCOUNT && checkoutConfig.selfServiceAccountManagementDefaultLanguage) {
        counterpart.setLocale(checkoutConfig.selfServiceAccountManagementDefaultLanguage);
      }
      return { ...state, checkoutConfig }
    }
    case GET_CUSTOMER_LANGUAGE_SUCCESS: {
      const customerPreferredLanguage = action.response as GetCustomerLanguageResponse;
      const customerLanguage = customerPreferredLanguage?.getIsoCode();
      return { ...state, customerLanguage }
    }
    case SET_PRELOADED_CUSTOMER_DATA:
      return { ...state, preloadedCustomerData: action.payload }
    case SET_EMBED_CODE_LANGUAGE:
      return { ...state, embedCodeLanguage: action.payload }
    case SET_BILLSBY_DATA_TYPE:
      return { ...state, billsbyDataType: action.payload }
    default:
      return state;
  }
}