/* eslint-disable */
// source: coupons.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.billing.public.coupons.Coupon', null, global);
goog.exportSymbol('proto.billing.public.coupons.Coupon.DiscountLengthCase', null, global);
goog.exportSymbol('proto.billing.public.coupons.Coupon.ExpirationCase', null, global);
goog.exportSymbol('proto.billing.public.coupons.Coupon.FixedTermDiscountLength', null, global);
goog.exportSymbol('proto.billing.public.coupons.CouponType', null, global);
goog.exportSymbol('proto.billing.public.coupons.DeductionType', null, global);
goog.exportSymbol('proto.billing.public.coupons.Discount', null, global);
goog.exportSymbol('proto.billing.public.coupons.Discount.DiscountModelCase', null, global);
goog.exportSymbol('proto.billing.public.coupons.Discount.MonetaryModel', null, global);
goog.exportSymbol('proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices', null, global);
goog.exportSymbol('proto.billing.public.coupons.Discount.PercentageModel', null, global);
goog.exportSymbol('proto.billing.public.coupons.DiscountType', null, global);
goog.exportSymbol('proto.billing.public.coupons.EligibleCustomers', null, global);
goog.exportSymbol('proto.billing.public.coupons.FrequencyType', null, global);
goog.exportSymbol('proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest', null, global);
goog.exportSymbol('proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.displayName = 'proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.displayName = 'proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.coupons.Coupon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.billing.public.coupons.Coupon.oneofGroups_);
};
goog.inherits(proto.billing.public.coupons.Coupon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.coupons.Coupon.displayName = 'proto.billing.public.coupons.Coupon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.coupons.Coupon.FixedTermDiscountLength = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.coupons.Coupon.FixedTermDiscountLength, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.coupons.Coupon.FixedTermDiscountLength.displayName = 'proto.billing.public.coupons.Coupon.FixedTermDiscountLength';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.coupons.Discount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.coupons.Discount.repeatedFields_, proto.billing.public.coupons.Discount.oneofGroups_);
};
goog.inherits(proto.billing.public.coupons.Discount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.coupons.Discount.displayName = 'proto.billing.public.coupons.Discount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.coupons.Discount.PercentageModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.coupons.Discount.PercentageModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.coupons.Discount.PercentageModel.displayName = 'proto.billing.public.coupons.Discount.PercentageModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.coupons.Discount.MonetaryModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing.public.coupons.Discount.MonetaryModel.repeatedFields_, null);
};
goog.inherits(proto.billing.public.coupons.Discount.MonetaryModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.coupons.Discount.MonetaryModel.displayName = 'proto.billing.public.coupons.Discount.MonetaryModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.displayName = 'proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    couponCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest;
  return proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCouponCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest} returns this
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string coupon_code = 2;
 * @return {string}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.prototype.getCouponCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest} returns this
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.prototype.setCouponCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email_address = 3;
 * @return {string}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest} returns this
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    coupon: (f = msg.getCoupon()) && proto.billing.public.coupons.Coupon.toObject(includeInstance, f),
    discount: (f = msg.getDiscount()) && proto.billing.public.coupons.Discount.toObject(includeInstance, f),
    isExistingCustomer: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse;
  return proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.public.coupons.Coupon;
      reader.readMessage(value,proto.billing.public.coupons.Coupon.deserializeBinaryFromReader);
      msg.setCoupon(value);
      break;
    case 2:
      var value = new proto.billing.public.coupons.Discount;
      reader.readMessage(value,proto.billing.public.coupons.Discount.deserializeBinaryFromReader);
      msg.setDiscount(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExistingCustomer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoupon();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.billing.public.coupons.Coupon.serializeBinaryToWriter
    );
  }
  f = message.getDiscount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.billing.public.coupons.Discount.serializeBinaryToWriter
    );
  }
  f = message.getIsExistingCustomer();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional Coupon coupon = 1;
 * @return {?proto.billing.public.coupons.Coupon}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.prototype.getCoupon = function() {
  return /** @type{?proto.billing.public.coupons.Coupon} */ (
    jspb.Message.getWrapperField(this, proto.billing.public.coupons.Coupon, 1));
};


/**
 * @param {?proto.billing.public.coupons.Coupon|undefined} value
 * @return {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse} returns this
*/
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.prototype.setCoupon = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse} returns this
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.prototype.clearCoupon = function() {
  return this.setCoupon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.prototype.hasCoupon = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Discount discount = 2;
 * @return {?proto.billing.public.coupons.Discount}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.prototype.getDiscount = function() {
  return /** @type{?proto.billing.public.coupons.Discount} */ (
    jspb.Message.getWrapperField(this, proto.billing.public.coupons.Discount, 2));
};


/**
 * @param {?proto.billing.public.coupons.Discount|undefined} value
 * @return {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse} returns this
*/
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.prototype.setDiscount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse} returns this
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.prototype.clearDiscount = function() {
  return this.setDiscount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.prototype.hasDiscount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_existing_customer = 3;
 * @return {boolean}
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.prototype.getIsExistingCustomer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse} returns this
 */
proto.billing.public.coupons.GetCouponDetailsFromCouponCodeResponse.prototype.setIsExistingCustomer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.billing.public.coupons.Coupon.oneofGroups_ = [[5,6],[7,8]];

/**
 * @enum {number}
 */
proto.billing.public.coupons.Coupon.ExpirationCase = {
  EXPIRATION_NOT_SET: 0,
  IS_INFINITE: 5,
  EXPIRES_ON: 6
};

/**
 * @return {proto.billing.public.coupons.Coupon.ExpirationCase}
 */
proto.billing.public.coupons.Coupon.prototype.getExpirationCase = function() {
  return /** @type {proto.billing.public.coupons.Coupon.ExpirationCase} */(jspb.Message.computeOneofCase(this, proto.billing.public.coupons.Coupon.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.billing.public.coupons.Coupon.DiscountLengthCase = {
  DISCOUNT_LENGTH_NOT_SET: 0,
  IS_LIFE_TIME: 7,
  FIXED_TERM: 8
};

/**
 * @return {proto.billing.public.coupons.Coupon.DiscountLengthCase}
 */
proto.billing.public.coupons.Coupon.prototype.getDiscountLengthCase = function() {
  return /** @type {proto.billing.public.coupons.Coupon.DiscountLengthCase} */(jspb.Message.computeOneofCase(this, proto.billing.public.coupons.Coupon.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.coupons.Coupon.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.coupons.Coupon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.coupons.Coupon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.Coupon.toObject = function(includeInstance, msg) {
  var f, obj = {
    couponId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    couponType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eligibleCustomers: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isInfinite: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    expiresOn: (f = msg.getExpiresOn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isLifeTime: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    fixedTerm: (f = msg.getFixedTerm()) && proto.billing.public.coupons.Coupon.FixedTermDiscountLength.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.coupons.Coupon}
 */
proto.billing.public.coupons.Coupon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.coupons.Coupon;
  return proto.billing.public.coupons.Coupon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.coupons.Coupon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.coupons.Coupon}
 */
proto.billing.public.coupons.Coupon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.billing.public.coupons.CouponType} */ (reader.readEnum());
      msg.setCouponType(value);
      break;
    case 4:
      var value = /** @type {!proto.billing.public.coupons.EligibleCustomers} */ (reader.readEnum());
      msg.setEligibleCustomers(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInfinite(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiresOn(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLifeTime(value);
      break;
    case 8:
      var value = new proto.billing.public.coupons.Coupon.FixedTermDiscountLength;
      reader.readMessage(value,proto.billing.public.coupons.Coupon.FixedTermDiscountLength.deserializeBinaryFromReader);
      msg.setFixedTerm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.coupons.Coupon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.coupons.Coupon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.coupons.Coupon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.Coupon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCouponId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCouponType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEligibleCustomers();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getExpiresOn();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getFixedTerm();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.billing.public.coupons.Coupon.FixedTermDiscountLength.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.coupons.Coupon.FixedTermDiscountLength.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.coupons.Coupon.FixedTermDiscountLength.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.coupons.Coupon.FixedTermDiscountLength} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.Coupon.FixedTermDiscountLength.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequencyType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.coupons.Coupon.FixedTermDiscountLength}
 */
proto.billing.public.coupons.Coupon.FixedTermDiscountLength.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.coupons.Coupon.FixedTermDiscountLength;
  return proto.billing.public.coupons.Coupon.FixedTermDiscountLength.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.coupons.Coupon.FixedTermDiscountLength} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.coupons.Coupon.FixedTermDiscountLength}
 */
proto.billing.public.coupons.Coupon.FixedTermDiscountLength.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {!proto.billing.public.coupons.FrequencyType} */ (reader.readEnum());
      msg.setFrequencyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.coupons.Coupon.FixedTermDiscountLength.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.coupons.Coupon.FixedTermDiscountLength.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.coupons.Coupon.FixedTermDiscountLength} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.Coupon.FixedTermDiscountLength.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int32 frequency = 1;
 * @return {number}
 */
proto.billing.public.coupons.Coupon.FixedTermDiscountLength.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.coupons.Coupon.FixedTermDiscountLength} returns this
 */
proto.billing.public.coupons.Coupon.FixedTermDiscountLength.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional FrequencyType frequency_type = 2;
 * @return {!proto.billing.public.coupons.FrequencyType}
 */
proto.billing.public.coupons.Coupon.FixedTermDiscountLength.prototype.getFrequencyType = function() {
  return /** @type {!proto.billing.public.coupons.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.billing.public.coupons.FrequencyType} value
 * @return {!proto.billing.public.coupons.Coupon.FixedTermDiscountLength} returns this
 */
proto.billing.public.coupons.Coupon.FixedTermDiscountLength.prototype.setFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 coupon_id = 1;
 * @return {number}
 */
proto.billing.public.coupons.Coupon.prototype.getCouponId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.coupons.Coupon} returns this
 */
proto.billing.public.coupons.Coupon.prototype.setCouponId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.billing.public.coupons.Coupon.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.coupons.Coupon} returns this
 */
proto.billing.public.coupons.Coupon.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CouponType coupon_type = 3;
 * @return {!proto.billing.public.coupons.CouponType}
 */
proto.billing.public.coupons.Coupon.prototype.getCouponType = function() {
  return /** @type {!proto.billing.public.coupons.CouponType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.billing.public.coupons.CouponType} value
 * @return {!proto.billing.public.coupons.Coupon} returns this
 */
proto.billing.public.coupons.Coupon.prototype.setCouponType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional EligibleCustomers eligible_customers = 4;
 * @return {!proto.billing.public.coupons.EligibleCustomers}
 */
proto.billing.public.coupons.Coupon.prototype.getEligibleCustomers = function() {
  return /** @type {!proto.billing.public.coupons.EligibleCustomers} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.billing.public.coupons.EligibleCustomers} value
 * @return {!proto.billing.public.coupons.Coupon} returns this
 */
proto.billing.public.coupons.Coupon.prototype.setEligibleCustomers = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool is_infinite = 5;
 * @return {boolean}
 */
proto.billing.public.coupons.Coupon.prototype.getIsInfinite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.coupons.Coupon} returns this
 */
proto.billing.public.coupons.Coupon.prototype.setIsInfinite = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.billing.public.coupons.Coupon.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.billing.public.coupons.Coupon} returns this
 */
proto.billing.public.coupons.Coupon.prototype.clearIsInfinite = function() {
  return jspb.Message.setOneofField(this, 5, proto.billing.public.coupons.Coupon.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.coupons.Coupon.prototype.hasIsInfinite = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp expires_on = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.billing.public.coupons.Coupon.prototype.getExpiresOn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.billing.public.coupons.Coupon} returns this
*/
proto.billing.public.coupons.Coupon.prototype.setExpiresOn = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.billing.public.coupons.Coupon.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.coupons.Coupon} returns this
 */
proto.billing.public.coupons.Coupon.prototype.clearExpiresOn = function() {
  return this.setExpiresOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.coupons.Coupon.prototype.hasExpiresOn = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool is_life_time = 7;
 * @return {boolean}
 */
proto.billing.public.coupons.Coupon.prototype.getIsLifeTime = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.coupons.Coupon} returns this
 */
proto.billing.public.coupons.Coupon.prototype.setIsLifeTime = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.billing.public.coupons.Coupon.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.billing.public.coupons.Coupon} returns this
 */
proto.billing.public.coupons.Coupon.prototype.clearIsLifeTime = function() {
  return jspb.Message.setOneofField(this, 7, proto.billing.public.coupons.Coupon.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.coupons.Coupon.prototype.hasIsLifeTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional FixedTermDiscountLength fixed_term = 8;
 * @return {?proto.billing.public.coupons.Coupon.FixedTermDiscountLength}
 */
proto.billing.public.coupons.Coupon.prototype.getFixedTerm = function() {
  return /** @type{?proto.billing.public.coupons.Coupon.FixedTermDiscountLength} */ (
    jspb.Message.getWrapperField(this, proto.billing.public.coupons.Coupon.FixedTermDiscountLength, 8));
};


/**
 * @param {?proto.billing.public.coupons.Coupon.FixedTermDiscountLength|undefined} value
 * @return {!proto.billing.public.coupons.Coupon} returns this
*/
proto.billing.public.coupons.Coupon.prototype.setFixedTerm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.billing.public.coupons.Coupon.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.coupons.Coupon} returns this
 */
proto.billing.public.coupons.Coupon.prototype.clearFixedTerm = function() {
  return this.setFixedTerm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.coupons.Coupon.prototype.hasFixedTerm = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.coupons.Discount.repeatedFields_ = [8];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.billing.public.coupons.Discount.oneofGroups_ = [[9,10]];

/**
 * @enum {number}
 */
proto.billing.public.coupons.Discount.DiscountModelCase = {
  DISCOUNT_MODEL_NOT_SET: 0,
  PERCENTAGE: 9,
  MONETARY: 10
};

/**
 * @return {proto.billing.public.coupons.Discount.DiscountModelCase}
 */
proto.billing.public.coupons.Discount.prototype.getDiscountModelCase = function() {
  return /** @type {proto.billing.public.coupons.Discount.DiscountModelCase} */(jspb.Message.computeOneofCase(this, proto.billing.public.coupons.Discount.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.coupons.Discount.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.coupons.Discount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.coupons.Discount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.Discount.toObject = function(includeInstance, msg) {
  var f, obj = {
    discountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    discountUniqueId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    couponCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    discountType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    deductionType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    allowAllPlans: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    planIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    percentage: (f = msg.getPercentage()) && proto.billing.public.coupons.Discount.PercentageModel.toObject(includeInstance, f),
    monetary: (f = msg.getMonetary()) && proto.billing.public.coupons.Discount.MonetaryModel.toObject(includeInstance, f),
    isAllowedWithOtherDiscounts: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.coupons.Discount}
 */
proto.billing.public.coupons.Discount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.coupons.Discount;
  return proto.billing.public.coupons.Discount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.coupons.Discount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.coupons.Discount}
 */
proto.billing.public.coupons.Discount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscountUniqueId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCouponCount(value);
      break;
    case 5:
      var value = /** @type {!proto.billing.public.coupons.DiscountType} */ (reader.readEnum());
      msg.setDiscountType(value);
      break;
    case 6:
      var value = /** @type {!proto.billing.public.coupons.DeductionType} */ (reader.readEnum());
      msg.setDeductionType(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowAllPlans(value);
      break;
    case 8:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPlanIdsList(value);
      break;
    case 9:
      var value = new proto.billing.public.coupons.Discount.PercentageModel;
      reader.readMessage(value,proto.billing.public.coupons.Discount.PercentageModel.deserializeBinaryFromReader);
      msg.setPercentage(value);
      break;
    case 10:
      var value = new proto.billing.public.coupons.Discount.MonetaryModel;
      reader.readMessage(value,proto.billing.public.coupons.Discount.MonetaryModel.deserializeBinaryFromReader);
      msg.setMonetary(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAllowedWithOtherDiscounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.coupons.Discount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.coupons.Discount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.coupons.Discount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.Discount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscountId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDiscountUniqueId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCouponCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDiscountType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDeductionType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAllowAllPlans();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPlanIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
  f = message.getPercentage();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.billing.public.coupons.Discount.PercentageModel.serializeBinaryToWriter
    );
  }
  f = message.getMonetary();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.billing.public.coupons.Discount.MonetaryModel.serializeBinaryToWriter
    );
  }
  f = message.getIsAllowedWithOtherDiscounts();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.coupons.Discount.PercentageModel.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.coupons.Discount.PercentageModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.coupons.Discount.PercentageModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.Discount.PercentageModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentage: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.coupons.Discount.PercentageModel}
 */
proto.billing.public.coupons.Discount.PercentageModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.coupons.Discount.PercentageModel;
  return proto.billing.public.coupons.Discount.PercentageModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.coupons.Discount.PercentageModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.coupons.Discount.PercentageModel}
 */
proto.billing.public.coupons.Discount.PercentageModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.coupons.Discount.PercentageModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.coupons.Discount.PercentageModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.coupons.Discount.PercentageModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.Discount.PercentageModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 percentage = 1;
 * @return {number}
 */
proto.billing.public.coupons.Discount.PercentageModel.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.coupons.Discount.PercentageModel} returns this
 */
proto.billing.public.coupons.Discount.PercentageModel.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing.public.coupons.Discount.MonetaryModel.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.coupons.Discount.MonetaryModel.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.coupons.Discount.MonetaryModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.coupons.Discount.MonetaryModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.Discount.MonetaryModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricesList: jspb.Message.toObjectList(msg.getPricesList(),
    proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.coupons.Discount.MonetaryModel}
 */
proto.billing.public.coupons.Discount.MonetaryModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.coupons.Discount.MonetaryModel;
  return proto.billing.public.coupons.Discount.MonetaryModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.coupons.Discount.MonetaryModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.coupons.Discount.MonetaryModel}
 */
proto.billing.public.coupons.Discount.MonetaryModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices;
      reader.readMessage(value,proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.deserializeBinaryFromReader);
      msg.addPrices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.coupons.Discount.MonetaryModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.coupons.Discount.MonetaryModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.coupons.Discount.MonetaryModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.Discount.MonetaryModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.prototype.toObject = function(opt_includeInstance) {
  return proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencyCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices}
 */
proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices;
  return proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices}
 */
proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string currency_code = 1;
 * @return {string}
 */
proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices} returns this
 */
proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices} returns this
 */
proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated MonetaryCurrencyPrices prices = 1;
 * @return {!Array<!proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices>}
 */
proto.billing.public.coupons.Discount.MonetaryModel.prototype.getPricesList = function() {
  return /** @type{!Array<!proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices, 1));
};


/**
 * @param {!Array<!proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices>} value
 * @return {!proto.billing.public.coupons.Discount.MonetaryModel} returns this
*/
proto.billing.public.coupons.Discount.MonetaryModel.prototype.setPricesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices}
 */
proto.billing.public.coupons.Discount.MonetaryModel.prototype.addPrices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing.public.coupons.Discount.MonetaryModel.MonetaryCurrencyPrices, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.coupons.Discount.MonetaryModel} returns this
 */
proto.billing.public.coupons.Discount.MonetaryModel.prototype.clearPricesList = function() {
  return this.setPricesList([]);
};


/**
 * optional int32 discount_id = 1;
 * @return {number}
 */
proto.billing.public.coupons.Discount.prototype.getDiscountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.coupons.Discount} returns this
 */
proto.billing.public.coupons.Discount.prototype.setDiscountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.billing.public.coupons.Discount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.coupons.Discount} returns this
 */
proto.billing.public.coupons.Discount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string discount_unique_id = 3;
 * @return {string}
 */
proto.billing.public.coupons.Discount.prototype.getDiscountUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing.public.coupons.Discount} returns this
 */
proto.billing.public.coupons.Discount.prototype.setDiscountUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 coupon_count = 4;
 * @return {number}
 */
proto.billing.public.coupons.Discount.prototype.getCouponCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing.public.coupons.Discount} returns this
 */
proto.billing.public.coupons.Discount.prototype.setCouponCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional DiscountType discount_type = 5;
 * @return {!proto.billing.public.coupons.DiscountType}
 */
proto.billing.public.coupons.Discount.prototype.getDiscountType = function() {
  return /** @type {!proto.billing.public.coupons.DiscountType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.billing.public.coupons.DiscountType} value
 * @return {!proto.billing.public.coupons.Discount} returns this
 */
proto.billing.public.coupons.Discount.prototype.setDiscountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional DeductionType deduction_type = 6;
 * @return {!proto.billing.public.coupons.DeductionType}
 */
proto.billing.public.coupons.Discount.prototype.getDeductionType = function() {
  return /** @type {!proto.billing.public.coupons.DeductionType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.billing.public.coupons.DeductionType} value
 * @return {!proto.billing.public.coupons.Discount} returns this
 */
proto.billing.public.coupons.Discount.prototype.setDeductionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool allow_all_plans = 7;
 * @return {boolean}
 */
proto.billing.public.coupons.Discount.prototype.getAllowAllPlans = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.coupons.Discount} returns this
 */
proto.billing.public.coupons.Discount.prototype.setAllowAllPlans = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated int32 plan_ids = 8;
 * @return {!Array<number>}
 */
proto.billing.public.coupons.Discount.prototype.getPlanIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.billing.public.coupons.Discount} returns this
 */
proto.billing.public.coupons.Discount.prototype.setPlanIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.billing.public.coupons.Discount} returns this
 */
proto.billing.public.coupons.Discount.prototype.addPlanIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing.public.coupons.Discount} returns this
 */
proto.billing.public.coupons.Discount.prototype.clearPlanIdsList = function() {
  return this.setPlanIdsList([]);
};


/**
 * optional PercentageModel percentage = 9;
 * @return {?proto.billing.public.coupons.Discount.PercentageModel}
 */
proto.billing.public.coupons.Discount.prototype.getPercentage = function() {
  return /** @type{?proto.billing.public.coupons.Discount.PercentageModel} */ (
    jspb.Message.getWrapperField(this, proto.billing.public.coupons.Discount.PercentageModel, 9));
};


/**
 * @param {?proto.billing.public.coupons.Discount.PercentageModel|undefined} value
 * @return {!proto.billing.public.coupons.Discount} returns this
*/
proto.billing.public.coupons.Discount.prototype.setPercentage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.billing.public.coupons.Discount.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.coupons.Discount} returns this
 */
proto.billing.public.coupons.Discount.prototype.clearPercentage = function() {
  return this.setPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.coupons.Discount.prototype.hasPercentage = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional MonetaryModel monetary = 10;
 * @return {?proto.billing.public.coupons.Discount.MonetaryModel}
 */
proto.billing.public.coupons.Discount.prototype.getMonetary = function() {
  return /** @type{?proto.billing.public.coupons.Discount.MonetaryModel} */ (
    jspb.Message.getWrapperField(this, proto.billing.public.coupons.Discount.MonetaryModel, 10));
};


/**
 * @param {?proto.billing.public.coupons.Discount.MonetaryModel|undefined} value
 * @return {!proto.billing.public.coupons.Discount} returns this
*/
proto.billing.public.coupons.Discount.prototype.setMonetary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.billing.public.coupons.Discount.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing.public.coupons.Discount} returns this
 */
proto.billing.public.coupons.Discount.prototype.clearMonetary = function() {
  return this.setMonetary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing.public.coupons.Discount.prototype.hasMonetary = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool is_allowed_with_other_discounts = 11;
 * @return {boolean}
 */
proto.billing.public.coupons.Discount.prototype.getIsAllowedWithOtherDiscounts = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing.public.coupons.Discount} returns this
 */
proto.billing.public.coupons.Discount.prototype.setIsAllowedWithOtherDiscounts = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * @enum {number}
 */
proto.billing.public.coupons.CouponType = {
  UNSPECIFIED_CT: 0,
  SINGLE_USE: 1,
  MULTI_USE: 2
};

/**
 * @enum {number}
 */
proto.billing.public.coupons.DiscountType = {
  UNSPECIFIED_DIT: 0,
  PERCENTAGE: 1,
  MONETARY: 2
};

/**
 * @enum {number}
 */
proto.billing.public.coupons.FrequencyType = {
  UNSPECIFIED_FT: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  YEARLY: 4
};

/**
 * @enum {number}
 */
proto.billing.public.coupons.DeductionType = {
  UNSPECIFIED_DET: 0,
  PLAN_ONLY: 1,
  ENTIRE_SUBSCRIPTION: 2
};

/**
 * @enum {number}
 */
proto.billing.public.coupons.EligibleCustomers = {
  UNSPECIFIED_EC: 0,
  NEW_CUSTOMERS: 1,
  EXISTING_CUSTOMERS: 2,
  ALL_CUSTOMERS: 3
};

goog.object.extend(exports, proto.billing.public.coupons);
