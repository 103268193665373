import React from "react";
import "./ButtonLoader.scss";

const ButtonLoader: React.FC = React.memo(() => {
  return (
    <div className="spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
})

export default ButtonLoader;
