
import * as grpcWeb from "grpc-web";
import * as jspb from "google-protobuf";



export async function grpcUnaryCall(request: any, client: Object, method: (request: any, metadata: grpcWeb.Metadata | null, callback: (err: grpcWeb.Error,
  response: jspb.Message) => void) => grpcWeb.ClientReadableStream<any>) {

  // enable grpc dev tools in network tab -> the chrome plugin is needed anyways
  const enableDevTools = (window as any).__GRPCWEB_DEVTOOLS__ || (() => { });
  enableDevTools([client]);


  return (function grpcInternal() {
    return new Promise<jspb.Message>((resolve, reject) => {
      try {
        method.bind(client)(request, {}, (err, response) => {
          if (err) {
            reject(err);
          } else {
            resolve(response);
          }
        });
      }
      catch (err) {
        console.log(err);
        return reject(err);
      }

    })
  })()

}