import React, { useEffect, useState } from "react";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import moment from "moment";
import counterpart from "counterpart";
import "./InvoiceSummary.scss";
import { Invoice, StatusType } from "../../../utils/grpc/generated/Billsby.Protos/billing/public/customer/invoice_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { IBranding } from "../../../models/Branding";
import { sendEvent, CROSS_DOMAIN_EVENT } from "../../../utils/crossDomainEvents";

interface IInvoiceSummary {
  invoice: Invoice;
  branding: IBranding;
  hasTax: boolean;
}

const InvoiceSummary: React.FC<IInvoiceSummary> = ({ invoice, branding, hasTax = false }) => {
  const [plansText, setPlansText] = useState("");
  const { highlightColor, logoBackgroundColor, headerAndButtonColor } = branding;
  const dateFormat = "DD MMM YYYY";

  const invoiceUnpaid = invoice.getStatus() === StatusType.UNPAID || invoice.getStatus() === StatusType.FAILED

  useEffect(() => {
    let plansText = "";

    invoice.getPlanNameList().forEach((plan, i) => {
      if (invoice.getPlanNameList().length === 1) {
        plansText = plan;
        return;
      }

      plansText += i !== invoice.getPlanNameList().length - 1 ? `${plan}${invoice.getPlanNameList().length - 2 !== i ? ", " : " "}` : `${counterpart("AND")} ${plan}`;
    });

    setPlansText(plansText);
  }, [invoice]);

  return (
    <div className="invoice-summary">
      <Panel id="invoice-summary" isClickable={true} onClick={() => sendEvent(CROSS_DOMAIN_EVENT.REDIRECT_NEW_TAB, invoice.getInvoiceUrl())}>
        <Text className="invoice-summary__number" shouldTranslate={false} content={invoice.getInvoiceNumber()} />
        <Text
          className={"invoice-summary__plans"}
          content={plansText}
          shouldTranslate={false}
          weight="bold"
          style={{ color: headerAndButtonColor }}
        />
        <div className="invoice-summary__bottom">
          <Text
            className={`invoice-summary__transaction-details ${invoiceUnpaid ? "invoice-summary__transaction-details--error price" : ""}`}
            content={invoice.getPriceFormatted()}
            shouldTranslate={false}
            weight="bold"
          />
          {
            hasTax && <Text
              className={`invoice-summary__transaction-details ${invoiceUnpaid ? "invoice-summary__transaction-details--error" : ""}`}
              content="PRICE_SUMMARY_PLUS_TAX"
              weight="bold"
            />
          }
          <Text
            className={`invoice-summary__transaction-details ${invoiceUnpaid ? "invoice-summary__transaction-details--error" : ""}`}
            content={"VIEW_BILLING_HISTORY_DATE"}
            translateWith={{ date: moment.unix((invoice.getInvoicedOn() as Timestamp).getSeconds()).format(dateFormat) }}
          />
          {invoiceUnpaid && <Text
            className={"invoice-summary__transaction-details invoice-summary__transaction-details--error"}
            content={"VIEW_BILLING_HISTORY_DATE_UNPAID"}
            weight="bold"
          />}
        </div>
      </Panel>
    </div>
  );
};

export default InvoiceSummary;
