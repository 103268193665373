import React, { useState, useMemo, useEffect } from "react";
import PageWrapper from "../../../containers/page-wrapper/PageWrapper";
import Panel from "../../ui/panel/Panel";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { ILoginReducer } from "../../../reducers/loginReducer";
import FormLabel from "../../ui/form-label/FormLabel";
import "./LoginPasscode.scss";
import PassCode, { IPassCodeField } from "../../ui/passcode/PassCode";
import { setLoginField, loginPassCode } from "../../../actions/loginActions";
import { LoginScreen } from "../../../models/Login";
import { IGlobalReducer } from "../../../reducers/globalReducer";
import SystemInfo from "../../ui/system-info/SystemInfo";
import { ValidatePasscodeResponse } from "../../../utils/grpc/generated/Billsby.Protos/billing/public/customer/customer_pb";


const LoginPasscode: React.FC = () => {
  const { email, isLoginPasscodeSuccess, isLoginPasscodeFailure, validatePasscodeResponse } = useSelector<AppState, ILoginReducer>(state => state.loginReducer);
  const { companyDomain } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const dispatch = useDispatch();
  const [passCode, setPassCode] = useState<IPassCodeField>({ "0": "", "1": "", "2": "", "3": "", "4": "", "5": "" });
  const [isLoading, setIsLoading] = useState(false);


  const isPassCodeEmpty = useMemo(() => {
    return Object.keys(passCode).some(key => !(passCode as any)[key])
  }, [passCode]);

  useEffect(() => {
    isLoginPasscodeSuccess && validatePasscodeResponse && validatePasscodeResponse.getCustomerUniqueId() && dispatch(setLoginField("currentScreen", LoginScreen.SELECT_SUBSCRIPTION));
  }, [isLoginPasscodeSuccess])

  const onSubmit = (passCode: IPassCodeField) => {
    setIsLoading(true);
    const _passCode = `${passCode[0]}${passCode[1]}${passCode[2]}${passCode[3]}${passCode[4]}${passCode[5]}`;
    (dispatch(loginPassCode(companyDomain, email, _passCode)) as any as Promise<ValidatePasscodeResponse>)
      .finally(() => setIsLoading(false));
  }

  return (
    <PageWrapper
      className="login-passcode"
      isLoading={isLoading}
      title="LOGIN_PASSCODE_TITLE"
      titlePosition="left-aligned"
      subTitle="LOGIN_PASSCODE_SUBTITLE"
      btnDisabled={isPassCodeEmpty}
      btnCallback={() => onSubmit(passCode)}
      btnPosition="bottom"
      btnBackHidden>
      <SystemInfo
        title="LOGIN_PASSCODE_ERROR_TITLE"
        text="LOGIN_PASSCODE_ERROR_SUBTITLE"
        isShowIcon={false}
        type="warning"
        isVisible={isLoginPasscodeFailure || (isLoginPasscodeSuccess && validatePasscodeResponse && !validatePasscodeResponse.getCustomerUniqueId())}
      />

      <Panel id="login-passcode">
        <FormLabel content="LOGIN_PASSCODE" />
        <PassCode
          value={passCode}
          onChange={(newPassCode: IPassCodeField) => setPassCode(newPassCode)}
          onSubmit={onSubmit}
        />
      </Panel>

    </PageWrapper>
  )
}

export default LoginPasscode