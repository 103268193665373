import React from "react";
import "./FormGroup.scss";

interface IFormGroup {
  /**
  * The form elements wrapped by this component
  */
  children?: any,
  /**
  * Add custom classes
  */
  className?: string,
  /**
   * add custom bottom margin
   */
  margin?: "tiny" | "default" | "none"
  /**
   * add custom inline style
   */
  style?: object
}

const FormGroup: React.FC<IFormGroup> = React.memo(({ children = null, style = {}, className = "", margin = "default" }) => {
  return (
    <div className={`form-group__${margin} ${className}`} style={style}>
      {children}
    </div>
  );
})

export default FormGroup;