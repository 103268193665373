import React, { useRef, useEffect } from "react";
import ReactModal from "react-modal";
import "./Modal.scss";
import { findDOMNode } from "react-dom";
import { setIsCloseIconVisible } from "../../../actions/globalActions";
import { useDispatch, useSelector } from "react-redux";
import { useCheckOverflow, usePrevious } from "../../../utils/custom-hooks";
import Text from "../text/Text";
import { MobileView, isBrowser, isTablet } from "react-device-detect";
import { AppState } from "../../..";
import { IGlobalReducer } from "../../../reducers/globalReducer";
import { CheckoutViewMode } from "../../../models/BillsbyData";

interface IModal {
  isOpen: boolean;
  title?: string;
  shouldTranslateTitle?: boolean;
  onAfterOpen?: () => any;
  onRequestClose?: () => void;
  [key: string]: any;
  className?: string;
  buttonColor?: string;
  showCloseButton?: boolean;
  width?: string,
  height?: string,
  hasOverlay?: boolean
}

const _onRequestClose = (modalRef: any, onRequestClose: Function) => {
  const modalBody = (findDOMNode(modalRef.current) as Element).querySelector(".modal__body") as Element || null;
  const modalDom = (findDOMNode(modalRef.current) as Element).querySelector(".ReactModal__Content--after-open") as Element || null;
  if (modalBody) {
    modalBody.className = modalBody.className.replace(/modal__body--visible/, "");
  }
  if (modalDom) {
    modalDom.className = modalDom.className.replace(/ReactModal__Content--after-open/, "");
  }
  onRequestClose();
};

const _onAfterOpen = (modalRef: any, onAfterOpen: Function) => {
  const modal = findDOMNode(modalRef.current) as Element;
  if (modal) {
    const modalBody = modal.querySelector(".modal__body") as HTMLElement;
    const container = document.querySelector(".container") as HTMLElement;
    modalBody.className += " modal__body--visible";
    modalBody.style.height = (container ? container.clientHeight : 500) - 20 - 30 + "px";
  }

  onAfterOpen();
};

const Modal: React.FC<IModal> = ({ isOpen, showCloseButton = true, hasOverlay = false, width, height, buttonColor = "", children, className = "", onRequestClose,
  title, shouldTranslateTitle = true, onAfterOpen = () => { },  ...rest }) => {
  const { checkoutViewMode, branding } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const dispatch = useDispatch<Function>()
  const modalRef: any = useRef(null);
  const contentRef: any = useRef(null);
  const previousIsOpen = !!usePrevious(isOpen);
  const isOverflown = useCheckOverflow(contentRef, [children, isOpen]);

  useEffect(() => {
    if (isOpen) {
      // hide the X icon to close the Checkout in the top right hand corner because it overlaps with the modal and it seems to close 
      // the modal while in reality it closes the app
      dispatch(setIsCloseIconVisible(false));
    }
    else if (!isOpen && (isOpen !== previousIsOpen)) {
      dispatch(setIsCloseIconVisible(true));
    }
  }, [isOpen]);


  //adjust modals size in standalone mode 
  //TODO this behavior needs to be improved
  height = height || (checkoutViewMode === CheckoutViewMode.STANDALONE ? "400px" : undefined)
  width = width || (checkoutViewMode === CheckoutViewMode.STANDALONE ? "660px" : undefined)
  hasOverlay = checkoutViewMode === CheckoutViewMode.STANDALONE ? true : hasOverlay

  const isCustomSizeModal = !!width || !!height;
  const fixedLayoutStyle: React.CSSProperties = { position: "fixed", top: 0, left: 0, right: 0, bottom: 0 };
  const customSizeModalStyle: React.CSSProperties = {
    margin: 0,
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    height: height,
    overflow: "hidden",
    boxShadow: "0px 0px 6px #00000029",
    borderRadius: `${branding.rounding}px`
  }

  if (isBrowser || isTablet) {
    return <ReactModal
      isOpen={isOpen}
      ref={modalRef}
      ariaHideApp={false}
      onRequestClose={() => onRequestClose && _onRequestClose(modalRef, onRequestClose)}
      onAfterOpen={() => _onAfterOpen(modalRef, onAfterOpen)}
      className={`modal ${hasOverlay ? "modal--with-overlay" : ""}`}
      style={{
        overlay: {
          ...fixedLayoutStyle,
          backgroundColor: !isCustomSizeModal ? "transparent" : "rgba(255, 255, 255, 0.75)"
        } as any
      }}
      {...rest}
    >
      <div className={`modal__wrapper ${isOverflown ? "modal__wrapper--overflown" : ""}`} style={!isCustomSizeModal ? {} : { ...fixedLayoutStyle }}>
        <div style={!isCustomSizeModal ? { position: "relative" } : { ...customSizeModalStyle }}>
          <div ref={contentRef} className={`modal__body ${className}`} style={{ height: `${500 - (isOverflown ? 50 : 20)}px` }}>
            {!!title && <Text content={title.capitalize()} className="modal__body__title" shouldTranslate={shouldTranslateTitle} component="div" />}
            {showCloseButton &&
              <div className="modal__body__close-btn">
                <i className="fal fa-times" onClick={() => onRequestClose && _onRequestClose(modalRef, onRequestClose)}></i>
              </div>}
            {children}
          </div>
        </div>
      </div>
    </ReactModal>
  }

  return (
    <>
      <MobileView>
        <ReactModal
          isOpen={isOpen}
          ref={modalRef}
          ariaHideApp={false}
          className="modal"
          onRequestClose={() => onRequestClose && _onRequestClose(modalRef, onRequestClose)}
          onAfterOpen={() => _onAfterOpen(modalRef, onAfterOpen)}
          {...rest}
        >
          <>
            {showCloseButton &&
              <div className="modal__body__close-btn">
                <i className="far fa-times" onClick={() => onRequestClose && _onRequestClose(modalRef, onRequestClose)}></i>
              </div>}
            <div className={`modal__body ${className}`}>
              {!!title && <Text content={title.capitalize()} className="modal__body__title" shouldTranslate={shouldTranslateTitle} component="div" />}
              {children}
            </div>
          </>
        </ReactModal>
      </MobileView >
    </>
  );
};

export default Modal