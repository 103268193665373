import React, { CSSProperties, ReactElement, useMemo } from "react";
import Footer from "../footer/Footer";
import NavigationBar from "../../components/ui/navigation-bar/NavigationBar";
import { AppState } from "../..";
import { useSelector } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import FloatingHeader from "../floating-header/FloatingHeader";
import "./Container.scss";
import ScaSetup from "../../components/sca-setup/ScaSetup";

interface IContainer {
  className?: string,
  footerText?: string,
  footerShouldTranslate?: boolean,
  children: Array<ReactElement> | ReactElement
  showProgress?: boolean
}

const Container: React.FC<IContainer> = ({ className = "", footerText, footerShouldTranslate = true, showProgress = false, children }) => {
  const branding = useSelector((state: AppState) => state.globalReducer.branding);

  const containerMobileStyle = useMemo(() => {
    // this has to be cached otherwise window.innerheight can be recalculated runtime when for example the keyboard appears in mobile 
    // destroying the layout
    const CONTAINER_BACKGROUND_PADDING_TOP = 44;
    const NAVIGATION_BAR_HEIGHT = 6
    return {
      height: window.innerHeight - CONTAINER_BACKGROUND_PADDING_TOP - NAVIGATION_BAR_HEIGHT,
      borderRadius: "0px"
    }
  }, []);

  let containerBaseStyle: CSSProperties = {
    borderRadius: `${branding.rounding}px`
  }

  if (isMobileOnly) {
    containerBaseStyle = { ...containerBaseStyle, ...containerMobileStyle }
  }

  return (
    <>
      <FloatingHeader
        className="container__placeholder-floating-bar"
        btnBackHidden
        isVisible={isMobileOnly}
      />
      <div className={`container__background ${className}`}>
        <div className="container" style={containerBaseStyle}>
          {children}
          {!isMobileOnly && <NavigationBar isVisible={showProgress} className="container__progress-bar" />}
        </div>
        {isMobileOnly && <NavigationBar isVisible={showProgress} className="container__progress-bar" />}
        <Footer isVisible={!isMobileOnly} text={footerText} shouldTranslate={footerShouldTranslate} />
      </div>

      <ScaSetup />
    </>
  )
}

export default Container;