import { makeGenericActionCreator } from "../utils/reduxUtils";
import { SendPasscodeEmailRequest, ValidatePasscodeRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/customer_pb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { CustomerServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/CustomerServiceClientPb";
import { ConfigConstants } from "../utils/config";
import { ILoginReducer } from "../reducers/loginReducer";

export const SET_LOGIN_FIELD = "SET_LOGIN_FIELD";
type AllowedLoginFields = keyof ILoginReducer;
export const setLoginField = makeGenericActionCreator<AllowedLoginFields>(SET_LOGIN_FIELD);

export const LOGIN_EMAIL_REQUEST = "LOGIN_EMAIL_REQUEST";
export const LOGIN_EMAIL_SUCCESS = "LOGIN_EMAIL_SUCCESS";
export const LOGIN_EMAIL_FAILURE = "LOGIN_EMAIL_FAILURE";

export const LOGIN_PASSCODE_REQUEST = "LOGIN_PASSCODE_REQUEST";
export const LOGIN_PASSCODE_SUCCESS = "LOGIN_PASSCODE_SUCCESS";
export const LOGIN_PASSCODE_FAILURE = "LOGIN_PASSCODE_FAILURE";


export const loginEmail = (email: string, companyDomain: string) => {
  const sendPasscodeEmailRequest = new SendPasscodeEmailRequest();
  sendPasscodeEmailRequest.setEmail(email);
  sendPasscodeEmailRequest.setCompanyDomain(companyDomain);
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [LOGIN_EMAIL_REQUEST, LOGIN_EMAIL_SUCCESS, LOGIN_EMAIL_FAILURE],
    callAPI: () => grpcUnaryCall(sendPasscodeEmailRequest, customerServiceClient, customerServiceClient.sendPasscodeEmail)
  }
}

export const loginPassCode = (companyDomain: string, email: string, passCode: string) => {
  const validatePasscodeRequest = new ValidatePasscodeRequest();
  validatePasscodeRequest.setCompanyDomain(companyDomain);
  validatePasscodeRequest.setEmailAddress(email);
  validatePasscodeRequest.setPasscode(Number(passCode));
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [LOGIN_PASSCODE_REQUEST, LOGIN_PASSCODE_SUCCESS, LOGIN_PASSCODE_FAILURE],
    callAPI: () => grpcUnaryCall(validatePasscodeRequest, customerServiceClient, customerServiceClient.validatePasscode)
  }
}