import React from "react";
import { ICustomerSubscription } from "../../models/Customer";
import "./CustomerSubscriptions.scss";
import { GetDiscountOfferResponse } from "../../utils/grpc/generated/Billsby.Protos/core/public/companyconfig/retentionstep/retention_step_pb";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import { getCycleFrequencyText } from "../../utils/planUtils";

interface ICustomerSubscriptions {
  subscriptions: Array<ICustomerSubscription>,
  selectedSubscriptionIdx: number,
  discountOffer?: GetDiscountOfferResponse
  onClick: (selectedSubscriptionIdx: number) => void
}

const CustomerSubscriptions: React.FC<ICustomerSubscriptions> = ({ subscriptions, selectedSubscriptionIdx, onClick }) => {

  return (
    <div className="customer-subscriptions">
      {
        subscriptions.map((subscription, idx) => {
          const { priceFormatted, frequency, frequencyType } = subscription.priceModel;
          return (
            <Panel id={`customer-subscriptions${idx}`} key={idx} isClickable isSelected={selectedSubscriptionIdx === idx} onClick={() => onClick(idx)}>
              <Text content={`${subscription.productName} - ${subscription.planName}`} shouldTranslate={false} />
              <Text
                content={`${priceFormatted} ${getCycleFrequencyText(frequency, frequencyType)}`}
                shouldTranslate={false}
                weight="bold"
                noMargin
              />
            </Panel>
          )
        })
      }
    </div>
  )
}

export default CustomerSubscriptions;