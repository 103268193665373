import React, { useState } from "react";
import { RouteProps } from "react-router";
import { Redirect, Route } from "react-router";
import queryString from "query-string";
import history from "../../utils/history";
import { BillsbyDataAction, BillsbyDataParams, BillsbyDataType } from "../../models/BillsbyData";

interface IBillsbyDataActionRoute extends RouteProps {

}

const BillsbyDataActionRoute: React.FC<IBillsbyDataActionRoute> = (props) => {
  const queryParams = { ...queryString.parse(window.location.search) };
  const [mapping] = useState({
    [BillsbyDataAction.CANCEL_PLAN]: `/management/${BillsbyDataAction.CANCEL_PLAN}`,
    [BillsbyDataAction.UPDATE_PAYMENT_DETAILS]: `/management/${BillsbyDataAction.UPDATE_PAYMENT_DETAILS}`,
    [BillsbyDataAction.CHANGE_PLAN]: `/management/${BillsbyDataAction.CHANGE_PLAN}`,
    [BillsbyDataAction.CHANGE_PERSONAL_DETAILS]: `/management/${BillsbyDataAction.CHANGE_PERSONAL_DETAILS}`,
    [BillsbyDataAction.CHANGE_ADDRESS]: `/management/${BillsbyDataAction.CHANGE_ADDRESS}`,
    [BillsbyDataAction.MANAGE_ADDONS]: `/management/${BillsbyDataAction.MANAGE_ADDONS}`,
    [BillsbyDataAction.VIEW_BILLING_HISTORY]: `/management/${BillsbyDataAction.VIEW_BILLING_HISTORY}`,
    [BillsbyDataAction.VIEW_BILLING_HISTORY_UNPAID]: `/management/${BillsbyDataAction.VIEW_BILLING_HISTORY}`,
    [BillsbyDataAction.MANAGE_COUPONS]: `/management/${BillsbyDataAction.MANAGE_ADDONS}`,
    [BillsbyDataAction.MANAGE_ALLOWANCES]: `/management/${BillsbyDataAction.MANAGE_ALLOWANCES}`
  })
  
  const getContent = () => {
    const requestedAction = queryParams[BillsbyDataParams.action] as BillsbyDataAction;
    const billsbyDataType = queryParams[BillsbyDataParams.type];
    const requestedActionUrl = mapping[requestedAction];

    if (requestedActionUrl) {
      return <Redirect to={{ ...history.location, search: queryString.stringify(queryParams), pathname: requestedActionUrl }} />
    }
    if (!requestedActionUrl && billsbyDataType !== BillsbyDataType.ACCOUNT) {
      return <Redirect exact from="/" to={{ ...history.location, pathname: "/subscription" }} />
    }
    if (!requestedActionUrl && billsbyDataType === BillsbyDataType.ACCOUNT) {
      return <Redirect exact from="/" to={{ ...history.location, pathname: "/management" }} />
    }
  }

  return (
    <Route {...props}>
      {getContent()}
    </Route>
  )
}

export default BillsbyDataActionRoute;

