import { ICountryRestricted } from "./Country";
import { ICountryState } from "./CountryState";

export interface IAddress {
  addressLine1: string,
  addressLine2: string,
  state: string,
  city: string,
  country: string,
  postCode: string
}

export interface IBillingAddress {
  address1: string,
  address2: string,
  city: string,
  zipCode: string,
  country: { label: string, value: ICountryRestricted } | null,
  state: string,
  statesDropdown: Array<{ label: string, value: ICountryRestricted }>,
  stateObject: { label: string, value: ICountryState } | null
}

export interface IShippingAddress extends IBillingAddress {
  isUpdateShippingAddressSuccess: boolean,
  isUpdateShippingAddressFailure: boolean
}

export enum AutocompleteFields {
  LOCALITY = "locality",
  SUBLOCALITY = "sublocality",
  SUBLOCALITY_LV1 = "sublocality_level_1",
  POSTAL_CODE = "postal_code",
  POSTAL_TOWN = "postal_town",
  COUNTRY = "country",
  ADMIN_AREA_LVL1 = "administrative_area_level_1",
  ADMIN_AREA_LVL2 = "administrative_area_level_2",
  ADMIN_AREA_LVL3 = "administrative_area_level_3"
}