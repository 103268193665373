import React, { useMemo } from "react"

interface IPricingInfoDisclosure {
  rawHTMLEncoded: string
}


const PricingInfoDisclosure: React.FC<IPricingInfoDisclosure> = ({ rawHTMLEncoded }) => {

  /* safe decoding from base64 to retain special characters
  * https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
  */
  const b64_to_utf8 = (str: string) => {
    str = str.replace(/\s/g, "");    
    return decodeURIComponent(escape(window.atob( str )));
  }

  const htmlToRender = useMemo(() => {
    return b64_to_utf8(rawHTMLEncoded)
  }, [rawHTMLEncoded])

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlToRender }} />
  )
}

export default PricingInfoDisclosure