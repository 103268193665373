/* eslint-disable */
// source: retention_step.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.FrequencyType', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.GetPlanChangeRequest', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.GetPlanChangeResponse', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse', null, global);
goog.exportSymbol('proto.core.public.companyretentionstepconfig.RetentionStepType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.displayName = 'proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.displayName = 'proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest.displayName = 'proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.displayName = 'proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.public.companyretentionstepconfig.GetPlanChangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.public.companyretentionstepconfig.GetPlanChangeRequest.displayName = 'proto.core.public.companyretentionstepconfig.GetPlanChangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.public.companyretentionstepconfig.GetPlanChangeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.displayName = 'proto.core.public.companyretentionstepconfig.GetPlanChangeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest.displayName = 'proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.displayName = 'proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.displayName = 'proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.displayName = 'proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest.displayName = 'proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.repeatedFields_, null);
};
goog.inherits(proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.displayName = 'proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.displayName = 'proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest;
  return proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest} returns this
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest} returns this
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    available: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    percentage: (f = msg.getPercentage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    expiryDate: (f = msg.getExpiryDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    exitIntentImageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    expiryFrequency: jspb.Message.getFieldWithDefault(msg, 5, 0),
    expiryFrequencyType: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse;
  return proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAvailable(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPercentage(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiryDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExitIntentImageUrl(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiryFrequency(value);
      break;
    case 6:
      var value = /** @type {!proto.core.public.companyretentionstepconfig.FrequencyType} */ (reader.readEnum());
      msg.setExpiryFrequencyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPercentage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getExpiryDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExitIntentImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExpiryFrequency();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getExpiryFrequencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional bool available = 1;
 * @return {boolean}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.getAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.setAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value percentage = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.getPercentage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse} returns this
*/
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.setPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.clearPercentage = function() {
  return this.setPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.hasPercentage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp expiry_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.getExpiryDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse} returns this
*/
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.setExpiryDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.clearExpiryDate = function() {
  return this.setExpiryDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.hasExpiryDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string exit_intent_image_url = 4;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.getExitIntentImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.setExitIntentImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 expiry_frequency = 5;
 * @return {number}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.getExpiryFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.setExpiryFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional FrequencyType expiry_frequency_type = 6;
 * @return {!proto.core.public.companyretentionstepconfig.FrequencyType}
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.getExpiryFrequencyType = function() {
  return /** @type {!proto.core.public.companyretentionstepconfig.FrequencyType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.core.public.companyretentionstepconfig.FrequencyType} value
 * @return {!proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetDiscountOfferResponse.prototype.setExpiryFrequencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest;
  return proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest} returns this
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneNumberDialCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumberDialCountry: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    supportPageUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse;
  return proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberDialCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberDialCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupportPageUrl(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneNumberDialCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumberDialCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSupportPageUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subtitle = 2;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_number_dial_code = 4;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.getPhoneNumberDialCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.setPhoneNumberDialCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number_dial_country = 5;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.getPhoneNumberDialCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.setPhoneNumberDialCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone_number = 6;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string support_page_url = 7;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.getSupportPageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.setSupportPageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_enabled = 8;
 * @return {boolean}
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetHelpInterruptResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.public.companyretentionstepconfig.GetPlanChangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.public.companyretentionstepconfig.GetPlanChangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.public.companyretentionstepconfig.GetPlanChangeRequest}
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.public.companyretentionstepconfig.GetPlanChangeRequest;
  return proto.core.public.companyretentionstepconfig.GetPlanChangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.public.companyretentionstepconfig.GetPlanChangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.public.companyretentionstepconfig.GetPlanChangeRequest}
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.public.companyretentionstepconfig.GetPlanChangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.public.companyretentionstepconfig.GetPlanChangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetPlanChangeRequest} returns this
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.public.companyretentionstepconfig.GetPlanChangeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    subText: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.public.companyretentionstepconfig.GetPlanChangeResponse}
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.public.companyretentionstepconfig.GetPlanChangeResponse;
  return proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.public.companyretentionstepconfig.GetPlanChangeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.public.companyretentionstepconfig.GetPlanChangeResponse}
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.public.companyretentionstepconfig.GetPlanChangeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSubText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetPlanChangeResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.public.companyretentionstepconfig.GetPlanChangeResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string sub_text = 3;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.prototype.getSubText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetPlanChangeResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetPlanChangeResponse.prototype.setSubText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest;
  return proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest} returns this
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    text1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text3: jspb.Message.getFieldWithDefault(msg, 3, ""),
    text4: jspb.Message.getFieldWithDefault(msg, 4, ""),
    text5: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse;
  return proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText3(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText4(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setText5(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText3();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getText4();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getText5();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string text1 = 1;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.getText1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.setText1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text2 = 2;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.getText2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.setText2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text3 = 3;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.getText3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.setText3 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string text4 = 4;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.getText4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.setText4 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string text5 = 5;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.getText5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.setText5 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool is_enabled = 6;
 * @return {boolean}
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetReasonsToStayResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionUniqueId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest}
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest;
  return proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest}
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionUniqueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionUniqueId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest} returns this
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_unique_id = 2;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.prototype.getSubscriptionUniqueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest} returns this
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingRequest.prototype.setSubscriptionUniqueId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    remainingDays: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse}
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse;
  return proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse}
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRemainingDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRemainingDays();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 remaining_days = 3;
 * @return {number}
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.prototype.getRemainingDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetTimeRemainingResponse.prototype.setRemainingDays = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyDomain: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest;
  return proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string company_domain = 1;
 * @return {string}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest.prototype.getCompanyDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest} returns this
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsRequest.prototype.setCompanyDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    retentionStepsList: jspb.Message.toObjectList(msg.getRetentionStepsList(),
    proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse;
  return proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep;
      reader.readMessage(value,proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.deserializeBinaryFromReader);
      msg.addRetentionSteps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRetentionStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.prototype.toObject = function(opt_includeInstance) {
  return proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.toObject = function(includeInstance, msg) {
  var f, obj = {
    retentionStepType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    orderSequenceNumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep;
  return proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.core.public.companyretentionstepconfig.RetentionStepType} */ (reader.readEnum());
      msg.setRetentionStepType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderSequenceNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRetentionStepType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOrderSequenceNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional RetentionStepType retention_step_type = 1;
 * @return {!proto.core.public.companyretentionstepconfig.RetentionStepType}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.prototype.getRetentionStepType = function() {
  return /** @type {!proto.core.public.companyretentionstepconfig.RetentionStepType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.core.public.companyretentionstepconfig.RetentionStepType} value
 * @return {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep} returns this
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.prototype.setRetentionStepType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep} returns this
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 order_sequence_number = 3;
 * @return {number}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.prototype.getOrderSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep} returns this
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep.prototype.setOrderSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated RetentionStep retention_steps = 1;
 * @return {!Array<!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep>}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.prototype.getRetentionStepsList = function() {
  return /** @type{!Array<!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep, 1));
};


/**
 * @param {!Array<!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep>} value
 * @return {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse} returns this
*/
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.prototype.setRetentionStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep}
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.prototype.addRetentionSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.RetentionStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse} returns this
 */
proto.core.public.companyretentionstepconfig.GetRetentionStepsResponse.prototype.clearRetentionStepsList = function() {
  return this.setRetentionStepsList([]);
};


/**
 * @enum {number}
 */
proto.core.public.companyretentionstepconfig.RetentionStepType = {
  UNSPECIFIED: 0,
  HELP_INTERRUPT: 1,
  PLAN_CHANGE: 2,
  TIME_REMAINING: 3,
  REASONS_TO_STAY: 4,
  DISCOUNT_OFFER: 5
};

/**
 * @enum {number}
 */
proto.core.public.companyretentionstepconfig.FrequencyType = {
  UNSPECIFIED_FT: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  YEARLY: 4
};

goog.object.extend(exports, proto.core.public.companyretentionstepconfig);
