import React, { useEffect, useState } from "react";
import PageWrapper from "../page-wrapper/PageWrapper";
import { AppState } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { IUpdatePaymentReducer } from "../../reducers/updatePaymentReducer";
import { setUpdatePaymentState, UPDATE_PAYMENT_DETAILS_FAILURE, UPDATE_PAYMENT_DETAILS_REQUEST, UPDATE_PAYMENT_DETAILS_SUCCESS } from "../../actions/updatePaymentActions";
import SystemInfo from "../../components/ui/system-info/SystemInfo";
import counterpart from "counterpart";
import { IGlobalReducer } from "../../reducers/globalReducer";
import history from "../../utils/history";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { ICardDetailInputDetail, ICardDetailInputDetailSCA, IUpdatePaymentResponseSCA, UpdatePaymentStatusSCA } from "../../models/PaymentDetail";
import PaymentDetailsForm, { validatePaymentDetailsForm } from "../../components/payment-details-form/PaymentDetailsForm";
import "./UpdateCardDetails.scss";
import { gaEventTracker, getPublicIpV4 } from "../../utils/commonUtils";
import { AccountManagementPermissions } from "../../models/AccountManagement";
import { IChangePersonalDetailsReducer } from "../../reducers/changePersonalDetailsReducer";
import { fetchPersonalDetails } from "../../actions/changePersonalDetailsActions";
import { SPREEDLY_TEST_CARD_NAME, SPREEDLY_TEST_CREDIT_CARD, SPREEDLY_TEST_CVV, SPREEDLY_TEST_EXP_MONTH, SPREEDLY_TEST_EXP_YEAR } from "../../utils/constants";
import { DashboardSettingsType } from "../../models/BillsbyData";
import TestGatewayPlaceHolder from "../../components/payment-method/TestGatewayPlaceHolder";
import API from "../../utils/API";
import { getBrowserInfo, setup3dSecure } from "../../components/sca-setup/ScaSetup";
import { ConfigConstants } from "../../utils/config";
import { CustomAnalyticsEvents } from "../../models/GoogleAnalytics";
import { IUpdatePaymentDetails } from "../update-payment-details/UpdatePaymentDetails";


const UpdateCardDetails: React.FC<IUpdatePaymentDetails> = ({
  title = "UPDATE_PAYMENT_DETAILS_TITLE",
  titleTranslateWith,
  titlePosition = "centered",
  subTitle = "UPDATE_PAYMENT_DETAILS_SUBTITLE",
  subTitleTranslateWith,
  btnText = "UPDATE_PAYMENT_DETAILS_BTN",
  btnTextTranslateWith = {},
  shouldTranslateText = true,
  btnPosition = "bottom",
  bottomContent = <div />,
  btnCallback,
  afterUpdateCallback,
  btnBackCallback,
  onSuccessRedirectToAccountManagement = true,
  redirect = true,
  customSuccessSubtitle = "",
  showCloseIcon = true,
  isLoading = false
}) => {

  const globalReducer = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const updatePaymentReducer = useSelector<AppState, IUpdatePaymentReducer>(state => state.updatePaymentReducer);
  const personalDetailsReducer = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { billingAddress } = useSelector<AppState, IChangePersonalDetailsReducer>(state => state.changePersonalDetailsReducer);
  const dispatch = useDispatch();

  const { cardHolderName, expDateMM, expDateYY, errors, isUpdatingPaymentDetailsRequest, isUpdatingPaymentDetailsSuccess,
    isUpdatingPaymentDetailsFailure } = updatePaymentReducer;
  const { companyDomain, checkoutConfig, accountManagementPermissions } = globalReducer;
  const customerUniqueId: string = personalDetailsReducer.mainProps.customerUniqueId as string;
  const isTestAccount = !!checkoutConfig?.companyStatus && checkoutConfig.companyStatus !== DashboardSettingsType.Live
    && checkoutConfig.companyStatus !== DashboardSettingsType.OnTrial;
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>("");

  const fetchIpAddress = async () => {
    setIsLoadingData(true);

    try {
      const ipv4 = await getPublicIpV4()
      setIpAddress(ipv4);
    }
    catch (err) { }
    finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    dispatch(fetchPersonalDetails(companyDomain, customerUniqueId as string));
    //useful for SCA, we saved it in the reducer because the reducer is saved in the session storage before the redirect
    dispatch(setUpdatePaymentState("customSuccessSubtitle", customSuccessSubtitle));
    fetchIpAddress();
  }, [])

  useEffect(() => {
    if ((isUpdatingPaymentDetailsSuccess || isUpdatingPaymentDetailsFailure) && onSuccessRedirectToAccountManagement && redirect) {
      // redirect + forward history query string
      history.push({ pathname: "/management", search: history.location.search });
    }

  }, [isUpdatingPaymentDetailsSuccess, isUpdatingPaymentDetailsFailure, redirect]);

  const callAPI = async (cardDetails: ICardDetailInputDetailSCA, transactionToken?: string): Promise<IUpdatePaymentResponseSCA | undefined> => {
    let response = {} as IUpdatePaymentResponseSCA;
    dispatch({ type: UPDATE_PAYMENT_DETAILS_REQUEST });

    try {
      response = await API.updatePaymentDetailsSCA(companyDomain, customerUniqueId, cardDetails) as IUpdatePaymentResponseSCA;
    }
    catch (err) {
      dispatch({ type: UPDATE_PAYMENT_DETAILS_FAILURE });
      return;
    }
    if (response.status === UpdatePaymentStatusSCA.Unassigned) {
      dispatch({ type: UPDATE_PAYMENT_DETAILS_FAILURE });
      return;
    }
    if (response.status === UpdatePaymentStatusSCA.UpdateCompleted) {
      dispatch({ type: UPDATE_PAYMENT_DETAILS_SUCCESS, response });
      gaEventTracker(CustomAnalyticsEvents.UPDATED_PAYMENT_DETAILS);
      return;
    }
    if (response.status === UpdatePaymentStatusSCA.Pending) {
      return response;
    }
  }

  const handleUpdatePaymentDetails = () => {
    validatePaymentDetailsForm({ cardholderName: cardHolderName, expiryMonth: expDateMM, expiryYear: expDateYY },
      {
        addressLine1: billingAddress.addressLine1,
        addressLine2: billingAddress.addressLine2,
        state: billingAddress.state,
        city: billingAddress.city,
        country: billingAddress.country,
        postCode: billingAddress.postCode
      },
      async (cardData, errors) => {
        if (errors) {
          return dispatch(setUpdatePaymentState("errors", errors));
        }

        if (cardData) {
          const cardDetails: ICardDetailInputDetail = {
            paymentCardToken: cardData.token,
            expiryMonth: cardData.month,
            expiryYear: cardData.year,
            cardType: cardData.card_type,
            last4Digits: cardData.last_four_digits,
            fullName: cardData.full_name,
            ipAddress
          };
          dispatch(setUpdatePaymentState("cardType", cardData.card_type));
          dispatch(setUpdatePaymentState("lastFourDigit", cardData.last_four_digits));
          dispatch(setUpdatePaymentState("token", cardData.token));
          /*if (!preloadedCustomerData.scaEnabled) {
            //old codepath without sca
            dispatch(updatePaymentDetails(companyDomain, customerUniqueId, cardDetails))
            return;
          }*/

          //new codepath
          const redirectUrl = `https://checkout${ConfigConstants.billsbyDomain}/updatepaymentdetails/3ds1`;
          const cardDetailsSCA = { ...cardDetails, browserInfo: getBrowserInfo(), redirectUrl, callbackUrl: redirectUrl } as ICardDetailInputDetailSCA;
          const response = await callAPI(cardDetailsSCA);

          if (!response) {
            // if undefined it means the call has already completed
            return;
          }

          //PENDING status -> required 3ds
          const statusUpdates = async (event: { action: string }) => {

            // save in session storage relevant data required in case 3ds1 is supported
            sessionStorage.setItem("billsbyUpdatePaymentSCA", JSON.stringify({
              cardDetailsSCA,
              transactionToken: response?.transactionToken,
              globalReducerState: globalReducer,
              personalDetailsState: personalDetailsReducer,
              updatePaymentState: updatePaymentReducer
            }));


            if (event.action === "succeeded") {
              callAPI(cardDetailsSCA, response?.transactionToken);
            }
            else if (event.action === "error") {
              return dispatch({ type: UPDATE_PAYMENT_DETAILS_FAILURE });
            }
            else if (event.action === "trigger-completion") {
              callAPI(cardDetailsSCA, response?.transactionToken);
            }
          }

          setup3dSecure(response?.transactionToken, statusUpdates);

        }
      });
  };

  if (isLoadingData || !checkoutConfig) {
    return null
  }

  const goBackCallback = () => {
    if (accountManagementPermissions === AccountManagementPermissions.FULL && history.location.pathname !== "/management") {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }

  return (
    <PageWrapper
      btnText={btnText}
      btnTextTranslateWith={btnTextTranslateWith}
      shouldTranslateText={shouldTranslateText}
      className="update-card-details"
      btnDisabled={!cardHolderName || !expDateMM || !expDateYY || isUpdatingPaymentDetailsRequest || isTestAccount}
      btnPosition={btnPosition}
      bottomStickyContent={bottomContent}
      isLoading={isLoading || isUpdatingPaymentDetailsRequest}
      btnCallback={btnCallback || handleUpdatePaymentDetails}
      btnBackCallback={btnBackCallback || goBackCallback}
      btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION}
      title={title}
      titleTranslateWith={titleTranslateWith}
      titlePosition={titlePosition}
      subTitle={subTitle}
      subTitleTranslateWith={{ ...subTitleTranslateWith, subscriptionLabel: checkoutConfig.terminologySubscriptionPlural }}
      showCloseIcon={showCloseIcon}
    >
      <>
        <SystemInfo
          title={counterpart("PAYMENT_COMPULSORY_FIELDS")}
          text={errors?.arr.join(", ")}
          isShowIcon={false}
          shouldTranslateTitle={false}
          shouldTranslateText={false}
          type="warning"
          isVisible={!!errors}
        />
        {
          isTestAccount
            ? <TestGatewayPlaceHolder content="BILLSBY_TEST_GATEWAY_UPDATE_TEXT" isSubscribing={isLoading || isUpdatingPaymentDetailsRequest} onContinue={btnCallback || handleUpdatePaymentDetails} />
            : <div />
        }
        <div className={isTestAccount ? "payment-method--opaque" : "payment-method"}>
          <PaymentDetailsForm
            className="update-card-details__form"
            cardNumberId="update-payment-card-number"
            cvvId="update-payment-card-cvv"
            onChangeCardholderName={(name) => dispatch(setUpdatePaymentState("cardHolderName", name))}
            onChangeExpiryMonth={(month) => dispatch(setUpdatePaymentState("expDateMM", month))}
            onChangeExpiryYear={(year) => dispatch(setUpdatePaymentState("expDateYY", year))}
            formData={{
              cardholderName: cardHolderName,
              expiryMonth: expDateMM,
              expiryYear: expDateYY,
            }}
            formErrors={{
              expiryMonth: errors?.obj.expiryMonth,
              expiryYear: errors?.obj.expiryYear,
              cardNumber: errors?.obj.cardNumber
            }}
            initialCardNumber={isTestAccount ? SPREEDLY_TEST_CREDIT_CARD : undefined}
            initialCvv={isTestAccount ? SPREEDLY_TEST_CVV : undefined}
            initialExpiryMonth={isTestAccount ? SPREEDLY_TEST_EXP_MONTH : undefined}
            initialExpiryYear={isTestAccount ? SPREEDLY_TEST_EXP_YEAR : undefined}
            initialCardholderName={isTestAccount ? SPREEDLY_TEST_CARD_NAME : undefined}
            disabled={isTestAccount}
          />
        </div>
      </>
    </PageWrapper>
  );
};

export default UpdateCardDetails
