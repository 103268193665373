import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { IAddOn, AddOnPricingModelType, IAllowance, AllowancePricingModelType } from "../models/AddOnAllowance";
import { ADD_OUT_OF_RANGE_ADDON, FETCH_PLANS_SUCCESS_FOR_ADDONS, REMOVE_OUT_OF_RANGE_ADDON, SET_SELECTED_ADD_ON, SET_SELECTED_ADD_ONS_UNITS, SET_ADDONS, SET_ADDONS_UNITS, FETCH_SUBSCRIPTION_ADDONS_SUCCESS, RESET_ADDON_STATE, ADD_NEW_ADDONS_SUCCESS, SET_SELECTED_ADD_ONS, EDIT_ADDONS_SUCCESS, GET_ADDON_PRICE_SIMULATION_SUCCESS, FETCH_SUBSCRIPTION_ALLOWANCES_SUCCESS, REMOVE_ALLOWANCE_SUCCESS, GET_ALLOWANCE_PRICE_SIMULATION_SUCCESS, ADD_NEW_ALLOWANCES_SUCCESS, SET_PRELOADED_ADDONS, SET_PRELOADED_ALLOWANCES, FETCH_SUBSCRIPTION_ADDONS_FAILURE, FETCH_SUBSCRIPTION_ALLOWANCES_FAILURE, ADD_NEW_ALLOWANCES_FAILURE, ADD_NEW_ADDONS_FAILURE, SET_ALLOWANCES, SET_ALLOWANCE_UNITS, EDIT_ADDONS_REQUEST, EDIT_ADDONS_FAILURE, ADD_NEW_ADDONS_REQUEST, REMOVE_ALLOWANCE_REQUEST, REMOVE_ALLOWANCE_FAILURE, ADD_NEW_ALLOWANCES_REQUEST, SET_ADDON_FIELD, GET_ADDON_PRICING_DISCLOSURE_SUCCESS, GET_ALLOWANCE_PRICING_DISCLOSURE_SUCCESS } from "../actions/addOnActions";
import { GetAddonsResponse, UpdateSubscriptionAddonsResponse, Currency, PricingModelType as PricingModelTypeGrpc, FrequencyType as FrequencyTypeGrpc, CheckoutAddonsSimulationResponse, GetAllowancesResponse, UpdateSubscriptionAllowanceResponse, CheckoutAllowancesSimulationResponse, GetCheckoutAddonsSimulationPricingDisclosureResponse, AllowancesPricingDisclosureResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/subscription_pb";
import { FrequencyType, IPlanWithCycles } from "../models/Product";
import { AddonsAllowances, AllowanceOverage } from "../utils/grpc/generated/Billsby.Protos/billing/public/invoice/invoice_pb";
import { isComplexPricingModel } from "../utils/planUtils";
import { CROSS_DOMAIN_EVENT, sendEvent } from "../utils/crossDomainEvents";


export interface IAddOnReducer {
  selectedAddOns: Array<IAddOn | IAllowance>,
  addOns: Array<IAddOn>,
  allowances: Array<IAllowance>,
  subscriptionAddons?: Array<IAddOn>,
  subscriptionAllowances?: Array<IAllowance>,
  allowancesOverage?: AllowanceOverage,
  isRequestingCurrentAllowances: boolean,
  isFetchSubscriptionAddOnsFailure: boolean,
  isFetchSubscriptionAllowancesFailure: boolean,
  isFetchSubscriptionAddOnsSuccess: boolean,
  isFetchSubscriptionAllowanceSuccess: boolean,
  addOnPricingDisclosure?: string,
  allowancePricingDisclosure?: string,
  addOnPriceSimulation?: CheckoutAddonsSimulationResponse,
  allowancePriceSimulation?: CheckoutAllowancesSimulationResponse,
  //updateSubscriptionAllowanceSuccess?: boolean,
  //removedAllowance?: IAllowance,
  outOfRangeAddOns: Array<string>,
  /*The type for a preloaded addon is a nested array
  for example the data can be pass through this way where the first value is the id the second is optional and represents the number of units, 
  data-billsby-addons="[[AddOn_ID1, AddOn_Units1]]"*/
  preloadedAddOns: Array<[number, number?]>,
  hasPreloadedAddOnsWithMissingUnits: boolean,
  preloadedAllowances: Array<number>,
  isEditingAddOnsRequest: boolean,
  isEditingAddOnsSuccess: boolean,
  isEditingAddOnsFailure: boolean,
  isAddingNewAddOnsRequest: boolean,
  isAddingNewAddOnsSuccess: boolean,
  isAddingNewAddOnsFailure: boolean,
  isRemovingAllowancesRequest: boolean,
  isRemovingAllowancesSuccess: boolean,
  isRemovingAllowancesFailure: boolean,
  isAddingNewAllowancesRequest: boolean,
  isAddingNewAllowancesSuccess: boolean,
  isAddingNewAllowancesFailure: boolean,
}

export const initialState = {
  selectedAddOns: [],
  addOns: [],
  allowances: [],
  outOfRangeAddOns: [],
  isRequestingCurrentAllowances: false,
  isFetchSubscriptionAddOnsFailure: false,
  isFetchSubscriptionAllowancesFailure: false,
  isFetchSubscriptionAddOnsSuccess: false,
  isFetchSubscriptionAllowanceSuccess: false,
  preloadedAddOns: [],
  hasPreloadedAddOnsWithMissingUnits: false,
  preloadedAllowances: [],
  isEditingAddOnsRequest: false,
  isEditingAddOnsSuccess: false,
  isEditingAddOnsFailure: false,
  isAddingNewAddOnsRequest: false,
  isAddingNewAddOnsSuccess: false,
  isAddingNewAddOnsFailure: false,
  isRemovingAllowancesRequest: false,
  isRemovingAllowancesSuccess: false,
  isRemovingAllowancesFailure: false,
  isAddingNewAllowancesRequest: false,
  isAddingNewAllowancesSuccess: false,
  isAddingNewAllowancesFailure: false,
}

const frequencyTypeMap = {
  [FrequencyTypeGrpc.UNSPECIFIED_FT]: FrequencyType.Daily,
  [FrequencyTypeGrpc.DAILY]: FrequencyType.Daily,
  [FrequencyTypeGrpc.WEEKLY]: FrequencyType.Weekly,
  [FrequencyTypeGrpc.MONTHLY]: FrequencyType.Monthly,
  [FrequencyTypeGrpc.YEARLY]: FrequencyType.Yearly,
}

export default function addOnReducer(state: IAddOnReducer = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case SET_ADDON_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }
    case SET_SELECTED_ADD_ON: {
      let selectedAddOns = [...state.selectedAddOns];
      const existingAddOn = selectedAddOns.filter(i => i.id === action.payload.id);
      if (existingAddOn.length > 0) {
        selectedAddOns = selectedAddOns.filter(i => i.id !== action.payload.id);
      } else {
        selectedAddOns.push(action.payload)
      }
      return { ...state, selectedAddOns: [...selectedAddOns] }
    }
    case SET_SELECTED_ADD_ONS_UNITS: {
      const selectedAddOnsToSet = [...state.selectedAddOns];
      const updatedSelectedAddOns = selectedAddOnsToSet.map(addOn => {
        if (addOn.id === action.payload.id) {
          addOn.units = action.payload.units;
          addOn.pricingModels = action.payload.pricingModels;
        }
        return addOn;
      });
      return { ...state, selectedAddOns: [...updatedSelectedAddOns] }
    }
    case SET_ADDONS: {
      const addOns = [...action.payload].sort((a, b) => (a.displayName || a.name) > (b.displayName || b.name) ? 1 : -1);
      return { ...state, addOns }
    }
    case SET_ALLOWANCES: {
      const allowances = [...action.payload].sort((a, b) => (a.displayName || a.name) > (b.displayName || b.name) ? 1 : -1);
      return { ...state, allowances }
    }
    case SET_SELECTED_ADD_ONS: {
      const selectedAddOns = [...action.payload]
      return { ...state, selectedAddOns }
    }
    case SET_ADDONS_UNITS: {
      const addOnsToSet = [...state.addOns];
      
      const updatedAddOns = addOnsToSet.map(addOn => {
        if (addOn.id === action.payload.id) {
          addOn.units = action.payload.units;
          addOn.pricingModels = action.payload.pricingModels;
        }
        return addOn;
      });
      return { ...state, addOns: [...updatedAddOns] }
    }
    case SET_ALLOWANCE_UNITS: {
      const allowancesToSet = [...state.allowances];
      const updatedAddOns = allowancesToSet.map(allowance => {
        if (allowance.id === action.payload.id) {
          allowance.units = action.payload.units;
          allowance.pricingModels = action.payload.pricingModels;
        }
        return allowance;
      });
      return { ...state, allowances: [...updatedAddOns] }
    }
    case SET_PRELOADED_ADDONS:
      return { ...state, preloadedAddOns: action.payload }
    case SET_PRELOADED_ALLOWANCES:
      return { ...state, preloadedAllowances: action.payload }

    case FETCH_PLANS_SUCCESS_FOR_ADDONS: {
      // intercept the FETCH_PLANS_SUCCESS action during the subscription workflow to check if we can preload addons and allowances
      // productId, planId and cycleId must be pass through the embed code as well
      const { productId, planId, cycleId } = store.selectPlanReducer;
      const { preloadedAddOns, preloadedAllowances } = state;
      const plans = action.response as Array<IPlanWithCycles>;
      const selectedPlan = plans.find(p => p.planId === planId);
      const selectedCycle = selectedPlan && selectedPlan.cycles.find(c => c.cycleId === cycleId);

      if ((!productId || !planId || !cycleId || !selectedPlan || !selectedCycle) || (!preloadedAddOns.length && !preloadedAllowances.length)) {
        return { ...state }
      }

      let forcedAddOns: Array<IAddOn> = [];
      let forcedAllowances: Array<IAllowance> = [];
      let hasPreloadedAddOnsWithMissingUnits = false;

      preloadedAddOns.forEach(preloadedAddOn => {
        const addOn = selectedPlan.addons.find(a => a.id === preloadedAddOn[0]);
        const preloadedNrUnits = preloadedAddOn[1];
        const addOnPricingModel = addOn && addOn.pricingModels
          .find(p => p.frequency === selectedCycle.pricingModel.frequency && p.frequencyType === selectedCycle.pricingModel.frequencyType);

        if (addOn
          && isComplexPricingModel(addOn.pricingModelType)
          //&& preloadedNrUnits
          && addOnPricingModel
          //&& (addOnPricingModel.pricingModelType === AddOnPricingModelType.AddonPerUnit
          //  || addOnPricingModel.tiers.some(t => preloadedNrUnits >= t.start && preloadedNrUnits <= t.finish))
        ) {
          // to preload the units it has to be a valid number and within the range of one tier
          if (preloadedNrUnits && (addOnPricingModel.pricingModelType === AddOnPricingModelType.AddonPerUnit
            || addOnPricingModel.tiers.some(t => preloadedNrUnits >= t.start && preloadedNrUnits <= t.finish))) {
            addOn.units = preloadedNrUnits;
            addOn.isForced = true;
            forcedAddOns.push(addOn);
          }
          else if (!preloadedNrUnits) {
            addOn.units = undefined;
            addOn.isForced = true;
            forcedAddOns.push(addOn);
            hasPreloadedAddOnsWithMissingUnits = true;
          }
        }
        else if (addOn && !isComplexPricingModel(addOn.pricingModelType) && addOnPricingModel) {
          addOn.units = 1;
          addOn.isForced = true;
          forcedAddOns.push(addOn);
        }

      })

      preloadedAllowances.forEach(preloadedAllowance => {
        const allowance = selectedPlan.allowances.find(a => a.id === preloadedAllowance);
        if (allowance) {
          allowance.units = 1;
          allowance.isForced = true;
          forcedAllowances.push(allowance);
        }
      })

      return {
        ...state,
        addOns: forcedAddOns.length ? forcedAddOns : state.addOns,
        allowances: forcedAllowances.length ? forcedAllowances : state.allowances,
        hasPreloadedAddOnsWithMissingUnits
      }
    }

    // manageaddon
    case FETCH_SUBSCRIPTION_ADDONS_SUCCESS: {
      const getAddonResponse = action.response as GetAddonsResponse;
      const subscriptionAddonsGrpc = getAddonResponse.getAddonsList();
      const selectedAddOns: Array<IAddOn> = [];
      const forcedAddOns: Array<IAddOn> = [];
      const addOnPricingModelMap = {
        [PricingModelTypeGrpc.FLATFEE]: AddOnPricingModelType.AddonFlatFee,
        [PricingModelTypeGrpc.PERUNIT]: AddOnPricingModelType.AddonPerUnit,
        [PricingModelTypeGrpc.RANGED]: AddOnPricingModelType.AddonRanged,
        [PricingModelTypeGrpc.TIERED]: AddOnPricingModelType.AddonTiered,
        [PricingModelTypeGrpc.VOLUME]: AddOnPricingModelType.AddonVolume
      }

      // we need the map the model received from the grpc call to our local IAddon model to be able to reuse the addon related components :(
      const subscriptionAddons = subscriptionAddonsGrpc.map(subAddon => {
        const addOn: IAddOn = {
          id: subAddon.getId(),
          name: subAddon.getName(),
          displayName: subAddon.getDisplayName(),
          description: subAddon.getDescription(),
          singleUnitName: subAddon.getSingleUnitName(),
          pluralUnitName: subAddon.getPluralUnitName(),
          isForced: subAddon.getIsForced(),
          pricingModelType: (addOnPricingModelMap as any)[subAddon.getPricingModelType()],
          pricingModels: subAddon.getAddOnPriceModelsList().map(pricingModel => ({
            frequency: pricingModel.getFrequency(),
            frequencyType: frequencyTypeMap[pricingModel.getFrequencyType()],
            flatFeePrice: Number(pricingModel.getFlatFeePrice()),
            perUnitPrice: Number(pricingModel.getPerUnitPrice()),
            pricingModelType: (addOnPricingModelMap as any)[subAddon.getPricingModelType()],
            priceFormatted: pricingModel.getFlatFeePriceFormatted() || pricingModel.getPerUnitPriceFormatted(),
            tiers: pricingModel.getTiersList().map(tier => ({
              start: tier.getStart(),
              finish: tier.getFinish(),
              price: tier.getPrice(),
              priceFormatted: tier.getPriceFormatted()
            })),
            currency: {
              englishName: (pricingModel.getCurrency() || new Currency()).getEnglishName(),
              symbol: (pricingModel.getCurrency() || new Currency()).getSymbol(),
              isoCode: (pricingModel.getCurrency() || new Currency()).getIso3Code(),
              hasDecimal: (pricingModel.getCurrency() || new Currency()).getHasDecimal()
            }
          })),
          units: subAddon.getQuantity(),
          unitsNext: Number(subAddon.getQuantityNext()),
          willBeDeleted: subAddon.getWillBeDeleted(),
          imageUrl: subAddon.getImageUrl()
        };
        return addOn;
      })

      return { ...state, subscriptionAddons, isFetchSubscriptionAddOnsSuccess: true, forcedAddOns, selectedAddOns }
    }
    case FETCH_SUBSCRIPTION_ADDONS_FAILURE:
      return { ...state, isFetchSubscriptionAddOnsFailure: true }

    case FETCH_SUBSCRIPTION_ALLOWANCES_SUCCESS: {
      const { subscriptionReducer: { currentSubscription } } = store;

      const getAllowancesResponse = action.response as GetAllowancesResponse;
      const subscriptionAllowancesGrpc = getAllowancesResponse.getAllowancesList();
      const allowancePricingModelMap = {
        [PricingModelTypeGrpc.CAPPED]: AllowancePricingModelType.AllowanceCapped,
        [PricingModelTypeGrpc.PERUNIT]: AllowancePricingModelType.AllowancePerUnit,
        [PricingModelTypeGrpc.RANGED]: AllowancePricingModelType.AllowanceRanged,
        [PricingModelTypeGrpc.TIERED]: AllowancePricingModelType.AllowanceTiered,
        [PricingModelTypeGrpc.VOLUME]: AllowancePricingModelType.AllowanceVolume,
      }

      // we need the map the model received from the grpc call to our local IAllowance model to be able to reuse the allowance related components :(
      const subscriptionAllowances = subscriptionAllowancesGrpc.map(subAllowance => {
        const allowance: IAllowance = {
          id: subAllowance.getId(),
          name: subAllowance.getName(),
          displayName: subAllowance.getDisplayName(),
          description: subAllowance.getDescription(),
          singleUnitName: subAllowance.getSingleUnitName(),
          pluralUnitName: subAllowance.getPluralUnitName(),
          isForced: subAllowance.getIsForced(),
          pricingModelType: (allowancePricingModelMap as any)[subAllowance.getPricingModelType()],
          pricingModels: subAllowance.getAllowancePriceModelsList().map(pricingModel => ({
            frequency: pricingModel.getFrequency(),
            frequencyType: frequencyTypeMap[pricingModel.getFrequencyType()],
            perUnitPrice: Number(pricingModel.getPerUnitPrice()),
            pricingModelType: (allowancePricingModelMap as any)[subAllowance.getPricingModelType()],
            includedUnits: Number(pricingModel.getIncludedUnits()),
            priceFormatted: pricingModel.getPerUnitPriceFormatted(),
            tiers: pricingModel.getTiersList().map(tier => ({
              start: tier.getStart(),
              finish: tier.getFinish(),
              price: tier.getPrice(),
              priceFormatted: tier.getPriceFormatted()
            })),
            currency: {
              englishName: (pricingModel.getCurrency() || new Currency()).getEnglishName(),
              symbol: (pricingModel.getCurrency() || new Currency()).getSymbol(),
              isoCode: (pricingModel.getCurrency() || new Currency()).getIso3Code(),
              hasDecimal: (pricingModel.getCurrency() || new Currency()).getHasDecimal()
            }
          })),
          units: subAllowance.getQuantity() || 1,
          willBeDeleted: subAllowance.getWillBeDeleted(),
          imageUrl: subAllowance.getImageUrl()
        };
        return allowance;
      })

      const allowances = action.response as GetAllowancesResponse;
      const newAllowancesOverage = new AllowanceOverage();
      let allowancesOvergeList: Array<AddonsAllowances> = [];

      if (currentSubscription) {
        allowances.getAllowancesList().forEach(al => {
          const newAddonAllowance = new AddonsAllowances();
          newAddonAllowance.setId(al.getId());
          newAddonAllowance.setUnits(al.getQuantity())
          allowancesOvergeList.push(newAddonAllowance)
        });


        newAllowancesOverage.setCycleId(currentSubscription.cycleId);
        newAllowancesOverage.setPlanId(currentSubscription.planId)
        newAllowancesOverage.setAllowanceOveragesList(allowancesOvergeList)
      }

      return { ...state, subscriptionAllowances, isFetchSubscriptionAllowanceSuccess: true, allowancesOverage: newAllowancesOverage }
    }
    case FETCH_SUBSCRIPTION_ALLOWANCES_FAILURE:
      return { ...state, isFetchSubscriptionAllowancesFailure: true }

    case ADD_NEW_ADDONS_REQUEST:
      return { ...state, isAddingNewAddOnsRequest: true, isAddingNewAddOnsSuccess: false, isAddingNewAddOnsFailure: false }
    case ADD_NEW_ADDONS_SUCCESS: {
      sendEvent(CROSS_DOMAIN_EVENT.CHANGE_ADDONS_SUCCESS)
      const response = action.response as UpdateSubscriptionAddonsResponse;
      const updateSubscriptionAddonSuccess = response.getIsSuccess();
      return {
        ...state,
        isAddingNewAddOnsRequest: false,
        isAddingNewAddOnsSuccess: updateSubscriptionAddonSuccess,
        isAddingNewAddOnsFailure: !updateSubscriptionAddonSuccess
      }
    }
    case ADD_NEW_ADDONS_FAILURE:
      return { ...state, isAddingNewAddOnsRequest: false, isAddingNewAddOnsSuccess: false, isAddingNewAddOnsFailure: true }

    case EDIT_ADDONS_REQUEST:
      return { ...state, isEditingAddOnsRequest: true, isEditingAddOnsSuccess: false, isEditingAddOnsFailure: false }
    case EDIT_ADDONS_SUCCESS: {
      sendEvent(CROSS_DOMAIN_EVENT.CHANGE_ADDONS_SUCCESS)
      const response = action.response as UpdateSubscriptionAddonsResponse;
      const updateSubscriptionAddonSuccess = response.getIsSuccess();
      return {
        ...state,
        isEditingAddOnsRequest: false,
        isEditingAddOnsSuccess: updateSubscriptionAddonSuccess,
        isEditingAddOnsFailure: !updateSubscriptionAddonSuccess
      }
    }
    case EDIT_ADDONS_FAILURE:
      return { ...state, isEditingAddOnsRequest: false, isEditingAddOnsSuccess: false, isEditingAddOnsFailure: true }

    case REMOVE_ALLOWANCE_REQUEST:
      return { ...state, isRemovingAllowancesRequest: true, isRemovingAllowancesSuccess: false, isRemovingAllowancesFailure: false }
    case REMOVE_ALLOWANCE_SUCCESS: {
      sendEvent(CROSS_DOMAIN_EVENT.CHANGE_ALLOWANCES_SUCCESS)
      const response = action.response as UpdateSubscriptionAllowanceResponse;
      const updateSubscriptionAllowanceSuccess = response.getIsSuccess();
      return {
        ...state,
        isRemovingAllowancesRequest: false,
        isRemovingAllowancesSuccess: updateSubscriptionAllowanceSuccess,
        isRemovingAllowancesFailure: !updateSubscriptionAllowanceSuccess
      }
    }
    case REMOVE_ALLOWANCE_FAILURE:
      return { ...state, isRemovingAllowancesRequest: false, isRemovingAllowancesSuccess: false, isRemovingAllowancesFailure: true }

    case ADD_NEW_ALLOWANCES_REQUEST:
      return { ...state, isAddingNewAllowancesRequest: true, isAddingNewAllowancesSuccess: false, isAddingNewAllowancesFailure: false }
    case ADD_NEW_ALLOWANCES_SUCCESS:
      sendEvent(CROSS_DOMAIN_EVENT.CHANGE_ALLOWANCES_SUCCESS)
      const response = action.response as UpdateSubscriptionAllowanceResponse;
      const updateSubscriptionAllowanceSuccess = response.getIsSuccess();
      return {
        ...state,
        isAddingNewAllowancesRequest: false,
        isAddingNewAllowancesSuccess: updateSubscriptionAllowanceSuccess,
        isAddingNewAllowancesFailure: !updateSubscriptionAllowanceSuccess
      }
    case ADD_NEW_ALLOWANCES_FAILURE:
      return { ...state, isAddingNewAllowancesRequest: false, isAddingNewAllowancesSuccess: false, isAddingNewAllowancesFailure: true }
    case GET_ADDON_PRICE_SIMULATION_SUCCESS:
      return { ...state, addOnPriceSimulation: action.response }
    case GET_ALLOWANCE_PRICE_SIMULATION_SUCCESS:
      return { ...state, allowancePriceSimulation: action.response }


    case ADD_OUT_OF_RANGE_ADDON:
      return { ...state, outOfRangeAddOns: [...state.outOfRangeAddOns, action.payload] }

    case REMOVE_OUT_OF_RANGE_ADDON:
      return { ...state, outOfRangeAddOns: state.outOfRangeAddOns.filter(or => or !== action.payload) }

    case GET_ADDON_PRICING_DISCLOSURE_SUCCESS: {
      const response = action.response as GetCheckoutAddonsSimulationPricingDisclosureResponse;
      return { ...state, addOnPricingDisclosure: response.getPricingDisclosure() }
    }

    case GET_ALLOWANCE_PRICING_DISCLOSURE_SUCCESS: {
      const response = action.response as AllowancesPricingDisclosureResponse;
      return { ...state, allowancePricingDisclosure: response.getPricingDisclosure() }
    }

    case RESET_ADDON_STATE:
      return { ...initialState }

    default:
      return state;
  }
}