import { ISubscriptionACH, ISubscriptionExistingCustomer, ISubscriptionSCA } from "../models/Subscription";
import { IAddOn, IAllowance } from "../models/AddOnAllowance";
import API from "../utils/API";
import { reduxStore } from "..";
import { makeActionCreator } from "../utils/reduxUtils";
import { InvoiceServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/invoice/InvoiceServiceClientPb";
import { GetInvoiceSimulationRequest, AddonsAllowances, AllowanceOverage } from "../utils/grpc/generated/Billsby.Protos/billing/public/invoice/invoice_pb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { ConfigConstants } from "../utils/config";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import { GetCustomerPaymentDetailsRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/customer_pb";
import { CustomerServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/CustomerServiceClientPb";

export const SET_PAYMENT_CURRENT_SCREEN = "SET_PAYMENT_CURRENT_SCREEN";
export const setPaymentCurrentScreen = makeActionCreator(SET_PAYMENT_CURRENT_SCREEN, "payload");

export const SET_CARD_HOLDER_NAME = "SET_CARD_HOLDER_NAME";
export const setCardHolderName = makeActionCreator(SET_CARD_HOLDER_NAME, "payload");
export const SET_CARD_EXPIRY_MONTH = "SET_CARD_EXPIRY_MONTH";
export const setCardExpiryMonth = makeActionCreator(SET_CARD_EXPIRY_MONTH, "payload");
export const SET_CARD_EXPIRY_YEAR = "SET_CARD_EXPIRY_YEAR";
export const setCardExpiryYear = makeActionCreator(SET_CARD_EXPIRY_YEAR, "payload");
export const SET_CARD_ERRORS = "SET_CARD_ERRORS";
export const setCardsErrors = makeActionCreator(SET_CARD_ERRORS, "payload");
export const SET_PAYMENT_TOKEN = "SET_PAYMENT_TOKEN";
export const setPaymentToken = makeActionCreator(SET_PAYMENT_TOKEN, "payload");
export const SET_TERMS_POLICY = "SET_TERMS_POLICY";
export const setTermsPolicy = makeActionCreator(SET_TERMS_POLICY, "payload");
export const RESET_PAYMENT_STATE = "RESET_PAYMENT_STATE";
export const resetPaymentState = makeActionCreator(RESET_PAYMENT_STATE);
export const VALIDATE_PAYMENT_DATA = "VALIDATE_PAYMENT_DATA";
export const SET_PAYMENT_GATEWAY_TYPE = "SET_PAYMENT_GATEWAY_TYPE";
export const setPaymentGatewayType = makeActionCreator(SET_PAYMENT_GATEWAY_TYPE, "payload");

export const SET_CARD_TYPE = "SET_CARD_TYPE";
export const setCardType = makeActionCreator(SET_CARD_TYPE, "payload");
export const SET_CARD_LAST_FOUR_DIGIT = "SET_CARD_LAST_FOUR_DIGIT";
export const setCardLastFourDigit = makeActionCreator(SET_CARD_LAST_FOUR_DIGIT, "payload");

export const CREATE_SUBSCRIPTION_REQUEST = "CREATE_SUBSCRIPTION_REQUEST";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAILURE = "CREATE_SUBSCRIPTION_FAILURE";

export const GET_HAS_CUSTOMER_CARD_ON_FILE_REQUEST = "GET_HAS_CUSTOMER_CARD_ON_FILE";
export const GET_HAS_CUSTOMER_CARD_ON_FILE_SUCCESS = "GET_HAS_CUSTOMER_CARD_ON_FILE_SUCCESS";
export const GET_HAS_CUSTOMER_CARD_ON_FILE_FAILURE = "GET_HAS_CUSTOMER_CARD_ON_FILE_FAILURE";

export function createSubscriptionSca(companyDomain: string, subscription: ISubscriptionSCA | ISubscriptionACH) {
  return {
    types: [CREATE_SUBSCRIPTION_REQUEST, CREATE_SUBSCRIPTION_SUCCESS, CREATE_SUBSCRIPTION_FAILURE],
    callAPI: () => API.createSubscriptionSCA(companyDomain, subscription)
  }
}

export function createSubscriptionExistingCustomer(companyDomain: string, customerUniqueId: string, subscription: ISubscriptionExistingCustomer) {
  return {
    types: [CREATE_SUBSCRIPTION_REQUEST, CREATE_SUBSCRIPTION_SUCCESS, CREATE_SUBSCRIPTION_FAILURE],
    callAPI: () => API.createSubscriptionExistingCustomer(companyDomain,customerUniqueId, subscription)
  }
}


export const GET_PRICE_DESCRIPTION_REQUEST = "GET_PRICE_DESCRIPTION_REQUEST";
export const GET_PRICE_DESCRIPTION_SUCCESS = "GET_PRICE_DESCRIPTION_SUCCESS";
export const GET_PRICE_DESCRIPTION_FAILURE = "GET_PRICE_DESCRIPTION_FAILURE";

const buildInvoiceSimulationRequest = (cycleId: number, addons: Array<IAddOn | IAllowance>, allowances: Array<IAddOn | IAllowance>, units: number = 1, couponsList?: Array<string>, 
  discountIdsList?: Array<number>, allowancesOverage?: AllowanceOverage, currentCycleId?: number, customerCountryIso3?: string) => {

  const getInvoiceSimulation = new GetInvoiceSimulationRequest();

  getInvoiceSimulation.setCompanyDomain(reduxStore.getState().globalReducer.companyDomain);
  getInvoiceSimulation.setCycleId(cycleId);
  getInvoiceSimulation.setUnits(units || 1);
  couponsList && getInvoiceSimulation.setCouponsList(couponsList);
  discountIdsList && getInvoiceSimulation.setDiscountsList(discountIdsList)
  allowancesOverage && getInvoiceSimulation.setAllowanceOverage(allowancesOverage)
  customerCountryIso3 && getInvoiceSimulation.setCustomerCountry(customerCountryIso3);
  // getInvoiceSimulation.setDiscountIdsList(discountIdsList);

  if(currentCycleId) {
    const val = new Int32Value();
    val.setValue(currentCycleId);
    getInvoiceSimulation.setCurrentCycleId(val);
  }

  addons.forEach(addon => {
    let item = new AddonsAllowances();
    item.setId(addon.id);
    item.setUnits(addon.units || 1);
    getInvoiceSimulation.getAddonsList().push(item);
  });

  allowances.forEach(allowance => {
    let item = new AddonsAllowances();
    item.setId(allowance.id);
    item.setUnits(allowance.units || 1);
    getInvoiceSimulation.getAllowancesList().push(item);
  });

  return getInvoiceSimulation

}
export function getPriceDescription(cycleId: number, addons: Array<IAddOn | IAllowance>, allowances: Array<IAddOn | IAllowance>, units: number = 1, couponsList?: Array<string>, 
  discountIdsList?: Array<number>, allowancesOverage?: AllowanceOverage, currentCycleId?: number, customerCountryIso3?: string) {
  
  const getInvoiceSimulation = buildInvoiceSimulationRequest(cycleId, addons, allowances, units, couponsList, discountIdsList, allowancesOverage, currentCycleId, customerCountryIso3)
  const invoiceService = new InvoiceServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [GET_PRICE_DESCRIPTION_REQUEST, GET_PRICE_DESCRIPTION_SUCCESS, GET_PRICE_DESCRIPTION_FAILURE],
    callAPI: () => grpcUnaryCall(getInvoiceSimulation, invoiceService, invoiceService.getInvoiceSimulation)
  }
}

export const GET_PRICING_INFO_DISCLOSURE_REQUEST = "GET_PRICING_INFO_DISCLOSURE_REQUEST";
export const GET_PRICING_INFO_DISCLOSURE_SUCCESS = "GET_PRICING_INFO_DISCLOSURE_SUCCESS";
export const GET_PRICING_INFO_DISCLOSURE_FAILURE = "GET_PRICING_INFO_DISCLOSURE_FAILURE";

export const getPricingInfoDisclosure = (cycleId: number, addons: Array<IAddOn | IAllowance>, allowances: Array<IAddOn | IAllowance>, units: number = 1, couponsList?: Array<string>, 
  discountIdsList?: Array<number>, allowancesOverage?: AllowanceOverage, currentCycleId?: number, customerCountryIso3?: string) => {
  const getInvoiceSimulation = buildInvoiceSimulationRequest(cycleId, addons, allowances, units, couponsList, discountIdsList, allowancesOverage, currentCycleId, customerCountryIso3)
  const invoiceService = new InvoiceServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [GET_PRICING_INFO_DISCLOSURE_REQUEST, GET_PRICING_INFO_DISCLOSURE_SUCCESS, GET_PRICING_INFO_DISCLOSURE_FAILURE],
    callAPI: () => grpcUnaryCall(getInvoiceSimulation, invoiceService, invoiceService.getInvoicePricingDisclosure)
  }
}

export const getHasCustomerCardOnFile = (companyDomain: string, customerUniqueId: string) => {
  const getCustomerCardDetailsRequest = new GetCustomerPaymentDetailsRequest();
  getCustomerCardDetailsRequest.setCompanyDomain(companyDomain);
  getCustomerCardDetailsRequest.setCustomerUniqueId(customerUniqueId);
  
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [GET_HAS_CUSTOMER_CARD_ON_FILE_REQUEST, GET_HAS_CUSTOMER_CARD_ON_FILE_SUCCESS, GET_HAS_CUSTOMER_CARD_ON_FILE_FAILURE],
    callAPI: () => grpcUnaryCall(getCustomerCardDetailsRequest, customerServiceClient, customerServiceClient.getCustomerPaymentDetails)
  }
}