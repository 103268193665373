import { makeActionCreator, makeGenericActionCreator } from "../utils/reduxUtils";
import { GetAddonsRequest, UpdateSubscriptionAddonsRequest, SubscriptionAddon, CheckoutAddonsSimulationRequest, UpdateSubscriptionAllowanceRequest, CheckoutAllowancesSimulationRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/subscription_pb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { SubscriptionServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/SubscriptionServiceClientPb";
import { ConfigConstants } from "../utils/config";
import { IAddOn, IAllowance } from "../models/AddOnAllowance";
import { IAddOnReducer } from "../reducers/addOnReducer";
import { FETCH_PLANS_SUCCESS } from "./selectPlanActions";

//IMPORTANT: This is to prevent encountering the error
//ReferenceError: Cannot access 'initialState' before initialization 
//when adding unit tests for addOnReducer.ts
export const FETCH_PLANS_SUCCESS_FOR_ADDONS = FETCH_PLANS_SUCCESS;

export const SET_ADDON_FIELD = "SET_ADDON_FIELD";
export const setAddonFields = makeGenericActionCreator<keyof IAddOnReducer>(SET_ADDON_FIELD);

export const SET_ADDONS = "SET_ADDONS";
export const setAddOns = makeActionCreator(SET_ADDONS, "payload");

export const SET_ALLOWANCES = "SET_ALLOWANCES";
export const setAllowances = makeActionCreator(SET_ALLOWANCES, "payload");

export const SET_ADDONS_UNITS = "SET_ADDONS_UNITS";
export const setAddOnsUnits = makeActionCreator(SET_ADDONS_UNITS, "payload");

export const SET_ALLOWANCE_UNITS = "SET_ALLOWANCE_UNITS";
export const setAllowanceUnits = makeActionCreator(SET_ALLOWANCE_UNITS, "payload");

export const SET_SELECTED_ALLOWANCES = "SET_SELECTED_ALLOWANCES";
export const setSelectedAllowances = makeActionCreator(SET_SELECTED_ALLOWANCES, "payload");

export const SET_SELECTED_ADD_ON = "SET_SELECTED_ADD_ON";
export const setSelectedAddOn = makeActionCreator(SET_SELECTED_ADD_ON, "payload");

export const SET_SELECTED_ADD_ONS = "SET_SELECTED_ADD_ONS";
export const setSelectedAddOns = makeActionCreator(SET_SELECTED_ADD_ONS, "payload");

export const SET_SELECTED_ADD_ONS_UNITS = "SET_SELECTED_ADD_ONS_UNITS";
export const setSelectedAddOnsUnits = makeActionCreator(SET_SELECTED_ADD_ONS_UNITS, "payload");

export const FETCH_SUBSCRIPTION_ADDONS_REQUEST = "FETCH_SUBSCRIPTION_ADDONS_REQUEST";
export const FETCH_SUBSCRIPTION_ADDONS_SUCCESS = "FETCH_SUBSCRIPTION_ADDONS_SUCCESS";
export const FETCH_SUBSCRIPTION_ADDONS_FAILURE = "FETCH_SUBSCRIPTION_ADDONS_FAILURE";

export const FETCH_SUBSCRIPTION_ALLOWANCES_REQUEST = "FETCH_SUBSCRIPTION_ALLOWANCES_REQUEST";
export const FETCH_SUBSCRIPTION_ALLOWANCES_SUCCESS = "FETCH_SUBSCRIPTION_ALLOWANCES_SUCCESS";
export const FETCH_SUBSCRIPTION_ALLOWANCES_FAILURE = "FETCH_SUBSCRIPTION_ALLOWANCES_FAILURE";

export const ADD_NEW_ADDONS_REQUEST = "ADD_NEW_ADDONS_REQUEST";
export const ADD_NEW_ADDONS_SUCCESS = "ADD_NEW_ADDONS_SUCCESS";
export const ADD_NEW_ADDONS_FAILURE = "ADD_NEW_ADDONS_FAILURE";


export const EDIT_ADDONS_REQUEST = "EDIT_ADDONS_REQUEST";
export const EDIT_ADDONS_SUCCESS = "EDIT_ADDONS_SUCCESS";
export const EDIT_ADDONS_FAILURE = "EDIT_ADDONS_FAILURE";

export const REMOVE_ALLOWANCE_REQUEST = "REMOVE_ALLOWANCE_REQUEST";
export const REMOVE_ALLOWANCE_SUCCESS = "REMOVE_ALLOWANCE_SUCCESS";
export const REMOVE_ALLOWANCE_FAILURE = "REMOVE_ALLOWANCE_FAILURE";

export const ADD_NEW_ALLOWANCES_REQUEST = "ADD_NEW_ALLOWANCES_REQUEST";
export const ADD_NEW_ALLOWANCES_SUCCESS = "ADD_NEW_ALLOWANCES_SUCCESS";
export const ADD_NEW_ALLOWANCES_FAILURE = "ADD_NEW_ALLOWANCES_FAILURE";

export const GET_ADDON_PRICE_SIMULATION_REQUEST = "GET_ADDON_PRICE_SIMULATION_REQUEST";
export const GET_ADDON_PRICE_SIMULATION_SUCCESS = "GET_ADDON_PRICE_SIMULATION_SUCCESS";
export const GET_ADDON_PRICE_SIMULATION_FAILURE = "GET_ADDON_PRICE_SIMULATION_FAILURE";


export const GET_ADDON_PRICING_DISCLOSURE_REQUEST = "GET_ADDON_PRICING_DISCLOSURE_REQUEST";
export const GET_ADDON_PRICING_DISCLOSURE_SUCCESS = "GET_ADDON_PRICING_DISCLOSURE_SUCCESS";
export const GET_ADDON_PRICING_DISCLOSURE_FAILURE = "GET_ADDON_PRICING_DISCLOSURE_FAILURE";

export const GET_ALLOWANCE_PRICE_SIMULATION_REQUEST = "GET_ALLOWANCE_PRICE_SIMULATION_REQUEST";
export const GET_ALLOWANCE_PRICE_SIMULATION_SUCCESS = "GET_ALLOWANCE_PRICE_SIMULATION_SUCCESS";
export const GET_ALLOWANCE_PRICE_SIMULATION_FAILURE = "GET_ALLOWANCE_PRICE_SIMULATION_FAILURE";

export const GET_ALLOWANCE_PRICING_DISCLOSURE_REQUEST = "GET_ALLOWANCE_PRICING_DISCLOSURE_REQUEST";
export const GET_ALLOWANCE_PRICING_DISCLOSURE_SUCCESS = "GET_ALLOWANCE_PRICING_DISCLOSURE_SUCCESS";
export const GET_ALLOWANCE_PRICING_DISCLOSURE_FAILURE = "GET_ALLOWANCE_PRICING_DISCLOSURE_FAILURE";

export const SET_PRELOADED_ADDONS = "SET_PRELOADED_ADDONS";
export const setPreloadedAddOns = makeActionCreator(SET_PRELOADED_ADDONS, "payload");

export const SET_PRELOADED_ALLOWANCES = "SET_PRELOADED_ALLOWANCES";
export const setPreloadedAllowances = makeActionCreator(SET_PRELOADED_ALLOWANCES, "payload");

export const ADD_OUT_OF_RANGE_ADDON = "ADD_OUT_OF_RANGE_ADDON";
export const addOutOfRangeAddOn = makeActionCreator(ADD_OUT_OF_RANGE_ADDON);

export const REMOVE_OUT_OF_RANGE_ADDON = "REMOVE_OUT_OF_RANGE_ADDON";
export const removeOutOfRangeAddOn = makeActionCreator(REMOVE_OUT_OF_RANGE_ADDON);

export const RESET_ADDON_STATE = "RESET_ADDON_STATE";
export const resetAddOnState = makeActionCreator(RESET_ADDON_STATE);


export const fetchSubscriptionAddons = (companyDomain: string, subscriptionUniqueId: string) => {
  const getSubscriptionAddons = new GetAddonsRequest();
  getSubscriptionAddons.setCompanyDomain(companyDomain);
  getSubscriptionAddons.setSubscriptionUniqueId(subscriptionUniqueId);
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_SUBSCRIPTION_ADDONS_REQUEST, FETCH_SUBSCRIPTION_ADDONS_SUCCESS, FETCH_SUBSCRIPTION_ADDONS_FAILURE],
    callAPI: () => grpcUnaryCall(getSubscriptionAddons, subscriptionServiceClient, subscriptionServiceClient.getAddons)
  }
}

export const fetchSubscriptionAllowances = (companyDomain: string, subscriptionUniqueId: string) => {
  const getSubscriptionAllowances = new GetAddonsRequest();
  getSubscriptionAllowances.setCompanyDomain(companyDomain);
  getSubscriptionAllowances.setSubscriptionUniqueId(subscriptionUniqueId);
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_SUBSCRIPTION_ALLOWANCES_REQUEST, FETCH_SUBSCRIPTION_ALLOWANCES_SUCCESS, FETCH_SUBSCRIPTION_ALLOWANCES_FAILURE],
    callAPI: () => grpcUnaryCall(getSubscriptionAllowances, subscriptionServiceClient, subscriptionServiceClient.getAllowances)
  }
}

export const addNewAllowances = (companyDomain: string, subscriptionUniqueId: string, allowances: Array<IAllowance>) => {
  const updateSubscriptionAllowancesRequest = new UpdateSubscriptionAllowanceRequest();
  updateSubscriptionAllowancesRequest.setCompanyDomain(companyDomain);
  updateSubscriptionAllowancesRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  updateSubscriptionAllowancesRequest.setSubscriptionAllowancesList(allowances.filter(a => !!a.units).map(al => al.id))
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [ADD_NEW_ALLOWANCES_REQUEST, ADD_NEW_ALLOWANCES_SUCCESS, ADD_NEW_ALLOWANCES_FAILURE],
    callAPI: () => grpcUnaryCall(updateSubscriptionAllowancesRequest, subscriptionServiceClient, subscriptionServiceClient.updateSubscriptionAllowance)
  }
}

export const removeSubscriptionAllowances = (companyDomain: string, subscriptionUniqueId: string, allowances: Array<IAllowance>) => {
  const updateSubscriptionAllowancesRequest = new UpdateSubscriptionAllowanceRequest();
  updateSubscriptionAllowancesRequest.setCompanyDomain(companyDomain);
  updateSubscriptionAllowancesRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  updateSubscriptionAllowancesRequest.setSubscriptionAllowancesList(allowances.filter(a => !!a.units).map(al => al.id))
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [REMOVE_ALLOWANCE_REQUEST, REMOVE_ALLOWANCE_SUCCESS, REMOVE_ALLOWANCE_FAILURE],
    callAPI: () => grpcUnaryCall(updateSubscriptionAllowancesRequest, subscriptionServiceClient, subscriptionServiceClient.updateSubscriptionAllowance)
  }
}

export const addNewAddons = (companyDomain: string, subscriptionUniqueId: string, newAddOns: Array<IAddOn>) => {
  const updateSubscriptionAddonsRequest = new UpdateSubscriptionAddonsRequest();
  updateSubscriptionAddonsRequest.setCompanyDomain(companyDomain);
  updateSubscriptionAddonsRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  updateSubscriptionAddonsRequest.setSubscriptionAddonsList(newAddOns.filter(a => !!a.units).map(a => {
    const subAddon = new SubscriptionAddon();
    subAddon.setAddonId(a.id);
    subAddon.setQuantity(Number(a.units || 1));
    return subAddon;
  }))
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [ADD_NEW_ADDONS_REQUEST, ADD_NEW_ADDONS_SUCCESS, ADD_NEW_ADDONS_FAILURE],
    callAPI: () => grpcUnaryCall(updateSubscriptionAddonsRequest, subscriptionServiceClient, subscriptionServiceClient.updateSubscriptionAddons)
  }
}

export const editSubscriptionAddons = (companyDomain: string, subscriptionUniqueId: string, addOns: Array<IAddOn>) => {
  const updateSubscriptionAddonsRequest = new UpdateSubscriptionAddonsRequest();
  updateSubscriptionAddonsRequest.setCompanyDomain(companyDomain);
  updateSubscriptionAddonsRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  updateSubscriptionAddonsRequest.setSubscriptionAddonsList(addOns.filter(a => !!a.units).map(a => {
    const subAddon = new SubscriptionAddon();
    subAddon.setAddonId(a.id);
    subAddon.setQuantity(Number(a.units || 1));
    return subAddon;
  }))
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [EDIT_ADDONS_REQUEST, EDIT_ADDONS_SUCCESS, EDIT_ADDONS_FAILURE],
    callAPI: () => grpcUnaryCall(updateSubscriptionAddonsRequest, subscriptionServiceClient, subscriptionServiceClient.updateSubscriptionAddons)
  }
}

export const getAddOnPriceSimulation = (companyDomain: string, subscriptionUniqueId: string, addons: Array<IAddOn>) => {
  const checkoutAddonsSimulationRequest = new CheckoutAddonsSimulationRequest();
  checkoutAddonsSimulationRequest.setCompanyDomain(companyDomain);
  checkoutAddonsSimulationRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  checkoutAddonsSimulationRequest.setSubscriptionAddonsList(addons.map(addon => {
    const subAddon = new SubscriptionAddon();
    subAddon.setAddonId(addon.id);
    subAddon.setQuantity(Number(addon.units || 1));
    return subAddon;
  }));
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [GET_ADDON_PRICE_SIMULATION_REQUEST, GET_ADDON_PRICE_SIMULATION_SUCCESS, GET_ADDON_PRICE_SIMULATION_FAILURE],
    callAPI: () => grpcUnaryCall(checkoutAddonsSimulationRequest, subscriptionServiceClient, subscriptionServiceClient.checkoutAddonsSimulation)
  }
}

export const getAddOnPricingDisclosure = (companyDomain: string, subscriptionUniqueId: string, addons: Array<IAddOn>) => {
  const checkoutAddonsSimulationRequest = new CheckoutAddonsSimulationRequest();
  checkoutAddonsSimulationRequest.setCompanyDomain(companyDomain);
  checkoutAddonsSimulationRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  checkoutAddonsSimulationRequest.setSubscriptionAddonsList(addons.map(addon => {
    const subAddon = new SubscriptionAddon();
    subAddon.setAddonId(addon.id);
    subAddon.setQuantity(Number(addon.units || 1));
    return subAddon;
  }));
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [GET_ADDON_PRICING_DISCLOSURE_REQUEST, GET_ADDON_PRICING_DISCLOSURE_SUCCESS, GET_ADDON_PRICING_DISCLOSURE_FAILURE],
    callAPI: () => grpcUnaryCall(checkoutAddonsSimulationRequest, subscriptionServiceClient, subscriptionServiceClient.getAddonsSimulationPricingDisclosure)
  }
}

export const getAllowancePriceSimulation = (companyDomain: string, subscriptionUniqueId: string, allowances: Array<IAllowance>) => {
  const checkoutAllowancesSimulationRequest = new CheckoutAllowancesSimulationRequest();
  checkoutAllowancesSimulationRequest.setCompanyDomain(companyDomain);
  checkoutAllowancesSimulationRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  checkoutAllowancesSimulationRequest.setSubscriptionAllowancesIdsList(allowances.map(al => al.id));
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [GET_ALLOWANCE_PRICE_SIMULATION_REQUEST, GET_ALLOWANCE_PRICE_SIMULATION_SUCCESS, GET_ALLOWANCE_PRICE_SIMULATION_FAILURE],
    callAPI: () => grpcUnaryCall(checkoutAllowancesSimulationRequest, subscriptionServiceClient, subscriptionServiceClient.checkoutAllowancesSimulation)
  }
}

export const getAllowancePricingDisclosure = (companyDomain: string, subscriptionUniqueId: string, allowances: Array<IAllowance>) => {
  const checkoutAllowancesSimulationRequest = new CheckoutAllowancesSimulationRequest();
  checkoutAllowancesSimulationRequest.setCompanyDomain(companyDomain);
  checkoutAllowancesSimulationRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  checkoutAllowancesSimulationRequest.setSubscriptionAllowancesIdsList(allowances.map(al => al.id));
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [GET_ALLOWANCE_PRICING_DISCLOSURE_REQUEST, GET_ALLOWANCE_PRICING_DISCLOSURE_SUCCESS, GET_ALLOWANCE_PRICING_DISCLOSURE_FAILURE],
    callAPI: () => grpcUnaryCall(checkoutAllowancesSimulationRequest, subscriptionServiceClient, subscriptionServiceClient.getAllowancesSimulationPricingDisclosure)
  }
}
