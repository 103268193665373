import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { RESET_ADD_DISCOUNT_OFFER, ADD_DISCOUNT_OFFER_REQUEST, ADD_DISCOUNT_OFFER_SUCCESS, ADD_DISCOUNT_OFFER_FAILURE } from "../actions/addDiscountOfferActions";


export interface IAddDiscountOfferReducer {
  isAddDiscountOfferRequest: boolean;
  isAddDiscountOfferSuccess: boolean;
  isAddDiscountOfferFailure: boolean;
}

export const initialState: IAddDiscountOfferReducer = {
  isAddDiscountOfferRequest: false,
  isAddDiscountOfferSuccess: false,
  isAddDiscountOfferFailure: false
};

export default function addDiscountOfferReducer(state = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case ADD_DISCOUNT_OFFER_REQUEST:
      return {
        ...state, isAddDiscountOfferRequest: true,
        isAddDiscountOfferSuccess: false,
        isAddDiscountOfferFailure: false
      }

    case ADD_DISCOUNT_OFFER_SUCCESS:
      return {
        ...state, isAddDiscountOfferRequest: false,
        isAddDiscountOfferSuccess: true,
        isAddDiscountOfferFailure: false
      }

    case ADD_DISCOUNT_OFFER_FAILURE:
      return {
        ...state, isAddDiscountOfferRequest: false,
        isAddDiscountOfferSuccess: false,
        isAddDiscountOfferFailure: true
      }
    
    case RESET_ADD_DISCOUNT_OFFER:
      return initialState

    default:
      return state;
  }
}
