import React, { useLayoutEffect, useMemo } from "react";
import "./PersonalDetailsStep.scss";
import Input from "../../components/ui/input/Input";
import counterpart from "counterpart";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import { setPersonalDetailsCurrentScreen, setFirstName, setLastName, setEmail, resetPersonalDetailsErrors, setCompanyName } from "../../actions/personalDetailsActions";
import { PersonalDetailsScreen } from "../../models/PersonalDetails";
import FormGroup from "../ui/form-group/FormGroup";
import { Row, Col } from "react-grid-system";
import { trimWhiteSpaces } from "../../utils/commonUtils";
import FormLabel from "../ui/form-label/FormLabel";
import SystemInfo from "../ui/system-info/SystemInfo";
import { setNavigationStep } from "../../actions/globalActions";
import { NavigationBarSteps } from "../../models/NavigationBar";
import { setSelectPlanCurrentScreen, setCycle, setProduct } from "../../actions/selectPlanActions";
import { SelectPlanScreen } from "../../models/SelectPlan";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import Panel from "../ui/panel/Panel";
import { IAddOnReducer } from "../../reducers/addOnReducer";
import { isComplexPricingModel } from "../../utils/planUtils";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { CollectCompanyNameType } from "../../models/BillsbyData";


export const PersonalDetailsStep1: React.FC = () => {
  const { mainProps, mainDataErrors, personalDetailsErrors, isUserDataScreenPreloaded } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { productId, planId, cycleId, addOnsFilteredByCycle, allowancesFilteredByCycle,
    productsDropdownAvailable, plansDropdownAvailable } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { firstName, lastName, email, companyName } = mainProps;
  const { preloadedAddOns, preloadedAllowances, hasPreloadedAddOnsWithMissingUnits } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const { checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const dispatch = useDispatch<Function>()
  const collectCompanyNameType = checkoutConfig?.collectCompanyNameType as CollectCompanyNameType;

  useLayoutEffect(() => {
    //if (firstName && lastName && email && email.match(EMAIL_REGEX) && !preloadedScreensTracker.MAIN_DATA_SCREEN) {
    if (isUserDataScreenPreloaded) {
      //we skip the screen if all data have been preloaded and we're not previously clicked a back button in this screen  
      dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.PERSONAL_ADDRESS));
      return;
    }
    return () => dispatch(resetPersonalDetailsErrors())
  }, []);

  const isCompanyNameVisible = useMemo(() => {
    return collectCompanyNameType === CollectCompanyNameType.Compulsory || collectCompanyNameType === CollectCompanyNameType.Optional;
  }, [collectCompanyNameType])

  const isCompanyNameValid = useMemo(() => {
    if(collectCompanyNameType === CollectCompanyNameType.Compulsory && !companyName) {
      return false;
    }
    return true;
  }, [collectCompanyNameType, companyName])


  return (
    <PageWrapper
      className="personal-details"
      title="PERSONAL_DETAILS_TITLE"
      titlePosition="left-aligned"
      btnCallback={() => {
        dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.PERSONAL_ADDRESS));
      }}
      btnDisabled={!firstName || !lastName || !email || !isCompanyNameValid}
      btnBackCallback={() => {
        dispatch(setNavigationStep(NavigationBarSteps.SELECT_PLAN));
        if (allowancesFilteredByCycle.length && !allowancesFilteredByCycle.every(a => a.isForced) && !preloadedAllowances.length) {
          dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ALLOWANCE));
        }
        else if ((addOnsFilteredByCycle.length && !addOnsFilteredByCycle.every(a => a.isForced && !isComplexPricingModel(a.pricingModelType)) && !preloadedAddOns.length) || (hasPreloadedAddOnsWithMissingUnits)) {
          dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ADD_ON));
        }
        else if (!planId && plansDropdownAvailable.length > 1) {
          dispatch(setCycle(null));
          dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PLAN));
        }
        else /*if (!productId && products.length > 1)*/ {
          dispatch(setProduct(null));
          dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PRODUCT));
        }
      }}
      btnBackHidden={((!!preloadedAddOns.length && !!preloadedAllowances.length) || (!addOnsFilteredByCycle.length && !allowancesFilteredByCycle.length))
        &&
        ((!!productId && !!planId && !!cycleId)
          || (productsDropdownAvailable.length === 1 && plansDropdownAvailable.length === 1 && plansDropdownAvailable[0].value.cycles.length === 1)
          || (!!productId && plansDropdownAvailable.length === 1 && plansDropdownAvailable[0].value.cycles.length === 1))
      }>

      <SystemInfo
        title={counterpart("PAYMENT_COMPULSORY_FIELDS")}
        text={personalDetailsErrors.map(err => counterpart(err)).join(", ")}
        isShowIcon={false}
        shouldTranslateTitle={false}
        shouldTranslateText={false}
        type="warning"
        isVisible={personalDetailsErrors.length > 0}
      />

      <Panel id="personal-details__field-panel" noMargin>
        <FormLabel className="personal-details__field-title" content="PERSONAL_DETAILS_NAME" shouldTranslate />
        <FormGroup>
          <Row>
            <Col xs={12} sm={6}>
              <Input
                id="first-name"
                value={firstName || ""}
                className="personal-details__first-name"
                placeholder={counterpart("PERSONAL_DETAILS_FIRST_NAME")}
                onChange={e => dispatch(setFirstName(e.target.value))}
                isError={!!mainDataErrors.firstName}
                errorMsg={mainDataErrors.firstName ? counterpart(mainDataErrors.firstName) : ""}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Input
                id="last-name"
                value={lastName || ""}
                className="personal-details__last-name"
                placeholder={counterpart("PERSONAL_DETAILS_LAST_NAME")}
                onChange={evt => dispatch(setLastName(evt.target.value))}
                isError={!!mainDataErrors.lastName}
                errorMsg={mainDataErrors.lastName ? counterpart(mainDataErrors.lastName) : ""}
              />
            </Col>
          </Row>
        </FormGroup>

      <FormGroup margin={isCompanyNameVisible ? "default" : "none"}>
        <FormLabel className="personal-details__field-title" content="PERSONAL_DETAILS_EMAIL" shouldTranslate />
        <Row>
          <Col xs={12} sm={12}>
            <Input
              id="email"
              value={email || ""}
              className="personal-details__email"
              placeholder={counterpart("PERSONAL_DETAILS_EMAIL_PLACEHOLDER")}
              onChange={evt => { dispatch(setEmail(evt.target.value)) }}
              isError={!!mainDataErrors.email}
              errorMsg={mainDataErrors.email ? counterpart(mainDataErrors.email) : ""}
            />
          </Col>
        </Row>
      </FormGroup>
      {
        isCompanyNameVisible ? 
          <>
            <FormLabel className="personal-details__field-title" content="PERSONAL_DETAILS_COMPANY_NAME" shouldTranslate />
            <Row>
              <Col xs={12} sm={12}>
                <Input
                  id="company-name"
                  value={companyName || ""}
                  className="personal-details__companyName"
                  placeholder={counterpart("PERSONAL_DETAILS_EMAIL_COMPANY_NAME")}
                  onChange={evt => { dispatch(setCompanyName(trimWhiteSpaces(evt.target.value, "left"))) }}
                />
              </Col>
            </Row>
          </>
          : null
      }
      </Panel>


    </PageWrapper>
  );
}

export default PersonalDetailsStep1
