import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { ChangePlanScreen } from "../../models/ChangePlan";
import ChangePlanStep1 from "../../components/change-plan/ChangePlanStep1";
import ChangePlanStep2 from "../../components/change-plan/ChangePlanStep2";
import ChangePlanStep3 from "../../components/change-plan/ChangePlanStep3";
import ChangePlanStep4 from "../../components/change-plan/ChangePlanStep4";
import EndContractTerm from "../../components/change-plan/EndContractTerm";
import { setChangePlanField, setIsUpdateSubscription } from "../../actions/changePlanActions";
import { resetDiscountCoupon } from "../../actions/discountCouponActions";
import { resetAddOnState } from "../../actions/addOnActions";
import SetAddOnsStep from "../../components/select-plan/SetAddOnsStep";
import SetAllowancesStep from "../../components/select-plan/SetAllowancesStep";
import history from "../../utils/history";
import { RetentionScreen } from "../../models/Retention";

interface IChangePlan {
  /* optional heading text used for example in retention */
  customizedHeading?: string,
  /* optional button under the continue wrapper button */
  bottomContent?: JSX.Element,
}

const ChangePlan: React.FC<IChangePlan> = ({ customizedHeading, bottomContent }) => {
  const currentSubscription = useSelector((state: AppState) => state.subscriptionReducer.currentSubscription);
  const changePlanReducer = useSelector((state: AppState) => state.changePlanReducer);
  const retentionReducer = useSelector((state: AppState) => state.retentionReducer);
  const selectPlanReducer = useSelector((state: AppState) => state.selectPlanReducer);

  const dispatch = useDispatch<Function>();
  
  const { currentScreen } = changePlanReducer;
  const { plan, cycle, addOnsFilteredByCycle, allowancesFilteredByCycle} = selectPlanReducer;
  
  useEffect(() => {
    if(retentionReducer.currentScreen !== RetentionScreen.changePlan) {
      dispatch(setIsUpdateSubscription(true));
    } else {
      dispatch(setIsUpdateSubscription(false));
    }

    return () => {
      dispatch(resetDiscountCoupon());
      dispatch(resetAddOnState());
    }
  }, []);

  if(!currentSubscription) {
    return null;
  }

  switch (currentScreen) {
    case ChangePlanScreen.STEP1:
      return <ChangePlanStep1 bottomContent={bottomContent} />
    case ChangePlanScreen.STEP2:
      return <ChangePlanStep2 customizedHeading={customizedHeading} bottomContent={bottomContent} />
    case ChangePlanScreen.SET_ADD_ON:
      return plan && cycle && currentSubscription && (<SetAddOnsStep
        onSubmit={() => dispatch(setChangePlanField("currentScreen", ChangePlanScreen.SET_ALLOWANCES))}
        noAddonsCb={() => dispatch(setChangePlanField("currentScreen", ChangePlanScreen.SET_ALLOWANCES))}
        btnBackCallback={() => dispatch(setChangePlanField("currentScreen", ChangePlanScreen.STEP2))}
        plan={plan.value}
        cycle={cycle}
        billingCurrency={currentSubscription.currency}
      />)    
    case ChangePlanScreen.SET_ALLOWANCES:
      return plan && cycle && (
        <SetAllowancesStep
          onSubmit={() => dispatch(setChangePlanField("currentScreen", ChangePlanScreen.STEP3))}
          noAllowanceCB={() => dispatch(setChangePlanField("currentScreen", ChangePlanScreen.STEP3))}
          btnBackCallback={() => {
            if(addOnsFilteredByCycle.length) {
              return dispatch(setChangePlanField("currentScreen", ChangePlanScreen.SET_ADD_ON));
            }
            return dispatch(setChangePlanField("currentScreen", ChangePlanScreen.STEP2));
          }}
          plan={plan.value}
          cycle={cycle}
        />
      )

    case ChangePlanScreen.STEP3:
      return <ChangePlanStep3 btnBackCallback={() => {
        const isAllAllowancesForced = allowancesFilteredByCycle.every(a => a.isForced)
        const isAllAddonsForced = addOnsFilteredByCycle.every(a => a.isForced)

        if(allowancesFilteredByCycle.length && !isAllAllowancesForced) {
          return dispatch(setChangePlanField("currentScreen", ChangePlanScreen.SET_ALLOWANCES));
        }

        if (addOnsFilteredByCycle.length && !isAllAddonsForced) {
          return dispatch(setChangePlanField("currentScreen", ChangePlanScreen.SET_ADD_ON));
        }

        return dispatch(setChangePlanField("currentScreen", ChangePlanScreen.STEP2));
        
      }}/>
    case ChangePlanScreen.STEP4:
      return <ChangePlanStep4 btnBackCallback={() => dispatch(setChangePlanField("currentScreen", ChangePlanScreen.STEP3))} />
    case ChangePlanScreen.END_CONTRACT:
      return <EndContractTerm btnBackCallback={() => history.push({ pathname: "/management", search: history.location.search })} />
    default:
      return <div/>
  }
}

export default ChangePlan;