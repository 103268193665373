import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUpdatePaymentState, UPDATE_PAYMENT_DETAILS_FAILURE, UPDATE_PAYMENT_DETAILS_REQUEST, UPDATE_PAYMENT_DETAILS_SUCCESS } from "../../actions/updatePaymentActions";
import { CustomAnalyticsEvents } from "../../models/GoogleAnalytics";
import { ICardDetailInputDetailSCA, IUpdatePaymentResponseSCA } from "../../models/PaymentDetail";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { IUpdatePaymentReducer } from "../../reducers/updatePaymentReducer";
import API from "../../utils/API";
import { gaEventTracker } from "../../utils/commonUtils";
import history from "../../utils/history";

/**
 * pending data saved in the session storage before 3ds1 redirect
 */
type IBillsbyUpdatePaymentSCApendingData = {
  cardDetailsSCA: ICardDetailInputDetailSCA,
  transactionToken: string,
  globalReducerState: IGlobalReducer,
  personalDetailsState: IPersonalDetailsReducerState,
  updatePaymentState: IUpdatePaymentReducer
}

const Sca3ds1UpdatePaymentSuccess: React.FC = () => {
  const dispatch = useDispatch<Function>();

  const callAPI = async (updatePaymentPendingScaData: IBillsbyUpdatePaymentSCApendingData) => {
    const { globalReducerState, personalDetailsState, cardDetailsSCA } = updatePaymentPendingScaData;
    const { companyDomain } = globalReducerState;
    const { mainProps: { customerUniqueId } } = personalDetailsState;

    let response = {} as IUpdatePaymentResponseSCA;
    dispatch({ type: UPDATE_PAYMENT_DETAILS_REQUEST });

    try {
      response = await API.updatePaymentDetailsSCA(companyDomain, customerUniqueId as string, { ...cardDetailsSCA, transactionToken: updatePaymentPendingScaData.transactionToken }) as IUpdatePaymentResponseSCA;
      dispatch({ type: UPDATE_PAYMENT_DETAILS_SUCCESS });
      gaEventTracker(CustomAnalyticsEvents.UPDATED_PAYMENT_DETAILS);
    } 
    catch (err) {
      dispatch(setUpdatePaymentState("customSuccessSubtitle", ""));
      dispatch({ type: UPDATE_PAYMENT_DETAILS_FAILURE });
    }
    finally {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }

  useEffect(() => {
    let updatePaymentPendingScaData = {} as IBillsbyUpdatePaymentSCApendingData;
    try {
      updatePaymentPendingScaData = JSON.parse(sessionStorage.billsbyUpdatePaymentSCA) as IBillsbyUpdatePaymentSCApendingData;
    }
    catch (err) { }

    //const { checkoutViewMode, accountManagementPermissions } = updatePaymentPendingScaData.globalReducerState;
    //dispatch(setAccountManagementPermissions(accountManagementPermissions));
    callAPI(updatePaymentPendingScaData);
  }, [])


  return null;
  
}

export default Sca3ds1UpdatePaymentSuccess;