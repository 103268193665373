import { IAddress } from "./Address";

export enum PersonalDetailsScreen {
  MAIN_DATA = "MAIN_DATA_SCREEN",
  PERSONAL_ADDRESS = "PERSONAL_ADDRESS_SCREEN",
  CUSTOM_FIELDS = "CUSTOM_FIELDS"
}

export interface PersonalDetails {
  companyCurrency: string,
  totalAmountPaid: string,
  averageAmountPerMonth: string,
  customerId: number,
  firstName: string,
  lastName: string,
  companyName: string | null,
  billingAddress: IAddress,
  email: string,
  additionalEmail: string,
  phoneNumber: string,
  phoneNumberDialCode: string,
  phoneNumberDialCountry: string,
  isMarketingConsentGiven: boolean,
  createdOn: string,
  uniqueId: string
}