import React from "react";
import InteractivePanel, { IInteractivePanel } from "../interactive-panel/InteractivePanel";
import "./InteractiveList.scss";

interface IInteractiveList {
  data: Array<IInteractivePanel>
  className?: string,
  isVisible?: boolean
}

const InteractiveList: React.FC<IInteractiveList> = React.memo(({ data, className = "", isVisible = true }) => {

  if (!isVisible) {
    return null;
  }

  return (
    <div className="interactive-list">
      {
        data.map((interactivePanel: IInteractivePanel, idx: number) => {
          return (
            <InteractivePanel
              id={interactivePanel.id}
              key={idx}
              className={`interactive-list__el ${className}`}
              isVisible={interactivePanel.isVisible}
              translateWith={interactivePanel.translateWith}
              title={interactivePanel.title}
              shouldTranslateTitle={interactivePanel.shouldTranslateTitle}
              onClick={interactivePanel.onClick}
            />
          )
        })
      }
    </div>
  )
})

export default InteractiveList;