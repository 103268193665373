import { ConfigConstants } from "./config";
import { fetchGet, fetchPost, fetchPut, fetchDelete, fetchPatch, externalGet, externalPost } from "./fetchUtils";
import { logout, refreshToken } from "./authUtils";
import history from "./history";
import { GOOGLE_RECAPTCHA_TOKEN } from "./constants";
//import { BILLSBY_AUTH_DATA_CONTROL } from './constants';
//import { IParsedToken, IToken } from '../models/Token';



function getHeaders() {
  const headers = new Headers();
  const actionToken = sessionStorage.getItem(GOOGLE_RECAPTCHA_TOKEN);

  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  actionToken && headers.append("X-Recaptcha-Token", actionToken);

  /*if (storeGet(BILLSBY_AUTH_DATA_CONTROL)) {
    const token = <IToken>storeGet(BILLSBY_AUTH_DATA_CONTROL);
    headers.append('Authorization', 'Bearer ' + token.access_token);
  }*/

  return headers;
}

function retryConnection(tries: number) {
  return new Promise((fulfill, reject) => {
    // Refresh token
    refreshToken().then(() => {
      // Token refreshed successfully
      fulfill();
    }).catch(() => {
      // If refresh token fails, try again 3 times
      tries++;
      if (tries < 3) {
        retryConnection(tries);
      } else {
        logout();
        history.push("/");
      }
    });
  });
}

function getFileUploadHeaders() {
  const headers = new Headers();

  /*if (authData.isAuth) {
    const storeData = storeGet(BILLSBY_AUTH_DATA_CONTROL);
    headers.append('Authorization', 'Bearer ' + storeData.token);
  }*/

  return headers;
}

function getExternalHeaders() {
  const createHeaders = {
    "Content-Type": "application/json",
    "Accept": "application/json",
  };

  const headers = new Headers(createHeaders);

  return headers;
}

export function apiGetExternal(req: string) {
  return externalGet(req, getExternalHeaders());
}

export function apiPostExternal(req: string, data: object) {
  return externalPost(req, getExternalHeaders(), JSON.stringify(data));
}

export function apiGet(request: string) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchGet(url, getHeaders())
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiGet(request).then((response) => {
              fulfill(response);
            });
          });
        } else {
          if (error.status === 403) {
            history.push("/forbidden");
          } else if (error.status === 429) {
            history.push("/suspicious-behavior");
          } else {
            reject(error);
          }
        }
      });
  });
}

export function apiPost(request: string, data: object, isMock?: boolean) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchPost(url, getHeaders(), JSON.stringify(data))
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiPost(request, data).then((response) => {
              fulfill(response);
            });
          });
        } else {
          if (error.status === 403) {
            history.push("/forbidden");
          } else if (error.status === 429) {
            history.push("/suspicious-behavior");
          } else {
            reject(error);
          }
        }
      });
  });
}

export function apiPatch(request: string, data: object) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchPatch(url, getHeaders(), JSON.stringify(data))
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiPatch(request, data).then((response) => {
              fulfill(response);
            });
          });
        } else {
          if (error.status === 403) {
            history.push("/forbidden");
          } else if (error.status === 429) {
            history.push("/suspicious-behavior");
          } else {
            reject(error);
          }
        }
      });
  });
}

export function apiPostFiles(request: string, data: object) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchPost(url, getFileUploadHeaders(), data)
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiPostFiles(request, data).then((response) => {
              fulfill(response);
            });
          });
        } else {
          if (error.status === 403) {
            history.push("/forbidden");
          } else if (error.status === 429) {
            history.push("/suspicious-behavior");
          } else {
            reject(error);
          }
        }
      });
  });
}

export function apiPutFiles(request: string, data: object) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchPut(url, getFileUploadHeaders(), data)
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiPutFiles(request, data).then((response) => {
              fulfill(response);
            });
          });
        } else {
          if (error.status === 403) {
            history.push("/forbidden");
          } else if (error.status === 429) {
            history.push("/suspicious-behavior");
          } else {
            reject(error);
          }
        }
      });
  });
}

export function apiPut(request: string, data: object) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchPut(url, getHeaders(), JSON.stringify(data))
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiPut(request, data).then((response) => {
              fulfill(response);
            });
          });
        } else {
          if (error.status === 403) {
            history.push("/forbidden");
          } else if (error.status === 429) {
            history.push("/suspicious-behavior");
          } else {
            reject(error);
          }
        }
      });
  });
}

export function apiDelete(request: string) {
  return new Promise((fulfill, reject) => {
    const url = ConfigConstants.baseUrl + request;

    return fetchDelete(url, getHeaders())
      .then((response) => {
        fulfill(response);
      }).catch((error) => {
        if (error.status === 401) {
          retryConnection(0).then(() => {
            apiDelete(request).then((response) => {
              fulfill(response);
            });
          });
        } else if (error.status === 429) {
          history.push("/suspicious-behavior");
        } else {
          reject(error);
        }
      });
  });
}

export function parseJSON(response: any) {
  return response.json();
}