import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import history from "./history";
import { ConfigConstants } from "./config";
const reactPlugin = new ReactPlugin();

//https://github.com/microsoft/ApplicationInsights-JS/tree/master/extensions/applicationinsights-react-js
export function loadAppInsights() {
  const isTestRunning = process.env.JEST_WORKER_ID !== undefined;
  if(isTestRunning) {
    return;
  }
  const appInsights = new ApplicationInsights({
      config: {
          instrumentationKey: ConfigConstants.instrumentationKey,
          extensions: [reactPlugin as any],
          extensionConfig: {
            [reactPlugin.identifier]: { history }
          }
      }
  });
  appInsights.loadAppInsights();
  appInsights.trackEvent({name: "Checkout"});
}