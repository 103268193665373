import React from "react";
import FormGroup from "../ui/form-group/FormGroup";
import Input from "../ui/input/Input";
import { Row, Col } from "react-grid-system";
import Dropdown from "../ui/dropdown/Dropdown";
import counterpart from "counterpart";
import { ICountry } from "../../models/Country";
import { ICountryState } from "../../models/CountryState";
import "./Address.scss";
import { isMobileOnly } from "react-device-detect";

interface IAddress {
  address1: string,
  address2: string,
  city: string,
  state: { label: string, value: ICountryState } | null,
  zipCode: string,
  country: { label: string, value: ICountry } | null,
  addressErrors: {
    address1?: string,
    city?: string,
    state?: string,
    zipCode?: string,
    country?: string
  },
  address1OnChange: (evt: any) => any,
  address2OnChange: (evt: any) => any,
  cityOnChange: (evt: any) => any,
  stateOnChange: (selectedItem: any) => any,
  zipCodeOnChange: (evt: any) => any,
  countryOnChange: (selectedItem: any) => any,
  states?: Array<{ label: string, value: ICountry }>,
  countries: Array<{ label: string, value: ICountry }>
}

const Address: React.FC<IAddress> = ({ address1, address2, city, state, zipCode, country, addressErrors,
  address1OnChange, address2OnChange, cityOnChange, stateOnChange, zipCodeOnChange, countryOnChange,
  states, countries
}) => {
  return (
    <div>
      <FormGroup>
        <Input
          id="address-line1"
          value={address1}
          className="address__line1"
          placeholder={counterpart("PERSONAL_DETAILS_ADDRESS_LINE_1")}
          onChange={address1OnChange}
          isError={!!addressErrors.address1}
          errorMsg={!!addressErrors.address1 ? counterpart(addressErrors.address1) : ""}
        />
      </FormGroup>

      <FormGroup>
        <Input
          id="address-line2"
          value={address2}
          className="address__line2"
          placeholder={counterpart("PERSONAL_DETAILS_ADDRESS_LINE_2")}
          onChange={address2OnChange}
        />
      </FormGroup>

      <FormGroup>
        <Row>
          <Col xs={12} sm={6}>
            <Input
              id="city"
              className="address__city"
              value={city}
              placeholder={counterpart("PERSONAL_DETAILS_CITY")}
              onChange={cityOnChange}
              isError={!!addressErrors.city}
              errorMsg={!!addressErrors.city ? counterpart(addressErrors.city) : ""}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Input
              id="zip-code"
              className="address__zip-code"
              value={zipCode}
              placeholder={counterpart("PERSONAL_DETAILS_ZIPCODE")}
              onChange={zipCodeOnChange}
              isError={!!addressErrors.zipCode}
              errorMsg={!!addressErrors.zipCode ? counterpart(addressErrors.zipCode) : ""}
            />
          </Col>
        </Row>
      </FormGroup>

      <FormGroup margin={isMobileOnly ? "none" : "default"}>
        <Row>
          <Col xs={12} sm={6}>
            <Dropdown
              id="country-dropdown"
              className="address__country"
              value={country}
              menuPlacement="top"
              onChange={countryOnChange}
              options={countries}
              placeholder={counterpart("PERSONAL_DETAILS_COUNTRY")}
            />
          </Col>
          <Col xs={12} sm={6}>
            {
              (country && country.value.iso3Code === "CAN"
                || country && country.value.iso3Code === "USA"
                || country && country.value.iso3Code === "IND")
                ? (
                  <Dropdown
                    id="state-dropdown"
                    className="address__state"
                    value={state}
                    menuPlacement="top"
                    onChange={stateOnChange}
                    options={!!states ? states : []}
                  />
                )
                : (
                  <Input
                    id="state"
                    className="address__state"
                    placeholder={counterpart("PERSONAL_DETAILS_STATE")}
                    isError={!!addressErrors.state}
                    errorMsg={!!addressErrors.state ? counterpart(addressErrors.state) : ""}
                    value={state && state.value ? state.value.StateName : ""}
                    onChange={(evt: any) => {
                      stateOnChange({ label: evt.target.value, value: { CountryIso2: country && country.value.iso2Code, CountryIso3: country && country.value.iso3Code, StateIso2: evt.target.value, StateName: evt.target.value } as ICountryState })
                    }}
                  />
                )

            }
          </Col>
        </Row>
      </FormGroup>

    </div>
  )
}

export default Address;