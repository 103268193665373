import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { AppState } from "..";
import { BillsbyDataAction } from "../models/BillsbyData";
import { getCurrentScreenTitle } from "../models/GoogleAnalytics";
import history from "../utils/history";
import ReactGA from "react-ga4";

/**
 * the parameter is a function and not a promise because if it was a promise the call would be execute every re-render
 * of the component that uses this hook, because promises are "hot" by default and are immediatly executed
 * @param apiFn 
 */
export const useFetch = (apiFn: () => Promise<any>) => {
  const [response, setResponse] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const json = await apiFn();
        setResponse(json);
      }
      catch (error) {
        setError(error);
      }
      finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return { response, error, isLoading };
};

/**
 * returns the previous value of a component prop, useful to compare the previous value with the current value
 * it simulates the behaviour of the lifecycle method componentWillReceiveProps()
 * @param value 
 */
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


export const useCheckOverflow = (ref: React.MutableRefObject<HTMLElement | null>, dependencies: Array<any> = []) => {
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
      if(!ref.current) {
          return;
      }

      if(ref.current.scrollHeight > ref.current.clientHeight) {
          setIsOverflown(true)
      } else {
          setIsOverflown(false)
      }
      
  }, [ref.current, ...dependencies]);

  return isOverflown
}

export const useAnalytics = () => {
  const analyticsCode = useSelector((state: AppState) => state.globalReducer.checkoutConfig?.googleAnalyticsCode);
  const navigationStep = useSelector((state: AppState) => state.globalReducer.navigationStep);

    useEffect(() => {
    const initializeAnalytics = () => {
      const arr = history.location.pathname.split("/");
      const basePath = arr[1];
      const blockTracking = basePath === "standalone" || basePath === "updatepaymentdetails" || basePath === "login";

      if (!analyticsCode || blockTracking) {
        return;
      }

      const getCurrentScreen = () => {
        if (basePath === "management") {
          return arr[arr.length - 1] as BillsbyDataAction
        }
        return navigationStep;
      }

      const currentScreen = getCurrentScreenTitle(getCurrentScreen());
      document.title = currentScreen;

      ReactGA.initialize(analyticsCode, { gaOptions: { titleCase: false, cookieFlags: "SameSite=None; Secure" } });
      ReactGA.send({ hitType: "pageview", title: currentScreen });
    }

    initializeAnalytics();
  }, [analyticsCode, navigationStep, history.location.pathname]);

  return null
}