import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { FETCH_COUPON_DETAILS_SUCCESS, SET_APPLIED_COUPONS, FETCH_COUPON_DETAILS_FAILURE, RESET_DISCOUNT_COUPON, SET_COUPON_CODE, FETCH_DISCOUNTS_SUCCESS } from "../actions/discountCouponActions";
import { Coupon, Discount, GetCouponDetailsFromCouponCodeResponse, EligibleCustomers, DiscountType } from "../utils/grpc/generated/Billsby.Protos/billing/public/coupons/coupons_pb";
import { CouponError, IAppliedCoupon } from "../models/DiscountCoupon"
import { GetDiscountsResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/discount/discount_pb";

export interface IDiscountCouponReducer {
  appliedCoupons: Array<IAppliedCoupon>,
  couponCode: string,
  couponName: string,
  originalPrice?: string
}

export const initialState: IDiscountCouponReducer = {
  appliedCoupons: [],
  couponCode: "",
  couponName: ""
}

export default function discountCouponReducer(state: IDiscountCouponReducer = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case FETCH_COUPON_DETAILS_SUCCESS:
      const couponResponse = action.response as GetCouponDetailsFromCouponCodeResponse;

      const coupon = couponResponse.getCoupon() as Coupon;
      const discount = couponResponse.getDiscount() as Discount;

      const { selectPlanReducer: { plan } } = store;
      
      //Check if customer is elligible 
      if (couponResponse.getIsExistingCustomer() && coupon.getEligibleCustomers() === EligibleCustomers.NEW_CUSTOMERS) {
        throw CouponError.NOT_FOR_EXISTING_CUSTOMERS
      }

      //Check if currency is configured
      if(discount.getDiscountType() === DiscountType.MONETARY && !(discount.getMonetary() as Discount.MonetaryModel).getPricesList().find(pl => pl.getCurrencyCode() === (store.selectPlanReducer.product && store.selectPlanReducer.product.value.billingCurrency))) {
        throw CouponError.INVALID_CODE
      }

      if (!couponResponse.getIsExistingCustomer() && coupon.getEligibleCustomers() === EligibleCustomers.EXISTING_CUSTOMERS) {
        throw CouponError.NOT_FOR_NEW_CUSTOMERS
      }

      //Check if coupon is allowed to be used in conjunction with others
      if ((!discount.getIsAllowedWithOtherDiscounts() && state.appliedCoupons.length > 0) || state.appliedCoupons.find(ac => !ac.discount.getIsAllowedWithOtherDiscounts())) {
        throw CouponError.NOT_COMPATIBLE
      }

      //Check if applicable only to selected plan
      if (plan && discount.getPlanIdsList().length > 0) {
        if (discount.getPlanIdsList().indexOf(plan.value.planId) < 0) {
          throw CouponError.INVALID_CODE
        }
      }

      //Check if discount has already been applied previously
      if (state.appliedCoupons.find(ac => ac.discount.getDiscountId() === discount.getDiscountId())) {
        throw CouponError.INVALID_CODE
      }

      return { ...state, couponName: coupon.getName(), appliedCoupons: [{ coupon, discount, couponCode: state.couponCode }, ...state.appliedCoupons] }

    case FETCH_COUPON_DETAILS_FAILURE:
      throw CouponError.INVALID_CODE

    case FETCH_DISCOUNTS_SUCCESS:
      const discountsResponse = action.response as GetDiscountsResponse;
      let subscriptionCoupons: Array<IAppliedCoupon> = [];

      discountsResponse.getDiscountsList().forEach(d => {
        subscriptionCoupons.push({ coupon: d.getCouponValue() as Coupon, discount: d.getDiscount() as Discount, subscriptionDiscount: d })
      });

      return { ...state, appliedCoupons: subscriptionCoupons }

    case SET_COUPON_CODE: {
      return { ...state, couponCode: action.payload }
    }

    case SET_APPLIED_COUPONS:
      return { ...state, appliedCoupons: action.payload }


    case RESET_DISCOUNT_COUPON:
      return initialState

    default:
      return state;
  }
}