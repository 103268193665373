import React from "react";
import { isMobileOnly } from "react-device-detect";
import ButtonBase from "@material-ui/core/ButtonBase";
import Text from "../text/Text";
import "./Icon.scss";

interface IICon {
  className?: string,
  component?: string,
  icon: string,
  onClick?: () => void,
  showBoundingBox?: boolean,
  size?: "xlarge" | "large" | "normal" | "small" | "xsmall",
  weight?: "bold" | "normal",
  hidden?: boolean,
  text?: string,
  [key: string]: any
}

const Icon: React.FC<IICon> = ({ className = "", component = "div", icon, size = "normal", text, hidden = false, showBoundingBox = true, weight = "", onClick, ...rest }) => {
  let Component: any = component === "button" ? ButtonBase : component;

  if (hidden) {
    return null;
  }

  return (
    <Component className={`icon-container ${!showBoundingBox ? "icon-container--hide-bounding-box" : ""} ${!isMobileOnly ? "icon-container--with-hover" : ""} ${className}`} {...rest} onClick={onClick}>
      <span>
        <i className={`icon icon--${size} icon--weight-${weight} fa-fw ${icon}`}></i>
        { text && <Text className="icon__text" content={text} shouldTranslate={false} noMargin></Text> }
      </span>
    </Component>
  )
}

export default Icon;