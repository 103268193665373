import React, { FormEvent, useState } from "react";
import { ICheckoutConfig } from "../../../models/BillsbyData";
import { IBranding } from "../../../models/Branding";
import { IAddOn, IAddOnPricingModel, AddOnPricingModelType, IAllowance, IAllowancePricingModel, AllowancePricingModelType } from "../../../models/AddOnAllowance";
import { IPlanWithCycles, ICycle, FrequencyType } from "../../../models/Product";
import Text from "../../ui/text/Text";
import { calculateAddOnPrice, getFrequencyText, getIncludedUnitsText, extractCurrencyFromPriceFormatted } from "../../../utils/planUtils";
import counterpart from "counterpart";
import Modal from "../../ui/modal/Modal";
import AddOnPriceInfo from "../../select-plan/cycle-price-info/AddOnPriceInfo";
import { convertFormattedPriceToNumber } from "../../../utils/commonUtils";

interface IAddOnPriceText {
  addon: IAddOn | IAllowance;
  plan: IPlanWithCycles;
  cycle: ICycle;
  checkoutConfig: ICheckoutConfig;
  branding: IBranding;
  viewPriceModalBtnText?: string;
  showPreviewPriceForComplexType?: boolean;
  showPriceOnly?: boolean
}

const AddOnPriceText: React.FC<IAddOnPriceText> = ({
  addon,
  plan,
  cycle,
  checkoutConfig,
  branding,
  viewPriceModalBtnText = "MORE_PRICING_INFO",
  showPreviewPriceForComplexType = false,
  showPriceOnly = false
}) => {
  const { pricingModels } = addon;
  const priceModel = (pricingModels as Array<IAddOnPricingModel | IAllowancePricingModel>).find(
    (i) => i.frequency === cycle.pricingModel.frequency && i.frequencyType === cycle.pricingModel.frequencyType && i.currency.isoCode === cycle.currency
  );

  const [showPricingInfo, setShowPricingInfo] = useState(false);
  const [showPricingInfoAddOn, setShowPricingInfoAddOn] = useState<IAddOn | IAllowance | undefined>(undefined);
  const [showPricingInfoPricingModel, setShowPricingInfoPricingModel] = useState<IAddOnPricingModel | IAllowancePricingModel | undefined>(undefined);

  const showMoreInfoOnClick = (addOn: IAddOn | IAllowance, priceModel: IAddOnPricingModel | IAllowancePricingModel) => {
    setShowPricingInfoAddOn(addOn);
    setShowPricingInfoPricingModel(priceModel);
    setShowPricingInfo(true);
  };

  const getCycleFrequencyText = (frequency: number, frequencyType: FrequencyType) => {
    if (frequency > 1) {
      return `${frequency} ${getFrequencyText(frequency, frequencyType)}`;
    }
    return `${getFrequencyText(frequency, frequencyType)}`;
  }

  if (!priceModel) {
    return null;
  }

  if (addon.pricingModelType === AddOnPricingModelType.AddonFlatFee) {
    const currency = priceModel.currency.symbol.replace("_", "");
    const { isAtBeginning } = extractCurrencyFromPriceFormatted(cycle.pricingModel.priceFormatted);
    const priceFormatted = `${isAtBeginning ? currency : ""}${(((priceModel as IAddOnPricingModel).flatFeePrice as number) / 100).toFixed(2)}${!isAtBeginning ? currency : ""}`;

    return (
      <>
        <Text
          className="select-plan__inline-info"
          content={`${priceFormatted}`}
          weight="bold"
          shouldTranslate={false}
          noMargin
        />
        <Text
          className="select-plan__inline-info"
          content={` ${counterpart("FREQUENCY_EVERY")} `}
          shouldTranslate={false}
          noMargin
        />
        <Text
          className="select-plan__inline-info"
          content={`${getCycleFrequencyText(priceModel.frequency, priceModel.frequencyType)}`}
          shouldTranslate={false}
          weight="bold"
          noMargin
        />
      </>
    );
  }

  if (addon.pricingModelType === AddOnPricingModelType.AddonPerUnit || addon.pricingModelType === AllowancePricingModelType.AllowancePerUnit) {
    const currency = priceModel.currency.symbol.replace("_", "");
    const { isAtBeginning } = extractCurrencyFromPriceFormatted(cycle.pricingModel.priceFormatted);
    const priceFormatted = `${isAtBeginning ? currency : ""}${(((priceModel.perUnitPrice as number) / 100) * (addon.units || 1)).toFixed(2)}${!isAtBeginning ? currency : ""}`;
    const convertedPriceToNumber = convertFormattedPriceToNumber(priceFormatted);
    const unit = addon.singleUnitName || checkoutConfig.terminologyUnitSingular;
    const units = addon.pluralUnitName || checkoutConfig.terminologyUnitPlural;

    if ((priceModel as IAddOnPricingModel).flatFeePrice as number === 0) {
      return <Text content="SELECT_PLAN_TIERED_CYCLE_UNIT_OUT_OF_RANGE" noMargin></Text>
    }

    const getPerUnitLabel = () => {
      if (addon.units) {
        return addon.units === 1 ? `${counterpart("FREQUENCY_PER")} ${unit}` : `${counterpart("FREQUENCY_FOR")} ${addon.units} ${units}`;
      }
      return "";
    }

    // if (addon.units && addon.units > 0 && convertedPriceToNumber === 0) {
    //   return <Text content="SELECT_PLAN_TIERED_CYCLE_OUT_OF_RANGE" translateWith={{ unitLabel: getPerUnitLabel() }}></Text>
    // }

    return (
      <>
        <Text
          className="select-plan__inline-info"
          content={`${addon.units && addon.units > 1 ? addon.pluralUnitName : addon.singleUnitName} `}
          weight="bold"
          shouldTranslate={false}
          noMargin
        />
        <Text
          className="select-plan__inline-info"
          content={` ${counterpart("FREQUENCY_EVERY")} `}
          shouldTranslate={false}
          noMargin
        />
        <Text
          className="select-plan__inline-info"
          content={`${getCycleFrequencyText(priceModel.frequency, priceModel.frequencyType)} `}
          shouldTranslate={false}
          weight="bold"
          noMargin
        />
        <Text
          className="select-plan__inline-info"
          content="FREQUENCY_FOR"
          noMargin
        />
        <Text
          className="select-plan__inline-info"
          content={` ${priceFormatted}`}
          weight="bold"
          shouldTranslate={false}
          noMargin
        />
      </>
    );
  }

  if (addon.pricingModelType === AllowancePricingModelType.AllowanceCapped) {
    return (
      <Text
        className="select-plan__inline-info"
        content={`${getIncludedUnitsText(priceModel as IAllowancePricingModel, addon as IAllowance, checkoutConfig)}`}
        shouldTranslate={false}
        noMargin
      />
    );
  }

  const getPricePreviewComplexPricingModels = () => {
    if (!showPreviewPriceForComplexType) {
      return "";
    }

    const finalPriceFormatted = calculateAddOnPrice(cycle, priceModel, addon.units || 1, priceModel.pricingModelType).priceFormatted || "";
      const price = convertFormattedPriceToNumber(finalPriceFormatted) * 100;

      // if (addon.units && addon.units > 0 && price === 0) {
      //   return counterpart("SELECT_PLAN_TIERED_CYCLE_OUT_OF_RANGE");
      // }

      return showPriceOnly ?
        <Text
          className="select-plan__inline-info"
          content={` ${finalPriceFormatted}  `}
          weight="bold"
          shouldTranslate={false}
          noMargin
        /> : (
          <>
            <Text
              className="select-plan__inline-info"
              content={`${counterpart("FREQUENCY_EVERY")} `}
              shouldTranslate={false}
              noMargin
            />
            <Text
              className="select-plan__inline-info"
              content={`${getCycleFrequencyText(priceModel.frequency, priceModel.frequencyType)} `}
              shouldTranslate={false}
              weight="bold"
              noMargin
            /><Text
              className="select-plan__inline-info"
              content="FREQUENCY_FOR"
              noMargin
            />
            <Text
              className="select-plan__inline-info"
              content={` ${finalPriceFormatted}  `}
              weight="bold"
              shouldTranslate={false}
              noMargin
            />
          </>
        )
  };

  // for complex pricing models
  return (
    <>
      {getPricePreviewComplexPricingModels()}
      <Text
        className="select-plan__more-pricing-info select-plan__inline-info"
        content={viewPriceModalBtnText}
        onClick={(evt: FormEvent) => showMoreInfoOnClick(addon, priceModel)}
        noMargin
      />
      <Modal
        className="cycle-price-info__modal"
        buttonColor={branding.highlightColor}
        isOpen={showPricingInfo}
        title=""
        shouldTranslateTitle={false}
        onRequestClose={() => {
          setShowPricingInfo(false);
          setShowPricingInfoAddOn(undefined);
          setShowPricingInfoPricingModel(undefined);
        }}
        key={"modal"}
      >
        <AddOnPriceInfo
          plan={plan}
          cycle={cycle}
          checkoutConfig={checkoutConfig}
          branding={branding}
          pricingModel={showPricingInfoPricingModel}
          addOn={showPricingInfoAddOn}
        />
      </Modal>
    </>
  );
};

export default AddOnPriceText;
