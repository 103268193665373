import React, { useLayoutEffect, useMemo, useState } from "react";
import "./ChangeAddress.scss";
import PageWrapper from "../page-wrapper/PageWrapper";
import Panel from "../../components/ui/panel/Panel";
import { AppState } from "../..";
import { useDispatch, useSelector } from "react-redux";
import FormLabel from "../../components/ui/form-label/FormLabel";
import Address from "../../components/address/Address";
import { setAddressLine1, setAddressLine2, setState, setZipCode, setCountry, resetCountryState, setCity, fetchCountries, setShippingAsBillingFlag, fetchShippingAddress, updateShippingAddress, validateChangeAddress, resetPersonalDetailsErrors, resetPersonalAddressErrors } from "../../actions/personalDetailsActions";
import FormGroup from "../../components/ui/form-group/FormGroup";
import Checkbox from "../../components/ui/checkbox/Checkbox";
import counterpart from "counterpart";
import { fetchPersonalDetails, changePersonalDetails } from "../../actions/changePersonalDetailsActions";
import { fetchCustomerSubscriptionDetails } from "../../actions/subscriptionsActions";
import { IAddress } from "../../models/Address";
import { setChangeAddressField } from "../../actions/changeAddressActions";
import history from "../../utils/history";
import { ICountry } from "../../models/Country";
import { AccountManagementPermissions } from "../../models/AccountManagement";
import AddressAutocomplete from "../../components/address-autocomplete/AddressAutocomplete";
import SystemInfo from "../../components/ui/system-info/SystemInfo";
import { ISubscriptionDetails } from "../../models/Subscription";
import LoadingAnimation from "../../components/ui/loading-animation/LoadingAnimation";
import { CollectCompanyNameType } from "../../models/BillsbyData";

const ChangeAddress: React.FC = () => {
  const { address, shippingAddress, addressDataErrors, shippingAddressDataErrors, countries, countriesDropdown, shippingAsBilling,
    mainProps: { customerUniqueId, subscriptionUniqueId }, personalDetailsErrors } = useSelector((state: AppState) => state.personalDetailsReducer);
  const { companyDomain, checkoutConfig, accountManagementPermissions } = useSelector((state: AppState) => state.globalReducer);
  const { currentSubscriptionDetails } = useSelector((state: AppState) => state.subscriptionReducer);
  const { firstName, lastName, email, phoneNumberDialCountry, phoneNumberDialCode, phoneNumber, companyName } = useSelector((state: AppState) => state.changePersonalDetailsReducer);
  
  const dispatch = useDispatch<Function>();

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);
  const [isBillingAddressError, setIsBillingAddressError] = useState(false);
  const [isShippingAddressError, setIsShippingAddressError] = useState(false);

  const collectCompanyNameType = checkoutConfig?.collectCompanyNameType as CollectCompanyNameType;
  const isCompanyNameValid = useMemo(() => {
    if(collectCompanyNameType === CollectCompanyNameType.Compulsory && !companyName) {
      return false;
    }
    return true;
  }, [collectCompanyNameType, companyName])

  const shippingAddressCountriesDropdown = useMemo(() => {
    return countriesDropdown.filter(country => country.value.addressPermission === "ShippingAddress" || country.value.addressPermission === "Both");
  }, [countriesDropdown]);

  const shippingAddressCountries = useMemo(() => {
    return countries.filter(country => country.addressPermission === "ShippingAddress" || country.addressPermission === "Both");
  }, [countries]);

  const billingAddressCountriesDropdown = useMemo(() => {
    return countriesDropdown.filter(country => country.value.addressPermission === "BillingAddress" || country.value.addressPermission === "Both");
  }, [countriesDropdown]);

  const billingAddressCountries = useMemo(() => {
    return countries.filter(country => country.addressPermission === "BillingAddress" || country.addressPermission === "Both");
  }, [countries]);

  useLayoutEffect(() => {
    setIsBillingAddressError(false);
    setIsShippingAddressError(false);
  }, [address, shippingAddress])

  useLayoutEffect(() => {
    const loadData = async () => {
      setIsLoadingData(true);
      try {
        const { response } = await dispatch(fetchCustomerSubscriptionDetails(companyDomain, subscriptionUniqueId as string)) as { response: ISubscriptionDetails };;
        await dispatch(fetchCountries(companyDomain, response.productId));
        await dispatch(fetchPersonalDetails(companyDomain, customerUniqueId as string));
        await dispatch(fetchShippingAddress(companyDomain, subscriptionUniqueId as string));
        setIsLoadingData(false);

      }
      catch (err) {
        setIsLoadingData(false);
      }
    }
    loadData();

    return () => {
      dispatch(resetPersonalDetailsErrors());
      dispatch(resetPersonalAddressErrors());
      dispatch(setShippingAsBillingFlag(true));
    }
  }, []);

  useLayoutEffect(() => {
    if (currentSubscriptionDetails && currentSubscriptionDetails.isShippingAddressRequired) {
      if (isLoadingData) {
        return;
      }
      if (address.address1 !== shippingAddress.address1 || address.address2 !== shippingAddress.address2
        || address.city !== shippingAddress.city || address.country !== shippingAddress.country
        || address.state !== shippingAddress.state || address.zipCode !== shippingAddress.zipCode) {
        dispatch(setShippingAsBillingFlag(false));
      } else {
        dispatch(setShippingAsBillingFlag(true));
      }
    }

  }, [currentSubscriptionDetails, isLoadingData])

  if (!currentSubscriptionDetails || !checkoutConfig || !countries.length) {
    return null;
  }

  const { isBillingAddressValidationRequired, isShippingAddressValidationRequired, isShippingAddressRequired } = currentSubscriptionDetails;
  const formatAddress = (address: { address1: string, address2: string, city: string, country: { label: string, value: ICountry } | null, zipCode: string, state: string }): IAddress => {
    return {
      addressLine1: address.address1,
      addressLine2: address.address2,
      city: address.city,
      country: address.country ? address.country.value.iso3Code : "",
      postCode: address.zipCode,
      state: address.state
    }
  }

  const onSubmit = async () => {
    const newBillingAddress: IAddress = formatAddress(address);
    const newPersonalDetails = {
      firstName, lastName, companyName, email, phoneNumberDialCountry, phoneNumberDialCode, phoneNumber, billingAddress: newBillingAddress
    }
    const newShippingAddress: IAddress = shippingAsBilling ? formatAddress(address) : formatAddress(shippingAddress);
    setIsUpdatingAddress(true);
    try {
      const { response: hasUpdatedBilling } = await dispatch(changePersonalDetails(companyDomain, customerUniqueId as string, newPersonalDetails));
      const { response: hasUpdatedShipping } = await dispatch(updateShippingAddress(companyDomain, subscriptionUniqueId as string, newShippingAddress));
      if (hasUpdatedBilling !== undefined && hasUpdatedShipping !== undefined) {
        return dispatch(setChangeAddressField("isChangeAddressSuccess", true));
      }
      throw new Error("");
    }
    catch (err) {
      dispatch(setChangeAddressField("isChangeAddressFailure", true));
    }
    finally {
      setIsUpdatingAddress(false);
      history.push({ pathname: "/management", search: history.location.search });
    }
  }

  const goBackCallback = () => {
    if (accountManagementPermissions === AccountManagementPermissions.FULL && history.location.pathname !== "/management") {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }

  return (
    <PageWrapper
      className="change-address"
      btnDisabled={isUpdatingAddress || isLoadingData || !isCompanyNameValid}
      isLoading={isUpdatingAddress}
      btnCallback={() => dispatch(validateChangeAddress(onSubmit))}
      btnText="ACCOUNT_MANAGEMENT_CHANGE_ADDRESS_SAVE"
      btnBackCallback={goBackCallback}
      btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION}
      title="UPDATE_ADDRESS_TITLE"
      titlePosition="left-aligned"
      subTitle="UPDATE_ADDRESS_SUB_TITLE"
      subTitleTranslateWith={{ subscriptionLabel: checkoutConfig.terminologySubscriptionPlural }}
    >
      <SystemInfo
        className="change-address__invalid-company-name"
        title="PERSONAL_DETAILS_WARNING_INVALID_COMPANY_NAME_TITLE"
        text={"PERSONAL_DETAILS_WARNING_INVALID_COMPANY_NAME_SUBTITLE"}
        isShowIcon={false}
        type="warning"
        isVisible={!isCompanyNameValid && !isLoadingData}
      />
      {isLoadingData
        ? <LoadingAnimation />
        : <>
          {personalDetailsErrors.length > 0 &&
            <SystemInfo
              title={counterpart("PAYMENT_COMPULSORY_FIELDS")}
              text={personalDetailsErrors.map(err => counterpart(err)).join(", ")}
              isShowIcon={false}
              shouldTranslateTitle={false}
              shouldTranslateText={false}
              type="warning"
            />
          }
          {(isShippingAddressError || isBillingAddressError) &&
            <SystemInfo
              title="SELECTED_ADDRESS_ERROR"
              isShowIcon={false}
              type="warning"
            />}
          <Panel id="personal-details__address">
            <FormGroup>
              <FormLabel className="personal-details__field-title" content="PERSONAL_DETAILS_ADDRESS_TITLE" shouldTranslate />
              <AddressAutocomplete
                countries={billingAddressCountriesDropdown}
                hasError={isBillingAddressError}
                id="billing-address"
                restrictedCountries={billingAddressCountries}
                onSelectedAddress={(selectedAddress, isValid) => {
                  if (!selectedAddress || !isValid) {
                    setIsBillingAddressError(true);
                    return;
                  }
                  setIsBillingAddressError(false)
                  dispatch(setAddressLine1(true, selectedAddress.line1));
                  dispatch(setAddressLine2(true, selectedAddress.line2));
                  dispatch(setCity(true, selectedAddress.city));
                  dispatch(setState(true, selectedAddress.state));
                  dispatch(setZipCode(true, selectedAddress.zipCode));
                  dispatch(setCountry(true, { label: selectedAddress.countryName, value: { name: selectedAddress.countryName, iso3Code: selectedAddress.countryIso3, iso2Code: selectedAddress.countryIso2 } }));
                }} />
            </FormGroup>
            <Address
              address1={address.address1}
              address2={address.address2}
              city={address.city}
              state={address.stateObject}
              zipCode={address.zipCode}
              country={address.country}
              addressErrors={addressDataErrors}
              address1OnChange={evt => dispatch(setAddressLine1(true, evt.target.value))}
              address2OnChange={evt => dispatch(setAddressLine2(true, evt.target.value))}
              cityOnChange={e => dispatch(setCity(true, e.target.value))}
              stateOnChange={(selectedItem: any) => dispatch(setState(true, selectedItem))}
              zipCodeOnChange={e => dispatch(setZipCode(true, e.target.value))}
              countryOnChange={(selectedItem: any) => {
                dispatch(setCountry(true, selectedItem));
                dispatch(resetCountryState(true));
              }}
              states={address.statesDropdown}
              countries={billingAddressCountriesDropdown}
            />

            {
              isShippingAddressRequired
                ? (
                  <div>
                    <FormGroup>
                      <Checkbox
                        id="2"
                        onClick={() => dispatch(setShippingAsBillingFlag(!shippingAsBilling))}
                        content={counterpart("PERSONAL_DETAILS_USE_MY_BILLING_ADDRESS")}
                        checked={shippingAsBilling}
                        value=""
                      />
                    </FormGroup>

                    {
                      shippingAsBilling
                        ? null
                        : (
                          <>
                            <FormGroup>
                              <FormLabel className="personal-details__field-title" content="PERSONAL_DETAILS_SHIPPING_ADDRESS_TITLE" shouldTranslate />
                              <AddressAutocomplete
                                countries={shippingAddressCountriesDropdown}
                                hasError={isShippingAddressError}
                                id="shipping-address"
                                restrictedCountries={shippingAddressCountries}
                                onSelectedAddress={(selectedAddress, isValid) => {
                                  if (!selectedAddress || !isValid) {
                                    setIsShippingAddressError(true);
                                    return;
                                  }
                                  setIsShippingAddressError(false);
                                  dispatch(setAddressLine1(false, selectedAddress.line1));
                                  dispatch(setAddressLine2(false, selectedAddress.line2));
                                  dispatch(setCity(false, selectedAddress.city));
                                  dispatch(setState(false, selectedAddress.state));
                                  dispatch(setZipCode(false, selectedAddress.zipCode));
                                  dispatch(setCountry(false, { label: selectedAddress.countryName, value: { name: selectedAddress.countryName, iso3Code: selectedAddress.countryIso3, iso2Code: selectedAddress.countryIso2 } }));
                                }} />
                            </FormGroup>
                            <Address
                              address1={shippingAddress.address1}
                              address2={shippingAddress.address2}
                              city={shippingAddress.city}
                              state={shippingAddress.stateObject}
                              zipCode={shippingAddress.zipCode}
                              country={shippingAddress.country}
                              addressErrors={shippingAddressDataErrors}
                              address1OnChange={evt => dispatch(setAddressLine1(false, evt.target.value))}
                              address2OnChange={evt => dispatch(setAddressLine2(false, evt.target.value))}
                              cityOnChange={e => dispatch(setCity(false, e.target.value))}
                              stateOnChange={(selectedItem: any) => dispatch(setState(false, selectedItem))}
                              zipCodeOnChange={e => dispatch(setZipCode(false, e.target.value))}
                              countryOnChange={(selectedItem: any) => {
                                dispatch(setCountry(false, selectedItem));
                                dispatch(resetCountryState(false));
                              }}
                              states={shippingAddress.statesDropdown}
                              countries={shippingAddressCountriesDropdown}
                            />
                          </>
                        )

                    }

                  </div>
                )
                : null
            }
          </Panel>
        </>}
    </PageWrapper>
  )
}

export default ChangeAddress;