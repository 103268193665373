import React, { useMemo } from "react"
import { IAddOnReducer } from "../../../../reducers/addOnReducer";
import { AppState } from "../../../..";
import "./SubscriptionAddOnsAllowances.scss";
import SubscriptionAddOnItem from "./SubscriptionAddOnItem";
import { IGlobalReducer } from "../../../../reducers/globalReducer";
import { ISelectPlanReducer } from "../../../../reducers/selectPlanReducer";
import { ICycle } from "../../../../models/Product";
import { IAddOnPricingModel, IAllowancePricingModel } from "../../../../models/AddOnAllowance";
import { setAddOnsUnits, setAllowanceUnits } from "../../../../actions/addOnActions";
import SubscriptionAllowanceItem from "./SubscriptionAllowanceItem";
import { useDispatch, useSelector } from "react-redux";


export const SubscriptionAddOnsAllowances: React.FC = () => {
  const { addOns, allowances, preloadedAddOns, preloadedAllowances } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const { plan, cycle } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { branding, checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const dispatch = useDispatch<Function>()

  const filteredAllowances = useMemo(() => allowances.filter(i => !!i.units && i.units > 0 || i.isForced), [allowances, cycle])
  const filteredAddOns = useMemo(() => addOns.filter(i => (!!i.units && i.units > 0 || i.isForced)), [addOns, cycle])

  const updateAddOns = (addOnId: number, pricingModels: IAddOnPricingModel[], units?: number) => {
    dispatch(setAddOnsUnits({ id: addOnId, units, pricingModels }))
  };

  const updateAllowance = (allowanceId: number, pricingModels: IAllowancePricingModel[], units?: number) => {
    dispatch(setAllowanceUnits({ id: allowanceId, units, pricingModels }))
  };

  
  if(!plan) return null;

  if(!cycle) return null;

  if(!checkoutConfig) return null;
  
  return (
    <div className="subscription-addons-container">
    {
      filteredAddOns.map((addOn) => (
        <SubscriptionAddOnItem
          key={addOn.id}
          addOn={addOn}
          plan={plan.value}
          image={addOn.imageUrl}
          cycle={cycle as ICycle}
          branding={branding}
          checkoutConfig={checkoutConfig}
          updateAddons={(addOnId: number, pricingModels: IAddOnPricingModel[], units?: number) => updateAddOns(addOnId, pricingModels, units)}
          isLocked={preloadedAddOns.some(p => p[0] === addOn.id && p[1])}
        />
      ))
    }  
    {
      filteredAllowances.map(allowance => (
        <SubscriptionAllowanceItem
          key={allowance.id}
          allowance={allowance}
          plan={plan.value}
          image={allowance.imageUrl}
          cycle={cycle}
          checkoutConfig={checkoutConfig}
          branding={branding}
          updateAllowance={updateAllowance}
          isLocked={!!preloadedAllowances.length}
        />
      ))
    }  
    </div>
  )
}

export default SubscriptionAddOnsAllowances