import React, { useState, useMemo, useLayoutEffect } from "react";
import { ISelectPlanReducer } from "../../../reducers/selectPlanReducer";
import { IPersonalDetailsReducerState } from "../../../reducers/personalDetailsReducer";
import PaymentSummary from "../../ui/payment-summary/PaymentSummary";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import { IGlobalReducer } from "../../../reducers/globalReducer";
import { IAddOnReducer } from "../../../reducers/addOnReducer";
import { IDiscountCouponReducer } from "../../../reducers/discountCouponReducer";
import { setAppliedCoupons } from "../../../actions/discountCouponActions";
import { IAppliedCoupon } from "../../../models/DiscountCoupon";
import { fetchSubscriptionAllowances } from "../../../actions/addOnActions";
import { getIncludedAddOnsAllowancesTypes } from "../../../utils/planUtils";
import LoadingAnimation from "../../ui/loading-animation/LoadingAnimation";
import { ISubscriptionReducer } from "../../../reducers/subscriptionReducer";

interface IPlanSummaryDetails {
  onDeleteSubscriptionCoupon?: (coupon: IAppliedCoupon) => void;
  includeAllowanceOverages?: boolean,
  hasTitle?: boolean
}

const PlanSummaryDetails: React.FC<IPlanSummaryDetails> = ({ onDeleteSubscriptionCoupon, includeAllowanceOverages = false, hasTitle = false }) => {
  const [isOpenIncludedAddonsAllowancesModal, setIsOpenIncludedAddonsAllowancesModal] = useState(false);
  const { product, plan, cycle } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { checkoutConfig, companyDomain } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { allowances, addOns } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const { appliedCoupons } = useSelector<AppState, IDiscountCouponReducer>(state => state.discountCouponReducer);
  const { mainProps: { subscriptionUniqueId } } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { currentSubscription } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer)
  const dispatch = useDispatch<Function>()

  const [isLoadingData, setIsLoadingData] = useState(false);

  const addOnsAllowances = useMemo(() => {
    const combinedAddOnAllowances = [...addOns, ...allowances];
    return combinedAddOnAllowances.filter(i => !!i.units && i.units > 0);
  }, [allowances, addOns]);

  const fetchAllowances = async () => {
    setIsLoadingData(true);
    try {
      await dispatch(fetchSubscriptionAllowances(companyDomain, subscriptionUniqueId as string));
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log(err)
    }
  }

  useLayoutEffect(() => {
    if (includeAllowanceOverages) {
      fetchAllowances()
    }
  }, [includeAllowanceOverages]);


  const includedAddonsAllowancesTypes = useMemo(() => {
    return getIncludedAddOnsAllowancesTypes(addOnsAllowances);
  }, [addOnsAllowances]);

  if(isLoadingData) {
    return <LoadingAnimation/>
  }

  if (!plan || !cycle || !checkoutConfig || isLoadingData) {
    return null;
  }

  const onDeleteCoupon = (coupon: IAppliedCoupon) => {
    if (onDeleteSubscriptionCoupon) {
      return onDeleteSubscriptionCoupon(coupon);
    }
    dispatch(setAppliedCoupons(appliedCoupons.filter((ac) => ac.coupon.getCouponId() !== coupon.coupon.getCouponId())));
  };

  return (
    <PaymentSummary
      pricingModel={cycle.pricingModel}
      productName={product && product.value ? product.value.displayName : currentSubscription ? currentSubscription.planName : ""}
      planName={plan.value.displayName}
      hasTitle={hasTitle}
      currencyCode={product && product.value ? product.value.billingCurrency : currentSubscription ? currentSubscription.currency : ""}
      appliedCoupons={appliedCoupons}
      onDeleteCoupon={onDeleteCoupon}
    />
  );
};

export default PlanSummaryDetails
