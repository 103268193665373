
/*import { IToken, IParsedToken } from '../models/Token';
import { initAuthData, initReadmeioLinks } from '../actions/authActions';
import { injectTrackingScripts } from '../utils/injectTrackingScripts';
import jwt from 'jsonwebtoken';*/



export function login(username: string, password: string, redirectAfterLogin: boolean = true) {
  /*return new Promise(function (fulfill: Function, reject: Function) {
    username = username.split('+').join('%2B');
    password = password.split('+').join('%2B');

    const data = JSON.stringify({ "grant_type": "password", username, password });
    let url = `${ConfigConstants.baseUrl}token`;
    const headers = new Headers({ 'Content-Type': 'application/json', ClientId: ConfigConstants.clientId });

    return fetchPost(url, headers, data)
      .then((response: any) => {
        response = <IToken>response;

        if (!reduxStore.getState().login.rememberUser) {
          // if the user doesn't check the remember user checkbox we save the token in the session storage
          setStoreType(SESSION_STORAGE);
        }

        // Store session data in browser local storage
        storeSet(BILLSBY_AUTH_DATA_CONTROL, response);
        reduxStore.dispatch(initAuthData());
        reduxStore.dispatch(initReadmeioLinks());

        // If only one company redirect to company subdomain
        if((reduxStore.getState().auth.parsedToken as IParsedToken).role.length === 1) {
          const company = (reduxStore.getState().auth.parsedToken as IParsedToken).role[0];
          
          storeSet(BILLSBY_SELECTED_COMPANY, company.CompanyId);
          if(redirectAfterLogin) {
            window.location.replace(`//${company.CompanyDomain}${ConfigConstants.billsbyDomain}${!IS_PRODUCTION ? `:${process.env.REACT_APP_PORT}` : '' }`);
          }
          return;
        }

        if(redirectAfterLogin) {
          history.push("/");
        }

        fulfill(response);

      }).catch((error) => {
        reject(error);
      });
  });*/
}


export function logout() {
  /*storeRemove(BILLSBY_SELECTED_COMPANY);
  storeRemove(BILLSBY_AUTH_DATA_CONTROL);
  reduxStore.dispatch(initAuthData());

  if(!IS_PRODUCTION) {
    window.location.replace(<string>process.env.REACT_APP_BROWSER);
    return;
  } 
  
  // redirect to environment specific url -> app.dev.billsby.com | app.test.billsby.com
  window.location.replace(`//app${ConfigConstants.billsbyDomain}`);*/
}

export function refreshToken() {
  return new Promise(function (fulfill: Function, reject: Function) {

    /*let storeData = storeGet(BILLSBY_AUTH_DATA_CONTROL);
    const data = JSON.stringify({ "grant_type": "refresh_token", "refresh_token": storeData.refresh_token });
    let url = `${ConfigConstants.baseUrl}token`;
    const headers = new Headers({ 'Content-Type': 'application/json', ClientId: ConfigConstants.clientId });

    return fetchPost(url, headers, data)
      .then((response: any) => {

        response = <IToken>response;
        // Store session data in browser local storage
        storeSet(BILLSBY_AUTH_DATA_CONTROL, response);

        fulfill(response);

      }).catch((error) => {
        reject(error);
      });*/
  });
}