import React, { useEffect, useMemo } from "react"
import { IAddOn, IAddOnPricingModel, AddOnPricingModelType } from "../../../../models/AddOnAllowance";
import { IPlanWithCycles, ICycle } from "../../../../models/Product";
import { IBranding } from "../../../../models/Branding";
import { ICheckoutConfig } from "../../../../models/BillsbyData";
import { calculateAddOnPrice } from "../../../../utils/planUtils";
import Panel from "../../panel/Panel";
import NumberPicker from "../../number-picker/NumberPicker";
import AddOnPriceText from "../../../add-on/addon-price-text/AddonPriceText";
import "./SubscriptionAddOnsAllowances.scss";
import FormLabel from "../../form-label/FormLabel";

interface ISubscriptionAddOnItem {
  addOn: IAddOn;
  plan: IPlanWithCycles;
  cycle: ICycle;
  branding: IBranding;
  checkoutConfig?: ICheckoutConfig;
  updateAddons: (addOnId: number, pricingModels: IAddOnPricingModel[], units?: number) => void;
  isLocked?: boolean;
  image?: string
}

const SubscriptionAddOnItem: React.FC<ISubscriptionAddOnItem> = ({
  addOn,
  plan,
  cycle,
  branding,
  checkoutConfig,
  updateAddons,
  image,
  isLocked = false }) => {

  useEffect(() => {
    if (Number(addOn.units)) {
      // in case of editing an addon we calculate the price immediately
      onUnitsChange(addOn, Number(addOn.units));
    } else {
      const initialUnit = addOn.isForced ? 1 : 0;
      onUnitsChange(addOn, initialUnit);
    }
  }, []);

  const getMax = useMemo(() => {
    if (addOn.pricingModelType !== AddOnPricingModelType.AddonFlatFee && addOn.pricingModelType !== AddOnPricingModelType.AddonPerUnit) {
      const filteredPriceModel = addOn.pricingModels.filter(pm =>
        pm.currency.isoCode === plan.currency &&
        pm.frequency === cycle.pricingModel.frequency &&
        pm.frequencyType === cycle.pricingModel.frequencyType
      )

      return Math.max(...filteredPriceModel[0].tiers.map(t => t.finish))
    }
    return undefined;
  }, [addOn]);

  const onUnitsChange = (addOn: IAddOn, units: number) => {
    if (addOn.isForced) {
      units = units < 1 ? 1 : units;
    } else {
      units = units < 0 ? 0 : units;
    }

    if (!!getMax) {
      units = units > getMax ? getMax : units;
    }

    const pricingModels: Array<IAddOnPricingModel> = addOn.pricingModels.map(pricingModel => {
      if (pricingModel.frequency === cycle.pricingModel.frequency && pricingModel.frequencyType === cycle.pricingModel.frequencyType) {
        return calculateAddOnPrice(cycle, pricingModel, units, addOn.pricingModelType) as IAddOnPricingModel;
      }
      return pricingModel;
    });

    updateAddons(addOn.id, pricingModels, units);
  };

  if (!plan || !cycle || !checkoutConfig) {
    return null;
  }


  return (
    <Panel id="subscription-addon-item__container" image={image} imageLayout="box" className="subscription-addon-item__container" key={`add-on-${addOn.id}`}>
      {!addOn.isForced && !isLocked && <i className="far fa-times subscription-addon-item__remove"
        onClick={() => onUnitsChange(addOn, 0)}></i>}
      <div className="payment-summary__name__container">
        <FormLabel content={addOn.displayName || addOn.name} shouldTranslate={false} weight="bold" noMargin />
      </div>
      <div>
      <div className="payment-summary__details">
        <div className="subscription-addon-item">
          {addOn.pricingModelType !== AddOnPricingModelType.AddonFlatFee
            &&
            <div className="subscription-addon-item__number-picker-container">
              <NumberPicker
                className="subscription-addon-item__number-picker"
                value={addOn.units}
                min={addOn.isForced ? 1 : 0}
                max={getMax}
                minLock={(addOn.isForced ? addOn.units === 1 : addOn.units === 0) || isLocked}
                maxLock={isLocked}
                isInputDisabled={isLocked}
                onChange={(value: number) => onUnitsChange(addOn, value)}
                onMinus={() => !!addOn.units ? onUnitsChange(addOn, +addOn.units - 1) : {}}
                onPlus={() => addOn.units === undefined || addOn.units === null ? {} : onUnitsChange(addOn, +addOn.units + 1)} />
            </div>
          }
          <div className={`subscription-addon-item__price-text ${addOn.pricingModelType !== AddOnPricingModelType.AddonFlatFee ? "price-complex" : ""}`}>
            <AddOnPriceText
              addon={addOn}
              plan={plan}
              cycle={cycle}
              checkoutConfig={checkoutConfig}
              branding={branding}
              showPreviewPriceForComplexType={true}
              viewPriceModalBtnText="ADD_ON_INPUT_PRICE_WHY"
            />
          </div>
        </div>
      </div>
      </div>
    </Panel>
  )
}

export default SubscriptionAddOnItem;