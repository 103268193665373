import React, { useEffect } from "react";
import "./SystemInfo.scss";
import Text from "../text/Text";
import Panel from "../panel/Panel";

interface ISystemInfo {
  /**
    * Determines the colour and icon
    */
  type?: "success" | "warning" | "info" | "support" | "custom",
  /**
  * The text to be displayed in the alert
  */
  title: string | JSX.Element,
  /**
  * The text to be displayed in the alert
  */
  text?: string | JSX.Element,
  /**
   * if the text is a dom element
   */
  textIsHtml?: boolean,
  /**
  * Uses the Translate "with" prop for dynamic translation of the text
  */
  translateWith?: any,
  /**
  * If the alert is a custom one, you can also use a different icon
  */
  customIcon?: string,
  /**
   * Whether to translate or not the title
   */
  shouldTranslateTitle?: boolean,
  /**
   * Whether to translate or not the text
   */
  shouldTranslateText?: boolean,
  /*
  Show or not icon
  */
  isShowIcon?: boolean,
  /**
   * additional classes
   */
  className?: string,
  /**
   * is visible or not
   */
  isVisible?: boolean,
  noMargin?: boolean
}

const SystemInfo: React.FC<ISystemInfo> = React.memo(({ type = "success", title, text, translateWith = {}, shouldTranslateTitle = true, shouldTranslateText = true,
  customIcon = "check-circle", textIsHtml = false, isShowIcon = false, className = "", isVisible = true, noMargin = false }) => {

  let alertType;
  let icon;

  useEffect(() => {
    if(isVisible) {
      const pageWrapperScrollable = document.querySelector(".page-wrapper__body-content");
      pageWrapperScrollable && pageWrapperScrollable.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [isVisible]);

  switch (type) {
    case "warning":
      alertType = "system-info-container-warning";
      icon = "fas fa-exclamation-circle";
      break;
    case "support":
      alertType = "system-info-container-support";
      icon = "fas fa-comments";
      break;
    case "custom":
      alertType = "system-info-container-custom";
      icon = `fas fa-${customIcon}`;
      break;
    default:
      alertType = "system-info-container-success";
      icon = "fas fa-check-circle";
  }

  if (!isVisible) {
    return null;
  }

  return (
    <Panel id="system-info-container" className={`system-info-container ${alertType} ${className}`} noMargin={noMargin}>
      <span className={isShowIcon ? `system-info-container__icon ${icon}` : ""} aria-hidden="true" />
      <div className="system-info-container__title-container__title">
        {
          textIsHtml
            ? (
              <div className="system-info-container__title-container__title">
                {title}
              </div>

            )
            : (
              <Text
                content={title}
                component="span"
                className={"system-info-container__title-container__title"}
                translateWith={translateWith}
                noMargin
                shouldTranslate={shouldTranslateTitle}
              />
            )
        }
        {text ?
          <Text
            content={text}
            className={"system-info-container__title-container__text"}
            translateWith={translateWith}
            noMargin
            shouldTranslate={shouldTranslateText}
          />
          : null

        }
      </div>
      <div className="system-info-container__bg-color"></div>
    </Panel>
  );
})


export default SystemInfo;
