import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import "./Tooltip.scss";

interface ITooltip {
  onClose?: Function,
  isOpen: boolean,
  className?: string,
  resetBaseLayout?: boolean,
  arrowClassName?: string,
  children: Array<ReactElement> | ReactElement,
  type?: "default" | "blue"
}

class Tooltip extends React.PureComponent<ITooltip, { isOpen: boolean }> {

  BASE_CONTAINER_CLASS = "tooltip";
  hasListener: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: props.isOpen
    }
  }

  
  handleClickOutside = (evt: any) => {
    const domNode = ReactDOM.findDOMNode(this);
    const bodyHasModalOpened = document.querySelector(".ReactModal__Overlay--after-open");
    if (!domNode || !domNode.contains(evt.target) && !bodyHasModalOpened) {
      this.props.onClose && this.props.onClose(false);
    }
  }


  componentWillUnmount() {
    if (typeof this.props.onClose === "function") {
      document.removeEventListener("click", this.handleClickOutside, true);
      this.props.onClose(false);
    }
  }


  componentWillReceiveProps(props: ITooltip) {
    if (props.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: props.isOpen });
    }
    if (props.isOpen !== this.props.isOpen && props.isOpen && typeof this.props.onClose === "function" && !this.hasListener) {
      document.addEventListener("click", this.handleClickOutside, true);
      this.hasListener = true;
    }
    if (props.isOpen !== this.props.isOpen && !props.isOpen && typeof this.props.onClose === "function") {
      document.removeEventListener("click", this.handleClickOutside, true);
      this.hasListener = false;
    }
  }

  render() {
    const { isOpen } = this.state;
    const { children, className = "", resetBaseLayout = false, arrowClassName = "", type = "default" } = this.props;


    //build the class for the container
    const tooltipClassName = `
      ${resetBaseLayout ? "" : this.BASE_CONTAINER_CLASS} 
      tooltip--${type} ${className}
    `;

    if (!isOpen) {
      return null;
    }

    return (
      <div className="tooltip-container">
        <div className={arrowClassName} />
        <div className={tooltipClassName} onClick={(evt: any) => evt.stopPropagation()}>
          {children}
        </div>
      </div>
    )
  }
}

export default Tooltip;