import React, { useState, useEffect, useRef } from "react";
import { AppState } from "../../..";
import { ISelectPlanReducer } from "../../../reducers/selectPlanReducer";
import Text from "../text/Text";
import { IPaymentReducer } from "../../../reducers/paymentReducer";
import { PaymentScreen } from "../../../models/Payment";
import { IGlobalReducer } from "../../../reducers/globalReducer";
import { IAddOnReducer } from "../../../reducers/addOnReducer";
import "./Cart.scss";
import PlanSummaryDetails from "../../select-plan/plan-summary-details/PlanSummaryDetails";
import { fetchCouponDetails, setAppliedCoupons, setCouponCode } from "../../../actions/discountCouponActions";
import { IDiscountCouponReducer } from "../../../reducers/discountCouponReducer";
import { CouponError, IAppliedCoupon } from "../../../models/DiscountCoupon";
import { NavigationBarSteps } from "../../../models/NavigationBar";
import TaxLabel from "../../tax-label/TaxLabel";
import FormGroup from "../form-group/FormGroup";
import Input from "../input/Input";
import Modal from "../modal/Modal";
import Button from "../button/Button";
import counterpart from "counterpart";
import PaymentSummaryCoupons from "../payment-summary/PaymentSummaryCoupons";
import SystemInfo from "../system-info/SystemInfo";
import { getCouponError } from "../../../utils/planUtils";
import { isMobileOnly } from "react-device-detect";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useCheckOverflow } from "../../../utils/custom-hooks";
import { useDispatch, useSelector } from "react-redux";

interface ICart {
  className?: string,
}


const Cart: React.FC<ICart> = React.memo(({ className }) => {
  const { product, plan, cycle } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { currentScreen: currentPaymentScreen, paymentDescription } = useSelector<AppState, IPaymentReducer>(state => state.paymentReducer);
  const { appliedCoupons, couponCode, couponName } = useSelector<AppState, IDiscountCouponReducer>(state => state.discountCouponReducer);
  const { checkoutConfig, navigationStep, companyDomain } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { preloadedAddOns, preloadedAllowances } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const dispatch = useDispatch<Function>()

  const cartBodyRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);
  const [isApplyingCouponCode, setIsApplyingCouponCode] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState<CouponError | null>(null);
  const isOverflown = useCheckOverflow(cartBodyRef, [isOpen, paymentDescription])
  const [removedCoupons, setRemovedCoupons] = useState<Array<{ name: string; id: number }>>([]);
  const plusTaxesLabel = paymentDescription && !paymentDescription.getTaxesIncluded() ? ` ${counterpart("PRICE_SUMMARY_PLUS_TAX")}` : "";

  const noticeCouponName = removedCoupons
    ? removedCoupons.map((rc) => rc.name).join(", ")
    : error === CouponError.NOT_COMPATIBLE
      ? appliedCoupons.map((d) => d.coupon.getName()).join(", ")
      : couponName;
  const hasDiscounts = appliedCoupons && appliedCoupons.length > 0;

  useEffect(() => {
    if (!product || !plan || !cycle) {
      setIsOpen(false);
    }

    setIsSuccessful(false)

  }, [product, plan, cycle]);

  useEffect(() => {
    if (!isOpen) {
      setError(null);
    }
  }, [isOpen]);

  const onDeleteCoupon = (coupon: IAppliedCoupon) => {
    dispatch(setAppliedCoupons(appliedCoupons.filter((ac) => ac.coupon.getCouponId() !== coupon.coupon.getCouponId())));
    setIsSuccessful(false);
    setError(null);
  }

  const renderAddCouponModal = () => {
    const onError = (err: CouponError) => {
      setIsApplyingCouponCode(false);
      setIsSuccessful(false);
      setError(err);
      onCloseCouponModal();
    };

    const onAddCoupon = async () => {
      if (!couponCode) {
        return;
      }

      setIsApplyingCouponCode(true);
      setIsSuccessful(false);
      setError(null);

      try {
        await dispatch(fetchCouponDetails(companyDomain, couponCode));

        setIsSuccessful(true);
        setIsApplyingCouponCode(false);
        setError(null);
        onCloseCouponModal();
      } catch (err) {
        onError(err);
      }
    };

    const onCloseCouponModal = () => {
      setIsOpenCouponModal(false);
      dispatch(setCouponCode(""));
    }

    return (
      <Modal hasOverlay shouldTranslateTitle={false} width="340px" height="172px" isOpen={isOpenCouponModal}  title={counterpart("APPLY_COUPON_LABEL", { couponLabel: checkoutConfig?.terminologyCouponSingular })} onRequestClose={() => setIsOpenCouponModal(false)}>
        <>
          <FormGroup>
            <Input
              id="coupon-code"
              value={couponCode}
              placeholder={counterpart("APPLY_COUPON_PLACEHOLDER", { couponLabel: checkoutConfig?.terminologyCouponSingular })}
              onChange={(evt: any) => {
                dispatch(setCouponCode(evt.target.value.replace(/\s/g, "")));
              }}
            />
          </FormGroup>

          <div className="select-plan__coupon-modal__footer">
            <Button
              disabled={isApplyingCouponCode || !couponCode}
              isLoading={isApplyingCouponCode}
              className="modal__body__closebtn"
              title="APPLY_COUPON_BTN"
              translateWith={{ couponLabel: checkoutConfig?.terminologyCouponSingular }}
              id="apply-coupon"
              onClick={onAddCoupon}
            />
          </div>
        </>
      </Modal>
    )
  }

  const renderCartFooter = () => {
    return !!product && (
      <div className="cart__footer">
        {/* TODO: Add endpoint to fetch tax details */}
        <TaxLabel companyDomain="sample" countryIso2Code="sample"></TaxLabel>
        <div className="cart__footer__price-summary">
          <Text component="span" className={`cart__footer__total-price${hasDiscounts ? " cart__footer__total-price--discounted" : ""}`} weight="bold" size="xlarge" shouldTranslate={false} content={paymentDescription ? `${paymentDescription.getFirstInvoiceTotalWithoutDiscountsPrice()}${plusTaxesLabel}` : ""}></Text>
          {hasDiscounts &&
            <Text
              component="span"
              className="cart__footer__total-price"
              weight="bold"
              size="xlarge"
              shouldTranslate={false}
              content={paymentDescription ? `${paymentDescription.getFirstInvoiceTotalPrice()}${plusTaxesLabel}` : ""}
            />
          }
          {hasDiscounts && cycle &&
            <PaymentSummaryCoupons
              paymentDescription={paymentDescription}
              appliedCoupons={appliedCoupons}
              onDeleteCoupon={onDeleteCoupon}
              checkoutConfig={checkoutConfig}
              currencyCode={product.value.billingCurrency}
            />
          }
          {paymentDescription && checkoutConfig?.isApplyCouponsVisible  && <Text
            onClick={() => setIsOpenCouponModal(true)}
            className="cart__footer__link"
            content="UPDATE_PAYMENT_DETAILS_APPLY_COUPON"
            translateWith={{ couponLabel: checkoutConfig.terminologyCouponSingular }}
            noMargin
          />}
        </div>
      </div>
    )
  }


  const renderCartContent = () => {
    return (
      <div ref={cartBodyRef} className="cart__body">
        {
          (isSuccessful || error) && (
            <SystemInfo
              title={counterpart(!error ? "APPLY_COUPON_SUCCESS_TITLE1" : getCouponError(error, appliedCoupons, removedCoupons).title, {
                couponName: noticeCouponName,
                couponLabel: checkoutConfig?.terminologyCouponSingular,
                couponsLabel: checkoutConfig?.terminologyCouponPlural
              })}
              text={error ? getCouponError(error, appliedCoupons, removedCoupons).content : ""}
              type={error ? "warning" : "success"}
              className="cart__body__error"
              isShowIcon={false}
              shouldTranslateTitle={false}
            />
          )
        }
        <PlanSummaryDetails
          hasTitle
          onDeleteSubscriptionCoupon={onDeleteCoupon}
        />
        {renderCartFooter()}
        {renderAddCouponModal()}
      </div>

    )
  }

  const renderCartButton = () => {
    return (
      <ButtonBase className={`cart__button ${isOpen ? "cart__button--open" : ""}`} onClick={() => setIsOpen(!isOpen)}>
        {!isMobileOnly && <i className={`far ${isOpen ? "fa-angle-left" : "fa-angle-right"}`} />}
        <i className="fal fa-shopping-cart" />
        {isMobileOnly && <i className={`far ${isOpen ? "fa-angle-down" : "fa-angle-up"}`} />}
      </ButtonBase>
    )
  }

  const isInPaymentStep = navigationStep === NavigationBarSteps.PAYMENT_METHOD && (currentPaymentScreen === PaymentScreen.CREDIT_CARD
    || currentPaymentScreen === PaymentScreen.CONFIRMATION);

  if (!product || !plan || !cycle || isInPaymentStep || checkoutConfig?.isShoppingCartHidden) {
    return null;
  }

  if (isOpen) {
    return (
      <div className="cart__container cart__container--open">
        {isMobileOnly && renderCartButton()}
        <div className={`cart__content${isOverflown ? " cart__content--overflown" : ""}`}>
          {renderCartContent()}
        {!isMobileOnly && renderCartButton()}

        </div>
        <div className="cart__overlay"></div>
      </div>
    )
  }

  return (
    <div className="cart__container cart__container--closed">
      <div className={`cart__content${isOverflown ? " cart__content--overflown" : ""}`}>
        {renderCartButton()}
      </div>
    </div>
  )

})


export default Cart
