import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { SET_UPDATE_PAYMENT_STATE, VALIDATE_UPDATE_PAYMENT_DETAILS, UPDATE_PAYMENT_DETAILS_REQUEST, UPDATE_PAYMENT_DETAILS_SUCCESS, UPDATE_PAYMENT_DETAILS_FAILURE, RESET_UPDATE_PAYMENT_STATE } from "../actions/updatePaymentActions";
import { IPaymentDetailsError } from "../components/payment-details-form/PaymentDetailsForm";
import { CROSS_DOMAIN_EVENT, sendEvent } from "../utils/crossDomainEvents";


export interface IUpdatePaymentReducer {
  cardHolderName: string,
  expDateMM: string,
  expDateYY: string,
  cardType: string,
  lastFourDigit: string,
  token: string,
  errors: { obj: IPaymentDetailsError, arr: Array<string> } | null,
  customSuccessSubtitle: string,
  isUpdatingPaymentDetailsRequest: boolean,
  isUpdatingPaymentDetailsSuccess: boolean,
  isUpdatingPaymentDetailsFailure: boolean
}

export const initialState = {
  cardHolderName: "",
  expDateMM: "",
  expDateYY: "",
  cardType: "",
  lastFourDigit: "",
  token: "",
  errors: null,
  customSuccessSubtitle: "",
  isUpdatingPaymentDetailsRequest: false,
  isUpdatingPaymentDetailsSuccess: false,
  isUpdatingPaymentDetailsFailure: false
}

const validatePaymentFields = (state: IUpdatePaymentReducer, action: CheckoutAction) => {
  const { cardHolderName, expDateMM, expDateYY } = state;
  const errors: Array<string> = [];

  if (!cardHolderName) {
    errors.push("PAYMENT_CARDHOLDER_NAME");
  }
  if (!expDateMM) {
    errors.push("PAYMENT_EXPIRATION_DATE");
  }
  if (!expDateYY) {
    errors.push("PAYMENT_EXPIRATION_DATE");
  }

  return { ...state, errors }
}

export default function updatePaymentReducer(state: IUpdatePaymentReducer = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case RESET_UPDATE_PAYMENT_STATE:
      return { ...initialState }
    case SET_UPDATE_PAYMENT_STATE:
      return { ...state, [action.fieldName]: action.fieldValue }
    case VALIDATE_UPDATE_PAYMENT_DETAILS:
      return validatePaymentFields(state, action);
    case UPDATE_PAYMENT_DETAILS_REQUEST:
      return { ...state, isUpdatingPaymentDetailsRequest: true, isUpdatingPaymentDetailsSuccess: false, isUpdatingPaymentDetailsFailure: false }
    case UPDATE_PAYMENT_DETAILS_SUCCESS:
      sendEvent(CROSS_DOMAIN_EVENT.CHANGE_PAYMENT_DETAILS_SUCCESS)
      return { ...state, isUpdatingPaymentDetailsRequest: false, isUpdatingPaymentDetailsSuccess: true, isUpdatingPaymentDetailsFailure: false }
    case UPDATE_PAYMENT_DETAILS_FAILURE:
      return { ...state, isUpdatingPaymentDetailsRequest: false, isUpdatingPaymentDetailsSuccess: false, isUpdatingPaymentDetailsFailure: true }

    default:
      return state;
  }
}