import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { GetInvoiceResponse, StatusType } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/invoice_pb";
import { FETCH_INVOICES_SUMMARY_SUCCESS, RESET_VIEW_BILLING_HISTORY } from "../actions/viewBillingHistoryActions";
import { BillsbyDataAction } from "../models/BillsbyData";


export interface IViewBillingHistoryReducer {
  invoices: GetInvoiceResponse,
}

export const initialState = {
  invoices: new GetInvoiceResponse(),
}

export default function viewBillingHistoryReducer(state: IViewBillingHistoryReducer = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case RESET_VIEW_BILLING_HISTORY:
      return { ...initialState }
    case FETCH_INVOICES_SUMMARY_SUCCESS:
      let invoiceResponse = action.response as GetInvoiceResponse
      if(store.globalReducer.billsbyAction === BillsbyDataAction.VIEW_BILLING_HISTORY_UNPAID) {
        invoiceResponse.setInvoicesList(invoiceResponse.getInvoicesList().filter(i => i.getStatus() === StatusType.UNPAID))
      }
      return { ...state, invoices: invoiceResponse }

    default:
      return state;
  }
}