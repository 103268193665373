import React, { useLayoutEffect, useMemo, useState } from "react";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import FormGroup from "../ui/form-group/FormGroup";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { validateTaxRegNumber, setTaxRegNumber } from "../../actions/personalDetailsActions";
import { TaxCollection, TaxRegNumberError } from "../../models/Taxes";
import { AppState } from "../..";
import { useDispatch, useSelector } from "react-redux";
import Button from "../ui/button/Button";
import "./TaxInput.scss";
import { getTaxName } from "../../utils/commonUtils";
import counterpart from "counterpart";
import { Col, Row } from "react-grid-system";
import { IGlobalReducer } from "../../reducers/globalReducer";

interface ITaxInput {
  onSubmit?: () => void;
  isInlineDisplay?: boolean,
  margin?: "tiny" | "default" | "none"
}

const TaxInput: React.FC<ITaxInput> = ({ onSubmit, isInlineDisplay = true, margin = "default" }) => {

  const [isValidating, setIsValidating] = useState(false);
  const [isTaxValidated, setIsTaxValidated] = useState(false);
  const [countryIso3Code, setCountryIso3Code] = useState("");
  const [newTax, setNewTax] = useState("");

  const { companyDomain } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const { product } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { address: { country, stateObject }, taxRegNumberRequirementType, taxRegNumber,taxRegError } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const dispatch = useDispatch<Function>()

  useLayoutEffect(() => {
    setNewTax(taxRegNumber);
  }, []);

  useLayoutEffect(() => {
    setNewTax(newTax);
  }, [taxRegNumber]);

  useLayoutEffect(() => {
    const countryIso3Code = country ? country.value.iso3Code : stateObject ? stateObject.value.CountryIso3 : "";
    setCountryIso3Code(countryIso3Code);
  }, [country, stateObject])

  const taxName = useMemo(() => {
    return getTaxName(countryIso3Code)
  }, [countryIso3Code])

  if (!product) {
    return null;
  }

  const validateTax = async (newTax: string) => {
    if (!countryIso3Code) {
      return;
    }

    setIsTaxValidated(false);
    setIsValidating(true);
    try {
      await dispatch(validateTaxRegNumber(companyDomain, countryIso3Code, newTax));
      dispatch(setTaxRegNumber(newTax))
      
      setIsTaxValidated(true);
      if (!!onSubmit) {
        onSubmit();
      }
    } catch (err) {
      console.log(err);
      setIsTaxValidated(false);
    }
    finally {
      setIsValidating(false);
    }
  };

  const submit = () => {
    validateTax(newTax)
  }

  return (
    <>
      {isInlineDisplay ? (
        <FormGroup margin={margin}>
          <FormLabel content={taxName.short} shouldTranslate={false} />
          <Row>
            <Col sm={10}>
              <Input
                required={taxRegNumberRequirementType === TaxCollection.COMPULSORY}
                id="tax-reg-number"
                placeholder={counterpart("TAX_INPUT_PLACEHOLDER", { taxName: taxName.short })}
                isError={taxRegError === TaxRegNumberError.INVALID}
                value={newTax}
                disabled={!!taxRegNumber}
                onChange={(evt: any) => setNewTax(evt.target.value)}
              />
            </Col>
            <Col sm={2}>
              <Button
                isLoading={isValidating}
                disabled={!newTax || !!taxRegNumber || isValidating}
                title="APPLY_TAX"
                id="apply-tax"
                isFullWidth
                className="tax-input__inline-btn"
                onClick={() => submit()} />
            </Col>
          </Row>
        </FormGroup>
      ) : (
          <FormGroup className="tax-input__form-group" margin={margin}>
            <FormGroup>
              <Input
                required={taxRegNumberRequirementType === TaxCollection.COMPULSORY}
                id="tax-reg-number"
                placeholder={counterpart("TAX_INPUT_PLACEHOLDER", { taxName: taxName.short })}
                isError={taxRegError === TaxRegNumberError.INVALID}
                value={newTax}
                onChange={(evt: any) => setNewTax(evt.target.value)}
              />
            </FormGroup>
            <Button
              disabled={isValidating || !newTax}
              isLoading={isValidating}
              title="APPLY_TAX"
              id="apply-tax"
              className="tax-input__btn"
              onClick={() => submit()} />
          </FormGroup>
        )}
    </>
  );
};

export default TaxInput
