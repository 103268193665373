import React, { useState } from "react";
import Text from "../text/Text";
import "./SeeMoreText.scss";
import counterpart from "counterpart";

interface ISeeMoreText {
  maxChar?: number;
  label?: string;
  shouldTranslateLabel?: boolean;
  content: string;
  shouldTranslateContent?: boolean;
  className?: string;
  noMargin?: boolean;
  style?: any;
  toggle?: boolean;
}

const SeeMoreText: React.FC<ISeeMoreText> = React.memo(({ maxChar = 180, toggle = false, label = "SEE_MORE_LABEL", style = {}, shouldTranslateLabel = true,
  content, className, shouldTranslateContent = true, noMargin = false }) => {

  const [isChecked, setIsChecked] = useState(toggle);

  const getSeeMoreDOM = (contentTranslated: string) => {
    if (contentTranslated.length > maxChar && !isChecked) {
      return (
        <Text
          className={`see-more-text ${className}`}
          style={style}
          component="div"
          content={
            <>
              <Text style={style} content={contentTranslated.substring(0, maxChar)} shouldTranslate={false} noMargin />
              <Text style={style} content={label} shouldTranslate={shouldTranslateLabel} noMargin onClick={(evt) =>{ evt.stopPropagation(); setIsChecked(!isChecked) }} />
            </>
          }
          shouldTranslate={false}
          noMargin={noMargin}
        />
      );
    }
    if (isChecked) {
      return (
        <Text
          className={`see-more-text ${className}`}
          style={style}
          component="div"
          content={
            <>
              <Text style={style} content={contentTranslated} shouldTranslate={false} noMargin />
              {isChecked && <Text style={style} content={"SEE_LESS_LABEL"} noMargin onClick={(evt) => { evt.stopPropagation(); setIsChecked(!isChecked)} }/>}
            </>
          }
          shouldTranslate={false}
          noMargin={noMargin}
        />
      );
    }
    return <Text className={`see-more-text ${className}`} content={contentTranslated} shouldTranslate={false} noMargin={noMargin} style={style} />;
  };

  if (content && shouldTranslateContent) {
    const contentTranslated = counterpart(content);
    return getSeeMoreDOM(contentTranslated);
  }

  return getSeeMoreDOM(content);
}
);

export default SeeMoreText;
