import { FormGroup } from "@material-ui/core";
import React, { useMemo } from "react";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { setAchField, startCheckoutAchMicroDeposit, updateAchPayment } from "../../actions/achActions";
import { CREATE_SUBSCRIPTION_FAILURE, CREATE_SUBSCRIPTION_REQUEST, CREATE_SUBSCRIPTION_SUCCESS, setPaymentCurrentScreen } from "../../actions/paymentActions";
import { CustomAnalyticsEvents } from "../../models/GoogleAnalytics";
import { ISubscription, ISubscriptionACH, ISubscriptionResponseSCA, PaymentStatus } from "../../models/Subscription";
import { IACHReducer } from "../../reducers/achReducer";
import { IChangePersonalDetailsReducer } from "../../reducers/changePersonalDetailsReducer";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { IPaymentReducer } from "../../reducers/paymentReducer";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import { reduxStateToQueryString } from "../../utils/embedCodeutils";
import API from "../../utils/API";
import { gaEventTracker, generateRecaptchaActionToken } from "../../utils/commonUtils";
import { getPurchaseDetails } from "../../utils/planUtils";
import Button from "../ui/button/Button";
import Modal from "../ui/modal/Modal";
import Text from "../ui/text/Text";
import "./AchMandateModal.scss";
import { ConfigConstants } from "../../utils/config";
import { PaymentScreen } from "../../models/Payment";
import { IStripeACHSetupIntent } from "../../models/AchData";
import { RecaptchaActions } from "../../utils/constants";

interface IAchMandateModal {
  type: "update-payment" | "create-subscription",
  subscriptionData?: ISubscription
}

const AchMandateModal: React.FC<IAchMandateModal> = ({ type, subscriptionData }) => {

  const { accountHolder, stripeClientSecret, stripeInstance: stripe, stripeCustomerId, isOpenMandateForm, stripeLast4Digits,
    stripeBankName, stripePaymentId } = useSelector<AppState, IACHReducer>(state => state.achReducer);
  const hasPreloadedACHsession = !!stripePaymentId
  const { paymentDescription } = useSelector<AppState, IPaymentReducer>(state => state.paymentReducer);
  const appState = useSelector<AppState, AppState>(state => state)
  const { plan, cycle } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { mainProps: { customerUniqueId } } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { checkoutConfig, companyDomain } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { firstName, lastName, email } = useSelector<AppState, IChangePersonalDetailsReducer>(state => state.changePersonalDetailsReducer);
  const dispatch = useDispatch<Function>();

  const onSubmitAPI = useMemo(() => {
    if (hasPreloadedACHsession) {
      //means the ach session is being preloaded so we return the setup intent passed through the embed code
      return () => Promise.resolve({
        setupIntent: {
          status: "succeeded",
          payment_method: stripePaymentId
        } as IStripeACHSetupIntent
      })
    }
    return () => stripe.confirmUsBankAccountSetup(stripeClientSecret)
  }, [stripeClientSecret])


  const onAccept = async () => {

    const { setupIntent, error }: { setupIntent: IStripeACHSetupIntent, error: any } = await onSubmitAPI()
    dispatch(setAchField("isOpenMandateForm", false))
    if (error) {
      console.error(error.message);
      dispatch(setAchField("isUpdateAchFailed", true));
      return;
    }

    dispatch(setAchField("stripeSetupIntent", setupIntent))

    if (setupIntent.status === "requires_payment_method") {
      // Confirmation failed. Attempt again with a different payment method.
      dispatch(setAchField("isUpdateAchFailed", true));
    }
    else if (setupIntent.status === "succeeded" && type === "create-subscription") {
      // Confirmation succeeded! The account is now saved.
      await generateRecaptchaActionToken(RecaptchaActions.CHECKOUT);
      
        let response = {} as ISubscriptionResponseSCA
        dispatch({ type: CREATE_SUBSCRIPTION_REQUEST });
  
        try {
          response = await API.createSubscriptionSCA(companyDomain, {
            ...subscriptionData,
            achDetails: {
              customerId: stripeCustomerId,
              paymentId: setupIntent.payment_method,
              fullName: accountHolder,
              last4Digits: stripeLast4Digits,
              bankName: stripeBankName
            },
            cardDetails: undefined
          } as ISubscriptionACH) as ISubscriptionResponseSCA;
  
          if (response.paymentStatus === PaymentStatus.PendingAch || response.paymentStatus === PaymentStatus.Success) {
            dispatch({ type: CREATE_SUBSCRIPTION_SUCCESS, response });
            gaEventTracker(CustomAnalyticsEvents.CREATED_SUBSCRIPTION);
            gaEventTracker(CustomAnalyticsEvents.PURCHASE, getPurchaseDetails(response.subscriptionUniqueId, paymentDescription?.toObject(), plan?.value, cycle));
          }
          else {
            dispatch({ type: CREATE_SUBSCRIPTION_FAILURE });
          }
        }
        catch (err) {
          dispatch({ type: CREATE_SUBSCRIPTION_FAILURE });
        }
    }
    else if (type === "update-payment") {
      //both in case of normal ach and ach with microdeposits
      dispatch(updateAchPayment(checkoutConfig?.companyId as number,
        customerUniqueId as string,
        stripeCustomerId,
        setupIntent.payment_method,
        accountHolder,
        stripeBankName,
        stripeLast4Digits,
        setupIntent?.next_action?.verify_with_microdeposits?.hosted_verification_url))
      
    }
    else if (setupIntent?.next_action?.type === "verify_with_microdeposits" && type === "create-subscription") {
      await generateRecaptchaActionToken(RecaptchaActions.CHECKOUT)
      const checkoutStateStringified = reduxStateToQueryString(appState, setupIntent)
      const checkoutUrl = `https://checkout${ConfigConstants.billsbyDomain}/standalone/subscription?${checkoutStateStringified}`

      try {
        await dispatch(startCheckoutAchMicroDeposit(checkoutConfig?.companyId as number, subscriptionData?.email || email, subscriptionData?.firstName || firstName,
          stripeLast4Digits, stripeBankName, setupIntent.next_action?.verify_with_microdeposits?.hosted_verification_url as string, checkoutUrl))
      }
      catch (err) { }

      dispatch(setPaymentCurrentScreen(PaymentScreen.CONFIRMATION))
    }
  }

  return (
    <Modal
      width={"500px"}
      height={"310px"}
      hasOverlay={true}
      isOpen={isOpenMandateForm}
      title="MANDATE_ACH_TITLE"
      showCloseButton={false}
      onRequestClose={() => { }}>
      <Text content="MANDATE_ACH_CONTENT" translateWith={{ companyName: checkoutConfig?.companyName }} />

      <FormGroup className="ach-mandate__footer">
        <Row>
          <Col xs={6}>
            <Button
              id="mandate-accept"
              title="MANDATE_ACH_ACCEPT"
              isFullWidth
              onClick={onAccept}
            />
          </Col>
          <Col xs={6}>
            <Button
              id="mandate-cancel"
              title="MANDATE_ACH_CANCEL"
              isFullWidth
              onClick={() => {
                dispatch(setAchField("isOpenMandateForm", false))
              }}
            />
          </Col>
        </Row>
      </FormGroup>
    </Modal>
  )

}

export default AchMandateModal;