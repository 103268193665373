/**
 * @fileoverview gRPC-Web generated client stub for billing.public.company.taxation
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from "grpc-web";

import {
  GetTaxValidationRequest,
  GetTaxValidationResponse,
  ValidateTaxRequest,
  ValidateTaxResponse} from "./taxation_pb";

export class TaxationServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options["format"] = "text";

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoGetTaxValidationConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    GetTaxValidationResponse,
    (request: GetTaxValidationRequest) => {
      return request.serializeBinary();
    },
    GetTaxValidationResponse.deserializeBinary
  );

  getTaxValidationConfig(
    request: GetTaxValidationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: GetTaxValidationResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.company.taxation.TaxationService/GetTaxValidationConfig",
      request,
      metadata || {},
      this.methodInfoGetTaxValidationConfig,
      callback);
  }

  methodInfoValidateTax = new grpcWeb.AbstractClientBase.MethodInfo(
    ValidateTaxResponse,
    (request: ValidateTaxRequest) => {
      return request.serializeBinary();
    },
    ValidateTaxResponse.deserializeBinary
  );

  validateTax(
    request: ValidateTaxRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: ValidateTaxResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        "/billing.public.company.taxation.TaxationService/ValidateTax",
      request,
      metadata || {},
      this.methodInfoValidateTax,
      callback);
  }

}

