import { makeActionCreator, makeGenericActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { ICardDetailInputDetail } from "../models/PaymentDetail";
import { IUpdatePaymentReducer } from "../reducers/updatePaymentReducer";

export const RESET_UPDATE_PAYMENT_STATE = "RESET_UPDATE_PAYMENT_STATE";
export const resetUpdatePaymentState = makeActionCreator(RESET_UPDATE_PAYMENT_STATE);

export const SET_UPDATE_PAYMENT_STATE = "SET_UPDATE_PAYMENT_STATE";
type updatePaymentState = keyof IUpdatePaymentReducer;
export const setUpdatePaymentState = makeGenericActionCreator<updatePaymentState>(SET_UPDATE_PAYMENT_STATE);

export const UPDATE_PAYMENT_DETAILS_REQUEST = "UPDATE_PAYMENT_DETAILS_REQUEST";
export const UPDATE_PAYMENT_DETAILS_SUCCESS = "UPDATE_PAYMENT_DETAILS_SUCCESS";
export const UPDATE_PAYMENT_DETAILS_FAILURE = "UPDATE_PAYMENT_DETAILS_FAILURE";

export const VALIDATE_UPDATE_PAYMENT_DETAILS = "VALIDATE_UPDATE_PAYMENT_DETAILS";

export const updatePaymentDetails = function (companyDomain: string, customerUniqueId: string, cardDetails: ICardDetailInputDetail) {
  return {
    types: [UPDATE_PAYMENT_DETAILS_REQUEST, UPDATE_PAYMENT_DETAILS_SUCCESS, UPDATE_PAYMENT_DETAILS_FAILURE],
    callAPI: () => API.updatePaymentDetails(companyDomain, customerUniqueId, cardDetails)
  }
} 