import React, { useMemo, useState, useLayoutEffect } from "react";
import { ISelectPlanReducer } from "../../../reducers/selectPlanReducer";
import { setSelectedPricingModel, setShowPricingInfo, setNumberOfUnits, setCycle, setSelectPlanCurrentScreen } from "../../../actions/selectPlanActions";
import { IPricingModel, PricingModelType, ICycle, VisibilityType } from "../../../models/Product";
import { extractCurrencyFromPriceFormatted, calculateTotalFlatFeeAddOnsPerCycle, recalculatePlanCycle, getCycleFrequencyText, getCycleFreeTrialText, getSetupFeeText, getContractTermText, isComplexPricingModel, getMaximumUnit, getMaximumUnitCycle, showTaxLabel } from "../../../utils/planUtils";
import counterpart from "counterpart";
import FormGroup from "../../ui/form-group/FormGroup";
import Text from "../../ui/text/Text";
import { AddOnPricingModelType, IAddOn, IAllowance, AllowancePricingModelType } from "../../../models/AddOnAllowance";
import { IAddOnReducer } from "../../../reducers/addOnReducer";
import { AppState } from "../../..";
import { useDispatch, useSelector } from "react-redux";
import { ISubscriptionReducer } from "../../../reducers/subscriptionReducer";
import Dropdown from "../../ui/dropdown/Dropdown";
import NumberPicker from "../../ui/number-picker/NumberPicker";
import Button from "../../ui/button/Button";
import "./PlanCycles.scss"
import { SelectPlanScreen } from "../../../models/SelectPlan";
import { IPaymentReducer } from "../../../reducers/paymentReducer";
import { IDiscountCouponReducer } from "../../../reducers/discountCouponReducer";
import { IChangePlanReducer } from "../../../reducers/changePlanReducer";
import { setChangePlanField } from "../../../actions/changePlanActions";
import { ChangePlanScreen } from "../../../models/ChangePlan";
import { Col, Row } from "react-grid-system";
import { setAddOns, setAllowances } from "../../../actions/addOnActions";
import { IPersonalDetailsReducerState } from "../../../reducers/personalDetailsReducer";
import SystemInfo from "../../ui/system-info/SystemInfo";
import { CustomAnalyticsEvents } from "../../../models/GoogleAnalytics";
import { gaEventTracker } from "../../../utils/commonUtils";
import { IGlobalReducer } from "../../../reducers/globalReducer";

interface IPlanCycles {
  hasSelectedCycle?: boolean,
  onSelectCycle?: () => void,
  isChangePlan?: boolean,
  filteredAddons?: IAddOn[],
  filteredAllowances?: IAllowance[],
  readOnly?: boolean,
  disabled?: boolean
}

const PlanCycles: React.FC<IPlanCycles> = ({ disabled = false, isChangePlan = false, filteredAddons, filteredAllowances, readOnly = false, hasSelectedCycle = false, 
  onSelectCycle }) => {
  
  const { checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const { plan, cycle: selectedCycle, numberOfUnits, validUnitRange, plansDropdown, planId, cycleId } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { addOns, allowances, subscriptionAddons, subscriptionAllowances, preloadedAddOns, preloadedAllowances } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const { currentSubscription, currentPlan } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const { paymentDescription } = useSelector<AppState, IPaymentReducer>(state => state.paymentReducer);
  const { appliedCoupons } = useSelector<AppState, IDiscountCouponReducer>(state => state.discountCouponReducer);
  const { isChangePlanCycleRequest, isUpdateSubscription } = useSelector<AppState, IChangePlanReducer>(state => state.changePlanReducer);
  const { taxRegNumber } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const dispatch = useDispatch<Function>()

  if(!onSelectCycle) {
    onSelectCycle = () => dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ADD_ON))
  } 
  
  const isExisitingSubscription = Boolean(currentSubscription) || Boolean(currentPlan);
  const [modifiedPlan, setModifiedPlan] = useState(plan);
  const [currentCycle, setCurrentCycle] = useState<{ label: string, value: ICycle } | null>(null);

  const plusTaxesLabel = showTaxLabel(paymentDescription, taxRegNumber) ? ` ${counterpart("PRICE_SUMMARY_PLUS_TAX")}` : "";

  const forceAddOnAllowances = useMemo(() => {
    const combinedAddOnAllowances = [...addOns, ...allowances];
    return combinedAddOnAllowances.filter(i => i.isForced);
  }, [allowances, addOns, selectedCycle, plan]);

  const hasForcedAddons = (addOns: Array<IAddOn | IAllowance>) => {
    return addOns.filter(i => i.isForced && i.pricingModelType !== AllowancePricingModelType.AllowanceCapped).length > 0;
  }

  const isComplex = useMemo(() => plan && isComplexPricingModel(plan.value.pricingModelType), [plan]);
  const isCurrentCycleOffSale = useMemo(() => {
    if (currentSubscription && plan) {
      return Boolean(plan.value.cycles.find(c => c.cycleId === currentSubscription.cycleId && c.visibility === VisibilityType.OffSale))
    } else {
      return false
    }
  }, [])


  const addOnsAllowancesText = useMemo(() => {
    const planHasForcedAddons = hasForcedAddons(addOns);
    const planHasForcedAllowances = hasForcedAddons(allowances);

    const subscriptionHasAddons = filteredAddons ? filteredAddons.length > 0 : subscriptionAddons && subscriptionAddons.length > 0;
    const subscriptionHasAllowances = filteredAllowances ? filteredAllowances.length > 0 : subscriptionAllowances && subscriptionAllowances.length > 0;

    if (isExisitingSubscription && isUpdateSubscription) {
      if (subscriptionHasAddons && subscriptionHasAllowances) {
        return "CHANGE_SUBSCRIPTION_INCLUDES_ADDONS_ALLOWANCES"
      }
      if (subscriptionHasAddons) {
        return "CHANGE_SUBSCRIPTION_INCLUDES_ADDONS"
      }
      if (subscriptionHasAllowances) {
        return "CHANGE_SUBSCRIPTION_INCLUDES_ALLOWANCES"
      }
      return ""
    }

  }, [subscriptionAddons, subscriptionAllowances, filteredAddons, filteredAllowances, isExisitingSubscription, plan, hasSelectedCycle, addOns, allowances])

  const cyclesPublicSorted = useMemo(() => {
    if(plan && !currentSubscription && planId && cycleId) {
      // e.g preloaded per unit plan and cycle without passing nr of units, then we show the preloaded cycle even if it's hidden
      return plan.value.cycles.filter(c => c.cycleId === cycleId);
    }
    if (plan) {
      return currentSubscription && isCurrentCycleOffSale
        ? plan.value.cycles.filter(c => c.visibility === VisibilityType.Public || c.cycleId === currentSubscription.cycleId)
        : plan.value.cycles.filter(c => c.visibility === VisibilityType.Public);

      //Hide for now, disable sorting by frequency type
      // return planCycles
      //   .sort((a, b) => {
      //     return a.pricingModel.frequencyType > b.pricingModel.frequencyType
      //       ? 1
      //       : a.pricingModel.frequencyType < b.pricingModel.frequencyType
      //         ? -1
      //         : 0 || a.pricingModel.price - b.pricingModel.price;
      //   })
    }
    return [];
  }, [plan]);

  const totalFlatFeeAddOnsCycle = (cycle: ICycle) => {
    const { currency, isAtBeginning } = extractCurrencyFromPriceFormatted(cycle.pricingModel.priceFormatted);
    const flatFeeAddOns = calculateTotalFlatFeeAddOnsPerCycle(forceAddOnAllowances as Array<IAddOn>, cycle);
    const cyclePrice = (flatFeeAddOns + cycle.pricingModel.price) / 100;
    return `${isAtBeginning ? currency : ""}${cyclePrice.toFixed(2)}${!isAtBeginning ? currency : ""}`;
  }

  const cycleOptions = useMemo(() => {
    if (!modifiedPlan || (isComplexPricingModel(modifiedPlan.value.pricingModelType) && !numberOfUnits && !validUnitRange)) {
      return []
    }

    const selectableCycles = cyclesPublicSorted.filter((c => ((numberOfUnits || 1000000) <= getMaximumUnitCycle(c))));

    return selectableCycles.map((cycle: ICycle) => {
      const { priceFormatted, frequency, frequencyType, freeTrial, freeTrialFrequencyType } = cycle.pricingModel;
      // const priceWithAddOn = modifiedPlan.value.pricingModelType === PricingModelType.FlatFee ? totalFlatFeeAddOnsCycle(cycle) : priceFormatted;
      const freeTrialText = isExisitingSubscription ? "" : getCycleFreeTrialText(freeTrial, freeTrialFrequencyType);

      return (
        {
          label: `${priceFormatted} ${getCycleFrequencyText(frequency, frequencyType)} ${freeTrialText}`,
          value: cycle
        });
    })
  }, [numberOfUnits, validUnitRange, addOns, allowances, forceAddOnAllowances, cyclesPublicSorted, modifiedPlan]);

  //Update addons and allowances after selecting a new plan
  useLayoutEffect(() => {
    if (modifiedPlan && !isUpdateSubscription) {
      // only set available addons and allowances if they are not preloaded. 
      // if they are we ignore this step because they will not be selectable
      if(!preloadedAddOns.length) {
        dispatch(setAddOns(modifiedPlan.value.addons));
      }
      if(!preloadedAllowances.length) {
        dispatch(setAllowances(modifiedPlan.value.allowances));
      }
    }
  }, [isUpdateSubscription]);

  //HIDE FOR NOW
  // useLayoutEffect(() => {
  //   if(!numberOfUnits) {
  //     dispatch(setNumberOfUnits(1))
  //   }
  //   //To refresh cycle prices when changing plan, a cycle should be selected on default
  //   if (currentSubscription && isChangePlan) {
  //     return;
  //   }
  //   if (cyclesPublicSorted.length > 0 && plan && currentCycle === null) {
  //     dispatch(setCycle(isComplexPricingModel(plan.value.pricingModelType) && !numberOfUnits ? null : cyclesPublicSorted[0]));
  //   }
  // }, [cyclesPublicSorted, plan, currentSubscription, numberOfUnits, isChangePlan, currentCycle]);

  // Set default number of units and cycle
  useLayoutEffect(() => {
    if ((!selectedCycle && !currentSubscription) || (!selectedCycle && isChangePlan)) {
      dispatch(setCycle(cyclesPublicSorted[0]));
    }
  }, [selectedCycle, currentCycle, currentSubscription, cycleOptions])

  useLayoutEffect(() => {
    if (!isUpdateSubscription && currentSubscription) {
      setCurrentCycle(cycleOptions[0])
    }

    if (selectedCycle && cycleOptions) {
      const deletedCurrentCycle = modifiedPlan && modifiedPlan.value.cycles.map(c => { })

      setCurrentCycle(cycleOptions.find(co => co.value.cycleId === selectedCycle.cycleId) || cycleOptions[0])
    }
  }, [cycleOptions, selectedCycle, isUpdateSubscription, currentSubscription]);

  // Update cycle price in dropdown
  useLayoutEffect(() => {
    if (!numberOfUnits) {
      dispatch(setNumberOfUnits(1))
    }

    if (numberOfUnits && plan) {
      const currentPlan = { ...plan };
      const flatFeeAddOns = forceAddOnAllowances.filter(i => i.pricingModelType === AddOnPricingModelType.AddonFlatFee) as Array<IAddOn>;
      currentPlan.value = recalculatePlanCycle(currentPlan.value, numberOfUnits, flatFeeAddOns);
      setModifiedPlan(currentPlan);

    }
  }, [numberOfUnits, forceAddOnAllowances, plan]);


  if (!plan || !checkoutConfig) {
    return <div />;
  }

  const showMoreInfo = (pricingModel: IPricingModel) => {
    dispatch(setSelectedPricingModel(pricingModel));
    dispatch(setShowPricingInfo(true));
  }

  const onNumberOfUnitsChanged = (units: number) => {
    dispatch(setNumberOfUnits(units));
    // const currentPlan = { ...plan };
    // const flatFeeAddOns = forceAddOnAllowances.filter(i => i.pricingModelType === AddOnPricingModelType.AddonFlatFee) as Array<IAddOn>;
    // currentPlan.value = recalculatePlanCycle(currentPlan.value, units, flatFeeAddOns);
    // dispatch(setPlan(currentPlan));
    // dispatch(setCycle(undefined));

  }

  if (!checkoutConfig) {
    return null;
  }


  const isPlanNotOnSale = () => {
    if (plan && plan.value) {
      const currentPlan = plan.value;
      return currentPlan.visibility !== VisibilityType.Public || currentPlan.isDeleted;
    }

    return false;
  };

  const updateUnits = (numberOfUnits: number | undefined, action: "plus" | "minus") => {
    if (numberOfUnits === undefined) {
      if (action === "plus") {
        dispatch(setNumberOfUnits(1))
      }
    } else {
      onNumberOfUnitsChanged(action === "plus" ? Number(numberOfUnits) + 1 : Number(numberOfUnits) - 1)
    }
  }

  const renderPlanCycles = () => {
    return (
      <>
        <FormGroup className="plan-cycles__inputs" margin="none">
          <Row align="center">
            <div className="plan-cycles__input-group">
              {isComplex && !readOnly && (
                <div className="plan-cycles__inputs__complex">
                  <NumberPicker
                    isInputDisabled={disabled}
                    value={numberOfUnits}
                    min={1}
                    max={currentCycle ? getMaximumUnitCycle(currentCycle.value) : getMaximumUnit(plan.value)}
                    minLock={disabled || numberOfUnits as any === "" || numberOfUnits === 1 || numberOfUnits === undefined}
                    maxLock={disabled || (currentCycle ? ((numberOfUnits || 1000000) >= getMaximumUnitCycle(currentCycle.value)) : false)}
                    onMinus={() => updateUnits(numberOfUnits, "minus")}
                    onPlus={() => updateUnits(numberOfUnits, "plus")}
                    onChange={(value) => {
                      // dispatch(setCycle(null));
                      // setCurrentCycle(null);
                      onNumberOfUnitsChanged(value);
                    }}>

                  </NumberPicker>
                  <Text className="plan-cycles__unit-label" content={
                    numberOfUnits && numberOfUnits > 1 ?
                      checkoutConfig.terminologyUnitPlural :
                      checkoutConfig.terminologyUnitSingular} shouldTranslate={false} noMargin></Text>
                </div>
              )}
              {!readOnly && !!cycleOptions.length && (
                <div className="plan-cycles__cycle-dropdown-container">
                  <Dropdown
                    disabled={isComplexPricingModel(plan.value.pricingModelType) && !numberOfUnits}
                    placeholder={counterpart("SELECT_PLAN_CHANGE_SELECT_CYCLE")}
                    options={isCurrentCycleOffSale && currentSubscription ? cycleOptions.filter(c => c.value.cycleId !== currentSubscription.cycleId) : cycleOptions}
                    value={currentCycle}
                    style={{ marginLeft: isComplex ? "-5px" : "5px" }}
                    readOnly={cyclesPublicSorted && !isUpdateSubscription ? cyclesPublicSorted.length === 1 : disabled}
                    className="plan-cycles__cycle-dropdown"
                    onChange={(cycle: any) => {
                      if (!isPlanNotOnSale()) {
                        // we need to dispatch the cycle here to trigger some hooks in the SelectPlanStep2
                        dispatch(setCycle(cycle.value));
                        setCurrentCycle(cycle)
                      }
                    }}
                  >
                  </Dropdown>
                </div>
              )}

              <SystemInfo
                title="SELECTED_PRODUCT_HAVE_NO_CYCLES"
                type="warning"
                isVisible={!cycleOptions.length}
                noMargin
              />
            </div>
          </Row>
          
        </FormGroup>
      </>

    )
  };

  const renderPlanCycleSummary = () => {
    const hasDiscounts = appliedCoupons.length > 0

    const _currentCycle = currentCycle ? currentCycle.value : selectedCycle;

    if (!currentCycle || !_currentCycle) {
      return null;
    }

    const { priceFormatted, frequency, frequencyType, setupFeePriceFormatted, freeTrial, freeTrialFrequencyType, contractTerm,
      contractTermFrequencyType, tiers } = _currentCycle.pricingModel;
    let contractTermText = getContractTermText(contractTerm, contractTermFrequencyType);
    contractTermText = setupFeePriceFormatted ? `${contractTermText.toLowerCase()}, ` : contractTermText;
    const priceWithAddOn = plan.value.pricingModelType === PricingModelType.FlatFee ? totalFlatFeeAddOnsCycle(_currentCycle) : priceFormatted;
    const freeTrialText = isExisitingSubscription ? "" : getCycleFreeTrialText(freeTrial, freeTrialFrequencyType);

    return (
      <Row className="plan-cycles__summary">
        <Col className="plan-cycles__summary__details" sm={8}>
          {readOnly && (
            <>
              <Text
                content={`${paymentDescription ? paymentDescription.getFirstInvoiceTotalWithoutDiscountsPrice() : priceWithAddOn}${plusTaxesLabel}`}
                className={`plan-cycles__summary__price${hasDiscounts ? " plan-cycles__summary__price--discounted" : ""}`}
                shouldTranslate={false}
                weight="bold"
                noMargin
                component="span"
              />
              {
                hasDiscounts && (
                  <Text
                    content={`${paymentDescription ? paymentDescription.getFirstInvoiceTotalPrice() : ""}${plusTaxesLabel} ${freeTrialText}`}
                    shouldTranslate={false}
                    weight="bold"
                    noMargin
                    component="span"
                  />
                )
              }
              <Text
                content={` ${getCycleFrequencyText(frequency, frequencyType)} ${freeTrialText}`}
                shouldTranslate={false}
                weight="bold"
                noMargin
                component="span"
              />
              {addOnsAllowancesText && (
                <Text content={addOnsAllowancesText} translateWith={{ addonLabel: filteredAddons && filteredAddons.length > 1 ? checkoutConfig?.terminologyAddonPlural : checkoutConfig?.terminologyAddonSingular, allowanceLabel: filteredAllowances && filteredAllowances.length > 1 ? checkoutConfig?.terminologyAllowancePlural : checkoutConfig?.terminologyAllowanceSingular }} noMargin component="span"></Text>
              )}
            </>
          )}
          {!readOnly && (
            <>
              <div>
                <Text
                  content={`${isChangePlan && paymentDescription ? paymentDescription.getFirstInvoiceTotalWithoutDiscountsPrice() : priceWithAddOn}${plusTaxesLabel}`}
                  className={`plan-cycles__summary__price${hasDiscounts ? " plan-cycles__summary__price--discounted" : ""}`}
                  shouldTranslate={false}
                  weight="bold"
                  noMargin
                  component="span"
                />

                {
                  hasDiscounts && (
                    <Text
                      content={`${paymentDescription ? paymentDescription.getFirstInvoiceTotalPrice() : ""}${plusTaxesLabel}`}
                      shouldTranslate={false}
                      weight="bold"
                      noMargin
                      component="span"
                    />
                  )
                }
                {
                  tiers && (
                    <>
                      {" "}
                      <Text size='normal' className="select-plan__more-pricing-info select-plan__inline-info"
                        content="PAYMENT_SUMMARY_PLAN_WHY" noMargin onClick={() => showMoreInfo(_currentCycle.pricingModel)} />
                    </>
                  )
                }
                {
                  <Text
                    content={` ${freeTrialText}`}
                    className={"plan-cycles__summary__price"}
                    shouldTranslate={false}
                    weight="bold"
                    noMargin
                    component="span"
                  />
                }
                {
                  addOnsAllowancesText && (
                    <Text content={addOnsAllowancesText} translateWith={{ addonLabel: filteredAddons && filteredAddons.length > 1 ? checkoutConfig?.terminologyAddonPlural : checkoutConfig?.terminologyAddonSingular, allowanceLabel: filteredAllowances && filteredAllowances.length > 1 ? checkoutConfig?.terminologyAllowancePlural : checkoutConfig?.terminologyAllowanceSingular }} noMargin component="span"></Text>
                  )
                }
              </div>
              <Text component="span" size='normal' content={contractTermText} shouldTranslate={false} noMargin />
              {
                setupFeePriceFormatted
                  ? (
                    <Text
                      size='normal'
                      content={getSetupFeeText(setupFeePriceFormatted, checkoutConfig)}
                      shouldTranslate={false}
                      noMargin
                      component="span"
                    />
                  )
                  : null
              }
            </>
          )}
          {
            hasForcedAddons(forceAddOnAllowances)
              ? <Text component="span" content={`, ${counterpart("ADD_ONS_EXTRA_CHARGES_APPLY")}`} shouldTranslate={false} noMargin />
              : null
          }
        </Col>
        {!readOnly &&
          <Col sm={4}>
            <Button
              id="pick-plan"
              className="plan-cycles__pick-btn"
              disabled={isExisitingSubscription && isUpdateSubscription ? (!hasSelectedCycle || isChangePlanCycleRequest) : false}
              title={isExisitingSubscription && isUpdateSubscription ? "CHANGE_SUBSCRIPTION_UPDATE" : "PICK_THIS_PLAN"}
              translateWith={{ planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular, subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
              isLoading={isChangePlanCycleRequest}
              onClick={async () => {
                if (!_currentCycle) {
                  return;
                }

                dispatch(setCycle(_currentCycle));
                if (!isUpdateSubscription && currentSubscription) {
                  dispatch(setChangePlanField("currentScreen", ChangePlanScreen.SET_ADD_ON));
                  return;
                }
                await onSelectCycle?.();
                isExisitingSubscription && isUpdateSubscription && gaEventTracker(CustomAnalyticsEvents.UPDATED_SUBSCRIPTION)
              }}
            />
          </Col>
        }
      </Row>
    )
  }

  return (
    <div className="plan-cycles">
      {renderPlanCycles()}
      {renderPlanCycleSummary()}
    </div>
  )
}

export default PlanCycles