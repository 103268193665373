import React, { useEffect } from "react";
import { IPaymentReducer } from "../../reducers/paymentReducer";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import { resetPaymentState, setPaymentCurrentScreen, setPaymentGatewayType } from "../../actions/paymentActions";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import SystemInfo from "../ui/system-info/SystemInfo";
import queryString from "query-string";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import { sendEvent, CROSS_DOMAIN_EVENT } from "../../utils/crossDomainEvents";
import { setIsCloseIconVisible, setNavigationStep } from "../../actions/globalActions";
import { BillsbyDataParams, ContainerWebsite } from "../../models/BillsbyData";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { getPaymentScreenBySourceType, loadScriptFromString } from "../../utils/commonUtils";
import history from "../../utils/history";
import { NavigationBarSteps } from "../../models/NavigationBar";
import { setPersonalDetailsCurrentScreen } from "../../actions/personalDetailsActions";
import { PersonalDetailsScreen } from "../../models/PersonalDetails";
import { PaymentMethodType } from "../../models/Payment";
import { resetAchState } from "../../actions/achActions";
import { IACHReducer } from "../../reducers/achReducer";
import AchInfoMessage from "../ach-form/AchInfoMessage";


const PaymentMethodStep2: React.FC = () => {
  const { hasFailed, paymentGatewayType, currentScreen, errorsList } = useSelector<AppState, IPaymentReducer>(state => state.paymentReducer);
  const { isUpdateAchSucceeded, isUpdateAchFailed, isStartMicroDepositSuccess, isStartMicroDepositFailed } = useSelector<AppState, IACHReducer>(state => state.achReducer)
  const { plan } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { subscriptionResponse, hasCustomerPaymentOnFile } = useSelector<AppState, IPaymentReducer>(state => state.paymentReducer);
  const { checkoutConfig, preloadedCustomerData } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const dispatch = useDispatch<Function>()
  
  const planName = plan ? plan.label : "error";
  const params = queryString.parse(window.location.search);

  const isContainerWebsiteBillsby = preloadedCustomerData.containerWebsite === ContainerWebsite.BILLSBY_APP;
  const isAccountManagement = history.location.pathname.includes("/management");
  const confirmationHeading = checkoutConfig?.confirmationHeading;
  const confirmationSubheading = checkoutConfig?.confirmationSubheading;
  const confirmationCopy = checkoutConfig?.confirmationCopy;
  const confirmationButtonText = checkoutConfig?.confirmationButtonText;

  const showContinueButton = () => {
    if(isContainerWebsiteBillsby || isAccountManagement) {
      return true
    }

    if(checkoutConfig?.isWixCompatibilityModeVisible) {
      return false;
    }

    return true;
  }

  const onContinue = () => {
    // priority redirectUrl: embedCode -> plan level -> company level
    const redirectUrl = params[BillsbyDataParams.redirect] as string || (plan && plan.value.redirectUrl) || (checkoutConfig && checkoutConfig.redirectUrl);
    if (!redirectUrl) {
      return sendEvent(CROSS_DOMAIN_EVENT.CLOSE);
    }
    if (subscriptionResponse) {
      if (redirectUrl.includes("?")) {
        return sendEvent(CROSS_DOMAIN_EVENT.REDIRECT, `${redirectUrl}&cid=${subscriptionResponse.customerUniqueId}&sid=${subscriptionResponse.subscriptionUniqueId}`);
      }
      return sendEvent(CROSS_DOMAIN_EVENT.REDIRECT, `${redirectUrl}?cid=${subscriptionResponse.customerUniqueId}&sid=${subscriptionResponse.subscriptionUniqueId}`);
    }
    sendEvent(CROSS_DOMAIN_EVENT.REDIRECT, redirectUrl);
  }


  useEffect(() => {
    loadScriptFromString(checkoutConfig && checkoutConfig.trackingCodeEnd);
    if (!hasFailed) {
      dispatch(setIsCloseIconVisible(false));
    }
  }, [])


  if (hasFailed) {
    const isInvalidCountry = errorsList && errorsList.length && errorsList[0].code === "Subscriptions_Create_Subscription_Address_Not_Permitted";
    const getBtnText = () => {
      if(isInvalidCountry) {
        return "PAYMENT_CHANGE_ADDRESS";
      }
      if((plan?.value.paymentSourceTypes as Array<PaymentMethodType>).length > 1) {
        return "PAYMENT_TRY_ANOTHER_PAYMENT_METHOD";
      }
      return "PAYMENT_TRY_ANOTHER_CARD";
    }
    
    return (
      <PageWrapper 
        btnBackHidden 
        title={"PAYMENT_DETAILS_TITLE"} 
        btnPosition="bottom" 
        btnText={getBtnText()} 
        btnCallback={() => {
          dispatch(resetPaymentState());
          dispatch(resetAchState());
          dispatch(setPaymentCurrentScreen(getPaymentScreenBySourceType(plan?.value.paymentSourceTypes as Array<PaymentMethodType>, preloadedCustomerData, hasCustomerPaymentOnFile)));
          dispatch(setPaymentGatewayType(paymentGatewayType));
          if(isInvalidCountry) {
            dispatch(setNavigationStep(NavigationBarSteps.PERSONAL_DETAILS));
            dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.PERSONAL_ADDRESS));
          }
        }}>
        <div className="payment-method">
          <SystemInfo
            title="PAYMENT_ERROR_TITLE"
            text={isInvalidCountry && errorsList && errorsList.length && errorsList[0].description ? errorsList[0].description : "PAYMENT_ERROR_SUBTITLE"}
            type="warning"
            isShowIcon={false}
            shouldTranslateText={!isInvalidCountry}
          />

        </div>
      </PageWrapper>
    )
  }


  const renderBody = () => {
    if(isUpdateAchSucceeded || isUpdateAchFailed || isStartMicroDepositSuccess || isStartMicroDepositFailed) {
      return <AchInfoMessage />
    }

    return (
      <Panel id="payment-method-custom-success" className="payment-method__custom-success">
        <Text content={confirmationSubheading || "PAYMENT_SUCCESS_TITLE"} shouldTranslate={!confirmationSubheading} translateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }} weight="bold" />
        <Text content={confirmationCopy || "PAYMENT_SUCCESS_SUBTITLE"} shouldTranslate={!confirmationCopy} translateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }} noMargin />
      </Panel>
    )
  }

  return (
    <PageWrapper
      title={confirmationHeading || "PAYMENT_SUCCESS_MAIN_TITLE"}
      titleShouldTranslate={!confirmationHeading}
      showCloseIcon={false}
      btnBackHidden
      btnPosition="bottom"
      shouldTranslateText={!confirmationButtonText}
      btnText={confirmationButtonText || "PAYMENT_CONTINUE"}
      btnCallback={showContinueButton() ? onContinue : undefined}>
      <div className="payment-method">
        {/*<SystemInfo
          className="payment-method__custom-success"
          title="PAYMENT_SUCCESS_TITLE"
          text="PAYMENT_SUCCESS_SUBTITLE"
          translateWith={{ planName, planLabel: checkoutConfig && checkoutConfig.terminologyPlanSingular }}
          isShowIcon={false}
        />*/}
        
        { renderBody() }

        {
          !!params[BillsbyDataParams.wmessage]
          && (
            <Panel id="payment-method-data-params-wmessage">
              <Text content={params[BillsbyDataParams.wmessage] as string} shouldTranslate={false} noMargin />
            </Panel>
          )
        }

      </div>
    </PageWrapper>
  )
}

export default PaymentMethodStep2