import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import { IPaymentReducer } from "../../reducers/paymentReducer";
import { PaymentScreen } from "../../models/Payment";
import PaymentMethodStep1 from "../../components/payment-method/PaymentMethodStep1";
import PaymentMethodStep2 from "../../components/payment-method/PaymentMethodStep2";
import PaymentMethodSelectType from "../../components/payment-method/PaymentMethodSelection";

const getCurrentScreen = (paymentState: IPaymentReducer) => {
  const { currentScreen } = paymentState;
  switch (currentScreen) {
    case PaymentScreen.CREDIT_CARD:
    case PaymentScreen.ACH:
    case PaymentScreen.CARD_ON_FILE:
      return <PaymentMethodStep1 />
    case PaymentScreen.CONFIRMATION:
      return <PaymentMethodStep2 />
    case PaymentScreen.SELECT_TYPE:
      return <PaymentMethodSelectType />

  }
}

const PaymentMethod: React.FC = () => {
  const paymentState = useSelector((state: AppState) => state.paymentReducer)
  return getCurrentScreen(paymentState);
}

export default PaymentMethod;