import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PersonalDetailsScreen } from "../../models/PersonalDetails";
import PersonalDetailsStep1 from "../../components/personal-details/PersonalDetailsStep1";
import PersonalDetailsStep2 from "../../components/personal-details/PersonalDetailsStep2";
import { fetchCountries } from "../../actions/personalDetailsActions";
import { AppState } from "../..";
import PersonalDetailsStep3 from "../../components/personal-details/PersonalDetailsStep3";

const PersonalDetails: React.FC = () => {
  const currentScreen = useSelector((state: AppState) => state.personalDetailsReducer.currentScreen);
  const companyDomain = useSelector((state: AppState) => state.globalReducer.companyDomain);
  const selectPlanReducer = useSelector((state: AppState) => state.selectPlanReducer);
  
  const dispatch = useDispatch<Function>();

  useEffect(() => {
    dispatch(fetchCountries(companyDomain, selectPlanReducer.product ? selectPlanReducer.product.value.productId : -1 ));
  }, [])

  const getCurrentScreen = () => {
    switch (currentScreen) {
      case PersonalDetailsScreen.MAIN_DATA:
        return <PersonalDetailsStep1 />
      case PersonalDetailsScreen.PERSONAL_ADDRESS:    
        return <PersonalDetailsStep2 />
      case PersonalDetailsScreen.CUSTOM_FIELDS:
        return <PersonalDetailsStep3 />
      
      default:
        return <div>error</div>
    }
  }
  return getCurrentScreen();
}

export default PersonalDetails;
