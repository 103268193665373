import React, { useLayoutEffect, useState } from "react";
import "./SelectPlanStep.scss";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import { setProduct, setSelectPlanCurrentScreen, fetchPlans, setCycle, setPlan, FETCH_PLANS_SUCCESS, setNumberOfUnits, setActivePlanId } from "../../actions/selectPlanActions";
import { SelectPlanScreen } from "../../models/SelectPlan";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IProduct } from "../../models/Product";
import { resetAddOnState } from "../../actions/addOnActions";
import SeeMoreText from "../ui/see-more-text/SeeMoreText";
import { fetchCustomFields } from "../../actions/personalDetailsActions";
import FormLabel from "../ui/form-label/FormLabel";
import CollapsiblePanel from "../ui/collapsible-panel/CollapsiblePanel";


const SelectPlanStep1: React.FC = () => {
  const { product, productsDropdown, productId, plansDropdown, productsDropdownAvailable, isFetchingPlans } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const dispatch = useDispatch<Function>()
  
  const [hasPlans, setHasPlans] = useState(false);

  useLayoutEffect(() => {
    //even a hidden product can be pre-selected hence why we filtering through all the products including the hidden ones
    const preselectedProduct = productsDropdown.find(product => product.value.productId === productId);

    const setPreselectedProductAndGoToNextScreen = (preselectedProduct: { label: string, value: IProduct }) => {
      dispatch(setProduct(preselectedProduct));
      dispatch(fetchPlans(preselectedProduct.value.productId));
      dispatch(fetchCustomFields(preselectedProduct.value.productId));
      dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PLAN));
    }
    if (preselectedProduct) {
      setPreselectedProductAndGoToNextScreen(preselectedProduct);
      return;
    }
    // check if only one product available through the public ones
    if (productsDropdownAvailable.length === 1) {
      setPreselectedProductAndGoToNextScreen(productsDropdownAvailable[0]);
    }

  }, [productsDropdownAvailable]);


  useLayoutEffect(() => {
    setHasPlans(plansDropdown.length !== 0);
    //Remove active plan when going to product selection screen
    dispatch(setActivePlanId(null));
  }, [plansDropdown]);

  if (!checkoutConfig) {
    return null;
  }


  const renderProductsList = () => {
    return productsDropdownAvailable.map((product) => {
      return (
        <CollapsiblePanel
          id="select-plan__product-item-collapsible"
          isClickable
          className="select-plan__product-item"
          image={product.value.productImageUrl}
          key={product.label}
          onClick={() => {
            dispatch(setCycle(null));
            dispatch(setPlan(null));
            // need to reset the plans and nrUnits as well if we go back and forth with the screens to avoid keeping some old data
            dispatch({ type: FETCH_PLANS_SUCCESS, response: [] });
            dispatch(setNumberOfUnits(undefined));

            //we need to reset the addon reducer when changing product because we might change it to one that doesn't
            // support the addons previously added
            dispatch(resetAddOnState());
            dispatch(setProduct(product));
            dispatch(fetchPlans(product.value.productId));
            dispatch(fetchCustomFields(product.value.productId));
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PLAN))
          }}
        >
          <div>
            <FormLabel content={product.label} shouldTranslate={false} weight="bold" size="large" noMargin />
            <SeeMoreText content={product.value.description || ""} shouldTranslateContent={false} noMargin />
          </div>
        </CollapsiblePanel>
      )
    })
  }


  return (
    <PageWrapper
      className="select-plan"
      btnDisabled={!product || isFetchingPlans || !hasPlans}
      title="CHOOSE_PRODUCT_TITLE"
      titleTranslateWith={{ productLabel: checkoutConfig.terminologyProductSingular }}>
      <>
        {renderProductsList()}
      </>
    </PageWrapper>
  );
};

export default SelectPlanStep1
