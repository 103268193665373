import { CREATE_SUBSCRIPTION_SUCCESS, CREATE_SUBSCRIPTION_FAILURE, CREATE_SUBSCRIPTION_REQUEST, SET_PAYMENT_CURRENT_SCREEN, SET_CARD_HOLDER_NAME, SET_CARD_EXPIRY_MONTH, SET_CARD_EXPIRY_YEAR, SET_CARD_ERRORS, SET_PAYMENT_TOKEN, SET_TERMS_POLICY, RESET_PAYMENT_STATE, VALIDATE_PAYMENT_DATA, GET_PRICE_DESCRIPTION_REQUEST, GET_PRICE_DESCRIPTION_SUCCESS, GET_PRICE_DESCRIPTION_FAILURE, SET_PAYMENT_GATEWAY_TYPE, SET_CARD_TYPE, SET_CARD_LAST_FOUR_DIGIT, GET_HAS_CUSTOMER_CARD_ON_FILE_SUCCESS, GET_PRICING_INFO_DISCLOSURE_SUCCESS } from "../actions/paymentActions";
import { CheckoutAction } from "../models/CheckoutAction";
import { PaymentMethodType, PaymentScreen } from "../models/Payment";
import { AppState } from "..";
import { PaymentGatewayTypeId } from "../models/Product";
import { ISubscriptionResponse } from "../models/Subscription";
import { GetInvoicePricingDisclosureResponse, GetInvoiceSimulationResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/invoice/invoice_pb";
import { GetCustomerPaymentDetailsResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/customer_pb";
import { IPaymentDetailsError } from "../components/payment-details-form/PaymentDetailsForm";
import { CROSS_DOMAIN_EVENT, sendEvent } from "../utils/crossDomainEvents";

export interface IPaymentReducer {
  currentScreen: PaymentScreen,
  cardHolderName: string,
  cardExpiryMonth: string,
  cardExpiryYear: string,
  cardType: string,
  lastFourDigit: string,
  errors: { obj: IPaymentDetailsError, arr: Array<string> } | null,
  paymentToken: string,
  termsAndPolicies: boolean,
  hasSucceded: boolean,
  hasFailed: boolean,
  isSubscribing: boolean,
  paymentDescriptionIsLoading: boolean,
  paymentDescription: GetInvoiceSimulationResponse | null,
  pricingInfoDisclosure: string,
  paymentGatewayType: PaymentGatewayTypeId,
  subscriptionResponse?: ISubscriptionResponse,
  hasCustomerPaymentOnFile: boolean,
  customerPaymentDetails?: GetCustomerPaymentDetailsResponse,
  errorsList?: Array<any>
}

export const initialState: IPaymentReducer = {
  currentScreen: PaymentScreen.CREDIT_CARD,
  cardHolderName: "",
  cardExpiryMonth: "",
  cardExpiryYear: "",
  cardType: "",
  lastFourDigit: "",
  errors: null,
  paymentToken: "",
  termsAndPolicies: false,
  hasSucceded: false,
  hasFailed: false,
  isSubscribing: false,
  paymentDescriptionIsLoading: true,
  paymentDescription: null,
  pricingInfoDisclosure: "",
  paymentGatewayType: PaymentGatewayTypeId.SpreedlyTest,
  subscriptionResponse: undefined,
  hasCustomerPaymentOnFile: false
}

const validatePaymentFields = (state: IPaymentReducer, action: CheckoutAction) => {
  const { cardHolderName, cardExpiryMonth, cardExpiryYear } = state;
  const errors: Array<string> = [];

  if (!cardHolderName) {
    errors.push("PAYMENT_CARDHOLDER_NAME");
  }
  if (!cardExpiryMonth) {
    errors.push("PAYMENT_EXPIRATION_DATE");
  }
  if (!cardExpiryYear) {
    errors.push("PAYMENT_EXPIRATION_DATE");
  }

  return { ...state, errors }
}

export default function paymentReducer(state = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case SET_PAYMENT_CURRENT_SCREEN:
      return { ...state, currentScreen: action.payload }
    case SET_CARD_HOLDER_NAME:
      return { ...state, cardHolderName: action.payload }
    case SET_CARD_EXPIRY_MONTH:
      return { ...state, cardExpiryMonth: +action.payload <= 12 ? action.payload : state.cardExpiryMonth }
    case SET_CARD_EXPIRY_YEAR:
      return { ...state, cardExpiryYear: action.payload.length <= 4 ? action.payload : state.cardExpiryYear }
    case SET_CARD_TYPE:
      return { ...state, cardType: action.payload }
    case SET_CARD_LAST_FOUR_DIGIT:
      return { ...state, lastFourDigit: action.payload }
    case SET_CARD_ERRORS:
      return { ...state, errors: action.payload }
    case VALIDATE_PAYMENT_DATA:
      return validatePaymentFields(state, action);
    case SET_PAYMENT_TOKEN:
      return { ...state, paymentToken: action.payload }
    case CREATE_SUBSCRIPTION_REQUEST:
      return { ...state, isSubscribing: true, hasSucceded: false, hasFailed: false }
    case CREATE_SUBSCRIPTION_SUCCESS: {
      sendEvent(CROSS_DOMAIN_EVENT.CREATE_SUBSCRIPTION_SUCCESS)
      const subscriptionResponse = action.response as ISubscriptionResponse;
      return { ...state, isSubscribing: false, hasSucceded: true, hasFailed: false, currentScreen: PaymentScreen.CONFIRMATION, subscriptionResponse }
    }
    case CREATE_SUBSCRIPTION_FAILURE:
      return { ...state, isSubscribing: false, hasSucceded: false, hasFailed: true, currentScreen: PaymentScreen.CONFIRMATION, errorsList: action?.error?.list }
    case SET_TERMS_POLICY:
      return { ...state, termsAndPolicies: action.payload }
    case RESET_PAYMENT_STATE:
      return { ...initialState }
    case GET_PRICE_DESCRIPTION_REQUEST:
      return { ...state, paymentDescriptionIsLoading: true }
    case GET_PRICE_DESCRIPTION_SUCCESS:
      return { ...state, paymentDescription: action.response, paymentDescriptionIsLoading: false }
    case GET_PRICE_DESCRIPTION_FAILURE:
      return { ...state, paymentDescriptionIsLoading: false }
    case GET_PRICING_INFO_DISCLOSURE_SUCCESS: {
      const pricingInfoDisclosure = action.response as GetInvoicePricingDisclosureResponse;
      return { ...state, pricingInfoDisclosure: pricingInfoDisclosure.getPricingDisclosure() }
    }
    case SET_PAYMENT_GATEWAY_TYPE:
      return { ...state, paymentGatewayType: action.payload }
    case GET_HAS_CUSTOMER_CARD_ON_FILE_SUCCESS:
      const getCustomerPaymentDetails = action.response as GetCustomerPaymentDetailsResponse;
      return { 
        ...state, 
        hasCustomerPaymentOnFile: getCustomerPaymentDetails.getPaymentMethodType() !== PaymentMethodType.None,
        customerPaymentDetails: getCustomerPaymentDetails
      }
    default:
      return state;
  }
}