import React, { useState, useEffect, useMemo } from "react";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import counterpart from "counterpart";
import FormGroup from "../ui/form-group/FormGroup";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IGlobalReducer } from "../../reducers/globalReducer";
import Modal from "../ui/modal/Modal";
import { setNavigationStep } from "../../actions/globalActions";
import { NavigationBarSteps } from "../../models/NavigationBar";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import Button from "../ui/button/Button";
import { IDiscountCouponReducer } from "../../reducers/discountCouponReducer";
import { fetchCouponDetails, setCouponCode, setAppliedCoupons, deleteSubscriptionDiscounts, fetchSubscriptionDiscounts, updateCouponDiscount, resetDiscountCoupon } from "../../actions/discountCouponActions";
import SystemInfo from "../ui/system-info/SystemInfo";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";
import { CouponError, IAppliedCoupon } from "../../models/DiscountCoupon";
import PlanSummaryDetails from "./plan-summary-details/PlanSummaryDetails";
import { AddCouponDiscountRequest } from "../../utils/grpc/generated/Billsby.Protos/billing/public/subscription/discount/discount_pb";
import { Int32Value } from "google-protobuf/google/protobuf/wrappers_pb";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { setProduct, fetchPlans, setPlan, setNumberOfUnits, setCycle, setSelectPlanCurrentScreen } from "../../actions/selectPlanActions";
import { IPlanWithCycles, ProductType, IProduct, PricingModelType } from "../../models/Product";
import { fetchSubscriptionAddons, fetchSubscriptionAllowances, setSelectedAddOns } from "../../actions/addOnActions";
import { IAddOnReducer } from "../../reducers/addOnReducer";
import { AddOnPricingModelType, IAddOn } from "../../models/AddOnAllowance";
import { recalculatePlanCycle } from "../../utils/planUtils";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import { fetchPersonalDetails } from "../../actions/changePersonalDetailsActions";
import { IChangePersonalDetailsReducer } from "../../reducers/changePersonalDetailsReducer";
import { SelectPlanScreen } from "../../models/SelectPlan";

interface ISelectPlanSummary {
  preload?: boolean
  resetOnUnmount?: boolean,
  btnText?: string,
  btnCallback?: () => void,
  isChangingPlan?: boolean,
  showContentBelowBtn?: boolean,
  editableAddOns?: boolean
}

const SelectPlanSummary: React.FC<ISelectPlanSummary> = ({ btnText, editableAddOns = true, btnCallback, showContentBelowBtn = false, isChangingPlan = false, preload = true, resetOnUnmount = false }) => {
  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);
  const [isApplyingCouponCode, setIsApplyingCouponCode] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState<CouponError | null>(null);

  const { companyDomain, checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { appliedCoupons, couponName, couponCode } = useSelector<AppState, IDiscountCouponReducer>(state => state.discountCouponReducer);
  const { currentSubscription, currentPlan } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const { product, plan, cycle, productId, planId, products, plans } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { email } = useSelector<AppState, IChangePersonalDetailsReducer>(state => state.changePersonalDetailsReducer);
  const { mainProps: { subscriptionUniqueId, customerUniqueId } } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { addOns, allowances, subscriptionAddons, subscriptionAllowances } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const dispatch = useDispatch<Function>()

  const isExisitingSubscription = Boolean(currentSubscription) || Boolean(currentPlan);

  const forceAddOnAllowances = useMemo(() => {
    const combinedAddOnAllowances = [...addOns, ...allowances];
    return combinedAddOnAllowances.filter(i => i.isForced);
  }, [allowances, addOns]);

  const setCurrentProduct = () => {
    if (!currentSubscription || !currentPlan) {
      return;
    }

    const product: IProduct = {
      productId: currentSubscription.productId,
      isAdditionalEmailRequired: false,
      isMarketingConsentRequired: false,
      isPhoneNumberRequired: false,
      isShippingAddressRequired: false,
      isWorldWideAddressAllowed: false,
      isBillingAddressValidationRequired: false,
      isShippingAddressValidationRequired: false,
      name: currentSubscription.productName,
      visibility: currentSubscription.status as any,
      billingCurrency: currentSubscription.currency,
      displayName: currentSubscription.productName,
      description: currentPlan.description,
      typeOfProduct: ProductType.TieredPlan,
      isDeleted: false
    };

    dispatch(setProduct({ label: "", value: product }));
  }


  const preloadData = async () => {
    if (!currentSubscription || !currentPlan) {
      return;
    }

    try {
      const { response: plans }: { response: Array<IPlanWithCycles> } = (
      await dispatch(fetchPlans(currentSubscription.productId))) as { response: Array<IPlanWithCycles> };
      await dispatch(fetchSubscriptionAddons(companyDomain, subscriptionUniqueId as string));
      await dispatch(fetchSubscriptionAllowances(companyDomain, subscriptionUniqueId as string));
      await dispatch(fetchSubscriptionDiscounts(companyDomain, subscriptionUniqueId as string));
      await dispatch(fetchPersonalDetails(companyDomain, customerUniqueId as string));

      if (plans) {
        let _currentPlan = plans.find((plan) => plan.planId === currentSubscription.planId);

        if (_currentPlan) {
          const flatFeeAddOns = forceAddOnAllowances.filter((i) => i.pricingModelType === AddOnPricingModelType.AddonFlatFee) as Array<IAddOn>;
          _currentPlan = _currentPlan.pricingModelType !== PricingModelType.FlatFee ? recalculatePlanCycle(currentPlan, currentSubscription.units as number, flatFeeAddOns) : currentPlan;
          dispatch(setPlan({ label: currentPlan.name, value: _currentPlan }));

          dispatch(setNumberOfUnits(currentSubscription.units));

          const currentCycle = _currentPlan.cycles.find((cycle) => cycle.cycleId === currentSubscription.cycleId);
          if (currentCycle) {
            dispatch(setCycle(currentCycle));
          }
        }
      }
    } catch (err) { }
  };

  useEffect(() => {
    setCurrentProduct()
    preload && preloadData();

    return () => resetOnUnmount && dispatch(resetDiscountCoupon())
  }, [currentPlan, currentSubscription]);

  useEffect(() => {
    if (subscriptionAddons && subscriptionAllowances) {
      dispatch(setSelectedAddOns([...subscriptionAddons, ...subscriptionAllowances]));
    }
  }, [subscriptionAddons, subscriptionAllowances]);

  if (!product || !plan || !cycle) {
    return null;
  }

  const onAddCoupon = async () => {
    if (!couponCode) {
      return;
    }

    setIsSuccessful(false);
    setError(null)

    if (isExisitingSubscription && currentSubscription && currentPlan && !isChangingPlan) {
      let codeList: Array<AddCouponDiscountRequest.CodeDetails> = [];

      const newCode = new AddCouponDiscountRequest.CodeDetails();
      const newInt32 = new Int32Value();
      newInt32.setValue(currentPlan.planId);
      newCode.setPlanId(newInt32);
      newCode.setCouponCode(couponCode);

      codeList.push(newCode);

      try {
        await dispatch(fetchCouponDetails(companyDomain, couponCode, email));
        await dispatch(updateCouponDiscount(companyDomain, currentSubscription.subscriptionUniqueId, codeList));
        await dispatch(fetchSubscriptionDiscounts(companyDomain, currentSubscription.subscriptionUniqueId));

        setIsSuccessful(true);
        setIsApplyingCouponCode(false);
        setError(null);
        onCloseCouponModal();
      } catch (err) {
        console.log(err)
        onError(err);
      }

      return;
    }

    setIsApplyingCouponCode(true);
    setIsSuccessful(false);
    setError(null);

    try {
      await dispatch(fetchCouponDetails(companyDomain, couponCode, email));

      setIsSuccessful(true);
      setIsApplyingCouponCode(false);
      setError(null);
      onCloseCouponModal();
    } catch (err) {
      onError(err);
    }
  };

  const onError = (err: CouponError) => {
    setIsApplyingCouponCode(false);
    setIsSuccessful(false);
    setError(err);
    onCloseCouponModal();
  };

  const onDeleteCoupon = async (coupon: IAppliedCoupon) => {
    setIsSuccessful(false);
    setError(null)

    if (isExisitingSubscription && currentSubscription && !isChangingPlan) {
      try {
        await dispatch(deleteSubscriptionDiscounts(companyDomain, currentSubscription.subscriptionUniqueId, coupon.subscriptionDiscount ? coupon.subscriptionDiscount.getSubscriptionDiscountId() : undefined));
        await dispatch(fetchSubscriptionDiscounts(companyDomain, currentSubscription.subscriptionUniqueId));
      } catch (err) {
        onError(err);
      }

      return;
    }

    dispatch(setAppliedCoupons(appliedCoupons.filter((ac) => {
      if (ac.coupon) {
        return ac.coupon.getCouponId() !== coupon.coupon.getCouponId()
      }

      // else return ac.discount.getDiscountId() !== coupon.coupon.getCouponId()


    })));
    setError(null);
    setIsSuccessful(false);
  };

  const onCloseCouponModal = () => {
    setIsOpenCouponModal(false);
    dispatch(setCouponCode(""));
  };

  const getError = (): { title: string; content: string } => {
    switch (error) {
      case CouponError.INVALID_CODE:
        return { title: "APPLY_COUPON_ERROR_TITLE1", content: "APPLY_COUPON_ERROR_CONTENT1" };
      case CouponError.NOT_COMPATIBLE:
        return { title: appliedCoupons.length > 1 ? "APPLY_COUPON_ERROR_TITLE2" : "APPLY_COUPON_ERROR_TITLE6", content: "" };
      case CouponError.NOT_FOR_EXISTING_CUSTOMERS:
        return { title: isExisitingSubscription ? "APPLY_COUPON_ERROR_CONTENT3" : "APPLY_COUPON_ERROR_TITLE3", content: isExisitingSubscription ? "" : "APPLY_COUPON_ERROR_CONTENT3" };
      case CouponError.NOT_FOR_NEW_CUSTOMERS:
        return { title: !isExisitingSubscription ? "APPLY_COUPON_ERROR_CONTENT4" : "APPLY_COUPON_ERROR_TITLE3", content: !isExisitingSubscription ? "" : "APPLY_COUPON_ERROR_CONTENT4" };

      default:
        return { title: "APPLY_COUPON_ERROR_TITLE1", content: "APPLY_COUPON_ERROR_CONTENT1" };
    }
  };

  const noticeCouponName = error === CouponError.NOT_COMPATIBLE ? appliedCoupons.map((d) => d.coupon ? d.coupon.getName() : d.discount.getName()).join(", ") : couponName;


  return (
    <PageWrapper
      btnText={counterpart(btnText ? btnText : isExisitingSubscription ? "APPLY_COUPON_MANAGE_BTN" : "SELECT_PLAN_CONTINUE")}
      className="select-plan"
      title={currentSubscription ? "CHANGE_SUBSCRIPTION_NEW_SUBSCRIPTION" : ""}
      titlePosition="left-aligned"
      btnTextTranslateWith={{ couponLabel: checkoutConfig?.terminologyCouponSingular }}
      btnCallback={() => btnCallback
        ? btnCallback()
        : (isExisitingSubscription ? setIsOpenCouponModal(true) : dispatch(setNavigationStep(NavigationBarSteps.PERSONAL_DETAILS)))
      }
      btnDisabled={false}
      btnBackCallback={() => {
        if (plan.value.addons.length || plan.value.allowances.length) {
          dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ADD_ON));
        } else if (!planId && plans.length > 1) {
          dispatch(setCycle(null));
          dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PLAN));
        }
        else if (!productId && products.length > 1) {
          dispatch(setProduct(null));
          dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PRODUCT));
        }
      }}
      btnBackHidden={!plan.value.addons.length && !plan.value.allowances.length &&
        ((!!productId && !!planId) || (products.length === 1 && plans.length === 1) || (!!productId && plans.length === 1))}
      shouldTranslateText={false}
      bottomStickyContent={
        ((!isExisitingSubscription || showContentBelowBtn) ? (
          <a href="javascript:void(0)" className="retention__link" onClick={() => setIsOpenCouponModal(true)}>
            {counterpart("APPLY_COUPON_LINK", { couponLabel: checkoutConfig?.terminologyCouponSingular })}
          </a>
        ) : (
            undefined
          ))
      }
    >
      <>
        {(isSuccessful || error) && (
          <SystemInfo
            title={counterpart(!error ? "APPLY_COUPON_SUCCESS_TITLE1" : getError().title, {
              couponName: noticeCouponName,
              couponLabel: checkoutConfig?.terminologyCouponSingular,
              couponsLabel: checkoutConfig?.terminologyCouponPlural
            })}
            text={error ? getError().content : ""}
            type={error ? "warning" : "success"}
            isShowIcon={false}
            shouldTranslateTitle={false}
          />
        )}

        <PlanSummaryDetails
          onDeleteSubscriptionCoupon={onDeleteCoupon}
        />

        <Modal isOpen={isOpenCouponModal} title="" shouldTranslateTitle={false} className="select-plan__coupon-modal" showCloseButton={false}>
          <>
            <FormGroup>
              <FormLabel content="APPLY_COUPON_LABEL" translateWith={{ couponLabel: checkoutConfig?.terminologyCouponSingular }}></FormLabel>
              <Input
                id="coupon-code"
                value={couponCode}
                placeholder={counterpart("APPLY_COUPON_PLACEHOLDER", { couponLabel: checkoutConfig?.terminologyCouponSingular })}
                onChange={(evt: any) => {
                  dispatch(setCouponCode(evt.target.value.replace(/\s/g, "")));
                }}
              ></Input>
            </FormGroup>

            <div className="select-plan__coupon-modal__footer">
              <Button disabled={isApplyingCouponCode || !couponCode} isLoading={isApplyingCouponCode} className="modal__body__closebtn" title="APPLY_COUPON_BTN" translateWith={{ couponLabel: checkoutConfig?.terminologyCouponSingular }} id="apply-coupon" onClick={onAddCoupon}></Button>
              <div>
                <a href="javascript:void(0)" className="retention__link" onClick={onCloseCouponModal}>
                  {counterpart("APPLY_COUPON_CANCEL")}
                </a>
              </div>
            </div>
          </>
        </Modal>
      </>
    </PageWrapper>
  );
};

export default SelectPlanSummary