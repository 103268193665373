import { CheckoutAction } from "../models/CheckoutAction";
import { AppState } from "..";
import { SET_LOGIN_FIELD, LOGIN_EMAIL_REQUEST, LOGIN_EMAIL_SUCCESS, LOGIN_EMAIL_FAILURE, LOGIN_PASSCODE_REQUEST, LOGIN_PASSCODE_SUCCESS, LOGIN_PASSCODE_FAILURE } from "../actions/loginActions";
import { LoginScreen } from "../models/Login";
import { SendPasscodeEmailResponse, ValidatePasscodeResponse } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/customer_pb";

export interface ILoginReducer {
  /* this flag is used because the login screen is dynamic depending if the customerUniqueId or subscriptionUniqueId are passed through */
  isLoadingScreen: boolean,
  currentScreen: LoginScreen,
  email: string,
  isloginEmailRequest: boolean,
  isLoginEmailSuccess: boolean
  isLoginEmailFailure: boolean,
  validateEmailResponse?: SendPasscodeEmailResponse
  passCode: string,
  isLoginPasscodeRequest: boolean,
  isLoginPasscodeSuccess: boolean,
  isLoginPasscodeFailure: boolean,
  validatePasscodeResponse?: ValidatePasscodeResponse
}

export const initialState: ILoginReducer = {
  isLoadingScreen: true,
  currentScreen: LoginScreen.EMAIL,
  email: "",
  isloginEmailRequest: false,
  isLoginEmailSuccess: false,
  isLoginEmailFailure: false,
  passCode: "",
  isLoginPasscodeRequest: false,
  isLoginPasscodeSuccess: false,
  isLoginPasscodeFailure: false
}

export default function loginReducer(state = initialState, action: CheckoutAction, store: AppState) {
  switch (action.type) {
    case SET_LOGIN_FIELD:
      return { ...state, [action.fieldName]: action.fieldValue }
    case LOGIN_EMAIL_REQUEST:
      return { ...state, isloginEmailRequest: true, isLoginEmailSuccess: false, isLoginEmailFailure: false }
    case LOGIN_EMAIL_SUCCESS:
      return { ...state, isloginEmailRequest: false, isLoginEmailSuccess: true, validateEmailResponse: action.response, isLoginEmailFailure: false }
    case LOGIN_EMAIL_FAILURE:
      return { ...state, isloginEmailRequest: false, isLoginEmailSuccess: false, isLoginEmailFailure: true }
    case LOGIN_PASSCODE_REQUEST:
      return { ...state, isLoginPasscodeRequest: true, isLoginPasscodeSuccess: false, isLoginPasscodeFailure: false }
    case LOGIN_PASSCODE_SUCCESS:
      return { ...state, isLoginPasscodeRequest: false, validatePasscodeResponse: action.response, isLoginPasscodeSuccess: true, isLoginPasscodeFailure: false }
    case LOGIN_PASSCODE_FAILURE:
      return { ...state, isLoginPasscodeRequest: false, isLoginPasscodeSuccess: false, isLoginPasscodeFailure: true }
    default:
      return state;
  }
}