import React from "react";
import "./LoadingAnimation.scss";

interface ILoadingAnimation {
  isCentered?: boolean
}

const LoadingAnimation: React.FC<ILoadingAnimation> = React.memo(({ isCentered = true}) => {
  return (
    <div className={isCentered ? "loading-animation--centered" : ""}>
      <div className="loading-animation--lds-ellipsis">
        <div></div><div></div><div></div><div></div>
      </div>
    </div>
  )
})

export default LoadingAnimation;