import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { setNavigationStep } from "../../actions/globalActions";
import { setPaymentCurrentScreen } from "../../actions/paymentActions";
import { setEmail, setPersonalDetailsCurrentScreen } from "../../actions/personalDetailsActions";
import { setCycle, setProduct, setSelectPlanCurrentScreen } from "../../actions/selectPlanActions";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import { NavigationBarSteps } from "../../models/NavigationBar";
import { PaymentScreen } from "../../models/Payment";
import { PersonalDetailsScreen } from "../../models/PersonalDetails";
import { SelectPlanScreen } from "../../models/SelectPlan";
import { IAddOnReducer } from "../../reducers/addOnReducer";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import { isComplexPricingModel } from "../../utils/planUtils";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";

const PaymentMethodSelection = () => {
  const { fields, isAddressScreenPreloaded, isUserDataScreenPreloaded } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { product, productId, productsDropdownAvailable, planId, plansDropdownAvailable, addOnsFilteredByCycle, allowancesFilteredByCycle } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { preloadedAddOns, preloadedAllowances, hasPreloadedAddOnsWithMissingUnits } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const dispatch = useDispatch();

  if (!product) {
    return null;
  }

  const hasCustomFieldsOrStandardFields = !!fields.customFields.length || product.value.isAdditionalEmailRequired || product.value.isPhoneNumberRequired || product.value.isMarketingConsentRequired;

  return (
    <PageWrapper
      className="payment-method__step1"
      title="PAYMENT_METHOD_SELECTION"
      titlePosition="left-aligned"
      btnText=""
      btnVisible={false}
      btnBackCallback={() => {
        if (hasCustomFieldsOrStandardFields) {
          dispatch(setNavigationStep(NavigationBarSteps.PERSONAL_DETAILS));
          dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.CUSTOM_FIELDS));
        }
        else if (isAddressScreenPreloaded && !isUserDataScreenPreloaded) {
          // go back to user data screen
          dispatch(setNavigationStep(NavigationBarSteps.PERSONAL_DETAILS));
          dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.MAIN_DATA));
          dispatch(setEmail(""));
        }
        else if (isAddressScreenPreloaded && isUserDataScreenPreloaded) {
          dispatch(setNavigationStep(NavigationBarSteps.SELECT_PLAN));

          if (allowancesFilteredByCycle.length && !allowancesFilteredByCycle.every(a => a.isForced) && !preloadedAllowances.length) {
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ALLOWANCE));
          }
          else if ((addOnsFilteredByCycle.length && !addOnsFilteredByCycle.every(a => a.isForced && !isComplexPricingModel(a.pricingModelType)) && !preloadedAddOns.length) || (hasPreloadedAddOnsWithMissingUnits)) {
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ADD_ON));
          }
          else if (!planId && plansDropdownAvailable.length > 1) {
            dispatch(setCycle(null));
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PLAN));
          }
          else /*if (!productId && products.length > 1)*/ {
            dispatch(setProduct(null));
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PRODUCT));
          }
        }
        else {
          // go back to address screen
          dispatch(setNavigationStep(NavigationBarSteps.PERSONAL_DETAILS));
          dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.PERSONAL_ADDRESS));
        }
      }}
      btnBackHidden={isUserDataScreenPreloaded && isAddressScreenPreloaded && !hasCustomFieldsOrStandardFields &&
        ((!!preloadedAddOns.length && !!preloadedAllowances.length) || (!addOnsFilteredByCycle.length && !allowancesFilteredByCycle.length)) &&
        ((!!productId && !!planId) || (productsDropdownAvailable.length === 1 && plansDropdownAvailable.length === 1) || (!!productId && plansDropdownAvailable.length === 1))}
    >
      <Panel id="payment-method-selection-ach" isClickable onClick={() => {
        dispatch(setPaymentCurrentScreen(PaymentScreen.ACH))
      }}>
        <Text content="PAYMENT_METHOD_SELECTION_ACH" noMargin></Text>
      </Panel>

      <Panel id="payment-method-selection-credit-cards" isClickable onClick={() => {
        dispatch(setPaymentCurrentScreen(PaymentScreen.CREDIT_CARD))
      }}>
        <Text content="PAYMENT_METHOD_SELECTION_CREDIT_CARDS" noMargin></Text>
      </Panel>
    </PageWrapper>
  )
}


export default PaymentMethodSelection