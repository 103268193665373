import React from "react";
import { AppState } from "../..";
import { useSelector } from "react-redux";
import { PaymentMethodType } from "../../models/Payment";
import UpdateCardDetails from "../update-card-details/UpdateCardDetails";
import UpdateAchDetails from "../update-ach-details/UpdateAchDetails";
import { IPaymentReducer } from "../../reducers/paymentReducer";

export interface IUpdatePaymentDetails {
  title?: string;
  titleTranslateWith?: object,
  titlePosition?: "centered" | "left-aligned",
  subTitle?: string;
  subTitleTranslateWith?: object,
  btnText?: string;
  btnTextTranslateWith?: object,
  shouldTranslateText?: boolean,
  btnPosition?: "top-right" | "bottom",
  bottomContent?: JSX.Element,
  btnCallback?: () => void,
  btnBackCallback?: () => void,
  afterUpdateCallback?: () => void,
  redirect?: boolean,
  onSuccessRedirectToAccountManagement?: boolean,
  showCloseIcon?: boolean,
  isLoading?: boolean,
  customSuccessSubtitle?: string;
}

const UpdatePaymentDetails: React.FC<IUpdatePaymentDetails> = (props) => {
  //this Higher order component is used just for customer with existing payment details
  // existing ACH customers cannot replace payment to Credit cards and vice versa
  const { customerPaymentDetails } = useSelector<AppState, IPaymentReducer>(state => state.paymentReducer);
  
  const getCurrentScreen = () => {
    switch(customerPaymentDetails?.getPaymentMethodType()) {
      case PaymentMethodType.CreditCard:
        return < UpdateCardDetails {...props }/>
      case PaymentMethodType.Ach:
        return <UpdateAchDetails {...props} />
      default:
        return <UpdateCardDetails {...props} />
    }
  }

  return getCurrentScreen()
 };

export default UpdatePaymentDetails
