import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import API from "../../utils/API";
import PaymentMethod from "../payment-method/PaymentMethod";
import LoadingAnimation from "../../components/ui/loading-animation/LoadingAnimation";
import { CREATE_SUBSCRIPTION_FAILURE, CREATE_SUBSCRIPTION_SUCCESS, setPaymentCurrentScreen, setPaymentGatewayType } from "../../actions/paymentActions";
import { ISubscriptionExistingCustomerSCA, ISubscriptionResponseSCA, ISubscriptionSCA, PaymentStatus } from "../../models/Subscription";
import { IBillsbySubscriptionSCAPendingData, PaymentScreen } from "../../models/Payment";
import { setNavigationStep } from "../../actions/globalActions";
import { NavigationBarSteps } from "../../models/NavigationBar";
import NavigationContainer from "../navigation-container/NavigationContainer";
import { fetchCouponDetails, setAppliedCoupons, setCouponCode } from "../../actions/discountCouponActions";
import { CustomAnalyticsEvents } from "../../models/GoogleAnalytics";
import { gaEventTracker, generateRecaptchaActionToken } from "../../utils/commonUtils";
import { getPurchaseDetails } from "../../utils/planUtils";
import { RecaptchaActions } from "../../utils/constants";

/**
 * After the sca redirect back to the checkout the session is restored inside the reducer file index.ts
 * @param param0 
 * @returns 
 */
const Sca3ds1CreateSubSuccess: React.FC = () => {
  const companyDomain = useSelector((state: AppState) => state.globalReducer.companyDomain);
  const appliedCoupons = useSelector((state: AppState) => state.discountCouponReducer.appliedCoupons);
  const email = useSelector((state: AppState) => state.personalDetailsReducer.mainProps.email);

  const dispatch = useDispatch<Function>();
 
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const processData = async () => {
      let response;
      try {

        //get SCA data from session storage
        const subscriptionSCAPendingData = JSON.parse(sessionStorage.billsbySubscriptionSCA) as IBillsbySubscriptionSCAPendingData;
        const { subscriptionSCARequestPayload, customerUniqueId, selectPlanState, paymentDescription } = subscriptionSCAPendingData;


        // load global data required in reducers to work properly, we need to retain current checkout behavior, which means loading branding data, custom copies etc.
        dispatch(setNavigationStep(NavigationBarSteps.PAYMENT_METHOD));
        dispatch(setPaymentCurrentScreen(PaymentScreen.CONFIRMATION));
        dispatch(setPaymentGatewayType(selectPlanState.plan?.value.paymentGatewayType));


        // final call to complete the create subscription journey
        if (!!customerUniqueId) {
          const payload = subscriptionSCARequestPayload as ISubscriptionSCA;
          const { scaTransaction: _, ...payloadWithoutScaTransaction } = payload;
          response = await API.createSubscriptionExistingCustomerSCA(companyDomain, customerUniqueId, {
            ...payloadWithoutScaTransaction, 
            browserInfo: payload.scaTransaction.browserInfo,
            transactionToken: payload.scaTransaction.transactionToken
          } as ISubscriptionExistingCustomerSCA) as ISubscriptionResponseSCA;
        }
        else {
          response = await API.createSubscriptionSCA(companyDomain, subscriptionSCARequestPayload as ISubscriptionSCA) as ISubscriptionResponseSCA;
        }

        if (response.paymentStatus === PaymentStatus.Pending) {
          throw new Error("Payment status is Pending");
        }

        dispatch({ type: CREATE_SUBSCRIPTION_SUCCESS, response });
        gaEventTracker(CustomAnalyticsEvents.CREATED_SUBSCRIPTION);
        gaEventTracker(CustomAnalyticsEvents.PURCHASE, getPurchaseDetails(response.subscriptionUniqueId, paymentDescription, selectPlanState.plan?.value, selectPlanState.cycle));
      }
      catch (err) {
        // if there were any applied coupons before the sca redirect we have to preload them correctly in the reducer because 
        // even though we serialized the reducer data, coupons are instances of protos and those cannot be serialized correctly
        // in the session storage, because they have circular references, so we need to manually preload them to allow retrying with a new card
        if (appliedCoupons.length) {
          dispatch(setAppliedCoupons([]));
          appliedCoupons.forEach(async (acc) => {
            dispatch(setCouponCode(acc.couponCode as string));
            await dispatch(fetchCouponDetails(companyDomain, acc.couponCode as string, email as string))
          })
        }
        dispatch({ type: CREATE_SUBSCRIPTION_FAILURE });
      }
      finally {
        sessionStorage.removeItem("billsbySubscriptionSCA");
        setIsLoading(false);
      }
    }

    const createSubscription = async () => {
      await generateRecaptchaActionToken(RecaptchaActions.CHECKOUT);
      processData().catch(err => console.log(err));
    }

    createSubscription().catch(err => console.log(err));
  }, []);


  if (isLoading) {
    return <LoadingAnimation />
  }

  return (
    <NavigationContainer>
      <PaymentMethod />
    </NavigationContainer>
  )
}

export default Sca3ds1CreateSubSuccess;