import React from "react";
import Translate from "react-translate-component";
import ButtonLoader from "./ButtonLoader";
import "./Button.scss";
import { Button as ButtonMUI, ButtonProps } from "@material-ui/core";

interface IButton extends ButtonProps {
  title: string,
  disabled?: boolean,
  isVisible?: boolean,
  onClick?: (event: any) =>  void,
  isLoading?: boolean,
  isFullWidth?: boolean,
  translateWith?: object,
  buttonType?: "general",
  type?: "button" | "reset" | "submit",
  id: string,
  icon?: string,
  iconPosition?: "start" | "end",
  style?: object,
  className?: string,
  shouldTranslate?: boolean
}

const Button: React.FC<IButton> = React.memo(props => {
  const {
    iconPosition = "end",
    title = "Button title",
    disabled = false,
    isVisible = true,
    onClick = () => { },
    isLoading = false,
    isFullWidth = false,
    translateWith = {},
    buttonType = "general",
    type = "button",
    id = "",
    icon = "",
    style = {},
    className = "",
    shouldTranslate = true,
    children
  } = props;

  const fullWidthClassName: string = isFullWidth ? "btn__full" : "";

  if (!isVisible) {
    return null;
  }

  const renderMUILayout = () => {
    const startIcon = iconPosition === "start" && icon ? <i className={`button__icon button__icon-start ${icon}`} /> : undefined;
    const endIcon = iconPosition === "start" && icon ? <i className={`button__icon button__icon-start ${icon}`} /> : undefined;
    return (
      <ButtonMUI
        id={id}
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled || isLoading}
        style={style}
        type={type}
        onClick={onClick}
        className={`button ${fullWidthClassName} btn__basic btn__${buttonType} ${className} ${disabled ? "btn__disabled" : ""}`}>
        <span>
          {
            isLoading
            ? <ButtonLoader />
            : shouldTranslate
              ? <Translate content={title} component="span" with={translateWith} />
              : title
          }
          {children}
        </span>
      </ButtonMUI>
    )
  }
  
  return renderMUILayout();
})

export default Button;
