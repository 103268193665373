import React from "react";
import "./SelectPlan.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { SelectPlanScreen } from "../../models/SelectPlan";
import SelectPlanStep1 from "../../components/select-plan/SelectPlanStep1";
import SelectPlanStep2 from "../../components/select-plan/SelectPlanStep2";
import { setCycle, setSelectPlanCurrentScreen, setProduct } from "../../actions/selectPlanActions";
import SetAddOnsStep from "../../components/select-plan/SetAddOnsStep";
import SetAllowancesStep from "../../components/select-plan/SetAllowancesStep";

const SelectPlan: React.FC = () => {
  const selectPlanReducer = useSelector((state: AppState) => state.selectPlanReducer);
  const addOnReducer = useSelector((state: AppState) => state.addOnReducer);

  const dispatch = useDispatch<Function>();

  const { currentScreen, product, plan, cycle, productId, planId, cycleId, addOnsFilteredByCycle, plansDropdownAvailable, productsDropdownAvailable } = selectPlanReducer;
  const { preloadedAddOns, hasPreloadedAddOnsWithMissingUnits } = addOnReducer;

  /*useEffect(() => {
    if (product && product.value) {
      dispatch(fetchPlans(product.value.productId));
      dispatch(fetchCustomFields(product.value.productId));
    }
  }, [currentScreen]);*/



  switch (currentScreen) {
    case SelectPlanScreen.PRODUCT:
      return <SelectPlanStep1 />;
    case SelectPlanScreen.PLAN:
      return <SelectPlanStep2 />;
    case SelectPlanScreen.ADD_ON:
      return product && plan && cycle && (
        <SetAddOnsStep
          cycle={cycle}
          plan={plan.value}
          btnBackCallback={() => {
            // we need to reset at least the cycle otherwise the screen is skipped
            if (!planId && plansDropdownAvailable.length > 1) {
              dispatch(setCycle(null));
              dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PLAN));
            }
            else /*if (!productId && products.length > 1)*/ {
              dispatch(setProduct(null));
              dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PRODUCT));
            }

            /*dispatch(setCycle(null));
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PLAN));*/
          }}
          btnBackHidden={(!!productId && !!planId && !!cycleId)
            || (productsDropdownAvailable.length === 1 && plansDropdownAvailable.length === 1 && plansDropdownAvailable[0].value.cycles.length === 1)
            || (!!productId && plansDropdownAvailable.length === 1 && plansDropdownAvailable[0].value.cycles.length === 1)
            || (!!productId && !!planId && plansDropdownAvailable[0].value.cycles.length === 1)}
          billingCurrency={product.value.billingCurrency || ""}

        />
      )
    case SelectPlanScreen.ALLOWANCE:
      return plan && cycle && (
        <SetAllowancesStep
          cycle={cycle}
          plan={plan.value}
          btnBackCallback={() => {
            if (addOnsFilteredByCycle.length) {
              dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ADD_ON));
            }
            // we need to reset at least the cycle otherwise the screen is skipped
            else if (!planId && plansDropdownAvailable.length > 1) {
              dispatch(setCycle(null));
              dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PLAN));
            }
            else /*if (!productId && products.length > 1)*/ {
              dispatch(setProduct(null));
              dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PRODUCT));
            }
          }}
          btnBackHidden={(!!productId && !!planId && preloadedAddOns.length && !hasPreloadedAddOnsWithMissingUnits)
            || (productsDropdownAvailable.length === 1 && plansDropdownAvailable.length === 1 && !addOnsFilteredByCycle.length)
            || (!!productId && plansDropdownAvailable.length === 1 && !addOnsFilteredByCycle.length)}
        />
      )

    default:
      return <div />;
  }
};

export default SelectPlan;
