import counterpart from "counterpart";
import React, { FormEvent } from "react";
import Translate from "react-translate-component";
import "./Text.scss";

interface IText {
  /**
  * The text to be rendered
  */
  content: string | JSX.Element,
  /**
  * The type of div element
  */
  component?: string,
  /**
  * Additional classes use to add custom styles
  */
  className?: string,
  /**
   * additional inline style
   */
  style?: object
  /**
   * if the value has to be translated
   */
  shouldTranslate?: boolean,
  /**
   * translate with props
   */
  translateWith?: object,
  /*
   * Remove the bottom margin
   */
  noMargin?: boolean,
  /*
   * Click handler
   */
  onClick?: (evt: FormEvent) => any,
  /**
   * size of text
   */
  size?: "xlarge" | "large" | "normal" | "small" | "xsmall"
  /**
   * weight of text
   */
  weight?: "bold" | "normal",
  /**
   * dynamic translation of single text
   */
  locale?: string
}

const Text: React.FC<IText> = React.memo(({ content, component = "p", className = "", shouldTranslate = true, translateWith = {}, noMargin = false, onClick = () => { },
  style = {}, size = "normal", weight = "normal", locale = counterpart.getLocale() }) => {



  if (shouldTranslate) {
    return (
      <Translate
        content={content}
        component={component}
        with={translateWith}
        className={`text ${noMargin ? "text__mb0" : ""} text--${size} text--weight-${weight} ${className}`}
        onClick={onClick}
        style={style}
        locale={locale}
      />
    );
  }

  //first letter MUST BE capitalized in order to bind dinamically the component
  let Component: any = component;
  return (
    <Component className={`text ${noMargin ? "text__mb0" : ""} text--${size} text--weight-${weight} ${className}`} style={style} onClick={onClick}>
      {content}
    </Component>
  );
})

export default Text;
