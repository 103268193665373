export interface ICustomField {
  customFieldId: number,
  type: CustomFieldType,
  label: string,
  description: string,
  options: string,
  compulsory: boolean
}

export interface ICustomFieldWithValue extends ICustomField {
  value: string
}

export enum CustomFieldType {
  SingleLineTextField = "SingleLineTextField",
  MultiLineTextField = "MultiLineTextField",
  DropdownField = "DropdownField",
  CheckboxField = "CheckboxField",
  DatePickerField = "DatePickerField",
  NumbersField = "NumbersField"
}