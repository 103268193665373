import React, { useState, useEffect } from "react";
import PageWrapper from "../../../containers/page-wrapper/PageWrapper";
import Text from "../../ui/text/Text";
import UpdatePaymentDetails from "../../../containers/update-payment-details/UpdatePaymentDetails";
import "./ManageAddonRetryPayment.scss";
import { useDispatch, useSelector } from "react-redux";
import SystemInfo from "../../ui/system-info/SystemInfo";
import { AppState } from "../../..";
import moment from "moment";
import { IAddOnReducer } from "../../../reducers/addOnReducer";
import Modal from "../../ui/modal/Modal";
import { extractCurrencyFromPriceFormatted, getCycleFrequencyText } from "../../../utils/planUtils";
import { ICustomerSubscription } from "../../../models/Customer";
import { ICycle } from "../../../models/Product";
import { IGlobalReducer } from "../../../reducers/globalReducer";
import { setAddonFields } from "../../../actions/addOnActions";

interface IManageAddonRetryPayment {
  onUpdateCardSuccess: () => void,
  btnBackCallback: () => void,
  currentSubscription: ICustomerSubscription,
  currentCycle: ICycle
}

enum RetryPaymentScreen {
  ERROR = "ERROR",
  UPDATE_CARD = "UPDATE_CARD"
}

const ManageAddonRetryPayment: React.FC<IManageAddonRetryPayment> = ({ onUpdateCardSuccess, currentSubscription, currentCycle,
  btnBackCallback }) => {
  const [currentScreen, setCurrentScreen] = useState<RetryPaymentScreen>(RetryPaymentScreen.ERROR);
  const [showPriceSimulationModal, setShowPriceSimulationModal] = useState(false);
  const nextBilling = moment(currentSubscription.nextBilling).format("DD MMM YYYY");

  const dispatch = useDispatch<Function>()
  const { addOnPriceSimulation } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer)
  const { checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)

  useEffect(() => {
    // need to reset update payment reducer on on-mount to reset isUpdatingPaymentDetailsSuccess and isUpdatingPaymentDetailsFailure
    //return () => dispatch(resetUpdatePaymentState());
  }, []);

  useEffect(() => {
    dispatch(setAddonFields("isAddingNewAddOnsFailure", false));
  }, [currentScreen]);

  const renderPriceSummaryModal = () => {
    
    return (
      <Modal isOpen={showPriceSimulationModal} title="MANAGE_ADDON_HOW_WAS_CALCULATED" onRequestClose={() => setShowPriceSimulationModal(false)}>
        {!!addOnPriceSimulation &&
          <Text
            content="MANAGE_ADDON_ON_SUMMARY_SUMMARY_TEXT"
            translateWith={{
              amount: addOnPriceSimulation.getTotalAmountFormatted(),
              date: nextBilling,
              regularAmount: addOnPriceSimulation.getPriceSimulationsList().reduce((am, el) => am + el.getRegularAmount() / 100, 0) + extractCurrencyFromPriceFormatted(addOnPriceSimulation.getTotalAmountFormatted()).currency,
              cycleType: getCycleFrequencyText(currentCycle.pricingModel.frequency, currentCycle.pricingModel.frequencyType)
            }}
            noMargin
          />}
      </Modal>
    )
  }

  const renderErrorScreen = () => {
    return (
      <PageWrapper
        title="MANAGE_ADDON_TITLE"
        titleTranslateWith={{ addonLabel: checkoutConfig?.terminologyAddonPlural }}
        titlePosition="left-aligned"
        subTitle="MANAGE_ADDON_SUBTITLE"
        subTitleTranslateWith={{ nextBilling, addonLabel: checkoutConfig?.terminologyAddonPlural }}
        btnText="MANAGE_ADDON_TRY_ANOTHER_CARD"
        btnPosition="bottom"
        btnCallback={() => setCurrentScreen(RetryPaymentScreen.UPDATE_CARD)}
        btnBackCallback={btnBackCallback}>
        <SystemInfo
          title="MANAGE_ADDON_TRY_ANOTHER_CARD_ERROR_TITLE"
          text="MANAGE_ADDON_TRY_ANOTHER_CARD_ERROR_SUBTITLE"
          isShowIcon={false}
          type="warning"
        />
      </PageWrapper>
    )
  }
  const renderRetryPaymentScreen = () => {
    return !!addOnPriceSimulation
      ? (
        <>
          <UpdatePaymentDetails
            title="MANAGE_ADDON_TITLE"
            titleTranslateWith={{ addonLabel: checkoutConfig?.terminologyAddonPlural }}
            titlePosition="left-aligned"
            subTitle="MANAGE_ADDON_SUBTITLE"
            subTitleTranslateWith={{ nextBilling, addonLabel: checkoutConfig?.terminologyAddonPlural }}
            /*btnText={counterpart("MANAGE_ADDON_PAY_AMOUNT", {
              amountFormatted: addOnPriceSimulation.getTotalAmountFormatted(),
              addonLabel: addOnPriceSimulation.getPriceSimulationsList().length > 1 ? checkoutConfig?.terminologyAddonPlural : checkoutConfig?.terminologyAddonSingular
            })}
            shouldTranslateText={false}*/
            bottomContent={
              <Text
                className="manage-addon-list__calculation"
                content="MANAGE_ADDON_HOW_WAS_CALCULATED"
                onClick={() => setShowPriceSimulationModal(true)}
                noMargin
              />

            }
            customSuccessSubtitle="ACCOUNT_MANAGEMENT_UPDATE_PAYMENT_SUCCESS_MANAGE_ADDON"
          />
          {renderPriceSummaryModal()}
        </>
      )
      : <div />
  }

  if (currentScreen === RetryPaymentScreen.ERROR) {
    return renderErrorScreen();
  }

  return renderRetryPaymentScreen();

}

export default ManageAddonRetryPayment