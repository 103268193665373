import React, { useEffect, useState } from "react";
import PageWrapper from "../page-wrapper/PageWrapper";
import { AppState } from "../..";
import { useDispatch, useSelector } from "react-redux";
import InvoiceSummary from "../../components/billing-history/invoice-summary/InvoiceSummary";
import { fetchInvoices } from "../../actions/viewBillingHistoryActions";
import { AccountManagementPermissions } from "../../models/AccountManagement";
import history from "../../utils/history";
import LoadingAnimation from "../../components/ui/loading-animation/LoadingAnimation";
import { showTaxLabel } from "../../utils/planUtils";
import { getPriceDescription } from "../../actions/paymentActions";
import { BillsbyDataAction } from "../../models/BillsbyData";

interface IViewBillingHistory {
  headingText?: string;
  btnSubmitText?: string;
}

const ViewBillingHistory: React.FC<IViewBillingHistory> = ({ btnSubmitText = "UPDATE_PAYMENT_DETAILS_BTN" }) => {
  const globalReducer = useSelector((state: AppState) => state.globalReducer);
  const invoices = useSelector((state: AppState) => state.viewBillingHistoryReducer.invoices);
  const personalDetailsReducer = useSelector((state: AppState) => state.personalDetailsReducer);
  const { currentSubscription } = useSelector((state: AppState) => state.subscriptionReducer);
  const { paymentDescription } = useSelector((state: AppState) => state.paymentReducer);

  const dispatch = useDispatch<Function>();
 
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { companyDomain, branding, billsbyAction, accountManagementPermissions } = globalReducer;
  const { mainProps: { customerUniqueId }, address: { stateObject } } = personalDetailsReducer;


  const fetchData = async () => {
    setIsLoadingData(true);
    if(!currentSubscription) {
      return;
    }

    try {
      await dispatch(fetchInvoices(companyDomain, customerUniqueId as string));
      await dispatch(getPriceDescription(
        currentSubscription.cycleId,
        [],
        [],
        undefined,
        undefined,
        [],
        undefined,
        undefined,
        stateObject ? stateObject.value.CountryIso3 : undefined
      ))
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const goBackCallback = () => {
    if (accountManagementPermissions === AccountManagementPermissions.FULL && history.location.pathname !== "/management") {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }

  return (
    <PageWrapper btnText={btnSubmitText}
      className="update-payment-details"
      btnVisible={false}
      btnBackCallback={goBackCallback}
      title={billsbyAction === BillsbyDataAction.VIEW_BILLING_HISTORY_UNPAID ? "BILLING_HISTORY_VIEW_UNPAID_TITLE" : "BILLING_HISTORY_TITLE"}
      subTitle={billsbyAction === BillsbyDataAction.VIEW_BILLING_HISTORY_UNPAID ? "BILLING_HISTORY_VIEW_UNPAID_SUBTITLE" : "BILLING_HISTORY_SUBTITLE"}
      titlePosition="left-aligned"
      btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION}>
      {!isLoadingData
        ? invoices.getInvoicesList().map((invoice) => (
          <InvoiceSummary hasTax={showTaxLabel(paymentDescription)} key={invoice.getInvoiceNumber()} branding={branding} invoice={invoice}></InvoiceSummary>
        ))
        : <LoadingAnimation />}
    </PageWrapper>
  );
};

export default ViewBillingHistory;
