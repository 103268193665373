import React, { useRef, useEffect, Ref, useState } from "react";
import Translate from "react-translate-component";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import "./PhoneInput.scss";
const { isValidNumber } = (global as any).intlTelInputUtils;

interface IPhoneInput {
  preferredCountries: Array<string>
  defaultCountry: string
  value: string
  isRequired?: boolean
  isRequiredMsg?: string
  dialCode: string
  onPhoneNumberChange: (isValid: boolean, phone: string, countryData: any) => void
  onSelectFlag: (phone: string, countryData: any, formattedPhoneNumber: string, isValid: boolean) => void
  errorLabel?: string
  separateDialCode: boolean,
  [key: string]: any
}


const PhoneInput: React.FC<IPhoneInput> = React.memo(({ isRequired = false, errorLabel = "INVALID_PHONE_NUMBER", dialCode,
  isRequiredMsg = "INVALID_PHONE_NUMBER", onPhoneNumberChange, ...props }) => {

  //useEffect() and useRef() are new react api's available from react 16.7 alpha
  const inputContainer: Ref<any> = useRef(null);
  const [isDirty, setIsDirty] = useState(false);
  /**
   * the second parameter of the useEffect hook is to avoid calling the callback if that reference doesn't change
   * in this case it's acting like a componentDidMount
   */
  useEffect(() => {
    const input: HTMLInputElement | null = (inputContainer.current as HTMLElement).querySelector("input");
    input?.setAttribute("id", "phone-number");
    
    if (input && isRequired) {
      input.setAttribute("required", "");
    }
  }, [inputContainer])

  return (
    <div className="billsby-phone-input" ref={inputContainer}>
      {!props.value && isRequired && isDirty &&
        <Translate
          content={isRequiredMsg}
          component="span"
          className="billsby-phone-input__error"
        />
      }
      {props.value && !isValidNumber(`+${dialCode}${props.value}`)
        ? <Translate
          content={errorLabel}
          component="span"
          className="billsby-phone-input__error"
        />
        : <span />
      }
      <IntlTelInput
        containerClassName="intl-tel-input"
        inputClassName="form-control"
        onPhoneNumberChange={(isValid: boolean, phone: string, countryData: any) => {
          setIsDirty(true);
          onPhoneNumberChange(isValid, phone, countryData);
        }}
        {...props}
      />
    </div>
  )
})

export default PhoneInput;