import React, { useEffect, useState } from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { setConfiguration } from "react-grid-system";
import "../style/global.scss";
import history from "../utils/history";
import SubscriptionRoute from "./subscription-route/SubscriptionRoute";
import NavigationContainer from "./navigation-container/NavigationContainer";
import AccountManagementRoute from "./account-management-route/AccountManagementRoute";
import AccountManagement from "./account-management/AccountManagement";
import UpdatePaymentDetails from "./update-payment-details/UpdatePaymentDetails";
import ChangePlan from "./change-plan/ChangePlan";
import ViewBillingHistory from "./view-billing-history/ViewBillingHistory";
import { BillsbyDataAction } from "../models/BillsbyData";
import ChangePersonalDetails from "./change-personal-details/ChangePersonalDetails";
import ChangeAddress from "./change-address/ChangeAddress";
import Login from "./login/Login";
import LoginRoute from "./login-route/LoginRoute";
import { customPolyfill } from "../utils/customPolifylls";
import Retention from "./retention/Retention";
import ManageAddon from "./add-on/ManageAddon";
import BillsbyDataActionRoute from "../components/redirect-qs/BillsbyDataActionRoute";
import ManageAllowance from "./allowances/ManageAllowance";
import ManageCoupon from "./coupon/ManageCoupon";
import Sca3ds1CreateSubSuccess from "./sca-3ds1/Sca3ds1CreateSubSuccess";
import Sca3ds1UpdatePaymentSuccess from "./sca-3ds1/Sca3ds1UpdatePaymentSuccess";
import UpdateAchDetails from "./update-ach-details/UpdateAchDetails";
import UpdateCardDetails from "./update-card-details/UpdateCardDetails";
import { addScriptDynamically, generateRecaptchaActionToken } from "../utils/commonUtils";
import { GOOGLE_RECAPTCHA_TOKEN, RecaptchaActions } from "../utils/constants";
import RobotError from "./robot-error/RobotError";
import { ConfigConstants } from "../utils/config";
import { useAnalytics } from "../utils/custom-hooks";


customPolyfill();
setConfiguration({ gutterWidth: 10 });

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    addScriptDynamically("https://www.google.com/recaptcha/enterprise.js", true, { render: ConfigConstants.reCaptchaEnterpriseKey },
      () => {
        generateRecaptchaActionToken(RecaptchaActions.CHECKOUT_OPEN).then(() => setIsLoading(false));
      });
    return () => { sessionStorage.removeItem(GOOGLE_RECAPTCHA_TOKEN) };
  }, [])

  useAnalytics();

  if (isLoading) {
    return null
  }

  return (
    <Router history={history}>
      <Switch>
        <BillsbyDataActionRoute exact path="/" />

        <LoginRoute path="/login">
          <Route exact path="/login" component={Login} />
        </LoginRoute>

        <AccountManagementRoute path="/management">
          <Route exact path="/management" component={AccountManagement} />
          <Route exact path={`/management/${BillsbyDataAction.CANCEL_PLAN}`} component={Retention} />
          <Route exact path={`/management/${BillsbyDataAction.UPDATE_PAYMENT_DETAILS}`} component={UpdatePaymentDetails} />
          <Route exact path={`/management/${BillsbyDataAction.CHANGE_PLAN}`} component={ChangePlan} />
          <Route exact path={`/management/${BillsbyDataAction.CHANGE_PERSONAL_DETAILS}`} component={ChangePersonalDetails} />
          <Route exact path={`/management/${BillsbyDataAction.CHANGE_ADDRESS}`} component={ChangeAddress} />
          <Route exact path={`/management/${BillsbyDataAction.MANAGE_ADDONS}`} component={ManageAddon} />
          <Route exact path={`/management/${BillsbyDataAction.MANAGE_ALLOWANCES}`} component={ManageAllowance} />
          <Route exact path={`/management/${BillsbyDataAction.VIEW_BILLING_HISTORY}`} component={ViewBillingHistory} />
          <Route exact path={`/management/${BillsbyDataAction.VIEW_BILLING_HISTORY_UNPAID}`} component={ViewBillingHistory} />
          <Route exact path={`/management/${BillsbyDataAction.MANAGE_COUPONS}`} component={ManageCoupon} />

        </AccountManagementRoute>

        <SubscriptionRoute path="/subscription">
          <Route exact path='/subscription' component={NavigationContainer} />
          <Route exact path='/subscription/3ds1' component={Sca3ds1CreateSubSuccess} />
        </SubscriptionRoute>

        <SubscriptionRoute path="/standalone/subscription">
          <Route exact path='/standalone/subscription' component={NavigationContainer} />
          <Route exact path='/standalone/subscription/3ds1' component={Sca3ds1CreateSubSuccess} />
        </SubscriptionRoute>

        <Route exact path='/updatepaymentdetails/3ds1' component={Sca3ds1UpdatePaymentSuccess} />
        <Route exact path='/suspicious-behavior' component={RobotError} />
        <Route exact path='/forbidden' component={RobotError} />

        <AccountManagementRoute path="/standalone/payment">
          <Route
            exact
            path={"/standalone/payment/providedetails"}
            render={() =>
              <UpdateCardDetails
                title="PROVIDE_PAYMENT_STANDALONE_TITLE"
                subTitle="PROVIDE_PAYMENT_STANDALONE_SUBTITLE"
                btnText="PROVIDE_PAYMENT_STANDALONE_BTN_TEXT"
                showCloseIcon={false}
              />}
          />
          <Route
            exact
            path={"/standalone/payment/providedetails-ach"}
            component={UpdateAchDetails}
          />
        </AccountManagementRoute>


        <Redirect exact from="/" to={{ ...history.location, pathname: "/subscription" }} />
      </Switch>
    </Router>
  )
}

export default React.memo(App);