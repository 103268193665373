import React from "react";
import "./NumberPicker.scss";
import Icon from "../icon/Icon";

interface INumberPicker {
  onChange?: (value: number) => any,
  className?: string,
  value?: number,
  min?: number,
  max?: number,
  minLock?: boolean,
  maxLock?: boolean
  onMinus: () => void,
  onPlus: () => void,
  isInputDisabled?: boolean
}

const NumberPicker: React.FC<INumberPicker> = React.memo(({ min = 0, max = 1000000, value = 0, minLock, maxLock, onMinus = () => { }, isInputDisabled = false, onPlus = () => { }, onChange = (evt: any) => { }, className = "", ...rest }) => {
  const _value = (value === undefined || value as any === "") ? 0 : value;
  const _onChange = (evt: any) => {
    let value = +evt.target.value;

    if (!onChange || isInputDisabled) {
      return;
    }

    if (value < min) {
      value = min
    }

    if (value > max) {
      value = max
    }

    onChange(value);
  }

  const _onMinus = () => {
    if (value < min) {
      return;
    }

    onMinus && onMinus()
  }

  const _onPlus = () => {
    if (value > max) {
      return;
    }
    onPlus && onPlus()
  }

  return (
    <div className={`number-input number-input__container ${className}`}>
      <Icon
        className={`number-input__btn number-input__btn-minus ${minLock || min === value ? "disabled" : ""} ${minLock ? "lock" : ""}`}
        onClick={_onMinus}
        component="button"
        icon={`${minLock || min === value ? "fas fa-lock-alt" : "fas fa-minus"}`}
        disabled={_value === min || minLock}
      />
      <input type="number" pattern="[0-9]*" disabled={isInputDisabled} className={`number-input__field ${isInputDisabled ? "number-input__field--disabled" : ""}`} value={value.toString().replace(/^0+/, "") || 0} onChange={_onChange} {...rest} />
      <Icon
        className={`number-input__btn number-input__btn-plus ${maxLock || max === value ? "disabled" : ""} ${maxLock ? "lock" : ""}`}
        onClick={_onPlus}
        component="button"
        icon={`${maxLock || max === value ? "fas fa-lock-alt" : "fas fa-plus"}`}
        disabled={_value === max || maxLock}
      />
    </div>
  )
})

export default NumberPicker;