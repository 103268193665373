import React from "react";
import Text from "../text/Text";
import { extractCurrencyFromPriceFormatted } from "../../../utils/planUtils";
import { IAppliedCoupon } from "../../../models/DiscountCoupon";
import { Coupon, DiscountType, Discount, DeductionType } from "../../../utils/grpc/generated/Billsby.Protos/billing/public/coupons/coupons_pb";
import { getMomentFrequencyTypeParam } from "../../../utils/commonUtils";
import moment from "moment";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { SubscriptionDiscount } from "../../../utils/grpc/generated/Billsby.Protos/billing/public/subscription/discount/discount_pb";
import { GetInvoiceSimulationResponse } from "../../../utils/grpc/generated/Billsby.Protos/billing/public/invoice/invoice_pb";
import { ICheckoutConfig } from "../../../models/BillsbyData";

interface IPaymentSummaryCoupons {
  currencyCode: string;
  appliedCoupons?: Array<IAppliedCoupon>;
  paymentDescription?: GetInvoiceSimulationResponse | null
  onDeleteCoupon?: (coupon: IAppliedCoupon) => void;
  className?: string,
  checkoutConfig?: ICheckoutConfig
}

const PaymentSummaryCoupons: React.FC<IPaymentSummaryCoupons> = React.memo(({paymentDescription, appliedCoupons, currencyCode, className, onDeleteCoupon, checkoutConfig }) => {
  const dateFormat = "DD MMM YYYY";

  if (!appliedCoupons || appliedCoupons.length === 0) {
    return null;
  }

  const getFormattedDate = (date: Timestamp | undefined) => {
    if(!date) {
      return;
    }
    return  moment.unix((date as Timestamp).getSeconds()).format(dateFormat)
  }

  const getDiscountDetails = (ac: IAppliedCoupon) => {

    if(ac.subscriptionDiscount && !ac.subscriptionDiscount.getCouponValue()) {
      if(ac.subscriptionDiscount.hasExpiresOn()) {
        return "APPLY_COUPON_DETAILS_COUPONLESS_FIXED"
      }

      return "APPLY_COUPON_DETAILS_COUPONLESS_LIFETIME"
    }


    if (ac.discount.getDeductionType() === DeductionType.PLAN_ONLY || ac.discount.getDeductionType() === DeductionType.ENTIRE_SUBSCRIPTION) {
      if (ac.coupon.getIsLifeTime()) {
        return "APPLY_COUPON_DETAILS_LIFETIME";
      }

      return "APPLY_COUPON_DETAILS_FIXED";
    }

    return "";
  };

  return (
    <>
      {appliedCoupons.map((ac, idx) => {
        let discount = "";

        const isCouponLess = ac.subscriptionDiscount && !ac.subscriptionDiscount.getCouponValue();
        const isSubscriptionDiscount = Boolean(ac.subscriptionDiscount);

        const fixedTerm = !isCouponLess && ac.coupon.hasFixedTerm() ? ac.coupon.getFixedTerm() as Coupon.FixedTermDiscountLength : null;
        const expiryDate = !isCouponLess && ac.subscriptionDiscount && !ac.coupon.getIsLifeTime() ? getFormattedDate(ac.subscriptionDiscount.getExpiresOn()) : ""
        const currentDateWithFixedTerm = fixedTerm
          ? moment()
              .add(fixedTerm.getFrequency(), getMomentFrequencyTypeParam(fixedTerm.getFrequencyType()))
              .format(dateFormat)
          : "";

        const expiryDates = {
          coupon: ac.subscriptionDiscount ? expiryDate : currentDateWithFixedTerm, 
          couponless: isCouponLess ? getFormattedDate((ac.subscriptionDiscount as SubscriptionDiscount).getExpiresOn()) : ""
        }

        if (ac.discount.getDiscountType() === DiscountType.MONETARY) {
          const price = (ac.discount.getMonetary() as Discount.MonetaryModel).getPricesList().find((pl) => pl.getCurrencyCode() === currencyCode);

          if(!price) {
            return;
          }

          discount = `${extractCurrencyFromPriceFormatted(paymentDescription ? paymentDescription.getFirstInvoiceTotalPrice() : "$").currency}${price.getValue() / 100}`;
        }

        if (ac.discount.getDiscountType() === DiscountType.PERCENTAGE) {
          const percentage = (ac.discount.getPercentage() as Discount.PercentageModel).getPercentage();
          discount = `${percentage / 100}%`;
        }


        return (
          <div className={`payment-summary__coupon ${className}`} key={!isCouponLess ? ac.coupon.getCouponId() : idx}>
            <Text content={getDiscountDetails(ac)} size="normal" component="span" noMargin translateWith={{ couponName: !isCouponLess ? ac.coupon.getName() : "", discount, endDate: isCouponLess ? expiryDates.couponless : expiryDates.coupon, discountLabel: checkoutConfig?.terminologyDiscountSingular, couponLabel: checkoutConfig?.terminologyCouponSingular  }}></Text>
            {onDeleteCoupon && (
              <>
                <Text content=" - " size="normal" shouldTranslate={false} component="span" noMargin></Text>
                <Text className="payment-summary__coupon__remove" size="normal" content="APPLY_COUPON_REMOVE" component="span" noMargin onClick={() => onDeleteCoupon(ac)}></Text>
              </>
            )}
          </div>
        );
      })}
    </>
  );
});

export default PaymentSummaryCoupons;
