import React from "react";
import "./Login.scss";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import { LoginScreen } from "../../models/Login";
import LoginEmail from "../../components/login/login-email/LoginEmail";
import LoginPasscode from "../../components/login/login-passcode/LoginPasscode";
import LoginSubscriptions from "../../components/login/login-subscriptions/LoginSubscriptions";

const Login: React.FC = () => {
  const currentScreen = useSelector((state: AppState) => state.loginReducer.currentScreen);
  
  switch (currentScreen) {
    case LoginScreen.EMAIL:
      return <LoginEmail />
    case LoginScreen.PASS_CODE:
      return <LoginPasscode />
    case LoginScreen.SELECT_SUBSCRIPTION:
      return <LoginSubscriptions />
    default:
      return <div />
  }
}

export default Login;