import React, { useEffect } from "react"
import { IAllowance, IAllowancePricingModel } from "../../../../models/AddOnAllowance";
import { IPlanWithCycles, ICycle } from "../../../../models/Product";
import { IBranding } from "../../../../models/Branding";
import { ICheckoutConfig } from "../../../../models/BillsbyData";
import { calculateAddOnPrice } from "../../../../utils/planUtils";
import Panel from "../../panel/Panel";
import Text from "../../text/Text";
import "./SubscriptionAddOnsAllowances.scss";
import AllowancePriceText from "../../../allowances/allowance-price-text/AllowancePriceText";
import FormLabel from "../../form-label/FormLabel";

interface ISubscriptionAllowanceItem {
  allowance: IAllowance;
  plan: IPlanWithCycles;
  cycle: ICycle;
  branding: IBranding;
  checkoutConfig?: ICheckoutConfig;
  updateAllowance: (allowanceId: number, pricingModels: IAllowancePricingModel[], units?: number) => void;
  isLocked?: boolean;
  image?: string
}

const SubscriptionAllowanceItem: React.FC<ISubscriptionAllowanceItem> = ({
  allowance,
  plan,
  cycle,
  branding,
  checkoutConfig,
  updateAllowance,
  image,
  isLocked = false }) => {


  useEffect(() => {
    if (Number(allowance.units)) {
      // in case of editing an addon we calculate the price immediately
      onUnitsChange(allowance, Number(allowance.units));
    } else {
      const initialUnit = allowance.isForced ? 1 : 0;
      onUnitsChange(allowance, initialUnit);
    }
  }, []);

  const onUnitsChange = (allowance: IAllowance, units: number) => {
    if (allowance.isForced) {
      units = units < 1 ? 1 : units;
    } else {
      units = units < 0 ? 0 : units;
    }

    const pricingModels: Array<IAllowancePricingModel> = allowance.pricingModels.map(pricingModel => {
      if (pricingModel.frequency === cycle.pricingModel.frequency && pricingModel.frequencyType === cycle.pricingModel.frequencyType) {
        return calculateAddOnPrice(cycle, pricingModel, units, allowance.pricingModelType) as IAllowancePricingModel;
      }
      return pricingModel;
    });

    updateAllowance(allowance.id, pricingModels, units);
  };

  if (!plan || !cycle || !checkoutConfig) {
    return null;
  }

  return (
    <Panel id="subscription-allowance-item__container" image={image} imageLayout="box" className="subscription-addon-item__container" key={`add-on-${allowance.id}`}>
      {!allowance.isForced && !isLocked && <i className="far fa-times subscription-addon-item__remove"
        onClick={() => onUnitsChange(allowance, 0)}></i>}
      <div className="payment-summary__name__container">
        <FormLabel content={allowance.displayName || allowance.name} shouldTranslate={false} weight="bold" noMargin />
      </div>
      <div className="payment-summary__details">
        <div className="subscription-addon-item">
          <div className={"subscription-addon-item__price-text"}>
            <AllowancePriceText
              allowance={allowance}
              plan={plan}
              cycle={cycle}
              checkoutConfig={checkoutConfig}
              branding={branding}
              showPreviewPriceForComplexType={true}
              viewPriceModalBtnText={"ALLOWANCE_PRICE_TEXT_VIEW_PRICES"}
            />
            <Text content="ALLOWANCE_PRICE_INFO_TEXT" translateWith={{ allowanceLabel: checkoutConfig?.terminologyAllowanceSingular }} className="subscription-addon-item__sub-text" noMargin></Text>
          </div>
        </div>
      </div>
    </Panel>
  )
}

export default SubscriptionAllowanceItem;