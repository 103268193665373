import React from "react";
import "./ContainerError.scss";
import ErrorMessage from "../../components/ui/error-message/ErrorMessage";

interface IContainerError {
  isVisibleMessage?: boolean,
  title: string,
  shouldTranslateTitle?: boolean,
  subTitle: JSX.Element
}

const ContainerError: React.FC<IContainerError> = ({ isVisibleMessage = true, title, shouldTranslateTitle = true, subTitle }) => {
  return (
    <div className="container-error">
      {[1, 2, 3, 4, 5, 6].map(idx => <div key={idx} className={`container-error__rectangle blink__me container-error__rectangle--${idx}`} />)}
      <ErrorMessage
        className="container-error__message"
        title="ERROR_INVOKING_BILLSBY"
        shouldTranslateTitle={shouldTranslateTitle}
        isVisible={isVisibleMessage}
        subTitle={subTitle}
      />
    </div>
  )
}

export default ContainerError;