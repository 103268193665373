import React, { useEffect, useMemo } from "react";
import FormLabel from "../../ui/form-label/FormLabel";
import SeeMoreText from "../../ui/see-more-text/SeeMoreText";
import { calculateAddOnPrice, isAddOn } from "../../../utils/planUtils";
import { IAddOnPricingModel, IAddOn, AddOnPricingModelType } from "../../../models/AddOnAllowance";
import { ICheckoutConfig } from "../../../models/BillsbyData";
import { ICycle, IPlanWithCycles } from "../../../models/Product";
import AddOnPriceText from "../addon-price-text/AddonPriceText";
import { IBranding } from "../../../models/Branding";
import "./AddOnInput.scss";
import NumberPicker from "../../ui/number-picker/NumberPicker";
import Selector from "../../ui/selector/Selector";
import { divideNumberBy100 } from "../../../utils/commonUtils";
import SystemInfo from "../../ui/system-info/SystemInfo";
import { Col, Row } from "react-grid-system";
import CollapsiblePanel from "../../ui/collapsible-panel/CollapsiblePanel";
import { isMobileOnly } from "react-device-detect";

interface IAddOnInput {
  addOn: IAddOn;
  plan: IPlanWithCycles;
  cycle: ICycle;
  branding: IBranding;
  checkoutConfig: ICheckoutConfig;
  updateAddons: (addOnId: number, pricingModels: IAddOnPricingModel[], units?: number) => void;
  isLocked?: boolean,
  // this flag turns to true after removing an addon from the account management
  willBeDeleted?: boolean,
  // this number of units represents the future number of units after changing an addon in the account management
  unitsNext?: number,
  nextBillingDate?: string,
  image?: string
}

const AddOnInput: React.FC<IAddOnInput> = ({
  addOn,
  plan,
  cycle,
  branding,
  checkoutConfig,
  updateAddons,
  isLocked = false,
  willBeDeleted = false,
  unitsNext,
  nextBillingDate = "",
  image
}) => {


  useEffect(() => {
    if (Number(addOn.units)) {
      // in case of editing an addon we calculate the price immediately
      onUnitsChange(addOn, Number(addOn.units));
    } else {
      const initialUnit = addOn.isForced ? 1 : 0;
      onUnitsChange(addOn, initialUnit);
    }
  }, []);

  const getMax = useMemo(() => {
    if (addOn.pricingModelType !== AddOnPricingModelType.AddonFlatFee && addOn.pricingModelType !== AddOnPricingModelType.AddonPerUnit) {
      const filteredPriceModel = addOn.pricingModels.filter(pm =>
        pm.currency.isoCode === plan.currency &&
        pm.frequency === cycle.pricingModel.frequency &&
        pm.frequencyType === cycle.pricingModel.frequencyType
      )

      return Math.max(...filteredPriceModel[0].tiers.map(t => t.finish))
    }
    return undefined;
  }, [addOn]);

  const onUnitsChange = (addOn: IAddOn, units: number) => {
    if (addOn.isForced) {
      units = units < 1 ? 1 : units;
    } else {
      units = units < 0 ? 0 : units;
    }

    if (!!getMax) {
      units = units > getMax ? getMax : units;
    }

    if (!cycle) return;

    const pricingModels: Array<IAddOnPricingModel> = addOn.pricingModels.map(pricingModel => {
      if (pricingModel.frequency === cycle.pricingModel.frequency && pricingModel.frequencyType === cycle.pricingModel.frequencyType) {
        return calculateAddOnPrice(cycle, pricingModel, units, addOn.pricingModelType) as IAddOnPricingModel;
      }
      return pricingModel;
    });

    updateAddons(addOn.id, pricingModels, units);
  };

  const getFlatFee = () => {
    if (addOn.pricingModelType === AddOnPricingModelType.AddonFlatFee) {
      const priceModel = (addOn.pricingModels as Array<IAddOnPricingModel>).find(
        (i) => i.frequency === cycle.pricingModel.frequency && i.frequencyType === cycle.pricingModel.frequencyType && i.currency.isoCode === cycle.currency
      );
      if (!!priceModel) {
        const currencySymbol = priceModel.currency.symbol;
        const price = divideNumberBy100(priceModel.flatFeePrice);
        return currencySymbol.replace("_", `${price}`);
      }
    }
    return "";
  }

  const renderNumberPicker = () => {
    return (
      <Col xs={12} sm={4} className={`addon-unit-input__add-on-list ${(isLocked || willBeDeleted) ? "addon-unit-input--locked" : ""}`}>
        {addOn.pricingModelType !== AddOnPricingModelType.AddonFlatFee
          ?
          <div className="addon-unit-input__number-picker-container">
            <NumberPicker
              className="addon-unit-input__number-picker"
              value={addOn.units}
              min={addOn.isForced ? 1 : 0}
              max={getMax}
              maxLock={getMax === addOn.units}
              minLock={addOn.isForced ? addOn.units === 1 : addOn.units === 0}
              onChange={(value: number) => onUnitsChange(addOn, value)}
              onMinus={() => !!addOn.units ? onUnitsChange(addOn, +addOn.units - 1) : {}}
              onPlus={() => addOn.units === undefined || addOn.units === null ? {} : onUnitsChange(addOn, +addOn.units + 1)} />
            {!!addOn.units && addOn.units > 0 && (
              <div className="addon-unit-input__price-text">
                <AddOnPriceText
                  addon={addOn}
                  showPriceOnly={true}
                  plan={plan}
                  cycle={cycle}
                  checkoutConfig={checkoutConfig}
                  branding={branding}
                  showPreviewPriceForComplexType={true}
                  viewPriceModalBtnText={isAddOn(addOn) ? "ADD_ON_INPUT_PRICE_WHY" : "SELECT_ALLOWANCES_VIEW_OVERAGE"}
                />
              </div>
            )}
          </div>
          : <div className="addon-unit-input__selector-container">
            <Selector
              text={getFlatFee()}
              isLock={addOn.isForced}
              selected={!!addOn.units}
              onClick={() => !addOn.isForced ? onUnitsChange(addOn, !!addOn.units ? 0 : 1) : {}}
            >
            </Selector>
          </div>
        }
      </Col>
    )
  }


  return (
    <>
      <SystemInfo
        className="addon-unit-input__is-updated"
        title={!!unitsNext && unitsNext > 0 ? "MANAGE_ADDON_HAS_BEEN_CHANGED" : "MANAGE_ADDON_WILL_BE_DELETED"}
        translateWith={{ nextBilling: nextBillingDate, addOnUnitLabel: addOn.singleUnitName, addOnUnitsNext: unitsNext, subscriptionLabel: checkoutConfig.terminologySubscriptionSingular, addonLabel: checkoutConfig?.terminologyAddonSingular }}
        type="warning"
        isVisible={willBeDeleted || !!unitsNext}
      />
      <CollapsiblePanel
        id="addon-unit-input"
        isClickable
        isModal
        className={`addon-unit-input ${willBeDeleted || !!unitsNext ? "addon-unit-input--updated" : ""}`}
        key={`add-on-${addOn.id}`}
        imageLayout="dynamic"
        isFloating={false}
        isExpanded
        image={image}
        expandableContent={
          isMobileOnly ? renderNumberPicker() : undefined
        }
      >
        <Row align="center">
          <Col xs={12} sm={8}>
            <FormLabel content={addOn.displayName || addOn.name} shouldTranslate={false} weight="bold" noMargin />
            <SeeMoreText content={addOn.description} shouldTranslateContent={false} />
          </Col>
          {!isMobileOnly && renderNumberPicker()}
        </Row>
      </CollapsiblePanel>
    </>
  );
};

export default AddOnInput;
