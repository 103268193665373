import React from "react";
import logo from "../../images/billsby-logo.svg";
import Text from "../../components/ui/text/Text";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import "./Footer.scss";

interface IFooter {
  text?: string,
  shouldTranslate?: boolean,
  isVisible?: boolean
}

const Footer: React.FC<IFooter> = ({ text = "FOOTER_SECURED_TEXT", shouldTranslate = true, isVisible = true }) => {
  const { checkoutConfig, companyDomain } = useSelector((state: AppState) => state.globalReducer);

  if (!isVisible || !checkoutConfig) {
    return null
  }

  return (
    <div
      className="footer"
      // onClick={() => window.open(`https://www.billsby.com/powered?companyname=${checkoutConfig.companyName}&utm_campaign=brandedlinks&utm_medium=checkout&utm_source=${companyDomain}`, "_self")}
      onClick={() => window.open(`https://www.billsby.com?utm_source=billsby_checkout&utm_medium=referral&utm_content=${companyDomain}`, "_blank")}
    >
      {/*<i className="fas fa-lock" />*/}
      <Text className="footer__text1" content={text.capitalize()} shouldTranslate={shouldTranslate} noMargin />
      <a><img src={logo} alt="Billsby" className="footer__logo" /></a>
    </div>
  )
}

export default Footer;