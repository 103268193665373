import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./reducers";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import callAPIMiddleware from "./middlewares/callApiMiddleware";
import { registerTranslations } from "./utils/languages/registerTranslations";
import { ConfigConstants } from "./utils/config";
import { preLoadBillsbyCustomerData, detectCheckoutViewMode, loadAccountMangementPermissions } from "./actions/globalActions";
import { ISelectPlanReducer } from "./reducers/selectPlanReducer";
import { IGlobalReducer } from "./reducers/globalReducer";
import { IPersonalDetailsReducerState } from "./reducers/personalDetailsReducer";
import { IPaymentReducer } from "./reducers/paymentReducer";
import { IUpdatePaymentReducer } from "./reducers/updatePaymentReducer";
import { ISubscriptionReducer } from "./reducers/subscriptionReducer";
import { IChangePlanReducer } from "./reducers/changePlanReducer";
import { IChangePersonalDetailsReducer } from "./reducers/changePersonalDetailsReducer";
import AppWrapper from "./containers/container-standalone-page/AppWrapper";
import { ILoginReducer } from "./reducers/loginReducer";
import { IChangeAddressReducer } from "./reducers/changeAddressReducer";
import { loadAppInsights } from "./utils/app-insights";
import { IRetentionReducer } from "./reducers/retentionReducer";
import { IAddOnReducer } from "./reducers/addOnReducer";
import { IViewBillingHistoryReducer } from "./reducers/viewBillingHistoryReducer";
import { IDiscountCouponReducer } from "./reducers/discountCouponReducer";
import { IAddDiscountOfferReducer } from "./reducers/addDiscountOfferReducer";
import { addScriptDynamically } from "./utils/commonUtils";
import { IACHReducer } from "./reducers/achReducer";

loadAppInsights();
registerTranslations();


const composeEnhancers =
  typeof window === "object" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk, callAPIMiddleware)
)

export const reduxStore = createStore(rootReducer, enhancer);


if(ConfigConstants.reactAppEnvironment !== "Production") {
  //on dev environments we may need to quickly print in the console the content of the redux store
  (window as any).reduxStore = reduxStore
}

export interface AppState {
  selectPlanReducer: ISelectPlanReducer,
  globalReducer: IGlobalReducer,
  personalDetailsReducer: IPersonalDetailsReducerState,
  paymentReducer: IPaymentReducer,
  updatePaymentReducer: IUpdatePaymentReducer,
  subscriptionReducer: ISubscriptionReducer,
  changePlanReducer: IChangePlanReducer,
  changePersonalDetailsReducer: IChangePersonalDetailsReducer,
  loginReducer: ILoginReducer,
  changeAddressReducer: IChangeAddressReducer,
  retentionReducer: IRetentionReducer,
  addOnReducer: IAddOnReducer,
  viewBillingHistoryReducer: IViewBillingHistoryReducer,
  discountCouponReducer: IDiscountCouponReducer,
  addDiscountOfferReducer: IAddDiscountOfferReducer,
  achReducer: IACHReducer
}

const isTestRunning = process.env.JEST_WORKER_ID !== undefined;
if (!isTestRunning) {
  reduxStore.dispatch<any>(preLoadBillsbyCustomerData());
  reduxStore.dispatch<any>(loadAccountMangementPermissions());
  reduxStore.dispatch<any>(detectCheckoutViewMode());
  //add Google API service
  addScriptDynamically("https://maps.googleapis.com/maps/api/js", true, { key: ConfigConstants.googleFontApiKey, libraries: "places" });

  ReactDOM.render(
    <Provider store={reduxStore}>
      <AppWrapper>
        <App />
      </AppWrapper>
    </Provider>,
    document.getElementById("root") || document.createElement("div") // for testing purposes
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
