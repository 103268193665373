import React, { useEffect, useLayoutEffect, useState } from "react";
import { ILoginReducer } from "../../../reducers/loginReducer";
import PageWrapper from "../../../containers/page-wrapper/PageWrapper";
import Panel from "../../ui/panel/Panel";
import FormLabel from "../../ui/form-label/FormLabel";
import Input from "../../ui/input/Input";
import { setLoginField, loginEmail } from "../../../actions/loginActions";
import { AppState } from "../../..";
import { useDispatch, useSelector } from "react-redux";
import { LoginScreen } from "../../../models/Login";
import { validateEmail } from "../../../utils/commonUtils";
import { IGlobalReducer } from "../../../reducers/globalReducer";
import { IPersonalDetailsReducerState } from "../../../reducers/personalDetailsReducer";
import history from "../../../utils/history";
import SystemInfo from "../../ui/system-info/SystemInfo";


const LoginEmail: React.FC = () => {
  const { email, isloginEmailRequest, isLoginEmailSuccess, isLoginEmailFailure, validateEmailResponse } = useSelector<AppState, ILoginReducer>(state => state.loginReducer);
  const { mainProps } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { companyDomain, billsbyAction } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const dispatch = useDispatch<Function>()

  const [isLoading, setIsLoading] = useState(false);

  const goToAccoutManagement = () => {
    // if data-billsby-action is passed we need to redirect to the specific action screen otherwise the management menu
    let pathname = `/management${billsbyAction ? `/${billsbyAction}` : ""}`;
    history.push({ pathname, search: history.location.search });
  }

  useLayoutEffect(() => {
    // if customerUniqueId and subscriptionUniqueId are preloaded we skip these screens
    /*if (mainProps.customerUniqueId && mainProps.subscriptionUniqueId) {
      goToAccoutManagement();
    }
    else*/ 
    if (mainProps.customerUniqueId) {
      dispatch(setLoginField("currentScreen", LoginScreen.SELECT_SUBSCRIPTION));
    } else {
      dispatch(setLoginField("isLoadingScreen", false));
    }
  }, []);

  useEffect(() => {
    if (isLoginEmailSuccess && validateEmailResponse && validateEmailResponse.getIsSuccess()) {
      dispatch(setLoginField("currentScreen", LoginScreen.PASS_CODE));
    }
  }, [isLoginEmailSuccess]);


  return (
    <PageWrapper
      title="LOGIN_HEADING"
      titlePosition="left-aligned"
      className="login"
      isLoading={isLoading}
      btnDisabled={!validateEmail(email)}
      btnPosition="bottom"
      btnCallback={() => {
        setIsLoading(true);
        dispatch(loginEmail(email, companyDomain))
          .finally(() => setIsLoading(false));
      }}>
      <>
        <SystemInfo
          title="LOGIN_EMAIL_ERRORR_TITLE"
          text="LOGIN_EMAIL_ERRORR_SUBTITLE"
          isShowIcon={false}
          type="warning"
          isVisible={isLoginEmailFailure || (isLoginEmailSuccess && !!validateEmailResponse && !validateEmailResponse.getIsSuccess())}
        />

        <Panel id="login-email">
          <FormLabel content="LOGIN_EMAIL" />
          <Input
            id="email"
            placeholder="james.stevenson@myemail.com"
            isError={!!email && !validateEmail(email)}
            value={email}
            onChange={(evt: any) => dispatch(setLoginField("email", evt.target.value))}
          />
        </Panel>
      </>
    </PageWrapper>
  )
}

export default LoginEmail