import React, { useEffect, useState } from "react";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import Text from "../ui/text/Text";
import Panel from "../ui/panel/Panel";
import counterpart from "counterpart";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import { setRetentionField, getTimeRemaining } from "../../actions/retentionActions";
import { RetentionScreen } from "../../models/Retention";
import history from "../../utils/history";
import "./Retention.scss";
import { IRetentionReducer } from "../../reducers/retentionReducer";
import { getNextRetentionStepAvailable } from "../../utils/commonUtils";
import { AccountManagementPermissions } from "../../models/AccountManagement";
import { BillsbyDataAction } from "../../models/BillsbyData";
import { sendEvent, CROSS_DOMAIN_EVENT } from "../../utils/crossDomainEvents";


const RetentionTimeRemaining: React.FC = () => {
  
  const { companyDomain, checkoutConfig, billsbyAction, accountManagementPermissions } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer)
  const { timeRemaining, retentionStepsAvailability } = useSelector<AppState, IRetentionReducer>(state => state.retentionReducer);
  const { currentSubscriptionDetails } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const { mainProps: { subscriptionUniqueId } } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const dispatch = useDispatch<Function>()

  const [previousScreen, setPreviousScreen] = useState<RetentionScreen | null>(null);

  useEffect(() => {
    dispatch(getTimeRemaining(companyDomain, String(subscriptionUniqueId)));
  }, []);

  useEffect(() => {
    if (retentionStepsAvailability) {
      const previousScreen = getNextRetentionStepAvailable(retentionStepsAvailability, "backward", RetentionScreen.timeRemaining);
      setPreviousScreen(previousScreen);
    }
  }, [retentionStepsAvailability]);

  if (!currentSubscriptionDetails || !timeRemaining || !retentionStepsAvailability) {
    return null;
  }

  const goBackCallback = () => {
    if (previousScreen) {
      dispatch(setRetentionField("currentScreen", previousScreen))
    }
    else {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }

  return (
    <PageWrapper 
      btnText="RETENTION_KEEP_MY_PLAN" 
      btnTextTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
      btnCallback={() => billsbyAction === BillsbyDataAction.CANCEL_PLAN ? sendEvent(CROSS_DOMAIN_EVENT.CLOSE) : history.goBack()}
      btnPosition="bottom"
      title="CANCEL_SUBSCRIPTION_TITLE"
      titleTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
      btnBackCallback={goBackCallback}
      btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION}
      bottomStickyContent={
        <a
          href="javascript:void(0)"
          className="retention__link"
          onClick={() => dispatch(setRetentionField("currentScreen", getNextRetentionStepAvailable(retentionStepsAvailability, "forward", RetentionScreen.timeRemaining)))} >
          {counterpart("RETENTION_CONTINUE_TOCANCEL")}
        </a>
      }>
      <Panel id="retention-time-remaining-text">
        <Text content={timeRemaining.getText()} shouldTranslate={false} noMargin />
      </Panel>

      <Panel id="retention-time-remaining-days"  className="retention__time-remaining">
        <div>{timeRemaining.getRemainingDays()}</div>
        <Text content="RETENTION_STEP3_DAYS_REMAINING_LABEL" noMargin />
      </Panel>
    </PageWrapper>
  )
}

export default RetentionTimeRemaining