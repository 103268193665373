import React from "react";
import "./AppWrapper.scss";
import { useSelector } from "react-redux";
import { AppState } from "../..";
import { CheckoutViewMode } from "../../models/BillsbyData";


const AppWrapper: React.FC = ({ children }) => {
  const checkoutViewMode = useSelector((state: AppState) => state.globalReducer.checkoutViewMode);
  return (
    /*this component acts as a singleton and renders only once */
    <>
     <div className={checkoutViewMode === CheckoutViewMode.STANDALONE ? "app-wrapper--standalone__background" : ""} />
      <div className={checkoutViewMode === CheckoutViewMode.STANDALONE ? "app-wrapper--standalone" : "app-wrapper"}>
        {children}
      </div>
      <div className="app-wrapper__react-select-portal"></div>
    </>
  )
}

export default AppWrapper;