import { ISubscription } from "../models/Subscription";
import { IACHReducer } from "../reducers/achReducer";
import { ConfigConstants } from "../utils/config";
import { CustomerServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/CustomerServiceClientPb";
import { CreateAchMandateExistingCustomerRequest, CreateAchMandateNewCustomerRequest, StartCheckoutAchMicroDepositRequest, UpdateAchPaymentMethodRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/customer_pb";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { makeActionCreator, makeGenericActionCreator } from "../utils/reduxUtils";

export const SET_ACH_FIELD = "SET_ACH_FIELD";
export const setAchField = makeGenericActionCreator<keyof IACHReducer>(SET_ACH_FIELD);

export const FETCH_ACH_CLIENT_SECRET_REQUEST = "FETCH_ACH_CLIENT_SECRET_REQUEST";
export const FETCH_ACH_CLIENT_SECRET_SUCCESS = "FETCH_ACH_CLIENT_SECRET_SUCCESS";
export const FETCH_ACH_CLIENT_SECRET_FAILURE = "FETCH_ACH_CLIENT_SECRET_FAILURE";

export const UPDATE_ACH_PAYMENT_REQUEST = "UPDATE_ACH_PAYMENT_REQUEST";
export const UPDATE_ACH_PAYMENT_SUCCESS = "UPDATE_ACH_PAYMENT_SUCCESS";
export const UPDATE_ACH_PAYMENT_FAILURE = "UPDATE_ACH_PAYMENT_FAILURE";

export const START_ACH_MICRODEPOSIT_REQUEST = "START_ACH_MICRODEPOSIT_REQUEST";
export const START_ACH_MICRODEPOSIT_SUCCESS = "START_ACH_MICRODEPOSIT_SUCCESS";
export const START_ACH_MICRODEPOSIT_FAILURE = "START_ACH_MICRODEPOSIT_FAILURE";

export const RESET_ACH_STATE = "RESET_ACH_STATE";
export const resetAchState = makeActionCreator(RESET_ACH_STATE);

export const fetchACHClientSecretExistingCustomer = (companyId: number, customerUniqueId: string) => {

  const createMandateExistingCustomerRequest = new CreateAchMandateExistingCustomerRequest();
  createMandateExistingCustomerRequest.setCompanyId(companyId);
  createMandateExistingCustomerRequest.setCustomerUniqueId(customerUniqueId);
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_ACH_CLIENT_SECRET_REQUEST, FETCH_ACH_CLIENT_SECRET_SUCCESS, FETCH_ACH_CLIENT_SECRET_FAILURE],
    callAPI: () => grpcUnaryCall(createMandateExistingCustomerRequest, customerServiceClient, customerServiceClient.createAchMandateExistingCustomer)
  }

}

export const fetchACHClientSecretNewCustomer = (companyId: number, subscriptionData: ISubscription) => {

  const createMandateExistingCustomerRequest = new CreateAchMandateNewCustomerRequest();
  createMandateExistingCustomerRequest.setCompanyId(companyId);
  createMandateExistingCustomerRequest.setCustomerEmail(subscriptionData.email);
  createMandateExistingCustomerRequest.setCustomerName(`${subscriptionData.firstName} ${subscriptionData.lastName}`);
  createMandateExistingCustomerRequest.setCustomerCountry(subscriptionData.address.country);
  createMandateExistingCustomerRequest.setAddressLine1(subscriptionData.address.addressLine1);
  createMandateExistingCustomerRequest.setAddressCity(subscriptionData.address.city);
  createMandateExistingCustomerRequest.setAddressState(subscriptionData.address.state);
  createMandateExistingCustomerRequest.setAddressPostCode(subscriptionData.address.postCode);

  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_ACH_CLIENT_SECRET_REQUEST, FETCH_ACH_CLIENT_SECRET_SUCCESS, FETCH_ACH_CLIENT_SECRET_FAILURE],
    callAPI: () => grpcUnaryCall(createMandateExistingCustomerRequest, customerServiceClient, customerServiceClient.createAchMandateNewCustomer)
  }

}

export const updateAchPayment = (companyId: number, customerUniqueId: string, achCustomerId: string, achPaymentId: string, fullName: string, bankName: string, last4Digits: string, hostedVerificationUrl?: string) => {

  const updateAchPaymentRequest = new UpdateAchPaymentMethodRequest();
  updateAchPaymentRequest.setCompanyId(companyId);
  updateAchPaymentRequest.setCustomerUniqueId(customerUniqueId);
  updateAchPaymentRequest.setAchCustomerId(achCustomerId);
  updateAchPaymentRequest.setAchPaymentId(achPaymentId);
  updateAchPaymentRequest.setAchFullName(fullName);
  updateAchPaymentRequest.setAchBankName(bankName);
  updateAchPaymentRequest.setAchLast4Digits(last4Digits);
  !!hostedVerificationUrl && updateAchPaymentRequest.setHostedVerificationUrl(hostedVerificationUrl)
  const customerServiceClient = new CustomerServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [UPDATE_ACH_PAYMENT_REQUEST, UPDATE_ACH_PAYMENT_SUCCESS, UPDATE_ACH_PAYMENT_FAILURE],
    callAPI: () => grpcUnaryCall(updateAchPaymentRequest, customerServiceClient, customerServiceClient.updateAchPaymentMethod)
  }

}

export const startCheckoutAchMicroDeposit = (companyId: number, customerEmail: string, customerFirstName: string, achLast4Digits: string, achBankName: string,
  hostedVerificationUrl: string, checkoutUrl: string) => {

    const startCheckoutAchMicroDepositRequest = new StartCheckoutAchMicroDepositRequest()
    startCheckoutAchMicroDepositRequest.setCompanyId(companyId)
    startCheckoutAchMicroDepositRequest.setCustomerEmail(customerEmail)
    startCheckoutAchMicroDepositRequest.setCustomerFirstName(customerFirstName)
    startCheckoutAchMicroDepositRequest.setAchLast4Digits(achLast4Digits)
    startCheckoutAchMicroDepositRequest.setAchBankName(achBankName)
    startCheckoutAchMicroDepositRequest.setHostedVerificationUrl(hostedVerificationUrl)
    startCheckoutAchMicroDepositRequest.setCheckoutUrl(checkoutUrl)

    const customerService = new CustomerServiceClient(ConfigConstants.grpcBaseUrl)

    return {
      types: [START_ACH_MICRODEPOSIT_REQUEST, START_ACH_MICRODEPOSIT_SUCCESS, START_ACH_MICRODEPOSIT_FAILURE],
      callAPI: () => grpcUnaryCall(startCheckoutAchMicroDepositRequest, customerService, customerService.startCheckoutAchMicroDeposit)
    }
}