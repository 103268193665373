import React from "react";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IChangePlanReducer } from "../../reducers/changePlanReducer";
import moment from "moment";
import { setChangePlanField } from "../../actions/changePlanActions";
import { ChangePlanScreen, PlanChangeDate } from "../../models/ChangePlan";
import { IGlobalReducer } from "../../reducers/globalReducer";

interface IChangePlanStep3 {
  btnBackCallback?: () => void
}

const ChangePlanStep3: React.FC<IChangePlanStep3> = ({ btnBackCallback }) => {
  const { selectedSubscription } = useSelector<AppState, IChangePlanReducer>(state => state.changePlanReducer);
  const { checkoutConfig } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const dispatch = useDispatch<Function>()

  if (!selectedSubscription || !checkoutConfig) {
    return null;
  }

  return (
    <PageWrapper className="change-plan-step3"  title="CHANGE_PLAN_BILLING_DATE_TITLE" titlePosition="left-aligned" 
    btnBackCallback={btnBackCallback} 
    titleTranslateWith={{ planLabel: checkoutConfig.terminologyPlanSingular }} btnDisabled={false}>
      <Panel id="change-plan-select-date-immediate" isClickable onClick={() => {
        dispatch(setChangePlanField("selectedDateToChange", PlanChangeDate.IMMEDIATE));
        dispatch(setChangePlanField("currentScreen", ChangePlanScreen.STEP4));
      }}>
        <Text content="CHANGE_PLAN_BILLING_DATE_TODAY" weight="bold" noMargin></Text>
      </Panel>

      <Panel id="change-plan-select-date-next-billing" isClickable onClick={() => {
        dispatch(setChangePlanField("selectedDateToChange", PlanChangeDate.NEXT_BILLING));
        dispatch(setChangePlanField("currentScreen", ChangePlanScreen.STEP4));
      }}>
        <Text content="CHANGE_PLAN_BILLING_DATE_NEXT_BILLING" translateWith={{ nextBilling: moment(selectedSubscription.nextBilling).format("DD MMM YYYY") }} weight="bold" noMargin></Text>
      </Panel>
    </PageWrapper>
  )
}

export default ChangePlanStep3