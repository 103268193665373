import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { ConfigConstants } from "../utils/config";
import { ApplyRetentionDiscountRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/discount/discount_pb";
import { SubscriptionDiscountServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/discount/DiscountServiceClientPb";
import { makeActionCreator } from "../utils/reduxUtils";

export const ADD_DISCOUNT_OFFER_REQUEST = "ADD_DISCOUNT_OFFER_REQUEST"
export const ADD_DISCOUNT_OFFER_SUCCESS = "ADD_DISCOUNT_OFFER_SUCCESS"
export const ADD_DISCOUNT_OFFER_FAILURE = "ADD_DISCOUNT_OFFER_FAILURE"

export const RESET_ADD_DISCOUNT_OFFER = "RESET_ADD_DISCOUNT_OFFER"

export const resetApplyDiscountOffer = makeActionCreator(RESET_ADD_DISCOUNT_OFFER);

export function applyDiscountRetention(companyDomain: string, subscriptionUniqueId: string) {
  const applyDiscountRetentionRequest = new ApplyRetentionDiscountRequest();
  applyDiscountRetentionRequest.setCompanyDomain(companyDomain);
  applyDiscountRetentionRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  const subscriptionDiscountServiceClient = new SubscriptionDiscountServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [ADD_DISCOUNT_OFFER_REQUEST, ADD_DISCOUNT_OFFER_SUCCESS, ADD_DISCOUNT_OFFER_FAILURE],
    callAPI: () => grpcUnaryCall(applyDiscountRetentionRequest, subscriptionDiscountServiceClient, subscriptionDiscountServiceClient.applyRetentionDiscount)
  }
}