import { makeActionCreator } from "../utils/reduxUtils";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { GetInvoicesRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/invoice_pb";
import { InvoiceServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/customer/InvoiceServiceClientPb";
import { ConfigConstants } from "../utils/config";

export const RESET_VIEW_BILLING_HISTORY = "RESET_VIEW_BILLING_HISTORY";
export const resetViewBillingHistory = makeActionCreator(RESET_VIEW_BILLING_HISTORY);

export const FETCH_INVOICES_SUMMARY_REQUEST = "FETCH_INVOICES_SUMMARY_REQUEST";
export const FETCH_INVOICES_SUMMARY_SUCCESS = "FETCH_INVOICES_SUMMARY_SUCCESS";
export const FETCH_INVOICES_SUMMARY_FAILURE = "FETCH_INVOICES_SUMMARY_FAILURE";


export const fetchInvoices = function (companyDomain: string, customerUniqueId: string) {
  const getInvoices = new GetInvoicesRequest();
  getInvoices.setCompanyDomain(companyDomain);
  getInvoices.setCustomerUniqueReference(customerUniqueId);
  
  const invoiceServiceClient = new InvoiceServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [FETCH_INVOICES_SUMMARY_REQUEST, FETCH_INVOICES_SUMMARY_SUCCESS, FETCH_INVOICES_SUMMARY_FAILURE],
    callAPI: () => grpcUnaryCall(getInvoices, invoiceServiceClient, invoiceServiceClient.getInvoices)
  }
} 