import React, { useRef } from "react";
import ReactDOM from "react-dom";
import Input from "../input/Input";
import "./PassCode.scss";

export interface IPassCodeField {
  "0": string,
  "1": string,
  "2": string,
  "3": string,
  "4": string,
  "5": string
}

type PassCodeValidID = "el_0" | "el_1" | "el_2" | "el_3" | "el_4" | "el_5";

interface IPassCode {
  className?: string,
  value: IPassCodeField
  onChange: (passcode: IPassCodeField) => void
  onSubmit: (passCode: IPassCodeField) => void
}

const PassCode: React.FC<IPassCode> = ({ className = "", value, onChange, onSubmit }) => {
  const rootEl = useRef(null);
  const inputIds = ["el_0", "el_1", "el_2", "el_3", "el_4", "el_5"] as Array<PassCodeValidID>;

  const moveFocusTo = (id: PassCodeValidID) => {
    const rootDOM: Element = ReactDOM.findDOMNode(rootEl.current) as Element;
    if (rootDOM) {
      const elem: HTMLElement = rootDOM.querySelector(`#${id}`) as HTMLElement;
      elem && elem.focus();
    }
  }
  


  const renderPasscodeInput = () => {
    return inputIds.map((el, i: any) => {
      return (
        <Input
          id={el}
          key={el}
          value={value[i as keyof IPassCodeField]}
          maxLength="1"
          type="number"
          onChange={(evt: any) => {
            onChange({ ...value, [i]: evt.target.value ? evt.target.value[0] : "" });
            //Prevent going to next input box when deleting the value of the focused input
            if (el !== "el_5" && evt.target.value && evt.target.value[0]) {
              moveFocusTo(inputIds[i + 1]);
            }
          }}
          onPaste={(evt: any) => {
            evt.preventDefault();
            const clipboardData = evt.clipboardData.getData("text/plain")
            const copiedVal: Array<string> = [...clipboardData];
            const passCode: IPassCodeField = {
              [0]: copiedVal[0],
              [1]: copiedVal[1],
              [2]: copiedVal[2],
              [3]: copiedVal[3],
              [4]: copiedVal[4],
              [5]: copiedVal[5]
            }
            onChange(passCode)
            onSubmit(passCode)
          }}
        />
      )
    })
  }

  return (
    <div className="passcode" ref={rootEl}>
      {renderPasscodeInput()}
    </div>
  )
}

export default PassCode;