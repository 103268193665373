import React from "react";
import { ICheckoutConfig } from "../../../models/BillsbyData";
import { IAllowance, IAllowancePricingModel } from "../../../models/AddOnAllowance";
import Text from "../../ui/text/Text";
import { getCycleFrequencyText } from "../../../utils/planUtils";
import counterpart from "counterpart";

interface IIncludedUnitsText {
  priceModel: IAllowancePricingModel;
  allowance: IAllowance;
  checkoutConfig: ICheckoutConfig;
}

const IncludedUnitsText: React.FC<IIncludedUnitsText> = ({priceModel, allowance, checkoutConfig}) => {
  if (!priceModel.includedUnits) 
    return null;
  
  const unitLabel = priceModel.includedUnits
    ? (allowance.pluralUnitName || checkoutConfig.terminologyUnitPlural)
    : (allowance.singleUnitName || checkoutConfig.terminologyUnitSingular);
  
  return (
    <>
      <Text
        className="select-plan__inline-info text-bold"
        content={`${priceModel.includedUnits} ${counterpart("SELECT_ALLOWANCES_INCLUDED")}`}
        shouldTranslate={false}
        noMargin
      />
      <Text
        className="select-plan__inline-info"
        content={` ${unitLabel} ${counterpart("FREQUENCY_EVERY")} `}
        shouldTranslate={false}
        noMargin
      />
      <Text
        className="select-plan__inline-info text-bold"
        content={`${getCycleFrequencyText(priceModel.frequency, priceModel.frequencyType, true)}`}
        shouldTranslate={false}
        noMargin
      />
    </>
  ) 
}

export default IncludedUnitsText;