import React from "react"
import { useSelector } from "react-redux";
import { AppState } from "../..";
import { getContractMinimumTermInfo } from "../../utils/commonUtils";
import { ICustomerSubscription } from "../../models/Customer";
import moment from "moment";
import SystemInfo from "../ui/system-info/SystemInfo";
import counterpart from "counterpart";
import AchInfoMessage from "../ach-form/AchInfoMessage";
import Text from "../ui/text/Text";

const CustomerInfoMessage: React.FC = () => {
  const { subscriptionOutstandingBalance, currentSubscription, pendingMinimumTermAmount } = useSelector((state: AppState) => state.subscriptionReducer);
  const { isRemoveCustomerSubscriptionSuccess, isRemoveCustomerSubscriptionFailure } = useSelector((state: AppState) => state.retentionReducer);
  const { isUpdatingPaymentDetailsSuccess, isUpdatingPaymentDetailsFailure, customSuccessSubtitle } = useSelector((state: AppState) => state.updatePaymentReducer);
  const { isChangePlanSuccess, isChangePlanFailure, isChangePlanCycleSuccess, isChangePlanCycleFailure, isPayContractTermSuccess } = useSelector((state: AppState) => state.changePlanReducer);
  const { isChangePersonalDetailsSuccess, isChangePersonalDetailsFailure } = useSelector((state: AppState) => state.changePersonalDetailsReducer);
  const { isChangeAddressSuccess, isChangeAddressFailure } = useSelector((state: AppState) => state.changeAddressReducer);
  const { isAddDiscountOfferSuccess, isAddDiscountOfferFailure } = useSelector((state: AppState) => state.addDiscountOfferReducer);
  const { isEditingAddOnsSuccess, isEditingAddOnsFailure, isAddingNewAddOnsSuccess, isAddingNewAddOnsFailure,
    isAddingNewAllowancesSuccess, isAddingNewAllowancesFailure, isRemovingAllowancesSuccess, isRemovingAllowancesFailure } = useSelector((state: AppState) => state.addOnReducer);
  const { checkoutConfig } = useSelector((state: AppState) => state.globalReducer);
  const { isUpdateAchSucceeded, isUpdateAchFailed, isStartMicroDepositSuccess, isStartMicroDepositFailed } = useSelector((state: AppState) => state.achReducer);

  const { isPendingContractMinimumTermEnd } = getContractMinimumTermInfo(currentSubscription as ICustomerSubscription);
  const nextBilling = moment(currentSubscription?.nextBilling).format("DD MMM YYYY");
  const hasUpdatedPlanNextBilling = currentSubscription?.planIdNext !== currentSubscription?.planId;


  const getSuccessErrorMessage = () => {

    if (isRemoveCustomerSubscriptionSuccess) {
      return (
        <div className="account-management__subscription-cancelled">
          <Text content="ACCOUNT_MANAGEMENT_SUB_CANCELLATION_SUCCESS_TITLE" size="xlarge" weight="bold"></Text>
          <Text content="ACCOUNT_MANAGEMENT_SUB_CANCELLATION_SUCCESS_SUBTITLE" translateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }} size="normal" weight="bold" />
        </div>
      )
    }
    if (isPayContractTermSuccess && pendingMinimumTermAmount && pendingMinimumTermAmount.getAmount() > 0) {
      return (
        <SystemInfo
          title="END_CONTRACT_TERM_SUCCESS_TITLE"
          text="END_CONTRACT_TERM_SUCCESS_SUBTITLE"
          type="success"
        />
      )
    }

    if (isRemoveCustomerSubscriptionSuccess || isRemoveCustomerSubscriptionFailure) {
      let subTitle: string | undefined = "";
      if (isRemoveCustomerSubscriptionSuccess) {
        subTitle = undefined;
      }
      else if (subscriptionOutstandingBalance?.getFormattedAmount()) {
        subTitle = "ACCOUNT_MANAGEMENT_PLAN_CANCELLATION_ERROR_OUTSTANDING_BALANCE_SUBTITLE";
      }
      else if (isPendingContractMinimumTermEnd) {
        subTitle = "ACCOUNT_MANAGEMENT_PLAN_CANCELLATION_ERROR_MINIMUM_TERM_SUBTITLE";
      }
      else {
        subTitle = "ACCOUNT_MANAGEMENT_PLAN_CANCELLATION_ERROR_SUBTITLE";
      }

      return (
        <SystemInfo
          title={isRemoveCustomerSubscriptionSuccess ? "ACCOUNT_MANAGEMENT_PLAN_CANCELLATION_SUCCESS_TITLE" : "ACCOUNT_MANAGEMENT_PLAN_CANCELLATION_ERROR_TITLE"}
          text={subTitle}
          type={isRemoveCustomerSubscriptionSuccess ? "success" : "warning"}
          translateWith={{
            subscriptionLabel: checkoutConfig?.terminologySubscriptionSingular,
            planLabel: checkoutConfig?.terminologyPlanSingular,
            outstandingBalance: subscriptionOutstandingBalance?.getFormattedAmount()
          }}
        />
      )
    }

    if (isChangePlanSuccess || isChangePlanFailure) {
      return (
        <SystemInfo
          title={hasUpdatedPlanNextBilling ? "ACCOUNT_MANAGEMENT_CHANGEPLAN_NEXT_BILLING_SUCCESS_TITLE" : isChangePlanSuccess ? "ACCOUNT_MANAGEMENT_CHANGEPLAN_SUCCESS_TITLE" : isChangePlanFailure && subscriptionOutstandingBalance && !!subscriptionOutstandingBalance.getAmount() ? "ACCOUNT_MANAGEMENT_CHANGEPLAN_ERROR_INVOICE_TITLE" : "ACCOUNT_MANAGEMENT_CHANGEPLAN_ERROR_TITLE"}
          text={isChangePlanFailure && subscriptionOutstandingBalance && !!subscriptionOutstandingBalance.getAmount() ? "ACCOUNT_MANAGEMENT_CHANGEPLAN_ERROR_INVOICE_SUBTITLE" : "ACCOUNT_MANAGEMENT_CHANGEPLAN_SUCCESS_SUBTITLE"}
          type={isChangePlanSuccess ? "success" : "warning"}
          translateWith={{ planLabel: checkoutConfig?.terminologyPlanSingular, outstandingBalance: subscriptionOutstandingBalance?.getFormattedAmount(), nextBilling }}
        />
      )
    }
    if (isChangeAddressSuccess || isChangeAddressFailure) {
      return (
        <SystemInfo
          title={isChangeAddressSuccess ? "ACCOUNT_MANAGEMENT_CHANGEADDRESS_SUCCESS_TITLE" : "ACCOUNT_MANAGEMENT_CHANGEADDRESS_ERROR_TITLE"}
          text="ACCOUNT_MANAGEMENT_CHANGEADDRESS_SUBTITLE"
          type={isChangeAddressSuccess ? "success" : "warning"}
        />
      )
    }
    if (isChangePersonalDetailsSuccess || isChangePersonalDetailsFailure) {
      return (
        <SystemInfo
          title={isChangePersonalDetailsSuccess ? "ACCOUNT_MANAGEMENT_CHANGEPERSONALDETAILS_SUCCESS_TITLE" : "ACCOUNT_MANAGEMENT_CHANGEPERSONALDETAILS_ERROR_TITLE"}
          type={isChangePersonalDetailsSuccess ? "success" : "warning"}
        />
      )
    }
    if (isChangePlanCycleSuccess || isChangePlanCycleFailure) {

      return (
        <SystemInfo
          title={isChangePlanCycleSuccess
            ? "ACCOUNT_MANAGEMENT_CHANGEPLAN_SUBSCRIPTION_SUCCESS_TITLE"
            : isChangePlanCycleFailure && !!subscriptionOutstandingBalance?.getAmount() ? "ACCOUNT_MANAGEMENT_CHANGEPLAN_ERROR_INVOICE_TITLE" : "ACCOUNT_MANAGEMENT_CHANGEPLAN_ERROR_TITLE"}
          text={isChangePlanCycleFailure && !!subscriptionOutstandingBalance?.getAmount() ? "ACCOUNT_MANAGEMENT_CHANGEPLAN_ERROR_INVOICE_SUBTITLE" : "ACCOUNT_MANAGEMENT_CHANGEPLAN_SUCCESS_SUBTITLE"}
          type={isChangePlanCycleSuccess ? "success" : "warning"}
          translateWith={{
            planLabel: checkoutConfig?.terminologyPlanSingular,
            outstandingBalance: subscriptionOutstandingBalance?.getFormattedAmount(),
            nextBilling,
            subscriptionLabel: checkoutConfig?.terminologySubscriptionSingular
          }}
        />
      )
    }

    if (isAddDiscountOfferSuccess || isAddDiscountOfferFailure) {
      return (
        <SystemInfo
          title={counterpart(isAddDiscountOfferSuccess ? "ACCOUNT_MANAGEMENT_ADD_DISCOUNT_OFFER_TITLE" : "ACCOUNT_MANAGEMENT_ADD_DISCOUNT_OFFER_ERROR_TITLE", { discountLabel: checkoutConfig?.terminologyDiscountSingular })}
          // title={counterpart("ACCOUNT_MANAGEMENT_PENDING_CONTRACT_TERM_TITLE", { contractMinimumTermEnd: moment(contractMinimumTermEnd).format('DD MMM YYYY') })}
          shouldTranslateTitle={false}
          text={`${counterpart("ACCOUNT_MANAGEMENT_ADD_DISCOUNT_OFFER_SUBTITLE")}`}
          shouldTranslateText={false}
          type={isAddDiscountOfferSuccess ? "success" : "warning"}
        />
      )
    }
    if (isEditingAddOnsSuccess || isEditingAddOnsFailure) {
      return (
        <SystemInfo
          title={isEditingAddOnsSuccess ? "MANAGE_ADDON_EDITING_SUCCESS_TITLE" : "MANAGE_ADDON_ERROR_TITLE"}
          text="MANAGE_ADDON_EDITING_SUCCESS_SUBTITLE"
          translateWith={{ nextBilling, addonLabel: checkoutConfig?.terminologyAddonPlural }}
          type={isEditingAddOnsSuccess ? "success" : "warning"}
        />
      )
    }
    if (isAddingNewAddOnsSuccess || isAddingNewAddOnsFailure) {
      return (
        <SystemInfo
          title={isAddingNewAddOnsSuccess ? "MANAGE_ADDON_ADDING_SUCCESS_TITLE" : "MANAGE_ADDON_ERROR_TITLE"}
          translateWith={{ addonLabel: checkoutConfig?.terminologyAddonPlural }}
          text="MANAGE_ADDON_EDITING_SUCCESS_SUBTITLE"
          type={isAddingNewAddOnsSuccess ? "success" : "warning"}
        />
      )
    }
    if (isRemovingAllowancesSuccess || isRemovingAllowancesFailure) {
      return (
        <SystemInfo
          title={isRemovingAllowancesSuccess ? "MANAGE_ALLOWANCE_REMOVE_SUCCESS_TITLE" : "MANAGE_ALLOWANCE_ERROR_TITLE"}
          text="MANAGE_ALLOWANCE_REMOVE_SUCCESS_SUBTITLE"
          translateWith={{ nextBilling, allowanceLabel: checkoutConfig?.terminologyAllowancePlural }}
          type={isRemovingAllowancesSuccess ? "success" : "warning"}
        />
      )
    }
    if (isAddingNewAllowancesSuccess || isAddingNewAllowancesFailure) {
      return (
        <SystemInfo
          title={isAddingNewAllowancesSuccess ? "MANAGE_ALLOWANCE_ADDING_SUCCESS_TITLE" : "MANAGE_ALLOWANCE_ERROR_TITLE"}
          translateWith={{ allowanceLabel: checkoutConfig?.terminologyAllowancePlural }}
          text="MANAGE_ALLOWANCE_ADDING_SUCCESS_SUBTITLE"
          type={isAddingNewAllowancesSuccess ? "success" : "warning"}
        />
      )
    }
    if (isUpdatingPaymentDetailsSuccess || isUpdatingPaymentDetailsFailure) {
      let subTitle = customSuccessSubtitle;
      if (!subTitle) {
        if ((isUpdatingPaymentDetailsSuccess)) {
          subTitle = "ACCOUNT_MANAGEMENT_UPDATE_PAYMENT_SUCCESS_SUBTITLE";
        }
      }
      if (isUpdatingPaymentDetailsFailure) {
        subTitle = "ACCOUNT_MANAGEMENT_UPDATE_PAYMENT_ERROR_SUBTITLE";
      }
      return (
        <SystemInfo
          title={isUpdatingPaymentDetailsSuccess ? "ACCOUNT_MANAGEMENT_UPDATE_PAYMENT_SUCCESS_TITLE" : "ACCOUNT_MANAGEMENT_UPDATE_PAYMENT_ERROR_TITLE"}
          text={subTitle ? counterpart(subTitle) : subTitle}
          shouldTranslateText={false}
          type={isUpdatingPaymentDetailsSuccess ? "success" : "warning"}
          translateWith={{ subscriptionLabel: checkoutConfig?.terminologySubscriptionSingular.capitalize() }}
        />
      )
    }

    if (isUpdateAchSucceeded || isUpdateAchFailed || isStartMicroDepositSuccess || isStartMicroDepositFailed) {
      return <AchInfoMessage />
    }


    return <div />;
  }


  return getSuccessErrorMessage()
}

export default CustomerInfoMessage