import React, { useEffect, useMemo, useState } from "react";
import PageWrapper from "../../../containers/page-wrapper/PageWrapper";
import Text from "../../ui/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../..";
import moment from "moment";
import { IAddOnPricingModel, IAddOn } from "../../../models/AddOnAllowance";
import counterpart from "counterpart";
import { ICycle } from "../../../models/Product";
import { setAddOns, setAddOnsUnits, editSubscriptionAddons, getAddOnPriceSimulation, addNewAddons, getAddOnPricingDisclosure } from "../../../actions/addOnActions";
import SystemInfo from "../../ui/system-info/SystemInfo";
import "./ManageAddonList.scss";
import history from "../../../utils/history";
import { AccountManagementPermissions } from "../../../models/AccountManagement";
import AddOnInput from "../addon-input/AddOnInput";
import Modal from "../../ui/modal/Modal";
import PricingInfoDisclosure from "../../pricing-info-disclosure/PricingInfoDisclosure";

interface IManageAddonList {
  onAddingAddOnsFailure: () => void
}

const ManageAddonList: React.FC<IManageAddonList> = ({ onAddingAddOnsFailure }) => {

  const { addOns, subscriptionAddons, isEditingAddOnsRequest, isEditingAddOnsSuccess, isEditingAddOnsFailure, addOnPriceSimulation,
      isAddingNewAddOnsRequest, isAddingNewAddOnsSuccess, isAddingNewAddOnsFailure, addOnPricingDisclosure } = useSelector((state: AppState) => state.addOnReducer);
  const { currentSubscription, currentPlan } = useSelector((state: AppState) => state.subscriptionReducer);
  const { companyDomain, checkoutConfig, branding, accountManagementPermissions } = useSelector((state: AppState) => state.globalReducer);
  const subscriptionUniqueId = useSelector((state: AppState) => state.personalDetailsReducer.mainProps.subscriptionUniqueId)

  const dispatch = useDispatch<Function>();

  const [hasAddedNewAddOns, setHasAddedNewAddOns] = useState(false);
  const [hasChangedSubscriptionAddOns, setHasChangedSubscriptionAddOns] = useState(false);
  const [isLoadingPriceSimulation, setIsLoadingPriceSimulation] = useState(false);
  const [showPriceSimulationModal, setShowPriceSimulationModal] = useState(false);
  const [hasError, setHasError] = useState(false);

  const backupAddOns: Array<IAddOn> = useMemo(() => {
    if (currentPlan && currentSubscription && subscriptionAddons) {
      const currentCycle = currentPlan.cycles.find(cycle => cycle.cycleId === currentSubscription.cycleId) as ICycle;
      if (currentCycle) {
        // new addOns excluding the ones already added by the customer
        const newAddOns = currentPlan.addons
          .filter(addon => addon.pricingModels.some(p => (
            p.frequency === currentCycle.pricingModel.frequency
            && p.frequencyType === currentCycle.pricingModel.frequencyType
            && p.currency.isoCode === currentSubscription.currency
          ))
            && !subscriptionAddons.some(a => a.id === addon.id));
        // include new addons and subscription addons
        const allAddOns = JSON.parse(JSON.stringify([...subscriptionAddons, ...newAddOns]));
        dispatch(setAddOns(allAddOns));
        // backupAddOns contains a deep clone of the addons that will be useful if we need to reset the addons
        // this is a very expensive operation and has to be done only once
        return JSON.parse(JSON.stringify(allAddOns));
      }
    }
    return [];
  }, [currentPlan, currentSubscription, subscriptionAddons]);

  const checkIfCustomerAddedNewAddOns = () => {
    let hasAddedNewAddon = false;
    let hasChangedSubscriptionAddOns = false;

    for (let i = 0; i < addOns.length && !hasAddedNewAddon; i++) {
      if (backupAddOns.some(a => a.id === addOns[i].id
        && a.units !== addOns[i].units
        && !addOns[i].isForced
        && !(addOns[i].units === 0 && a.units === undefined)
        && subscriptionAddons?.every(ad => ad.id !== a.id))) {
        hasAddedNewAddon = true;
      }
    }
    for (let i = 0; i < addOns.length && !hasChangedSubscriptionAddOns; i++) {
      hasChangedSubscriptionAddOns = !!subscriptionAddons?.some(a => a.id === addOns[i].id && a.units !== addOns[i].units);
    }
    setHasAddedNewAddOns(hasAddedNewAddon);
    setHasChangedSubscriptionAddOns(hasChangedSubscriptionAddOns);
  }

  useEffect(() => {
    checkIfCustomerAddedNewAddOns();
  }, [addOns]);

  useEffect(() => {
    if (isEditingAddOnsSuccess || isAddingNewAddOnsSuccess || isEditingAddOnsFailure) {
      history.push({ pathname: "/management", search: history.location.search });
    }
    if (isAddingNewAddOnsFailure) {
      onAddingAddOnsFailure();
    }
  }, [isEditingAddOnsSuccess, isEditingAddOnsFailure, isAddingNewAddOnsSuccess, isAddingNewAddOnsFailure])

  useEffect(() => {
    const fetchPriceSimulation = async () => {
      if (currentSubscription && hasAddedNewAddOns && subscriptionAddons) {
        try {
          setIsLoadingPriceSimulation(true);
          const newAddOns = addOns.filter(a => !!a.units && !subscriptionAddons.some(sa => sa.id === a.id))
          await dispatch(getAddOnPriceSimulation(companyDomain, currentSubscription.subscriptionUniqueId, newAddOns));
          await dispatch(getAddOnPricingDisclosure(companyDomain, currentSubscription.subscriptionUniqueId, newAddOns));

        }
        catch (err) { }
        finally { setIsLoadingPriceSimulation(false) }
      }
    }
    fetchPriceSimulation();
  }, [hasAddedNewAddOns, addOns]);

  const currentSubscriptionUniqueId = currentSubscription && currentSubscription.subscriptionUniqueId;
  const currentSubscriptionCycleId = currentSubscription && currentSubscription.cycleId;
  const nextBilling = currentSubscription && moment(currentSubscription.nextBilling).format("DD MMM YYYY");
  const currentCycle = currentPlan && currentPlan.cycles.find(cycle => cycle.cycleId === currentSubscriptionCycleId) as ICycle;

  if (!currentSubscription || !currentSubscriptionUniqueId || !currentPlan || !checkoutConfig || !subscriptionAddons || !currentCycle) {
    return null;
  }

  const updateAddOns = (addOnId: number, pricingModels: IAddOnPricingModel[], units?: number) => {
    const isNewAddOn = subscriptionAddons.every(a => a.id !== addOnId);
    setHasError(false);
    if (hasChangedSubscriptionAddOns && isNewAddOn) {
      // before showing the warning we scroll to the top of the page to make sure the warning is visible even if there are many add-ons
      const pageWrapperScrollable = document.querySelector(".page-wrapper__body-content");
      pageWrapperScrollable && pageWrapperScrollable.scrollTo({ top: 0, behavior: "smooth" });
      setHasError(true);
    }
    else {
      dispatch(setAddOnsUnits({ id: addOnId, units, pricingModels }));
    }
  };

  const renderPriceSummaryModal = () => {
    return (
      <Modal isOpen={showPriceSimulationModal} title="MANAGE_ADDON_HOW_WAS_CALCULATED" onRequestClose={() => setShowPriceSimulationModal(false)}>
        {addOnPricingDisclosure && <PricingInfoDisclosure rawHTMLEncoded={addOnPricingDisclosure} />}
      </Modal>
    )
  }

  return (
    <PageWrapper
      btnText={hasAddedNewAddOns && addOnPriceSimulation
        ? counterpart("MANAGE_ADDON_PAY_AMOUNT", {
          amountFormatted: addOnPriceSimulation.getTotalAmountFormatted(),
          addonLabel: addOnPriceSimulation.getPriceSimulationsList().length > 1
            ? checkoutConfig?.terminologyAddonPlural
            : checkoutConfig?.terminologyAddonSingular
        })
        : counterpart("MANAGE_ADDON_SAVE_CHANGES")}
      btnDisabled={(!hasAddedNewAddOns && !hasChangedSubscriptionAddOns) || isEditingAddOnsRequest || isAddingNewAddOnsRequest}
      btnCallback={() => {
        if (!hasAddedNewAddOns && hasChangedSubscriptionAddOns) {
          dispatch(editSubscriptionAddons(companyDomain, subscriptionUniqueId as string, addOns));
        }
        if (hasAddedNewAddOns && !hasChangedSubscriptionAddOns) {
          dispatch(addNewAddons(companyDomain, subscriptionUniqueId as string, addOns));
        }
      }}
      shouldTranslateText={false}
      btnPosition="bottom"
      isLoading={isEditingAddOnsRequest || isAddingNewAddOnsRequest || isLoadingPriceSimulation}
      title="MANAGE_ADDON_TITLE"
      titleTranslateWith={{ addonLabel: checkoutConfig?.terminologyAddonPlural }}
      titlePosition="left-aligned"
      subTitle="MANAGE_ADDON_SUBTITLE"
      subTitleTranslateWith={{ nextBilling, addonLabel: checkoutConfig?.terminologyAddonPlural }}
      btnBackCallback={() => history.push({ pathname: "/management", search: history.location.search })}
      btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION}
      bottomStickyContent={
        hasAddedNewAddOns
          ? <Text
            className="manage-addon-list__calculation"
            content="MANAGE_ADDON_HOW_WAS_CALCULATED"
            onClick={() => setShowPriceSimulationModal(true)}
            noMargin
          />
          : undefined
      }>
      <>
        <SystemInfo
          title="MANAGE_ADDON_SAVE_CHANGES_ERROR"
          translateWith={{ addonLabel: checkoutConfig?.terminologyAddonPlural }}
          isShowIcon={false}
          type="warning"
          isVisible={hasError}
        />
        {
          addOns.map((addOn: IAddOn) => (
            <AddOnInput
              key={addOn.id}
              addOn={addOn}
              plan={currentPlan}
              cycle={currentCycle}
              branding={branding}
              checkoutConfig={checkoutConfig}
              updateAddons={(addOnId: number, pricingModels: IAddOnPricingModel[], units?: number) => updateAddOns(addOnId, pricingModels, units)}
              nextBillingDate={nextBilling}
              image={addOn.imageUrl}
              isLocked={hasAddedNewAddOns && subscriptionAddons.some(a => a.id === addOn.id)}
              willBeDeleted={!!addOn.willBeDeleted}
              unitsNext={addOn.unitsNext}
            />
          ))
        }

        {renderPriceSummaryModal()}
      </>
    </PageWrapper>
  )

}

export default ManageAddonList;