import React, { useLayoutEffect, useMemo, useState } from "react";
import "./PersonalDetailsStep.scss";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import { setAddressLine1, setAddressLine2, setCity, setState, setZipCode, setCountry, validateAddress, setShippingAsBillingFlag, resetCountryState, resetPersonalDetailsErrors, setPersonalDetailsCurrentScreen, setIsShippingAddressManual, setIsBillingAddressManual } from "../../actions/personalDetailsActions";
import FormGroup from "../ui/form-group/FormGroup";
import Checkbox from "../ui/checkbox/Checkbox";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import Address from "../address/Address";
import counterpart from "counterpart";
import FormLabel from "../ui/form-label/FormLabel";
import { countryStates } from "../../utils/states/states";
import SystemInfo from "../ui/system-info/SystemInfo";
import { setNavigationStep } from "../../actions/globalActions";
import { NavigationBarSteps } from "../../models/NavigationBar";
import { PersonalDetailsScreen } from "../../models/PersonalDetails";
import { setSelectPlanCurrentScreen, setCycle, setProduct } from "../../actions/selectPlanActions";
import { SelectPlanScreen } from "../../models/SelectPlan";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import AddressAutocomplete from "../address-autocomplete/AddressAutocomplete";
import Button from "../ui/button/Button";
import Panel from "../ui/panel/Panel";
import { Col, Row } from "react-grid-system";
import { isComplexPricingModel } from "../../utils/planUtils";
import { IAddOnReducer } from "../../reducers/addOnReducer";


export const PersonalDetailsStep2: React.FC = () => {
  const { personalDetailsErrors, addressDataErrors, shippingAddressDataErrors, address, shippingAddress, countries, countriesDropdown,
    shippingAsBilling, mainProps: { customerUniqueId }, isUserDataScreenPreloaded, isAddressScreenPreloaded, isShippingAddressManual,
    isBillingAddressManual } = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer);
  const { productId, planId, cycleId, plansDropdownAvailable, productsDropdownAvailable, addOnsFilteredByCycle, allowancesFilteredByCycle,
    product } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { preloadedAddOns, preloadedAllowances, hasPreloadedAddOnsWithMissingUnits } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const dispatch = useDispatch<Function>()

  const [isBillingAddressError, setIsBillingAddressError] = useState(false);
  const [isShippingAddressError, setIsShippingAddressError] = useState(false);
  const [enableShippingAddressCheckbox, setEnableShippingAddressCheckbox] = useState(true);

  const shippingAddressCountriesDropdown = useMemo(() => {
    return countriesDropdown.filter(country => country.value.addressPermission === "ShippingAddress" || country.value.addressPermission === "Both");
  }, [countriesDropdown]);

  const shippingAddressCountries = useMemo(() => {
    return countries.filter(country => country.addressPermission === "ShippingAddress" || country.addressPermission === "Both");
  }, [countries]);

  const billingAddressCountriesDropdown = useMemo(() => {
    return countriesDropdown.filter(country => country.value.addressPermission === "BillingAddress" || country.value.addressPermission === "Both");
  }, [countriesDropdown]);

  const billingAddressCountries = useMemo(() => {
    return countries.filter(country => country.addressPermission === "BillingAddress" || country.addressPermission === "Both");
  }, [countries]);

  const isShippingAddressRequired = useMemo(() => {
    return !!product && product.value.isShippingAddressRequired;
  }, [product?.value.isShippingAddressRequired]);


  const shippingAddressMargin = useMemo(() => {
    return (isShippingAddressManual || isShippingAddressError) ? "default" : "none";
  }, [isShippingAddressManual, isShippingAddressError]);

  const billingAddressMargin = useMemo(() => {
    return (isBillingAddressManual || isBillingAddressError) ? "default" : "none";
  }, [isBillingAddressManual, isBillingAddressError]);

  const isShippingAddressInvalid = useMemo(() => {
    return isShippingAddressRequired && !shippingAsBilling && (!shippingAddress.address1 || !shippingAddress.city || !shippingAddress.country || !shippingAddress.state || !shippingAddress.zipCode);
  }, [isShippingAddressRequired, shippingAsBilling, shippingAddress])

  useLayoutEffect(() => {
    dispatch(setNavigationStep(NavigationBarSteps.ADDRESS_DETAILS));
  }, [])

  useLayoutEffect(() => {
    dispatch(setIsShippingAddressManual(Object.keys(shippingAddressDataErrors).length > 0 || isShippingAddressManual));
    dispatch(setIsBillingAddressManual(Object.keys(shippingAddressDataErrors).length > 0 || isBillingAddressManual));
  }, [shippingAddressDataErrors, addressDataErrors])

  useLayoutEffect(() => {
    dispatch(setIsBillingAddressManual(isBillingAddressError || isBillingAddressManual));
    dispatch(setIsShippingAddressManual(isShippingAddressError || isShippingAddressManual));
  }, [isBillingAddressError, isShippingAddressError])

  //Customers are required to enter a different shipping address if we cant use the billing address for both
  useLayoutEffect(() => {
    if (isShippingAddressRequired && billingAddressCountries.find(c => c.iso3Code === address.country?.value.iso3Code)?.addressPermission === "BillingAddress") {
      shippingAddress && dispatch(setShippingAsBillingFlag(false));
      setEnableShippingAddressCheckbox(false);
    } else {
      setEnableShippingAddressCheckbox(true);
    }
  }, [address, billingAddressCountries, shippingAsBilling, isShippingAddressRequired])
  const isInvalidForm = useMemo(() => {
    return !address.address1 || !address.city || !address.zipCode || !address.country || !address.state;
  }, [address]);

  useLayoutEffect(() => {
    let isValidAddressCountry = false;
    let isValidShippingCountry = !isShippingAddressRequired;
    // if countries are preloaded via the config object we have to select the correspondent countries in the dropdown
    if (address.country && billingAddressCountriesDropdown.length) {
      const preSelectedAddressCountry = billingAddressCountriesDropdown.find(country => country.value.iso3Code === (address.country as any).value.iso3Code);
      if (preSelectedAddressCountry) {
        isValidAddressCountry = true;
        dispatch(setCountry(true, preSelectedAddressCountry));
        const statesOptions = countryStates.filter(state => state.CountryIso2 === preSelectedAddressCountry.value.iso2Code);
        const preSelectedAddressState = statesOptions.find(state => state.StateIso2 === address.state);
        if (preSelectedAddressState) {
          dispatch(setState(true, { label: preSelectedAddressState.StateName, value: preSelectedAddressState }));
        }
      }
    }
    if (shippingAddress.country && shippingAddressCountriesDropdown.length && !isValidShippingCountry) {
      const preSelectedShippingCountry = shippingAddressCountriesDropdown.find(country => country.value.iso3Code === (shippingAddress.country as any).value.iso3Code);
      if (preSelectedShippingCountry) {
        // if shipping address is required then it's valid only if all the properties are filled in
        isValidShippingCountry = !!(shippingAddress.address1 && shippingAddress.city && shippingAddress.country && shippingAddress.state && shippingAddress.zipCode);
        dispatch(setCountry(false, preSelectedShippingCountry));
        const statesOptions = countryStates.filter(state => state.CountryIso2 === preSelectedShippingCountry.value.iso2Code);
        const preSelectedAddressState = statesOptions.find(state => state.StateIso2 === shippingAddress.state);
        if (preSelectedAddressState) {
          dispatch(setState(false, { label: preSelectedAddressState.StateName, value: preSelectedAddressState }));
        }
      }
    }

    if (isAddressScreenPreloaded && isValidAddressCountry && isValidShippingCountry) {
      dispatch(validateAddress());
    }

    return () => dispatch(resetPersonalDetailsErrors())
  }, [countriesDropdown]);

  if (!product || !countriesDropdown.length) {
    return null
  }

  const renderAutocompleteBillingAddress = () => {
    return (
      <>
        <FormLabel className="personal-details__field-title" content="PERSONAL_DETAILS_ADDRESS_TITLE" shouldTranslate />
        <FormGroup className="personal-details__address-autocomplete" margin={billingAddressMargin}>
          <Row>
            <Col xs={12} sm={9}>
              <AddressAutocomplete
                countries={billingAddressCountriesDropdown}
                hasError={isBillingAddressError}
                id="billing-address"
                className="personal-details__address-autocomplete__input"
                restrictedCountries={billingAddressCountries}
                initialValue={{
                  line1: address.address1,
                  line2: address.address2,
                  city: address.city,
                  state: { label: address.stateObject?.label || address.state, value: address.stateObject?.value as any },
                  zipCode: address.zipCode,
                  countryName: address.country?.label || "",
                  countryIso2: address.country?.value.iso2Code || "",
                  countryIso3: address.country?.value.iso3Code || "",
                }}
                onSelectedAddress={(selectedAddress, isValid) => {
                  if (!isBillingAddressManual) {
                    dispatch(setIsBillingAddressManual(true));
                  }
                  if (!selectedAddress || !isValid) {
                    setIsBillingAddressError(true);
                    return;
                  }
                  setIsBillingAddressError(false);
                  dispatch(setAddressLine1(true, selectedAddress.line1));
                  dispatch(setAddressLine2(true, selectedAddress.line2));
                  dispatch(setCity(true, selectedAddress.city));
                  dispatch(setState(true, selectedAddress.state));
                  dispatch(setZipCode(true, selectedAddress.zipCode));
                  dispatch(setCountry(true, { label: selectedAddress.countryName, value: { name: selectedAddress.countryName, iso3Code: selectedAddress.countryIso3, iso2Code: selectedAddress.countryIso2 } }));
                }} />
            </Col>
            <Col xs={12} sm={3}>
              <Button
                id="billing-address"
                disabled={isBillingAddressManual}
                title="ADDRESS_ENTER_MANUALLY"
                onClick={() => dispatch(setIsBillingAddressManual(!isBillingAddressManual))}
              />
            </Col>
          </Row>
        </FormGroup>
        {isBillingAddressManual && (
          <Address
            address1={address.address1}
            address2={address.address2}
            city={address.city}
            state={address.stateObject}
            zipCode={address.zipCode}
            country={address.country}
            addressErrors={addressDataErrors}
            address1OnChange={evt => dispatch(setAddressLine1(true, evt.target.value))}
            address2OnChange={evt => dispatch(setAddressLine2(true, evt.target.value))}
            cityOnChange={e => dispatch(setCity(true, e.target.value))}
            stateOnChange={(selectedItem: any) => {
              dispatch(setState(true, selectedItem))
            }}
            zipCodeOnChange={e => dispatch(setZipCode(true, e.target.value))}
            countryOnChange={(selectedItem: any) => {
              dispatch(setCountry(true, selectedItem));
              dispatch(resetCountryState(true));
            }}
            states={address.statesDropdown}
            countries={billingAddressCountriesDropdown}
          />
        )}
      </>
    )
  }

  const renderAutocompleteShippingAddress = () => {
    if (isShippingAddressRequired) {
      return (
        <>
          <FormGroup margin={shippingAsBilling ? "none" : "default"}>
            <Checkbox
              id="2"
              disabled={!enableShippingAddressCheckbox}
              onClick={() => dispatch(setShippingAsBillingFlag(!shippingAsBilling))}
              content={counterpart("PERSONAL_DETAILS_USE_MY_BILLING_ADDRESS")}
              checked={shippingAsBilling}
              value=""
            />
          </FormGroup>
          {
            shippingAsBilling
              ? null
              : (
                <>
                  <FormLabel className="personal-details__field-title" content="PERSONAL_DETAILS_SHIPPING_ADDRESS_TITLE" shouldTranslate />
                  <FormGroup className="personal-details__address-autocomplete" margin={shippingAddressMargin}>
                    <Row>
                      <Col xs={12} sm={9}>
                        <AddressAutocomplete
                          countries={shippingAddressCountriesDropdown}
                          hasError={isShippingAddressError}
                          id="shipping-address"
                          restrictedCountries={shippingAddressCountries}
                          initialValue={{
                            line1: shippingAddress.address1,
                            line2: shippingAddress.address2,
                            city: shippingAddress.city,
                            state: { label: shippingAddress.stateObject?.label || shippingAddress.state, value: shippingAddress.stateObject?.value as any },
                            zipCode: shippingAddress.zipCode,
                            countryName: shippingAddress.country?.label || "",
                            countryIso2: shippingAddress.country?.value.iso2Code || "",
                            countryIso3: shippingAddress.country?.value.iso3Code || "",
                          }}
                          onSelectedAddress={(selectedAddress, isValid) => {
                            if (!selectedAddress || !isValid) {
                              setIsShippingAddressError(true);
                              return;
                            }
                            setIsShippingAddressError(false);
                            dispatch(setAddressLine1(false, selectedAddress.line1));
                            dispatch(setAddressLine2(false, selectedAddress.line2));
                            dispatch(setCity(false, selectedAddress.city));
                            dispatch(setState(false, selectedAddress.state));
                            dispatch(setZipCode(false, selectedAddress.zipCode));
                            dispatch(setCountry(false, { label: selectedAddress.countryName, value: { name: selectedAddress.countryName, iso3Code: selectedAddress.countryIso3, iso2Code: selectedAddress.countryIso2 } }));
                          }} />
                      </Col>
                      <Col xs={12} sm={3}>
                        <Button
                          id="shipping-address"
                          disabled={isShippingAddressManual}
                          title="ADDRESS_ENTER_MANUALLY"
                          onClick={() => dispatch(setIsShippingAddressManual(!isShippingAddressManual))}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {isShippingAddressManual && (
                    <Address
                      address1={shippingAddress.address1}
                      address2={shippingAddress.address2}
                      city={shippingAddress.city}
                      state={shippingAddress.stateObject}
                      zipCode={shippingAddress.zipCode}
                      country={shippingAddress.country}
                      addressErrors={shippingAddressDataErrors}
                      address1OnChange={evt => dispatch(setAddressLine1(false, evt.target.value))}
                      address2OnChange={evt => dispatch(setAddressLine2(false, evt.target.value))}
                      cityOnChange={e => dispatch(setCity(false, e.target.value))}
                      stateOnChange={(selectedItem: any) => {
                        dispatch(setState(false, selectedItem))
                      }}
                      zipCodeOnChange={e => dispatch(setZipCode(false, e.target.value))}
                      countryOnChange={(selectedItem: any) => {
                        dispatch(setCountry(false, selectedItem));
                        dispatch(resetCountryState(false));
                      }}
                      states={shippingAddress.statesDropdown}
                      countries={shippingAddressCountriesDropdown}
                    />
                  )}
                </>
              )
          }
        </>
      )
    }
  }


  return (
    <PageWrapper
      className="personal-details"
      title="ADDRESS_DETAILS_TITLE"
      titlePosition="left-aligned"
      btnCallback={() => dispatch(validateAddress())}
      btnDisabled={isShippingAddressError || isBillingAddressError || isInvalidForm || isShippingAddressInvalid}
      btnBackCallback={() => {
        if (isUserDataScreenPreloaded) {
          dispatch(setNavigationStep(NavigationBarSteps.SELECT_PLAN));
          if (allowancesFilteredByCycle.length && !allowancesFilteredByCycle.every(a => a.isForced) && !preloadedAllowances.length) {
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ALLOWANCE));
          }
          else if ((addOnsFilteredByCycle.length && !addOnsFilteredByCycle.every(a => a.isForced && !isComplexPricingModel(a.pricingModelType)) && !preloadedAddOns.length) || (hasPreloadedAddOnsWithMissingUnits)) {
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.ADD_ON));
          }
          else if (!planId && plansDropdownAvailable.length > 1) {
            dispatch(setCycle(null));
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PLAN));
          }
          else /*if (!productId && products.length > 1)*/ {
            dispatch(setProduct(null));
            dispatch(setSelectPlanCurrentScreen(SelectPlanScreen.PRODUCT));
          }
        }
        else {
          dispatch(setNavigationStep(NavigationBarSteps.PERSONAL_DETAILS));
          dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.MAIN_DATA));
        }
      }}
      btnBackHidden={isUserDataScreenPreloaded &&
        ((!!preloadedAddOns.length && !!preloadedAllowances.length) || (!addOnsFilteredByCycle.length && !allowancesFilteredByCycle.length)) &&
        ((!!productId && !!planId && !!cycleId)
          || (productsDropdownAvailable.length === 1 && plansDropdownAvailable.length === 1 && plansDropdownAvailable[0].value.cycles.length === 1)
          || (!!productId && plansDropdownAvailable.length === 1 && plansDropdownAvailable[0].value.cycles.length === 1))
      }>

      <>
        {(isShippingAddressError || isBillingAddressError) &&
          <SystemInfo
            title={counterpart("SELECTED_ADDRESS_ERROR")}
            isShowIcon={false}
            shouldTranslateTitle={false}
            shouldTranslateText={false}
            type="warning"
          />}

        {personalDetailsErrors.length > 0 &&
          <SystemInfo
            title={counterpart("PAYMENT_COMPULSORY_FIELDS")}
            text={personalDetailsErrors.map(err => counterpart(err)).join(", ")}
            isShowIcon={false}
            shouldTranslateTitle={false}
            shouldTranslateText={false}
            type="warning"
          />
        }

      </>

      <Panel id="personal-details-address">
        {renderAutocompleteBillingAddress()}
        {renderAutocompleteShippingAddress()}
      </Panel>
    </PageWrapper>
  );
}

export default PersonalDetailsStep2