import React, { useEffect, useMemo, useState } from "react";
import InteractiveList from "../../components/ui/interactive-list/InteractiveList";
import { IInteractivePanel } from "../../components/ui/interactive-panel/InteractivePanel";
import PageWrapper from "../page-wrapper/PageWrapper";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import history from "../../utils/history";
import { AccountManagementPermissions } from "../../models/AccountManagement";
import { resetUpdatePaymentState } from "../../actions/updatePaymentActions";
import { resetChangePlanState, setIsUpdateSubscription } from "../../actions/changePlanActions";
import { resetSelectPlanState } from "../../actions/selectPlanActions";
import { CheckoutViewMode, BillsbyDataAction } from "../../models/BillsbyData";
import { fetchPersonalDetails, resetChangePersonalDetailsState } from "../../actions/changePersonalDetailsActions";
import { resetChangeAddressState } from "../../actions/changeAddressActions";
import "./AccountManangement.scss";
import { resetRetentionState } from "../../actions/retentionActions";
import { resetAddOnState } from "../../actions/addOnActions";
import { resetApplyDiscountOffer } from "../../actions/addDiscountOfferActions";
import { useFetch } from "../../utils/custom-hooks";
import { resetAchState } from "../../actions/achActions";
import CustomerInfoMessage from "../../components/customer-info-message/CustomerInfoMessage";

const AccountManagement: React.FC = () => {
  const customerUniqueId = useSelector((state: AppState) => state.personalDetailsReducer.mainProps.customerUniqueId);
  const {  currentPlan } = useSelector((state: AppState) => state.subscriptionReducer);
  const { isRemoveCustomerSubscriptionSuccess } = useSelector((state: AppState) => state.retentionReducer);
  const { firstName } = useSelector((state: AppState) => state.changePersonalDetailsReducer);
  const { companyDomain, checkoutViewMode, accountManagementPermissions, checkoutConfig }  = useSelector((state: AppState) => state.globalReducer);
  const { isLoading: isLoadingPersonalDetails } = useFetch(() => dispatch(fetchPersonalDetails(companyDomain, String(customerUniqueId))));
  const [state, setState] = useState<{ hasAddons: boolean, hasAllowances: boolean }>({ hasAddons: false, hasAllowances: false });

  const dispatch = useDispatch<Function>();

  const resetStates = () => {
    dispatch(resetSelectPlanState());
    dispatch(resetChangePlanState());
    dispatch(resetRetentionState());
    dispatch(resetUpdatePaymentState());
    dispatch(resetChangePersonalDetailsState());
    dispatch(resetChangeAddressState());
    dispatch(resetAddOnState());
    dispatch(resetApplyDiscountOffer());
    dispatch(resetAchState());
    return true;
  }

  useEffect(() => {
    currentPlan && setState({ hasAddons: !!currentPlan.addons.length, hasAllowances: !!currentPlan.allowances.length });
  }, [currentPlan])

  const canSeeMenu = useMemo(() => {
    if (checkoutViewMode === CheckoutViewMode.STANDALONE || isRemoveCustomerSubscriptionSuccess) {
      return false;
    }
    if (accountManagementPermissions === AccountManagementPermissions.FULL) {
      return true;
    }
    return false;
  }, [checkoutViewMode, isRemoveCustomerSubscriptionSuccess, accountManagementPermissions])


  if (!checkoutConfig || isLoadingPersonalDetails || (checkoutViewMode === CheckoutViewMode.STANDARD && !currentPlan)) {
    return null;
  }

  const data: Array<IInteractivePanel> = [
    {
      id: "change-plan-el",
      title: "ACCOUNT_MANAGEMENT_VIEW_CHANGE_PLAN",
      translateWith: { subscriptionLabel: checkoutConfig.terminologySubscriptionSingular },
      // isVisible: checkoutConfig.isViewChangePlanVisible && !isPendingContractMinimumTermEnd,
      isVisible: checkoutConfig.isViewChangePlanVisible,
      onClick: () => {
        resetStates();
        dispatch(setIsUpdateSubscription(true))
        history.push({ pathname: `/management/${BillsbyDataAction.CHANGE_PLAN}`, search: history.location.search });
      }
    },
    {
      id: "change-personal-details-el",
      title: "ACCOUNT_MANAGEMENT_CHANGE_PERSONAL_DETAILS",
      isVisible: checkoutConfig.isChangePersonalDetailsVisible,
      onClick: () => resetStates() && history.push({ pathname: `/management/${BillsbyDataAction.CHANGE_PERSONAL_DETAILS}`, search: history.location.search })
    },
    {
      id: "change-address-el",
      title: "ACCOUNT_MANAGEMENT_CHANGE_ADDRESS",
      isVisible: checkoutConfig.isChangeAddressVisible,
      onClick: () => resetStates() && history.push({ pathname: `/management/${BillsbyDataAction.CHANGE_ADDRESS}`, search: history.location.search })
    },
    {
      id: "update-payment-el",
      title: "ACCOUNT_MANAGEMENT_UPDATE_PAYMENT_DETAILS",
      isVisible: checkoutConfig.isUpdatePaymentVisible,
      onClick: () => resetStates() && history.push({ pathname: `/management/${BillsbyDataAction.UPDATE_PAYMENT_DETAILS}`, search: history.location.search })
    },
    {
      id: "manage-allowances-el",
      title: "ACCOUNT_MANAGEMENT_ALLOWANCES",
      translateWith: { allowanceLabel: checkoutConfig?.terminologyAllowancePlural },
      isVisible: state.hasAllowances && checkoutConfig.isManageAllowancesVisible,
      onClick: () => resetStates() && history.push({ pathname: `/management/${BillsbyDataAction.MANAGE_ALLOWANCES}`, search: history.location.search })
    },
    {
      id: "manage-addons-el",
      title: "ACCOUNT_MANAGEMENT_ADDONS",
      translateWith: { addonLabel: checkoutConfig.terminologyAddonPlural },
      isVisible: state.hasAddons && checkoutConfig.isManageAddonsVisible,
      onClick: () => resetStates() && history.push({ pathname: `/management/${BillsbyDataAction.MANAGE_ADDONS}`, search: history.location.search })
    },
    {
      id: "manage-coupons-el",
      title: "ACCOUNT_MANAGEMENT_COUPONS",
      translateWith: { couponLabel: checkoutConfig.terminologyCouponSingular },
      isVisible: false,
      onClick: () => resetStates() && history.push({ pathname: `/management/${BillsbyDataAction.MANAGE_COUPONS}`, search: history.location.search })
    },
    {
      id: "view-billing-history-el",
      title: "ACCOUNT_MANAGEMENT_BILLING_HISTORY",
      isVisible: checkoutConfig.isViewBillingHistoryVisible,
      onClick: () => resetStates() && history.push({ pathname: `/management/${BillsbyDataAction.VIEW_BILLING_HISTORY}`, search: history.location.search })
    },
    {
      id: "cancel-plan-el",
      title: "ACCOUNT_MANAGEMENT_VIEW_CANCEL_PLAN",
      translateWith: { subscriptionLabel: checkoutConfig.terminologySubscriptionSingular },
      isVisible: checkoutConfig.isCancelPlanVisible,
      onClick: () => resetStates() && history.push({ pathname: `/management/${BillsbyDataAction.CANCEL_PLAN}`, search: history.location.search })
    }
  ];



  return (
    <PageWrapper
      title={isRemoveCustomerSubscriptionSuccess ? "" : "ACCOUNT_MANAGEMENT_HEADING"}
      titleTranslateWith={{ firstName: firstName.capitalize() }}
      titlePosition="left-aligned"
      className="account-management"
      btnVisible={false}
      btnBackHidden>
      <CustomerInfoMessage />
      <InteractiveList
        data={data}
        className="account-management__clickable-panel-list"
        isVisible={canSeeMenu}
      />
    </PageWrapper>
  )
}

export default AccountManagement;