import React, { useLayoutEffect, useEffect, useState, useMemo } from "react";
import "./ChangePlanStep.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IChangePlanReducer } from "../../reducers/changePlanReducer";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import { fetchPlans, setPlan, setCycle, setNumberOfUnits, setValidUnitRange, setProduct } from "../../actions/selectPlanActions";
import SelectPlanStep2 from "../select-plan/SelectPlanStep2";
import { ChangePlanScreen, IChangePlanBody, PlanChangeDate } from "../../models/ChangePlan";
import { setChangePlanField, changePlanCycle } from "../../actions/changePlanActions";
import { IPlanWithCycles, PricingModelType, IProduct } from "../../models/Product";
import { IGlobalReducer } from "../../reducers/globalReducer";
import counterpart from "counterpart";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";
import moment from "moment";
import { fetchCustomerSubscriptions, fetchSubscriptionOutstandingBalance, setCurrentSubscription } from "../../actions/subscriptionsActions";
import { IPersonalDetailsReducerState } from "../../reducers/personalDetailsReducer";
import history from "../../utils/history";
import { recalculatePlanCycle, isValidNumberOfUnits } from "../../utils/planUtils";
import { AddOnPricingModelType, IAddOn, IAllowance } from "../../models/AddOnAllowance";
import { IAddOnReducer } from "../../reducers/addOnReducer";
import { AccountManagementPermissions } from "../../models/AccountManagement";
import { ICustomerSubscription } from "../../models/Customer";
import { IRetentionReducer } from "../../reducers/retentionReducer";
import { RetentionScreen } from "../../models/Retention";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import SystemInfo from "../ui/system-info/SystemInfo";
import API from "../../utils/API";
import { fetchProductById } from "../../actions/changePersonalDetailsActions";

interface IChangePlanStep2 {
  /* optional heading text used for example in retention */
  customizedHeading?: string,
  /* optional button under the continue wrapper button */
  bottomContent?: JSX.Element
}

const ChangePlanStep2: React.FC<IChangePlanStep2> = ({ customizedHeading, bottomContent }) => {

  const { selectedSubscription, selectedDateToChange, isChangePlanCycleSuccess, isChangePlanCycleFailure, isUpdateSubscription } = useSelector<AppState, IChangePlanReducer>(state => state.changePlanReducer);
  const { plans, plan: selectedPlan, cycle: selectedCycle, numberOfUnits } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const { addOns, allowances } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isNewPlan, setIsNewPlan] = useState(false);
  const [hasOnePlanAndOneCycle, setHasOnePlanOneCycle] = useState(false);
  const [hasUpdatedPlan, setHasUpdatedPlan] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<{ label: any; value: IPlanWithCycles } | null>(null);
  const { companyDomain, checkoutConfig, accountManagementPermissions } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { currentSubscription, customerActiveSubscriptions } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const personalDetailsReducer = useSelector<AppState, IPersonalDetailsReducerState>(state => state.personalDetailsReducer)
  const customerUniqueId = personalDetailsReducer.mainProps.customerUniqueId as string
  const { currentScreen: retentionCurrentScreen } = useSelector<AppState, IRetentionReducer>(state => state.retentionReducer)
  const dispatch = useDispatch<Function>()


  customizedHeading = customizedHeading || counterpart("CHANGE_PLAN_ADDON_ALLOWANCE_WARNING", { addonLabel: checkoutConfig?.terminologyAddonPlural, allowanceLabel: checkoutConfig?.terminologyAllowancePlural })

  const nextBilling = moment(customerActiveSubscriptions[0].nextBilling).format("DD MMM YYYY");
  const isRetentionChangePlan = retentionCurrentScreen && retentionCurrentScreen === RetentionScreen.changePlan;

  const btnText = useMemo(() => {
    if (!currentSubscription && selectedPlan && (selectedPlan.value.addons.length || selectedPlan.value.allowances.length)) {
      return counterpart("SELECT_PLAN_CONTINUE");
    }
    return counterpart("CHANGE_PLAN_BTN", { planLabel: checkoutConfig ? checkoutConfig.terminologyPlanSingular : "" })
  }, [selectedPlan, checkoutConfig]);

  const forceAddOnAllowances = useMemo(() => {
    const combinedAddOnAllowances: Array<IAddOn | IAllowance> = [...addOns, ...allowances];
    return combinedAddOnAllowances.filter(i => i.isForced);
  }, [allowances, addOns]);

  const fetchPlansAndSetCurrentPlan = async () => {
    if (!selectedSubscription) { return }
    try {
      const { response: plans }: { response: Array<IPlanWithCycles> } = (await dispatch(fetchPlans(selectedSubscription.productId))) as { response: Array<IPlanWithCycles> };
      if (plans) {
        //let currentPlan = plans.find(plan => plan.planId === selectedSubscription.planId);
        let currentPlan = await API.getCurrentPlan(companyDomain, selectedSubscription.productId, selectedSubscription.planId, selectedSubscription.subscriptionUniqueId) as IPlanWithCycles
        if (plans.length === 1 && plans[0].cycles.length === 1) {
          setHasOnePlanOneCycle(true);
        }
        if (currentPlan) {
          let currentProduct = await dispatch(fetchProductById(companyDomain, currentPlan?.productId)) as { response?: IProduct }
          dispatch(setProduct({ label: currentProduct?.response?.name, value: currentProduct.response }))
          const flatFeeAddOns = forceAddOnAllowances.filter(i => i.pricingModelType === AddOnPricingModelType.AddonFlatFee) as Array<IAddOn>;
          currentPlan = currentPlan.pricingModelType !== PricingModelType.FlatFee ? recalculatePlanCycle(currentPlan, selectedSubscription.units as number, flatFeeAddOns) : currentPlan;
          dispatch(setPlan({ label: currentPlan.name, value: currentPlan }));
          setCurrentPlan({ label: currentPlan.name, value: currentPlan });
          dispatch(setNumberOfUnits(selectedSubscription.units));

          const isValidRange = isValidNumberOfUnits(currentPlan, selectedSubscription.units as number);
          if (!isValidRange)
            dispatch(setCycle(undefined));

          dispatch(setValidUnitRange(isValidRange));

          const currentCycle = currentPlan.cycles.find(cycle => cycle.cycleId === selectedSubscription.cycleId);
          if (currentCycle) {
            dispatch(setCycle(currentCycle));
          }
        }
      }
    } catch (err) { }
  };

  // useLayoutEffect(() => {
  //   (isUpdateSubscription || isRetentionChangePlan) && fetchPlansAndSetCurrentPlan();

  //   if (isRetentionChangePlan) {
  //     dispatch(setIsUpdateSubscription(false))
  //   }

  // }, [selectedSubscription, isUpdateSubscription, isRetentionChangePlan]);

  useLayoutEffect(() => {
    if (selectedSubscription) {
      if (isUpdateSubscription && !isRetentionChangePlan) {
        fetchPlansAndSetCurrentPlan();
      } else {
        dispatch(fetchPlans(selectedSubscription.productId));
      }
    }

  }, [selectedSubscription, isUpdateSubscription, isRetentionChangePlan]);

  useEffect(() => {
    if (isChangePlanCycleSuccess || isChangePlanCycleFailure) {
      // redirect + forward history query string
      history.push({ pathname: "/management", search: history.location.search });
    }
  }, [isChangePlanCycleSuccess || isChangePlanCycleFailure]);

  useEffect(() => {
    if ((currentSubscription && currentSubscription.planIdNext) && currentSubscription.planIdNext !== currentSubscription.planId) {
      setHasUpdatedPlan(true)
    } else {
      setHasUpdatedPlan(false)
    }
  }, [currentSubscription])

  useEffect(() => {
    if (selectedSubscription) {
      if (currentPlan && selectedPlan && selectedPlan.value.planId !== currentPlan.value.planId) {
        setIsNewPlan(true);
      }
      else {
        setIsNewPlan(false);
      }
      if (selectedCycle && selectedPlan && selectedSubscription.cycleId === selectedCycle.cycleId
        && selectedSubscription.planId === selectedPlan.value.planId) {
        if (!numberOfUnits || selectedSubscription.units === numberOfUnits) {
          setBtnDisabled(true);
        }
        else {
          setBtnDisabled(false);
        }
      }
      else {
        setBtnDisabled(false);
      }
    }
  }, [selectedPlan, selectedCycle, numberOfUnits]);


  if (!selectedSubscription || !checkoutConfig) {
    return null;
  }

  const onSubmit = async () => {
    if (!selectedPlan || !selectedCycle) {
      return;
    }

    const newAddons = addOns.filter(i => i.isForced || (!!i.units && i.units > 0)).map(addOn => ({ addOnId: addOn.id, quantity: addOn.units || 1 }));
    const newAllowances = allowances.filter(i => i.isForced || (!!i.units && i.units > 0)).map(i => i.id);

    const changePlanRequestBody: IChangePlanBody = {
      planId: selectedPlan.value.planId,
      cycleId: selectedCycle.cycleId,
      customerUniqueId,
      planChangeType: isUpdateSubscription ? PlanChangeDate.NEXT_BILLING : selectedDateToChange,
      addOns: newAddons,
      allowances: newAllowances,
      couponCodes: [],
      units: numberOfUnits ? numberOfUnits : undefined
    }

    try {
      const response = await dispatch(changePlanCycle(companyDomain, selectedSubscription.subscriptionUniqueId, changePlanRequestBody));
      if (response.error && response.error.message === "Subscription_Change_Subscription_Plan_Billing_Error") {
        dispatch(fetchSubscriptionOutstandingBalance(companyDomain, customerUniqueId, selectedSubscription.subscriptionUniqueId));
      }
      dispatch(setChangePlanField("billingDate", nextBilling));
      //cycleId has changed, we have to refetch the subscription
      const { response: subscriptions } = await dispatch(fetchCustomerSubscriptions(companyDomain, customerUniqueId as string)) as { response: Array<ICustomerSubscription> };
      dispatch(setCurrentSubscription(subscriptions.find(s => s.subscriptionUniqueId === currentSubscription?.subscriptionUniqueId)));
    } catch (err) { }
  };

  const goBackCallback = () => {
    if (accountManagementPermissions === AccountManagementPermissions.FULL && history.location.pathname !== "/management") {
      history.push({ pathname: "/management", search: history.location.search });
    }
  }


  if (hasUpdatedPlan && currentSubscription) {
    const newPlanName = plans.find(p => p.planId === currentSubscription.planIdNext)
    return (
      <PageWrapper
        btnBackCallback={goBackCallback}
        title="CHANGE_SUBSCRIPTION_TITLE"
        titleTranslateWith={{ subscriptionLabel: checkoutConfig.terminologySubscriptionSingular }}
        titlePosition="left-aligned"
        btnText={isRetentionChangePlan ? "RETENTION_KEEP_MY_PLAN" : ""}
        btnTextTranslateWith={{ subscriptionLabel: !!checkoutConfig && checkoutConfig.terminologySubscriptionSingular }}
        btnCallback={isRetentionChangePlan ? () => history.goBack() : undefined}
        bottomStickyContent={isRetentionChangePlan ? bottomContent : undefined}
      >
        <SystemInfo
          title=""
          text={counterpart("CHANGE_SUBSCRIPTION_PLAN_UPDATED_NOTICE",
            {
              planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular,
              nextBillingDate: moment(currentSubscription.nextBilling).format("DD MMM YYYY"),
              planName: newPlanName ? newPlanName.displayName : ""
            }
          )}
          type="warning"
          isShowIcon={false}
          shouldTranslateTitle={false}
          shouldTranslateText={false}
        />
      </PageWrapper>
    )
  }


  return (
    <>
      <SelectPlanStep2
        customizedHeading={customizedHeading}
        bottomContent={bottomContent}
        nextBilling={nextBilling}
        btnText={btnText}
        shouldTranslateBtnText={false}
        btnDisabled={btnDisabled}
        btnBackCallback={goBackCallback}
        btnBackHidden={accountManagementPermissions === AccountManagementPermissions.ACTION && isUpdateSubscription}
        warningMessage={hasOnePlanAndOneCycle ? counterpart("PRODUCT_HAS_ONE_PLAN_AND_ONE_CYCLE", { productName: selectedSubscription.productName, planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular }) : undefined}
        isNewPlan={isNewPlan}
        onSubmit={() => (!btnDisabled && !isNewPlan ? onSubmit() : dispatch(setChangePlanField("currentScreen", ChangePlanScreen.SET_ADD_ON)))}
      />
    </>
  );
};

export default ChangePlanStep2
