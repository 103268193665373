import React, { useEffect, useState } from "react";
import "./PaymentDetailsForm.scss";
import FormGroup from "../ui/form-group/FormGroup";
import counterpart from "counterpart";
import { SPREEDLY_ERRORS } from "../../utils/constants";
import { ConfigConstants } from "../../utils/config";
import FormLabel from "../ui/form-label/FormLabel";
import Input from "../ui/input/Input";
import Panel from "../ui/panel/Panel";
import TaxInput from "../tax-input/TaxInput";
import { Col, Row } from "react-grid-system";
import { IAddress } from "../../models/Address";
import { useSelector } from "react-redux";
import { AppState } from "../..";

interface IPaymentDetailsForm {
  onChangeCardholderName: (name: string) => void;
  onChangeExpiryMonth: (month: number) => void;
  onChangeExpiryYear: (year: number) => void;
  cardNumberId: string;
  cvvId: string;
  formErrors?: { cardNumber?: string; expiryMonth?: string; expiryYear?: string } | null;
  formData: {
    cardholderName: string;
    expiryMonth: string;
    expiryYear: string;
  };
  initialCardholderName?: string;
  initialCardNumber?: string;
  initialCvv?: string;
  initialExpiryMonth?: number;
  initialExpiryYear?: number;
  isTaxRequired?: boolean;
  disabled?: boolean;
  className?: string,
}

export interface IPaymentDetailsError {
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
}

export interface ISpreedlyCardData {
  token: string,
  last_four_digits: string,
  card_type: string
  full_name: string,
  month: string,
  year: string
}

export const validatePaymentDetailsForm = (data: { cardholderName: string; expiryMonth: string; expiryYear: string },
  billingAddress: IAddress,
  callback: (cardData: ISpreedlyCardData | null, error: { obj: IPaymentDetailsError, arr: Array<string> } | null) => void) => {
  const Spreedly = (window as any).Spreedly;
  // for some reasons the Spreedly callback 'paymentMethod' triggers multiple times, so to avoid to trigger the callback more than once we use this flag
  let hasPaymentMethodCbTriggered = false;
  const [CARD_EXPIRY_MONTH, CARD_EXPIRY_YEAR, CREDIT_CARD_NUMBER] = ["expiryMonth", "expiryYear", "cardNumber"];

  // remove all handlers every time we invoke this method otherwise we're stacking up many callbacks on the "errors" and "paymentMethod" events
  // this issue may happen if the customer mistype the card details submit the form then type the correct card details and resubmit the form
  Spreedly.removeHandlers();


  Spreedly.on("errors", (errors: Array<{ key: string; message: string; attribute: string }>) => {
    let errorsObject: any = {};
    const attrMap: any = {
      month: CARD_EXPIRY_MONTH,
      year: CARD_EXPIRY_YEAR,
      number: CREDIT_CARD_NUMBER,
    };
    const getErrorMessage = (error: typeof errors[0] ) => {
      let errorMessage = SPREEDLY_ERRORS[error.key] || "SPREEDLY_ERROR_GENERIC";
      if(error.attribute === "year") {
        errorMessage = data.expiryYear.length < 4 ? "SPREEDLY_ERROR_CARD_YEAR_INVALID" : "SPREEDLY_ERROR_CARD_EXPIRED"
      }
      return counterpart(errorMessage, { attribute: error.attribute })
    }
    errors.forEach((err) => {
      errorsObject[attrMap[err.attribute] || CREDIT_CARD_NUMBER] = getErrorMessage(err);
    });

    callback(null, { obj: errorsObject, arr: Object.values(errorsObject) });
  });

  Spreedly.on("paymentMethod", (token: string, cardData: ISpreedlyCardData) => {
    callback(cardData, null);
  });

  Spreedly.tokenizeCreditCard({
    full_name: data.cardholderName,
    month: data.expiryMonth,
    year: data.expiryYear,
    address1: billingAddress.addressLine1,
    address2: billingAddress.addressLine2,
    city: billingAddress.city,
    state: billingAddress.state,
    zip: billingAddress.postCode,
    country: billingAddress.country
  });
};

const PaymentDetailsForm: React.FC<IPaymentDetailsForm> = ({
  formData,
  formErrors,
  onChangeCardholderName,
  onChangeExpiryMonth,
  onChangeExpiryYear,
  cardNumberId,
  cvvId,
  initialCardNumber,
  initialCvv,
  initialExpiryMonth,
  initialExpiryYear,
  initialCardholderName,
  isTaxRequired = false,
  disabled = false
}) => {

  const { branding, checkoutConfig } = useSelector((state: AppState) => state.globalReducer);

  const { cardholderName, expiryMonth, expiryYear } = formData;
  const [cardNumLength, setCardNumLength] = useState(0);

  useEffect(() => {
    //const Spreedly = new (window as any).SpreedlyPaymentFrame();
    const Spreedly = (window as any).Spreedly;

    Spreedly.init(ConfigConstants.spreedlyKey, {
      numberEl: cardNumberId,
      cvvEl: cvvId
    });

    Spreedly.on("ready", function () {
      //placeholders https://docs.spreedly.com/reference/iframe/v1/ui/
      Spreedly.setLabel("number", "1234 5678 1234 5678");
      Spreedly.setLabel("cvv", "123");
      Spreedly.setFieldType("number", "text");
      Spreedly.setNumberFormat("prettyFormat");

      if (initialCardNumber) {
        Spreedly.setValue("number", initialCardNumber);
        setCardNumLength(initialCardNumber.length);
      }
      if (initialCvv) {
        Spreedly.setValue("cvv", initialCvv);
      }

      Spreedly.on("fieldEvent", (name: string, type: string, activeEl: string, inputProperties: { numberLength: number }) => {
        if (name === "number" && inputProperties.numberLength && cardNumLength === 0) {
          setCardNumLength(inputProperties.numberLength)
        }
      })

      /* we cannot style the inputs via css because they're inside the spreedly iframe */
      const spreedlyInputStyle = `font-size: 14px; font-family: ${branding.typographyBody}; color: ${branding.bodyTextColor};`;

      Spreedly.setStyle("cvv", spreedlyInputStyle);
      Spreedly.setStyle("number", spreedlyInputStyle);
    });

  }, []);

  useEffect(() => {
    initialExpiryYear && onChangeExpiryYear(initialExpiryYear);
    initialExpiryMonth && onChangeExpiryMonth(initialExpiryMonth);
    initialCardholderName && onChangeCardholderName(initialCardholderName)
  }, [initialExpiryMonth, initialExpiryYear, initialCardholderName])

  const cardNumberHasError = formErrors && formErrors.cardNumber ? "payment-details-form__spreedly-input--error" : "";
  const expiryMonthHasError = formErrors && formErrors.expiryMonth;
  const expiryYearHasError = formErrors && formErrors.expiryYear;

  const renderCardNumberInput = () => {
    return (
      <div className="payment-details-form__card-number">
        <div
          id={cardNumberId}
          className={`payment-details-form__spreedly-input-container ${!initialCardNumber ? "card-panel" : ""} ${cardNumberHasError} ${!initialCardNumber && cardNumLength > 10 ? "card-panel--shrink-icons" : ""} ${disabled ? "payment-details-form__spreedly-input-container--disabled" : ""}`}
          placeholder="1234 5678 1234 5678"
        />
        <div className={`payment-details-form__card-number__icons ${!initialCardNumber && cardNumLength > 10 ? "payment-details-form__card-number__icons--shrink" : ""}`}>
          {checkoutConfig?.isVisaCardLogoVisible && <i className="fab fa-cc-visa"></i>}
          {checkoutConfig?.isMasterCardLogoVisible && <i className="fab fa-cc-mastercard"></i>}
          {checkoutConfig?.isAmexCardLogoVisible && <i className="fab fa-cc-amex"></i>}
          {checkoutConfig?.isJCBCardLogoVisible && <i className="fab fa-cc-jcb"></i>}
          {checkoutConfig?.isDinersCardLogoVisible && <i className="fab fa-cc-diners-club"></i>}
        </div>
      </div>
    )
  }

  return (
    <Panel id="payment-details-form" className={"payment-details-form"}>
      {isTaxRequired && <TaxInput />}
      <FormGroup>
        <FormLabel content="UPDATE_PAYMENT_DETAILS_CARDHOLDER" />
        <Input
          id="cc-name"
          value={cardholderName}
          placeholder="John Smith"
          name='cardnumber'
          autoComplete='cc-name'
          disabled={!!initialCardholderName}
          showDisabledIcon={true}
          onChange={(evt: any) => onChangeCardholderName(evt.target.value)}
        />
      </FormGroup>

      <Row>
        <Col xs={12} sm={6}>
          <FormLabel content="UPDATE_PAYMENT_DETAILS_CREDIT_CARD" />
          {renderCardNumberInput()}
        </Col>
        <Col xs={4} sm={2}>
          <FormLabel content="UPDATE_PAYMENT_DETAILS_CVV" />
          <div
            id={cvvId}
            className={`payment-details-form__spreedly-input-container ${disabled ? "payment-details-form__spreedly-input-container--disabled" : ""}`}
            placeholder="123"
          />
        </Col>

        <Col xs={8} sm={4}>
          <FormLabel content="UPDATE_PAYMENT_DETAILS_EXPIRATION_DATE" />
          <div className="payment-details-form__expiration-date">
            <Input
              id="cc-month"
              isError={!!expiryMonthHasError}
              warninglayout={!!expiryMonthHasError}
              disabled={!!initialExpiryMonth}
              name="ccmonth"
              autoComplete="cc-exp-month"
              placeholder="MM"
              className={"payment-details-form__expiration-date__month"}
              maxLength="2"
              showDisabledIcon={true}
              value={expiryMonth}
              onChange={(evt: any) => +evt.target.value <= 12 && onChangeExpiryMonth(evt.target.value)}
            />
            <Input
              id="cc-year"
              isError={!!expiryYearHasError}
              warninglayout={!!expiryYearHasError}
              disabled={!!initialExpiryYear}
              name="ccyear"
              autoComplete="cc-exp-year"
              className={"payment-details-form__expiration-date__year"}
              placeholder="YYYY"
              maxLength="4"
              type="number"
              value={expiryYear}
              showDisabledIcon={true}
              onChange={(evt: any) => +evt.target.value < 3000 && onChangeExpiryYear(evt.target.value)}
            />
          </div>

        </Col>
      </Row>
    </Panel>
  );
};

export default PaymentDetailsForm;
