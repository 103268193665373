import { makeGenericActionCreator, makeActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { IChangePlanBody } from "../models/ChangePlan";
import { IChangePlanReducer } from "../reducers/changePlanReducer";
import { PayPendingMinimumTermAmountRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/subscription_pb";
import { SubscriptionServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/SubscriptionServiceClientPb";
import { ConfigConstants } from "../utils/config";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";

export const SET_CHANGE_PLAN_FIELD = "SET_CHANGE_PLAN_FIELD";
type changePlanAllowedFields = keyof IChangePlanReducer;
export const setChangePlanField = makeGenericActionCreator<changePlanAllowedFields>(SET_CHANGE_PLAN_FIELD);

export const RESET_CHANGE_PLAN_STATE = "RESET_CHANGE_PLAN_STATE";
export const resetChangePlanState = makeActionCreator(RESET_CHANGE_PLAN_STATE);

export const SET_IS_UPDATE_SUBSCRIPTION = "SET_IS_UPDATE_SUBSCRIPTION";
export const setIsUpdateSubscription = makeActionCreator(SET_IS_UPDATE_SUBSCRIPTION, "payload");

export const CHANGE_PLAN_REQUEST = "CHANGE_PLAN_REQUEST";
export const CHANGE_PLAN_SUCCESS = "CHANGE_PLAN_SUCCESS";
export const CHANGE_PLAN_FAILURE = "CHANGE_PLAN_FAILURE";

export const CHANGE_PLAN_CYCLE_REQUEST = "CHANGE_PLAN_CYCLE_REQUEST";
export const CHANGE_PLAN_CYCLE_SUCCESS = "CHANGE_PLAN_CYCLE_SUCCESS";
export const CHANGE_PLAN_CYCLE_FAILURE = "CHANGE_PLAN_CYCLE_FAILURE";

export const ADD_DISCOUNT_OFFER_REQUEST = "ADD_DISCOUNT_OFFER_REQUEST";
export const ADD_DISCOUNT_OFFER_SUCCESS = "ADD_DISCOUNT_OFFER_SUCCESS";
export const ADD_DISCOUNT_OFFER_FAILURE = "ADD_DISCOUNT_OFFER_FAILURE";

export const PAY_CONTRACT_TERM_REQUEST = "PAY_CONTRACT_TERM_REQUEST";
export const PAY_CONTRACT_TERM_SUCCESS = "PAY_CONTRACT_TERM_SUCCESS";
export const PAY_CONTRACT_TERM_FAILURE = "PAY_CONTRACT_TERM_FAILURE";

export const changePlan = (companyDomain: string, subscriptionUniqueId: string, changePlanBody: IChangePlanBody) => {
  return {
    types: [CHANGE_PLAN_REQUEST, CHANGE_PLAN_SUCCESS, CHANGE_PLAN_FAILURE],
    callAPI:() => API.changePlan(companyDomain, subscriptionUniqueId, changePlanBody)
  }
}

export const changePlanCycle = (companyDomain: string, subscriptionUniqueId: string, changePlanBody: IChangePlanBody) => {
  return {
    types: [CHANGE_PLAN_CYCLE_REQUEST, CHANGE_PLAN_CYCLE_SUCCESS, CHANGE_PLAN_CYCLE_FAILURE],
    callAPI:() => API.changePlan(companyDomain, subscriptionUniqueId, changePlanBody)
  }
}

export const payContractTerm = (companyDomain: string, customerUniqueId: string, subscriptionUniqueId: string) => {
  const payPendingMinTermRequest = new PayPendingMinimumTermAmountRequest();
  payPendingMinTermRequest.setCompanyDomain(companyDomain);
  payPendingMinTermRequest.setCustomerUniqueId(customerUniqueId);
  payPendingMinTermRequest.setSubscriptionUniqueId(subscriptionUniqueId);

  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);

  return {
    types: [PAY_CONTRACT_TERM_REQUEST, PAY_CONTRACT_TERM_SUCCESS, PAY_CONTRACT_TERM_FAILURE],
    callAPI: () => grpcUnaryCall(payPendingMinTermRequest, subscriptionServiceClient, subscriptionServiceClient.payPendingMinimumTermAmount)
  }
}