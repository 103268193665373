import React, { ReactElement, useLayoutEffect, useMemo, useState } from "react";
import "./AccountManagementRoute.scss";
import { Switch, Route } from "react-router";
import Container from "../container/Container";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IGlobalReducer } from "../../reducers/globalReducer";
import CloseBtn from "../../components/ui/close-btn/CloseBtn";
import ContainerError from "../container-error/ContainerError";
import Text from "../../components/ui/text/Text";
import { fetchBranding } from "../../actions/globalActions";
import history from "../../utils/history";
import { getBrandingInlineStyle } from "../../utils/brandingUtils";
import counterpart from "counterpart";
import { CheckoutViewMode } from "../../models/BillsbyData";
import { fetchCurrentPlan, fetchCustomerSubscriptions } from "../../actions/subscriptionsActions";
import { ICustomerSubscription } from "../../models/Customer";

interface IAccountManagementRoute  {
  children: Array<ReactElement> | ReactElement,
  path: string
}

const validateParams = (globalReducer: IGlobalReducer): { isValid: boolean, subTitle?: JSX.Element } => {
  const { companyDomain } = globalReducer;
  if (!companyDomain) {
    return { isValid: false, subTitle: <Text content="ERROR_INVOKING_BILLSBY_COMPANY_NOT_PRESENT1" noMargin /> }
  }

  return { isValid: true }
}

const AccountManagementRoute: React.FC<IAccountManagementRoute> = ({ children, path }) => {
  const [isValidParams, setIsValidParams] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const globalReducer = useSelector((state: AppState) => state.globalReducer);
  const { companyDomain, isCloseIconVisible, branding, checkoutConfig, checkoutViewMode } = globalReducer;
  const currentSubscription = useSelector((state: AppState) => state.subscriptionReducer.currentSubscription);
  const { customerUniqueId, subscriptionUniqueId  } = useSelector((state: AppState) => state.personalDetailsReducer.mainProps);

  const dispatch = useDispatch<Function>();
  //const { isLoading } = useFetch(() => dispatch(fetchBranding(companyDomain)));

  useLayoutEffect(() => {
    const runCheck = async () => {
      try {
        await dispatch(fetchBranding(companyDomain));
        if ((!customerUniqueId || !subscriptionUniqueId || !currentSubscription) && checkoutViewMode === CheckoutViewMode.STANDARD) {
          return history.push({ pathname: "/login", search: history.location.search });
        }
        if (checkoutViewMode === CheckoutViewMode.STANDALONE) {
          // in standalone mode the customer might now have a subscription
          // for example if a new customers with no subscriptions receives a payment link to update the payment details
          const { response: subscriptions } = await dispatch(fetchCustomerSubscriptions(companyDomain, customerUniqueId as string)) as { response: Array<ICustomerSubscription> };
          if(subscriptions.length) {
            await dispatch(fetchCurrentPlan(companyDomain, Number(subscriptions[0].productId), Number(subscriptions[0].planId), subscriptions[0].subscriptionUniqueId));
          }
        }
      }
      catch(err) {}
      finally {
        setIsLoading(false);
      }
    }
    runCheck();
    const { isValid } = validateParams(globalReducer);
    setIsValidParams(isValid);
  }, []);

  const customBranding = useMemo(() => {
    return getBrandingInlineStyle(branding);
  }, [branding]);

  if (isLoading || !checkoutConfig) {
    return null;
  }

  if (!isValidParams) {
    return (
      <Container>
        <CloseBtn isVisible={isCloseIconVisible} />
        <ContainerError
          isVisibleMessage={true}
          title="ERROR_INVOKING_BILLSBY"
          subTitle={validateParams(globalReducer).subTitle || <div />}
        />
      </Container>
    )
  }

  return (
    <Route path={path}>
      <Container footerText={counterpart("FOOTER_SUBSCRIPTION_TEXT", { subscriptionLabel: checkoutConfig.terminologySubscriptionSingular })}
        footerShouldTranslate={false}>
        <style>{customBranding}</style>
        <div className="account-management-route">
          <Switch>
            {children}
          </Switch>
        </div>
      </Container>
    </Route>
  )
}

export default AccountManagementRoute;