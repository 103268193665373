import { makeActionCreator } from "../utils/reduxUtils";
import API from "../utils/API";
import { AppState, reduxStore } from "..";
import { setNavigationStep } from "./globalActions";
import { NavigationBarSteps } from "../models/NavigationBar";
import { IProduct } from "../models/Product";
import { PersonalDetailsScreen } from "../models/PersonalDetails";
import { IAddress } from "../models/Address";
import { GetTaxValidationRequest, ValidateTaxRequest } from "../../src/utils/grpc/generated/Billsby.Protos/billing/public/company/taxation/taxation_pb";
import { TaxationServiceClient } from "../../src/utils/grpc/generated/Billsby.Protos/billing/public/company/taxation/TaxationServiceClientPb";
import { ConfigConstants } from "../utils/config";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";

export const SET_FIRST_NAME = "SET_FIRST_NAME";
export const setFirstName = makeActionCreator(SET_FIRST_NAME, "payload");

export const SET_LAST_NAME = "SET_LAST_NAME";
export const setLastName = makeActionCreator(SET_LAST_NAME, "payload");

export const SET_EMAIL = "SET_EMAIL";
export const setEmail = makeActionCreator(SET_EMAIL, "payload");

export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const setCompanyName = makeActionCreator(SET_COMPANY_NAME, "payload");


export const SET_ADDRESS_LINE_1 = "SET_ADDRESS_LINE_1";
export const setAddressLine1 = makeActionCreator(SET_ADDRESS_LINE_1, "isBilling", "payload");

export const SET_ADDRESS_LINE_2 = "SET_ADDRESS_LINE_2";
export const setAddressLine2 = makeActionCreator(SET_ADDRESS_LINE_2, "isBilling", "payload");

export const SET_CITY = "SET_CITY";
export const setCity = makeActionCreator(SET_CITY, "isBilling", "payload");

export const SET_STATE = "SET_STATE";
export const setState = makeActionCreator(SET_STATE, "isBilling", "payload");

export const RESET_COUNTRY_STATE = "RESET_COUNTRY_STATE";
export const resetCountryState = makeActionCreator(RESET_COUNTRY_STATE, "isBilling");

export const SET_ZIP_CODE = "SET_ZIP_CODE";
export const setZipCode = makeActionCreator(SET_ZIP_CODE, "isBilling", "payload");

export const SET_COUNTRY = "SET_COUNTRY";
export const setCountry = makeActionCreator(SET_COUNTRY, "isBilling", "payload");

export const SET_PERSONALDETAILS_CURRENT_SCREEN = "SET_PERSONALDETAILS_CURRENT_SCREEN";
export const setPersonalDetailsCurrentScreen = makeActionCreator(SET_PERSONALDETAILS_CURRENT_SCREEN, "payload");

export const SET_SHIPPING_AS_BILLING_FLAG = "SET_SHIPPING_AS_BILLING_FLAG";
export const setShippingAsBillingFlag = makeActionCreator(SET_SHIPPING_AS_BILLING_FLAG, "payload");

export const SET_TAX_REG_NUMBER = "SET_TAX_REG_NUMBER";
export const setTaxRegNumber = makeActionCreator(SET_TAX_REG_NUMBER, "payload");

export const SET_TAX_REG_ERROR = "SET_TAX_REG_ERROR";
export const setTaxRegError = makeActionCreator(SET_TAX_REG_ERROR, "payload");

export const RESET_PERSONAL_DETAILS_ERRORS = "RESET_PERSONAL_DETAILS_ERRORS";
export const resetPersonalDetailsErrors = makeActionCreator(RESET_PERSONAL_DETAILS_ERRORS);

export const RESET_PERSONAL_ADDRESS_ERRORS = "RESET_PERSONAL_ADDRESS_ERRORS";
export const resetPersonalAddressErrors = makeActionCreator(RESET_PERSONAL_ADDRESS_ERRORS);

export const SET_SHIPPING_AS_BILLING_ADDRESS = "SET_SHIPPING_AS_BILLING_ADDRESS";

export const SET_FIELD = "SET_FIELD";
export const setField = makeActionCreator(SET_FIELD, "fieldName", "payload");
export const VALIDATE_BILLING_ADDRESS = "VALIDATE_BILLING_ADDRESS";
export const VALIDATE_SHIPPING_ADDRESS = "VALIDATE_SHIPPING_ADDRESS";
export const VALIDATE_CUSTOM_FIELDS = "VALIDATE_CUSTOM_FIELDS";
export const SET_CUSTOM_FIELD_VALUE = "SET_CUSTOM_FIELD_VALUE";
export const setCustomFieldValue = makeActionCreator(SET_CUSTOM_FIELD_VALUE, "index", "payload");

export const SET_CUSTOMER_UNIQUE_ID = "SET_CUSTOMER_UNIQUE_ID";
export const setCustomerUniqueId = makeActionCreator(SET_CUSTOMER_UNIQUE_ID, "payload");

export const SET_SUBSCRIPTION_UNIQUE_ID = "SET_SUBSCRIPTION_UNIQUE_ID";
export const setSubscriptionUniqueId = makeActionCreator(SET_SUBSCRIPTION_UNIQUE_ID, "payload");

export const SET_IS_USER_DATA_SCREEN_PRELOADED = "SET_IS_USER_DATA_SCREEN_PRELOADED";
export const setIsUserDataScreenPreloaded = makeActionCreator(SET_IS_USER_DATA_SCREEN_PRELOADED, "payload");

export const SET_IS_ADDRESS_SCREEN_PRELOADED = "SET_IS_ADDRESS_SCREEN_PRELOADED";
export const setIsAddressScreenPreloaded = makeActionCreator(SET_IS_ADDRESS_SCREEN_PRELOADED, "payload");


export const SET_IS_BILLING_ADDRESS_MANUAL = "SET_IS_BILLING_ADDRESS_MANUAL";
export const setIsBillingAddressManual = makeActionCreator(SET_IS_BILLING_ADDRESS_MANUAL, "payload");

export const SET_IS_SHIPPING_ADDRESS_MANUAL = "SET_IS_SHIPPING_ADDRESS_MANUAL";
export const setIsShippingAddressManual = makeActionCreator(SET_IS_SHIPPING_ADDRESS_MANUAL, "payload");

export const validateAddress = () => {
  // custom thunk to dispatch multiple actions
  // https://daveceddia.com/what-is-a-thunk/#actions-are-boring

  return (dispatch: Function, getState: () => AppState) => {
    const { shippingAsBilling } = getState().personalDetailsReducer;
    const product: { label: string, value: IProduct } = getState().selectPlanReducer.product as { label: string, value: IProduct };
    if (shippingAsBilling) {
      dispatch({ type: SET_SHIPPING_AS_BILLING_ADDRESS });
    }
    dispatch({ type: VALIDATE_BILLING_ADDRESS });
    if (product && product.value.isShippingAddressRequired && !shippingAsBilling) {
      dispatch({ type: VALIDATE_SHIPPING_ADDRESS });
    }
    const { addressDataErrors, shippingAddressDataErrors } = getState().personalDetailsReducer;
    if (!Object.keys(addressDataErrors).length && !Object.keys(shippingAddressDataErrors).length) {
      // no errors
      dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.CUSTOM_FIELDS));
    }
  }
}

export const validateChangeAddress = (callback: () => void) => {

  return (dispatch: Function, getState: () => AppState) => {
    const { shippingAsBilling } = getState().personalDetailsReducer;
    const { currentSubscriptionDetails } = getState().subscriptionReducer;
    dispatch({ type: VALIDATE_BILLING_ADDRESS });
    if (currentSubscriptionDetails?.isShippingAddressRequired && !shippingAsBilling) {
      dispatch({ type: VALIDATE_SHIPPING_ADDRESS });
    }
    const { addressDataErrors, shippingAddressDataErrors } = getState().personalDetailsReducer;
    if (!Object.keys(addressDataErrors).length && !Object.keys(shippingAddressDataErrors).length) {
      callback();
    }
  }
}

export const validateCustomFields = () => {
  return (dispatch: Function, getState: () => AppState) => {
    dispatch({ type: VALIDATE_CUSTOM_FIELDS });

    const { errors } = getState().personalDetailsReducer.fields;
    if (!Object.keys(errors).length) {
      dispatch(setNavigationStep(NavigationBarSteps.PAYMENT_METHOD));
    }
  }
}

export const FETCH_COUNTRIES_REQUEST = "FETCH_COUNTRIES_REQUEST";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const FETCH_COUNTRIES_FAILURE = "FETCH_COUNTRIES_FAILURE";

export const FETCH_CUSTOM_FIELDS_REQUEST = "FETCH_CUSTOM_FIELDS_REQUEST";
export const FETCH_CUSTOM_FIELDS_SUCCESS = "FETCH_CUSTOM_FIELDS_SUCCESS";
export const FETCH_CUSTOM_FIELDS_FAILURE = "FETCH_CUSTOM_FIELDS_FAILURE";

export const FETCH_SHIPPING_ADDRESS_REQUEST = "FETCH_SHIPPING_ADDRESS_REQUEST";
export const FETCH_SHIPPING_ADDRESS_SUCCESS = "FETCH_SHIPPING_ADDRESS_SUCCESS";
export const FETCH_SHIPPING_ADDRESS_FAILURE = "FETCH_SHIPPING_ADDRESS_FAILURE";

export const FETCH_TAX_VALIDATION_REQUEST = "FETCH_TAX_VALIDATION_REQUEST";
export const FETCH_TAX_VALIDATION_SUCCESS = "FETCH_TAX_VALIDATION_SUCCESS";
export const FETCH_TAX_VALIDATION_FAILURE = "FETCH_TAX_VALIDATION_FAILURE";

export const UPDATE_SHIPPING_ADDRESS_REQUEST = "UPDATE_SHIPPING_ADDRESS_REQUEST";
export const UPDATE_SHIPPING_ADDRESS_SUCCESS = "UPDATE_SHIPPING_ADDRESS_SUCCESS";
export const UPDATE_SHIPPING_ADDRESS_FAILURE = "UPDATE_SHIPPING_ADDRESS_FAILURE";

export const VALIDATE_TAX_REQUEST = "VALIDATE_TAX_REQUEST";
export const VALIDATE_TAX_SUCCESS = "VALIDATE_TAX_SUCCESS";
export const VALIDATE_TAX_FAILURE = "VALIDATE_TAX_FAILURE";

/**********************  ASYNCHRONOUS ACTIONS (api requests) ********************/

export const fetchCountries = function (companyDomain: string, productId: number) {
  return {
    types: [FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRIES_FAILURE],
    callAPI: () => API.getProductCountries(companyDomain, productId)
  }
}

export const fetchCustomFields = function (productId: number) {
  return {
    types: [FETCH_CUSTOM_FIELDS_REQUEST, FETCH_CUSTOM_FIELDS_SUCCESS, FETCH_CUSTOM_FIELDS_FAILURE],
    callAPI: () => API.getCustomFields(reduxStore.getState().globalReducer.companyDomain, productId)
  }
}

export const fetchShippingAddress = function (companyDomain: string, subscriptionUniqueId: string) {
  return {
    types: [FETCH_SHIPPING_ADDRESS_REQUEST, FETCH_SHIPPING_ADDRESS_SUCCESS, FETCH_SHIPPING_ADDRESS_FAILURE],
    callAPI: () => API.fetchShippingAddress(companyDomain, subscriptionUniqueId)
  }
}

export const updateShippingAddress = (companyDomain: string, subscriptionUniqueId: string, payload: IAddress) => {
  return {
    types: [UPDATE_SHIPPING_ADDRESS_REQUEST, UPDATE_SHIPPING_ADDRESS_SUCCESS, UPDATE_SHIPPING_ADDRESS_FAILURE],
    callAPI: () => API.updateShippingAddress(companyDomain, subscriptionUniqueId, payload)
  }
}

export const fetchTaxValidation = (companyDomain: string, countryIso3Code: string) => {
  const getTaxValidationRequest = new GetTaxValidationRequest();
  getTaxValidationRequest.setCompanyDomain(companyDomain);
  getTaxValidationRequest.setCountryIso3Code(countryIso3Code)

  const taxationServiceClient = new TaxationServiceClient(ConfigConstants.grpcBaseUrl);
  
  return {
    types: [FETCH_TAX_VALIDATION_REQUEST, FETCH_TAX_VALIDATION_SUCCESS, FETCH_TAX_VALIDATION_FAILURE],
    callAPI: () => grpcUnaryCall(getTaxValidationRequest, taxationServiceClient, taxationServiceClient.getTaxValidationConfig)
  }
}

export const validateTaxRegNumber = (companyDomain: string, countryIso3Code: string, taxCode: string) => {
  const validateTaxRequest = new ValidateTaxRequest();
  validateTaxRequest.setCompanyDomain(companyDomain);
  validateTaxRequest.setCountryIso3Code(countryIso3Code);
  validateTaxRequest.setCustomerTaxCode(taxCode);

  const taxationServiceClient = new TaxationServiceClient(ConfigConstants.grpcBaseUrl);
  
  return {
    types: [VALIDATE_TAX_REQUEST, VALIDATE_TAX_SUCCESS, VALIDATE_TAX_FAILURE],
    callAPI: () => grpcUnaryCall(validateTaxRequest, taxationServiceClient, taxationServiceClient.validateTax)
  }
}