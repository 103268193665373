import React from "react";
import "./CyclePriceInfo.scss";
import Panel from "../../ui/panel/Panel";
import Text from "../../ui/text/Text";
import TieredCyclePriceInfo from "./TieredCyclePriceInfo";
import RangedCyclePricingInfo from "./RangedCyclePricingInfo";
import VolumeCylePricingInfo from "./VolumeCylePricingInfo";
import { getCycleFrequencyText, getSetupFeeText, isAddOn, getIncludedUnitsText } from "../../../utils/planUtils";
import { ITier, IPlanWithCycles, ICycle } from "../../../models/Product";
import { ICheckoutConfig } from "../../../models/BillsbyData";
import { IBranding } from "../../../models/Branding";
import { IAddOnPricingModel, IAddOn, AddOnPricingModelType, IAllowancePricingModel, IAllowance, AllowancePricingModelType } from "../../../models/AddOnAllowance";
import FormLabel from "../../ui/form-label/FormLabel";

interface IAddOnPriceInfo {
  checkoutConfig: ICheckoutConfig;
  branding: IBranding;
  addOn?: IAddOn | IAllowance;
  pricingModel?: IAddOnPricingModel | IAllowancePricingModel;
  plan: IPlanWithCycles;
  cycle: ICycle
}

const AddOnPriceInfo: React.FC<IAddOnPriceInfo> = ({ checkoutConfig, pricingModel, addOn, plan, cycle }) => {

  if (!addOn || !pricingModel) return null;
  
  const getSetupFee = () => (!cycle || !cycle.pricingModel.setupFeePriceFormatted) ? "" : getSetupFeeText(cycle.pricingModel.setupFeePriceFormatted, checkoutConfig);

  const getDescription = (tier: ITier) => {
    if (!plan) return null;

    let frequency = "";
    if (pricingModel)
      frequency = getCycleFrequencyText(pricingModel.frequency, pricingModel.frequencyType);

    switch (addOn.pricingModelType) {
      case AddOnPricingModelType.AddonTiered:
      case AllowancePricingModelType.AllowanceTiered:
        return (
          <TieredCyclePriceInfo
            tier={tier}
            checkoutConfig={checkoutConfig}
            frequency={frequency}
            unit={addOn.singleUnitName ? addOn.singleUnitName : checkoutConfig.terminologyUnitSingular}
            units={addOn.pluralUnitName ? addOn.pluralUnitName : checkoutConfig.terminologyUnitPlural}
          />
        );
      case AddOnPricingModelType.AddonRanged:
      case AllowancePricingModelType.AllowanceRanged:
        return (
          <RangedCyclePricingInfo
            tier={tier}
            checkoutConfig={checkoutConfig}
            frequency={frequency}
            unit={addOn.singleUnitName ? addOn.singleUnitName : checkoutConfig.terminologyUnitSingular}
            units={addOn.pluralUnitName ? addOn.pluralUnitName : checkoutConfig.terminologyUnitPlural}
          />
        );
      case AddOnPricingModelType.AddonVolume:
      case AllowancePricingModelType.AllowanceVolume:
        return (
          <VolumeCylePricingInfo
            tier={tier}
            checkoutConfig={checkoutConfig}
            frequency={frequency}
            unit={addOn.singleUnitName ? addOn.singleUnitName : checkoutConfig.terminologyUnitSingular}
            units={addOn.pluralUnitName ? addOn.pluralUnitName : checkoutConfig.terminologyUnitPlural}
          />
        );
    }
  };

  const getPricingInfoDescription = () => {
    if (!addOn) return "SELECT_ADD_ON_TIERED_CYCLE_DESCRIPTION";

    switch (addOn.pricingModelType) {
      case AddOnPricingModelType.AddonTiered:
        return "SELECT_ADD_ON_TIERED_CYCLE_DESCRIPTION";
      case AddOnPricingModelType.AddonRanged:
        return "SELECT_ADD_ON_RANGED_CYCLE_DESCRIPTION";
      case AddOnPricingModelType.AddonVolume:
        return "SELECT_ADD_ON_VOLUME_CYCLE_DESCRIPTION";
      default:
        return "SELECT_ADD_ON_TIERED_CYCLE_DESCRIPTION";
    }
  };

  const getHeading = () => {
    if (isAddOn(addOn)) {
      return (
        <>
          {getSetupFee() ? 
          (
            <Text
              className='cycle-price-info__plan-info'
              content={getSetupFee()}
              shouldTranslate={false}
              noMargin
            />
          )
          : null }
          <Text className='cycle-price-info__message' content={getPricingInfoDescription()}
            translateWith={{
              addonLabel: checkoutConfig?.terminologyAddonSingular,
              singleUnitName: addOn.singleUnitName ? addOn.singleUnitName : checkoutConfig.terminologyUnitSingular,
              pluralUnitName: addOn.pluralUnitName ? addOn.pluralUnitName : checkoutConfig.terminologyUnitPlural
            }} noMargin />

        </>
      )
    }
    else {
      return (
        <>
          <Text
            className='cycle-price-info__message'
            content={"SELECT_ALLOWANCE_OVERAGE_TIERED_DESCRIPTION"
              /*addOn.pricingModelType === AllowancePricingModelType.AllowanceTiered
              ? "SELECT_ALLOWANCE_OVERAGE_TIERED_DESCRIPTION"
              : addOn.pricingModelType === AllowancePricingModelType.AllowanceVolume
                ? "SELECT_ALLOWANCE_OVERAGE_VOLUME_DESCRIPTION"
                : "SELECT_ALLOWANCE_OVERAGE_RANGED_DESCRIPTION"*/
            }
            translateWith={{
              singleUnitName: addOn.singleUnitName ? addOn.singleUnitName : checkoutConfig.terminologyUnitSingular,
              pluralUnitName: addOn.pluralUnitName ? addOn.pluralUnitName : checkoutConfig.terminologyUnitPlural
            }}
            noMargin
          />
          <Text
            content={getIncludedUnitsText(pricingModel as IAllowancePricingModel, addOn as IAllowance, checkoutConfig)}
            shouldTranslate={false}
            noMargin
          />
        </>
      )
    }
  }

  return (
    <div>
      <FormLabel
        className='cycle-price-info__addon-title'
        content={isAddOn(addOn) ? "ADD_ON_PRICE_INFO_TITLE" : "ALLOWANCE_PRICE_INFO_TITLE"}
        translateWith={{unitsName: addOn.pluralUnitName}}
        noMargin
      />
      {getHeading()}

      <div>
        {pricingModel && pricingModel.tiers && pricingModel.tiers
          .sort((a: ITier, b: ITier) => a.start > b.start ? 1 : -1)
          .map((tier, index) => (
          <Panel id="cycle-price-info__tiered-price-model" noMargin className='cycle-price-info__tiered-price__panel' key={`tier-${index}`}>
            {getDescription(tier)}
          </Panel>
        ))}      
      </div>
    </div>
  );
};

export default AddOnPriceInfo;
