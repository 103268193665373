import { AppState } from "..";
import { IStripeACHSetupIntent } from "../models/AchData";
import { IAddOn, IAllowance } from "../models/AddOnAllowance";
import { BillsbyDataParams, BillsbyDataType, ContainerWebsite, IBillsbyData } from "../models/BillsbyData";
import { PaymentScreen, PaymentMethodType } from "../models/Payment";
const queryStringLib = require("query-string");


const encodeJSONtoStrig = (billsbyData: any) => {
  return encodeURIComponent(JSON.stringify(billsbyData));
};

/**
 * this method simulates the embed code functionality and given a redux store it returns a query string
 * with the current checkout state the same way the checkoutlib project does it when it passes through 
 * the embed code to the checkout iframe
 * @param reduxState 
 */
export const reduxStateToQueryString = (reduxState: AppState, stripeSetupIntent: IStripeACHSetupIntent): string => {
	
	const { globalReducer, selectPlanReducer, personalDetailsReducer, addOnReducer, paymentReducer, achReducer } = reduxState
	const { companyDomain } = globalReducer
	const { product, plan, cycle, numberOfUnits } = selectPlanReducer
	const { 
		mainProps: { firstName, lastName, email, companyName }, address, shippingAddress, 
		fields: { additionalEmails, phoneNumber, marketingConsent, phoneNumberPrefix, phoneNumberCountry, customFields }
	} = personalDetailsReducer

	const { addOns, allowances } = addOnReducer

	const { currentScreen: paymentCurrentScreen } = paymentReducer

	const { stripeCustomerId, stripeBankName, stripeLast4Digits, accountHolder } = achReducer

	const selectedAddOns = addOns.filter(addon => !!addon.units && addon.units > 0)
	const selectedAllowances = allowances.filter(allowance => !!allowance.units && allowance.units > 0)

	const getPaymentSourceType = () => {
		if(paymentCurrentScreen === PaymentScreen.ACH) {
			return PaymentMethodType.Ach
		}
		if(paymentCurrentScreen === PaymentScreen.CREDIT_CARD) {
			return PaymentMethodType.CreditCard
		}
		return undefined
	}

	let customBillsbyData: IBillsbyData = {
		//cid?: string,
		containerWebsite: ContainerWebsite.BILLSBY_APP,
		firstName: firstName as string,
		lastName: lastName as string,
		email: email as string,
		billingAddressLine1: address.address1,
		billingAddressLine2: address.address2,
		billingAddressCity: address.city,
		billingAddressState: address.state,
		billingAddressZip: address.zipCode,
		billingAddressCountry: address.country?.value?.iso3Code,
		shippingAddressLine1: shippingAddress.address1,
		shippingAddressLine2: shippingAddress.address2,
		shippingAddressCity: shippingAddress.city,
		shippingAddressState: shippingAddress.state,
		shippingAddressZip: shippingAddress.zipCode,
		shippingAddressCountry: shippingAddress.country?.value.iso3Code,
		companyName: companyName || undefined, 
		additionalEmails: additionalEmails,
		phoneNumberDialCode: phoneNumberPrefix,
		phoneNumberDialCountry: phoneNumberCountry,
		phoneNumber: phoneNumber,
		marketingConsent: marketingConsent,
		customFields: customFields,
		paymentSourceType: getPaymentSourceType(),
		achSession: {
			customerId: stripeCustomerId,
			fullName: accountHolder,
			last4Digits: stripeLast4Digits,
			bankName: stripeBankName,
			stripePaymentId: stripeSetupIntent.payment_method
		}
	}

	const getAddonAllowanceFormatted = (arr: Array<IAddOn | IAllowance>): string => {
		if(!arr.length) {
			// this is a trick to skip the addon/allowance screen when we reload the checkout by passing through an invalid id
			return JSON.stringify([-1])
		}
		return JSON.stringify(arr.map(a => ([a.id, a.units])))
	}

	let billsbyDataAttribute = {
		[BillsbyDataParams.type]: BillsbyDataType.CHECKOUT,
		[BillsbyDataParams.company]: companyDomain,
		[BillsbyDataParams.product]: product?.value.productId,
		[BillsbyDataParams.plan]: plan?.value.planId,
		[BillsbyDataParams.cycle]: cycle?.cycleId,
		[BillsbyDataParams.units]: numberOfUnits,
		//[BillsbyDataParams.customer]: "customerid",
		[BillsbyDataParams.addons]: getAddonAllowanceFormatted(selectedAddOns),
		[BillsbyDataParams.allowances]: getAddonAllowanceFormatted(selectedAllowances)
	}


	const queryString = `${queryStringLib.stringify(billsbyDataAttribute)}&billsbyData=${encodeJSONtoStrig(customBillsbyData)}`;
	return queryString
}


