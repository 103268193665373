import { makeActionCreator } from "../utils/reduxUtils";
import { GetPendingMinimumTermAmountRequest, GetSubscriptionUnpaidAmountRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/subscription_pb";
import { SubscriptionServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/SubscriptionServiceClientPb";
import API from "../utils/API";
import { grpcUnaryCall } from "../utils/grpc/grpcUtils";
import { ConfigConstants } from "../utils/config";
import { GetCancellationDetailsRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/subscription/subscription_pb";
import { GetRefundPolicyRequest } from "../utils/grpc/generated/Billsby.Protos/billing/public/refundpolicy/refundpolicy_pb";
import { RefundPolicyServiceClient } from "../utils/grpc/generated/Billsby.Protos/billing/public/refundpolicy/RefundpolicyServiceClientPb";
import { IPlanWithCycles } from "../models/Product";


export const RESET_SUBSCRIPTION_STATE = "RESET_SUBSCRIPTION_STATE";
//type resetCancelSubscriptionFields = "hasInvalidParams";
export const resetSubscriptionState = makeActionCreator(RESET_SUBSCRIPTION_STATE);

export const SET_CURRENT_SUBSCRIPTION = "SET_CURRENT_SUBSCRIPTION";
export const setCurrentSubscription = makeActionCreator(SET_CURRENT_SUBSCRIPTION, "payload");

export const FETCH_CUSTOMER_SUBSCRIPTIONS_REQUEST = "FETCH_CUSTOMER_SUBSCRIPTIONS_REQUEST";
export const FETCH_CUSTOMER_SUBSCRIPTIONS_SUCCESS = "FETCH_CUSTOMER_SUBSCRIPTIONS_SUCCESS";
export const FETCH_CUSTOMER_SUBSCRIPTIONS_FAILURE = "FETCH_CUSTOMER_SUBSCRIPTIONS_FAILURE";

export const FETCH_CUSTOMER_SUBSCRIPTION_DETAILS_REQUEST = "FETCH_CUSTOMER_SUBSCRIPTION_DETAILS_REQUEST";
export const FETCH_CUSTOMER_SUBSCRIPTION_DETAILS_SUCCESS = "FETCH_CUSTOMER_SUBSCRIPTION_DETAILS_SUCCESS";
export const FETCH_CUSTOMER_SUBSCRIPTION_DETAILS_FAILURE = "FETCH_CUSTOMER_SUBSCRIPTION_DETAILS_FAILURE";

export const FETCH_SUBSCRIPTION_OUTSTANDING_BALANCE_REQUEST = "FETCH_SUBSCRIPTION_OUTSTANDING_BALANCE_REQUEST";
export const FETCH_SUBSCRIPTION_OUTSTANDING_BALANCE_SUCCESS = "FETCH_SUBSCRIPTION_OUTSTANDING_BALANCE_SUCCESS";
export const FETCH_SUBSCRIPTION_OUTSTANDING_BALANCE_FAILURE = "FETCH_SUBSCRIPTION_OUTSTANDING_BALANCE_FAILURE";

export const FETCH_CANCELLATION_DETAILS_REQUEST = "FETCH_CANCELLATION_DETAILS_REQUEST";
export const FETCH_CANCELLATION_DETAILS_SUCCESS = "FETCH_CANCELLATION_DETAILS_SUCCESS";
export const FETCH_CANCELLATION_DETAILS_FAILURE = "FETCH_CANCELLATION_DETAILS_FAILURE";

export const FETCH_PENDING_MIN_TERM_REQUEST = "FETCH_PENDING_MIN_TERM_REQUEST";
export const FETCH_PENDING_MIN_TERM_SUCCESS = "FETCH_PENDING_MIN_TERM_SUCCESS";
export const FETCH_PENDING_MIN_TERM_FAILURE = "FETCH_PENDING_MIN_TERM_FAILURE";

export const FETCH_CURRENT_PLAN_REQUEST = "FETCH_CURRENT_PLAN_REQUEST";
export const FETCH_CURRENT_PLAN_SUCCESS = "FETCH_CURRENT_PLAN_SUCCESS";
export const FETCH_CURRENT_PLAN_FAILURE = "FETCH_CURRENT_PLAN_FAILURE";

export const FETCH_REFUND_POLICY_REQUEST = "FETCH_REFUND_POLICY_REQUEST";
export const FETCH_REFUND_POLICY_SUCCESS = "FETCH_REFUND_POLICY_SUCCESS";
export const FETCH_REFUND_POLICY_FAILURE = "FETCH_REFUND_POLICY_FAILURE";

export function fetchCustomerSubscriptions(companyDomain: string, customerUniqueId: string) {
  return {
    types: [FETCH_CUSTOMER_SUBSCRIPTIONS_REQUEST, FETCH_CUSTOMER_SUBSCRIPTIONS_SUCCESS, FETCH_CUSTOMER_SUBSCRIPTIONS_FAILURE],
    callAPI: () => API.getCustomerSubscriptions(companyDomain, customerUniqueId)
  }
}

export function fetchCustomerSubscriptionDetails(companyDomain: string, subscriptionUniqueId: string) {
  return {
    types: [FETCH_CUSTOMER_SUBSCRIPTION_DETAILS_REQUEST, FETCH_CUSTOMER_SUBSCRIPTION_DETAILS_SUCCESS, FETCH_CUSTOMER_SUBSCRIPTION_DETAILS_FAILURE],
    callAPI: () => API.getCustomerSubscriptionDetails(companyDomain, subscriptionUniqueId)
  }
}


export function fetchSubscriptionOutstandingBalance(companyDomain: string, customerUniqueId: string, subscriptionUniqueId: string) {
  const getSubscriptionUnpaidAmountRequest = new GetSubscriptionUnpaidAmountRequest();
  getSubscriptionUnpaidAmountRequest.setCompanyDomain(companyDomain);
  getSubscriptionUnpaidAmountRequest.setCustomerUniqueId(customerUniqueId);
  getSubscriptionUnpaidAmountRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_SUBSCRIPTION_OUTSTANDING_BALANCE_REQUEST, FETCH_SUBSCRIPTION_OUTSTANDING_BALANCE_SUCCESS, FETCH_SUBSCRIPTION_OUTSTANDING_BALANCE_FAILURE],
    callAPI: () => grpcUnaryCall(getSubscriptionUnpaidAmountRequest, subscriptionServiceClient, subscriptionServiceClient.getSubscriptionUnpaidAmount)
  }
}

export function fetchCurrentPlan(companyDomain: string, productId: number, planId: number, subscriptionUniqueId: string) {
  return async (dispatch: Function) => {
    try {
      dispatch({ type: FETCH_CURRENT_PLAN_REQUEST });
      const currentPlan = await API.getCurrentPlan(companyDomain, productId, planId, subscriptionUniqueId) as IPlanWithCycles
      dispatch({ type: FETCH_CURRENT_PLAN_SUCCESS, response: currentPlan })
    }
    catch(err) {
      return dispatch({ type: FETCH_CURRENT_PLAN_FAILURE });
    }
  }
}

export function fetchSubscriptionCancellationDetails(companyDomain: string, customerUniqueId: string, subscriptionUniqueId: string) {
  const getCancellationDetailsRequest = new GetCancellationDetailsRequest();
  getCancellationDetailsRequest.setCompanyDomain(companyDomain);
  getCancellationDetailsRequest.setCustomerUniqueId(customerUniqueId);
  getCancellationDetailsRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_CANCELLATION_DETAILS_REQUEST, FETCH_CANCELLATION_DETAILS_SUCCESS, FETCH_CANCELLATION_DETAILS_FAILURE],
    callAPI: () => grpcUnaryCall(getCancellationDetailsRequest, subscriptionServiceClient, subscriptionServiceClient.getCancellationDetails)
  }
}

export function fetchPendingMinimumTermAmount(companyDomain: string, customerUniqueId: string, subscriptionUniqueId: string) {
  const getPendingMinTermAmountRequest = new GetPendingMinimumTermAmountRequest();
  getPendingMinTermAmountRequest.setCompanyDomain(companyDomain);
  getPendingMinTermAmountRequest.setCustomerUniqueId(customerUniqueId);
  getPendingMinTermAmountRequest.setSubscriptionUniqueId(subscriptionUniqueId);
  const subscriptionServiceClient = new SubscriptionServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_PENDING_MIN_TERM_REQUEST, FETCH_PENDING_MIN_TERM_SUCCESS, FETCH_PENDING_MIN_TERM_FAILURE],
    callAPI: () => grpcUnaryCall(getPendingMinTermAmountRequest, subscriptionServiceClient, subscriptionServiceClient.getPendingMinimumTermAmount)
  }
}

export function fetchRefundPolicy(companyDomain: string) {
  const getRefundPolicyRequest = new GetRefundPolicyRequest();
  getRefundPolicyRequest.setCompanyDomain(companyDomain);
  const refundPolicyServiceClient = new RefundPolicyServiceClient(ConfigConstants.grpcBaseUrl);
  return {
    types: [FETCH_REFUND_POLICY_REQUEST, FETCH_REFUND_POLICY_SUCCESS, FETCH_REFUND_POLICY_FAILURE],
    callAPI: () => grpcUnaryCall(getRefundPolicyRequest, refundPolicyServiceClient, refundPolicyServiceClient.getRefundPolicy)
  }
}