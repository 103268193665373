import { ICouponSubscription } from "./DiscountCoupon";

export enum ChangePlanScreen {
  STEP1 = "STEP1",
  STEP2 = "STEP2",
  STEP3 = "STEP3",
  STEP4 = "STEP4",
  SET_ADD_ON = "SET_ADD_ON",
  SET_ALLOWANCES = "SET_ALLOWANCES",
  END_CONTRACT = "END_CONTRACT" 
  //SET_FORCED_ADDON_STEP = "SET_FORCED_ADDON_STEP",
  //SELECT_OPTIONAL_ADDON = "SELECT_OPTIONAL_ADDON",
  //SET_OPTIONAL_ADDON = "SET_OPTIONAL_ADDON",
  //SET_COUPONS = "SET_COUPONS",
}

export enum PlanChangeDate {
  IMMEDIATE = 1,
  NEXT_BILLING = 2
}

export interface IChangePlanBody {
  planId: number,
  cycleId: number,
  customerUniqueId: string
  planChangeType: PlanChangeDate,
  addOns: Array<{ addOnId: number, quantity: number }>,
  allowances: Array<number>,
  couponCodes: Array<ICouponSubscription>,
  units?: number
}