import React, { useEffect, useState, useMemo } from "react";
import "./SelectPlanStep.scss";
import PageWrapper from "../../containers/page-wrapper/PageWrapper";
import Panel from "../ui/panel/Panel";
import Text from "../ui/text/Text";
import AllowanceSelector from "../allowances/allowance-selector/AllowanceSelector";
import { IGlobalReducer } from "../../reducers/globalReducer";
import { ISelectPlanReducer } from "../../reducers/selectPlanReducer";
import { IAllowance, IAllowancePricingModel } from "../../models/AddOnAllowance";
import { ICycle, IPlanWithCycles } from "../../models/Product";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../..";
import { IAddOnReducer } from "../../reducers/addOnReducer";
import { setAllowanceUnits } from "../../actions/addOnActions";
import { setNavigationStep } from "../../actions/globalActions";
import { NavigationBarSteps } from "../../models/NavigationBar";
import { setPersonalDetailsCurrentScreen } from "../../actions/personalDetailsActions";
import { PersonalDetailsScreen } from "../../models/PersonalDetails";
import { ISubscriptionReducer } from "../../reducers/subscriptionReducer";

interface ISetAllowancesStep {
  onSubmit?: () => void;
  noAllowanceCB?: () => void;
  btnText?: string;
  shouldTranslateBtnText?: boolean;
  btnDisabled?: boolean;
  btnBackCallback?: () => void;
  btnBackHidden?: boolean;
  warningMessage?: string;
  customizedHeading?: string;
  contentAfterWrapperBtn?: JSX.Element;
  plan: IPlanWithCycles;
  cycle: ICycle;
}

const SetAllowancesStep: React.FC<ISetAllowancesStep> = ({
  onSubmit,
  noAllowanceCB,
  btnText = "SELECT_PLAN_CONTINUE",
  shouldTranslateBtnText = true,
  btnDisabled,
  btnBackCallback = () => { },
  btnBackHidden = false,
  customizedHeading,
  contentAfterWrapperBtn,
  plan,
  cycle
}) => {

  const [currentAddOnUnits, setCurrentAddOnUnits] = useState<number>();
  const { allowances, preloadedAllowances } = useSelector<AppState, IAddOnReducer>(state => state.addOnReducer);
  const { currentSubscription } = useSelector<AppState, ISubscriptionReducer>(state => state.subscriptionReducer);
  const { checkoutConfig, branding } = useSelector<AppState, IGlobalReducer>(state => state.globalReducer);
  const { currentScreen, product, productId, planId, products, plans } = useSelector<AppState, ISelectPlanReducer>(state => state.selectPlanReducer);
  const dispatch = useDispatch<Function>()
  btnDisabled = false;

  if(!onSubmit) {
    onSubmit = () => {
      dispatch(setNavigationStep(NavigationBarSteps.PERSONAL_DETAILS));
      dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.MAIN_DATA));
    }
  }

  if(!noAllowanceCB) {
    noAllowanceCB = () => {
      dispatch(setNavigationStep(NavigationBarSteps.PERSONAL_DETAILS));
      dispatch(setPersonalDetailsCurrentScreen(PersonalDetailsScreen.MAIN_DATA));
    }
  }

  const allowancesFiltered = useMemo(() => {
    if (plan && cycle) {
      const filteredData = allowances.sort((a, b) => ((a.displayName || a.name) > (b.displayName || b.name)) ? 1 : -1).filter(allowance => allowance.pricingModels.some(p => {
        return p.frequency === cycle.pricingModel.frequency
          && p.frequencyType === cycle.pricingModel.frequencyType
          && p.currency.isoCode === (product && product.value ? product.value.billingCurrency : currentSubscription ? currentSubscription.currency : "USD");
      }));
      return [...filteredData];
    }
    return [];
  }, [product, plan, cycle, allowances]);

  const canSkipScreen = allowancesFiltered.length < 1
    || preloadedAllowances.length
    || (allowancesFiltered.every(a => a.isForced) && !preloadedAllowances.length);

  useEffect(() => {
    setCurrentAddOnUnits(undefined);
    if (canSkipScreen) {
      noAllowanceCB?.();
    }
  }, []);


  const updateAllowance = (addOnId: number, pricingModels: IAllowancePricingModel[], units?: number) => {
    dispatch(setAllowanceUnits({ id: addOnId, units, pricingModels }))
  };


  if (!checkoutConfig) {
    return null;
  }

  if (!plan) {
    return null;
  }

  return (
    <PageWrapper
      className={`select-plan select-plan-step2 ${canSkipScreen ? "hidden" : ""}`}
      btnText={btnText}
      shouldTranslateText={shouldTranslateBtnText}
      btnDisabled={btnDisabled}
      btnCallback={onSubmit}
      title="CONFIGURE_ALLOWANCE_TITLE"
      titleTranslateWith={{ planLabel: !!checkoutConfig && checkoutConfig.terminologyPlanSingular, allowanceLabel: checkoutConfig?.terminologyAllowancePlural }}
      titlePosition="left-aligned"
      subTitle="CONFIGURE_ALLOWANCE_SUB_TITLE"
      subTitleTranslateWith={{ allowanceLabel: checkoutConfig?.terminologyAllowancePlural }}
      btnBackCallback={btnBackCallback}
      btnBackHidden={btnBackHidden}
      bottomStickyContent={contentAfterWrapperBtn}>
      <>
        {
          customizedHeading && (
            <Panel id="select-plan-step2__heading">
              <Text content={customizedHeading} shouldTranslate={false} noMargin />
            </Panel>
          )
        }
        {
          allowancesFiltered.map((allowance: IAllowance) => (
            <AllowanceSelector
              key={allowance.id}
              allowance={allowance}
              plan={plan}
              cycle={cycle as ICycle}
              branding={branding}
              image={allowance.imageUrl}
              checkoutConfig={checkoutConfig}
              updateAddons={(allowanceId: number, pricingModels: IAllowancePricingModel[], units?: number) => updateAllowance(allowanceId, pricingModels, units)}
            />
          ))
        }
      </>

    </PageWrapper>
  );
};

export default SetAllowancesStep
