import React from "react";
import SystemInfo from "../../components/ui/system-info/SystemInfo";
import Container from "../container/Container";
import PageWrapper from "../page-wrapper/PageWrapper";

const RobotError: React.FC = () => {
  return (
    <Container>
    <PageWrapper
      titlePosition="left-aligned"
      className="login"
      btnPosition="bottom"
      >
        <SystemInfo
          title="ERROR_INVOKING_BILLSBY_GENERIC_ERROR"
          text="ROBOT_ERROR"
          isShowIcon={false}
          type="warning"
          isVisible
        />
    </PageWrapper>
    </Container>

  )
}

export default RobotError